import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import { CSSTransition } from "react-transition-group";
import tinycolor from "tinycolor2";
import { durations } from "../../config/animations";

const Overlay = (props) => {
  const { active, onClick } = props;

  return (
    <CSSTransition in={active} timeout={durations.normal} mountOnEnter={true} unmountOnExit={true} classNames="overlay">
      <div className={componentStyles() + " overlay"} onClick={onClick} style={props.style} />
    </CSSTransition>
  );
};

const componentStyles = (props) => css`
  will-change: auto;
  position: fixed;
  backdrop-filter: blur(2px);
  /* z-index: 3; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${tinycolor(colors.black).setAlpha(0.7).toString()};

  &.overlay-enter {
    opacity: 0;
  }
  &.overlay-enter-active {
    opacity: 1;
    transition: opacity ${durations.normal}ms;
  }
  &.overlay-exit {
    opacity: 1;
  }
  &.overlay-exit-active {
    opacity: 0;
    transition: opacity ${durations.normal}ms;
  }
`;

export default Overlay;
