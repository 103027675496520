// Libs
import React from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import { AccountGroupIcon } from "mdi-react";
import TargetGroupModal from "./TargetGroupModal/TargetGroupModal";

// Utilities
import { getActiveFromPrimaryColor, getHoverFromPrimaryColor } from "../../utilities/color-modifiers";

// Actions
import { showModalPage } from "../../actions/uiActions";
import getTargetGroupPlaceholder from "../pageBuilder/utilities/getTargetGroupPlaceholder";
import useLang from "../../hooks/useLang";

/**
 * @param {Object} anon
 * @param {Object} anon.targetGroup The selected targetGroup. Set this on render when editing. Otherwise keep the targetGroup recieved from onTargetGroup in state and pass it down
 * @param {Object} anon.onTargetGroup Whenever the targetGroup changes, this function will return it
 * @param {Object} anon.noUsersSelectedOverwrite a string to overwrite the default placeholder when no users are
 * @param {Object} anon.className
 * @param {Object} anon.["data-test-id"]
 */
const TargetGroupSelector = ({
  targetGroup = {},
  onTargetGroup = () => {},
  className = "",
  ["data-test-id"]: dataTestId,
  noUsersSelectedOverwrite,
  ...rest
}) => {
  const dispatch = useDispatch();
  const lang = useLang();

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  function onClickHandler() {
    dispatch(
      showModalPage({
        title: `${lang.choose} ${lang.targetGroup}`,
        content: <TargetGroupModal targetGroup={targetGroup} onTargetGroup={onTargetGroup} />,
        useScrollView: false,
      })
    );
  }

  return (
    <div className={`${componentStyle(primaryColor)} ${className}`} {...rest}>
      <div className="account-group-icon-wrapper">
        <AccountGroupIcon />
      </div>
      <button className="target-group-selector" onClick={onClickHandler} data-test-id={dataTestId}>
        {getTargetGroupPlaceholder({ targetGroup, noUsersSelectedOverwrite })}
      </button>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 5px;

  div.account-group-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid var(--midGrey);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    svg {
      color: var(--darkGrey)
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  button.target-group-selector {
    color: var(--darkGrey);
    cursor: pointer;
    background-color: transparent;
    flex: 1;
    width: 100%;
    border: 1px solid var(--midGrey);
    text-align: left;
    font-size: 1rem;
    padding-left: 0.5rem;
    font-size: 0.95rem;

    &:hover {
      cursor: pointer;
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
      transition: background-color 60ms ease;
    }

    &:active {
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
      transition: background-color 100ms ease;
    }

    p.placeholder {
      font-size: 1rem;
      margin-left: 0.5rem;
      text-align: left;
      width: 100%;
    }
  }
`;

export default TargetGroupSelector;
