export default function convertTimeToDecimal(time) {
  /**
   * Takes a time in the form of "03:10" and converts it to a decimal number 3,167
   * **/
  if (!time) return 0;

  let [hour, decimal] = time.split(":");
  decimal = Math.round((decimal / 0.6) * 100);
  return parseFloat(parseInt(hour, 10) + "." + (decimal < 1000 ? "0" : "") + decimal);
}
