import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast } from "../../../actions/uiActions";

// Hooks
import useLang from "../../../hooks/useLang";

// Components
import TabBar from "../../ui/TabBar";
import TabView from "../../ui/TabView";
import NewsTargetGroupList from "./NewsTargetGroupList";

const NewsTargetGroupModal = ({ subTypeId, newsId, onlyUnread = false, customisedDescription = false }) => {
  const lang = useLang();
  const { admin } = useSelector((state) => state.auth.user);

  // Data
  const [targetGroup, setTargetGroup] = useState([]);
  const [targetGroupRead, setTargetGroupRead] = useState([]);
  const [targetGroupUnread, setTargetGroupUnread] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  // UI Layout
  const tabs = [
    lang.all,
    <>
      {lang.hasRead} {loading == false ? <span style={{ opacity: 0.7 }}> ({targetGroupRead.length})</span> : ""}
    </>,
    <>
      {lang.hasNotRead} {loading == false ? <span style={{ opacity: 0.7 }}> ({targetGroupUnread.length})</span> : ""}
    </>,
  ];

  const getTabView = () => {
    let tabViewArray = [];
    if (!onlyUnread) {
      tabViewArray.push(
        <NewsTargetGroupList
          targetGroup={targetGroup}
          loading={loading}
          overviewList={true}
          description={lang.UsersInTargetGroup}
        />
      );
    }

    if (admin && !onlyUnread) {
      tabViewArray.push(<NewsTargetGroupList targetGroup={targetGroupRead} loading={loading} description={lang.hasRead} />);
    }
    if (admin) {
      tabViewArray.push(
        <NewsTargetGroupList
          targetGroup={targetGroupUnread}
          loading={loading}
          description={customisedDescription ? customisedDescription : lang.hasNotRead}
        />
      );
    }
    return tabViewArray;
  };

  const tabView = getTabView();

  useEffect(() => {
    getTargetGroup();
  }, []);

  const getTargetGroup = async () => {
    try {
      let { data } = await req().get(`news/${subTypeId}/${newsId}/target-group/members`);
      const newTargetGroupRead = data.filter((user) => user.read);
      const newTargetGroupUnread = data.filter((user) => !user.read);
      setTargetGroup(data);
      setTargetGroupRead(newTargetGroupRead);
      setTargetGroupUnread(newTargetGroupUnread);
      /* There is probably a better solution than the next line..
       * But there is a flash of a statusbox saying that there is no users, if the loading doesnt go on for a bit longer than the actual load time. */
      setTimeout(() => setLoading(false), 300);
    } catch (err) {
      addToast({ template: "error" });
    }
  };

  return (
    <>
      {/* If the user isnt an admin, they one have one tab to see. Therefore we only show the tabbar for admins */}
      {admin && !onlyUnread && (
        <TabBar
          activeTabIndex={activeTab}
          tabs={tabs.map((tab, tabIndex) => ({
            title: tab,
            onClick: () => {
              setActiveTab(tabIndex);
            },
          }))}
        />
      )}
      <TabView activeTabIndex={activeTab} tabs={tabView} useScrollView={true} />
    </>
  );
};

export default NewsTargetGroupModal;
