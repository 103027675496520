// Libs
import React, { useState } from "react";
import * as queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";

// Components
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import { css } from "emotion";
import ErrorOutlineIcon from "mdi-react/ErrorOutlineIcon";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Utilities
import req from "../../utilities/request-utility";

// Actions
import { getChecklists } from "../../actions/checklistActions";
import { addToast, hideModalPage } from "../../actions/uiActions";

// Config
import { feedTypes } from "./config";
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

const NewChecklist = () => {
  // Query params
  const { dataId: registrationId } = queryString.parse(window.location.search);

  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  // Local state
  const [checklistTitle, setChecklistTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Dispatcher
  const dispatch = useDispatch();

  // Async create checklist
  const addChecklist = async () => {
    try {
      setLoading(true);
      await req().post(`checklists/registrations/${registrationId}/checklists`, { checklistTitle });
      dispatch(getChecklists({ registrationId: registrationId, feedType: feedTypes.uncompleted }));
      setLoading(false);
      dispatch(hideModalPage());
    } catch (err) {
      setError(true);
      setLoading(false);
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: lang.couldNotCreateChecklist,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  };

  return (
    <div className={componentStyle()}>
      {loading && !error && <Spinner style={{ marginTop: "2rem" }} />}
      {!loading && error && <StatusBox />}
      {!loading && !error && (
        <div className="container">
          <p className="title">{lang.nameForChecklist}</p>
          <TextInput placeholder={lang.chooseTitleForChecklist} onChange={(e) => setChecklistTitle(e.target.value)} />
          <Button disabled={checklistTitle === ""} onClick={() => addChecklist()}>
            {lang.createChecklist}
          </Button>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  background-color: var(--white);

  div.container {
    padding: 1rem;
    margin: auto;
    max-width: ${breakpoints.md}px;
  }

  p.title {
    padding: 0.5rem 0;
  }

  input {
    margin-bottom: 1.5rem;
  }
`;

export default NewChecklist;
