// Libraries
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

// Styles
import colors from "../../style/colors";

// Icons
import bold from "../../images/editor/bold.svg";
import italic from "../../images/editor/italic.svg";
import ordered from "../../images/editor/orderedList.svg";
import unordered from "../../images/editor/unorderedList.svg";
import link from "../../images/editor/link.svg";
import linkOff from "../../images/editor/linkOff.svg";

// Utils
import common from "../../style/common";

/** Editor text area
 * It is a textarea input field, but with the opportunity to format the text.
 *
 */

function TextEditor(props) {
  const { value, onChange, style } = props;
  // Get the primary colour of the app to use in the style sheet
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Enable edit mode
  useEffect(() => {
    if (value) {
      const draft = convertFromRaw(
        markdownToDraft(value, {
          preserveNewlines: true,
          // emptyLineBeforeBlock: true,
        })
      );

      let mutEditorState = EditorState.createWithContent(draft);
      setEditorState(mutEditorState);
    }
    // eslint-disable-next-line
  }, []);

  // Store live markdown version of content
  useEffect(() => {
    if (editorState) {
      const markdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
        preserveNewlines: true,
        emptyLineBeforeBlock: true,
      });

      onChange(markdown);
    }
    // eslint-disable-next-line
  }, [editorState]);

  return (
    <div id={props.id} className={componentStyle(primaryColor)} style={style}>
      <Editor
        editorClassName="wysiwyg-editor"
        toolbarClassName="wysiwyg-toolbar"
        stripPastedStyles={true}
        editorState={editorState}
        onEditorStateChange={(e) => setEditorState(e)}
        toolbar={{
          options: ["inline", "list", "link"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic"],
            bold: { icon: bold },
            italic: { icon: italic },
          },
          list: {
            options: ["unordered", "ordered", "link"],
            unordered: { icon: unordered },
            ordered: { icon: ordered },
          },
          link: {
            link: { icon: link },
            unlink: { icon: linkOff },
            defaultTargetOption: "_blank",
          },
        }}
      />
    </div>
  );
}

const componentStyle = (primaryColor) => css`
  .wysiwyg-toolbar {
    display: flex;
    border-radius: 3px 3px 0 0;
    border: 1px var(--midGrey) solid;
    background-color: var(--ultraLightGrey);
    position: relative;

    .rdw-option-wrapper {
      width: 38px;
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      border-bottom: 3px transparent solid;
      transition: border 220ms ease, opacity 220ms ease;

      &.rdw-option-active {
        border-bottom: 3px ${primaryColor} solid;
        opacity: 1;
        transition: border 120ms ease, opacity 120ms ease;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .rdw-link-modal {
      border: 1px var(--midGrey) solid;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 6px 6px;
      margin-top: 0px;
      background-color: var(--ultraLightGrey);
      position: absolute;
      left: 5%;
      width: 90%;
      padding: 0.65rem;
      white-space: initial;
      animation: linkModalEnter 220ms ease forwards;

      label {
        display: block;
        color: var(--darkGrey);
        margin: 0 0 0.15rem 0;
        font-size: 0.9rem;
      }

      input.rdw-link-modal-input {
        display: block;
        -webkit-appearance: none;
        border: 1px var(--midGrey) solid;
        border-radius: 3px;
        font-size: 0.9rem;
        color: var(--darkGrey);
        margin: 0 0 0.5rem 0;
        padding: 0.25rem;
        width: 100%;

        &:focus {
          border: 1px ${primaryColor} solid;
          outline: 0;
        }
      }

      .rdw-link-modal-btn {
        display: inline-block;
        font-size: 0.9rem;
        line-height: 2rem;
        border-radius: 1rem;
        margin: 0.25rem 0.5rem 0 0;
        padding: 0 1rem;

        &:nth-child(1) {
          background-color: ${primaryColor};
          color: var(--white);
          font-weight: 700;
          border: 0px;
        }
        &:nth-child(2) {
          background-color: transparent;
          color: var(--darkGrey);
          border: 1px var(--midGrey) solid;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .rdw-link-modal-target-option {
        display: none;
      }

      .rdw-link-modal-buttonsection {
        display: block;
      }
    }
  }

  .wysiwyg-editor {
    display: flex;
    border: 1px var(--midGrey) solid;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 3px 3px;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    display: block;
    width: 100%;
    font-size: 1rem;
    color: var(--black);
    resize: vertical;
    font-family: ${common.fontStack};
    padding: 0.5rem;
    -webkit-appearance: none;
    min-height: 120px;

    ol,
    ul {
      margin-left: 0.9rem;
    }

    &:focus {
      outline: 0;
      border: 1px solid ${primaryColor};
    }

    .rdw-link-decorator-wrapper {
      a {
        color: ${primaryColor};
        text-decoration: underline;
      }

      img.rdw-link-decorator-icon {
        display: none;
      }
    }
  }

  @keyframes linkModalEnter {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export default TextEditor;

TextEditor.propTypes = {
  /** If the post already has content */
  content: PropTypes.string,
  /** A function to update the content */
  setContent: PropTypes.func,
  /** An array of custom style */
  style: PropTypes.object,
};
