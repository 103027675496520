// Libs
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import colors from "../../../style/colors";

// Utilities
import getPageFromId from "../../../utilities/get-page-from-id";
import req from "../../../utilities/request-utility";
import { parseDate, parseDatetime } from "../../../utilities/parse-date";
import { addToast, hideModalPage, showContextMenu, showDialog, showModalPage } from "../../../actions/uiActions";

// Components
import InlineSpinner from "../../ui/InlineSpinner";
import breakpoints from "../../../config/breakpoints";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import StatusBox from "../../ui/StatusBox";
import ListItem from "../../ui/ListItem";
import ContextMenuButton from "../../ui/ContextMenuButton";
import { AlertDecagramIcon, InformationOutlineIcon, PencilIcon, SmileyCoolIcon, TrashCanOutlineIcon } from "mdi-react";
import EquipmentBookingStartBookingModal from "../EquipmentBookingStartBookingModal";
import styleTypes from "../../../config/styleTypes";

function EquipmentBookingSingleTimes({ match }) {
  const dispatch = useDispatch();
  const [times, setTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const currentUserId = useSelector((s) => s.auth.user.id);
  const { pages } = useSelector((state) => state.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  function getTimes() {
    setLoading(true);
    req()(`equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}/future-bookings`)
      .then(({ data }) => {
        setLoading(false);
        setError(false);
        setTimes(data);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }

  function deleteBooking(bookingId) {
    req()
      .delete(`equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}/bookings/${bookingId}/remove`)
      .then(() => {
        getTimes();
      })
      .catch(() => {
        dispatch(addToast({ template: "error" }));
      });
  }

  useEffect(() => {
    getTimes();
  }, []);

  function handleContextMenuClick(booking) {
    dispatch(
      showContextMenu({
        actions: [
          {
            icon: <PencilIcon />,
            title: "Rediger",
            callback: () => {
              dispatch(
                showModalPage({
                  pageStyle: {
                    backgroundColor: colors.white,
                  },
                  content: (
                    <EquipmentBookingStartBookingModal
                      equipmentBookingId={booking.id}
                      equipmentId={match.params.equipmentId}
                      onFinishedBooking={() => {
                        getTimes();
                        dispatch(hideModalPage());
                      }}
                      editData={booking}
                      config={page.config}
                    />
                  ),
                })
              );
            },
          },
          {
            icon: <TrashCanOutlineIcon />,
            title: "Slet",
            callback: () => {
              dispatch(
                showDialog({
                  title: "Vil du fjerne din bookning?",
                  content:
                    "Er du sikker på at du vil fjerne din bookning - så snart den er fjernet kan andre booke udstyret i stedet for dig.",
                  primaryActionTitle: "Ja, fjern min bookning",
                  primaryAction: () => deleteBooking(booking.id),
                  styleType: styleTypes.error,
                  icon: <AlertDecagramIcon />,
                })
              );
            },
          },
        ],
      })
    );
  }

  return (
    <div className={componentStyles()}>
      {loading && !error && <InlineSpinner />}
      {!loading && error && <StatusBox />}
      {!loading && !error && times.length === 0 && (
        <StatusBox
          icon={<SmileyCoolIcon />}
          title="Ingen bookninger fundet"
          content="Du kan frit booke når det passer dig"
        />
      )}

      {!loading &&
        !error &&
        times.map((booking) => (
          <ListItem
            maxWidth={breakpoints.md}
            title={
              <span>
                {parseDatetime(booking.bookingStartDate)} <br /> {parseDatetime(booking.bookingEndDate)}
              </span>
            }
            subTitle={booking.user.name}
            imageLeft={getProfilePictureFromUserObject(booking.user, undefined, { marginRight: "0.5rem" })}
            contentRight={
              currentUserId === booking.user.id ? (
                <ContextMenuButton onClick={() => handleContextMenuClick(booking)} />
              ) : null
            }
          />
        ))}
    </div>
  );
}

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 1rem auto 5rem auto;

  p.meta {
    color: var(--darkGrey);
  }

  .booking-details {
    border: 1px var(--midGrey) solid;
    background-color: var(--white);
    border-radius: 3px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    .upper-section {
      display: flex;
      margin-bottom: 0.85rem;

      .user-details {
        padding-left: 0.5rem;
      }
    }

    .comment-label {
      margin-bottom: 0.15rem;
    }

    .comment-content {
      font-size: 0.95rem;
      color: var(--darkGrey);
    }
  }
`;

export default EquipmentBookingSingleTimes;
