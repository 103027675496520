import {
  SET_SELECTED_DATE,
  SET_SELECTED_USER,
  EXPORT_TIME_REGISTRATION_TIME_SHEET,
  EXPORT_TIME_REGISTRATION_TIME_SHEET_FAILURE,
  EXPORT_TIME_REGISTRATION_TIME_SHEET_SUCCESS,
  GET_TIME_REGISTRATION_PERMISSIONS_FAILURE,
  GET_TIME_REGISTRATION_PERMISSIONS_SUCCESS,
  GET_TIME_REGISTRATION_TIME_SHEETS,
  GET_TIME_REGISTRATION_TIME_SHEETS_FAILURE,
  GET_TIME_REGISTRATION_TIME_SHEETS_SUCCESS,
  RESET_TIME_REGISTRATION,
  TIME_REGISTRATION_SET_SUMMARY_MODE,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_FAILURE,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_SUCCESS,
  RESET_TIME_REGISTRATION_TIME_SHEETS,
  UPDATE_TIME_REGISTRATION_TABLE_FILTERS,
  RESET_TIME_REGISTRATION_TABLE_FILTERS,
  RESET_TIME_REGISTRATION_TABLE_FILTER,
  NOT_FOUND_TIME_REGISTRATION_TIME_SHEETS,
  UPDATE_TIME_REGISTRATION_TABLE_GLOBAL_FILTER,
} from "../actions/actionTypes";

const initialState = {
  summaryMode: "hours",
  selectedDate: "",
  permissions: {},
  table: {
    filters: {
      startDate: null,
      endDate: null,
      enableCSVDownload: false,
    },
    globalFilter: null,
    timeSheetsNotFound: false,
    timeSheets: [],
    loading: false,
    error: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_TIME_REGISTRATION:
      return { ...initialState };

    case GET_TIME_REGISTRATION_TIME_SHEETS_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          timeSheets: [...action.payload],
          error: false,
          loading: false,
          timeSheetsNotFound: false,
        },
      };

    case GET_TIME_REGISTRATION_TIME_SHEETS:
    case EXPORT_TIME_REGISTRATION_TIME_SHEET:
    case UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      };

    case RESET_TIME_REGISTRATION_TABLE_FILTERS:
      // The default period is the current day and a week forward

      return {
        ...state,
        table: {
          ...state.table,
          filters: {
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
          },
          globalFilter: null,
          timeSheetsNotFound: false,
        },
      };

    case RESET_TIME_REGISTRATION_TABLE_FILTER:
      let filters = { ...state.table.filters };
      let filterProperty = action.payload;

      if (!(filterProperty in filters)) return { ...state };

      filters[filterProperty] = "";

      return {
        ...state,
        table: {
          ...state.table,
          filters,
        },
      };

    case NOT_FOUND_TIME_REGISTRATION_TIME_SHEETS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          timeSheetsNotFound: true,
          timeSheets: [{ timeRegistrationData: { date: state.table.filters.startDate } }],
        },
      };

    case EXPORT_TIME_REGISTRATION_TIME_SHEET_SUCCESS:
    case UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          timeSheets: state.table.timeSheets.map((timeSheet) => {
            let updatedTimeSheet = action.payload.find((updatedTimeSheet) => updatedTimeSheet.id === timeSheet.id);
            if (updatedTimeSheet) return updatedTimeSheet;
            return timeSheet;
          }),
        },
      };

    case GET_TIME_REGISTRATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };

    case RESET_TIME_REGISTRATION_TIME_SHEETS:
      return {
        ...state,
        table: {
          ...state.table,
          timeSheets: [],
        },
      };

    case EXPORT_TIME_REGISTRATION_TIME_SHEET_FAILURE:
    case GET_TIME_REGISTRATION_TIME_SHEETS_FAILURE:
    case GET_TIME_REGISTRATION_PERMISSIONS_FAILURE:
    case UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_FAILURE:
      return {
        ...state,
        table: {
          ...state.table,
          error: true,
          loading: false,
        },
      };

    case UPDATE_TIME_REGISTRATION_TABLE_FILTERS:
      return {
        ...state,
        table: {
          ...state.table,
          filters: { ...state.table.filters, ...action.payload },
        },
      };

    case UPDATE_TIME_REGISTRATION_TABLE_GLOBAL_FILTER:
      return {
        ...state,
        table: {
          ...state.table,
          globalFilter: action.payload,
        },
      };

    case TIME_REGISTRATION_SET_SUMMARY_MODE:
      return {
        ...state,
        summaryMode: action.payload,
      };

    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };

    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    default:
      return state;
  }
}
