// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import * as queryString from "query-string";
import { format, parse } from "date-fns";
import tinycolor from "tinycolor2";

// Images
import BackgroundImage from "../../images/christmas/christmas-background.jpg";

// Utils
import req from "../../utilities/request-utility";
import getUserLocale from "../../utilities/get-user-locale";
import { AlertOctagonIcon, CheckboxBlankCircleIcon } from "mdi-react";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import ChristmasCalendarDoor from "./ChristmasCalendarDoor";
import ChristmasCalendarQuestion from "./ChristmasCalendarQuestion";

// Actions
import { addToast, showModalPage, hideModalPage, updateModalPage } from "../../actions/uiActions";
import { getBirthdays, resetAll } from "../../actions/birthdayActions";

// Utilities
import common from "../../style/common";
import colors from "../../style/colors";

const ChristmasCalendar = (props) => {
  // Destructure the props
  const { showModalPage, hideModalPage, addToast } = props;

  // Language state from redux
  const { language: lang } = useSelector((state) => state.language);

  // User state from redux
  const { user } = useSelector((state) => state.auth);

  // The 24 questions
  const [christmasCalenderMetaData, setChristmasCalenderMetaData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [questionsError, setQuestionsError] = useState(false);

  // variables

  function getToday() {
    let currentDay = new Date();
    currentDay.setHours(0);
    currentDay.setMinutes(0);
    return format(currentDay, "yyyyMMddHHmm");
  }

  function getChristmasDay(date, month) {
    let christmasDay = new Date();
    christmasDay.setMonth(month - 1);
    christmasDay.setDate(date);
    christmasDay.setHours(0);
    christmasDay.setMinutes(0);
    return format(christmasDay, "yyyyMMddHHmm");
  }

  function isItToday(date, month) {
    return getToday() === getChristmasDay(date, month);
  }

  useEffect(() => {
    getChristmasQuiz();

    // eslint-disable-next-line
  }, []);

  function getChristmasQuiz() {
    setQuestionsLoading(true);
    setQuestionsError(false);
    let christmasQuizId = queryString.parse(window.location.search).dataId;
    req()(`christmas-quizzes/${christmasQuizId}`)
      .then(({ data }) => {
        const metaData = {
          answerBonus: data.answerBonus,
          correctAnswerBonus: data.correctAnswerBonus,
          noDailyWinner: data.noDailyWinner,
        };
        setChristmasCalenderMetaData(metaData);
        setQuestions(data.questions);
        setQuestionsLoading(false);
      })
      .catch(() => {
        setQuestionsLoading(false);
        setQuestionsError(true);
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "60000",
          title: lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.christmasCalendar.toLowerCase()),
          content: `${lang.errorCouldNotGet__placeholder__.replace(
            /{{placeholder}}/gi,
            lang.christmasCalendar.toLowerCase()
          )}. ${lang.tryAgainOrContactSupport}`,
        });
      });
  }

  function openQuestion(question) {
    showModalPage({
      title: format(
        parse(getChristmasDay(question.dayNumber, question.christmasMonth), "yyyyMMddHHmm", 0),
        "do MMMM",
        getUserLocale(user)
      ),
      content: (
        <ChristmasCalendarQuestion
          today={isItToday(question.dayNumber, question.christmasMonth)}
          questionId={question.id}
          christmasCalenderMetaData={christmasCalenderMetaData}
        />
      ),
      useScrollView: false,
      closeCallback: () => {
        getChristmasQuiz();
        hideModalPage();
      },
    });
  }

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={lang.christmasCalendar} />
      <ScrollView>
        <div className={componentStyle()}>
          <div className="calendar-container">
            {/* Loading -> "Skeleton" icons with blinking animations */}
            {questionsLoading && !questionsError && (
              <div className={`${iconsContainer(props)} frontpage-navigation`}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, index) => (
                  <div className={icons()} key={index}>
                    <CheckboxBlankCircleIcon className="placeholder-icon" style={{ animationDelay: `${index * 30}ms` }} />
                    <div className="fake-title" style={{ animationDelay: `${index * 30}ms` }}></div>
                  </div>
                ))}
              </div>
            )}

            {/* Actual content */}
            {!questionsLoading &&
              !questionsError &&
              questions.map((question) => (
                <ChristmasCalendarDoor
                  key={question.id}
                  active={question.isActive}
                  christmasMonth={question.christmasMonth}
                  dayNumber={question.dayNumber}
                  alreadyAnswered={question.alreadyAnswered}
                  questionId={question.id}
                  onClick={() => openQuestion(question)}
                />
              ))}
            <img src={BackgroundImage} alt="" />
          </div>
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  .calendar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100vh - ${common.topBarHeight}px);

    img {
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 100%;
      object-fit: cover;
      filter: brightness(77%) blur(0.6px);
    }
  }
`;

const iconsContainer = () => css`
  flex: 0 1 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
`;

const icons = () => css`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 16.6666%;
  padding: 0.5rem;
  vertical-align: top;
  font-size: 0.8rem;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  transition: background-color 180ms ease;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};

  /* First column */
  &:nth-child(3n + 1) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }
  /* Second column */
  &:nth-child(3n + 2) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }

  svg {
    margin-bottom: 0.5rem;
    width: 2rem;
    height: 2rem;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  }

  svg.placeholder-icon {
    width: 2.25rem;
    height: 2.25rem;
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  .fake-title {
    width: 65px;
    height: 7px;
    border-radius: 5px;
    margin-top: 7px;
    background-color: rgba(255, 255, 255, 0.6);
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    35% {
      opacity: 0.3;
    }
    55% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.3;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
    transition: background-color 120ms ease;
  }
  &:active {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.12);
    transition: background-color 120ms ease;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  resetAll: bindActionCreators(resetAll, dispatch),
  getBirthdays: bindActionCreators(getBirthdays, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  appConfig: state.appConfig,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChristmasCalendar);
