// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import queryString from "query-string";

// Actions
import { getRegistrationPosts, resetAll } from "../../actions/registrationActions";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import RegistrationPostContent from "../registration/components/RegistrationPostContent";

// Styles
import breakpoints from "../../config/breakpoints";

const SurveySingle = (props) => {
  const { match } = props;
  const { loading, error, posts } = useSelector((s) => s.registration.all);
  const dispatch = useDispatch();

  const { dataId: postId } = queryString.parse(window.location.search);

  /** Initializes the first retrieval of posts **/
  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getRegistrationPosts({
        registrationId: match.params.registrationId,
        postId: postId,
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={"Min besvarelse"} />

      <ScrollView className={componentStyle()}>
        {/* Spinner */}
        {loading && !error && <InlineSpinner />}

        {/* Error */}
        {!loading && error && <StatusBox />}

        {/* Posts */}
        {!loading && !error && posts.length > 0 && (
          <div className="post-wrapper">
            <RegistrationPostContent answers={posts[0].answers} />
          </div>
        )}
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  background-color: var(--white);
  padding: 1rem 0;
  display: flex;
  justify-content: center;

  .post-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    max-width: ${breakpoints.md}px;
    width: 100%;
  }
`;

export default SurveySingle;
