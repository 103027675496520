import { css } from "emotion";
import Toggle from "../../Toggle";

/**
 * @param {Object} props
 * @param {Object} props.lang - Language object state
 * @param {Function} props.appConfig - Application config state
 * @param {String} props.onConfigChange - Function that handles then application config change
 */

const UserDisplayToggleSettings = (props) => {
  const { lang, appConfig, onConfigChange } = props;

  return (
    <div className={componentStyle()}>
      <div className="setting-row">
        <p>
          {lang.view} {lang.mainGroup.toLowerCase()} (mastergroup)
        </p>
        <Toggle
          on={appConfig.showMasterGroup}
          onClick={() => onConfigChange("showMasterGroup", !appConfig.showMasterGroup)}
        />
      </div>
      <div className="setting-row">
        <p>
          {lang.view} {lang.subGroup.toLowerCase()} (usergroup)
        </p>
        <Toggle on={appConfig.showUserGroup} onClick={() => onConfigChange("showUserGroup", !appConfig.showUserGroup)} />
      </div>
      <div className="setting-row">
        <p>
          {lang.view} {lang.jobTitle.toLowerCase()}
        </p>
        <Toggle on={appConfig.showJobTitle} onClick={() => onConfigChange("showJobTitle", !appConfig.showJobTitle)} />
      </div>
      <div className="setting-row">
        <p>
          {lang.view} {lang.employeeId}
        </p>
        <Toggle on={!appConfig.hideEmployeeId} onClick={() => onConfigChange("hideEmployeeId", !appConfig.hideEmployeeId)} />
      </div>
    </div>
  );
};

const componentStyle = () => css`
  border: 1px solid var(--midGrey);
  border-radius: 3px;
  border-bottom: none;

  .usergroup-settings {
    margin-bottom: 2rem;
  }

  .setting-row {
    border-bottom: 1px solid var(--midGrey);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }
`;

export default UserDisplayToggleSettings;
