import {
  SET_ACTIVE_TAB,
  GET_CONTACT_GROUP_NAMES_STARTED,
  GET_CONTACT_GROUP_NAMES_SUCCESS,
  GET_CONTACT_GROUP_NAMES_FAILURE,
  GET_CONTACTS_STARTED,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  CONTACTS_END_OF_FEED,
  CLEAR_CONTACTS,
  SET_RELATED_APPS_CONTACTS,
  SET_CONTACT_FILTERS_ACTIVE_STATE,
  SET_CONTACT_FILTERS_VALUE,
  CLEAR_ALL_CONTACTS,
  SET_CONTACT_PREVIOUS_STATE,
} from "../actions/actionTypes";

const initialState = {
  previousState: null,
  activeTab: 0,
  relatedApps: [], // the apps are related on a per-feature basis (contacts, birthdays) so this only makes sense in a local context for contacts
  filters: {
    selectedAppId: null,
    active: false,
  },
  phoneBook: {
    groupNames: [],
    contacts: [],
    error: false,
    endOfFeed: false,
    loading: false,
    searchTerm: "",
  },
  keyPerson: {
    groupNames: [],
    contacts: [],
    error: false,
    endOfFeed: false,
    loading: false,
    searchTerm: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_GROUP_NAMES_STARTED:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          loading: true,
        },
      };

    case GET_CONTACT_GROUP_NAMES_SUCCESS:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          groupNames: action.payload.groupNames,
          loading: false,
        },
      };

    case GET_CONTACT_GROUP_NAMES_FAILURE:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          error: true,
          loading: false,
        },
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case GET_CONTACTS_STARTED:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          loading: true,
        },
      };

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          contacts: [...state[action.payload.contactType].contacts, ...action.payload.contacts],
          loading: false,
        },
      };

    case GET_CONTACTS_FAILURE:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          error: true,
          loading: false,
        },
      };

    case CONTACTS_END_OF_FEED:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          endOfFeed: true,
          loading: false,
        },
      };

    case CLEAR_CONTACTS:
      return {
        ...state,
        [action.payload.contactType]: {
          ...state[action.payload.contactType],
          contacts: [],
          error: false,
          loading: false,
          endOfFeed: false,
          searchTerm: "",
        },
      };

    case CLEAR_ALL_CONTACTS:
      return {
        ...initialState,
      };

    case SET_RELATED_APPS_CONTACTS:
      return {
        ...state,
        relatedApps: action.payload,
      };

    case SET_CONTACT_FILTERS_ACTIVE_STATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          active: action.payload,
        },
      };

    case SET_CONTACT_FILTERS_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };

    case SET_CONTACT_PREVIOUS_STATE: {
      return {
        ...state,
        previousState: action.payload,
      };
    }

    default:
      return state;
  }
}
