import React from "react";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import getAppName from "../../../utilities/get-app-name";

const UseNavigateToSubPages = ({ moduleId }) => {
  const navigateToSubPages = (pageId) => {
    pageNavigatorV2({
      path: `/${getAppName()}/page-builder/${moduleId}/parentPages/${pageId}`,
      direction: "forward",
    });
  };

  return { navigateToSubPages };
};

export default UseNavigateToSubPages;
