import store from "../../store";

/**
 * List of known things to check for in order to support notifications.
 * The name will be publicly available from settings so name them appropriately.
 *
 * They are used to determine if it makes sense to prompt user for push-permissions.
 * If one test fails we will NOT prompt user so be careful when adding a new test as
 * it could potentially ruin push-notification support.
 *
 * -> Returns true if check is OK
 * -> Returns false if check is not OK
 *
 */
const pushNotificationsChecks = [
  {
    name: "Checking for service worker support",
    performCheck: () => {
      if ("serviceWorker" in navigator) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    name: "Checking for service worker installation",
    performCheck: () => {
      if (navigator?.serviceWorker?.controller) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    name: "Checking for push-notification support",
    performCheck: () => {
      if ("PushManager" in window) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    name: "Checking if notifications is enabled for app",
    performCheck: () => {
      let state = store.getState().appConfig;
      if (state.enablePushNotifications === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    name: "Checking if notification-permissions is ok",
    performCheck: () => {
      if (Notification.permission !== "denied") {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    name: "Checking if app is installed (Only applies to iOS. For other devices this check should always be ok)",
    performCheck: () => {
      // If IOS and not added to  homescreen
      const isIOS = navigator.userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false;
      const displayModeIsStandalone = window.matchMedia("(display-mode: standalone)").matches;

      // device is not IOS so check doesn't matter
      if (!isIOS) return true;

      if (isIOS && displayModeIsStandalone) {
        return true;
      } else {
        return false;
      }
    },
  },
];

export default pushNotificationsChecks;
