import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import TinyAnimate from "TinyAnimate";
import PropTypes from "prop-types";

import colors from "../../style/colors";

import { ArrowForwardIcon } from "mdi-react";
import tinycolor from "tinycolor2";

const SLIDER_SIZE = 40;

/** Component used for adding a slider element to larger components
 *
 * Example:
 * ```jsx
 * {!disableSwipe && !read && <Slider style={{ marginBottom: "0.5rem" }} onSwipeEnD={onSwipeEnD} points={points} />}
 * ```
 */

const eventTypes = {
  mouse: {
    move: "mousemove",
    end: "mouseup",
  },
  touch: {
    move: "touchmove",
    end: "touchend",
  },
};

function Slider(props) {
  const { style, points, swipeCompleted, readActionLangOverwrite = false, sliderRef = undefined } = props;
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const lang = useSelector((state) => state.language.language);
  const [x, setX] = useState(0);
  const [animateOut, setAnimateOut] = useState(false);
  const initialX = useRef(0);
  const xRef = useRef(0);
  const isDragging = useRef(false);
  const containerRef = useRef();
  const readActionLang = readActionLangOverwrite ? lang[readActionLangOverwrite] : lang.readAndUnderstood;

  useEffect(() => {
    xRef.current = x;
  }, [x]);

  function dragStart(e, type) {
    if (isDragging.current === true) return;

    if (e.type === "touchstart") {
      initialX.current = e.touches[0].clientX;
    } else {
      initialX.current = e.clientX;
    }

    isDragging.current = true;

    document.addEventListener(eventTypes[type].move, handleMove, true);
    document.addEventListener(eventTypes[type].end, handleDragEnd);
  }

  function handleMove(e) {
    if (isDragging.current !== true) return;

    if (e.type === "touchmove") {
      setX(e.touches[0].clientX - initialX.current);
    } else {
      setX(e.clientX - initialX.current);
    }
  }

  function resetSlider() {
    TinyAnimate.animate(
      xRef.current,
      0,
      1000,
      (newX) => setX(newX),
      "easeOutElastic",
      () => {
        isDragging.current = false;
      }
    );
  }

  function handleDragEnd(e) {
    // Get total swipelength

    let totalSwipeLength;
    if (containerRef.current) {
      totalSwipeLength = containerRef.current.getBoundingClientRect().width / 2 - SLIDER_SIZE;
    } else {
      let el = document.querySelector(".post-swipe");
      totalSwipeLength = el ? el.getBoundingClientRect().width / 2 - SLIDER_SIZE : 500;
    }

    // Slider is swiped more than 70%
    if (xRef.current / totalSwipeLength > 0.7) {
      TinyAnimate.animate(
        xRef.current,
        totalSwipeLength,
        240,
        (newX) => setX(newX),
        "ease",
        () => {
          requestAnimationFrame(() => {
            setAnimateOut(true);
            setTimeout(() => {
              props.onSwipeEnd();
            }, 250);
          });
        }
      );
    } else {
      resetSlider();
    }

    // Eventlistener cleanup
    document.removeEventListener("touchmove", handleMove, true);
    document.removeEventListener("touchend", handleDragEnd);
    document.removeEventListener("mousemove", handleMove, true);
    document.removeEventListener("mouseup", handleDragEnd);
  }

  useImperativeHandle(sliderRef, () => ({
    resetSlider,
  }));

  return (
    <div
      className={`${componentStyle(primaryColor)} ${swipeCompleted && animateOut ? "animate-out" : ""} ${
        swipeCompleted ? "hide" : ""
      }`}
      style={style}
    >
      <div className="pre-swipe">
        <p>
          {points ? `${points} ${lang.points.toLowerCase()}` : readActionLang} <ArrowForwardIcon />
        </p>
      </div>
      <div
        className="post-swipe"
        style={{ transform: `translateX(${x}px)` }}
        onTouchStart={(e) => dragStart(e, "touch")}
        onMouseDown={(e) => dragStart(e, "mouse")}
        ref={containerRef}
      >
        <p>
          {readActionLang}
          <ArrowForwardIcon className="swipe-indicator" />
        </p>
      </div>
    </div>
  );
}

const componentStyle = (primaryColor) => css`
  height: ${SLIDER_SIZE}px;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  line-height: ${SLIDER_SIZE}px;
  overflow: hidden;
  border-radius: ${SLIDER_SIZE / 2}px;
  white-space: nowrap;
  text-align: center;
  border: 1px var(--midGrey) solid;
  box-sizing: initial;
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(white, black); /* iOS safari hack for overflow hidden */

  &.hide {
    opacity: 0;
  }

  &.animate-out {
    animation: removeSlider 400ms ease forwards;

    @keyframes removeSlider {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(1.35);
      }
    }
  }

  .pre-swipe {
    position: absolute;
    width: 100%;
    border-radius: ${SLIDER_SIZE / 2}px;
  }

  .post-swipe {
    position: absolute;
    left: calc(-200% + ${SLIDER_SIZE}px);
    padding-left: 95%;
    width: 200%;
    border-radius: ${SLIDER_SIZE / 2}px;
    background-color: ${primaryColor};
    color: #fff;
    transition: background-color 240ms ease;

    &:hover {
      transition: background-color 80ms ease;
      cursor: pointer;
      background-color: ${tinycolor(primaryColor).lighten(4).toString()};
    }

    &:active {
      transition: background-color 120ms ease;
      background-color: ${tinycolor(primaryColor).darken(7).toString()};
    }
    /* Forces all events to parent-element */
    * {
      pointer-events: none;
    }

    p {
      position: relative;

      svg.swipe-indicator {
        position: absolute;
        right: 0px;
        top: 0px;
        margin: 0;
        width: ${SLIDER_SIZE}px;
        height: ${SLIDER_SIZE}px;
        padding: ${SLIDER_SIZE / 4}px;
        color: var(--white);
      }
    }
  }

  &.read .post-swipe {
    margin-left: 0;
  }

  svg {
    vertical-align: middle;
    margin: 0 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    color: var(--darkGrey);
    margin-top: -3px;
  }
`;

Slider.propTypes = {
  /** Numerical value which displays how many points, the swipe is worth */
  points: PropTypes.number,
};

export default Slider;
