// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../../style/colors";

// Config
import breakpoints from "../../../config/breakpoints";

const NotificationListItemSkeleton = (props) => {
  return (
    <div className={componentStyles(props)}>
      <div className="image" />
      <div className="content" />
    </div>
  );
};

const componentStyles = () => css`
  width: 100%;
  border-bottom: 1px solid ${colors.lightGrey};
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 1rem;
  }

  div.image {
    background-color: ${colors.lightGrey};
    width: 3rem;
    height: 3rem;
    display: inline-block;
    border-radius: 50%;
  }

  div.content {
    height: 3rem;
    background-color: ${colors.lightGrey};
    flex: 1;
    margin: 0 1rem;
  }
`;

export default NotificationListItemSkeleton;
