// Libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import * as queryString from "query-string";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import { InfoOutlineIcon, PlusIcon } from "mdi-react";
import ActionWrapper from "../ui/ActionWrapper";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import NewChecklist from "./NewChecklist";
import ChecklistItem from "./ChecklistItem";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";

// Config
import { colors } from "../happyScore/config";
import { feedTypes } from "./config";

// Actions
import { getChecklists, setActiveTab } from "../../actions/checklistActions";
import { showModalPage } from "../../actions/uiActions";

const ChecklistOverview = (props) => {
  // Page for navigation
  const { match } = props;
  // redux state
  const { pages } = useSelector((state) => state.pages);
  const { activeTab } = useSelector((state) => state.checklist);
  const { language: lang } = useSelector((state) => state.language);

  const dispatch = useDispatch();

  // const { registrationId } = match.params;
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  // UI Layout
  const [tabs] = useState([lang.current, lang.finished]);

  let { dataId: registrationId } = queryString.parse(window.location.search);

  // Redux state
  const {
    checklists: completedChecklists,
    error: completedError,
    loading: completedLoading,
  } = useSelector((state) => state.checklist.completed);
  const {
    checklists: uncompletedChecklists,
    error: uncompletedError,
    loading: uncompletedLoading,
  } = useSelector((state) => state.checklist.uncompleted);

  useEffect(() => {
    dispatch(getChecklists({ registrationId: registrationId, feedType: feedTypes.completed }));
    dispatch(getChecklists({ registrationId: registrationId, feedType: feedTypes.uncompleted }));
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showModalPage({
                  title: page.title,
                  content: <NewChecklist />,
                })
              )
            }
          >
            <PlusIcon />
          </ActionWrapper>
        }
      />
      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            dispatch(setActiveTab(tabIndex));
          },
        }))}
      />
      <TabView
        activeTabIndex={activeTab}
        tabs={[
          <div className={componentStyle()}>
            {uncompletedLoading && !uncompletedError && <Spinner />}
            {!uncompletedLoading && uncompletedError && <StatusBox />}
            {!uncompletedLoading && !uncompletedError && uncompletedChecklists.length === 0 && (
              <StatusBox
                style={{ marginBottom: "2rem" }}
                icon={<InfoOutlineIcon />}
                title={lang.noChecklistCurrently}
                content={lang.comeBackSoon}
              />
            )}
            {!uncompletedLoading &&
              uncompletedChecklists.map((checklist) => (
                <ChecklistItem
                  key={`checklist-uncompleted-id-${checklist.id}`}
                  {...checklist}
                  onClick={() => pageNavigatorV2({ path: `${match.url}/${checklist.id}`, direction: "forward" })}
                />
              ))}
          </div>,
          <div className={componentStyle()}>
            {completedLoading && !completedError && <Spinner />}
            {!completedLoading && completedError && <StatusBox />}
            {!completedLoading && !completedError && completedChecklists.length === 0 && (
              <StatusBox
                style={{ marginBottom: "2rem" }}
                icon={<InfoOutlineIcon />}
                title={lang.noFinishedChecklist}
                content={lang.comeBackSoon}
              />
            )}
            {!completedLoading &&
              completedChecklists.map((checklist) => (
                <ChecklistItem
                  key={`checklist-completed-id-${checklist.id}`}
                  {...checklist}
                  completed={true}
                  onClick={() => pageNavigatorV2({ path: `${match.url}/${checklist.id}`, direction: "forward" })}
                />
              ))}
          </div>,
        ]}
      />
    </Page>
  );
};

const componentStyle = () => css`
  padding-top: 1rem;

  div.icon-right-container {
    display: flex;

    svg.checked,
    svg.not-checked {
      margin-right: 0.25rem;
    }

    svg.checked {
      color: var(--green);
    }
    svg.not-checked {
      color: var(--red);
    }
  }
`;

export default ChecklistOverview;
