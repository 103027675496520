import React from "react";
import { css } from "emotion";
import { ErrorOutlineIcon, UploadIcon } from "mdi-react";
import ButtonRounded from "../ButtonRounded";
import colors from "../../../style/colors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { durations } from "../../../config/animations";
import { useEffect } from "react";
import ALLOWED_FILE_TYPES from "../../../config/allowedFileTypes";
import { addToast } from "../../../actions/uiActions";
import useLang from "../../../hooks/useLang";

/**
 * Quick side note about the hacky-ness going on here:
 *
 * Dropzones have a difficulty where once you hover a child of a parent-element with a dropenter/dropleave
 * the dropLeave event fires. This is technically correct but not what we want as we are relying on
 * dropEnter/dropLeave to determine when the UI should show the dropzone as being ready for dropping.
 * A simple solution for this is to show a overlay on the entire dropzone and use the dropleave event from
 * this element instead 🥷
 *
 * (https://stackoverflow.com/a/17052434/7446618)
 */

function createFileInputElement() {
  let element = document.createElement("input");
  element.type = "file";
  element.multiple = true;
  element.accept = "." + ALLOWED_FILE_TYPES.join(",.");
  return element;
}

function DragAndDropArea({ style = {}, onDrop }) {
  const [hasDropableHover, setHasDropableHover] = useState(false);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const dispatch = useDispatch();
  const lang = useLang();

  const [fileElement, setFileElement] = useState(createFileInputElement());

  useEffect(() => {
    fileElement.addEventListener("change", handleFileUploadButton);

    return () => {
      fileElement.removeEventListener("change", handleFileUploadButton);
      fileElement.remove();
    };
  }, []);

  function handleFileUploadButton(e) {
    try {
      [...e.target.files].forEach((file) => onDrop(file));

      fileElement.value = null;
      setFileElement(fileElement);
    } catch (err) {
      // Doesnt really matter. We are try/catch'ing as both select and cancel events
      // will trigger a "change" event
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    setHasDropableHover(false);

    [...e.dataTransfer.items].forEach((dataTransferItem) => {
      const file = dataTransferItem.getAsFile();
      const format = file.name.split(".").slice(-1)[0].toLowerCase();

      if (!ALLOWED_FILE_TYPES.includes(format.toLowerCase())) {
        dispatch(
          addToast({
            title: lang.fileFormat__placeholder__NotPermitted.replace(/{{placeholder}}/gi, format),
            content: `${lang.ignoring} "${file.name}"`,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
      } else {
        onDrop(file);
      }
    });
  }

  function handleDragOver(e) {
    e.preventDefault(); // Prevents browser from opening dropped file in new window/tab
  }

  function handleDragEnter(e) {
    setHasDropableHover(true);
  }
  function handleDragLeave(e) {
    setHasDropableHover(false);
  }

  return (
    <div
      className={componentStyle(primaryColor) + ` ${hasDropableHover ? "has-dropable-hover" : ""}`}
      style={style}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {hasDropableHover && <div className="drop-zone-overlay" onDragLeave={handleDragLeave} />}
      <UploadIcon />
      <p>{lang.dragFilesHereToUpload}</p>
      <ButtonRounded onClick={(e) => fileElement.click()} style={{ margin: "0 auto" }} size="small" secondary={true}>
        {lang.chooseFile}
      </ButtonRounded>
    </div>
  );
}

const componentStyle = (primaryColor) => css`
  color: var(--darkGrey);
  text-align: center;
  padding: 0.8rem;
  border: 3px var(--midGrey);
  border-style: dashed;
  border-radius: 8px;
  transition: border-color ${durations.normal}ms ease;
  position: relative;

  .drop-zone-overlay {
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.has-dropable-hover {
    border-color: ${primaryColor};
    transition: border-color ${durations.fast}ms ease;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  p {
    margin: 0 0 0.45rem 0;
  }
`;

export default DragAndDropArea;
