export function searchAndRankUsers(users, searchTerm) {
  return users
    .filter((user) => filterUser(user, searchTerm))
    .map((user) => rankUser(user, searchTerm))
    .sort((a, b) => {
      if (a.score < b.score) return 1;
      if (a.score > b.score) return -1;
      return 0;
    });
}

function filterUser(entry, searchTerm) {
  searchTerm = searchTerm.toLowerCase();

  if (entry.name.toLowerCase().indexOf(searchTerm) !== -1) return true;
  if (entry.jobTitle && entry.jobTitle.toLowerCase().indexOf(searchTerm) !== -1) return true;
  if (entry.phone && entry.phone.toLowerCase().indexOf(searchTerm) !== -1) return true;
  if (entry.email && entry.email.toLowerCase().indexOf(searchTerm) !== -1) return true;

  return false;
}

const MATCH_IN_USER_NAME = 2.5;
const EXACT_MATCH = 1;
const MATCH_AT_START = 1;

function rankUser(entry, searchTerm) {
  entry.score = 0;

  if (isMatch(entry.name, searchTerm)) entry.score += MATCH_IN_USER_NAME;
  if (isExactMatch(entry.name, searchTerm)) entry.score += EXACT_MATCH;
  if (isMatchAtStartOfString(entry.name, searchTerm)) entry.score += MATCH_AT_START;
  return entry;
}

function isMatch(str = "", searchTerm = "") {
  if (str.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
    return true;
  } else {
    return false;
  }
}

function isExactMatch(str = "", searchTerm = "") {
  let index = str.toLowerCase().indexOf(searchTerm.toLowerCase());

  if (index === -1) return false;

  let isStartEmpty = false;
  let isEndEmpty = false;

  if (index === 0 || str[index - 1] === " ") isStartEmpty = true;
  if (index + searchTerm.length === str.length || str[index + searchTerm.length] === " ") isEndEmpty = true;

  if (isStartEmpty && isEndEmpty) {
    return true;
  } else {
    return false;
  }
}
function isMatchAtStartOfString(str = "", searchTerm = "") {
  let index = str.toLowerCase().indexOf(searchTerm.toLowerCase());
  if (index === 0) {
    return true;
  } else {
    return false;
  }
}
