async function deleteCacheAndRefreshPage() {
  try {
    // Get cache keys
    let keys = await caches.keys();

    // Filter them to only get the runtime cache (this is where the jobPackage will be)
    let workboxCacheKeys = keys.filter((key) => key.match(/workbox-precache/gi));

    if (workboxCacheKeys || workboxCacheKeys.length > 0) {
      await Promise.all(workboxCacheKeys.map((key) => caches.delete(key)));

      // Reload page (with depreacted force option set to true)
      window.location.reload(true);
    }
  } catch (err) {
    // -> Just catching in case this produces errors in non-service-worker contexts (where it obviously isn't needed either)
  }
  return;
}

export default deleteCacheAndRefreshPage;
