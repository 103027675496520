import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// utils
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import req from "../../utilities/request-utility";

// Components
import { ContactsListItemSkeletonGroup } from "../DEPRECATED_contacts/ContactsListItemSkeleton";
import ListItem from "../ui/ListItem";
import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import TopBar from "../ui/TopBar";

// style
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

// Hooks
import useAuthorModal from "../../hooks/useUserModal";
import StatusBox from "../ui/StatusBox";

function ChatGroupUsersPage({ match }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const app = useSelector((s) => s.appConfig);

  useEffect(() => {
    req()(`pages/${match.params.pageId}/users`)
      .then(({ data }) => {
        setUsers(data);
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const authorModal = useAuthorModal();

  function getMiddleTitle(contact) {
    let string = "";
    if (contact.jobTitle) string += contact.jobTitle;

    /** 
    THIS IS IS TEMPORARY SECURITY FIX!! 
    ---
    NRGi apparently uses their employment id as the only identification to access an IT-system with sensitive information
    so we are hiding this info in their apps until this is sorted in their end (i suspect it will stay like this forever)
    
    JensH 4/5-2021
    */
    if ([35, 36, 37, 38, 39, 41, 42].includes(app.appId)) {
      return string;
    } else {
      if (contact.externalId) string += ` (${contact.externalId})`;
      return string;
    }
  }

  return (
    <Page>
      <TopBar useDefaultBackButton={true}></TopBar>

      <ScrollView style={{ padding: "1rem 0 3rem 0" }}>
        {!loading && error && <StatusBox />}

        {users.map((contact) => (
          <ListItem
            style={{ padding: "0.625rem" }}
            maxWidth={breakpoints.lg}
            key={contact.id}
            clickable={true}
            onCLick={() => authorModal(contact.id)}
            onClick={() => authorModal(contact.id)}
            middleTitleStyle={{ fontSize: "0.8125rem", color: colors.black }}
            imageLeft={getProfilePictureFromUserObject(contact, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
            title={contact.name}
            middleTitle={getMiddleTitle(contact)}
            subTitle={`${
              (contact.application ? `${contact.application.title}, ` : "") +
              (contact.userGroup ? contact.userGroup.title : "") +
              (contact.contactPersonTitle ? contact.contactPersonTitle : "") +
              ", " +
              (contact.masterGroup ? contact.masterGroup.title : "") +
              (contact.contactGroup ? contact.contactGroup.groupName : "")
            }`}
          />
        ))}
        {loading && <ContactsListItemSkeletonGroup />}
      </ScrollView>
    </Page>
  );
}

export default ChatGroupUsersPage;
