import React from "react";
import TopBar from "../../../ui/TopBar";
import pageNavigatorV2 from "../../../../utilities/page-navigator-v2";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import Page from "../../../ui/Page";
import ListItem from "../../../ui/ListItem";
import { ChevronRightIcon } from "mdi-react";
import ScrollView from "../../../ui/ScrollView";
import breakpoints from "../../../../config/breakpoints";
import { css } from "emotion";

const RegistrationsSettings = (props) => {
  const { data: registrations, isValidating } = useSWR("admin/registrations", swrFetcher);

  const navigateToRegistrationSettings = (registrationId) => {
    pageNavigatorV2({ path: `${props.match.url}/${registrationId}`, direction: "forward" });
  };

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={"Registreringer"} />
      <ScrollView className={componentStyle}>
        {Array.isArray(registrations) &&
          registrations.map(({ id, title }) => (
            <ListItem
              key={id}
              maxWidth={breakpoints.lg}
              onClick={() => navigateToRegistrationSettings(id)}
              title={title}
              clickable={true}
              iconRight={<ChevronRightIcon />}
            />
          ))}
      </ScrollView>
    </Page>
  );
};

const componentStyle = css`
  width: 100%;
  padding: 1rem 0;
`;

export default RegistrationsSettings;
