import React from "react";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import CustomGroupContent from "./components/CustomGroupContent";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

const CustomGroup = (props) => {
  const page = useCurrentPage({ pageId: props.match.params.pageId });

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <CustomGroupContent groupId={page.dataId} />
    </Page>
  );
};

export default CustomGroup;
