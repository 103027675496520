import {
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  SHOW_DIALOG,
  HIDE_DIALOG,
  SHOW_MODAL_PAGE,
  UPDATE_MODAL_PAGE_CONTENT,
  HIDE_MODAL_PAGE,
  ADD_TOAST,
  SHOW_NEXT_TOAST,
  SHOW_LIGHT_BOX,
  HIDE_LIGHT_BOX,
  CLEAR_CONTEXT_MENU_ACTIONS,
  DEACTIVATE_CURRENT_TOAST,
  CLEAR_DIALOG,
  REPLACE_IDENTICAL_TOAST,
  GET_ICON,
  GET_ICON_SUCCESS,
  GET_ICON_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  contextMenu: {
    active: false,
    actions: [],
    defaultActionTitle: undefined,
    closeOnActionCallback: true,
  },
  Lightbox: {
    active: false,
    images: [],
  },
  modalPages: [],
  modalPage: {
    active: false,
    title: null,
    content: null,
    closeCallback: null,
    actionRight: null,
    useScrollView: true,
    pageStyle: {},
  },
  dialog: {
    active: false,
    actions: [],
    title: null,
    content: null,
    icon: null,
    primaryActionTitle: null,
    secondaryActionTitle: null,
    primaryAction: null,
    styleType: null,
  },
  toasts: [],
  icons: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Context menu
    case SHOW_CONTEXT_MENU:
      // Let you dispatch actions as an array if no options are needed
      if (Array.isArray(action.payload)) {
        action.payload.actions = action.payload;
      }

      return {
        ...state,
        contextMenu: {
          active: true,
          actions: action.payload.actions,
          closeOnActionCallback: action.payload.closeOnActionCallback,
          defaultActionTitle: action.payload.defaultActionTitle || undefined,
        },
      };

    case HIDE_CONTEXT_MENU:
      return {
        ...state,
        contextMenu: {
          ...state.contextMenu,
          active: false,
        },
      };

    case CLEAR_CONTEXT_MENU_ACTIONS:
      return {
        ...state,
        contextMenu: {
          ...state.contextMenu,
          actions: [],
        },
      };

    // Lightbox
    case SHOW_LIGHT_BOX:
      return {
        ...state,
        Lightbox: {
          active: true,
          images: action.payload.images,
          index: action.payload.index || 0,
        },
      };

    case HIDE_LIGHT_BOX:
      return {
        ...state,
        Lightbox: {
          ...state.Lightbox,
          active: false,
        },
      };

    // Modal page
    case SHOW_MODAL_PAGE:
      return {
        ...state,
        modalPages: [
          ...state.modalPages,
          {
            id: action.payload.id,
            active: true,
            title: action.payload.title,
            actionRight: action.payload.actionRight,
            content: action.payload.content,
            closeCallback: action.payload.closeCallback,
            useScrollView: action.payload.useScrollView,
            pageStyle: action.payload.pageStyle,
          },
        ],
      };

    case UPDATE_MODAL_PAGE_CONTENT:
      return {
        ...state,
        modalPages: [
          ...state.modalPages.slice(0, state.modalPages.length - 1),
          {
            ...state.modalPages.slice(-1)[0], // .slice(-1) = returns only the last element of the array, [0] takes only object now left in the array
            ...action.payload,
          },
        ],
      };

    case HIDE_MODAL_PAGE:
      return {
        ...state,
        modalPages: state.modalPages.slice(0, state.modalPages.length - action.payload),
      };

    // Toasts
    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };

    case DEACTIVATE_CURRENT_TOAST:
      return {
        ...state,
        toasts: [{ ...state.toasts.slice(0, 1)[0], active: false }, ...state.toasts.slice(1)],
      };

    case SHOW_NEXT_TOAST:
      return {
        ...state,
        toasts: state.toasts.slice(1),
      };

    case REPLACE_IDENTICAL_TOAST:
      let toasts = [...state.toasts];
      toasts.splice(1, 0, action.payload);
      return {
        ...state,
        toasts: toasts,
      };

    // Dialog
    case SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          active: true,
          ...action.payload,
        },
      };
    case HIDE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          active: false,
        },
      };
    case CLEAR_DIALOG:
      return {
        ...state,
        dialog: {
          active: false,
          actions: [],
          title: null,
          content: null,
          icon: null,
          primaryActionTitle: null,
          secondaryActionTitle: null,
          primaryAction: null,
          styleType: null,
          allowClosing: true,
        },
      };

    // Icons
    case GET_ICON:
    case GET_ICON_FAILURE:
      return {
        ...state,
        icons: {
          ...state.icons,
          [action.payload.id]: undefined,
        },
      };
    case GET_ICON_SUCCESS:
      return {
        ...state,
        icons: {
          ...state.icons,
          [action.payload.name]: action.payload.icon,
        },
      };

    default:
      return state;
  }
}
