// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { v4 as uuidv4 } from "uuid";
import AnimateHeight from "react-animate-height";
import { useDebouncedCallback } from "use-debounce";

// Components
import { AlertOctagonIcon, ChevronUpIcon, CloseIcon } from "mdi-react";
import TextareaInput from "../TextareaInput";
import DropDown from "./components/DropDown";
import DropDownSearchList from "./components/DropDownSearchList";
import ActionWrapper from "../ActionWrapper";
import ButtonRounded from "../ButtonRounded";
import TextInput from "../TextInput";

// Style
import colors from "../../../style/colors";
import sizes from "../../../style/sizes";

// Utilities
import req from "../../../utilities/request-utility";

// Hooks
import { UseLastUsedDropDownValues } from "./hook/UseLastUsedDropDownValues";

// Config
import { durations } from "../../../config/animations";
import styleTypes from "../../../config/styleTypes";
import getTimeRegistrationDataIdentifierQueryParamString from "../../../utilities/get-time-registration-data-identifier-query-param-string";

// Actions
import { showDialog } from "../../../actions/uiActions";
import { updateActiveRequestHandlers } from "../../../actions/registrationActions";

const DropDownFormBuilder = (props) => {
  const dispatch = useDispatch();
  const { language: lang } = useSelector((state) => state.language);
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const [expandedMap, setExpandedMap] = useState({});

  const registration = useSelector((state) => state.registration.form.registration);

  const [values, setValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [lastUsedValues, setLastUsedValues] = UseLastUsedDropDownValues({ key: props.localStorageKey, options });
  const [dropDownValue, setDropDownValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.values) setValues(props.values);
  }, [props.values]);

  useEffect(() => {
    if (props.dataIdentifier) {
      getDataIdentifierOptions();
    }
    if (props.options) {
      setOptions(props.options);
    }
  }, []);

  async function getDataIdentifierOptions() {
    try {
      setLoading(true);
      dispatch(updateActiveRequestHandlers(1));

      let { data } = await req()(
        `registration/${registration.id}/question-data/${
          props.dataIdentifier
        }?${getTimeRegistrationDataIdentifierQueryParamString()}`
      );

      setOptions(data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
      dispatch(updateActiveRequestHandlers(-1));
    }
  }

  // Wow.. what a mess
  // So: We want to check if one of the data-identifiers has changed, and we will make this check for every change to
  // the form. But to not overload the system with checks, the checks are debounced by 300ms (meaning that we wont
  // check more than once for multiple actions within 300ms of each other).
  useEffect(() => {
    debouncedHasDataIdentifierQueryStringChanged();
  }, [registration]);
  let [lastSeenQueryString, setLastSeenQueryString] = useState(getTimeRegistrationDataIdentifierQueryParamString());
  const [debouncedHasDataIdentifierQueryStringChanged] = useDebouncedCallback(() => {
    if (getTimeRegistrationDataIdentifierQueryParamString() !== lastSeenQueryString) {
      setLastSeenQueryString(getTimeRegistrationDataIdentifierQueryParamString);
      getDataIdentifierOptions();
    }
  }, 300);

  function addItem(e) {
    resetDropDownValue();
    setLastUsedValues({ valueId: e.target.value });
    let selectedItem = options.find((o) => o.id == e.target.value) || {};
    const externalOptionId = selectedItem.id;
    const title = selectedItem.name || selectedItem.title || null;

    if (!title) return;

    let newValues = [...values];

    let newValueObj = {
      keyId: uuidv4(),
      externalOptionId,
      title,
      amount: selectedItem?.defaultValue?.value || "",
      isAmountLocked: selectedItem?.defaultValue?.isLocked || false,
      comment: "",
    };
    if (props.config && props.config.SHARE_ANSWER_WITH_OTHERS) newValueObj.isShared = false;

    newValues.unshift(newValueObj);

    setValues(newValues);
    props.onChange(newValues);
  }

  function onDelete(index) {
    dispatch(
      showDialog({
        styleType: styleTypes.neutral,
        title: "Fjern tillæg",
        content: "Er du sikker på at du vil fjerne tillæget?",
        primaryActionTitle: "Ja, fjern tillæg",
        icon: <AlertOctagonIcon />,
        primaryAction: () => {
          let newValues = values.filter((v, i) => index !== i);
          setValues(newValues);
          props.onChange(newValues);
        },
      })
    );
  }

  function onValueChange(e, index) {
    let newValues = [...values];
    if (e.target.name === "isShared") {
      newValues[index][e.target.name] = !newValues[index][e.target.name];
    } else {
      newValues[index][e.target.name] = e.target.value;
    }
    setValues(newValues);
    props.onChange(newValues);
  }

  function resetDropDownValue() {
    setDropDownValue("");
  }

  function handleExpandCollapse(keyId) {
    if (!expandedMap[keyId]) {
      setExpandedMap({ ...expandedMap, [keyId]: true });
    } else {
      setExpandedMap({ ...expandedMap, [keyId]: false });
    }
  }

  return (
    <div style={props.style} className={`${componentStyle(props, primaryColor)} ${props.className || ""}`}>
      <DropDown
        value={dropDownValue}
        onChange={addItem}
        usePlaceholder={true}
        placeholder={"Vælg tillæg"}
        options={options}
        loading={loading}
      />
      {!loading && props.enableSearch && <DropDownSearchList appended={true} onSelect={addItem} options={options} />}
      {!loading && props.showLastUsedValues && lastUsedValues.length > 0 && (
        <div id="last-used-items" className="last-used-items-wrapper">
          {lastUsedValues.map((item) => (
            <ActionWrapper
              key={`last-used-item-drop-down-${item.id}`}
              onClick={() => addItem({ target: { value: item.id } })}
              className="last-used-item"
            >
              {item.title}
            </ActionWrapper>
          ))}
        </div>
      )}

      {values.map(({ keyId, amount, isAmountLocked = false, comment, title, isShared = false }, index) => (
        <div className={`item-component ${index === 0 ? "first" : ""}`} key={keyId}>
          <div className="title-wrapper">
            <ActionWrapper
              className="title"
              onClick={() => handleExpandCollapse(keyId)}
              style={{ maxWidth: "100%", whiteSpace: "normal" }}
            >
              <ChevronUpIcon style={{ transform: `rotate(${expandedMap[keyId] ? "180deg" : "0deg"})` }} />
              <div>{title}</div>
            </ActionWrapper>

            <ButtonRounded secondary={true} size={sizes.small} onClick={() => onDelete(index)}>
              <CloseIcon style={{ width: "1.25rem", height: "1.25rem" }} /> Fjern tillæg
            </ButtonRounded>
          </div>
          <AnimateHeight
            height={!expandedMap[keyId] === true ? "auto" : 0}
            duration={durations.normal}
            animateOpacity={true}
          >
            <div className="content-wrapper">
              <TextInput
                name="amount"
                placeholder="Tast tillæg"
                value={amount}
                disabled={isAmountLocked}
                onChange={(event) => onValueChange(event, index)}
              />
              <label style={{ margin: "0.65rem 0 0.25rem 0", display: "block" }}>{lang.comment}</label>
              <TextareaInput
                name="comment"
                placeholder="Tast kommentar"
                style={{ minHeight: "80px" }}
                value={comment}
                onChange={(event) => onValueChange(event, index)}
              />

              {props.config && props.config.ALLOW_SHARING_ANSWER && (
                <div className="share-with-others-container">
                  <p>Del med ruten</p>
                  <label>
                    <input
                      type="checkbox"
                      name="isShared"
                      checked={isShared}
                      onChange={(event) => onValueChange(event, index)}
                    />
                    Del tillægget med alle fra valgte rute
                  </label>
                </div>
              )}
            </div>
          </AnimateHeight>
        </div>
      ))}
    </div>
  );
};

const componentStyle = (props, primaryColor) => css`
  .last-used-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.35rem;
    margin: 0 -0.5rem;

    .last-used-item {
      display: inline-block;
      color: ${primaryColor};
      padding: 0.35rem 0.7rem;
      margin: 0.1rem 0.1rem;
    }
  }

  .item-component {
    border: 1px solid var(--midGrey);
    border-radius: 3px;
    margin-bottom: 1rem;
    border-left: 3px ${primaryColor} solid;

    &.first {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.65rem;

      button {
        flex-shrink: 0;
      }

      .title {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0.2rem 0;
        margin-right: 0.35rem;

        svg {
          flex-shrink: 0;
          margin-right: 0.35rem;
          vertical-align: bottom;
        }
      }
    }

    .content-wrapper {
      padding: 0 0.65rem 0.65rem 0.65rem;

      .share-with-others-container {
        p {
          margin: 0.65rem 0 0.25rem;
        }

        label {
          display: flex;
          align-items: center;
          color: var(--darkGrey);

          input {
            cursor: pointer;
            height: 1rem;
            width: 1rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
`;

export default DropDownFormBuilder;
