import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timeFromNow from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(customParseFormat);
// plugin get times from date
dayjs.extend(timeFromNow);
// plugin for custom onfig in other plugins
dayjs.extend(updateLocale);
// english version
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});
// danish version
dayjs.updateLocale("da", {
  relativeTime: {
    future: "om %s",
    past: "%s siden",
    s: "få sekunder",
    m: "et minut",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dage",
    M: "en måned",
    MM: "%d månder",
    y: "et år",
    yy: "%d år",
  },
});

/** @name dayjs */
export default dayjs;
