// Libs
import React, { useState } from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { useSelector } from "react-redux";
import AnimateHeight from "react-animate-height";

// Styles
import colors from "../../../style/colors";

// Config
import { durations } from "../../../config/animations";

// Components
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ActionWrapper from "../../ui/ActionWrapper";

const RegistrationSharedAnswer = (props) => {
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  const [extended, setExtended] = useState(false);

  const { user, value } = props.answer;
  return (
    <div className={componentStyle(primaryColor)} style={props.style}>
      <div className="title-wrapper">
        <ActionWrapper className="action-wrapper" onClick={() => setExtended((extended) => !extended)}>
          <div>
            <ChevronUpIcon style={{ transform: `rotate(${extended ? "180deg" : "0deg"})` }} />
            <p>
              {value.title} <label>{value.amount}</label>
            </p>
          </div>
        </ActionWrapper>
        <label className="shared">Delt tillæg</label>
      </div>

      <AnimateHeight
        duration={durations.normal}
        height={extended ? "auto" : 0}
        animateOpacity={true}
        style={{ width: "100%" }}
      >
        <div className="comment-wrapper">
          <label>Kommentar</label>
          <p>{value.comment}</p>
        </div>

        <div>
          <label>Tilføjet af</label>
          <p>{user.name}</p>
        </div>
      </AnimateHeight>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  border: 1px solid var(--midGrey);
  padding: 0.65rem;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .action-wrapper {
      max-width: 100%;
      width: 100%;
      white-space: normal;
      margin-right: 0.35rem;
      padding: 0.2rem 0;
    }

    label.shared {
      white-space: nowrap;
      color: ${primaryColor};
      font-size: 0.95rem;
      border: 1px solid ${tinycolor(primaryColor).setAlpha(0.2).toRgbString()};
      padding: 0.125rem 0.35rem;
      border-radius: 3px;
    }

    div {
      display: flex;

      svg {
        margin-right: 0.35rem;
      }

      p {
        font-size: 1rem;
        color: var(--black);

        label {
          color: var(--darkGrey);
        }
      }
    }
  }

  div.comment-wrapper {
    margin: 0.5rem 0 1rem 0;
  }

  p {
    color: var(--darkGrey);
    font-size: 0.95rem;
  }
`;

export default RegistrationSharedAnswer;
