// Libs
import dayjs from "../../../utilities/dayjs";

export default ({ userObject, language, lang }) => {
  //if user dont have been in the app before
  if (userObject.lastAppVisit === null) {
    return lang.never;
  } else {
    return dayjs(userObject.lastAppVisit).locale(language).fromNow();
  }
};
