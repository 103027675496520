const pageConfig = {
  ////////////////////
  // PHONE BOOK AND KEY PERSONS
  ////////////////////
  PHONEBOOK_LANG_OVERWRITE: "PHONEBOOK_LANG_OVERWRITE",
  KEYPERSONS_LANG_OVERWRITE: "KEYPERSONS_LANG_OVERWRITE",
  HIDE_KEYPERSONS_TAB: "HIDE_KEYPERSONS_TAB",
  HIDE_PHONEBOOK_TAB: "HIDE_PHONEBOOK_TAB",
  GROUP_PHONEBOOK: "GROUP_PHONEBOOK",
  GROUP_KEYPERSONS: "GROUP_KEYPERSONS",
  HIDE_KEYPERSON_GROUP: "HIDE_KEYPERSON_GROUP",
  SHOW_COMPETENCES: "SHOW_COMPETENCES",

  PHONEBOOK_HIDE_EMPLOYEE_ID: "PHONEBOOK_HIDE_EMPLOYEE_ID",
  PHONEBOOK_HIDE_USERGROUP: "PHONEBOOK_HIDE_USERGROUP",

  ////////////////////
  // Birthdays
  ////////////////////
  BIRTHDAYS_SHOW_AGE: "BIRTHDAYS_SHOW_AGE",

  ////////////////////
  // NEWS
  ////////////////////
  NEWS_POSTS_SORT_ORDER: "NEWS_POSTS_SORT_ORDER",
  INCLUDE_FILES_FROM_LINK_COLLECTION: "INCLUDE_FILES_FROM_LINK_COLLECTION",
  READ_ACTION_LANG_OVERWRITE: "READ_ACTION_LANG_OVERWRITE",
  NEWS_POSTS_SWIPEABLE: "NEWS_POSTS_SWIPEABLE",

  ////////////////////
  // SCHEDULE
  ////////////////////
  SHOW_MY_SCHEDULE_TAB: "SHOW_MY_SCHEDULE_TAB",
  SHOW_FREE_SHIFTS_TAB: "SHOW_FREE_SHIFTS_TAB",
  SHOW_PUNCH_CLOCK_TAB: "SHOW_PUNCH_CLOCK_TAB",

  ////////////////////
  // SICK LEAVE
  ////////////////////
  SICKLEAVE_TYPE_OF_END_DATE: "SICKLEAVE_TYPE_OF_END_DATE",
  SICKLEAVE_SHOW_COMMENTS: "SICKLEAVE_SHOW_COMMENTS",

  ////////////////////
  // EQUIPMENT BOOKING
  ////////////////////
  HIDE_START_DATE: "HIDE_START_DATE",
  HIDE_END_DATE: "HIDE_END_DATE",

  ////////////////////
  // NAVIGATION_LIST
  ////////////////////
  LOCKED_NAVIGATION_LIST: "LOCKED_NAVIGATION_LIST",
  NAVIGATION_LIST_SORT_ORDER: "NAVIGATION_LIST_SORT_ORDER",

  ////////////////////
  // GENERAL
  ////////////////////
  NOTIFICATION_TEXT_OVERRIDE: "NOTIFICATION_TEXT_OVERRIDE",
};

export default pageConfig;
