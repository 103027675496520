import React from "react";
import req from "../utilities/request-utility";
import UserProfile from "../components/profile/UserProfile";
import { showModalPage, updateModalPage } from "../actions/uiActions";
import { useDispatch } from "react-redux";

function useAuthorModal() {
  const dispatch = useDispatch();

  return function (authorId, endpointType = null) {
    dispatch(
      showModalPage({
        content: <UserProfile userDataLoading={true} userDataError={false} />,
      })
    );
    // The default endPoint is for users
    if (!endpointType) {
      endpointType = `users/${authorId}`;
    }

    req()(endpointType).then(({ data: userData }) => {
      dispatch(
        updateModalPage({
          content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
        })
      );
    });
  };
}

export default useAuthorModal;
