// Libs
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAsyncDebounce } from "react-table";
import { updateTableGlobalFilter } from "../../../../actions/timeRegistrationActions";

const GlobalSearch = ({ globalFilter, setGlobalFilter }) => {
  // Dispatcher
  const dispatch = useDispatch();

  const [value, setValue] = useState(globalFilter);

  useEffect(() => {
    setValue(globalFilter);
    // eslint-disable-next-line
  }, [globalFilter]);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
    dispatch(updateTableGlobalFilter(value));
  }, 200);

  return (
    <div className="input-container global-search-container">
      <label>Fritekst søgning</label>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="tast for at søge"
      />
    </div>
  );
};

export default GlobalSearch;
