import {
  SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON,
  HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON,
  CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON,
  GET_ACTIVE_THEME_QUIZZES_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  floatingActionButton: {
    active: false,
    quizId: undefined,
    icon: undefined,
    questionId: undefined,
  },
  quizzes: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_THEME_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: action.payload,
      };

    case SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON:
      return {
        ...state,
        floatingActionButton: {
          active: true,
          ...action.payload,
        },
      };

    case HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON:
      return {
        ...state,
        floatingActionButton: {
          ...state.floatingActionButton,
          active: false,
        },
      };

    case CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON:
      return {
        ...state,
        floatingActionButton: {
          active: false,
          quizId: undefined,
          icon: undefined,
          campaignId: undefined,
        },
      };

    default:
      return state;
  }
}
