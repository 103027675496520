export const mergeSkeletonAndFetchedRegistrations = (fetchedRegistrations, skeletonRegistrations) => {
  // We loop through all the dates in the selected / initial time range
  return skeletonRegistrations.map((skeletonRegistration) => {
    // We filter the fetched, saved registrations
    let fetchedRegistration = fetchedRegistrations.find(
      ({ date: fetchedRegistrationDate }) => fetchedRegistrationDate === skeletonRegistration.date
    );

    if (fetchedRegistration) return fetchedRegistration;
    return skeletonRegistration;
  });
};
