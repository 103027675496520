import LOG_ACTIONS from "../../timeRegistrationAdmin/config/logAction";

/**
 *
 * @param {Object} anon
 * @param {Object[]} anon.history
 * @param {Number} anon.currentIndex the index from where in the history array to find the previous dataLogEntry
 */
function getPreviousDataEntryInHistory({ history, currentIndex }) {
  console.log("getPreviousDataEntryInHistory");
  if (currentIndex === 0) return null;

  // Lets create reverse for loop -> first time in my career having to this 😄
  for (let i = currentIndex - 1; i >= 0; i--) {
    let currentEntry = history[i];

    // Check if action is of type "EDITED"
    if (currentEntry?.action === LOG_ACTIONS.CREATED || currentEntry?.action === LOG_ACTIONS.EDITED) return history[i]?.data;
  }

  // No idea what to return here -> Apperently there were no previous entry...
  return null;
}

export default getPreviousDataEntryInHistory;
