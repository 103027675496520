import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Utilities
import hm from "../../../../utilities/highlight-match";
import getProfilePicture from "../../../../utilities/get-profile-picture-from-user-object";
import useUserModal from "../../../../hooks/useUserModal";
import getUserGroupTitle from "../../../../utilities/get-user-group-title";
import highlightMatch from "../../../../utilities/highlight-match";
import iconConverter from "../../../../utilities/icon-converter";

// Components
import ContactMenuOptions from "../../../contacts/components/ContactsMenuOptions";

const SearchResultUser = (props) => {
  const user = props.result ? props.result.data : {};
  const userModal = useUserModal();
  const searchTerm = useSelector((s) => s.searchPage.searchTerm);

  // Using useState and useEffect to control when matched value is shown
  // The matching should only occur when new results are loaded
  // not when the user is typing in the search bar, for a more consistent experience
  const [phoneMatch, setPhoneMatch] = useState(null);
  const [emailMatch, setEmailMatch] = useState(null);
  const [titleMath, setTitleMatch] = useState(null);

  useEffect(() => {
    if (user.phone && searchTerm && user.phone.includes(searchTerm)) {
      const phoneMatch = highlightMatch(user.phone, searchTerm);
      setPhoneMatch(phoneMatch);
    }

    if (user.email && searchTerm && user.email.includes(searchTerm)) {
      const emailMatch = highlightMatch(user.email, searchTerm);
      setEmailMatch(emailMatch);
    }

    if (user.jobTitle && searchTerm && user.jobTitle.includes(searchTerm)) {
      const titleMatch = highlightMatch(user.jobTitle, searchTerm);
      setTitleMatch(titleMatch);
    }
  }, [user]);

  return (
    <div
      key={`div__user-result__${user.id}`}
      className={`list-element ${componentStyles()}`}
      onClick={() => userModal(user.id)}
    >
      {getProfilePicture(user, 40)}
      <div style={{ marginLeft: "0.5rem", flexGrow: 1 }}>
        <p>{hm(user.name, searchTerm)}</p>
        <p className="meta">{getUserGroupTitle(user)}</p>
        <div className={"search-helper"}>
          {phoneMatch && (
            <p className="meta">
              {iconConverter("fa-phone")} {phoneMatch}
            </p>
          )}
          {emailMatch && (
            <p className="meta">
              {iconConverter("fa-envelope-o")} {emailMatch}
            </p>
          )}
          {titleMath && (
            <p className="meta">
              {iconConverter("laptop")} {titleMath}
            </p>
          )}
        </div>
      </div>

      <ContactMenuOptions {...user} />
    </div>
  );
};

const componentStyles = (props) => css`
  display: flex;
  align-items: center;

  .search-helper {
    p {
      display: flex;
      align-items: center;

      svg {
        width: 0.8125rem;
        height: 0.8125rem;
        margin-right: 0.25rem;
      }
    }
  }
`;

export default SearchResultUser;
