import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import colors from "../../style/colors";
import { InfoOutlineIcon, DownloadIcon, FeatureSearchOutlineIcon } from "mdi-react";

import breakpoints from "../../config/breakpoints";
import ListItem from "../ui/ListItem";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import highlightMatch from "../../utilities/highlight-match";
import req from "../../utilities/request-utility";
import { useDebouncedCallback } from "use-debounce";

function LinkCollectionOverview(props) {
  // page setup
  const { match } = props;
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { pages } = useSelector((state) => state.pages);
  const lang = useSelector((state) => state.language.language);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchterm, setSearchterm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [documents, setDocuments] = useState([]);

  // Data
  useEffect(() => {
    req()(`/link-collections/${page.dataId}`).then(({ data }) => {
      setDocuments(data);
      setLoading(false);
      setError(false);
    });
  }, []);

  useEffect(() => {
    filterPostsDebounce();
  }, [searchterm, documents]);

  const [filterPostsDebounce] = useDebouncedCallback(() => {
    setFilteredPosts(
      // This logic *could* be moved to a hook, but it's not used anywhere else,
      // and it will never be used anywhere else, so it's not worth it.
      // This component is a deprecated feature (replaced by newsFileArchive) that only 3 customers
      // have, so it's not worth spending a lot of time on it.
      removeDuplicates([
        ...filterByDocumentTitle(searchterm, documents),
        ...filterAndSortBySearchWords(searchterm, documents),
      ])
    );
  }, 250);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />

      <ScrollView style={{ paddingTop: "4.45rem", paddingBottom: "5rem" }}>
        {/* Loading */}
        {loading && !error && <InlineSpinner style={{ margin: "2rem 0" }} />}

        {/* End of feed without posts */}
        {documents.length === 0 && !loading && !error && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {filteredPosts.length === 0 && documents.length > 0 && searchterm && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
            style={{ margin: "1rem auto" }}
          />
        )}

        {/* Content */}
        {filteredPosts.map((file) => (
          <ListItem
            key={file.id}
            onClick={() => window.open(`${file.baseURL}${file.file}`)}
            title={
              <span style={{ color: primaryColor, fontWeight: 700 }}>
                {highlightMatch(file.title, searchterm, "JSX", {
                  display: "inline-block",
                  borderBottom: `1px var(--darkGrey) dashed`,
                  backgroundColor: colors.yellowLight,
                  borderRadius: "3px",
                })}
              </span>
            }
            subTitle={file.file}
            maxWidth={breakpoints.lg}
            clickable={true}
            iconLeft={<DownloadIcon style={{ color: primaryColor }} />}
          />
        ))}
      </ScrollView>
      <FloatingSearchInput
        onClearSearch={() => setSearchterm("")}
        onChange={(e) => setSearchterm(e.target.value)}
        value={searchterm}
      />
    </Page>
  );
}

function filterByDocumentTitle(searchterm, documents) {
  return documents.filter((document) => document.title.toLowerCase().includes(searchterm.toLowerCase()));
}

function removeDuplicates(array) {
  return array.filter((a, b) => array.indexOf(a) === b);
}

// Example for search words:
// Document 1 -> "banana apple strawberry"
// Document 2 -> "apple strawberry"
// Document 3 -> "banana strawberry"
// The search, "strawberry" should return all three documents
// The search, "apple strawberry" also should return document 1, 2 and 3,
// but document 1 and 2 should be sorted higher than document 3
function filterAndSortBySearchWords(searchterm, documents) {
  const searchWords = searchterm.split(" ");
  const filteredDocuments = documents.filter((document) => {
    const documentWords = document.searchWords.split(" ");
    return searchWords.every((searchWord) =>
      documentWords.some((documentWord) => documentWord.toLowerCase().includes(searchWord.toLowerCase()))
    );
  });

  return filteredDocuments.sort((a, b) => {
    const aWords = a.searchWords.split(" ");
    const bWords = b.searchWords.split(" ");
    const aMatches = aWords.filter((aWord) =>
      searchWords.some((searchWord) => aWord.toLowerCase().includes(searchWord.toLowerCase()))
    );
    const bMatches = bWords.filter((bWord) =>
      searchWords.some((searchWord) => bWord.toLowerCase().includes(searchWord.toLowerCase()))
    );
    return bMatches.length - aMatches.length;
  });
}

export default LinkCollectionOverview;
