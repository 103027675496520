export default changePageSortOrder;

function changePageSortOrder({ fromPageId, toPageId, pages, currentParentPageId = undefined }) {
  let newPages = [...pages];

  if (currentParentPageId === undefined || newPages[0].parentId === currentParentPageId) {
    // Assign every page a new sortOrder
    for (let i = 0; i < newPages.length; i++) {
      newPages[i].sortOrder = i;
    }

    let fromIndex = newPages.findIndex((page) => page.id === fromPageId);
    let toIndex = newPages.findIndex((page) => page.id === toPageId);

    let elementToBeMoved = newPages.splice(fromIndex, 1)[0];
    newPages.splice(toIndex, 0, elementToBeMoved);

    for (let i = 0; i < newPages.length; i++) {
      newPages[i].sortOrder = i;
      newPages[i].flags.modified = true;
    }
  } else {
    for (let page of newPages) {
      if (Array.isArray(page.pages) && page.pages.length > 0) {
        page.pages = changePageSortOrder({ fromPageId, toPageId, pages: page.pages, currentParentPageId });
      }
    }
  }

  return newPages;
}
