import React, { useState } from "react";
import { css } from "emotion";
import { hideModalPage, showModalPage } from "../../../../../../../actions/uiActions";
import PageBuilderTree from "../../../../modal/pageBuilderTree/PageBuilderTree";
import { useDispatch } from "react-redux";
import { ClearIcon } from "mdi-react";

const CloneNavigationSelector = (props) => {
  const dispatch = useDispatch();

  const [selectedPageToClone, setSelectedPageToClone] = useState(null);

  const viewPageSelection = () => {
    dispatch(
      showModalPage({
        title: "Vælg mappemodul at klone",
        content: (
          <PageBuilderTree
            pageClickCallback={(page) => {
              setSelectedPageToClone(page);
              props.onChange(page.id);
              dispatch(hideModalPage());
            }}
          />
        ),
      })
    );
  };

  const clear = () => {
    props.onChange(null);
    setSelectedPageToClone(null);
  };

  return (
    <div className={componentStyle()}>
      <button onClick={viewPageSelection}>
        <span>{selectedPageToClone ? `Kloner modul: ${selectedPageToClone.title}` : "Vælg mappemodul"}</span>
      </button>
      {selectedPageToClone && <ClearIcon onClick={clear} />}
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  border: 1px solid var(--midGrey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 2.5rem;

  &:hover {
    background-color: var(--ultraLightGrey);
  }

  button {
    width: 100%;
    text-align: start;
    padding: 0.75rem 0 0.75rem 1rem;
    font-size: 1rem;
    color: var(--darkGrey);
    background-color: inherit;
    border-radius: 3px;
    cursor: inherit;
    border: none;

    &:hover {
      background-color: inherit;
    }
  }

  svg {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    cursor: inherit;

    &:active {
      border: 1px solid var(--ultraLightGrey);
    }
  }
`;

export default CloneNavigationSelector;
