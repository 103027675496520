import React, { useEffect, useState } from "react";

// Utilities
import splitIdentifier from "./utilities/split-identifier";
import mergeIdentifier from "./utilities/merge-identifier";

// Components
import { HelpCircleIcon, QuestionMarkCircleIcon } from "mdi-react";
import { getIcon } from "../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";

const DynamicIcon = ({ icon, ...rest }) => {
  const dispatch = useDispatch();

  const icons = useSelector((state) => state.ui.icons);
  const [iconName, setIconName] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!icon || iconName === icon) return;

    const { prefix, name } = splitIdentifier(icon);
    const identifier = mergeIdentifier(prefix, name);

    if (icons[identifier] && !icons[identifier].error) {
      setIconName(icon);
      setData(icons[identifier]);
    } else {
      setData(null);
    }
  }, [icons, icon]);

  useEffect(() => {
    if (!icon || iconName === icon) return;

    const { prefix, name } = splitIdentifier(icon);

    dispatch(getIcon(prefix, name));
  }, [icon]);

  return (
    <>
      {!data && <QuestionMarkCircleIcon {...rest} />}
      {data && (
        <svg
          fill="currentColor"
          dangerouslySetInnerHTML={{ __html: data.body }}
          className={`base-icon ${rest.className || ""}`}
          viewBox={rest.viewBox || data.viewBox || "0 0 24 24"}
          {...rest}
        ></svg>
      )}
    </>
  );
};
export default DynamicIcon;
