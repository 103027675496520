// Libs
import React from "react";
import { useDispatch } from "react-redux";

// Icons
import { TrashCanOutlineIcon } from "mdi-react";

// Actions
import { showDialog } from "../../../actions/uiActions";
import { getTimeSheets } from "../../../actions/timeRegistrationActions";

// config
import styleTypes from "../../../config/styleTypes";

// Utilities
import req from "../../../utilities/request-utility";

export default function UseHandleDelete() {
  const dispatch = useDispatch();

  const handleDelete = ({ value, timeRegistrationId, callback = () => {} }) => {
    dispatch(
      showDialog({
        title: "Er du sikker på at du vil slette registreringen?",
        content: "Hvis du sletter registreringen kan det ikke gøres om!",
        icon: <TrashCanOutlineIcon />,
        styleType: styleTypes.error,
        primaryAction: () => {
          req()
            .delete(`time-registrations/${timeRegistrationId}/time-sheets/${value}`)
            .then(() => dispatch(getTimeSheets({ timeRegistrationId })))
            .catch(() => dispatch(addToast({ template: "error" })));
          callback();
        },
        primaryActionTitle: "Slet registreringen",
      })
    );
  };

  return handleDelete;
}
