// Libs
import React from "react";
import { connect, useSelector } from "react-redux";
import { InfoOutlineIcon } from "mdi-react";
import { bindActionCreators } from "redux";

// Components
import RegistrationPost from "./components/RegistrationPost";
import StatusBox from "../ui/StatusBox";
import InlineSpinner from "../ui/InlineSpinner";

// Utilities and config
import { feedTypes } from "./config";

// Actions
import { showModalPage, updateModalPage } from "../../actions/uiActions";

const RegistrationPosts = (props) => {
  const feedType = props.feedType || feedTypes.all;

  // Redux states
  const lang = useSelector((state) => state.language.language);
  const { loading, posts, endOfFeed, error, currentTab, tabs } = useSelector((state) => state.registration[feedType]);

  // Assign posts based on feedType
  let feedPosts;
  if (feedType === feedTypes.processflow) {
    feedPosts = tabs[currentTab.tab].posts;
  } else {
    feedPosts = posts;
  }

  return (
    <div style={{ paddingTop: "1rem" }} className={props.className}>
      {/* Content */}
      {feedPosts.map((p) => (
        <RegistrationPost key={p.id} {...props} post={p} feedType={feedType} />
      ))}

      {/* Loading */}
      {loading && !error && !endOfFeed && (
        <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
      )}

      {/* End of feed with posts */}
      {feedPosts.length > 0 && endOfFeed && (
        <StatusBox
          style={{ marginBottom: "4rem", marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noMorePosts}
          content={lang.reachedEndOfFeed}
        />
      )}

      {/* End of feed without posts */}
      {feedPosts.length === 0 && endOfFeed && (
        <StatusBox
          style={{ marginBottom: "4rem", marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noPostsHere}
          content={lang.comeBackSoon}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationPosts);
