import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import { css } from "emotion";
import HighscoreUserListContainer from "../../../highscore/components/HighscoreUserListContainer";
import breakpoints from "../../../../config/breakpoints";

function RegistrationTippingHighscore({ registrationId }) {
  const { data: scores, isLoading } = useSWR(`/registration/${registrationId}/tippingHighscore`, swrFetcher);

  return (
    <div className={style()}>
      <h1 className="title">EM Highscore</h1>
      <HighscoreUserListContainer loading={isLoading} scores={scores} />
    </div>
  );
}

const style = () => css`
  max-width: ${breakpoints.lg}px;

  width: 100%;
  margin: 0 auto;

  > .title {
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

export default RegistrationTippingHighscore;
