import faIconConverter from "./fa-to-mdi-converter";
import mdiIconConverter from "./mdi-string-to-mdi-icon-converter";
import customIconConverter from "./custom-string-to-custom-icon-converter";

export default (iconString = "", styles = null) => {
  /**
   * Delegator function to determine wether an icon-string comes from Font-awesome or material design icons
   * ---------------
   * @param iconString [string] string describing the icon. If the first to characters of the string is "fa"
   *                            it will be treated a font-awesome and converted to a supported mdi-icon
   * @return [reactComponent] or [null]
   */

  if (iconString && iconString.substring(0, 2).toLowerCase() === "fa") {
    return faIconConverter(iconString, styles);
  } else if (iconString && iconString.substring(0, 7).toLowerCase() === "custom-") {
    return customIconConverter(iconString, styles);
  } else {
    return mdiIconConverter(iconString, styles);
  }
};
