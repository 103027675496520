// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import ScrollView from "../ui/ScrollView";
import ReactTable from "./components/table/ReactTable";

// Actions
import { getPermissions, getTimeSheets, resetAll, updateTableFilters } from "../../actions/timeRegistrationActions";

// Utilities
import mapTimeSheetsToTableStructure from "./utilities/mapTimeSheetsToTableStructure";
import getDateInterval from "../timeRegistration/utilities/getDateInterval";
import getUserLocale from "../../utilities/get-user-locale";
import { subWeeks } from "date-fns";

// Context
import { TimeRegistrationAdminContext } from "./context/TimeRegistrationAdminContext";

const TimeRegistrationTable = (props) => {
  const dispatch = useDispatch();

  const { loading, timeSheets, timeSheetsNotFound } = useSelector((state) => state.timeRegistration.table);
  const { language: lang } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state.auth);

  let timeRegistrationId = queryString.parse(window.location.search).dataId;
  const [mappedTimeSheets, setMappedTimeSheets] = useState({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    (function initialSetup() {
      // Get date last week
      const lastWeek = subWeeks(new Date(), 1);

      let { startDate, endDate } = getDateInterval({ date: lastWeek, locale: getUserLocale(user) });

      dispatch(resetAll());
      dispatch(getPermissions({ timeRegistrationId }));
      dispatch(
        updateTableFilters({
          filters: { startDate: parseInt(startDate, 10), endDate: parseInt(endDate, 10) },
          callback: () => {
            dispatch(getTimeSheets({ timeRegistrationId }));
          },
        })
      );
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { rows, columns } = mapTimeSheetsToTableStructure(timeSheets);

    setMappedTimeSheets({ rows, columns });
  }, [timeSheets]);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={lang.timeRegistration} />
      <ScrollView>
        {loading && timeSheets.length === 0 ? (
          <InlineSpinner style={{ margin: "2rem 0" }} />
        ) : (
          <TimeRegistrationAdminContext.Provider value={{ pageId: props?.match.params.pageId }}>
            <ReactTable
              rows={mappedTimeSheets.rows}
              columns={mappedTimeSheets.columns}
              isDataNotFound={timeSheetsNotFound}
              loading={loading}
            />
          </TimeRegistrationAdminContext.Provider>
        )}
      </ScrollView>
    </Page>
  );
};

export default TimeRegistrationTable;
