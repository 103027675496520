// ActionTypes
import {
  ATTEND_EVENT,
  ATTEND_EVENT_FAILURE,
  ATTEND_EVENT_SUCCESS,
  DELETE_ATTEND_EVENT,
  DELETE_ATTEND_EVENT_FAILURE,
  DELETE_ATTEND_EVENT_SUCCESS,
  END_OF_EVENTS,
  GET_EVENT,
  GET_EVENT_ATTENDEES,
  GET_EVENT_ATTENDEES_FAILURE,
  GET_EVENT_ATTENDEES_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENTS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  RESET_EVENT,
  RESET_EVENTS,
} from "../actions/actionTypes";

const initialState = {
  selectedEvent: null,
  error: false,
  loading: false,
  all: {
    events: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  attending: {
    events: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  loadingAttendees: false,
  errorAttendees: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DELETE_ATTEND_EVENT:
    case ATTEND_EVENT:
    case GET_EVENT:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ATTEND_EVENT_SUCCESS:
    case ATTEND_EVENT_SUCCESS:
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        selectedEvent: action.payload.event,
        loading: false,
      };

    case ATTEND_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case DELETE_ATTEND_EVENT_FAILURE:
    case GET_EVENT_FAILURE:
      return {
        ...state,
        selectedEvent: null,
        loading: false,
        error: true,
      };

    case GET_EVENTS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          loading: true,
          error: false,
        },
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          loading: false,
          error: false,
          events: [...state[action.payload.eventType].events, ...action.payload.events],
        },
      };

    case GET_EVENTS_FAILURE:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          loading: false,
          error: true,
        },
      };

    case GET_EVENT_ATTENDEES:
      return {
        ...state,
        loadingAttendees: true,
      };

    case GET_EVENT_ATTENDEES_SUCCESS:
      return {
        ...state,
        selectedEvent: Object.assign({ ...state.selectedEvent }, { attendees: [...action.payload.attendees] }),
        loadingAttendees: false,
        errorAttendees: false,
      };

    case GET_EVENT_ATTENDEES_FAILURE:
      return {
        ...state,
        loadingAttendees: false,
        errorAttendees: true,
      };

    case END_OF_EVENTS:
      return {
        ...state,
        [action.payload.eventType]: {
          ...state[action.payload.eventType],
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case RESET_EVENT:
      return {
        ...state,
        selectedEvent: null,
        error: false,
        loading: false,
        loadingAttendees: false,
        errorAttendees: false,
      };

    case RESET_EVENTS:
      return {
        ...state,
        all: {
          events: [],
          loading: false,
          endOfFeed: false,
          error: false,
        },
        attending: {
          events: [],
          loading: false,
          endOfFeed: false,
          error: false,
        },
      };

    default:
      return state;
  }
}
