/**
 * Split an identifier into its parts.
 * @param identifier - The identifier of the icon, eg. "account", "mdi:account" or "custom:hp-byg".
 * @param def {string} - The default prefix to use if none is specified.
 * @return {{prefix: string, name: string}}
 */
const splitIdentifier = (identifier, def = "mdi") => {
  let prefix = def;
  let name = identifier;

  if (identifier.includes(":")) {
    [prefix, name] = identifier.split(":");
  }

  return {
    prefix: prefix,
    name: name,
  };
};

export default splitIdentifier;
