export const GET_INITIAL_DATA = "GET_INITIAL_DATA";
export const GET_INITIAL_DATA_SUCCESS = "GET_INITIAL_DATA_SUCCESS";
export const GET_INITIAL_DATA_FAILURE = "GET_INITIAL_DATA_FAILURE";

export const SET_INITIAL_TARGET_GROUP = "SET_INITIAL_TARGET_GROUP";

export const SHOW_TARGET_GROUP_PANEL = "SHOW_TARGET_GROUP_PANEL";
export const HIDE_TARGET_GROUP_PANEL = "HIDE_TARGET_GROUP_PANEL";
export const SET_SELECTION_PANEL_ACTIVE_TAB_INDEX = "SET_SELECTION_PANEL_ACTIVE_TAB_INDEX";
export const SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX = "SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX";

export const ADD_TO_TARGET_GROUP = "ADD_TO_TARGET_GROUP";
export const REMOVE_FROM_TARGET_GROUP = "REMOVE_FROM_TARGET_GROUP";
export const ADD_ALL_TO_TARGET_GROUP = "ADD_ALL_TO_TARGET_GROUP";
export const REMOVE_ALL_FROM_TARGET_GROUP = "REMOVE_ALL_FROM_TARGET_GROUP";
export const RESET_TARGET_GROUP = "RESET_TARGET_GROUP";
