export const formatPageBuilderPages = (pages) => {
  return pages.map(
    ({
      id,
      icon,
      active,
      applicationId,
      noTargetGroup,
      startDate,
      endDate,
      module,
      moduleGroup,
      dataId,
      showAtFrontPage,
      parentId,
      sortOrder,
      coverImage,
      title,
      title_fl,
      description,
      description_fl,
      targetGroup,
      meta,
      config,
      pages,
    }) => {
      let page = {
        id,
        icon,
        active,
        applicationId,
        noTargetGroup,
        startDate,
        endDate,
        module,
        moduleGroup,
        dataId,
        showAtFrontPage,
        parentId,
        sortOrder,
        coverImage,
        title,
        title_fl,
        description,
        description_fl,
        targetGroup,
        meta,
        config,
        flags: {
          modified: false,
        },
      };

      if (Array.isArray(pages) && pages.length > 0) page.pages = formatPageBuilderPages(pages);

      return page;
    }
  );
};
