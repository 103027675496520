// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadIcon,
  ErrorOutlineIcon,
  PencilIcon,
  ContentCopyIcon,
  TrashCanOutlineIcon,
  AlertOctagramIcon,
} from "mdi-react";

// Actions
import { showModalPage, addToast, showDialog } from "../../../actions/uiActions";
import { deactivatePost } from "../../../actions/registrationActions";

// Components
import RegistrationFormBuilder from "../RegistrationFormBuilder";

// Utilities and config
import { formBuilderModes } from "../config";
import useLang from "../../../hooks/useLang";
import styleTypes from "../../../config/styleTypes";

const useRegistrationContextMenu = (props) => {
  const { page, post, registrationConfig, feedType } = props;

  const dispatch = useDispatch();
  const user = useSelector((s) => s.auth.user);
  const lang = useLang();
  const [contextMenu, setContextMenu] = useState([]);

  useEffect(() => {
    setContextMenu(createContextMenuItems());
  }, []);

  function createContextMenuItems() {
    let contextMenuItems = [];
    if (registrationConfig.allowPDFExport && post.pdfGeneratorURL) {
      function navigateToPDF(url) {
        if (!url) return;
        window.open(url, "_blank").focus();
      }

      contextMenuItems.push({
        icon: <DownloadIcon />,
        title: lang.downloadAsPdf,
        callback: () => navigateToPDF(post.pdfGeneratorURL ? post.pdfGeneratorURL : null),
      });
    }

    contextMenuItems.push({
      icon: <ContentCopyIcon />,
      title: lang.duplicate,
      callback: () =>
        dispatch(
          showModalPage({
            title: page.title,
            content: <RegistrationFormBuilder {...props} formMode={formBuilderModes.duplicate} post={post} />,
          })
        ),
    });

    if ((post.author && post.author.id === user.id) || user.admin || post.canEdit) {
      contextMenuItems.push({
        icon: <PencilIcon />,
        title: lang.edit,
        callback: () => {
          dispatch(
            showModalPage({
              title: page.title,
              content: <RegistrationFormBuilder {...props} formMode={formBuilderModes.edit} post={post} />,
            })
          );

          dispatch(
            addToast({
              title: `${lang.attention}!`,
              content: lang.previouslyAnsweredRegistrationQuestionsNotVisible,
              icon: <ErrorOutlineIcon />,
              styleType: styleTypes.neutral,
              duration: 20000,
            })
          );
        },
      });
    }

    if (user.admin) {
      contextMenuItems.push({
        icon: <TrashCanOutlineIcon />,
        title: lang.deactivate,
        callback: () =>
          dispatch(
            showDialog({
              title: `${lang.deactivate} ${lang.post}`,
              content: lang.deactivatePostInfo,
              primaryActionTitle: lang.deactivatePostConfirm,
              primaryAction: () => dispatch(deactivatePost({ postId: post.id, registrationId: page.dataId, feedType })),
              secondaryActionTitle: lang.deactivatePostCancel,
              styleType: styleTypes.error,
              icon: <AlertOctagramIcon />,
            })
          ),
      });
    }
    return contextMenuItems;
  }

  return contextMenu;
};

export default useRegistrationContextMenu;
