// Libs
import React, { useContext } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import { HouseIcon, ChevronRightIcon } from "mdi-react";

// Hooks
import useCurrentPagePath from "../hooks/useCurrentPagePath";

// Config
import breakpoints from "../../../config/breakpoints";

// Styles
import colors from "../../../style/colors";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import getAppName from "../../../utilities/get-app-name";
import { PageBuilderContext } from "../context/PageBuilderContext";
import tinycolor from "tinycolor2";

const PagePath = (props) => {
  const { currentPageId = undefined } = props;

  const { moduleId } = useContext(PageBuilderContext);

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  const { pagesPath } = useCurrentPagePath({ pageId: currentPageId });

  const navigateToSubPage = (pageId) => {
    pageNavigatorV2({
      path: `/${getAppName()}/page-builder/${moduleId}/parentPages/${pageId}`,
      direction: "backward",
    });
  };

  const navigateToHome = () => {
    pageNavigatorV2({
      path: `/${getAppName()}/page-builder/${moduleId}`,
      direction: "backward",
    });
  };

  return (
    <div className={`${componentStyle(primaryColor)} ${props.className ? props.className : ""}`}>
      <div className="paths-wrapper">
        <button className="nav-button" onClick={navigateToHome}>
          <a>Hjem </a>
          <HouseIcon className="home" />
        </button>
        {pagesPath.map((page) => (
          <div className="path" key={page.id}>
            <ChevronRightIcon className="right-arrow" />
            {currentPageId === page.id ? (
              <p>{page.title}</p>
            ) : (
              <a onClick={() => navigateToSubPage(page.id)}>{page.title}</a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    margin-right: 0.25rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      transition: background-color 180ms ease;
      color: var(--primary-color_lighten-5);
    }

    &:active {
      transition: background-color 180ms ease;
      color: var(--primary-color_darken-5);
    }
  }

  button.nav-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: ${colors.lightGrey};
    margin-right: 0.125rem;
  }

  .paths-wrapper {
    width: 100%;
    max-width: ${breakpoints.lg}px;
    display: flex;
    align-items: center;

    svg.home {
      height: 1.25rem;
      width: 1.25rem;
      fill: var(--primary-color);
    }

    p {
      color: var(--primary-color);
    }

    .path {
      display: flex;
      align-items: center;

      svg.right-arrow {
        height: 1.25rem;
        width: 1.25rem;
        fill: var(--primary-color);
        margin-right: 0.125rem;
      }
    }
  }
`;

export default PagePath;
