// Libs
import React from "react";
import { css } from "emotion";

// Components
import { ChevronDownIcon, DragIcon } from "mdi-react";

// Styles
import colors from "../../../../../style/colors";

const PageListItemSkeleton = ({ index = 1 }) => {
  return (
    <div className={componentStyle()}>
      <div>
        <DragIcon className="drag-icon" />
      </div>
      <div className="fake-title-container" style={{ animationDelay: `${index * 10}ms` }}>
        <div className="fake-title header">
          <div className="tile tile-1" />
        </div>
        <div className="fake-title sub-header" style={{ animationDelay: `${index * 10}ms` }}>
          <div className="tile tile-1" />
        </div>
      </div>
      <ChevronDownIcon />
    </div>
  );
};

const GroupedPageListItemSkeleton = () => {
  return (
    <>
      <PageListItemSkeleton index={1} />
      <PageListItemSkeleton index={2} />
      <PageListItemSkeleton index={3} />
      <PageListItemSkeleton index={4} />
      <PageListItemSkeleton index={5} />
      <PageListItemSkeleton index={6} />
      <PageListItemSkeleton index={7} />
      <PageListItemSkeleton index={8} />
      <PageListItemSkeleton index={9} />
      <PageListItemSkeleton index={10} />
      <PageListItemSkeleton index={11} />
      <PageListItemSkeleton index={12} />
      <PageListItemSkeleton index={13} />
      <PageListItemSkeleton index={14} />
      <PageListItemSkeleton index={15} />
      <PageListItemSkeleton index={16} />
    </>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  margin: -1px auto 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.65rem 0.5rem 0.65rem 0.65rem;
  background-color: var(--white);
  border: 1px solid var(--midGrey);

  svg.drag-icon {
    height: 1.75rem;
    width: 1.75rem;
    color: var(--darkGrey);
  }

  .fake-title-container {
    flex: 1;
    margin-left: 0.5rem;
    padding: 0.55rem 0;

    .fake-title {
      display: flex;
      opacity: 0.2;
      animation: blink 1.8s infinite;

      .tile {
        background-color: var(--darkGrey);
        border-radius: 4px;
        margin-right: 0.45rem;
      }

      &.header {
        margin-bottom: 0.9rem;

        .tile-1 {
          width: 100%;
          max-width: 300px;
          height: 7px;
        }
      }

      &.sub-header {
        .tile-1 {
          width: 250px;
          height: 7px;
        }
      }
    }
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export { PageListItemSkeleton, GroupedPageListItemSkeleton };
