import React from "react";
import TopBar from "../../../ui/TopBar";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import Page from "../../../ui/Page";
import { useForm } from "react-hook-form";
import { formatRegistrationToForm, registrationValidationSchema } from "./RegistrationSettings.config";
import TextInput from "../../../ui/TextInput";
import Button from "../../../ui/Button";
import ScrollView from "../../../ui/ScrollView";
import breakpoints from "../../../../config/breakpoints";
import { css } from "emotion";
import useApplicationLanguages from "../../../../hooks/useApplicationLanguages";
import RadioGroup from "../../../ui/RadioGroup";
import useSubmitRegistrationSettings from "./hooks/UseSubmitRegistrationSettings";
import { zodResolver } from "@hookform/resolvers/zod";
import Spinner from "../../../ui/InlineSpinner";
import StatusBox from "../../../ui/StatusBox";

import TextEditor from "../../../ui/TextEditor";
import InformationBox from "../../../ui/InformationBox";
import QuestionAsTitle from "./question-as-title/QuestionAsTitle";

const RegistrationSettings = (props) => {
  const registrationId = props.registrationId ?? props.match.params.registrationId;
  const showAsModal = props.showAsModal ?? false;

  const { applicationLanguages } = useApplicationLanguages();

  const { updateChanges, loading: submitLoading } = useSubmitRegistrationSettings({ registrationId, showAsModal });

  const {
    data: registration,
    isValidating = true,
    isLoading = true,
    error,
  } = useSWR(`admin/registrations/${registrationId}/settings`, swrFetcher, {
    revalidateOnFocus: false,
    onSuccess: (data) => {
      reset(formatRegistrationToForm(data));
    },
  });

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: zodResolver(registrationValidationSchema),
  });

  const [description = "", description_fl = "", autoPublish = false, questionAsAnswerTitleId = null] = watch([
    "description",
    "description_fl",
    "autoPublish",
    "questionAsAnswerTitleId",
  ]);

  let loading = isValidating || isLoading;

  const settings = (
    <ScrollView className={componentStyle()}>
      {error && <StatusBox style={{ marginTop: "2rem" }} />}
      {!error && loading && <Spinner style={{ marginTop: "2rem" }} title={"Henter indstillinger..."} active={loading} />}
      {!error && !loading && (
        <form id={"registration-settings-form"}>
          <InformationBox className={"information-box"} title={"Obs!"}>
            Vær opmærksom på at ændringer påvirker alle moduler som peger på denne registrering
          </InformationBox>

          <p className="title">Titel</p>
          <div className="field">
            {applicationLanguages?.primary?.name && (
              <label htmlFor="title">{`${applicationLanguages?.primary?.name || ""} *`}</label>
            )}
            <TextInput id="title" className={errors?.title?.message ? "error" : ""} register={register("title")} />
            <label htmlFor="title" name="title" className="error-message">
              {errors?.title?.message || ""}
            </label>
          </div>

          {applicationLanguages?.foreign?.name && (
            <div className="field" style={{ marginTop: "0.25rem" }}>
              <label htmlFor="title_fl">{applicationLanguages?.foreign?.name}</label>
              <TextInput id="title_fl" name="title_fl" register={register("title_fl")} />
            </div>
          )}

          <p className="title">Beskrivelse</p>
          <div className="field">
            {applicationLanguages?.primary?.name && (
              <label htmlFor="description">{`${applicationLanguages?.primary?.name || ""}`}</label>
            )}
            <TextEditor
              id="description"
              name="description"
              style={{ backgroundColor: "var(--white)" }}
              value={description}
              onChange={(value) => {
                setValue("description", value);
              }}
            />
          </div>

          {applicationLanguages?.foreign?.name && (
            <div className="field" style={{ marginTop: "0.25rem" }}>
              <label htmlFor="description_fl">{applicationLanguages?.foreign?.name}</label>
              <TextEditor
                id="description_fl"
                style={{ backgroundColor: "var(--white)" }}
                value={description_fl}
                onChange={(value) => setValue("description_fl", value)}
              />
            </div>
          )}

          <p className="title">Automatisk offentliggørelse</p>
          <div className="field">
            <label htmlFor="autoPublish">Skal udfyldte registreringsformular automatisk offentliggøres?</label>
            <RadioGroup
              id="autoPublish"
              onSelect={(value) => setValue("autoPublish", value === 1 ? true : false)}
              className="boolean-checkbox"
              selectedValue={autoPublish === true ? 1 : 2}
              options={[
                { id: 1, title: "Yes", value: true },
                { id: 2, title: "No", value: false },
              ]}
            />
          </div>

          <p className="title">Overskrift på oplæg</p>
          <div className="field">
            <label htmlFor="questionAsTitleId">Vælg hvilket spørgsmåls svar skal fremgå på besvaret registreringsoplæg?</label>
            <QuestionAsTitle
              id="questionAsAnswerTitleId"
              name="questionAsAnswerTitleId"
              value={questionAsAnswerTitleId}
              onChange={(e) => {
                let value = e.target.value ?? null;
                if (value) value = parseInt(value, 10);

                setValue("questionAsAnswerTitleId", value);
              }}
              onClear={() => setValue("questionAsAnswerTitleId", null)}
              registrationId={registrationId}
            />
          </div>

          <Button
            active={submitLoading}
            className="submit"
            onClick={handleSubmit(updateChanges, (error) => {
              console.log("Form errors", error);
            })}
          >
            Gem ændringer
          </Button>
        </form>
      )}
    </ScrollView>
  );

  if (showAsModal) return settings;

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={loading ? "Indlæser..." : registration?.title ?? ""} />
      {settings}
    </Page>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;

  .information-box {
    background-color: var(--white);
    margin-bottom: 0.75rem;
  }

  .error {
    border: 1px solid red;
  }

  p.title {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  form {
    padding: 1rem 0;
    width: 100%;
    max-width: ${breakpoints.lg}px;

    .field {
      label {
        font-size: 0.9rem;
        color: var(--darkGrey);

        &.error-message {
          color: var(--red);
        }
      }
    }

    button.submit {
      margin-top: 1.5rem;
    }
  }

  .boolean-checkbox {
    display: flex;

    label {
      flex: 1;
      background-color: var(--white);

      .radio {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
  }
`;

export default RegistrationSettings;
