// Libs
import React from "react";

// Styles
import { AlertIcon, TickCircleIcon, HourglassEmptyIcon } from "mdi-react";
import colors from "../../../style/colors";
import approvalStatuses from "../config/approvalStatuses";

export default (approvalStatus = "") => {
  if (approvalStatus === approvalStatuses.APPROVED) return <TickCircleIcon color={colors.green} />;
  if (approvalStatus === approvalStatuses.PARTIALLY_APPROVED) return <TickCircleIcon color={colors.yellow} />;
  if (approvalStatus === approvalStatuses.REJECTED) return <AlertIcon color={colors.red} />;
  if (approvalStatus === approvalStatuses.PENDING) return <HourglassEmptyIcon color={colors.darkGrey} />;
  return null; // Fallback / no icon
};
