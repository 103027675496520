import { css } from "emotion";
import { InformationOutlineIcon } from "mdi-react";
import colors from "../../../style/colors";

/**
 * @param {Object} props
 * @param {String} props.message - The rejection message
 * @param {String} props.groupName - Name of the group who rejected
 * @param {Object} props.style - Name of the group who rejected
 * @returns
 */
const noComment = "Ingen kommentar";

const RejectionMessage = ({ message = noComment, groupName = "Ukendt gruppe", style = {} }) => (
  <div className={`${componentStyles()} ${message === noComment ? "no-comment" : ""}`} style={style}>
    <p className="meta" style={{ opacity: 0.7 }}>
      Note fra {groupName}:
    </p>
    <p className="content">
      {message === noComment && <InformationOutlineIcon />}
      {message}
    </p>
  </div>
);

const componentStyles = () => css`
  color: ${colors.red};
  background-color: ${colors.redLight};
  color: ${colors.red};
  padding: 0.65rem;
  border-radius: 6px;

  &.no-comment p.content {
    font-size: 0.9rem;
    color: ${colors.darkGrey};

    svg {
      margin-bottom: -3px;
      margin-right: 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }

  & > p.meta,
  & > p.content {
    margin-bottom: 0rem;
    white-space: pre-wrap;
  }
`;

export default RejectionMessage;
