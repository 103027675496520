import { OPEN_SIDE_MENU, CLOSE_SIDE_MENU } from "./actionTypes";
import { lock, unlock } from "tua-body-scroll-lock";

export function openSideMenu() {
  setTimeout(() => lock(document.querySelector(".side-menu.scroll-hider")), 300);

  return {
    type: OPEN_SIDE_MENU,
  };
}

export function closeSideMenu() {
  unlock(document.querySelector(".side-menu.scroll-hider"));
  return {
    type: CLOSE_SIDE_MENU,
  };
}
