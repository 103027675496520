// Libs
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import * as queryString from "query-string";
import { parse, format } from "date-fns";

// Components
import ContextMenuButton from "../../ui/ContextMenuButton";
import RegistrationForm from "./RegistrationForm";
import ButtonRounded from "../../ui/ButtonRounded";

// Style
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";
import { PencilIcon, HistoryIcon, TrashCanOutlineIcon } from "mdi-react";

// Config
import approvalStatuses from "../config/approvalStatuses";
import styleTypes from "../../../config/styleTypes";
import formMode from "../config/formMode";

// Actions
import { addToast, hideModalPage, showContextMenu, showDialog, showModalPage } from "../../../actions/uiActions";
import { getRegistrationForm } from "../../../actions/registrationActions";

// Utilities
import getIconFromApprovalStatus from "../utilities/getIconFromApprovalStatus";
import req from "../../../utilities/request-utility";
import getUserLocale from "../../../utilities/get-user-locale";
import capitalizeFirstLetter from "./../utilities/capitalizeFirstLetter";
import RegistrationCardContent from "./RegistrationCardContent";
import TimeRegistrationHistory from "../modal/TimeRegistrationHistory";
import requestUtility from "../../../utilities/request-utility";
import getUserFriendlyNameFromApprovalStatus from "../utilities/getUserFriendlyNameFromApprovalStatus.js";
import RejectionMessage from "./RejectionMessage";

const RegistrationCard = ({ registration, refreshData = () => {} }) => {
  const dispatch = useDispatch();

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const permissions = useSelector((state) => state.timeRegistration.permissions);

  // Language
  const user = useSelector((state) => state.auth.user);

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  // State
  const [approvalStatus, setApprovalStatus] = useState(null);

  useEffect(() => {
    setApprovalStatus(getApprovalStatus());

    // If this is approvedWithChanges mark it as seen
    if (registration?.timeRegistrationData?.isApprovedWithChanges) {
      requestUtility().put(`time-registrations/${timeRegistrationId}/time-sheets/${registration.id}/seen-by-creator`);
    }
  }, [registration]);

  function getApprovalStatus() {
    if (registration.timeRegistrationData.exported) return approvalStatuses.APPROVED;
    if (registration.timeRegistrationData.isApproved) return approvalStatuses.APPROVED;

    let approvals = [];
    registration.timeRegistrationData.approvals.forEach((approval) => {
      approvals.push(approval.status);
    });

    // If any of the approvals is rejected show rejected
    if (approvals.includes(approvalStatuses.REJECTED)) {
      return approvalStatuses.REJECTED;
    }

    // If we got this far then there is no rejections and if there is at
    // least one that is pending, then status is pending
    if (approvals.includes(approvalStatuses.PENDING)) {
      return approvalStatuses.PENDING;
    }

    // Okay we got this far! That means that there are no PENDING
    // and no REJECTED approvals. If all of the remaining approvals are
    // approved (which they should be unless we have a bug) then the
    // status is approved
    if (
      approvals.includes(approvalStatuses.APPROVED) ||
      !approvals.includes(approvalStatuses.PENDING) ||
      !approvals.includes(approvalStatuses.REJECTED)
    ) {
      return approvalStatuses.APPROVED;
    }
  }

  const getTitle = () => {
    if (registration.timeRegistrationData.exported || registration.timeRegistrationData.isApproved)
      return (
        <p>
          {getIconFromApprovalStatus(approvalStatuses.APPROVED)} Godkendt{" "}
          {registration.timeRegistrationData.isApprovedWithChanges ? "med ændringer" : ""}
        </p>
      );

    // If any of the approvals is rejected show rejected
    if (approvalStatus === approvalStatuses.REJECTED) {
      return <p className="rejected">{getIconFromApprovalStatus(approvalStatuses.REJECTED)} Afvist</p>;
    }
    if (approvalStatus === approvalStatuses.PENDING) {
      return <p>{getIconFromApprovalStatus(approvalStatuses.PENDING)} Afventer godkendelse</p>;
    }
  };

  useEffect(() => {
    setApprovalStatus(getApprovalStatus());
  }, [registration]);

  const editRegistration = (registration) => {
    dispatch(getRegistrationForm({ registrationId: timeRegistrationId, postId: registration.id }));
    dispatch(
      showModalPage({
        title: `Rediger - ${capitalizeFirstLetter(
          format(parse(registration.timeRegistrationData.date, "yyyyMMdd", 0), "EEEE dd/MM-yyyy", getUserLocale(user))
        )} - ${registration.user.name}`,
        content: (
          <RegistrationForm
            postId={registration.id}
            mode={formMode.edit}
            onSubmitCallback={() => {
              dispatch(getRegistrationForm({ registrationId: timeRegistrationId }));
              refreshData();
            }}
          />
        ),
        useScrollView: false,
        pageStyle: { backgroundColor: colors.white },
        closeCallback: () => {
          dispatch(getRegistrationForm({ registrationId: timeRegistrationId }));
          dispatch(hideModalPage());
        },
      })
    );
  };

  const deleteRegistration = (registration) => {
    dispatch(
      showDialog({
        title: "Er du sikker på at du vil slette registreringen?",
        content: "Hvis du sletter registreringen kan det ikke gøres om!",
        icon: <TrashCanOutlineIcon />,
        styleType: styleTypes.error,
        primaryAction: async () => {
          await req()
            .delete(`time-registrations/${timeRegistrationId}/time-sheets/${registration.id}`)
            .catch(() => dispatch(addToast({ template: "error" })));
          refreshData();
        },
        primaryActionTitle: "Slet registreringen",
      })
    );
  };

  const handleContextMenuToggle = (registration) => {
    let contextMenu = [];

    contextMenu.push({
      title: "Se historik",
      icon: <HistoryIcon />,
      callback: () =>
        dispatch(
          showModalPage({
            content: <TimeRegistrationHistory timeSheetId={registration.id} timeRegistrationId={timeRegistrationId} />,
          })
        ),
    });

    if (isUserAllowedToEditAndDelete(registration)) {
      contextMenu.push({
        title: "Rediger",
        icon: <PencilIcon />,
        callback: () => editRegistration(registration),
      });
      contextMenu.push({
        title: "Slet",
        icon: <TrashCanOutlineIcon />,
        callback: () => deleteRegistration(registration),
      });
    }

    dispatch(showContextMenu(contextMenu));
  };

  function isUserAllowedToEditAndDelete(registration) {
    if (
      // The data ecxists
      registration.timeRegistrationData &&
      // The data has not been exported
      !registration.timeRegistrationData.exported &&
      // The data can be edited (Check server for this implementation)
      registration.timeRegistrationData.isEditable &&
      // It is registered by the current user or the current user has permission
      // to do something that is shown in the contextMenu: approve/reject or edit/delete
      (registration.user?.id === user.id || permissions.canRegisterForOthers || permissions.canApprove)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className={`${componentStyle(primaryColor)} ${approvalStatus}`}>
      <div className="top-line-container">
        {getTitle()}
        {/*  {doesUserHaveAccessToContextMenu(registration) && ( */}
        <ContextMenuButton style={{ marginLeft: "auto" }} onClick={() => handleContextMenuToggle(registration)} />
        {/*   )} */}
      </div>

      <div className="information-container">
        {registration.timeRegistrationData.isEditable &&
          !registration.timeRegistrationData.isApproved &&
          registration.timeRegistrationData.approvals.some((s) => s.status === approvalStatuses.REJECTED) && (
            <>
              <p style={{ marginBottom: "0.5rem" }}>
                Ret din registrering for at få en ny godkendelse. Er du uenig i afvisningen skal du kontakte din nærmeste
                leder.
              </p>
              {registration.timeRegistrationData.isEditable && (
                <div className="btn-container">
                  <ButtonRounded onClick={() => editRegistration(registration)} styleType={styleTypes.neutral}>
                    <PencilIcon />
                    <p>Rediger</p>
                  </ButtonRounded>
                  <ButtonRounded onClick={() => deleteRegistration(registration)} styleType={styleTypes.error}>
                    <TrashCanOutlineIcon />
                    <p>Slet</p>
                  </ButtonRounded>
                </div>
              )}

              <div className="seperator"></div>
            </>
          )}

        {/* Approvals */}
        {registration.timeRegistrationData.approvals.map((approval) => (
          <Fragment key={approval.id}>
            <p>
              <span className="label">{approval.groupName}:</span> {getIconFromApprovalStatus(approval.status)}{" "}
              <span className={`permission-group-approval ${approval.status}`}>
                {getUserFriendlyNameFromApprovalStatus(approval.status)}
              </span>
            </p>

            {/*Rejection message */}
            {approval.status === approvalStatuses.REJECTED && (
              <RejectionMessage
                message={approval?.approvedByUser?.comment}
                groupName={approval?.groupName}
                style={{ marginBottom: "1rem" }}
              />
            )}
          </Fragment>
        ))}

        {/* Questions with either question-identifier */}
        {registration.questions.map((question) => (
          <RegistrationCardContent key={question.id} question={question} />
        ))}
      </div>

      {/* Expand collaps functionality - temporarily removed. Might be needed later */}
      {/* <ActionWrapper
        onClick={() => setShowDetails(!showDetails)}
        style={{ color: primaryColor, fontWeight: 600, margin: "0 0 0.65rem 0" }}
      >
        <p>{`${showDetails ? lang.hide : lang.view} ${lang.details.toLowerCase()}`}</p>
      </ActionWrapper> */}

      {/* Rest of questions */}
      {/*       <AnimateHeight height={showDetails ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
        <div className="information-container">
          {registration.questions
            .filter((q) => !(q.dataIdentifier || q.identifier))
            .map((question) => (
              <p>
                <span className="label">{question.title}:</span> {question.answer.value}
              </p>
            ))}
        </div>
      </AnimateHeight> */}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  margin: 1rem;
  background-color: var(--white);
  padding: 0.65rem 0.65rem 0 0.65rem;
  white-space: normal;
  border: 1px var(--midGrey) solid;
  border-radius: 2px;
  transition: height 2s;

  &.${approvalStatuses.APPROVED} {
    border-left: 3px var(--green) solid;
  }

  &.${approvalStatuses.PENDING} {
    border-left: 3px var(--darkGrey) solid;
  }

  &.${approvalStatuses.REJECTED} {
    border-left: 3px var(--red) solid;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 1rem auto;
    border-radius: 3px;
    max-width: ${breakpoints.md}px;
  }

  .title-container {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }

    p {
      font-weight: 600;
    }
  }

  .top-line-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    p.rejected {
      color: var(--red);
    }

    & > p {
      font-weight: 700;

      & > svg {
        vertical-align: bottom;
      }
    }
  }

  .rejected-container {
    p.info {
      color: var(--darkGrey);
      margin-bottom: 0.75rem;
    }
  }
  .btn-container {
    display: flex;
    align-items: center;

    button {
      display: flex;
      justify-content: center;
      margin-left: 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }

      p {
        margin: 0 0 0 0.25rem;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  .information-container {
    margin: 0 0 0.65rem 0;

    div.seperator {
      height: 1px;
      background-color: ${colors.midGrey};
      margin: 1rem -0.65rem;
    }

    div.box-wrapper {
      border: 1px solid var(--midGrey);
      border-radius: 3px;
      padding: 0.5rem;
      margin: 0.5rem 0;

      div.title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        p {
          color: ${colors.midDarkGrey};
          margin: 0;

          &.shared {
            font-size: 0.85rem;
            color: ${primaryColor};
            border: 1px solid var(--midGrey);
            padding: 0.125rem 0.25rem;
            border-radius: 3px;
          }
        }
      }
    }

    p {
      line-height: 1.5;
      margin-bottom: 0.35rem;

      & > svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: -3px;
      }
    }

    .label {
      color: var(--darkGrey);
    }

    .permission-group-approval {
      font-weight: 700;
      &.APPROVED {
        color: ${colors.green};
      }
      &.REJECTED {
        color: ${colors.red};
      }
      &.PENDING {
        color: ${colors.darkGrey};
      }
    }
  }
`;

export default RegistrationCard;
