import req from "../utilities/request-utility";

import { GET_PAGES, SET_TRANSITION_DIRECTION, GET_PAGES_SUCCESS, GET_PAGES_FAILURE } from "./actionTypes";

let debouncer;
export function getPages() {
  return function (dispatch, getState) {
    // Will either start the debouncer (if it isn't currently active)
    // This initial run will include a page fetch so in cases where they are needed
    // immediately, then you will not have to wait 99% of the time (as long as it is
    // more than 4 seconds since last page fetch)
    if (!debouncer) {
      debouncer = setTimeout(getPagesInner, 4000);
      getPagesInner({ resetDebouncer: false });

      // If a debounce is currently running then it will clear that and start a new one. This means that
      // as long as there is a demand for fetching pages we will wait until the demand stops
      // and then we will fetch the pages
    } else {
      clearTimeout(debouncer);
      debouncer = setTimeout(getPagesInner, 4000);
    }

    function getPagesInner({ resetDebouncer = true } = {}) {
      dispatch({
        type: GET_PAGES,
      });

      req()
        .get("/pages")
        .then((res) => res.data)
        .then((pages) => {
          dispatch({
            type: GET_PAGES_SUCCESS,
            payload: pages,
          });

          let appTitle = getState().appConfig.title;
          updateAppTitle(pages, appTitle);
        })
        .catch((err) => {
          dispatch({
            type: GET_PAGES_FAILURE,
          });
        });

      // If the debouncer is reset to undefined then a page-fetch will be invoked immediately
      // when the debouncer is started
      if (resetDebouncer) {
        debouncer = undefined;
      }
    }
  };
}

function updateAppTitle(pages, title) {
  let notifications = 0;

  // Notifications are bubbled to parent so this should count all notifications from pages
  pages.forEach((page) => {
    if (page.notifications && typeof page.notifications === "number") {
      notifications += page.notifications;
    }
  });

  // It is important to set these before limiting to "99+"
  navigator.setAppBadge(notifications);

  if (notifications > 100) notifications = "99+";

  if (notifications > 0 || notifications === "99+") {
    document.title = `(${notifications}) ${title}`;
  } else {
    document.title = `${title}`;
  }
}

export function setTransitionMode(direction, autoRemove = true) {
  return function (dispatch) {
    dispatch({
      type: SET_TRANSITION_DIRECTION,
      payload: direction,
    });

    // Auto-removes animation so it won't be reused. This is usually what you want.
    // Disable it by setting autoRemove to false when calling the function
    if (autoRemove === true) {
      setTimeout(
        () =>
          dispatch({
            type: SET_TRANSITION_DIRECTION,
            payload: "",
          }),
        300
      );
    }
  };
}
