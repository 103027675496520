import React from "react";
import TextInput from "../../../../ui/TextInput";
import { useSelector } from "react-redux";

const ExternalLinkContent = ({ page, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.language);

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{lang?.urlForExternalLink}</p>
        <TextInput
          data-test-id={"external-link-input"}
          value={page?.meta?.URL}
          placeholder={`${lang?.type} URL`}
          name="URL"
          onChange={(e) =>
            onChangeHandler({
              target: { name: "meta", value: { ...page.meta, [e.target.name]: e.target.value } },
            })
          }
        />
      </div>
    </>
  );
};

export default ExternalLinkContent;
