import React from "react";
import TextareaInput from "../ui/TextareaInput";
import req from "../../utilities/request-utility";
import DatePicker from "../ui/DatePicker";
import { css } from "emotion";
import breakpoints from "../../config/breakpoints";
import Button from "../ui/Button";
import { useState } from "react";
import { addDays, parse, format } from "date-fns/esm";
import { useDispatch } from "react-redux";
import { addToast, hideModalPage } from "../../actions/uiActions";

function EquipmentBookingEndBookingModal({ equipmentBookingId, equipmentId, bookingId, onFinishedUnbooking }) {
  const dispatch = useDispatch();
  const [processingBooking, setProcessingBooking] = useState(false);

  const [formData, setFormData] = useState({
    comment: "",
  });

  function endBooking() {
    setProcessingBooking(true);

    req()
      .post(`equipment-booking/${equipmentBookingId}/equipment/${equipmentId}/bookings/${bookingId}/end-booking`, {
        comment: formData.comment,
      })
      .then(({ data }) => onFinishedUnbooking())
      .catch((err) => {
        setProcessingBooking(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  return (
    <div className={componentStyles()}>
      <label htmlFor="bookingCommentInput">Kommentar til din booking</label>
      <TextareaInput
        name="comment"
        style={{ marginBottom: "1.5rem" }}
        value={formData.comment}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        id="bookingCommentInput"
      ></TextareaInput>

      <Button active={processingBooking} onClick={endBooking} style={{ marginBottom: "0.5rem" }}>
        Afslut booking
      </Button>
      <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
        Annuller
      </Button>
    </div>
  );
}

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 0 auto;
  padding: 1.5rem 0.65rem 0.65rem 0.65rem;

  & > label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`;

export default EquipmentBookingEndBookingModal;
