import React from "react";
import CustomGroupSelector from "./ui/CustomGroupSelector";
import { useSelector } from "react-redux";

const CustomGroupContent = ({ page, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.language);

  function onChange(e) {
    onChangeHandler({ target: { name: "meta", value: { ...page.meta, [e.target.name]: e.target.value } } });
  }

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <div className="field-wrapper">
          <label>{lang?.customGroupSelection}</label>
          <CustomGroupSelector
            data-test-id={"custom-group-content-selector"}
            usePlaceholder={true}
            placeholder={lang?.clickToSelect}
            name={"customGroupId"}
            onChange={onChange}
            value={page?.meta?.customGroupId}
          />
        </div>
      </div>
    </>
  );
};

export default CustomGroupContent;
