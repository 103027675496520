// Libs
import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import { useTable, useSortBy, useFilters, useGlobalFilter, useRowSelect } from "react-table";
import AnimateHeight from "react-animate-height";
import * as queryString from "query-string";
import Icon from "@mdi/react";

// Components
import GlobalSearch from "./GlobalSearch";
import DefaultFilter from "./filters/DefaultFilter";
import BulkActionButtons from "./BulkActionButtons";
import RegistrationDetailModal from "../RegistrationDetailModal";
import ButtonRounded from "../../../ui/ButtonRounded";
import StatusBox from "../../../ui/StatusBox";
import InlineSpinner from "../../../ui/InlineSpinner";

import { mdiFilterOffOutline } from "@mdi/js";

// Utilities
import dateBetweenFilterFn from "../../utilities/dateBetweenFilterFn";
import includeFilterFn from "../../utilities/includeFilterFn";
import getFormattedHour from "../../../../utilities/get-formatted-hour";
import createTableColumns from "../../utilities/create-react-table-columns";
import convertTimeToDecimal from "../../utilities/convertTimeToDecimal";
import req from "../../../../utilities/request-utility";

// Actions
import { addToast, showModalPage } from "../../../../actions/uiActions";
import {
  exportTimeSheets,
  updateTimeSheetApprovalStatus,
  getTimeSheets,
  resetTableFilters,
  updateTableFilters,
} from "../../../../actions/timeRegistrationActions";

// Style
import { ChevronDownIcon, ChevronUpIcon, ErrorOutlineIcon } from "mdi-react";
import colors from "../../../../style/colors";
import sizes from "../../../../style/sizes";

// Config
import { durations } from "../../../../config/animations";
import approvalStatus from "../../config/approvalStatus";
import breakpoints from "../../../../config/breakpoints";
import timeRegistrationQuestionIdentifiers from "../../config/timeRegistrationQuestionIdentifiers";

// Context
import { TimeRegistrationAdminContext } from "../../context/TimeRegistrationAdminContext";

// Hooks
import useCurrentPage from "../../../../hooks/useCurrentPage";
import styleTypes from "../../../../config/styleTypes";

const ReactTable = (props) => {
  const lang = useSelector((state) => state.language.language);

  const { pageId } = React.useContext(TimeRegistrationAdminContext);
  const page = useCurrentPage({ pageId });

  // Redux state
  const { enableCSVDownload } = useSelector((state) => state.timeRegistration.table.filters);
  const { summaryMode } = useSelector((state) => state.timeRegistration);
  const { filters: filtersFromRedux } = useSelector((state) => state.timeRegistration.table);
  const { globalFilter: globalFilterFromRedux = "" } = useSelector((state) => state.timeRegistration.table);

  // State
  const [showFilters, setShowFilters] = useState(true);
  const [showBulkApprove, setShowBulkApprove] = useState(false);
  const [showBulkExport, setShowBulkExport] = useState(false);
  const [showBulkCSV, setShowBulkCSV] = useState(false);
  const [handlingRequest, setHandlingRequest] = useState(false);

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [initialFilters, setInitialFilters] = useState([]);

  // Dispatcher
  const dispatch = useDispatch();

  useEffect(() => {
    // updateState();
    handleCancelBulk({ bulkType: "all" });
    resetFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateState();
    updateInitialFilters();
  }, [props.rows, props.columns]);

  function updateState() {
    if (props.rows && props.rows.length > 0) {
      setData(props.rows);
    }

    if (props.columns) {
      setColumns(createTableColumns(props.columns));
    }
  }

  function updateInitialFilters() {
    const localFilters = [];

    for (let key in filtersFromRedux) {
      if (filtersFromRedux.hasOwnProperty(key)) {
        localFilters.push({
          id: key,
          value: filtersFromRedux[key],
        });
      }
    }

    setInitialFilters(localFilters);
  }

  // all columns needs a filter prop, even if filter is disabled. One way of doing this is by passing a defaultColum to the useTable function.
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultFilter,
    }),
    []
  );

  const filterTypes = useMemo(() => ({ dateBetween: dateBetweenFilterFn, customIncludes: includeFilterFn }), []);

  const resetFilters = () => {
    setGlobalFilter("");
    setAllFilters([]);
  };

  const handleToggleBulkExport = () => {
    toggleHideColumn("bulk-select");
    setShowBulkExport(!showBulkExport);
  };

  const handleToggleBulkCSV = () => {
    toggleHideColumn("bulk-select");
    setShowBulkCSV(!showBulkCSV);
    dispatch(updateTableFilters({ filters: { enableCSVDownload: true } }));
  };

  const handleToggleBulkApprove = () => {
    toggleHideColumn("bulk-select");
    setShowBulkApprove(!showBulkApprove);
  };

  const handleCancelBulk = ({ bulkType }) => {
    if (bulkType === "export") {
      setShowBulkExport(false);
    } else if (bulkType === "approve") {
      setShowBulkApprove(false);
    } else if (bulkType === "CSV") {
      setShowBulkCSV(false);
      dispatch(updateTableFilters({ filters: { enableCSVDownload: false } }));
    } else {
      setShowBulkCSV(false);
      setShowBulkApprove(false);
      setShowBulkExport(false);
      dispatch(updateTableFilters({ filters: { enableCSVDownload: false } }));
    }
    toggleHideColumn("bulk-select", true);
    toggleAllRowsSelected(false);
  };

  const handleBulkExport = () => {
    setHandlingRequest(true);
    dispatch(
      exportTimeSheets({
        timeSheetIds: selectedFlatRows.map((row) => row.values.id),
        timeRegistrationId,
        callback: setHandlingRequest(false),
      })
    );
    handleToggleBulkExport();
  };

  const handleBulkCSV = async () => {
    setHandlingRequest(true);

    const timeSheetIds = selectedFlatRows.map((row) => row.values.id);

    try {
      const { data: urlOfCSV } = await req().post(`admin/time-registrations/${timeRegistrationId}/time-sheets/meta/csv`, {
        timeSheetIds,
      });

      if (urlOfCSV) window.open(urlOfCSV, "_blank");
    } catch (error) {
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: "Der kunne desværre ikke fremvises timerne i CSV-format",
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    } finally {
      setHandlingRequest(false);
    }

    handleToggleBulkCSV();
  };

  const handleBulkApprove = () => {
    setHandlingRequest(true);
    dispatch(
      updateTimeSheetApprovalStatus({
        timeSheetIds: selectedFlatRows.map((row) => row.values.id),
        status: approvalStatus.approved,
        timeRegistrationId,
        callback: setHandlingRequest(false),
      })
    );
    handleToggleBulkApprove();
  };

  const handleRowClick = (row) => {
    dispatch(
      showModalPage({
        title: `Detaljer - ${row.original.date} - ${row.original.user.name}`,
        content: (
          <RegistrationDetailModal
            row={row.original}
            timeRegistrationId={timeRegistrationId}
            timeSheetId={row.original.id}
          />
        ),
        pageStyle: { backgroundColor: colors.white },
      })
    );
    setShowBulkCSV(false);
    setShowBulkApprove(false);
    setShowBulkExport(false);
  };

  const getTotalHours = (rows) => {
    const totalHours = rows.reduce((prevValue, currentValue) => {
      return (
        prevValue + convertTimeToDecimal(currentValue.values[timeRegistrationQuestionIdentifiers.TIME_REGISTRATION_HOURS])
      );
    }, 0);

    if (summaryMode === "hours") return getFormattedHour(totalHours);
    return totalHours;
  };

  // Create the table
  const tableInstance = useTable(
    {
      defaultColumn,
      columns,
      data,
      filterTypes,
      initialState: {
        hiddenColumns: ["bulk-select"],
        filters: initialFilters,
        globalFilter: globalFilterFromRedux,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const {
    toggleAllRowsSelected,
    selectedFlatRows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    toggleHideColumn,
    setGlobalFilter,
    setAllFilters,
  } = tableInstance;

  // I have made this, to avoid a flash of the statusbox saying that no registrations has been found, as the rows of the table instance is temporarily set to an empty array,  when new data is fetching.
  const isThereAnyRows = (rows) => {
    let isThereAnyRows = true;
    let timeout;
    clearTimeout(timeout);
    if (rows.length === 0) {
      timeout = setTimeout(500, () => {
        isThereAnyRows = false;
      });
    }
    return isThereAnyRows;
  };

  return (
    <div className={componentStyle()}>
      <div className="toggle-filter-container">
        <AnimateHeight height={showFilters ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
          <div className={`filtering-options-container`} {...getTableProps()}>
            {/* For each column with filtering enabled render the filter component. */}
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) =>
                column.canFilter ? <Fragment key={column.id}>{column.render("Filter")}</Fragment> : null
              )
            )}

            {/* Render the global search, that searches across all columns */}
            <GlobalSearch globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          </div>
        </AnimateHeight>
        <div className="toggle-filter-button-container">
          <ButtonRounded
            size={sizes.small}
            style={{ marginRight: "0.5rem" }}
            secondary={true}
            onClick={() => {
              resetFilters();
              dispatch(
                resetTableFilters({
                  callback: () => {
                    dispatch(getTimeSheets({ timeRegistrationId }));
                  },
                })
              );
            }}
          >
            <Icon path={mdiFilterOffOutline} className="base-icon" style={{ marginRight: "0.25rem" }} /> Nulstil filtrer
          </ButtonRounded>

          <ButtonRounded size={sizes.small} secondary={true} onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? "Skjul " : "Vis "}
            filtrer{" "}
            <ChevronUpIcon
              style={{
                transform: `scaleY(${showFilters ? "1" : "-1"})`,
                transition: `transform ${durations.normal}ms ease`,
              }}
            />
          </ButtonRounded>
        </div>
      </div>

      <div className="bulk-actions-container">
        {/* Get sum of either hours or count from the visible rows */}
        <p>Timer total: {getTotalHours(rows)}</p>

        <BulkActionButtons
          handleToggleBulkExport={handleToggleBulkExport}
          handleToggleBulkApprove={handleToggleBulkApprove}
          handleToggleBulkCSV={handleToggleBulkCSV}
          handleBulkExport={handleBulkExport}
          handleBulkApprove={handleBulkApprove}
          handleBulkCSV={handleBulkCSV}
          handleCancelBulk={handleCancelBulk}
          showBulkApprove={showBulkApprove}
          showBulkExport={showBulkExport}
          showBulkCSV={showBulkCSV}
          selectedFlatRows={selectedFlatRows}
          handlingRequest={handlingRequest}
          config={page?.config}
        />
      </div>

      <div className="outer-table-container">
        {props.loading && (
          <div className="spinner-container">
            <InlineSpinner className="spinner" />
          </div>
        )}
        {(props.isDataNotFound || !isThereAnyRows(rows)) && (
          <StatusBox
            style={{ marginTop: "1rem" }}
            title={"Ingen registreringer fundet"}
            content={"Prøv at ændre søgningkriterierne eller nulstil filtrer"}
            action={{
              title: "Nulstil filtrer",
              callback: () => {
                dispatch(
                  resetTableFilters({
                    callback: () => {
                      dispatch(getTimeSheets({ timeRegistrationId }));
                    },
                  })
                );
                setGlobalFilter("");
              },
            }}
          />
        )}
        {!props.isDataNotFound && isThereAnyRows(rows) && (
          <>
            <div className="table-container">
              <table {...getTableProps()} className="table">
                <thead>
                  {/* Loop over the header rows */}
                  {headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {/* Loop over the headers in each row */}
                      {headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {/* Render the header */}
                          {column.render("Header")}
                          {column.canSort && (
                            <span>
                              {column.isSorted && column.isSortedDesc && <ChevronDownIcon />}
                              {column.isSorted && !column.isSortedDesc && <ChevronUpIcon />}
                              {!column.isSorted && <ChevronUpIcon color="transparent" />}
                            </span>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {/* Loop over the table rows */}
                  {rows.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);

                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                        {/* Loop over the rows cells */}
                        {row.cells.map((cell) => {
                          return (
                            // Apply the cell props
                            <td {...cell.getCellProps()}>
                              {/* Render the cell contents */}
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const componentStyle = () => css`
  color: var(--darkGrey);

  input,
  select {
    padding: 0.3rem;
    font-size: 1rem;
    font-family: "Open Sans";
    cursor: pointer;
    border: 1px solid var(--midGrey);
    border-radius: 2px;
    width: 100%;
    min-height: 35.59px;
    background-color: var(--white);
    color: var(--black);
    outline: none;

    &:focus {
      border-color: var(--darkGrey);
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: -15px;
    cursor: pointer;
    height: 1rem;
  }
  input[type="date"] {
    min-width: 120px;
  }

  label {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .toggle-filter-container {
    background-color: var(--white);
    border: 1px solid var(--midGrey);

    .toggle-filter-button-container {
      display: flex;
      justify-content: center;
      margin: 0.5rem 0;
    }
  }

  .filtering-options-container {
    /* ANY Top level div's that does not have the class "input-container" (spoiler: it's the date-selectors) */
    & > div:not(.input-container) {
      display: inline; /* Make the div ignore all block-styling etc */
      flex-shrink: 0;
    }

    .input-container {
      display: inline-block;
      width: calc(100% - 1.6rem);
      margin: 0.4rem 0.8rem 0.4rem 0.8rem;

      label {
        display: block;
        margin-bottom: 0.25rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      * {
        white-space: nowrap;
        font-size: 0.9rem;
      }
    }

    @media screen and (min-width: 450px) and (max-width: 849px) {
      padding: 0 0.4rem;
      .input-container {
        width: calc(50% - 0.8rem);
        margin: 0.4rem 0.4rem 0.4rem 0.4rem;
      }
    }
    @media screen and (min-width: 850px) and (max-width: 1199px) {
      padding: 0 0.4rem;
      .input-container {
        width: calc(33% - 0.8rem);
        margin: 0.4rem 0.4rem 0.4rem 0.4rem;
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      padding: 0 0.4rem;
      /* max-width: 1400px; */
      margin: 0 auto;
      .input-container {
        width: calc(25% - 0.8rem);
        margin: 0.4rem 0.4rem 0.4rem 0.4rem;
      }
    }

    @media screen and (min-width: 1400px) {
      padding: 0 0.4rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .input-container {
        width: 200px;
        margin: 0.4rem;
        flex-shrink: 0;
      }
    }
  }

  .bulk-actions-container {
    max-width: ${breakpoints.xl}px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 1rem 0;
    align-items: baseline;
    flex-wrap: wrap;

    .bulk-action-buttons-container {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    p {
      font-weight: 600;
    }

    button {
      width: fit-content;
      border-radius: 50px;
      margin-left: 1rem;
      min-width: 162px;
    }

    @media screen and (max-width: ${breakpoints.xs}px) {
      justify-content: flex-start;
      padding: 1rem 0;

      .bulk-action-buttons-container {
        justify-content: flex-start;
      }

      p,
      button {
        margin-bottom: 0.5rem;
      }
      p {
        margin-left: 1rem;
      }
    }
  }

  .outer-table-container {
    overflow-x: auto;
    position: relative;
    min-height: 100%;
    height: 100%;

    .spinner-container {
      position: absolute;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(4px);

      .spinner {
        margin-top: 6rem;
      }
    }
  }

  .table-container {
    border-top: 1px solid var(--midGrey);
    border-bottom: 1px solid var(--midGrey);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--white);

    thead {
      white-space: nowrap;
      overflow-y: auto;
      overflow-x: hidden;

      input {
        width: 24px;
        height: 24px;
      }
    }

    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .centered-svg {
      margin: auto;
      text-align: center;
      display: inherit;
    }
  }
  tr:nth-child(even) {
    background-color: var(--ultraLightGrey);
  }

  tr {
    cursor: pointer;

    &:hover {
      background-color: var(--ultraLightGrey);
    }

    &:nth-child(even) {
      background-color: var(--ultraLightGrey);
    }
  }

  td,
  th {
    font-size: 0.925rem;
    padding: 0.3rem 0.4rem;
    white-space: nowrap;
    &:first-of-type {
      padding-left: 1.5rem;
    }
    &:last-of-type {
      padding-right: 1.5rem;
    }
  }

  th {
    text-align: left;
    border-bottom: 1px solid var(--midGrey);

    span {
      min-width: 24px;
      width: 24px;
      vertical-align: middle;
      display: inline-block;
    }
  }
  .actions-cell {
    display: flex;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.2;
  }
`;

export default ReactTable;
