import req from "../utilities/request-utility";
import io from "socket.io-client";
import dayjs from "dayjs";

import {
  GET_CHAT_MESSAGES,
  GET_CURRENT_ROOM,
  GET_CURRENT_ROOM_SUCCESS,
  GET_CURRENT_ROOM_FAILURE,
  REMOVE_ROOM,
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_CHAT_ROOMS,
  GET_CHAT_ROOMS_SUCCESS,
  GET_CHAT_ROOMS_FAILURE,
  UPDATE_CHAT_ROOM_IN_CHAT_LIST,
  REMOVE_CHAT_ROOMS,
  SET_SOCKET,
  REMOVE_SOCKET,
  SET_ONLINE_USERS,
  UPDATE_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_FAILURE,
  REMOVE_USERS,
} from "./actionTypes";

// Util
import groupMessages from "../components/liveChat/utilities/groupMessages";

export function getChatRoom({ chatId }) {
  return function (dispatch, getState) {
    const socket = getState().liveChat.socket;

    // check for socket
    if (socket) {
      dispatch({ type: GET_CURRENT_ROOM });
      // function for joinning a room
      socket.emit("join-room", chatId, (joinedRoom) => {
        if (joinedRoom) {
          dispatch({
            type: GET_CURRENT_ROOM_SUCCESS,
            payload: { chatRoom: joinedRoom },
          });
        } else {
          dispatch({ type: GET_CURRENT_ROOM_FAILURE });
        }
      });
    }
  };
}

export function removeChatRoom() {
  return function (dispatch, getState) {
    const socket = getState().liveChat.socket;
    const currentChatRoom = getState().liveChat.currentChatRoom.chatRoom;

    socket.emit("leaveRoom", currentChatRoom.id);
    dispatch({
      type: REMOVE_ROOM,
    });
  };
}

export function removeChatRooms() {
  return function (dispatch, getState) {
    dispatch({
      type: REMOVE_CHAT_ROOMS,
    });
  };
}

export function updateChatRoomInList(chatRoom) {
  return function (dispatch, getState) {
    const currentChatRoomList = getState().liveChat.chatRooms.chatRooms;
    let newList;

    // find chat room and update
    if (currentChatRoomList.length > 0) {
      newList = currentChatRoomList.map((currentChatRoom) => {
        if (currentChatRoom.id === chatRoom.id) {
          // if chat room is alrready in list update last message
          currentChatRoom.lastMessage = chatRoom.lastMessage;
          if (currentChatRoom.notificationCount) {
            currentChatRoom.notificationCount += 1;
          } else {
            currentChatRoom.notificationCount = 1;
          }
          return currentChatRoom;
        } else {
          return currentChatRoom;
        }
      });
    }

    if (currentChatRoomList.length === newList.length) {
      // if a chat room has been updated sort the list
      newList.sort((a, b) => b.lastMessage.createdAt - a.lastMessage.createdAt);
      dispatch({ type: UPDATE_CHAT_ROOM_IN_CHAT_LIST, payload: newList });
    } else {
      // add notification
      chatRoom.notificationCount = 1;
      // put element first in array because it is the newest
      newList.unshift(chatRoom);
      dispatch({ type: UPDATE_CHAT_ROOM_IN_CHAT_LIST, payload: newList });
    }
  };
}

export function getChatRooms({ searchTerm = "", scrollEnd = false }) {
  return function (dispatch, getState) {
    const socket = getState().liveChat.socket;
    const { chatRooms: currentChatRooms, endOfFeed } = getState().liveChat.chatRooms;

    let limit = 20;
    let offset = !scrollEnd ? 0 : currentChatRooms.length;

    // check for socket and for endOfFeed
    if (socket && (!endOfFeed || !scrollEnd)) {
      dispatch({ type: GET_CHAT_ROOMS });

      socket.emit("getChatRooms", searchTerm, limit, offset, (chatRooms) => {
        // if no error in getting the chat rooms
        if (chatRooms) {
          // set endOfFeed
          let endOfChatList = endOfFeed;
          if (chatRooms.length < limit && scrollEnd) endOfChatList = true;

          let mergedChatRooms;
          // if event is triggered by no scrolling it means it is a searchTerm therefore
          // we want to reset state and only save the new chatRooms gotten
          // but if event is triggered by scrolling we want to merge the new chatRooms array with the old.
          if (!scrollEnd) {
            dispatch(removeChatRooms());
            mergedChatRooms = [...chatRooms];
            endOfChatList = false;
          } else {
            mergedChatRooms = [...currentChatRooms, ...chatRooms];
          }

          dispatch({
            type: GET_CHAT_ROOMS_SUCCESS,
            payload: {
              chatRooms: mergedChatRooms,
              endOfFeed: endOfChatList,
            },
          });
        } else {
          dispatch({ type: GET_CHAT_ROOMS_FAILURE });
        }
      });
    }
  };
}

export function setSocket() {
  return function (dispatch, getState) {
    const currentSocket = getState().liveChat.socket;
    const token = getState().auth.token;
    const user = getState().auth.user;
    const appConfig = getState().appConfig;

    const url = appConfig.apiUrl.replace("/api/", "");

    // check for current socket or if the socket is connected.
    if (!currentSocket || currentSocket.connected === false) {
      // make a socket an connect it
      const socket = io.connect(`${url}/application-${user.applicationId}`, {
        transports: ["websocket"],
        auth: { token: token },
      });

      // make sure there is established a connection
      socket.connect();

      socket.emit("addUser", user);

      dispatch({
        type: SET_SOCKET,
        payload: socket,
      });
    }
  };
}

export function removeSocket() {
  return function (dispatch, getState) {
    const currentSocket = getState().liveChat.socket;

    if (currentSocket) {
      // disconnect the socket
      currentSocket.disconnect();
      dispatch({
        type: REMOVE_SOCKET,
      });
    }
  };
}

export function setOnlineUsers(onlineUsers) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_ONLINE_USERS,
      payload: onlineUsers,
    });
  };
}

export function getMessages(chatRoomId) {
  return function (dispatch, getState) {
    const socket = getState().liveChat.socket;
    const { messages: currentMessages, offset, endOfFeed } = getState().liveChat.messages;

    let limit = 20;

    // check for socket and endOfFeed
    if (socket && !endOfFeed) {
      dispatch({ type: GET_CHAT_MESSAGES });
      // function for getting messages
      socket.emit("getMessages", chatRoomId, limit, offset, (messages) => {
        if (messages) {
          // group messages
          const groupedMessages = groupMessages({ currentMessages: currentMessages, newMessages: messages });

          // if no messages set endOfFeed to true
          let endOfMessages = endOfFeed;
          if (messages.length < limit) endOfMessages = true;

          dispatch({
            type: GET_CHAT_MESSAGES_SUCCESS,
            payload: { groupedMessages, offset: messages.length, endOfFeed: endOfMessages },
          });
        } else {
          dispatch({ type: GET_CHAT_MESSAGES_FAILURE });
        }
      });
    }
  };
}

export function updateMessages(message) {
  return function (dispatch, getState) {
    let currentMessages = getState().liveChat.messages.messages;

    // if there is no messages push the message happens on creating a new chat rooom
    if (currentMessages.length === 0) {
      currentMessages.push({
        createdAt: message.createdAt,
        chatId: message.chatId,
        senderId: message.senderId,
        messages: [
          {
            id: message.id,
            text: message.text,
          },
        ],
      });
    } else if (
      // compare date between new message and old
      parseInt(currentMessages[0].createdAt) >
        parseInt(dayjs(message.createdAt).subtract(5, "minutes").format("YYYYMMDDHHmmss")) &&
      currentMessages[0].senderId === message.senderId
    ) {
      currentMessages[0].createdAt = message.createdAt;
      currentMessages[0].messages.push({
        id: null,
        text: message.text,
      });
    } else {
      currentMessages.unshift({
        createdAt: message.createdAt,
        chatId: message.chatId,
        senderId: message.senderId,
        messages: [
          {
            id: message.id,
            text: message.text,
          },
        ],
      });
    }

    dispatch({
      type: UPDATE_CHAT_MESSAGES,
      payload: currentMessages,
    });
  };
}

export function getUsersInApp({ searchTerm = "", scrollEnd = false }) {
  return async function (dispatch, getState) {
    try {
      const { endOfFeed, users: userList } = getState().liveChat.usersList;
      const user = getState().auth.user;
      let offset = !scrollEnd ? 0 : userList.length;
      let limit = 20;

      // run if not end of feed or not scrolling
      if (!endOfFeed || !scrollEnd) {
        dispatch({ type: GET_USERS_STARTED });
        const { data: users } = await req()(`users/?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);

        let endOfUsers = endOfFeed;
        if (users.length < limit && scrollEnd) endOfUsers = true;

        let mergedUsers;

        // if event is triggered by no scrolling it means it is a searchTerm therefore
        // we want to reset state and only save the new users gotten
        // but if event is triggered by scrolling we want to merge the new user array with the old.
        if (!scrollEnd) {
          dispatch(removeUsers());
          mergedUsers = [...users];
          endOfUsers = false;
        } else {
          mergedUsers = [...userList, ...users];
        }

        // remove own user from list
        const filteredUsers = mergedUsers.filter((u) => u.id !== user.id);

        dispatch({
          type: GET_USERS_SUCCESS,
          payload: { users: filteredUsers, endOfFeed: endOfUsers },
        });
      }
    } catch (error) {
      dispatch({ type: GET_USERS_FAILURE });
    }
  };
}

export function removeUsers() {
  return function (dispatch, getState) {
    dispatch({ type: REMOVE_USERS });
  };
}
