import store from "../store";

/** This function retrieves the auth token from localstorage and saves it in a cache accecible from the service worker
 * As the token was available through local-storage using javascript-api's this shouldn't really change anything. Its just
 * a weird way of communicating between the app and the service worker.
 * Other posibilities would be:
 * - Setting up push-comminication between app and service worker
 * - Setting up indexedDB instead
 * - Something else?
 */
async function storeTokenInCache() {
  let state = store.getState();
  const cache = await caches.open("auth-token-transfer");
  const response = new Response(JSON.stringify({ token: state.auth.token, apiUrl: state.appConfig.apiUrl }));
  await cache.put("auth-token-transfer", response);
}

export default storeTokenInCache;
