/**
 * Function that takes the current window location and returns the app name from it.
 * ---
 * Will return empty string if at the root level of the application
 * Can be called at any time - with or without react, redux or whatever available
 *
 * (and yes it's an extremely simple function but it's needed in multiple places
 * which i've chosen to make this utility-abstraction)
 *
 * @returns {string} The url name of the current app (as described by the url)
 */

export default () => {
  return window.location.pathname.substring(1).split("/")[0].toLowerCase();
};
