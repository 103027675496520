// Libraries
import React, { useState, useEffect, useRef, Suspense } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { format, addMonths, parse, isToday, isBefore } from "date-fns";
import { css } from "emotion";

// Styles
import breakpoints from "../../config/breakpoints";

// Components
import InlineSpinner from "../ui/InlineSpinner";
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import DropDownList from "../ui/dropDown/DropDownList";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";
import NewsPost from "./NewsPost";
import DateTimePicker from "../ui/DateTimePicker";
import ButtonRounded from "../ui/ButtonRounded";
import MediaUpload from "../ui/MediaUpload/MediaUpload";

// Utils
import req from "../../utilities/request-utility";
import {
  AlertOctagonIcon,
  TranslateIcon,
  PlusIcon,
  AlertDecagramIcon,
  AccountGroupIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  BellOutlineIcon,
  BellOffOutlineIcon,
  InformationOutlineIcon,
  VideoIcon,
  AlertCircleOutlineIcon,
} from "mdi-react";
import PropTypes from "prop-types";
import formatAndCombineDatetime from "./utilities/format-and-combine-datetime";
import timePickerValueToString from "../../utilities/time-picker-value-to-string";
import { isTimeBefore } from "./utilities/validate-datetime";
import stringToTimePickerValue from "../../utilities/string-to-time-picker-value";
import addActualLineBreaksToMarkdownString from "../../utilities/add-actual-line-breaks-to-markdown-string";
import { upperCaseFirstLetter } from "../../utilities/lang-utilties";
import shouldNotificationOptionBeAvailableInPostModal from "./utilities/should-notification-option-be-available-in-post-modal";

// Redux actions
import { resetFeeds, getPosts, updateUnreadCount } from "../../actions/newsActions";
import { hideModalPage, addToast, updateModalPage, showDialog } from "../../actions/uiActions";
import FileUploadMultiple from "../ui/FileUploadMultiple";
import InformationBox from "../ui/InformationBox";
import TargetGroupSelector from "../ui/TargetGroupSelector";
import { isTargetGroupEmpty } from "../../utilities/is-targetgroup-empty";
import { addPreExistingMedia } from "../../actions/mediaUploadActions";
import { RESET_MEDIA_UPLOAD, SET_MEDIA_CONTEXT } from "../../actions/actionTypes";

// Hooks
import { useLocalStorage } from "../../hooks/useLocalStorage";
import EmbedLinkInput from "../ui/EmbedLinkInput/EmbedLinkInput";
import getEmbedLink from "../../utilities/get-video-embed-link";

const TextEditor = React.lazy(() => import("../ui/TextEditor"));

/** Modal page for editing comments.
 *
 * Adding comments is done inline in the Post component.
 * Is implemented as a component passed to a redux action
 *
 * @example
 * ```jsx
 * showModalPage({
 *   title: `${lang.edit} ${lang.comment.toLowerCase()}`,
 *   content: <NewsCommentModal comment={comment} postId={id} />
 * });
 * ```
 */

function NewsPostModal(props) {
  const dispatch = useDispatch();

  // State
  const [appLanguages, setAppLanguages] = useState({ primary: {}, foreign: {} });
  const [loading, setLoading] = useState(false);
  const [savingPost, setSavingPost] = useState(false);
  const [fileUploadDisableButton, setFileUploadDisableButton] = useState(false);

  const [enableForeignLanguage, setEnableForeignLanguage] = useState(false);
  // To control whether it is the add/edit or preview tab that is visible
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Get the language object from Redux Store
  const { language: lang } = useSelector((state) => state.language);
  const { frontendAdminMappings } = useSelector((state) => state.news);

  // Get the current user from Redux Store
  const { user } = useSelector((state) => state.auth);
  // Get the primary colour of the app to use in the style sheet
  const { primaryColor, enableAutoTranslateForNewsModule, enablePushNotifications } = useSelector(
    (state) => state.appConfig
  );
  const [autoEnableForeignLangugeHasCompleted, setAutoEnableForeignLangugeHasCompleted] = useState(false);
  const [isUsingPrimaryLanguage, setIsUsingPrimaryLanguage] = useState(true);

  const [time, setTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(addMonths(new Date(), 1), "yyyy-MM-dd"));

  const [enableNotificationSetting, setEnableNotificationSetting] = useState(false);

  const mediaToken = useSelector((state) => state.mediaUpload.token);

  /** @type { import("../../reducers/mediaUploadReducer").MediaUploadFile[] } */
  const mediaFiles = useSelector((state) => state.mediaUpload.files);

  const [redoAutotranslate, setRedoAutoTranslate] = useLocalStorage("NewsPostModal_AutoTranslateConfig", {
    title: false,
    content: false,
  });

  // Props
  const { subTypeId, feedType, addToast, postId, updateModalPage, showDialog, hideModalPage } = props;

  // Refs
  const unsavedChanges = useRef(false);
  const formDataRef = useRef();
  const [formData, setFormData] = useState({
    title: "",
    title_fl: "",
    content: "",
    content_fl: "",
    date: format(new Date(), "yyyy-MM-dd"),
    time: null,
    endDate: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
    endTime: null,
    images: [],
    likes: [],
    comments: [],
    files: [],
    links: [],
    allowLikesAndComments: 1,
    points: 0,
    videoEmbed: "",
    targetGroup: {
      users: [],
      userGroups: [],
      masterGroups: [],
      customGroups: [],
      jobTitles: [],
    },
    hideAuthorInApp: 0,
    sendPushNotificationOnCreate: 0,
  });

  formDataRef.current = formData;

  useEffect(() => {
    dispatch({
      type: RESET_MEDIA_UPLOAD,
    });
    dispatch({ type: SET_MEDIA_CONTEXT, payload: "NEWS_POST" });

    req()("meta/app-languages")
      .then(({ data }) => {
        setAppLanguages(data);

        if (enableAutoTranslateForNewsModule) setIsUsingPrimaryLanguage(isUserUsingPrimaryLangauge(data));
      })
      .catch(() => {
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "60000",
          title: lang.networkError,
          content: lang.couldNotFetchLanguages,
        });
      });
  }, []);

  function isUserUsingPrimaryLangauge(appLanguages) {
    if (user.language !== appLanguages.primary.abbreviation.toLowerCase()) return false;

    return true;
  }

  // Oh boy is this overly complicated!
  // When we go to edit a post (this is determined by the fact that a postId is present) then we
  // need to display the input for title_fl and content_fl IF the app has a foreign-language AND
  // the user has actually put content into it. BUT we should only do it once and we have to wait
  // for the app-languages to load AND the post-content to load. So there is potential for a
  // race-conditions where either once hasn't loaded yet, stale closure data and out-of-sync
  // useState-variables.. It works this way now so be careful touching this
  useEffect(() => {
    if (
      !autoEnableForeignLangugeHasCompleted &&
      postId &&
      appLanguages.foreign &&
      (formData.title_fl || formData.content_fl)
    ) {
      setAutoEnableForeignLangugeHasCompleted(true);
      setEnableForeignLanguage(true);
    }
  }, [formData, appLanguages]);

  // Checks if notification-setting should be disabled or not whenever formData changes.
  // The logic is basically that if the date isn't today OR a startTime is chosen, then notifications
  // should no be enabled
  useEffect(() => {
    setEnableNotificationSetting(shouldNotificationOptionBeAvailableInPostModal({ formData }));
  }, [formData]);

  // Checks if a post is being edited. IF the post is being edited, a full version of the post
  // is fetched from the api. (some data will be excluded in the feed)
  useEffect(() => {
    if (postId) {
      setLoading(true);
      req()(`news/${subTypeId}/${postId}?editMode=1`)
        .then(({ data: post }) => {
          let localFormData = {
            title: post.title || "",
            title_fl: post.title_fl || "",
            content: post.content || "",
            content_fl: post.content_fl || "",
            date: post.date
              ? post.date.length > 10
                ? format(parse(post.date, "yyyyMMddHHmm", 0), "yyyy-MM-dd")
                : format(parse(post.date, "yyyyMMdd", 0), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd"),
            time: post.date ? (post.date.length > 10 ? format(parse(post.date, "yyyyMMddHHmm", 0), "HH:mm") : null) : null,
            endDate: post.endDate
              ? post.endDate.length === 12
                ? format(parse(post.endDate, "yyyyMMddHHmm", 0), "yyyy-MM-dd")
                : format(parse(post.endDate, "yyyyMMdd", 0), "yyyy-MM-dd")
              : null,
            endTime: post.endDate
              ? post.endDate.length > 10
                ? format(parse(post.endDate, "yyyyMMddHHmm", 0), "HH:mm")
                : null
              : null,
            images: post.images || [],
            likes: post.likes || [],
            comments: post.comments || [],
            files: post.files || [],
            links: post.links || [],
            allowLikesAndComments: post.allowLikesAndComments ? 1 : 0,
            points: post.points || 0,
            targetGroup: post.targetGroup || formData.targetGroup,
            hideAuthorInApp: post.hideAuthorInApp ? 1 : 0,
            metadata: post.metadata,
            videoEmbed: post.videoEmbed || "",
          };

          if (post.video && post.video.video && post.video.baseURL) {
            localFormData.images = [
              {
                baseURL: post.video.baseURL,
                video: post.video.video,
              },
            ];
          }

          if (post.images) {
            post.images.forEach((url) => {
              /**
               * @type { import("../../reducers/mediaUploadReducer").MediaUploadFile }
               */
              const media = {
                id: url.image.split(".")[0],
                url: url.baseURL + url.image,
                type: "image",
                file: {
                  type: "image/jpeg",
                },
                state: "pre-existing",
              };

              dispatch(addPreExistingMedia(media));
            });
          }

          if (post.video && post.video.video && post.video.baseURL) {
            /**
             * @type { import("../../reducers/mediaUploadReducer").MediaUploadFile }
             */
            const media = {
              id: post.video.video.split(".")[0],
              url: post.video.baseURL + post.video.video,
              type: "video",
              file: {
                type: "video/mp4",
              },
              state: "pre-existing",
            };

            dispatch(addPreExistingMedia(media));
          }

          setFormData(localFormData);

          setDate(localFormData.date);
          setTime(stringToTimePickerValue(localFormData.time));

          setEndDate(localFormData.endDate);
          setEndTime(stringToTimePickerValue(localFormData.endTime));

          setLoading(false);
        })
        .catch((err) => {
          addToast({ template: "error" });
        });
    }

    updateModalPage({
      closeCallback: () => {
        if (unsavedChanges.current) {
          showDialog({
            title: lang.unsavedChanges,
            content: lang.dialogUnsavedChangesContent,
            primaryActionTitle: lang.yesCloseForm,
            primaryAction: hideModalPage,
            secondaryActionTitle: lang.noDontCloseForm,
            styleType: "error",
            icon: <AlertDecagramIcon />,
          });
        } else {
          hideModalPage();
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  function savePost() {
    /** Validate date:
     * 1. Check that title and content is set
     * 2. Check that endDate is greater than date
     * 3. Check that title is less than XXX characters
     * 3. Check that content is less than XXX characters
     * 4. Format dates
     */

    let apiReadyData = {
      title: formData.title ? formData.title.trim() : "",
      title_fl: formData.title_fl && enableForeignLanguage ? formData.title_fl.trim() : "",
      content: formData.content ? addActualLineBreaksToMarkdownString(formData.content.trim()) : "",
      content_fl:
        formData.content_fl && enableForeignLanguage ? addActualLineBreaksToMarkdownString(formData.content_fl.trim()) : "",
      date: formatAndCombineDatetime(formData.date, formData.time || format(new Date(), "HH:mm")),
      endDate: formData.endDate ? formatAndCombineDatetime(formData.endDate, formData.endTime || "23:59") : null,
      points: parseInt(formData.points),
      allowLikesAndComments: parseInt(formData.allowLikesAndComments) === 1 ? true : false,
      files: formData.files,
      targetGroup: formData.targetGroup,
      redoTranslate: redoAutotranslate,
      hideAuthorInApp: parseInt(formData.hideAuthorInApp) === 1 ? true : false,
      sendPushNotificationOnCreate: parseInt(formData.sendPushNotificationOnCreate) === 1 ? true : false,
      videoEmbed: getEmbedLink(formData.videoEmbed) || undefined,
    };

    // If notificationSetting isn't available (due to time or date)
    // then overwrite whatever could be potentially chosen so push notificaitons isn't send
    if (!enableNotificationSetting) {
      apiReadyData.sendPushNotificationOnCreate = false;
    }

    let baseToast = {
      styleType: "error",
      icon: <AlertOctagonIcon />,
      duration: "60000",
    };

    // Ensure that all media is finished uploading
    if (mediaFiles.length > 0) {
      //TODO: Add translation
      if (mediaFiles.some((media) => ["pending", "uploading"].includes(media.state))) {
        addToast({
          ...baseToast,
          title: lang.errorNewsMediaNotFinishedUploadingTitle,
          content: lang.errorNewsMediaNotFinishedUploadingContent,
        });
        return;
      }
    }

    // Format media
    if (mediaFiles.length > 0) {
      apiReadyData.media = mediaFiles.map((media) => {
        let type = media.file.type.startsWith("image") ? "image" : "video";

        return {
          id: media.id,
          type,
          url: {
            base: `https://res.cloudinary.com/toecho/${type}/upload/`,
            file: media.url.replace(`https://res.cloudinary.com/toecho/${type}/upload/`, ""),
          },
        };
      });
    } else {
      apiReadyData.media = [];
    }

    apiReadyData.mediaToken = mediaToken;

    // Assume that image/video's are images since only 1 video is allowed
    if (formData.images.length > 1) {
      apiReadyData.images = formData.images;
      // If only 1 file is present check if it's a video
    } else if (formData.images.length === 1 && formData.images[0].fileType === "video") {
      apiReadyData.video = formData.images[0];
      // If only 1 file is present check if it's an image
    } else if (formData.images.length === 1 && formData.images[0].fileType === "image") {
      apiReadyData.images = formData.images;
    }

    // Check title and content
    // Post has to either have content and title
    if (!isContentFilled({ apiReadyData, appLanguages, enableForeignLanguage })) {
      addToast({
        ...baseToast,
        title: lang.errorNewsSomethingsMissing,
        content: lang.errorNewsMarkedFieldsMissing,
      });
      return;
    }

    if (apiReadyData.endDate < apiReadyData.date && apiReadyData.endDate !== null) {
      addToast({
        ...baseToast,
        title: lang.errorNewsInvalidDatesTitle,
        content: lang.errorNewsInvalidDatesContent,
      });
      return;
    }

    setSavingPost(true);

    if (!postId) {
      req().post(`news/${subTypeId}/`, apiReadyData).then(thenHandler).catch(catchHandler);
    } else {
      req().patch(`news/${subTypeId}/${postId}`, apiReadyData).then(thenHandler).catch(catchHandler);
    }

    function thenHandler() {
      setSavingPost(false);

      dispatch(updateUnreadCount({ subTypeId }));
      props.resetFeeds();
      props.getPosts({ subTypeId, feedType });
      props.hideModalPage();
    }

    function catchHandler() {
      setSavingPost(false);

      addToast({
        ...baseToast,
        title: lang.AddingPostError,
        content: lang.tryAgainOrContactSupport,
      });
    }
  }

  function isContentFilled({ apiReadyData, appLanguages, enableForeignLanguage }) {
    // if app has a foreign language AND user has enabled it in the form
    // then we require foreign content and title to have value
    if (appLanguages.foreign && enableForeignLanguage && (!apiReadyData.title_fl || !apiReadyData.content_fl)) return false;
    // No matter what we require title and content to have value
    if (!apiReadyData.title || !apiReadyData.content) return false;

    // didn't return earlier. Must be fine :)
    return true;
  }

  function onChange(e) {
    unsavedChanges.current = true; // Enables the close-form dialog
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    formDataRef.current = formData;
  }

  /**
   * @param {DateTimePickerValue} update
   */
  function onDateTimeChange({ date, time }) {
    if (date !== formData.date) {
      if (isBefore(new Date(endDate), new Date(date))) {
        setContent("endDate", date);
        setEndDate(date);

        addToast({
          content: lang.movedEndDateForwards,
        });

        if (time !== null && endTime !== null && isTimeBefore(endTime, time)) {
          setContent("endTime", time);
          setEndTime(time);
        }
      }

      setContent("date", date);
      setDate(date);
    }

    if (date == endDate && time !== null && endTime !== null) {
      if (isTimeBefore(endTime, time)) {
        setContent("endTime", time);
        setEndTime(time);

        addToast({
          content: lang.movedEndDateForwards,
        });
      }
    }

    setContent("time", timePickerValueToString(time));
    setTime(time);
  }

  function onStartTimePickerToggle() {
    if (time === null) {
      if (isToday(parse(date, "yyyy-MM-dd", 0))) {
        const now = format(new Date(), "HH:mm");

        onDateTimeChange({ date, time: stringToTimePickerValue(now) });
      } else {
        onDateTimeChange({ date, time: stringToTimePickerValue("00:00") });
      }
    } else {
      onDateTimeChange({ date, time: null });
    }
  }

  /**
   * @param {DateTimePickerValue} update
   */
  function onDateTimeEndChange({ date: newEndDate, time: newEndTime }) {
    if (newEndDate !== formData.endDate) {
      if (isBefore(new Date(newEndDate), new Date(date))) {
        setContent("date", newEndDate);
        setDate(newEndDate);

        addToast({
          content: lang.movedStartDateBackwards,
        });

        if (newEndTime !== null && time !== null && isTimeBefore(newEndTime, time)) {
          setContent("time", newEndTime);
          setTime(newEndTime);
        }
      }

      setContent("endDate", newEndDate);
      setEndDate(newEndDate);
    }

    if (date == newEndDate && newEndTime !== null && time !== null) {
      if (isTimeBefore(newEndTime, time)) {
        setContent("time", newEndTime);
        setTime(newEndTime);

        addToast({
          content: lang.movedStartTimeBackwards,
        });
      }
    }

    setContent("endTime", timePickerValueToString(newEndTime));
    setEndTime(newEndTime);
  }

  function onEndTimePickerToggle() {
    if (endTime === null) {
      onDateTimeEndChange({ date: endDate, time: stringToTimePickerValue("23:59") });
    } else {
      onDateTimeEndChange({ date: endDate, time: null });
    }
  }

  /**
   * @param {string} name
   * @param {any} value
   */
  function setContent(name, value) {
    unsavedChanges.current = true; // Enables the close-form dialog
    setFormData((formData) => {
      return {
        ...formData,
        [name]: value,
      };
    });
    formDataRef.current = formData;
  }

  function getContentComponent() {
    return (
      <div className={componentStyles(primaryColor)}>
        <div className="form-container">
          {enableAutoTranslateForNewsModule && (!postId || redoAutotranslate) && (
            <InformationBox
              icon={<TranslateIcon />}
              title={lang.autoTranslateEnabled}
              description={lang.autoTranslateEnabledDescription}
              style={{ marginBottom: "1rem" }}
            />
          )}

          {/************** Title **************/}

          <h2>{lang.title} *</h2>

          {/*********** Translate button ********/}
          {postId && enableAutoTranslateForNewsModule && (
            <div className="button-container">
              <button
                id="autotranslate__title-checkbox"
                className={`autoTranslate-button ${redoAutotranslate.title ? "checked" : ""}`}
                onClick={() =>
                  redoAutotranslate.title
                    ? setRedoAutoTranslate({ ...redoAutotranslate, title: false })
                    : setRedoAutoTranslate({ ...redoAutotranslate, title: true })
                }
              >
                {redoAutotranslate.title ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </button>
              <label className="form-labels translateText" htmlFor="autotranslate__title-checkbox">
                {lang.redoAutoTranslationTitleDescription}
              </label>
            </div>
          )}

          {!postId || !redoAutotranslate.title || isUsingPrimaryLanguage ? (
            <>
              {enableForeignLanguage && (
                <label className="form-labels" htmlFor="">
                  {appLanguages.primary.name}
                </label>
              )}
              <TextInput
                style={{ marginBottom: enableForeignLanguage ? "1rem" : "2rem" }}
                onChange={onChange}
                name="title"
                value={formData.title}
              />
            </>
          ) : (
            <></>
          )}

          {enableForeignLanguage && (!redoAutotranslate.title || !isUsingPrimaryLanguage) && (
            <>
              <label className="form-labels" htmlFor="">
                {appLanguages.foreign.name}
              </label>
              <TextInput style={{ marginBottom: "2rem" }} onChange={onChange} name="title_fl" value={formData.title_fl} />
            </>
          )}

          {/************** Content **************/}
          <h2>{lang.content} *</h2>

          {/*********** Translate button ********/}
          {postId && enableAutoTranslateForNewsModule && (
            <div className="button-container">
              <button
                id="autotranslate__content-checkbox"
                className={`autoTranslate-button ${redoAutotranslate.content ? "checked" : ""}`}
                onClick={() =>
                  redoAutotranslate.content
                    ? setRedoAutoTranslate({ ...redoAutotranslate, content: false })
                    : setRedoAutoTranslate({ ...redoAutotranslate, content: true })
                }
              >
                {redoAutotranslate.content ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </button>

              <label className="form-labels translateText" htmlFor="autotranslate__content-checkbox">
                {lang.redoAutoTranslationContentDescription}
              </label>
            </div>
          )}

          {!postId || !redoAutotranslate.content || isUsingPrimaryLanguage ? (
            <>
              {enableForeignLanguage && (
                <label className="form-labels" htmlFor="">
                  {appLanguages.primary.name}
                </label>
              )}
              <Suspense fallback={<p className="meta">Loading editor...</p>}>
                <TextEditor
                  style={{ marginBottom: enableForeignLanguage ? "1rem" : "2rem" }}
                  onChange={(e) => setContent("content", e)}
                  value={formData.content}
                />
              </Suspense>
            </>
          ) : (
            <></>
          )}

          {enableForeignLanguage && (!redoAutotranslate.content || !isUsingPrimaryLanguage) && (
            <>
              <label className="form-labels" htmlFor="">
                {appLanguages.foreign.name}
              </label>
              <Suspense fallback={<p className="meta">Loading editor...</p>}>
                <TextEditor
                  style={{ marginBottom: "2rem" }}
                  onChange={(e) => setContent("content_fl", e)}
                  value={formData.content_fl}
                />
              </Suspense>
            </>
          )}

          {/************** Images **************/}
          <h2>{lang.imagesOrVideo}</h2>

          {mediaFiles.find((media) => media.type === "video") && (
            <InformationBox
              icon={<VideoIcon />}
              title={lang.infobox_createNewsVideoSelected_title}
              description={lang.infobox_createNewsVideoSelected}
              style={{ marginBottom: "2rem" }}
            />
          )}

          <MediaUpload
            token={mediaToken}
            limits={{
              videos: formData.videoEmbed ? 0 : 1,
            }}
          />

          {/************** Video Embed TODO: Translation **************/}
          <h2>{lang.videoEmbed}</h2>

          {mediaFiles.find((media) => media.type === "video") && (
            <span style={{ whiteSpace: "wrap" }}>{lang.onlySingleVideoAllowed}</span>
          )}

          <EmbedLinkInput
            disabled={mediaFiles.find((media) => media.type === "video")}
            value={formData["videoEmbed"]}
            onChange={(e) => setContent("videoEmbed", e.target.value)}
            style={{ marginBottom: "2rem" }}
          />

          {/************** Meta - Likes and comments **************/}
          <h2>{lang.allowLikesAndComments}</h2>
          <DropDownList
            dropDownListContent={[
              { id: 1, title: lang.yes },
              { id: 0, title: lang.no },
            ]}
            style={{ marginBottom: "2rem" }}
            selectedContentId={formData.allowLikesAndComments}
            onChange={onChange}
            name="allowLikesAndComments"
          />

          {/************** Meta - Points **************/}
          <h2>{lang.points}</h2>

          {/* If a targetGroup is selected, then this post can't give any points */}
          {isTargetGroupEmpty(formData.targetGroup) ? (
            <DropDownList
              dropDownListContent={[
                { id: 0, title: lang.noPoints },
                { id: 1, title: "1" },
                { id: 2, title: "2" },
                { id: 3, title: "3" },
                { id: 4, title: "4" },
                { id: 5, title: "5" },
              ]}
              style={{ marginBottom: "2rem" }}
              selectedContentId={formData.points || 0}
              onChange={onChange}
              placeholder={lang.choosePoints}
              name="points"
            />
          ) : (
            <InformationBox
              icon={<AccountGroupIcon />}
              title={`${lang.targetGroup} ${lang.chosen?.toLowerCase()}`}
              description={lang.noPointsForPostsWithTargetGroup}
              style={{ marginBottom: "2rem" }}
            />
          )}

          {/************** Meta - Target Group **************/}
          {(user.admin || frontendAdminMappings.includes(subTypeId)) && (
            <>
              <h2>{lang.targetGroup}</h2>
              <TargetGroupSelector
                targetGroup={formData.targetGroup}
                onTargetGroup={(targetGroup) => setContent("targetGroup", targetGroup)}
                style={{ marginBottom: "2rem" }}
                noUsersSelectedOverwrite={`${lang.all} ${lang.users?.toLowerCase()}`}
              />
            </>
          )}

          {/************** Date **************/}
          <h2>{lang.date}</h2>

          <label className="form-labels" htmlFor="">
            {lang.showFrom}
          </label>
          <DateTimePicker
            value={{ date, time }}
            onChange={onDateTimeChange}
            onTimePickerToggle={onStartTimePickerToggle}
            className="date-picker"
            label={lang.setPostTime}
          />

          <div className="date-picker-show-to-wrapper">
            <label className="form-labels" style={{ alignContent: "flex-end", marginBottom: "0rem" }} htmlFor="">
              {lang.showTo}
            </label>

            {formData.endDate && (
              <ButtonRounded
                onClick={() => {
                  setFormData({ ...formData, endDate: null });
                  setEndDate(null);
                }}
                secondary={true}
                size="small"
              >
                {lang.noEndDate}
              </ButtonRounded>
            )}
          </div>

          {formData.endDate && (
            <DateTimePicker
              value={{ date: endDate, time: endTime }}
              onChange={onDateTimeEndChange}
              className="date-picker"
              label={lang.setEndingTime}
              onTimePickerToggle={onEndTimePickerToggle}
            />
          )}

          {!formData.endDate && (
            <InformationBox
              icon={<AlertCircleOutlineIcon />}
              title={lang.showsForever}
              style={{ marginBottom: "2rem" }}
              children={
                <>
                  <p className="information-box__description" style={{ marginBottom: "0.5rem" }}>
                    {lang.showsForeverDescription}
                  </p>
                  <ButtonRounded
                    onClick={() => {
                      setFormData({ ...formData, endDate: format(addMonths(new Date(), 1), "yyyy-MM-dd") });
                      setEndDate(format(addMonths(new Date(), 1), "yyyy-MM-dd"));
                    }}
                    secondary={true}
                    size="small"
                  >
                    {lang.addEndDate}
                  </ButtonRounded>
                </>
              }
            />
          )}

          {/************** File Upload **************/}
          <h2 style={{ marginTop: "2rem" }}>{upperCaseFirstLetter(lang.files)}</h2>
          <FileUploadMultiple
            value={formData.files}
            disabledSubmitButton={() => setFileUploadDisableButton(true)}
            enableSubmitButton={() => setFileUploadDisableButton(false)}
            onChange={(e) => {
              setFormData((formData) => {
                return {
                  ...formData,
                  files: e,
                };
              });
              formDataRef.current = formData;
            }}
          />

          {/************** Hide Author **************/}
          <h2 style={{ marginTop: "2rem" }}>{lang.hideAuthor}</h2>
          <label className="form-labels" htmlFor="sendPushNotificationOnCreate">
            {lang.hideAuthorDescription}
          </label>
          <DropDownList
            dropDownListContent={[
              { id: 1, title: lang.yes },
              { id: 0, title: lang.no },
            ]}
            style={{ marginBottom: "2rem" }}
            selectedContentId={formData.hideAuthorInApp}
            onChange={onChange}
            name="hideAuthorInApp"
          />

          {enablePushNotifications && (
            <>
              {/************** Notifications **************/}
              <h2 style={{ marginTop: "2rem" }}>{lang.notification}</h2>
              {!postId && (
                <label className="form-labels" htmlFor="sendPushNotificationOnCreate">
                  {lang.newsPostNotificationMetaExplanation}
                </label>
              )}

              {/* When editing a post */}
              {postId && (
                <InformationBox>
                  {formData?.metadata?.pushNotificationSentOnCreate ? (
                    <>
                      <BellOutlineIcon style={{ width: "1.15rem", height: "1.15rem", marginBottom: "-3px" }} />{" "}
                      {lang.notificationWasChosen}
                    </>
                  ) : (
                    <>
                      <BellOffOutlineIcon style={{ width: "1.15rem", height: "1.15rem", marginBottom: "-3px" }} />{" "}
                      {lang.notificationWasNotChosen}
                    </>
                  )}
                </InformationBox>
              )}

              {/* When creating a post */}
              {!postId && enableNotificationSetting && (
                <DropDownList
                  dropDownListContent={[
                    { id: 0, title: lang.dontSendNotificationOnCreate },
                    { id: 1, title: lang.sendNotificationOnCreate },
                  ]}
                  style={{ marginBottom: "2rem" }}
                  selectedContentId={formData.sendPushNotificationOnCreate}
                  onChange={onChange}
                  name="sendPushNotificationOnCreate"
                />
              )}

              {!postId && !enableNotificationSetting && (
                <InformationBox icon={<InformationOutlineIcon />} title={lang.infobox_dateOrTimeChosen_title}>
                  {lang.infobox_dateOrTimeChosen_content}
                  <ButtonRounded
                    size="small"
                    secondary={true}
                    style={{ marginTop: "0.5rem" }}
                    onClick={() => {
                      setTime(null);
                      setDate(format(new Date(), "yyyy-MM-dd"));
                      setFormData({ ...formDataRef.current, time: null, date: format(new Date(), "yyyy-MM-dd") });
                    }}
                  >
                    {lang.infobox_dateOrTimeChosen_action}
                  </ButtonRounded>
                </InformationBox>
              )}
            </>
          )}

          <p style={{ marginTop: "3rem", marginBottom: "3rem" }} className="meta">
            * {lang.requiredField}
          </p>

          {/************** Actions **************/}
          {appLanguages.foreign && !enableAutoTranslateForNewsModule && (
            <Button
              buttonType="secondary"
              style={{ marginBottom: "0.5rem" }}
              onClick={() => setEnableForeignLanguage(!enableForeignLanguage)}
            >
              <TranslateIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 0.4rem -3px 0" }} />
              {enableForeignLanguage ? lang.deactivate : lang.activate} {lang.multilingualContent}
            </Button>
          )}

          <Button buttonType="primary" active={savingPost} onClick={savePost} data-test-id="btn-add-news-post">
            <PlusIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 0.4rem -3px 0" }} />
            {postId ? lang.saveChanges : lang.createPost}
          </Button>
        </div>
      </div>
    );
  }

  function getPreviewComponent(foreign = false) {
    // In certain browser the datepicker will fire the onChange event with an empty string
    // while the user is manually typing the date. This will cause the preview to crash.
    if (!date || !endDate) return null;

    let postObj = {
      id: 0,
      title: foreign ? formData.title_fl : formData.title,
      content: foreign ? formData.content_fl : formData.content,
      author: user,
      images: formData.images,
      date: format(parse(formData.date, "yyyy-MM-dd", 0), "yyyyMMdd"),
      links: formData.links,
      files: formData.files,
      points: formData.points,
      hideAuthorInApp: parseInt(formData.hideAuthorInApp) === 1 ? true : false,
    };

    postObj.content = addActualLineBreaksToMarkdownString(postObj.content);

    // eslint-disable-next-line
    if (formData.allowLikesAndComments == 1) {
      postObj.likes = formData.likes;
      postObj.comments = formData.comments;
    } else {
      if (postObj.hasOwnProperty("likes")) delete postObj.likes;
      if (postObj.hasOwnProperty("comments")) delete postObj.comments;
    }

    if (formData.images.length === 1 && formData.images[0].fileType === "video") {
      postObj.video = formData.images[0];
    }
    return <NewsPost post={postObj} disableInteractionsPreview={true} style={{ margin: "1rem auto" }} />;
  }

  function getTabs() {
    const tabs = [lang.content];
    if (appLanguages.primary.name) {
      tabs.push(`${lang.preview} ${appLanguages.primary.name.toLowerCase()}`);
    }
    if (appLanguages.primary.name && enableForeignLanguage) {
      tabs.push(`${lang.preview} ${appLanguages.foreign.name.toLowerCase()}`);
    }
    return tabs;
  }

  function getTabViews() {
    let tabViews = [getContentComponent(), getPreviewComponent()];

    if (enableForeignLanguage) {
      tabViews.push(getPreviewComponent(true));
    }
    return tabViews;
  }

  return loading ? (
    <InlineSpinner style={{ marginTop: "2rem" }} title="Loading post..." />
  ) : (
    <>
      <TabBar
        activeTabIndex={activeTabIndex}
        tabs={getTabs().map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTabIndex(tabIndex);
          },
        }))}
      />
      <TabView activeTabIndex={activeTabIndex} tabs={getTabViews()} useScrollView={true} />
    </>
  );
}

const componentStyles = () => css`
  h2 {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    color: var(--black);
  }

  h3 {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 0.9rem;
    color: var(--black);
  }

  .form-container {
    background-color: var(--white);
    padding: 1rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1rem auto;
      border: 1px var(--midGrey) solid;
      border-radius: 3px;
      max-width: ${breakpoints.md}px;
    }
  }

  .date-picker {
    margin-bottom: 0.5rem;

    select {
      background-color: var(--lightGrey);
    }
  }

  .date-picker-show-to-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  label.form-labels {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--darkGrey);
    font-size: 0.9rem;
    white-space: normal;
  }

  .translateText {
    margin-left: 0.2rem;
    margin-top: 1.5px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    margin-left: -0.625rem;
  }

  .autoTranslate-button {
    margin-bottom: 1rem;
    background: none;
    border: none;
    &.checked {
      color: var(--primary-color);
    }
    svg {
      width: 1.15rem;
      height: 1.15rem;
      margin-left: 0.5rem;
      padding-right: -0.2rem;
      margin-top: 2px;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
  getPosts: bindActionCreators(getPosts, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsPostModal);

NewsPostModal.propTypes = {
  /** The id of the post to which the comment is being edited */
  postId: PropTypes.number,
  /** The comment being edited (it has more attributes, but only id and comment is needed as the author is implicitly defined through auth) */
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
  }),
};
