import { addToast } from "../../../actions/uiActions";
import store from "../../../store";
import getFormattedHour from "../../../utilities/get-formatted-hour";
import requestUtility from "../../../utilities/request-utility";

/**
 *
 * @param {Object} anon
 * @param {Object} anon.page - pageObject of the current page
 */
async function getRejectedRegistrations({ page }) {
  try {
    const selectedUser = store.getState().timeRegistration.selectedUser;

    let URL = `time-registrations/${page.dataId}/time-sheets/rejected`;

    if (selectedUser) URL += `?selectedUserId=${selectedUser?.id}`;

    let { data: rejectedRegistrations } = await requestUtility()(URL);

    rejectedRegistrations = rejectedRegistrations.map((registration) => ({
      ...registration,
      formattedHour: getFormattedHour(registration.hours),
    }));

    return rejectedRegistrations;
  } catch (err) {
    store.dispatch(addToast({ template: "error" }));
    throw new Error("getRejectedRegistrations failed");
  }
}

export default getRejectedRegistrations;
