import React, { useEffect, useState } from "react";

import TimePicker from "./TimePickerV2";
import DatePicker from "./DatePicker";
import { format, isAfter, isToday, parse } from "date-fns";
import { css } from "emotion";
import { useSelector } from "react-redux";
import colors from "../../style/colors";
import { CloseCircleIcon, CloseIcon, CrossIcon } from "mdi-react";
import { isDateAndHourNow, isDateToday } from "../news/utilities/validate-datetime";

/**
 * @typedef {Object} DateTimePickerValue
 * @property {string} date
 * @property {TimePickerValue} time
 */

/**
 *  @typedef {Object} DateRange
 * @property {string} from - Date in ISO format (YYYY-MM-DD)
 * @property {string} to - Date in ISO format (YYYY-MM-DD)
 */

/**
 * @typedef {Object} TimeRange
 * @property {string} from - Time in format HH:mm
 * @property {string} to - Time in format HH:mm
 */

/**
 * @typedef {Object} DateTimePickerProps
 * @property {DateTimePickerValue} value
 * @property {DateTimePickerValue} defaultValue
 * @property {Function} onChange
 * @property {Function} onTimePickerToggle
 * @property {string} label
 * @property {boolean} disabled
 * @property { DateTimePickerValue } min
 * @property { DateTimePickerValue } max
 * @property {string} [className]
 */

/**
 * Component that combines DatePicker and TimePicker
 * @param {DateTimePickerProps} props
 */
export default function DateTimePicker({
  value,
  label,
  onChange,
  onTimePickerToggle,
  disabled,
  min = { date: undefined, time: { hours: 0, minutes: 0 } },
  max = { date: undefined, time: { hours: 23, minutes: 59 } },
  className,
}) {
  const { primaryColor } = useSelector((state) => state.appConfig);

  const onDateChange = (date) => {
    onChange({
      ...value,
      date,
    });
  };

  const onTimeChange = (time) => {
    onChange({
      ...value,
      time,
    });
  };

  return (
    <div className={`${componentStyle(primaryColor)} ${className}`}>
      <DatePicker
        required
        min={min.date || undefined}
        max={max.date || undefined}
        onDateChange={onDateChange}
        value={value.date}
        disabled={disabled}
      />
      {value.time && (
        <div className="timepicker-container">
          <TimePicker
            value={value.time}
            min={{
              hours: isDateToday(value.date) ? min.time.hours || 0 : 0,
              minutes: isDateAndHourNow(value.date, value.time) ? min.time.minutes || 0 : 0,
            }}
            max={{
              hours: max.time ? max.time.hours || 23 : 23,
              minutes: max.date === value.date && max.time.hours === value.time.hours ? max.time.minutes : 59,
            }}
            onChange={(time) => onTimeChange(time)}
          />
          <CloseCircleIcon onClick={onTimePickerToggle} />
        </div>
      )}
      {!value.time && (
        <>
          <button className="" onClick={onTimePickerToggle} disabled={disabled}>
            + {label || "Add time selector"}
          </button>
        </>
      )}
    </div>
  );
}

const componentStyle = (primaryColor) => css`
  button {
    background-color: transparent;
    border: 0;
    color: ${primaryColor};
    cursor: pointer;
    font-size: 1rem;
    margin: 0.5rem 0;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .timepicker-container {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    select {
      background-color: var(--white);
    }

    svg {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      fill: var(--midGrey);

      &:hover {
        fill: ${colors.midDarkGrey};
        cursor: pointer;
      }

      &:active {
        fill: var(--darkGrey);
      }
    }
  }
`;
