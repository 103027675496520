// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Components
import ScrollView from "../../ui/ScrollView";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";

// Utilities and config
import breakpoints from "../../../config/breakpoints";

// Styles
import { InfoOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../../hooks/useAuthorModal";
import req from "../../../utilities/request-utility";
import { addDays, format, parse, startOfWeek } from "date-fns";
import scheduleRenderer from "../utilities/scheduleRenderer";

function getStartOfCurrentWeekDate() {
  return format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyyMMdd"); // Week starts on mondays (1) instead of sundays (0)
}

const ScheduleOverviewAvailableShiftsTab = (props) => {
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const authorModal = useAuthorModal();
  const { language: lang } = useSelector((state) => state.language);
  const user = useSelector((state) => state.auth.user);

  const [fetchDateStart, setFetchDateStart] = useState(getStartOfCurrentWeekDate());
  const [fetchDateEnd, setFetchDateEnd] = useState(format(addDays(new Date(), 7), "yyyyMMdd"));

  const [schedule, setSchedule] = useState({ loading: false, error: false, endOfFeed: false, data: [] });

  function getScheduleData() {
    if (schedule.loading || schedule.endOfFeed) return;

    setSchedule((prevState) => ({ ...prevState, loading: true }));

    req()(`schedules/shifts/available?startDate=${fetchDateStart}&endDate=${fetchDateEnd}`)
      .then(({ data }) => {
        // The next start fetch date is the day after the previous last day (if previous call ended 21/8 the next one beghins from 22/8)
        setFetchDateStart(format(addDays(parse(fetchDateEnd, "yyyyMMdd", 0), 1), "yyyyMMdd"));
        setFetchDateEnd(format(addDays(parse(fetchDateEnd, "yyyyMMdd", 0), 8), "yyyyMMdd"));
        setSchedule((prevState) => ({
          ...prevState,
          error: false,
          loading: false,
          endOfFeed: data.length === 0 ? true : false,
          data: [...prevState.data, ...data],
        }));
      })
      .catch(() => {
        setSchedule((prevState) => ({ ...prevState, error: true, loading: false }));
      });
  }

  // Get the groups
  useEffect(() => {
    getScheduleData();

    // Get schedules to display
  }, []);

  return (
    <ScrollView onScrollEnd={getScheduleData} style={{ height: "100%" }}>
      <div className={componentStyle(primaryColor)}>
        {/* This is the actual rendering */}
        {scheduleRenderer({ scheduleData: schedule.data, user, authorModal, lang })()}

        {/** Loading **/}
        {schedule.loading && !schedule.error && !schedule.endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.shifts.toLowerCase()}...`} />
        )}

        {/** End of feed with shifts **/}
        {schedule.data.length > 0 && schedule.endOfFeed && (
          <StatusBox
            style={{ margin: "2rem 0 5rem 0" }}
            icon={<InfoOutlineIcon />}
            title={lang.noMoreshifts}
            content={lang.reachedEndOfFeed}
          />
        )}

        {/** End of feed without shifts **/}
        {schedule.data.length === 0 && schedule.endOfFeed && (
          <StatusBox
            style={{ margin: "2rem 0 5rem 0" }}
            icon={<InfoOutlineIcon />}
            title={lang.noShiftsCreatedYet}
            content={lang.comeBackSoon}
          />
        )}
      </div>
    </ScrollView>
  );
};

const componentStyle = (primaryColor) => css`
  max-width: ${breakpoints.md}px;
  margin: auto;
  padding: 1rem 0 3rem 0;

  p.week {
    font-size: 1.7rem;
    margin: 5rem 0 1rem 0;
    color: ${primaryColor};
    font-weight: 700;

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  p.date {
    font-size: 1.25rem;
    margin: 2rem 0 1rem 0;
    color: ${primaryColor};
  }

  @media screen and (max-width: ${breakpoints.md + 20}px) {
    p.week,
    p.date {
      padding: 0 1rem;
    }
  }
`;

export default ScheduleOverviewAvailableShiftsTab;
