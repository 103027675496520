// Libs
import React from "react";
import { css } from "emotion";

// Style
import colors from "../../style/colors";

// Components
import { DeleteIcon } from "mdi-react";
import breakpoints from "../../config/breakpoints";
import Spinner from "./InlineSpinner";

const ImagePreview = (props) => {
  // Props
  const {
    baseURL,
    image,
    urlPrefix,
    onLoad,
    disableDelete,
    disabled,
    index,
    className,
    style,
    loadingImage = false,
    onFileRemove,
  } = props;

  // Local state

  return (
    <div className={`${componentStyle()} ${className}`} style={style} key={index}>
      {loadingImage ? (
        <Spinner size={25} />
      ) : (
        <img
          src={urlPrefix ? `${baseURL}${image}` : `${image.baseURL}h_${previewHeight}/${image.image}`}
          alt="comment-preview"
          onLoad={onLoad}
        />
      )}
      {!disableDelete && <DeleteIcon onClick={!disabled ? () => onFileRemove() : {}} />}
    </div>
  );
};

const previewHeight = 200;

const componentStyle = () => css`
  height: 110px;
  max-width: 120px;
  white-space: initial;
  position: relative;
  vertical-align: top;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border: 1px var(--midGrey) solid;
  border-radius: 3px;

  img {
    max-width: 120px;
    /* min-height: 110px; */
  }

  /* Delete icon */
  svg {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    border-bottom-left-radius: 4px;
    color: var(--darkGrey);
    border: 1px solid var(--midGrey);
    background-color: var(--white);
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.md}px) {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export default ImagePreview;
