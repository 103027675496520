import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToast, hideDialog, showDialog } from "../../../actions/uiActions";
import req from "../../../utilities/request-utility";
import { getPageBuilderPages } from "../../../actions/pageBuilderActions";
import { CheckIcon, ErrorIcon } from "mdi-react";

const UseDestroyPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deletePageRequest = async (pageId) => {
    try {
      await req().delete(`admin/pages/${pageId}`);
      dispatch(getPageBuilderPages());
      dispatch(
        addToast({
          title: "Modulet blev succesfuldt slettet",
          content: "",
          icon: <CheckIcon />,
          styleType: "success",
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: "Noget gik galt da vi prøvede at slette modulet",
          content: "",
          icon: <ErrorIcon />,
          styleType: "error",
          duration: 5000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const deletePage = (page) => {
    dispatch(
      showDialog({
        styleType: "warning",
        allowClosing: true,
        title: "Slet modul",
        content: `Du er ved at slette modulet "${page.title}". Er du sikker på at fortsætte?`,
        primaryActionTitle: "Slet",
        primaryAction: async () => {
          setLoading(true);
          dispatch(hideDialog());
          await deletePageRequest(page.id);
        },
        secondaryAction: () => dispatch(hideDialog()),
        secondaryActionTitle: "Annuller",
      })
    );
  };

  return { deletePage, loading };
};

export default UseDestroyPage;
