import React, { useState, useEffect } from "react";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import getPageFromId from "../../utilities/get-page-from-id";
import { useSelector } from "react-redux";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";
import ListItem from "../ui/ListItem";
import req from "../../utilities/request-utility";
import breakpoints from "../../config/breakpoints";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import { ChevronRightIcon, FeatureSearchOutlineIcon } from "mdi-react";
import getAppName from "../../utilities/get-app-name";
import InlineSpinner from "../ui/InlineSpinner";

function ScheduledChecklistNavigationList(props) {
  const pages = useSelector((s) => s.pages.pages);
  const lang = useSelector((s) => s.language.language);
  const page = getPageFromId(pages, props.match.params.pageId);

  const [scheduledChecklists, setScheduledChecklists] = useState({
    data: [],
    error: false,
    loading: true,
  });

  useEffect(() => {
    getScheduledChecklists();
  }, []);

  async function getScheduledChecklists() {
    try {
      let { data } = await req()(`scheduled-checklists`);
      setScheduledChecklists({ error: false, loading: false, data });
    } catch (err) {
      setScheduledChecklists({ error: true, loading: false });
    }
  }

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <ScrollView style={{ padding: "1rem 0" }}>
        {/* Loading */}
        {scheduledChecklists.loading && !scheduledChecklists.error && scheduledChecklists.data.length === 0 && (
          <InlineSpinner />
        )}

        {/* Error */}
        {!scheduledChecklists.loading && scheduledChecklists.error && <StatusBox />}

        {/* No content */}
        {!scheduledChecklists.loading && !scheduledChecklists.error && scheduledChecklists.data.length === 0 && (
          <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
        )}

        {/* Content */}
        {!scheduledChecklists.loading &&
          !scheduledChecklists.error &&
          scheduledChecklists.data.map((scheduledChecklist) => (
            <ListItem
              maxWidth={breakpoints.lg}
              clickable={true}
              onClick={() =>
                pageNavigatorV2({
                  path: `/${getAppName()}/scheduled-checklist/${page.id}?dataId=${scheduledChecklist.id}`,
                  direction: "forward",
                })
              }
              title={scheduledChecklist.title}
              iconRight={<ChevronRightIcon />}
              key={scheduledChecklist.id}
            />
          ))}
      </ScrollView>
    </Page>
  );
}

export default ScheduledChecklistNavigationList;
