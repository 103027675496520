import React, { useState, useEffect } from "react";
import { css } from "emotion";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import getPageFromId from "../../utilities/get-page-from-id";
import { useSelector } from "react-redux";
import ScrollView from "../ui/ScrollView";
import SkeletonPage from "../ui/SkeletonPage";
import req from "../../utilities/request-utility";
import breakpoints from "../../config/breakpoints";
import queryString from "query-string";
import colors from "../../style/colors";
import { format } from "date-fns/esm";
import ButtonRounded from "../ui/ButtonRounded";
import ScheduledChecklistTask from "./ScheduledChecklistTask";
import InlineSpinner from "../ui/InlineSpinner";
import { Fragment } from "react";
import { useRef } from "react";
import StatusBox from "../ui/StatusBox";

function ScheduledChecklist(props) {
  const pages = useSelector((s) => s.pages.pages);
  const lang = useSelector((s) => s.language.language);
  const page = getPageFromId(pages, props.match.params.pageId);
  const { dataId: scheduledChecklistId } = queryString.parse(window.location.search);

  const [checklistMetaData, setChecklistMetaData] = useState({ data: {}, error: false, loading: true });
  const [checklistTasks, setChecklistTasks] = useState({ data: [], error: false, loading: true, lastUpdate: "" });

  const checklistTasksDataRef = useRef(checklistTasks.data);

  useEffect(() => {
    checklistTasksDataRef.current = checklistTasks.data;
  }, [checklistTasks.data]);

  let interval;
  useEffect(() => {
    getChecklistMetaData();
    getChecklistTasks();

    interval = setInterval(getChecklistTasks, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function getChecklistMetaData() {
    try {
      let { data } = await req()(`scheduled-checklists/${scheduledChecklistId}/`);
      setChecklistMetaData({ error: false, loading: false, data });
    } catch (err) {
      setChecklistMetaData({ error: true, loading: false });
    }
  }
  async function getChecklistTasks() {
    try {
      setChecklistTasks({ ...checklistTasks, error: false, loading: true, data: [...checklistTasksDataRef.current] });
      let lastUpdate = format(new Date(), "hh:mm:ss");
      let { data } = await req()(`scheduled-checklists/${scheduledChecklistId}/categories`);
      setChecklistTasks({ error: false, loading: false, data, lastUpdate });
    } catch (err) {
      setChecklistTasks({ error: true, loading: false });
    }
  }

  if (!checklistMetaData.data) return <SkeletonPage></SkeletonPage>;
  return (
    <Page className={componentStyles()}>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <div className="header">
        <div className="content-wrapper">
          <h2>{checklistMetaData.data.title}</h2>
          <div className="update-wrapper">
            <p className="meta">
              {lang.lastUpdate}: {checklistTasks.lastUpdate}
            </p>

            <ButtonRounded onClick={getChecklistTasks} secondary={true} active={checklistTasks.loading}>
              {lang.update}
            </ButtonRounded>
          </div>
        </div>
      </div>

      <ScrollView className="scroll-view">
        <div className="content-wrapper">
          {checklistTasks.data && checklistTasks.data.length === 0 && checklistTasks.loading && !checklistTasks.error && (
            <InlineSpinner />
          )}
          {checklistTasks.error && !checklistTasks.loading && <StatusBox />}

          {checklistTasks.data &&
            checklistTasks.data.length > 0 &&
            checklistTasks.data.map((category) => (
              <Fragment key={`checklist-category__${category.categoryId}`}>
                {/* Show category name if there are multiple categories */}
                {checklistTasks.data.length > 1 && <h3>{category.name}</h3>}

                {/* show tasks in category */}
                {category.tasks.map((task) => (
                  <ScheduledChecklistTask
                    key={`scheduled-checklist-task__${task.id}`}
                    task={task}
                    checklist={checklistMetaData.data}
                    refreshChecklistTasks={() => getChecklistTasks(checklistTasks)}
                  />
                ))}
              </Fragment>
            ))}
        </div>
      </ScrollView>
    </Page>
  );
}

const basePadding = 0.65;

const componentStyles = () => css`
  position: relative;

  .header {
    background-color: var(--white);
    border-bottom: 1px var(--midGrey) solid;
    padding: ${basePadding}rem;
    width: 100vw;

    .content-wrapper {
      max-width: ${breakpoints.lg}px;
      margin: 0 auto;
    }

    h2 {
      font-weight: 400;
      font-size: 1.4rem;
    }

    .meta {
      color: var(--darkGrey);
    }

    .update-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      padding: 1rem;

      .update-wrapper {
        align-items: flex-end;
      }
    }
  }

  .scroll-view {
    padding: 1.5rem 0 5rem 0;
  }

  .content-wrapper {
    max-width: ${breakpoints.lg}px;
    margin: 0 auto;

    h3 {
      font-weight: 400;
      margin: 1.5rem 0 ${basePadding}rem 0;

      &:first-of-type {
        margin-top: 0;
      }

      @media screen and (max-width: ${breakpoints.lg}px) {
        margin-left: ${basePadding}rem;
      }
    }
  }
`;

export default ScheduledChecklist;
