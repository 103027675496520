import tinycolor from "tinycolor2";

const DESATURATION_AMOUNT = 50;

export function getHoverFromPrimaryColor(primaryColor) {
  return tinycolor(primaryColor).desaturate(DESATURATION_AMOUNT).setAlpha(0.08).toRgbString();
}
export function getActiveFromPrimaryColor(primaryColor) {
  return tinycolor(primaryColor).desaturate(DESATURATION_AMOUNT).setAlpha(0.13).toRgbString();
}
export function getSelectedFromPrimaryColor(primaryColor) {
  return tinycolor(primaryColor).desaturate(DESATURATION_AMOUNT).setAlpha(0.1).toRgbString();
}
