import req from "../utilities/request-utility";
import {
  GET_ALL_EQUIPMENT,
  GET_ALL_EQUIPMENT_SUCCESS,
  GET_ALL_EQUIPMENT_FAILURE,
  SET_ALL_EQUIPMENT_END_OF_FEED,
  GET_MY_EQUIPMENT,
  GET_MY_EQUIPMENT_SUCCESS,
  GET_MY_EQUIPMENT_FAILURE,
  SET_CURRENT_EQUIPMENT_BOOKING_ID,
  RESET_EQUIPMENT_BOOKING_STATE,
  SET_MY_EQUIPMENT_COUNT,
  SET_EQUIPMENT_CATEGORIES,
  SET_EQUIPMENT_FILTER_VALUE,
  REPLACE_ALL_EQUIPMENT_DATA,
  SET_PREVENT_STATE_RESET,
} from "../actions/actionTypes";

let lastUsedCategory = undefined;
let lastUsedStatus = undefined;
let lastUsedSearchterm = undefined;

export function getEquipment(refresh = false) {
  return function (dispatch, getState) {
    let state = getState().equipmentBooking;
    let appendResults;
    let offset;

    /* If category og bookingStatus has changed since last fetch, then replace results instead of appending to them */
    if (
      (lastUsedStatus === undefined || lastUsedStatus === state.filters.bookingStatus) &&
      (lastUsedCategory === undefined || lastUsedCategory === state.filters.categoryId) &&
      (lastUsedSearchterm === undefined || lastUsedSearchterm === state.searchterm) &&
      !refresh
    ) {
      appendResults = true;
      offset = state.allEquipment.data.length;
    } else {
      appendResults = false;
      offset = 0;
    }

    // Update "last used" values
    lastUsedCategory = state.filters.categoryId;
    lastUsedStatus = state.filters.bookingStatus;
    lastUsedSearchterm = state.searchterm;

    // Failsafe -> ignore if results are to be replaced
    if (appendResults === true && (state.allEquipment.endOfFeed === true || state.allEquipment.loading === true)) return;

    dispatch({ type: GET_ALL_EQUIPMENT });

    let URL = `equipment-booking/${state.currentEquipmentBookingId}/equipment`;
    URL += `?limit=10`;
    URL += `&offset=${offset}`;
    URL += `&categoryId=${state.filters.categoryId}`;
    URL += `&bookingStatus=${state.filters.bookingStatus}`;
    URL += `&searchterm=${state.searchterm}`;

    req()(URL)
      .then(({ data }) => {
        if (appendResults === true) dispatch({ type: GET_ALL_EQUIPMENT_SUCCESS, payload: data });
        if (appendResults === false) dispatch({ type: REPLACE_ALL_EQUIPMENT_DATA, payload: data });
        if (data.length === 0) dispatch({ type: SET_ALL_EQUIPMENT_END_OF_FEED });
      })
      .catch((err) => {
        dispatch({ type: GET_ALL_EQUIPMENT_FAILURE });
      });
  };
}
export function getMyBookings() {
  return function (dispatch, getState) {
    let state = getState().equipmentBooking;

    if (state.myBookings.endOfFeed === true || state.myBookings.loading === true) return;

    dispatch({ type: GET_MY_EQUIPMENT });

    req()(
      `equipment-booking/${state.currentEquipmentBookingId}/equipment?limit=10&offset=${state.myBookings.data.length}&showOnlyEquipmentBookedByMy=1`
    )
      .then(({ data }) => {
        dispatch({ type: GET_MY_EQUIPMENT_SUCCESS, payload: data });

        if (data.length < 10) dispatch({ type: SET_MY_EQUIPMENT_END_OF_FEED });
      })
      .catch((err) => dispatch({ type: GET_MY_EQUIPMENT_FAILURE }));
  };
}

export function getMyBookingsCount() {
  return function (dispatch, getState) {
    let state = getState().equipmentBooking;
    req()(`equipment-booking/${state.currentEquipmentBookingId}/equipment/booked-by-me-count`).then(({ data }) => {
      dispatch({ type: SET_MY_EQUIPMENT_COUNT, payload: data.count });
    });
  };
}

export function getCategories() {
  return function (dispatch, getState) {
    let state = getState().equipmentBooking;
    req()(`equipment-booking/${state.currentEquipmentBookingId}/categories`).then(({ data }) => {
      dispatch({ type: SET_EQUIPMENT_CATEGORIES, payload: data });
    });
  };
}

export function setFilterValue({ key, value }) {
  return {
    type: SET_EQUIPMENT_FILTER_VALUE,
    payload: { key, value },
  };
}

/** Will disable the state-reset action for 2 seconds */
export function disableResetStateTemporarily() {
  return function (dispatch, getState) {
    dispatch({ type: SET_PREVENT_STATE_RESET, payload: true });
    setTimeout(() => {
      dispatch({ type: SET_PREVENT_STATE_RESET, payload: false });
    }, 2000);
  };
}

export function resetState() {
  return function (dispatch, getState) {
    let state = getState().equipmentBooking;

    // Prevents state-rest (is applied for 2 seconds only)
    if (state.preventStateReset === true) return;

    dispatch({ type: RESET_EQUIPMENT_BOOKING_STATE });
  };
}

export function setEquipmentBookingId(id) {
  return {
    type: SET_CURRENT_EQUIPMENT_BOOKING_ID,
    payload: id,
  };
}
