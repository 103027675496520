import React from "react";

// Libraries
import { useDispatch } from "react-redux";

// Components
import ListItem from "../../ui/ListItem";
import UserProfile from "../../profile/UserProfile";
import ContactsMenuOptions from "../../contacts/components/ContactsMenuOptions";

// Config
import breakpoints from "../../../config/breakpoints";

// Styles
import colors from "../../../style/colors";

// Utilities
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import highlightMatch from "../../../utilities/highlight-match";
import getUserGroupTitle from "../../../utilities/get-user-group-title";

// Actions
import { showModalPage } from "../../../actions/uiActions";

// Reusable list item component for displaying a list of users
export const UserListItem = ({
  user,
  onClick = () => {},
  contentTestId = undefined,
  highlight = undefined,
  className = "",
  profilePictureSize = 36,
  showIconsRight = false,
  ...rest
}) => {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(
      showModalPage({
        title: "Profile",
        content: <UserProfile externalUserId={user.id} useExternalData={true} />,
      })
    );

    onClick();
  }

  return (
    <ListItem
      maxWidth={breakpoints.lg}
      clickable={true}
      onClick={handleClick}
      middleTitleStyle={{ fontSize: "0.8125rem", color: colors.black }}
      imageLeft={getProfilePicture(user, profilePictureSize, { marginRight: "0.5rem", marginBottom: "-3px" })}
      title={highlightMatch(user.name, highlight)}
      subTitle={getUserGroupTitle(user, highlight)}
      className={className}
      iconRight={showIconsRight && <ContactsMenuOptions {...user} />}
      {...rest}
    />
  );
};
