/**
 * Formats the time picker result from {@link TimePickerValue} into "HH:mm" format
 * @param {TimePickerValue} time
 */
function timePickerValueToString(time) {
  if (!time) {
    return null;
  }

  const hours = time.hours ? time.hours : "00";
  const minutes = time.minutes ? time.minutes : "00";
  return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
}

export default timePickerValueToString;
