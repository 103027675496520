import React from "react";
import useSWR from "swr";
import DropDown from "../../../../../ui/dropDown/components/DropDown";
import { swrFetcher } from "../../../../../../utilities/swr-fetcher";

const NewsPostsSortingDropdown = (props) => {
  const { data: options, isValidating: loading } = useSWR("admin/pages/configurations/news/sort-options", swrFetcher);

  return (
    <DropDown
      data-test-id={props["data-test-id"]}
      name={props.name}
      className={props.className}
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      usePlaceholder={props.usePlaceholder}
      options={options}
      loading={loading}
    />
  );
};

export default NewsPostsSortingDropdown;
