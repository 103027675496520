function saveToLocalStorage(key, value) {
  try {
    if (!key || !value) return;

    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw error;
  }
}

function registrationLocalStorageHandler(
  state = "saving",
  { id, fields, title, completedText, images, description, mailGroup }
) {
  if (state === "saving") {
    saveToLocalStorage(`registration-fields-${id}`, fields);
    saveToLocalStorage(`registration-title-${id}`, title);
    saveToLocalStorage(`registration-completedText-${id}`, completedText);
    saveToLocalStorage(`registration-images-${id}`, images);
    saveToLocalStorage(`registration-description-${id}`, description);
    saveToLocalStorage(`registration-mailGroup-${id}`, mailGroup);
  }
  if (state === "delete") {
    localStorage.removeItem(`registration-fields-${id}`);
    localStorage.removeItem(`registration-images-${id}`);
    localStorage.removeItem(`registration-description-${id}`);
    localStorage.removeItem(`registration-title-${id}`);
    localStorage.removeItem(`registration-completedText-${id}`);
    localStorage.removeItem(`registration-mailGroup-${id}`);
  }

  if (state === "get") {
    let returnedObject = {
      fields: [],
      images: [],
      description: null,
    };
    // Get from local fields (questions answers) if it exists
    let registrationFields = localStorage.getItem(`registration-fields-${id}`);

    // If it exist, parse it to an array
    if (registrationFields) {
      registrationFields = JSON.parse(registrationFields);
    }

    // Check if it storageRegistration is an array and is greater than 0
    if (Array.isArray(registrationFields) && registrationFields.length > 0) {
      returnedObject.fields = registrationFields;

      // Get images and set them if they exist
      let images = localStorage.getItem(`registration-images-${id}`);
      if (images) returnedObject.images = JSON.parse(images);

      // Get description and set them if they exist
      let description = localStorage.getItem(`registration-description-${id}`);
      if (description) returnedObject.description = JSON.parse(description);

      // Get the selectedReceiver and set then if they exist
      let mailGroup = localStorage.getItem(`registration-mailGroup-${id}`);
      if (mailGroup) returnedObject.mailGroup = JSON.parse(mailGroup);

      return returnedObject;
    }
  }
}
export default registrationLocalStorageHandler;
