import useLang from "../../../hooks/useLang";

const TotalCount = ({ fetchingRegistrations, totalSum }) => {
  const lang = useLang();

  // If in admin mode and no user has been selected, dont show a total count, as there is no hours to show for, before selecting a user

  // If the app is currently fetching registrations, show a skeleton to indicate it is loading
  if (fetchingRegistrations) {
    return (
      <div className="total-sum-container">
        <p>{lang.total}</p>
        <div className="fake-count"></div>
      </div>
    );
  }

  return (
    <div className="total-sum-container">
      <p>
        {lang.total} <span className="count">{totalSum}</span>
      </p>
    </div>
  );
};

export default TotalCount;
