import React from "react";
import useSWR from "swr";
import { swrFetcher } from "../utilities/swr-fetcher";

const UseRegistrationOptions = ({ groupBy = "", sort = "" }) => {
  let queryParams = "";

  if (groupBy) queryParams += `groupBy=${groupBy}&`;
  if (sort) queryParams += `sort=${sort}&`

  const formatRegistrationData = (data) => {
    let registrations = [];

    if (data?.active?.length > 0) {
      registrations.push({
        id: 1,
        title: "Aktiv",
        options: data.active.map((registration) => ({
          title: registration.title,
          id: registration.id,
        })),
      });
    }

    if (data?.inactive?.length > 0) {
      registrations.push({
        id: 2,
        title: "Inaktiv",
        options: data.inactive.map((registration) => ({
          title: registration.title,
          id: registration.id,
        })),
      });
    }

    return registrations;
  };

  const { data, mutate } = useSWR(`/admin/registrations?${queryParams}`, async (data) =>
    formatRegistrationData(await swrFetcher(data))
  );

  return { data, mutate };
};

export default UseRegistrationOptions;
