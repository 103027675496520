import { showThemeQuizFloatingActionButton } from "../actions/themeQuizActions";
import store from "../store";

function getQuizMappedToCurrentPage({ pageId, quizzes, frontPageMode }) {
  // Potential performance improvement: Return early instead of going over each item

  // Temp var to store result in
  let result = undefined;

  // Iterate over all quizzes' pageMappings (note the nested loop). Set the temp var to the matching pageMapping and quiz
  quizzes.forEach((quiz) =>
    quiz.pageMappings.forEach((pageMapping) => {
      if (pageId && pageMapping.pageId === pageId) {
        result = { pageMapping, quiz };
      }
      if (frontPageMode === true && pageMapping.showAtFrontPage === true) {
        result = { pageMapping, quiz };
      }
    })
  );

  // Return found pageMapping and quiz OR undefined if nothing were found
  return result;
}

export default function ({ pageId, frontPageMode = false }) {
  // Get quizes from Redux
  let state = store.getState();

  if (!state || !state.themeQuiz || !state.themeQuiz.quizzes) return;

  let quizzes = state.themeQuiz.quizzes;

  // If thre aren't any quizes don't do anything
  if (!quizzes || quizzes.length < 1) return;

  // Get the potential egg mapped to the current page
  let quizMappedToCurrentPage = getQuizMappedToCurrentPage({ pageId, quizzes, frontPageMode });

  // If current page has an egg: show it
  if (quizMappedToCurrentPage) {
    store.dispatch(
      showThemeQuizFloatingActionButton({
        quizId: quizMappedToCurrentPage.quiz.quizId,
        icon: quizMappedToCurrentPage.quiz.icon,
        campaignId: quizMappedToCurrentPage.pageMapping.campaignId,
      })
    );
  }
}
