// Config
import { SHOW_ALL, ACTIVATED, DEACTIVATED } from "../config/filterTypes";

function filterPages({ pages = [], filters }) {
  let tempPages = [...pages];

  if (filters.active === SHOW_ALL) {
    return tempPages;
  }

  if (filters.active === ACTIVATED) {
    let filteredPages = [];

    for (let page of tempPages) {
      if (page.active === 1) {
        filteredPages.push(page);
        if (Array.isArray(page) && page.pages.length > 0) {
          page.pages = filterPages({ pages: page.pages, filters });
        }
      }
    }

    return filteredPages;
  }

  if (filters.active === DEACTIVATED) {
    let filteredPages = [];

    for (let page of tempPages) {
      if (!page.active) {
        filteredPages.push(page);

        if (Array.isArray(page) && page.pages.length > 0) {
          page.pages = filterPages({ pages: page.pages, filters });
        }
      }
    }

    return filteredPages;
  }

  return tempPages;
}

export default filterPages;
