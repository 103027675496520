import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { CheckBoxOutlineBlankIcon, CheckboxMarkedIcon } from "mdi-react";
import PropTypes from "prop-types";
//Style
import common from "../../style/common";
import colors from "../../style/colors";
import {
  getSelectedFromPrimaryColor,
  getHoverFromPrimaryColor,
  getActiveFromPrimaryColor,
} from "../../utilities/color-modifiers";

/**
 * List for selection of multiple elements. If you need a list with only one selectable element at a time, see RadioGroup
 */
class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: [],
    };
  }

  componentDidMount() {
    if (this.props.selectedValues) this.setState({ selectedIds: this.props.selectedValues });
  }

  onSelect = (checkboxId) => {
    let checkboxIdIndex = this.state.selectedIds.indexOf(checkboxId);
    if (checkboxIdIndex === -1) {
      this.setState(
        {
          selectedIds: [...this.state.selectedIds, checkboxId],
        },
        () => this.props.onSelect(this.state.selectedIds)
      );
    } else {
      this.setState(
        {
          selectedIds: [
            ...this.state.selectedIds.slice(0, checkboxIdIndex),
            ...this.state.selectedIds.slice(checkboxIdIndex + 1),
          ],
        },
        () => this.props.onSelect(this.state.selectedIds)
      );
    }
  };

  render() {
    let { primaryColor } = this.props.appConfig;
    let { selectedIds } = this.state;
    let { disabled = false } = this.props;

    return (
      <div className={`${componentStyle(primaryColor)} ${this.props.className}`} style={this.props.style}>
        {this.props.options.map((checkbox, index) => (
          <label htmlFor={checkbox.id} onClick={() => (!disabled ? this.onSelect(checkbox.id) : {})} key={index}>
            {selectedIds.indexOf(checkbox.id) !== -1 ? (
              <div className="checkbox selected">
                <CheckboxMarkedIcon />
                <p>{checkbox.title}</p>
              </div>
            ) : (
              <div className="checkbox">
                <CheckBoxOutlineBlankIcon />
                <p>{checkbox.title}</p>
              </div>
            )}
          </label>
        ))}
      </div>
    );
  }
}
const componentStyle = (primaryColor) => css`
  border: 1px solid var(--midGrey);
  border-radius: 3px;
  transition: background-color 120ms ease;

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-appearance: none;
    border-bottom: 1px solid var(--midGrey);
    padding: 0.75rem 0.85rem;
    font-weight: 400;
    transition: background-color 120ms ease;

    svg {
      color: var(--midGrey);
      flex-shrink: 0;
    }

    p {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-family: ${common.fontStack};
    }

    &.selected {
      font-weight: 700;
      background-color: ${getSelectedFromPrimaryColor(primaryColor)};
      color: ${primaryColor};
    }

    &.selected svg {
      color: ${primaryColor};
    }
  }

  .checkbox:hover {
    cursor: pointer;
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
    transition: background-color 60ms ease;
  }

  .checkbox:active {
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
    transition: background-color 100ms ease;
  }

  label:last-child div {
    border-bottom: none;
  }
`;
CheckboxGroup.propTypes = {
  /** Array of items to be displayed, its structure should be  [ { id: XX, title: "YY" } ]*/
  options: PropTypes.array,

  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,

  /** Default values or target values **/
  selectedValues: PropTypes.array,
};
const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
});

export default connect(mapStateToProps, null)(CheckboxGroup);
