// Libs
import React, { useEffect, useState } from "react";

// Utilities
import req from "../../../utilities/request-utility";

const useUserGroups = () => {
  const [userGroups, setUserGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function getFilters() {
    try {
      setLoading(true);
      const { data: userGroups } = await req()(`user-groups`);
      setUserGroups(userGroups);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  return { userGroups, loading, error };
};

export default useUserGroups;
