// Actions
import {
  SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON,
  HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON,
  GET_ACTIVE_THEME_QUIZZES_SUCCESS,
  CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON,
} from "../actions/actionTypes";

// Config
import { durations } from "../config/animations";

// Utilities
import req from "../utilities/request-utility";

export function getActiveThemeQuizzes() {
  return async function (dispatch) {
    try {
      const { data: quizzes } = await req()(`quiz/active-theme-quizzes`);

      dispatch({
        type: GET_ACTIVE_THEME_QUIZZES_SUCCESS,
        payload: quizzes,
      });
    } catch (error) {}
  };
}

/** Shows the theme quiz's floating action button
 * @param {Object} anon
 * @param {Number} anon.quizId - Id of the current theme quiz
 * @param {Number} anon.questionId - The identifier of the current question (as in egg nr 3). Also known as eggId or campaignId
 * @param {String} anon.icon - icon-string to run through the iconConvertor for a SVG-icon
 */
export function showThemeQuizFloatingActionButton({ quizId, icon, campaignId } = {}) {
  return {
    type: SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON,
    payload: { quizId, icon, campaignId },
  };
}

export function hideThemeQuizFloatingActionButton() {
  return function (dispatch) {
    dispatch({ type: HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON });

    setTimeout(() => dispatch({ type: CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON }), durations.normal);
  };
}
