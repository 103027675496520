import { useState, useEffect } from "react";
import { throttle } from "lodash-es";

export function useElementSize(selectorOrRef) {
  const [size, setSize] = useState(null);

  useEffect(() => {
    let element;
    if (typeof selectorOrRef === "string") {
      element = document.querySelector(selectorOrRef);
    } else {
      element = selectorOrRef.current;
    }

    if (!element) return;

    setSize(element.getBoundingClientRect().width);

    let throttledUpdateSize = throttle(updateSize, 1000, { trailing: true });
    window.addEventListener("resize", throttledUpdateSize);
    return () => window.removeEventListener("resize", throttledUpdateSize);
    // eslint-disable-next-line

    function updateSize(e) {
      setSize(element.getBoundingClientRect().width);
    }
  }, [selectorOrRef]);

  return size;
}
