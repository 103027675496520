import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { css } from "emotion";
import { useDebouncedCallback } from "use-debounce/lib";

// Utilities
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../../utilities/get-user-group-title";
import hm from "../../../utilities/highlight-match";

// Config
import breakpoints from "../../../config/breakpoints";

// Hooks
import useLang from "../../../hooks/useLang";

// Components
import ListItem from "../../ui/ListItem";
import SearchInput from "../../ui/SearchInput";
import DynamicIcon from "../../ui/Icons/DynamicIcon";
import StatusBox from "../../ui/StatusBox";
import InlineSpinner from "../../ui/InlineSpinner";
import { AccountGroupIcon, CheckIcon } from "mdi-react";
import colors from "../../../style/colors";
import useAuthorModal from "../../../hooks/useAuthorModal";

const NewsTargetGroupList = ({ targetGroup, loading, overviewList = false, description = "" }) => {
  const lang = useLang();
  const [filteredTargetGroup, setFilteredTargetGroup] = useState([...targetGroup]);
  const [searchTerm, setSearchTerm] = useState("");
  const authorModal = useAuthorModal();

  useEffect(() => {
    filterTargetGroupDebounce();
  }, [searchTerm, targetGroup]);

  const [filterTargetGroupDebounce] = useDebouncedCallback(() => {
    const newFilteredTargetGroup = targetGroup.filter((t) => {
      if (
        (t.name && t.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (t.jobTitle && t.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (t.masterGroup?.title && t.masterGroup?.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (t.userGroup?.title && t.userGroup?.title.toLowerCase().includes(searchTerm.toLowerCase()))
      )
        return t;
    });
    setFilteredTargetGroup(newFilteredTargetGroup);
  }, 250);

  if (loading) {
    return <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.users}...`} />;
  } else if (!loading && targetGroup?.length > 0) {
    /* If the data is no longer loading, and there is data in the targetgroup array, we'll show the searchbar and then one of two options:
        1: The searchbar and the list of potentially filtered target group members
        2: The searchbar and a statusbox saying that no target group members matches the search term */
    return (
      <>
        <div className={componentStyles()}>
          <SearchInput
            className="search-input"
            placeholder={lang.typeToFilter}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            onClearSearch={() => {
              setSearchTerm("");
            }}
          />
          {filteredTargetGroup.length > 0 ? (
            <>
              <div className="information-box">
                <AccountGroupIcon />
                <p>{`${description}: ${targetGroup.length}`}</p>
              </div>
              {filteredTargetGroup.map((user) => (
                <ListItem
                  key={`target-group-${user.id}`}
                  maxWidth={breakpoints.lg}
                  imageLeft={getProfilePictureFromUserObject(user, 36, {
                    marginRight: "0.5rem",
                    marginBottom: "-3px",
                    marginLeft: user.read ? "-3px" : "0",
                  })}
                  title={hm(user.name, searchTerm)}
                  subTitle={getUserGroupTitle(user, searchTerm)}
                  onClick={() => authorModal(user.id)}
                  style={overviewList && user.read ? { borderLeft: `3px solid ${colors.green}` } : {}}
                  contentRight={
                    overviewList &&
                    user.read && (
                      <p className="read-text">
                        <CheckIcon /> {lang.hasRead}
                      </p>
                    )
                  }
                />
              ))}
            </>
          ) : (
            <StatusBox title={lang.noUsersFound} content={lang.trySearchingForSomethingElse} />
          )}
        </div>
      </>
    );
  } else {
    /* If the data is no longer loading, but there is no data in the target group array 
    (either due to an error* or due to no target group members being in respectively the "have read" or "have not read" tab)
    we'll show a statusbox saying there are no members in the tab. *If it's due to an error a toast will show as well. */

    return (
      <div style={{ marginTop: "1rem" }} className={componentStyles()}>
        <StatusBox
          style={{ paddingBottom: "5rem" }}
          icon={<DynamicIcon icon={"mdi:info-outline"} />}
          title={lang.noUsersFound}
          content={lang.noUsersInThisTab}
        />
      </div>
    );
  }
};

const componentStyles = () => css`
  max-width: 750px;
  margin-top: 1rem;
  margin: 1rem auto 5rem;

  .information-box {
    font-size: 0.85rem;
    display: flex;
    margin: 1rem 0;
    justify-content: center;
    align-items: center;
    color: var(--darkGrey);
    svg {
      margin-right: 0.5rem;
      width: 20px;
    }
  }

  .search-input {
    background-color: var(--white);
    max-width: 750px;
    margin: 0 auto 1rem auto;
    padding-left: 2.65rem;
    padding-right: 2rem;
    border: solid 1px var(--midGrey);
    border-radius: 25px;

    @media screen and (max-width: 760px) {
      margin: 0 1rem 1rem 1rem;
    }

    input:focus {
      border-radius: 25px;
    }
    input {
      padding-left: 0.5rem;
      font-size: 0.9rem;
    }
    svg {
      margin-right: 0.5rem;
      width: 20px;
    }
  }

  .list-item-container {
    cursor: pointer;
    border: 1px solid var(--midGrey);

    .content-right {
      font-size: 0.8rem;
      font-style: italic;
      color: var(--darkGrey);

      svg {
        width: 1rem;
        height: 1rem;
        margin: 0 -2px -3px 0;
        opacity: 0.8;
      }
    }
  }
`;

export default NewsTargetGroupList;
