// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import req from "../utilities/request-utility";

// Components
import { AlertOctagonIcon } from "mdi-react";

const useApplicationLanguages = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.language);

  const [applicationLanguages, setApplicationLanguage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);

    req()("meta/app-languages")
      .then(({ data }) => setApplicationLanguage(data))
      .catch(() => {
        setError(true);
        dispatch(
          addToast({
            styleType: "error",
            icon: <AlertOctagonIcon />,
            duration: "60000",
            title: lang.networkError,
            content: lang.couldNotFetchLanguages,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { applicationLanguages, loading, error };
};

export default useApplicationLanguages;
