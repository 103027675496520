// Libs
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Config
import { feedTypes } from "./config";
import pageConfig from "../../config/pageConfig";

// Redux actions
import { getPosts, resetFeeds, getFrontendAdminMapping } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Components
import NewsPost from "./NewsPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import { PostSkeletonGroup } from "../ui/PostSkeleton";
import StatusBox from "../ui/StatusBox";
import { PlusIcon, InfoOutlineIcon } from "mdi-react";
import NewsPostModal from "./NewsPostModal";
import ScrollView from "../ui/ScrollView";

// Utilities
import useCurrentPage from "../../hooks/useCurrentPage";
import extractValueFromObject from "../../utilities/extract-value-from-object";

// Context
import { PageContext } from "./context/PageContext";

function NewsFeedSingle(props) {
  const dispatch = useDispatch();
  // page setup
  const { match, getPosts, resetFeeds, showModalPage } = props;

  const lang = useSelector((state) => state.language.language);
  const admin = useSelector((state) => state.auth.user.admin);
  const frontendAdminMappings = useSelector((state) => state.news.frontendAdminMappings);

  const page = useCurrentPage({ pageId: match.params.pageId });
  const sort = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SORT_ORDER });

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, sort });
    dispatch(getFrontendAdminMapping());
    // eslint-disable-next-line
  }, []);

  // Fetches more posts if scrollview isn't yet scrollable
  useEffect(() => {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view");
      if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight)
        getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, sort });
    }, 500); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly

    // eslint-disable-next-line
  }, [posts, loading, error]);

  function hasAdminAccess() {
    return admin || frontendAdminMappings.some((subTypeId) => subTypeId == page.dataId);
  }

  return (
    <Page>
      <PageContext.Provider value={page.id}>
        <TopBar
          useDefaultBackButton={true}
          title={page.title}
          actionRight={
            hasAdminAccess() ? (
              <ActionWrapper
                data-test-id="btn-show-add-post-modal"
                onClick={() =>
                  showModalPage({
                    useScrollView: false,
                    content: <NewsPostModal subTypeId={page.dataId} feedType={feedTypes.all} />,
                  })
                }
              >
                <PlusIcon />
              </ActionWrapper>
            ) : null
          }
        />

        <ScrollView onScrollEnd={() => getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, sort })}>
          <div style={{ height: "1rem" }} />

          {/* Content */}
          {posts.map((p) => (
            <NewsPost
              post={p}
              feedType={feedTypes.all}
              subTypeId={page.dataId}
              key={`news-post-${p.id}`}
              disableSwipe={true}
            />
          ))}

          {/* Loading */}
          {loading && !error && !endOfFeed && <PostSkeletonGroup numberOfPosts={posts.length === 0 ? 2 : 1} />}

          {/* End of feed with posts (if only one post don't show this message) */}
          {posts.length > 0 && endOfFeed && posts.length !== 1 && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noMorePosts}
              content={lang.reachedEndOfFeed}
            />
          )}

          {/* End of feed without posts */}
          {posts.length === 0 && endOfFeed && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noPostsHere}
              content={lang.comeBackSoon}
            />
          )}
        </ScrollView>
      </PageContext.Provider>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedSingle);
