import React, { Fragment } from "react";

import { format, getISOWeek, parse } from "date-fns";
import ListItem from "../../ui/ListItem";
import breakpoints from "../../../config/breakpoints";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import getUserLocale from "../../../utilities/get-user-locale";
import colors from "../../../style/colors";
import ScheduleBadge from "../components/ScheduleBadge";

/**
 * This function might seem a bit overly complex and it might be.. So i will try to explain what it does :)
 * When we receive the shifts from the api they are a flat list. ie: [<shift1>, <shift2>, <shift3>, etc...]
 * The layout we want is something like:
 *
 *   Week 1
 *     Monday
 *     - Shift 1
 *     - Shift 2
 *     Tuesday
 *     - Shift 3
 *     - Shift 4
 *     ...rest of week
 *   Week 2
 *     Thursday
 *     - Shift 5
 *     - Shift 6
 *     Friday
 *     - Shift 7
 *     - Shift 8
 *
 * So when we iterate over the schedule we save a reference to the last used weekNumber and the last used date.
 * If the current schedule-entry's date differs from the previously used date, we will render a date-header and
 * do likewise with the weeknumer-header and update the previously used header.
 *
 * This solution is quite flexible and
 * - doesn't require every week to have shifts.
 * - doesn't require a time interval when fetching (we are doing so anyways though)
 *
 * I am not a big fan of shorthands but i am using:
 * prevDate and prevWeekNumber to mark the previously used date and week number as well as
 * curDate and curWeekNumber to mark the data in the current iteration
 *
 */
function scheduleRenderer({ scheduleData, authorModal, user, lang }) {
  // Keep reference of last rendered date and weekNr
  let prevDate, prevWeekNumber;

  return () => {
    // map over schedule data and render list item and potentially also render headings for weeks and dates
    return scheduleData.map((scheduleEntry) => {
      // Current entry's data
      let curDate = scheduleEntry.date;
      let curWeekNumber = getISOWeek(parse(curDate, "yyyyMMdd", 0), { weekStartsOn: 1 });

      // Decides if we should render a week number in this iteration
      let renderWeekNumber = false;
      if (prevWeekNumber !== curWeekNumber) {
        renderWeekNumber = true;
        prevWeekNumber = curWeekNumber; // Update reference for next iteration
      }

      // Decides if we should render the date in this iteration
      let renderDate = false;
      if (prevDate !== curDate) {
        renderDate = true;
        prevDate = curDate; // Update reference for next iteration
      }

      function getSubTitle(scheduleEntry) {
        let comment;

        /* prettier-ignore */
        if (scheduleEntry.comment) {
            comment = <>{" - "}<span style={{ fontWeight: 700, color: colors.orange }}>{scheduleEntry.comment}</span></>;
          } else if (scheduleEntry.activity) {
            comment = <>{" - "} <span style={{ fontWeight: 700, color: colors.green }}>{scheduleEntry.activity}</span></>;
          } else if (scheduleEntry.actualShiftActivity) {
            comment = <>{" - "} <span style={{ fontWeight: 700, color: colors.red }}>{scheduleEntry.actualShiftActivity}</span></>;
          }

        /* prettier-ignore */
        return <>{scheduleEntry.startTime} - {scheduleEntry.endTime}{comment}</>;
      }

      return (
        <Fragment
          key={`${scheduleEntry.date}_${scheduleEntry.startTime}_${
            (scheduleEntry.user && scheduleEntry.user.name) || scheduleEntry.userRefId
          }`}
        >
          {/* Week header (is rendered when week changes in data) */}
          {renderWeekNumber && (
            <p className="week">
              {lang.week} {curWeekNumber}
            </p>
          )}

          {/* Date header (is rendered when date changes in data) */}
          {renderDate && (
            <p className="date">{format(parse(curDate, "yyyyMMdd", 0), "eeee d. LLLL", getUserLocale(user))}</p>
          )}

          {/* Shifts */}
          <ListItem
            maxWidth={breakpoints.md}
            clickable={scheduleEntry.user && scheduleEntry.user.id ? true : false}
            onClick={() => (scheduleEntry.user.id ? authorModal(scheduleEntry.user.id) : null)}
            title={scheduleEntry.user && scheduleEntry.user.name}
            subTitle={getSubTitle(scheduleEntry)}
            middleTitle={<span style={{ color: colors.darkGrey }}>{scheduleEntry.groupTitle}</span>}
            imageLeft={getProfilePictureFromUserObject(scheduleEntry.user, undefined, { marginRight: "0.6rem" })}
            contentRight={<ScheduleBadge badgeName={scheduleEntry.badge} />}
          />
        </Fragment>
      );
    });
  };
}

export default scheduleRenderer;
