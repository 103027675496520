import getAppName from "./get-app-name";

function getIsStaging() {
  try {
    let hostname = window.location.hostname;
    if (hostname.split(".")[0] === "staging") return true;
    return false;
  } catch (err) {
    return false;
  }
}

export default function (apiConfig) {
  let manifestEl = document.getElementById("elManifest");
  let baseURL = manifestEl.getAttribute("data-base-url");
  let isStaging = getIsStaging();

  if (process.env.REACT_APP_CURRENT_DOMAIN_OVERWRITE) {
    baseURL = `${process.env.REACT_APP_CURRENT_DOMAIN_OVERWRITE}/api/`;
  }

  if (isStaging) {
    baseURL = "https://api-staging.ekkoapp.app/api/";
  }

  manifestEl.setAttribute("href", `${baseURL}apps/manifest/${getAppName()}${isStaging ? "?staging=1" : ""}`);

  if (process.env.NODE_ENV === "development") {
    manifestEl.setAttribute("href", `http://localhost:3030/api/apps/manifest/${getAppName()}`);
  }
}
