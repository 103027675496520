import React, { useRef, useState } from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";
import { useSelector } from "react-redux";
import { AlertCircleIcon, ErrorOutlineIcon, FileOutlineIcon, TrashCanOutlineIcon, UploadIcon } from "mdi-react";
import InlineSpinner from "./InlineSpinner";
import req from "../../utilities/request-utility";
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../utilities/color-modifiers";
import FileViewer from "./FileViewer";
import ALLOWED_FILE_TYPES from "../../config/allowedFileTypes";

const FileUploadMultiple = (props) => {
  const [fileListUploadState, setFileListUploadState] = useState({});
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { placeholder, style, boxPadding, value = [], disabled = false } = props;
  const [filesCount, setFilesCount] = useState(0);
  const inputRef = useRef();
  const lang = useSelector((state) => state.language.language);

  const onFileSelect = async (e) => {
    let files = e.target.files;
    let fileList = Array.from(files);
    setFilesCount(fileList.length);

    // Check if file format is allowed
    const isFormatValid = (format) => {
      if (ALLOWED_FILE_TYPES.includes(format.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    };

    // Disable the submit btn - if there is any
    if (props.disabledSubmitButton) props.disabledSubmitButton();

    const formatList = fileList.map((file) => {
      return file.name.split(".").slice(-1)[0].toLowerCase();
    });

    // If the file type is not permitted
    formatList.map((fileFormat) => {
      if (!isFormatValid(fileFormat)) {
        props.addToast({
          title: lang.fileFormat__placeholder__NotPermitted.replace(/{{placeholder}}/gi, format),
          content: lang.chooseAnotherFileFormat,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
        return;
      }
    });

    const formatted = fileList.map((file) => {
      // Check if file is selected
      if (!file) return; // No file is selected
      // Check file size
      if (file.size > 200000000) {
        return props.addToast({
          type: "error",
          title: lang.tooBig,
          content: lang.fileIsToBigDescription,
          icon: <AlertCircleIcon />,
        });
      } else if (file.size > 39999900) {
        // TO DO! Reimplement with dialog, once that is implemented
        return props.addToast({
          type: "info",
          title: lang.bigFileDetected,
          content: lang.bigFileDetectedDescription,
          icon: <AlertCircleIcon />,
        });
      }

      // Construct formData with file
      let formData = new FormData();
      formData.append("file", file);

      // Start upload and set state
      return { formData, name: file.name };
    });

    setFileListUploadState({ status: "uploading", count: 0 });
    uploadFiles(formatted);
  };

  const uploadFiles = async (files) => {
    try {
      const dataList = [];
      // go through each file an upload to server.
      await Promise.all(
        files.map(async (file) => {
          const data = await req().post(`/files/?filenameWish=${file.name}`, file.formData, {
            onUploadProgress: () => onUploadProgress(files.indexOf(file) + 1),
          });
          dataList.push({ data: data, title: file.name });
        })
      );

      onFileUploadSuccess(dataList);
    } catch (error) {
      onFileUploadFailure(error);
    }
  };

  const onUploadProgress = (index) => {
    setFileListUploadState({
      status: "uploading",
      count: index,
    });
  };

  const onFileUploadSuccess = (dataList) => {
    let currentValue = dataList.map((data) => {
      const fileName = data.data.data.filename;
      const baseURL = data.data.data.baseURL;
      setFileListUploadState({
        status: "uploaded",
        path: data.data.data.file,
      });

      return { baseURL: baseURL, file: fileName, title: data.title };
    });

    //merge currentValue with the value state
    currentValue = value.concat(currentValue);

    //  Update file list
    props.onChange(currentValue);
    if (props.enableSubmitButton) props.enableSubmitButton();
  };

  const onFileUploadFailure = (err, index) => {
    setFileListUploadState({
      icon: <AlertCircleIcon />,
      styleType: "error",
      title: lang.error,
      content: lang.errorCouldNotUploadFile,
      duration: 10000,
    });
    setFileListUploadState({});
  };

  // Remove all the files
  const onAllFilesRemove = () => {
    inputRef.current.value = null;

    setFileListUploadState({});
    props.onChange([]);
  };

  // Remove specific file
  const onFileRemove = (id) => {
    props.onChange(value.filter((f) => f.title != id));
    inputRef.current.value = null;
  };

  return (
    <div
      className={`${componentStyle(primaryColor, boxPadding)} ${props.className} ${
        fileListUploadState.status === "uploaded" ? "upload-off" : "upload-on"
      }`}
    >
      <div className="container" style={style}>
        <label>
          {/** Default value **/}
          {!fileListUploadState.status && <UploadIcon />}

          {/** Files uploaded **/}
          {fileListUploadState.status === "uploaded" && <FileOutlineIcon />}

          {/* Spinner */}
          {fileListUploadState.status === "uploading" && (
            <div className="spinner-container">
              <InlineSpinner size={22} className="spinner" />
            </div>
          )}
          {/* Files uploaded so far */}
          {fileListUploadState.status === "uploading" && (
            <p>{"" + lang.workingWith + " " + fileListUploadState.count + "/" + filesCount + " " + lang.files}</p>
          )}

          {/** Default values **/}
          {!fileListUploadState.status && <p style={{ textOverflow: "ellipsis" }}>{placeholder}</p>}

          {/** Input **/}
          <input
            type="file"
            accept={"." + ALLOWED_FILE_TYPES.join(",.")}
            multiple={true}
            disabled={disabled}
            onChange={onFileSelect}
            ref={inputRef}
          />
        </label>

        {/*Remove all files */}
        {value.length > 0 && (
          <TrashCanOutlineIcon className="delete-icon" onClick={!disabled ? () => onAllFilesRemove() : {}} />
        )}
      </div>
      <div>
        {/* Files */}
        {!value || value.length === 0 ? null : (
          <div className="attachments-container">
            {value.map((file) => (
              <div className="attachments" key={file.file}>
                <FileViewer
                  key={`file-viewer-compontent${file.file}`}
                  href={`${file.baseURL + file.file || ""}`}
                  title={file.title}
                  className="file-viewer"
                />
                <button className="icon-container" onClick={!disabled ? () => onFileRemove(file.title) : {}}>
                  <TrashCanOutlineIcon className="delete-icon" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const componentHeight = 40; //px

const componentStyle = (primaryColor, boxPadding) => css`
  .attachments-container {
    border-bottom: 1px ${colors.midGrey} solid;
    border-radius: 3px;
  }

  .attachments {
    border: 1px ${colors.midGrey} solid;
    border-bottom: none;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    &:hover {
      background-color: ${colors.lightGrey};
      cursor: pointer;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .file-viewer {
      flex-grow: 1;
      border: none;
    }

    .icon-container {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: none;
      color: ${colors.darkGrey};

      &:hover {
        cursor: pointer;
        background-color: ${colors.ultraLightGrey};
        transition: 70ms background-color ease;
        color: ${colors.red};
      }
    }
  }

  .container {
    font-size: 1rem;
    color: ${colors.black};
    display: flex;
    margin-bottom: 1rem;
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    overflow: hidden;

    &.upload-on:active {
      border: 1px ${primaryColor} solid;
      transition: 80ms border ease;

      svg {
        border-color: ${primaryColor};
      }
    }

    &.upload-on label:hover {
      cursor: pointer;
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
    }

    &.upload-on label:active {
      transition: 170ms border ease;
      cursor: pointer;
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
    }

    /* Upload button/Input */
    label {
      display: flex;
      flex-grow: 1;
      position: relative;
      align-items: center;
      height: ${componentHeight}px;
      background: ${colors.white};
      color: ${colors.black};
      font-family: ${common.fontStack};
      margin: 0 0 ${boxPadding};
      border-radius: 3px;
      margin-bottom: ${boxPadding};
      transition: 170ms border ease;

      &:hover {
        background-color: ${colors.ultraLightGrey};
        cursor: pointer;
      }

      svg {
        position: relative;
        width: ${componentHeight}px;
        height: ${componentHeight}px;
        margin-right: 0.45rem;
        padding: 0.45rem;
        color: ${colors.darkGrey};
        border-right: 1px solid ${colors.midGrey};
      }

      /* Progress bar */
      .progress-bar-container {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 3px;
        display: flex;
        justify-content: space-between;

        .progress-bar {
          height: 3px;
        }

        .progress-bar.colored {
          background-color: ${primaryColor};
        }
      }
    }

    .spinner {
      margin-right: 0.2rem;
      margin-top: 0.35rem;
      margin-left: 0.25rem;
    }

    .spinner-container {
      border-right: 1px solid ${colors.midGrey};
      width: fit-content;
      height: fit-content;
      margin-right: 0.5rem;
    }

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.5rem;
      color: ${colors.darkGrey};
      border-right: 1px solid ${colors.midGrey};
    }

    input[type="file"] {
      display: none;
    }

    svg.delete-icon {
      margin-right: 0;
      border-right: 0px;
      transition: 140ms background-color ease;

      &:hover {
        cursor: pointer;
        background-color: ${colors.ultraLightGrey};
        transition: 70ms background-color ease;
        color: ${colors.red};
      }
    }
  }
`;

export default FileUploadMultiple;
