// Libs
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import getAppName from "../utilities/get-app-name";
import isValidTranslatorLanguage from "../utilities/is-valid-translator-language";
import req from "../utilities/request-utility";

// Actions
import { showContextMenu, addToast } from "../actions/uiActions";

import translationTypes from "../config/translationTypes";

const useTranslation = ({ callBackOnTranslation = () => {}, type = translationTypes.newsPost, args = {} }) => {
  const dispatch = useDispatch();
  const { language: userLanguage } = useSelector((state) => state.auth.user);

  // get translation languages possibilities from redux store.
  const { languages: translatorLanguages } = useSelector((state) => state.language.translationsLanguages);

  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);

  const [currentTranslationLanguage, setCurrentTranslationLanguage] = useState(userLanguage);

  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);

  async function translateContent({ chosenTranslateLanguage = false, explicitlyChangeLanguage = false } = {}) {
    /* Three outcomes
    1) No language is chosen and user has no previous language OR
       User implcitly requested to change the langauge
       -> Language prompt
    2) No language is chosen but user has a previously used language
       -> Translate with previously used language
    3) A language is chosen
       -> Translate with chosen language regardless of previous settings
    */

    /** Previously used language will be stored here */
    let previouslyUsedLanguage = localStorage.getItem(`${getAppName()}-translator-language`);

    // if previously used language for some reason is set to users own language, don't just auto translate to it
    // this can happen either as a bug or because user changed language since last translation
    if (userLanguage === previouslyUsedLanguage) previouslyUsedLanguage = "";

    // 1)
    if ((!chosenTranslateLanguage && !previouslyUsedLanguage) || explicitlyChangeLanguage === true) {
      // Display prompt
      dispatch(
        showContextMenu(
          translatorLanguages.map((translatorLanguage) => ({
            title: translatorLanguage.title,
            callback: () => translateContent({ chosenTranslateLanguage: translatorLanguage.identifier }),
          }))
        )
      );
    }
    // 2)
    else if (!chosenTranslateLanguage && previouslyUsedLanguage) {
      // Validate language
      // If its valid -> continue as normal
      if (isValidTranslatorLanguage({ language: previouslyUsedLanguage, translatorLanguages })) {
        translateContent({ chosenTranslateLanguage: previouslyUsedLanguage });
        // For invalid - prompt user to reselect langauge
      } else {
        translateContent({ explicitlyChangeLanguage: true });
      }
    }
    // 3)
    else {
      setIsTranslating(true);
      setCurrentTranslationLanguage(chosenTranslateLanguage);

      if (type === translationTypes.newsPost) handleNewsPostTranslation({ chosenTranslateLanguage });
      if (type === translationTypes.newsCheckQuestion) handleNewsPostCheckQuestionTranslation({ chosenTranslateLanguage });
      if (type === translationTypes.registrationPost) handleRegistrationPostTranslation({ chosenTranslateLanguage });
      if (type === translationTypes.events) handleEventTranslation({ chosenTranslateLanguage });
      if (type === translationTypes.socialOrChatPost) handleSocialPostTranslation({ chosenTranslateLanguage });
    }
  }

  function handleNewsPostTranslation({ chosenTranslateLanguage }) {
    const { postId, subTypeId } = args;

    req()(`news/${subTypeId}/${postId}/translations/${chosenTranslateLanguage}`)
      .then(({ data }) => {
        // In case user went back to native language, don't set a value in localstorage and set isTranslated to false
        if (userLanguage !== chosenTranslateLanguage) {
          setIsTranslated(true);
          localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
        } else {
          setIsTranslated(false);
        }
        setIsTranslating(false);
        setContent(data.content);
        setTitle(data.title);
        // In order to make the search functionality work in NewsFeedFaq, we have to update the local array of posts with the translated post content, as the local array of posts is the one we are filtering through on search and the one we are using to render the posts. We therefor call this callback function with the translated post content and post id.
        callBackOnTranslation && callBackOnTranslation({ postId, ...data });
      })
      .catch((err) => {
        setIsTranslating(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  function handleSocialPostTranslation({ chosenTranslateLanguage }) {
    const { dataId, postId } = args;

    req()(`social/${dataId}/posts/${postId}/translations/${chosenTranslateLanguage}`)
      .then(({ data }) => {
        // In case user went back to native language, don't set a value in localstorage and set isTranslated to false
        if (userLanguage !== chosenTranslateLanguage) {
          setIsTranslated(true);
          localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
        } else {
          setIsTranslated(false);
        }
        setIsTranslating(false);
        setContent(data.content);
        setTitle(data.title);
        // In order to make the search functionality work in NewsFeedFaq, we have to update the local array of posts with the translated post content, as the local array of posts is the one we are filtering through on search and the one we are using to render the posts. We therefor call this callback function with the translated post content and post id.
        callBackOnTranslation && callBackOnTranslation({ postId, ...data });
      })
      .catch((err) => {
        setIsTranslating(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  function handleNewsPostCheckQuestionTranslation({ chosenTranslateLanguage }) {
    const { quizId, questionId } = args;

    req()(`quiz/${quizId}/questions/${questionId}/translations/${chosenTranslateLanguage}`)
      .then(({ data }) => {
        if (userLanguage !== chosenTranslateLanguage) {
          setIsTranslated(true);
          localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
        } else {
          setIsTranslated(false);
        }
        setIsTranslating(false);
        setContent(data.answers);
        setTitle(data.question);
      })
      .catch((err) => {
        setIsTranslating(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  function handleRegistrationPostTranslation({ chosenTranslateLanguage }) {
    const { postId, registrationId } = args;

    req()(`registration/${registrationId}/${postId}/translations/${chosenTranslateLanguage}`)
      .then(({ data }) => {
        if (userLanguage !== chosenTranslateLanguage) {
          setIsTranslated(true);
          localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
        } else {
          setIsTranslated(false);
        }
        setIsTranslating(false);
        setContent(data.content);
        setTitle(data.title);
        // In order to make the search functionality work in NewsFeedFaq, we have to update the local array of posts with the translated post content, as the local array of posts is the one we are filtering through on search and the one we are using to render the posts. We therefor call this callback function with the translated post content and post id.
        callBackOnTranslation && callBackOnTranslation({ postId, ...data });
      })
      .catch((err) => {
        setIsTranslating(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  function handleEventTranslation({ chosenTranslateLanguage }) {
    const { eventId } = args;

    req()(`events/${eventId}/translations/${chosenTranslateLanguage}`)
      .then(({ data }) => {
        if (userLanguage !== chosenTranslateLanguage) {
          setIsTranslated(true);
          localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
        } else {
          setIsTranslated(false);
        }
        setIsTranslating(false);
        setContent(data.content);
        setTitle(data.title);
      })
      .catch((err) => {
        setIsTranslating(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  return {
    translateContent,
    isTranslating,
    isTranslated,
    currentTranslationLanguage,
    title,
    content,
  };
};

export default useTranslation;
