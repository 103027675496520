import React from "react";
import GroupedUserPage from "../GroupedData/GroupedUserPage";
import useDataEndpoint from "../../hooks/useDataEndpoint";
import useCurrentPage from "../../hooks/useCurrentPage";

const DepartingEmployees = (props) => {
  const page = useCurrentPage({ pageId: props.match.params.pageId });

  const { data: groups, loading, error } = useDataEndpoint(`/users/departing${page.dataId ? "?dataId=" + page.dataId : ""}`);

  return <GroupedUserPage groups={groups} error={error} page={page} loading={loading} />;
};

export default DepartingEmployees;
