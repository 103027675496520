// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import { ErrorOutlineIcon } from "mdi-react";

// Components
import ScrollView from "../../ui/ScrollView";
import FormBuilder from "../../ui/FormBuilder";
import Spinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";
import ButtonRounded from "../../ui/ButtonRounded";
import RegistrationFormExtended from "./RegistrationFormExtended";

// Actions
import {
  submitRegistrationForm,
  submitUpdatedRegistrationForm,
  updateRegistrationForm,
} from "../../../actions/registrationActions";
import { hideModalPage } from "../../../actions/uiActions";
import { addToast } from "../../../actions/uiActions";

// Styles & Config
import breakpoints from "../../../config/breakpoints";
import formMode from "../config/formMode";

// Utilities
import { parseDate } from "../../../utilities/parse-date";

const RegistrationForm = (props) => {
  const dispatch = useDispatch();

  const { language: lang } = useSelector((s) => s.language);
  const { registration, loading, error, notValidQuestionIds, activeRequestHandlers } = useSelector(
    (s) => s.registration.form
  );
  const { selectedDate } = useSelector((s) => s.timeRegistration);

  const [showNotValidQuestions, setShowNotValidQuestions] = useState(false);

  const { mode = formMode.create, postId, onSubmitCallback = null, hideTotalHours = false } = props;

  function updateForm(value, questionIndex, categoryIndex) {
    dispatch(updateRegistrationForm({ value, questionIndex, categoryIndex }));
  }

  function submitForm() {
    try {
      setShowNotValidQuestions(true);

      if (mode === formMode.edit) {
        dispatch(
          submitUpdatedRegistrationForm({
            callback: () => {
              dispatch(hideModalPage());
              dispatch(
                addToast({
                  styleType: "success",
                  title: lang.thankYouForTheRegistration,
                  content: lang.registrationReceived,
                  duration: 5000,
                })
              );
              if (onSubmitCallback) onSubmitCallback();
            },
            postId,
          })
        );
      }

      if (mode === formMode.create) {
        // async call to submit registration form here!
        dispatch(
          submitRegistrationForm({
            // Success
            callback: () => {
              dispatch(hideModalPage());
              dispatch(
                addToast({
                  styleType: "success",
                  title: lang.thankYouForTheRegistration,
                  content: lang.registrationReceived,
                  duration: 5000,
                })
              );
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: lang.errorCouldNotRegister,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  }

  return (
    <ScrollView className={componentStyle()}>
      {loading && !error && <Spinner />}
      {!loading && error && <StatusBox />}
      {!loading && !error && (
        <>
          <div className="content-wrapper">
            {!hideTotalHours && (
              <p className="date">
                {`${lang.totalFor} ${parseDate(selectedDate)} `} <span>{props.totalValue}</span>
              </p>
            )}

            {registration.categories[0].questions.map((question, questionIndex) => (
              <FormBuilder
                key={question.id}
                question={question}
                questionIndex={questionIndex}
                updateFields={updateForm}
                categoryIndex={0}
                notValidArr={showNotValidQuestions ? notValidQuestionIds : []}
                style={{ marginBottom: "1.5rem" }}
              />
            ))}
          </div>

          <RegistrationFormExtended
            categories={registration.categories}
            updateRegistrationFormFields={updateForm}
            notValidQuestionsIds={showNotValidQuestions ? notValidQuestionIds : []}
          />

          <div className="content-wrapper">
            <ButtonRounded
              disabled={activeRequestHandlers > 0}
              onClick={submitForm}
              style={{ width: "100%", margin: "1rem 0 0.5rem 0" }}
            >
              {activeRequestHandlers > 0 && "..."}
              {activeRequestHandlers < 1 && mode === formMode.create && lang.add}
              {activeRequestHandlers < 1 && mode === formMode.edit && lang.update}
            </ButtonRounded>
            {<p className={`date ${activeRequestHandlers > 0 ? "show" : "hide"}`}>Venter på eksterne datakilder...</p>}
          </div>
        </>
      )}
    </ScrollView>
  );
};

const componentStyle = () => css`
  height: 100%;
  background-color: var(--white);
  padding: 1rem 0 5rem 0;

  .date {
    font-size: 0.85rem;
    color: var(--darkGrey);
    text-align: center;
    margin: 0 0 1rem 0;

    span {
      color: var(--black);
      font-weight: bold;
    }
  }

  .show {
    opacity: 1;
    transition: opacity 400ms ease;
  }
  .hide {
    opacity: 0;
    transition: opacity 300ms ease;
  }

  .content-wrapper {
    width: 100%;
    max-width: ${breakpoints.xs}px;
    margin: 0 auto;
    padding: 0 1rem;
  }
`;

export default RegistrationForm;
