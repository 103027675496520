import {
  GET_CERTIFICATE_OVERVIEW_FAILED,
  GET_CERTIFICATE_OVERVIEW_SUCCESS,
  GET_CHALLENGE_OVERVIEW_FAILED,
  GET_CHALLENGE_OVERVIEW_SUCCESS,
  GET_QUIZZES_OVERVIEW_FAILED,
  GET_QUIZZES_OVERVIEW_SUCCESS,
  SET_CERTIFICATE_LOADING,
  SET_CHALLENGE_LOADING,
  SET_QUIZ_LOADING
} from "../actions/actionTypes";

const initialState = {
  quiz: {
    error: false,
    loading: true,
    newQuizzes: [],
    completedQuizzes: []
  },
  challenge: {
    error: false,
    loading: true,
    highScore: [],
    challenge: {},
    bestScore: null
  },
  certificate: {
    error: false,
    loading: true,
    newCertificates: [],
    completedCertificates: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_QUIZZES_OVERVIEW_SUCCESS:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          newQuizzes: action.payload.newQuizzes,
          completedQuizzes: action.payload.completedQuizzes,
          loading: false
        }
      };
    case GET_QUIZZES_OVERVIEW_FAILED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          error: true,
          loading: false
        }
      };

    case SET_QUIZ_LOADING: {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: true
        }
      };
    }

    case GET_CHALLENGE_OVERVIEW_SUCCESS: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          challenge: action.payload.challenge,
          highScore: action.payload.highScore,
          bestScore: action.payload.bestScore,
          loading: false
        }
      };
    }

    case GET_CHALLENGE_OVERVIEW_FAILED: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          error: true,
          loading: false
        }
      };
    }

    case SET_CHALLENGE_LOADING:
      return {
        ...state,
        challenge: {
          ...state.challenge,
          loading: true
        }
      };

    case GET_CERTIFICATE_OVERVIEW_SUCCESS:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          newCertificates: action.payload.newCertificates,
          completedCertificates: action.payload.completedCertificates,
          loading: false
        }
      };

    case GET_CERTIFICATE_OVERVIEW_FAILED:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          error: true,
          loading: false
        }
      };

    case SET_CERTIFICATE_LOADING:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          loading: true
        }
      };

    default:
      return state;
  }
}
