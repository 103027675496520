import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import Notification from "../ui/Notification";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import getAppName from "../../utilities/get-app-name";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import iconConverter from "../../utilities/icon-converter";

// Style
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import useCurrentPage from "../../hooks/useCurrentPage";
import LeapeoSkeletonListItem from "./LeapeoSkeletonListItem";

// Icons
import { CheckCircleIcon, ChevronRightIcon, HourglassEmptyIcon } from "mdi-react";
import { css } from "emotion";

function LeapeoNavigationList(props) {
  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const [tasks, setTasks] = useState({
    data: [],
    error: false,
    loading: true,
  });

  useEffect(() => {
    getTasks();
  }, []);

  async function getTasks() {
    try {
      let { data } = await req()(`leapeo/tasks`);
      setTasks({ error: false, loading: false, data });
    } catch (err) {
      setTasks({ error: true, loading: false });
    }
  }

  return (
    <Page className={componentStyles(primaryColor)}>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <ScrollView style={{ padding: "1rem 0" }}>
        {tasks.loading &&
          !tasks.error &&
          tasks.data.length === 0 &&
          [0, 1, 2, 3, 4, 5, 6].map((num, index) => <LeapeoSkeletonListItem delay={index * 6} />)}
        {!tasks.loading &&
          !tasks.error &&
          tasks.data.map((task) => (
            <a className="leapeo-link" target="_blank" href={task.url}>
              <p className={`title ${task.isCompleted ? "completed" : "todo"}`}>
                {!task.isCompleted && <HourglassEmptyIcon color={colors.darkGrey} />}
                {task.isCompleted && <CheckCircleIcon color={primaryColor} />}
                {task.title}
              </p>
              <p className="description">{task.description}</p>
              <ChevronRightIcon color={colors.darkGrey} className="chevron-icon" />
            </a>
          ))}
      </ScrollView>
    </Page>
  );
}

const componentStyles = (primaryColor) => css`
  .leapeo-link {
    display: block;
    background-color: var(--white);
    border-top: 1px var(--midGrey) solid;
    border-bottom: 1px var(--midGrey) solid;
    margin-bottom: -1px;
    padding: 0.75rem;
    color: var(--black);
    position: relative;

    @media screen and (min-width: ${breakpoints.md}px) {
      width: ${breakpoints.md}px;
      border: 1px var(--midGrey) solid;
      margin-left: auto;
      margin-right: auto;

      &:first-of-type {
        border-radius: 3px 3px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 3px 3px;
      }
    }

    &:hover {
      background-color: var(--ultraLightGrey);
    }

    .chevron-icon {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      width: 1.25rem;
      height: 1.25rem;
      transform: translateY(-50%);
    }

    .title {
      color: ${primaryColor};
      margin: 0 0 0.35rem 0;
      font-weight: 700;
      padding-right: 2rem;

      &.todo:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: var(--red);
        position: absolute;
        top: 0.7rem;
        left: 1.4rem;
      }

      /* .status-icon-wrapper {
        display: inline-block;
        position: absolute;
      } */

      svg {
        margin-right: 0.35rem;
        margin-bottom: -3px;
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    .description {
      padding-right: 2rem;
      font-size: 0.9rem;
    }
  }
`;

export default LeapeoNavigationList;
