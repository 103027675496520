import React from "react";
import ListItem from "./ListItem";
import breakpoints from "../../config/breakpoints";
import getUserGroupTitle from "../../utilities/get-user-group-title";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import useAuthorModal from "../../hooks/useUserModal";
import GroupedDataList from "../GroupedData/GroupedDataList";

/**
 * @param { Object } [groups]
 * @param { string } groups.label
 * @param { Object[] } groups.objects
 * @param { string } [className]
 */
const GroupedUserList = ({ groups, className = "" }) => {
  const authorModal = useAuthorModal();

  const render = (user) => (
    <ListItem
      key={`${user.id}`}
      maxWidth={breakpoints.md}
      clickable={true}
      onClick={() => authorModal(user.id)}
      title={user.name}
      subTitle={getUserGroupTitle(user)}
      imageLeft={getProfilePictureFromUserObject(user, undefined, { marginRight: "0.6rem" })}
    />
  );

  return <GroupedDataList className={className} groups={groups} groupedItemsRenderFunction={render} />;
};

export default GroupedUserList;
