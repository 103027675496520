// Libs
import React from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import { updateModalPage } from "../../actions/uiActions";

// Components
import ImageCarousel from "../ui/ImageCarousel";
import Button from "../ui/Button";

// Styles
import breakpoints from "../../config/breakpoints";

import Quiz from "../quiz/Quiz";
import Certificate from "../certificate/Certificate";
import colors from "../../style/colors";

import { academyTypes } from "../../config/academyTypes";
import markdownParser from "../../utilities/markdown-parser";

const AcademyPreview = (props) => {
  const dispatch = useDispatch();

  // Destructure the props
  const { quiz, type } = props;

  // Language
  const lang = useSelector((state) => state.language.language);

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <div className="container">
        <p className="title">{quiz.title}</p>
        {quiz.images.length > 0 && <ImageCarousel boxPadding="1" images={quiz.images} />}
        {quiz.video && (
          <div className="videoWrapper">
            <video
              controls
              controlsList="nodownload"
              poster={`${quiz.video.baseURL}f_jpg/${quiz.video.video}`}
              src={`${quiz.video.baseURL}f_auto,q_auto,w_1600/${quiz.video.video}`}
            ></video>
          </div>
        )}
        {quiz.description && <p className="description" dangerouslySetInnerHTML={markdownParser(quiz.description)}></p>}
        <div style={{ margin: "2rem 1rem" }}>
          <Button
            data-test-id={"begin-quiz"}
            onClick={() => {
              dispatch(
                updateModalPage({
                  title: `${quiz.title}`,
                  content: (
                    <>
                      {type === academyTypes.certificate && <Certificate certificateId={quiz.id} />}
                      {type === academyTypes.quiz && <Quiz quizId={quiz.id} />}
                    </>
                  ),
                  pageStyle: { backgroundColor: colors.white },
                })
              );
            }}
          >
            {lang.start__placeholder__.replace(/{{placeholder}}/gi, lang.quiz.toLowerCase())}
          </Button>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  height: 100%;
  width: 100%;
  background-color: var(--white);

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 0 1rem 1rem 1rem;
  }

  video,
  iframe {
    width: 100%;
    border-radius: 3px;
    border: 1px var(--lightGrey) solid;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .container {
    max-width: ${breakpoints.md}px;
    margin: auto;
    overflow: auto;
  }

  .title {
    padding: 1rem;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .description {
    padding: 0 1rem;
  }
`;

export default AcademyPreview;
