import store from "../store";
import history from "./navigation-history";
import getAppName from "./get-app-name";
import req from "./request-utility";
import { getPages, setTransitionMode } from "../actions/pagesActions";
import pageTransitions from "../config/page-transitions";
import potentiallyShowThemeQuizFloatingActionButton from "./potentially-show-theme-quiz-floating-action-button.js";
import { hideThemeQuizFloatingActionButton } from "../actions/themeQuizActions";
import { HIDE_MODAL_PAGE } from "../actions/actionTypes";
import { checkToken } from "../actions/authActions";
import makeThrottledTokenCheck from "./make-throttled-token-check";

let popStateThrottler;
window.addEventListener("popstate", function (e) {
  // Okay yeah i know... The popstate event is fired twice which causes a bunch of weird issues. Its s
  // common issue as far as i can tell, so this is just a quick and easy way to solve it. This way we are
  // throttling it to not run more than once every 50ms which is enough to keep from double-firing
  // in the case of the modal-pages this is only relevant if multiple modalpages are open and you go
  // back with the browser
  if (popStateThrottler === true) return;
  popStateThrottler = true;
  setTimeout(() => {
    popStateThrottler = false;
  }, 50);

  /* 🥷🪄
   * Yes this seems completely unnecessary, but let me explain
   * We are showing the amount of notifications in the title ie. `(2) Echo Test`
   * The title is bound to the current navigation which means that when you
   * go back in history Chrome will use the title the page had at that time in
   * history and won't allow you to update it.
   * But for some reason (and i don't know why or how). If you set the title to something
   * else right before you're going to set it to what you wanted initally it works.
   *
   * Example of the problem:
   *
   * 1. You're at frontpage with 2 notificaitons. Title is
   *    `(2) Echo Test`
   * 2. You go to a newspage and swipe a post. Title is:
   *    `(1) Echo Test`
   * 3. You click on the back button. Title is
   *    `(2) Echo Test`
   *    Why is the title this? Well because this is what it was when you navigated away from the page
   *
   * Example of the solution
   *
   * 1. You're at frontpage with 2 notificaitons. Title is
   *    `(2) Echo Test`
   * 2. You go to a newspage and swipe a post. Title is:
   *    `(1) Echo Test`
   * 3. You click on the back button and as soon as the popstate event fires we set the titel to `Echo Test`
   *    The title is:
   *    `Echo Test`
   * 4. After each navigation step we update the pages which will trigger a update to the title
   *    The title will therefore be set to
   *    `(1) Echo Test`
   *    And because we had step 3 setting the title to something else Chrome will allow it. 🤷‍♂️
   *
   */
  // I am adding two non-breaking spaces at the end as this is an invisible change, but enough to make the title update
  document.title = `${store.getState().appConfig.title}\u00A0\u00A0`;

  // If back button was pressed and it wasn't pressed from within the app AND there are open a modalpage, close
  // up the modalPage and go forward to where the app were when the modalPage opened
  if (store.getState().ui.modalPages.length > 0 && !window._goBackFiredFromInApp) {
    setTimeout(() => void history.goForward(), 1);
    setTimeout(() => {
      store.dispatch({ type: HIDE_MODAL_PAGE });
    }, 300);
  }
});

/**
 * @param {Object} anon
 * @param {String} anon.path - The path to navigate to
 * @param {String} anon.direction - Transition direction. Import these from this file as `pageTransitions`
 * @param {Number} anon.pageId - The id of the page being navigated too. If provided the visit will be saved for statistics
 * @param {String} anon.mode - accepts `push` and `pop`. Push adds a new record to history while pop removes it
 */
export default function ({ path = "", direction = "backward", pageId = null, mode = "push", page = null }) {
  if (page && page.module === "ExternalLink") {
    let linkURL = page.dataId
      .replace(/{{externalId}}/gi, store.getState().auth.user.externalId || null)
      .replace(/{{secondaryExternalId}}/gi, store.getState().auth.user.secondaryExternalId);

    return window.open(linkURL, "_blank");
  }

  if (direction) {
    // Set the direction
    store.dispatch(setTransitionMode(direction));
  }

  // Replaces double slashes with single (url.com/lorem//ipsum -> url.com/lorem/ipsum)
  path = path.replace(/\/\//gm, "/");

  // Set the direction
  requestAnimationFrame(() => {
    if (mode === "pop" && window.history.length > 1) {
      // There is more than 1 entry in history so just what is there
      history.goBack();

      // I am setting a global flag on the window object. I know it's kinda bad, but the goBack() and back-button
      // event will both trigger a popstate event, so this is the only good way to know if the popstate event was
      // triggered by us or by the browser. This can be usefull, and is currently being used in the popstate-listener
      // also found in this file
      window._goBackFiredFromInApp = true;
      setTimeout(() => {
        window._goBackFiredFromInApp = false;
      }, 300);

      // There is only 1 entry in history so go back to frontpage
    } else if (mode === "pop" && window.history.length <= 1) {
      history.replace(`/${getAppName()}/`);

      // Push route
    } else if (mode === "push") {
      history.push(path);
    }

    // this has been moved to app.jsx where a global history-listener is registered. This is done as any back-button usage from
    // swipe-gestures or browser-buttons clicked outside of the app doesn't utilise this function thereby leaving theme-quiz-buttons
    // visible as this action is never dispatched
    // requestAnimationFrame(() => store.dispatch(hideThemeQuizFloatingActionButton()));

    setTimeout(() => {
      requestAnimationFrame(() => {
        makeThrottledTokenCheck();

        // if pageId isn't null, log current visit. I am using requestAnimationFrame
        // again to not jank up the animation starter above
        if (pageId !== null) {
          // Log app visit. Logic for duplicated visit-logs is handled in node api
          // It's fine to fire this request as many times a needed. The api will make sure
          // the log wont be registered multiple times. See business rules in Node-api for further details
          req().post("statistics/app/visits").catch((e) => console.error(e));
          req().post(`statistics/pages/visits/${pageId}`).catch((e) => console.error(e));
        }

        // Check if we should display an egg
        potentiallyShowThemeQuizFloatingActionButton({
          pageId,
          // If the location is /${AppName}/ then its at frontpage otherwise its not. Simple as that (hopefully)
          frontPageMode: window.location.pathname === `/${getAppName()}/` || window.location.pathname === `/${getAppName()}`,
        });

        // Request the pages again
        store.dispatch(getPages());
      });
    }, 600);
  });
}

export { pageTransitions };
