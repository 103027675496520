function removePageFromPage({ pageToBeRemovedId, pages }) {
  let newPages = [...pages];

  for (let i = 0; i < newPages.length; i++) {
    if (pageToBeRemovedId === newPages[i].id) {
      newPages.splice(i, 1);
    }

    if (newPages[i] && Array.isArray(newPages[i].pages) && newPages[i].pages.length > 0) {
      newPages[i].pages = removePageFromPage({ pageToBeRemovedId, pages: newPages[i].pages });
    }
  }

  for (let i = 0; i < newPages.length; i++) {
    newPages[i].sortOrder = i;
  }

  return newPages;
}

export default removePageFromPage;
