import store from "../store";
import { checkToken } from "../actions/authActions";

/* Control how often at a max we want the token check to run -> this is triggered by in-app navigation */
const THROTTLE_TIME = 20000;
let timer;
let throttlingActive = false;

export default function () {
  /* If throttlingActive is true return / dont do anything */
  if (throttlingActive) {
    return;

    // If throttlingActive isnt true, then execute the token check and
    // set throttlingActive to true for 20 seconds
  } else {
    requestAnimationFrame(() => store.dispatch(checkToken()));
    throttlingActive = true;
    timer = setTimeout(() => {
      throttlingActive = false;
    }, THROTTLE_TIME);
  }
}
