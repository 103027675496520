// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Utilities
import getPagesPath from "../utilities/getPagesPath";

const useCurrentPagePath = ({ pageId = undefined }) => {
  const [pagesPath, setPagesPath] = useState([]);

  const pages = useSelector((state) => state.pageBuilder.pages);

  useEffect(() => {
    if (!pageId) return;
    const calculatedPagePath = getPagesPath({ pageId, pages });
    setPagesPath(calculatedPagePath);
  }, [pageId, pages]);

  return { pagesPath };
};

export default useCurrentPagePath;
