// ActionTypes
import { GET_CHECKLISTS, GET_CHECKLISTS_FAILURE, GET_CHECKLISTS_SUCCESS, SET_CHECKLIST_TAB } from "./actionTypes";
import { feedTypes } from "../components/checklist/config";

// Utilities
import req from "../utilities/request-utility";
import { addToast } from "./uiActions";
import { ErrorOutlineIcon } from "mdi-react";
import React from "react";

/** The selected tab bar value, so the selected tab i remembered when navigating
 * @param {Integer} selectedTab
 * **/
export function setActiveTab(selectedTab) {
  return function (dispatch) {
    dispatch({
      type: SET_CHECKLIST_TAB,
      payload: selectedTab,
    });
  };
}

export function getChecklists({ registrationId, feedType = feedTypes.uncompleted } = {}) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: GET_CHECKLISTS,
        payload: { feedType },
      });

      let checklists = [];

      if (feedType === feedTypes.uncompleted) {
        let { data: uncompletedChecklists } = await req()(`checklists/registrations/${registrationId}`);
        checklists = uncompletedChecklists;
      }

      if (feedType === feedTypes.completed) {
        let { data: completedChecklists } = await req()(`checklists/registrations/${registrationId}/?completed=1`);
        checklists = completedChecklists;
      }

      dispatch({
        type: GET_CHECKLISTS_SUCCESS,
        payload: { checklists, feedType },
      });
    } catch (err) {
      dispatch({ type: GET_CHECKLISTS_FAILURE, payload: { feedTypes } });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotFetchChecklist,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}
