import { format } from "date-fns";
import UseConvertDateFormat from "../../../hooks/useConvertDateFormat";
import useLang from "../../../hooks/useLang";

const DateSelectionBar = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const lang = useLang();
  const convertDateFormat = UseConvertDateFormat();

  return (
    <div className="selected-period-container">
      <div className="date-container">
        <label>{lang.showsFrom}</label>
        <input
          className="date-picker"
          type="date"
          value={convertDateFormat(startDate) || ""}
          onChange={(e) => setStartDate(format(new Date(e.target.value), "yyyyMMdd"))}
        />
      </div>
      <div className="date-container">
        <label>{lang.to.toLowerCase()}</label>
        <input
          className="date-picker"
          type="date"
          value={convertDateFormat(endDate) || ""}
          onChange={(e) => setEndDate(format(new Date(e.target.value), "yyyyMMdd"))}
        />
      </div>
    </div>
  );
};

export default DateSelectionBar;
