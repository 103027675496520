import React, { useState } from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";
import { connect, useSelector } from "react-redux";
import { AlertCircleIcon, ErrorOutlineIcon, FileOutlineIcon, TrashCanOutlineIcon, UploadIcon } from "mdi-react";
import InlineSpinner from "./InlineSpinner";
import req from "../../utilities/request-utility";
import { bindActionCreators } from "redux";
import { addToast } from "../../actions/uiActions";
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../utilities/color-modifiers";
import ALLOWED_FILE_TYPES from "../../config/allowedFileTypes";

const FileUpload = (props) => {
  const [stateFile, setStateFile] = useState({});
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { placeholder, style, boxPadding, removeFile, value, disabled = false } = props;

  const lang = useSelector((state) => state.language.language);

  const onFileSelect = (e) => {
    // Disable the submit btn - if there is any
    if (props.disabledSubmitBtn) props.disabledSubmitBtn();

    let file = e.target.files[0];

    const format = file.name.split(".").slice(-1)[0].toLowerCase();

    // Check if file format is allowed
    const isFormatValid = (format) => {
      if (ALLOWED_FILE_TYPES.includes(format.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    };

    // If the file type is not permitted
    if (!isFormatValid(format)) {
      props.addToast({
        title: lang.fileFormat__placeholder__NotPermitted.replace(/{{placeholder}}/gi, format),
        content: lang.chooseAnotherFileFormat,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      });
      return;
    }

    // Check if file is selected
    if (!file) return; // No file is selected

    // Check file size
    if (file.size > 200000000) {
      return props.addToast({
        type: "error",
        title: lang.tooBig,
        content: lang.fileIsToBigDescription,
        icon: <AlertCircleIcon />,
      });
    } else if (file.size > 39999900) {
      // TO DO! Reimplement with dialog, once that is implemented
      return props.addToast({
        type: "info",
        title: lang.bigFileDetected,
        content: lang.bigFileDetectedDescription,
        icon: <AlertCircleIcon />,
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    uploadFile(formData);
    setStateFile({ status: "uploading", progress: 0 });

    // Reset input
    e.target.value = "";
  };

  const uploadFile = (file) => {
    req()
      .post("/files/", file, { onUploadProgress: (e) => onUploadProgress(e) })
      .then(({ data }) => data)
      .then((file) => onFileUploadSuccess(file))
      .catch((err) => onFileUploadFailure(err));
  };

  const onUploadProgress = (progress) => {
    let progressPercentage = (progress.loaded / progress.total) * 100;
    setStateFile({
      status: "uploading",
      progress: progressPercentage,
    });
  };

  const onFileUploadSuccess = (data) => {
    setStateFile({
      status: "uploaded",
      path: data.file,
    });
    if (props.enableSubmitBtn) props.enableSubmitBtn();
    props.onFile(data.file);
  };

  const onFileUploadFailure = (err, index) => {
    setStateFile({
      icon: <AlertCircleIcon />,
      styleType: "error",
      title: lang.error,
      content: lang.errorCouldNotUploadFile,
      duration: 10000,
    });
    setStateFile({});
  };

  const onFileRemove = () => {
    props.removeFile();
    setStateFile({});
  };

  return (
    <div
      className={`${componentStyle(primaryColor, boxPadding)} ${props.className} ${
        stateFile.status === "uploaded" && value ? "upload-off" : "upload-on"
      }`}
      style={style}
    >
      <label>
        {/** Default value **/}
        {!stateFile.status && <UploadIcon />}

        {/** Image uploaded **/}
        {stateFile.status === "uploaded" && <FileOutlineIcon />}
        {stateFile.status === "uploaded" && <p style={{ textOverflow: "ellipsis" }}>{stateFile.path.split("/").pop()}</p>}

        {/** Image uploading **/}
        {/* Spinner */}
        {stateFile.status === "uploading" && <InlineSpinner size={20} className="spinner" />}
        {/* Progress */}
        {stateFile.status === "uploading" && stateFile.progress < 100 && <p>{Math.round(stateFile.progress) + "%"}</p>}
        {stateFile.status === "uploading" && stateFile.progress === 100 && <p>{"Arbejder med filen..."}</p>}

        {/* Progress bar */}
        {stateFile.status === "uploading" && (
          <div className="progress-bar-container">
            <div style={{ flexGrow: stateFile.progress || 0 }} className="progress-bar colored" />
            <div style={{ flexGrow: 100 - (stateFile.progress || 0) }} className="progress-bar transparent" />
          </div>
        )}

        {/** Default values **/}
        {!stateFile.status && <p style={{ textOverflow: "ellipsis" }}>{placeholder}</p>}

        {/** Input **/}
        <input type="file" accept={"." + ALLOWED_FILE_TYPES.join(",.")} disabled={disabled} onChange={onFileSelect} />
      </label>

      {(stateFile.status === "uploaded" || (value && !stateFile.status)) && removeFile && (
        <TrashCanOutlineIcon className="delete-icon" onClick={!disabled ? () => onFileRemove() : {}} />
      )}
    </div>
  );
};

const componentHeight = 40; //px

const componentStyle = (primaryColor, boxPadding) => css`
  font-size: 1rem;
  color: var(--black);
  display: flex;
  border: 1px var(--midGrey) solid;
  border-radius: 3px;
  overflow: hidden;

  &.upload-on:active {
    border: 1px ${primaryColor} solid;
    transition: 80ms border ease;

    svg {
      border-color: ${primaryColor};
    }
  }

  &.upload-on label:hover {
    cursor: pointer;
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
  }

  &.upload-on label:active {
    transition: 170ms border ease;
    cursor: pointer;
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
  }

  /* Upload button/Input */
  label {
    display: flex;
    flex-grow: 1;
    position: relative;
    align-items: center;
    height: ${componentHeight}px;
    background: var(--white);
    color: var(--black);
    font-family: ${common.fontStack};
    margin: 0 0 ${boxPadding};
    border-radius: 3px;
    margin-bottom: ${boxPadding};
    transition: 170ms border ease;

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: var(--darkGrey);
      border-right: 1px solid var(--midGrey);
    }

    /* Progress bar */
    .progress-bar-container {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 3px;
      display: flex;
      justify-content: space-between;

      .progress-bar {
        height: 3px;
      }

      .progress-bar.colored {
        background-color: ${primaryColor};
      }
    }
  }

  .spinner {
    border-right: 1px solid var(--midGrey);
    margin-right: 0.45rem;
    padding: 0.5rem 0.45rem 0.1rem 0.45rem;
  }

  svg {
    position: relative;
    width: ${componentHeight}px;
    height: ${componentHeight}px;
    margin-right: 0.45rem;
    padding: 0.5rem;
    color: var(--darkGrey);
    border-right: 1px solid var(--midGrey);
  }

  input[type="file"] {
    display: none;
  }

  svg.delete-icon {
    margin-right: 0;
    border-right: 0px;
    transition: 140ms background-color ease;

    &:hover {
      cursor: pointer;
      background-color: var(--ultraLightGrey);
      transition: 70ms background-color ease;
      color: var(--red);
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(FileUpload);
