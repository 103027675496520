// Libs
import React from "react";

function VideoPlayer({ baseURL, video, style = {} }) {
  return (
    <video controls controlsList="nodownload" poster={`${baseURL}f_jpg/${video}`} style={style}>
      {/* Transformation explanation:
        f_<format> mp4|webm should cover most cases
        q_auto = automatic quality, low bandwidth gives lower quality
        w_1600 = means max-width of 1600px
        c_limit = means we will only scale videos down (crop = limit)
      */}
      <source src={`${baseURL}f_mp4,q_auto,w_1600,c_limit/${video}`} type="video/mp4"></source>
      <source src={`${baseURL}f_webm,q_auto,w_1600,c_limit/${video}`} type="video/webm"></source>
    </video>
  );
}

export default VideoPlayer;
