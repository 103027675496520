import { useState, useEffect } from "react";
import dayjs from "dayjs";
import requestUtility from "../../../utilities/request-utility";
import { css } from "emotion";
import InlineSpinner from "../../ui/InlineSpinner";
import colors from "../../../style/colors";
import {
  LabelOutlineIcon,
  ClipboardOutlineIcon,
  PlusCircleOutlineIcon,
  PencilIcon,
  ExportIcon,
  CheckCircleIcon,
  AlertIcon,
} from "mdi-react";
import breakpoints from "../../../config/breakpoints";
import HistoryLogEntry from "../components/HistoryLogEntry";
import LOG_ACTIONS from "../../timeRegistrationAdmin/config/logAction";
import APPROVAL_STATUSES from "../../timeRegistrationAdmin/config/approvalStatus";
import RejectionMessage from "../components/RejectionMessage";
import getPreviousDataEntryInHistory from "../utilities/getPreviousDataEntryInHistory";

function TimeRegistrationHistory({ timeSheetId, timeRegistrationId }) {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    getHistory();
  }, []);

  async function getHistory() {
    let { data } = await requestUtility()(`time-registrations/${timeRegistrationId}/time-sheets/${timeSheetId}/history`);
    setLoading(false);
    setHistory(data);
  }

  return (
    <div className={componentStyles()}>
      {loading && <InlineSpinner style={{ marginTop: "2rem" }} />}

      {!loading &&
        history.map((logEntry, index) => (
          <div className="log-entry" key={logEntry.id}>
            <h3>
              {logEntry.action === LOG_ACTIONS.CREATED && (
                <span style={{ fontWeight: 700 }}>
                  <PlusCircleOutlineIcon />
                  Oprettet
                </span>
              )}

              {logEntry.action === LOG_ACTIONS.EDITED && (
                <span style={{ fontWeight: 700 }}>
                  <PencilIcon />
                  Redigeret
                </span>
              )}

              {logEntry.action === LOG_ACTIONS.EXPORTED && (
                <span style={{ fontWeight: 700 }}>
                  <ExportIcon />
                  Eksporteret
                </span>
              )}

              {logEntry.approvalStatus === APPROVAL_STATUSES.approved && (
                <span style={{ color: colors.green, fontWeight: 700 }}>
                  <CheckCircleIcon color={colors.green} />
                  Godkendt
                </span>
              )}

              {logEntry.approvalStatus === APPROVAL_STATUSES.rejected && (
                <span style={{ color: colors.red, fontWeight: 700 }}>
                  <AlertIcon color={colors.red} />
                  Afvist
                </span>
              )}
            </h3>

            {/* Timestamp */}
            <div className="answer-wrapper">
              <p className="title">Tidsstempel:&nbsp;</p>
              <p className="value">{dayjs(String(logEntry.timestamp), "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm")}</p>
            </div>

            {/* User */}
            <div className="answer-wrapper">
              <p className="title">Bruger:&nbsp;</p>
              <p className="value">
                {logEntry.registeredBy?.name} {logEntry.permissionGroupTitle ? `(${logEntry.permissionGroupTitle})` : ""}
              </p>
            </div>

            {logEntry.comment && <RejectionMessage message={logEntry.comment} groupName={logEntry.permissionGroupTitle} />}

            {logEntry.data && (
              <>
                <div className="divider" />

                <h4>
                  <ClipboardOutlineIcon /> Registrering:
                </h4>

                <HistoryLogEntry
                  logEntryData={logEntry.data}
                  previousLogEntryData={getPreviousDataEntryInHistory({ history, currentIndex: index })}
                />
              </>
            )}
          </div>
        ))}
    </div>
  );
}

const componentStyles = () => css`
  padding: 0 1rem;

  .log-entry {
    padding: 0.85rem;
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    max-width: ${breakpoints.xs}px;
    margin: 1rem auto 1.5rem auto;
    background-color: ${colors.white};

    h3 {
      color: ${colors.black};
      font-size: 1.1rem;
      margin: 0 0 0.85rem 0;
      svg {
        width: 1.15rem;
        height: 1.15rem;
        color: ${colors.darkGrey};
        margin-bottom: -3px;
        margin-right: 0.35rem;
      }
    }
    h4 {
      color: ${colors.darkGrey};
      font-size: 1rem;
      margin: 0 0 0.85rem 0;
      svg {
        width: 1.15rem;
        height: 1.15rem;
        color: ${colors.darkGrey};
        margin-bottom: -3px;
      }
    }

    .divider {
      border-bottom: 1px ${colors.midGrey} solid;
      margin: 0.85rem -0.85rem 0.75rem -0.85rem;
    }

    .title {
      color: ${colors.darkGrey};
    }

    .answer-wrapper {
      margin: 0 0 0.35rem 0;

      &.has-changed,
      .extras-wrapper.has-changed {
        background-color: ${colors.yellowLight};
        border: 1px ${colors.yellow} solid;
        border-radius: 6px;
        margin-left: -0.4rem;
        margin-right: -0.4rem;
        padding: 0.2rem 0.4rem;
      }

      svg {
        width: 1.15rem;
        height: 1.15rem;
        margin: 0 0.15rem -3px 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p.title,
      p.value {
        display: inline-block;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 0;

    .log-entry {
      border-left-width: 0px;
      border-right-width: 0px;
      border-radius: 0px;
    }
  }
`;

export default TimeRegistrationHistory;
