import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import { useSelector } from "react-redux";

const StepBox = (props) => {
  // Get the language from the redux store
  const { language: lang } = useSelector((state) => state.language);

  const { currentStep, lastStep, style, title } = props;

  return (
    <div className={componentStyle(props, colors)} style={style}>
      <p>
        {lang.step__currentStep__outOf__lastStep__.replace("{{currentStep}}", currentStep).replace("{{lastStep}}", lastStep)}
        {title && ` -  ${title}`}
      </p>
    </div>
  );
};

const componentStyle = (props, colors) => css`
  background-color: var(--lightGrey);
  padding: 5px 10px;
  border-radius: 5%;
  display: inline-block;

  p {
    font-size: 12px;
    color: var(--black);
  }
`;

export default StepBox;
