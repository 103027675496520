// Libs
import React from "react";
import { useSelector } from "react-redux";

// Components
import ActionWrapper from "./ActionWrapper";
import { StopIcon, TalkIcon } from "mdi-react";
import { css } from "emotion";
import colors from "../../style/colors";

const ReadAloud = ({ isPlayingAudio, isLoadingAudio, onReadContent }) => {
  const lang = useSelector((state) => state.language.language);

  return (
    <ActionWrapper className={componentStyle()} onClick={onReadContent}>
      {!isPlayingAudio && !isLoadingAudio && (
        <>
          <TalkIcon /> {lang.readOutLoud}
        </>
      )}
      {isPlayingAudio && !isLoadingAudio && (
        <>
          <StopIcon /> {lang.stopReading}
        </>
      )}
      {!isPlayingAudio && isLoadingAudio && (
        <>
          <TalkIcon /> {lang.preparingSpeech}...
        </>
      )}
    </ActionWrapper>
  );
};

const componentStyle = () => css`
  color: var(--darkGrey);
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.15rem 0.25rem;
  margin-left: -0.25rem;
  margin-right: 0.65rem;

  svg {
    margin: 0 0.3rem -2px 0;
    width: 0.9rem;
    height: 0.9rem;
    color: var(--darkGrey);
  }
`;

export default ReadAloud;
