// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTableFilters } from "../../../../../actions/timeRegistrationActions";

const DropDownFilter = ({ column }) => {
  const { setFilter, preFilteredRows, id, Header } = column;

  const dispatch = useDispatch();
  const filterValue = useSelector((state) => state.timeRegistration.table.filters[`${id}`]);

  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();

    // Make copy of prefilteredRows because it effects the sorting of the time registration overview.
    // Sorts the options for external data in ascending alphabetic order for admin table.
    [...preFilteredRows]
      .sort((a, b) => (a.values[id] > b.values[id] ? 1 : -1))
      .forEach((row) => {
        options.add(row.values[id]);
      });
    return [...options.values()];
  }, [id, preFilteredRows]);

  function onChangeHandler(e) {
    setFilter(e.target.value || undefined);
    dispatch(
      updateTableFilters({
        filters: { [`${id}`]: e.target.value },
      })
    );
  }

  useEffect(() => {
    setFilter(filterValue);
  }, [filterValue]);

  // Render a multi-select box
  return (
    <div className="input-container">
      <label>{column.render("Header")}</label>
      <select value={filterValue || ""} onChange={onChangeHandler}>
        <option value="">Vis alle</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDownFilter;
