// Libs
import React, { useState, useRef, useEffect } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import TinyAnimate from "TinyAnimate";
import { addToast, showDialog } from "../../actions/uiActions";
import { lock, unlock } from "tua-body-scroll-lock";

// Utilities
import req from "../../utilities/request-utility";
import { getActiveFromPrimaryColor, getHoverFromPrimaryColor } from "../../utilities/color-modifiers";

// Components
import ImageUploadPreview from "./ImageUploadPreview";
import { UploadIcon, AlertCircleIcon, InfoOutlineIcon, InformationOutlineIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";

// Config
import { fileTypes } from "../../config/fileTypes";

/** Component used to upload images.
 * @param {Object} props
 * @param {String} props.placeholder - A placeholder to be used beside the button
 * @param {Object} props.style - A style object
 * @param {String} props.boxPadding - A valid css property with unit. ie: `1rem`, `16px` etc. Is used as negative margins to make the image slider be page-wide
 * @param {Integer} props.allowedAmountOfImages - Limits the amount of images that can be uploaded. Defaults to 1
 * @param {Boolean} props.disablePreview - Disables previews of uploaded images (if you want to implement this yourself)
 * @param {Boolean} props.disableDelete - Disables the delete-button
 * @param {Array} props.uploadedFiles - Specify a list of existing images, mostly used for editing content
 * @param {Function} props.onFileUpdate - Is called every time there is a change in the images array. Is passed all the images
 * @param {Boolean} props.disableVideoUpload - Disbables the selection of videos
 *
 * @example
 * ```jsx
 * <ImageUploadMultiple
 *   style={{ marginBottom: "2.5rem" }}
 *   onFileUpdate={({ files: images }) => setFormData({ ...formData, images })}
 * />
 * ```
 *
 */

function ImageUploadMultiple(props) {
  let {
    id = "image-upload",
    placeholder,
    style,
    boxPadding = 0,
    allowedAmountOfImages = 1,
    allowedAmountOfVideos = 1,
    allowOnlyOneFileTypeAtATime = false,
    disablePreview,
    uploadedFiles = uploadedFiles ? uploadedFiles : [],
    disableVideoUpload = false,
    disableImageUpload = false,
    disabled = false,
    shouldResetFiles = false,
  } = props;

  const [files, setFiles] = useState([]);
  const [isUploadAllowed, setIsUploadAllowed] = useState(true);
  const { language: lang } = useSelector((state) => state.language);
  const { primaryColor } = useSelector((state) => state.appConfig);

  const previousAmountOfImages = useRef();
  const imageSliderRef = useRef();
  const inputRef = useRef();
  // Ref's "hack" to prevent stale state in event-handlers
  const refFiles = useRef(files);

  useEffect(() => {
    refFiles.current = files;
  });

  // Set uploadedFiles
  useEffect(() => {
    if (uploadedFiles.length === 0) return;

    let processedFiles;

    processedFiles = uploadedFiles
      .filter((file) => file.image || file.video)
      .map((file) => {
        if (file.hasOwnProperty("video")) {
          return {
            status: "uploaded",
            baseURL: file.baseURL,
            image: file.image,
            video: file.video,
            fileType: fileTypes.video,
          };
        } else {
          return {
            status: "uploaded",
            baseURL: file.baseURL,
            image: file.image,
            fileType: fileTypes.image,
            original: file.originalImage,
          };
        }
      });

    setFiles(processedFiles);
    refFiles.current = processedFiles;
    // eslint-disable-next-line
  }, []);

  // Decide if upload is allowed
  useEffect(() => {
    if (imageSliderRef.current) {
      lock(imageSliderRef.current);
    }
    const { numberOfUploadedImages, numberOfUploadedVideos } = getNumberOfUploadedFiles();

    if (
      // Reached limit of upload amount
      allowedAmountOfImages <= numberOfUploadedImages ||
      allowedAmountOfVideos <= numberOfUploadedVideos
    ) {
      setIsUploadAllowed(false);
    } else {
      setIsUploadAllowed(true);
    }

    return () => {
      unlock(imageSliderRef.current);
    };
  }, [uploadedFiles, allowedAmountOfImages, refFiles, files]);

  // Reset the files when the file/s values are reset
  useEffect(() => {
    if (!shouldResetFiles) return;
    setFiles([]);
  }, [shouldResetFiles]);

  function onFileSelect(e) {
    // Disable the submit btn - if there is any
    if (props.disabledSubmitBtn) props.disabledSubmitBtn();
    e.persist();
    // TODO: Validate file type
    let file = e.target.files[0];

    // Check if file is selected
    if (!file) return; // No file is selected

    // Check file size
    if (file.size > 200000000) {
      return props.addToast({
        type: "error",
        title: lang.tooBig,
        content: lang.fileIsToBigDescription,
        icon: <AlertCircleIcon />,
      });
    }

    if (file.size > 39999900) {
      // TO DO! Reimplement with dialog, once that is implemented
      props.showDialog({
        styleType: "neutral",
        title: lang.bigFileDetected,
        content: lang.bigFileDetected,
        icon: <InformationOutlineIcon />,
        primaryAction: () => addFileToState(file),
        primaryActionTitle: lang.uploadFile,
      });
    } else {
      addFileToState(file);
    }

    // Reset input
    e.target.value = "";
  }

  function addFileToState(file) {
    let fileType = file.type.split("/")[0].toLowerCase() === "video" ? fileTypes.video : fileTypes.image;

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    setFiles([...refFiles.current, { status: "uploading", progress: 0, fileType }]);
    uploadFile(formData, refFiles.current.length, fileType);

    // Scroll upload div into view
    setTimeout(scrollImagePreviewToRight, 100);
  }

  function scrollImagePreviewToRight() {
    if (props.disablePreview) return null;

    // When we rotate images or change order this function will run as the new image loads. To prevent it from scrolling
    // to the right, we check if the previous amount of images differs from the current. Only if it does should the
    // scroll be applied
    if (previousAmountOfImages.current === refFiles.current.length) return; // cancel opreation as no new images has been added
    previousAmountOfImages.current = refFiles.current.length;

    // We need to check for current, as it would otherwiser throw an error.
    if (imageSliderRef.current) {
      TinyAnimate.animate(
        imageSliderRef.current.scrollLeft,
        imageSliderRef.current.scrollWidth - imageSliderRef.current.getBoundingClientRect().width,
        300,
        (x) => {
          imageSliderRef.current.scrollLeft = x;
        },
        "easeInOutQuart"
      );
    }
  }

  /**
   * @param {FormData} file - A formdata object ready to be uploaded
   * @param {Number} index - the index in the local copy of files state (used for updating progress)
   * @param {String} fileType - the file type "image" or "video"
   */
  function uploadFile(file, index, fileType) {
    req()
      .post(`${props.urlPrefix || ""}/${fileType}s`, file, { onUploadProgress: (e) => onUploadProgress(e, index) })
      .then(({ data }) => data)
      .then((data) => {
        onFileUploadSuccess(data, index, fileType);
      })
      .catch((err) => {
        onFileUploadFailure(err, index, fileType);
      });
  }

  function onUploadProgress(progress, index) {
    let progressPercent = (progress.loaded / progress.total) * 100;
    let localFiles = [...refFiles.current];
    localFiles[index].progress = progressPercent;
    setFiles(localFiles);
  }

  function onFileUploadSuccess(res, index, fileType) {
    let localFiles = [...refFiles.current];

    if (fileType === fileTypes.video) {
      localFiles[index] = {
        status: "uploaded",
        fileType: fileType,
        baseURL: res.baseURL,
        video: res.video,
        original: res.originalImage,
        deleteToken: res.deleteToken,
      };
    } else {
      localFiles[index] = {
        status: "uploaded",
        fileType: fileType,
        baseURL: res.baseURL,
        image: res.image,
        original: res.originalImage,
        deleteToken: res.deleteToken,
      };
    }

    setFiles(localFiles);

    if (props.enableSubmitBtn) props.enableSubmitBtn();
    let files = localFiles.filter((f) => f.status === "uploaded");
    if (props.onFileUpdate) props.onFileUpdate(files);
  }

  function onFileUploadFailure(err, index) {
    props.addToast({
      icon: <AlertCircleIcon />,
      styleType: "error",
      title: lang.error,
      content: lang.errorCouldNotUploadImage,
      duration: 10000,
    });

    if (refFiles.current[index]) {
      let localFiles = [...refFiles.current];
      localFiles[index].status = "error";
      setFiles(localFiles);
    }
  }

  async function deleteFile(image, index) {
    //this probably throws a network error due to cloudinary not allowing localhost connections
    let localFiles = [...refFiles.current];
    // The deleted files are saved in the array as the index of the images are used when showing progress, changing status and more.
    localFiles[index].status = "deleted";
    setFiles(localFiles.filter((f) => f.status !== "deleted"));
    props.onFileUpdate(localFiles.filter((f) => f.status === "uploaded"));

    req()
      .post("https://api.cloudinary.com/v1_1/toecho/delete_by_token", { token: image.deleteToken })
      .catch((err) => console.log("ImageUploadMultiple.jsx -> Couldn't delete file from external host."));
  }

  function getNumberOfUploadedFiles() {
    let numberOfUploadedImages = refFiles.current.filter(
      (f) => (f.status === "uploading" || f.status === "uploaded") && f.fileType === fileTypes.image
    ).length;
    let numberOfUploadedVideos = refFiles.current.filter(
      (f) => (f.status === "uploading" || f.status === "uploaded") && f.fileType === fileTypes.video
    ).length;

    return { numberOfUploadedVideos, numberOfUploadedImages };
  }

  function getAllowedFileTypes() {
    const { numberOfUploadedImages, numberOfUploadedVideos } = getNumberOfUploadedFiles();

    // Prevents multiple fileTypes to be uploaded
    if (allowOnlyOneFileTypeAtATime && numberOfUploadedImages > 0) {
      return "image/*";
    } else if (allowOnlyOneFileTypeAtATime && numberOfUploadedVideos > 0) {
      return "video/*";
    }
    // Allow image and video
    else if (disableVideoUpload === false && disableImageUpload === false) {
      return "video/*,image/*";
    }
    // Allow only video
    else if (disableVideoUpload === false && disableImageUpload === true) {
      return "video/*";
    }
    // Allow only images
    else {
      return "image/*";
    }
  }

  function rotateImage(direction, index) {
    // In order of the index to be correct, and to make sure we don't switch position with a deleted image not shown to the user, we have to filter out the deleted images
    let localFiles = [...refFiles.current].filter((f) => f.status !== "deleted");
    const localBaseUrl = localFiles[index].baseURL;
    localFiles[index].baseURL = "";
    setFiles(localFiles);
    direction === "right"
      ? (localFiles[index].baseURL = `${localBaseUrl}a_90/`)
      : (localFiles[index].baseURL = `${localBaseUrl}a_-90/`);
    if (props.onFileUpdate) props.onFileUpdate(localFiles);

    setTimeout(() => void setFiles(localFiles), 10000);
  }

  function changeOrder(direction, index) {
    // In order of the index to be correct, and to make sure we don't switch position with a deleted image not shown to the user, we have to filter out the deleted images
    let localFiles = [...refFiles.current].filter((f) => f.status !== "deleted");
    const imageToMove = localFiles[index];
    const imageToSwitch = direction === "right" ? localFiles[index + 1] : localFiles[index - 1];
    const prior = direction === "right" ? localFiles.slice(0, index) : localFiles.slice(0, index - 1);
    const after =
      direction === "right"
        ? localFiles.slice(index + 2, localFiles.length)
        : localFiles.slice(index + 1, localFiles.length);
    const middle = direction === "right" ? [imageToSwitch, imageToMove] : [imageToMove, imageToSwitch];
    const newFileArray = [...prior, ...middle, ...after];
    if (props.onFileUpdate) props.onFileUpdate(newFileArray);
    setFiles(newFileArray);
  }

  return (
    <div className={`${componentStyle(primaryColor, boxPadding)} ${props.className}`} style={style}>
      {isUploadAllowed && (
        <label
          htmlFor={id}
          tabIndex="0"
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") inputRef.current.click();
          }}
        >
          <UploadIcon />
          <p>{placeholder}</p>
          <input
            type="file"
            accept={getAllowedFileTypes()}
            id={id}
            disabled={disabled}
            onChange={(e) => onFileSelect(e)}
            ref={inputRef}
          />
        </label>
      )}
      {!isUploadAllowed && (
        <p className="meta max-uploads-reached">
          <InfoOutlineIcon /> {lang.maximumFileUploadReached}
        </p>
      )}

      {/* Image preview in post-like carousel */}
      {!disablePreview && files.filter((f) => f.status === "uploading" || f.status === "uploaded").length > 0 && (
        <div className="image-preview-container">
          <div className="scroll-hider" ref={imageSliderRef}>
            {
              // In order of the index to be correct, and to make sure we don't switch position with a deleted image not shown to the user, we have to filter out the deleted images
              files
                .filter((f) => f.status !== "deleted")
                .map((image, index) => (
                  <ImageUploadPreview
                    key={image.image}
                    image={image}
                    index={index}
                    files={files.filter((f) => f.status !== "deleted")}
                    changeOrder={changeOrder}
                    rotateImage={rotateImage}
                    scrollImagePreviewToRight={scrollImagePreviewToRight}
                    deleteFile={deleteFile}
                  />
                ))
            }
          </div>
        </div>
      )}
    </div>
  );
}

const previewHeight = 250;
const componentHeight = 40;

const componentStyle = (primaryColor, boxPadding) => css`
  font-size: 1rem;
  color: var(--black);
  border-radius: 3px;

  /* Upload button/Input */

  label {
    display: flex;
    align-items: center;
    height: ${componentHeight}px;
    background: var(--white);
    border: 1px var(--midGrey) solid;
    color: var(--black);
    font-family: ${common.fontStack};
    margin-bottom: ${boxPadding};
    border-radius: 3px;
    outline: 0;
    cursor: pointer;

    &:active {
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
      border-color: ${primaryColor};
    }

    &:active svg {
      border-color: ${primaryColor};
      outline: 0;
    }

    &:hover {
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
    }

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: var(--darkGrey);
      border-right: 1px solid var(--midGrey);
    }
  }

  p.max-uploads-reached.meta {
    margin-bottom: 1rem;

    svg {
      color: var(--darkGrey);
      vertical-align: middle;
      margin-top: -3px;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  input[type="file"] {
    display: none;
  }

  .image-preview-container {
    height: ${previewHeight}px;
    overflow: hidden;
    margin: 0.5rem -${boxPadding} 0;

    .scroll-hider {
      padding: 0 ${boxPadding};
      height: ${previewHeight}px;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(null, mapDispatchToProps)(ImageUploadMultiple);
