import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import TinyAnimate from "TinyAnimate";
import tinycolor from "tinycolor2";
import PropTypes from "prop-types";
import { useDebouncedCallback } from "use-debounce";

import colors from "../../style/colors";
import common from "../../style/common";
import { durations } from "../../config/animations";
import { lock, unlock } from "tua-body-scroll-lock";
import { ChevronRightIcon, ChevronLeftIcon } from "mdi-react";

/** used for adding tab functionality to a component, should always be played just under Topbar component
 * Remember to add Tabview component, if you want to make this work
 *
 * ```jsx
 *
        <TabBar
          activeTabIndex={activeTab}
          tabs={tabs.map((tab, tabIndex) => ({
            title: tab,
            onClick: () => {
              this.setState({ activeTab: tabIndex });
            }
          }))}
        />
        SOME CODE LATER
        <TabView
          tabStyle={{ padding: "1rem 0" }}
          activeTabIndex={activeTab}
          tabs={[

            <>
              {this.state.contacts
                .filter(contact => contact.type === "customGroup")
                .map(contact => (
                  <ListItem
                    onClick={() => pageNavigator(match.url + "/" + contact.id)}
                    title={contact.title}
                    iconRight={<ChevronRightIcon />}
                    key={contact.id}
                  />
                ))}
            </>,

            <>
              {this.state.contacts
                .filter(contact => contact.type !== "customGroup")
                .map(contact => (
                  <ListItem
                    onClick={() => pageNavigator(match.url + "/" + contact.id)}
                    title={contact.title}
                    iconRight={<ChevronRightIcon />}
                    key={contact.id}
                  />
                ))}
            </>
          ]}
        />
 * ```
 */

/**
 * @param {Object} props
 * @param {Number} props.activeTabIndex The currently active tab
 * @param {Object[]} props.tabs Array of tabs
 * @param {String|JSX} props.tabs[].title The title of the tab. Can be JSX for more complex tab-types
 * @param {Function} props.tabs[].onClick Click event when clicking on tab
 * @param {Function} props.tabs[]["data-test-id"] Test id
 * @param {Object} props.style React style-object
 * @param {Boolean} props.minimalPadding uses less padding for a more compact layout
 */
const TabBar = ({ activeTabIndex, tabs, style, minimalPadding = false }) => {
  const { primaryColor } = useSelector((state) => state.appConfig);
  const targetRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [tabBarWidth, setTabBarWidth] = useState(0);
  const [showChevronRight, setShowChevronRight] = useState(false);
  const [showChevronLeft, setShowChevronLeft] = useState(false);

  useEffect(() => {
    lock(targetRef.current);
    window.addEventListener("resize", debouncedHandleShowChevrons);
    return () => {
      unlock(targetRef.current);
      window.removeEventListener("resize", debouncedHandleShowChevrons);
    };
  }, []);

  useEffect(() => {
    handleShowChevrons();
  }, [tabContainerRef.current]);

  const handleShowChevrons = () => {
    setTabBarWidth(tabContainerRef.current ? tabContainerRef.current.offsetWidth : 0);
    // To show chevron right in the tabbar, 2 conditions need to be true: 1: the tabbar should be longer than the window width. 2: we should npt have scrolled enough to the left to have reached the end of the tabbar.
    const isChevronRightNeeded =
      tabBarWidth > window.innerWidth && tabBarWidth - targetRef.current.scrollLeft > window.innerWidth;
    // I show chevron left as soon as you have scrolled to the left. We could add a minimum amount of scroll to the left be done before showing th chevron
    const isChevronLeftNeeded = targetRef.current.scrollLeft;

    setShowChevronRight(isChevronRightNeeded);
    setShowChevronLeft(isChevronLeftNeeded);
  };

  const [debouncedHandleShowChevrons] = useDebouncedCallback(handleShowChevrons, 50);

  const onClickProxy = (next, e) => {
    // Continue with actual navigation
    next();
    // Scroll into view
    scrollActiveTabIntoView(e.target);
  };

  const scrollActiveTabIntoView = (activeTab, duration = 240) => {
    // Get clicked element position
    let clickedElPosition = activeTab.getBoundingClientRect();
    let windowWidth = window.innerWidth;
    let { current: el } = targetRef;

    // Scroll into view from right
    if (windowWidth - clickedElPosition.right < 0) {
      let newScrollDeltaX = clickedElPosition.width - (windowWidth - clickedElPosition.x);
      TinyAnimate.animate(
        el.scrollLeft,
        el.scrollLeft + newScrollDeltaX,
        duration,
        (x) => {
          el.scrollLeft = x;
        },
        "easeInOutQuart"
      );
    }
    // Scroll into view from left
    if (clickedElPosition.x < 0) {
      TinyAnimate.animate(
        el.scrollLeft,
        el.scrollLeft + clickedElPosition.x,
        duration,
        (x) => {
          el.scrollLeft = x;
        },
        "easeInOutQuart"
      );
    }
  };

  const handleClickChevron = ({ scrollLeft = 0 }) => {
    TinyAnimate.animate(
      targetRef.current.scrollLeft,
      scrollLeft,
      240,
      (x) => {
        targetRef.current.scrollLeft = x;
      },
      "easeInOutQuart"
    );
  };

  return (
    <div className={componentStyle(primaryColor, minimalPadding)} style={style}>
      <div className="scroll-hider disable-body-scroll" ref={targetRef} onScroll={handleShowChevrons}>
        <div className="tab-container" ref={tabContainerRef}>
          {tabs.map((tab, tabIndex) => (
            <div
              data-test-id={`tab-${tab["data-test-id"] || tabIndex}`}
              className={Number(tabIndex) === Number(activeTabIndex) ? "active tab" : "tab"}
              onClick={(e) => onClickProxy(tab.onClick, e)}
              key={tabIndex}
            >
              {tab.title}
            </div>
          ))}
          <ChevronRightIcon
            className={`icon-scroll right ${showChevronRight && "show"}`}
            onClick={() => handleClickChevron({ scrollLeft: tabBarWidth - window.innerWidth })}
          />
          <ChevronLeftIcon
            className={`icon-scroll left ${showChevronLeft && "show"}`}
            onClick={() => handleClickChevron({ scrollLeft: 0 })}
          />
        </div>
      </div>

      <div className={`scroll-hinter right ${showChevronRight && "show"}`}></div>
      <div className={`scroll-hinter left ${showChevronLeft && "show"}`}></div>
    </div>
  );
};

const componentStyle = (primaryColor, minimalPadding) => css`
  background-color: var(--white);
  border-bottom: 1px var(--midGrey) solid;
  overflow: hidden;
  flex: ${common.topBarHeight + 1}px 0 0;
  position: relative;

  .tab-container {
    width: fit-content;
  }

  .scroll-hinter {
    position: absolute;
    height: 100%;
    width: 50px;
    top: 0px;
    background: transparent;
    pointer-events: none; /* allow to click through element */

    &.right {
      right: 0px;
      &.show {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
      }
    }

    &.left {
      left: 0px;
      &.show {
        background: linear-gradient(90deg, white, rgba(255, 255, 255, 0));
      }
    }
  }

  .tab {
    cursor: pointer;
    transition: background-color ${durations.normal}ms, border-color ${durations.normal}ms,
      padding ${durations.normal}ms ease;

    &:hover {
      transition: background-color ${durations.ultraFast}ms, border-color ${durations.ultraFast}ms,
        padding ${durations.normal}ms ease;
      background-color: ${tinycolor(colors.white).darken(3).toString()};
      border-color: ${tinycolor(colors.white).darken(3).toString()};
    }

    &:active {
      transition: background-color ${durations.ultraFast}ms, border-color ${durations.ultraFast}ms,
        padding ${durations.normal}ms ease;
      background-color: ${tinycolor(colors.white).darken(7).toString()};
      border-color: ${tinycolor(colors.white).darken(7).toString()};
    }
  }

  .icon-scroll {
    position: absolute;
    height: 100%;
    display: none;
    z-index: 1;
    fill: ${primaryColor};

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }

    &.show {
      display: initial;
    }
  }

  .scroll-hider {
    /* This is added because of x-axes scrolling lagging on IOS 11.3 */
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding-right: 30px;
  }

  svg {
    vertical-align: sub;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.15rem;
  }

  .tab {
    line-height: ${common.topBarHeight - 3}px;
    padding: 0 ${minimalPadding ? "0.65rem" : "1.25rem"};
    display: inline-block;
    color: var(--darkGrey);
    border-bottom: 3px transparent solid;

    &.active {
      border-bottom: 3px ${primaryColor} solid;
      color: ${primaryColor};
      padding: 0 ${minimalPadding ? "0.85rem" : "1.75rem"};

      transition: border-color ${durations.ultraFast}ms, padding ${durations.normal}ms ease;

      &:hover,
      &:active {
        border-bottom: 3px ${primaryColor} solid;
      }
    }
  }
`;

TabBar.propTypes = {
  /** Numerical value which determines current active tab */
  activeTabIndex: PropTypes.number,
  /** Array which holds tab title */
  tabs: PropTypes.array,
  /** Override component style with this */
  style: PropTypes.object,
};

export default TabBar;
