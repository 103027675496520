// Libs
import React from "react";

// Components
import StatusBox from "./StatusBox";
import InformationBox from "./InformationBox";

// Utilities
import notificationErrorTypes from "../../config/notificationErrorTypes";

const NotificationNotFound = ({ error, type, lang }) => {
  return (
    <>
      {error && error.status === 404 && type === notificationErrorTypes.postNotFound && (
        <InformationBox
          title={lang.thePostDoesNotExist}
          description={lang.thePostDoesNotExistDescription}
          style={{ width: "50%", margin: "auto" }}
        />
      )}

      {error && error.status === 404 && type === notificationErrorTypes.registrationNotFound && (
        <InformationBox
          title={lang.thePostDoesNotExist}
          description={lang.theRegstrationDoesNotExistDescription}
          style={{ width: "50%", margin: "auto" }}
        />
      )}

      {error && (error.status === 500 || !error?.status) && <StatusBox />}
    </>
  );
};

export default NotificationNotFound;
