import styleTypes from "../config/styleTypes";

export default (styleType = "") => {
  styleType = String(styleType).toLowerCase();

  switch (styleType) {
    case styleTypes.neutral:
    case "none":
    case "default":
    case "neutral":
    case "standard":
      return "neutral";

    case styleTypes.ok:
    case "ok":
    case "success":
    case "succes":
    case "positive":
      return "ok";

    case styleTypes.error:
    case "error":
    case "negative":
    case "warning":
    case "failed":
    case "fail":
      return "error";

    default:
      return "neutral";
  }
};
