// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { ErrorOutlineIcon } from "mdi-react";
import { useSelector } from "react-redux";

// Utilities
import req from "../../utilities/request-utility";

// Components
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";
import Spinner from "../ui/InlineSpinner";
import ReadAloud from "../ui/ReadAloud";

// Config
import breakpoints from "../../config/breakpoints";
import translationTypes from "../../config/translationTypes";
import readAloudTypes from "../../config/readAloudTypes";

// Actions
import { addToast } from "../../actions/uiActions";

// Styling
import colors from "../../style/colors";
import ScrollView from "../ui/ScrollView";
import Translator from "../ui/Translator";

// Hooks
import useTranslation from "../../hooks/useTranslation";
import useReadAloud from "../../hooks/useReadAloud";

const CertificateResults = (props) => {
  const { hideTitle = false } = props;

  const [certificate, setCertificate] = useState({});
  const [spinner, setSpinner] = useState(true);

  // Language
  const lang = useSelector((state) => state.language.language);

  useEffect(() => {
    req()
      .get(`certificate/${props.quizId}/answers`)
      .then(({ data: certificate }) => {
        setCertificate(certificate);
        setSpinner(false);
      })
      .catch((err) => {
        addToast({
          title: `${lang.errorGeneral}`,
          content: `${lang.errorCouldNotGetQuiz}`,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ScrollView className={componentStyle(breakpoints, colors)}>
      <div className="content-wrapper">
        {spinner && <Spinner />}
        {!spinner && certificate && (
          <>
            {!hideTitle && <p className="title">{certificate.title}</p>}
            {certificate.quizQuestions.map((question) => (
              <Question quizId={props.quizId} question={question} />
            ))}
          </>
        )}
      </div>
    </ScrollView>
  );
};

const Question = ({ quizId, question }) => {
  const [title, setTitle] = useState(question.question);
  const [answers, setAnswers] = useState(question.quizAnswers);

  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedQuestion,
    content: translatedAnswers,
  } = useTranslation({
    type: translationTypes.newsCheckQuestion,
    args: { questionId: question.id, quizId },
  });

  const { isPlayingAudio, isLoadingAudio, readAloudContent } = useReadAloud({
    type: readAloudTypes.newsCheckQuestion,
    args: { questionId: question.id, quizId },
  });

  useEffect(() => {
    if (translatedQuestion) {
      setTitle(translatedQuestion.title);
    }
    if (translatedAnswers) {
      setAnswers((answers) =>
        answers.map((answer) => {
          const translatedAnswer = translatedAnswers.find((translatedAnswer) => translatedAnswer.id === answer.id);
          if (translatedAnswer) {
            return {
              ...answer,
              answer: translatedAnswer.title,
            };
          }
          return answer;
        })
      );
    }
  }, [translatedQuestion, translatedAnswers]);

  return (
    <div style={{ marginBottom: "2rem" }} key={`question-${question.id}`}>
      <p className="sub-title">{title}</p>
      <RadioGroupShowCorrectAnswers
        answers={answers.map(({ answer: title, correct, id }) => ({ title, correct, id }))}
        userAnswer={{ answerId: question.userAnswer.quizAnswerId }}
      />
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Translator
          style={{ marginTop: "0.5rem", marginLeft: "0.125rem" }}
          isTranslated={isTranslated}
          isTranslating={isTranslating}
          onTranslate={translateContent}
        />
        <ReadAloud
          isLoadingAudio={isLoadingAudio}
          isPlayingAudio={isPlayingAudio}
          onReadContent={() => readAloudContent({ currentTranslationLanguage })}
        />
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: var(--white);
  height: 100%;

  div.content-wrapper {
    padding: 1.6rem;
    overflow: auto;
    max-width: ${breakpoints.md}px;
    margin: auto;
  }

  .title {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  .sub-title {
    margin-bottom: 0.8rem;
    font-size: 1rem;
  }

  hr {
    margin: 1.8rem 0;
    height: 1px;
    color: var(--midGrey);
    background-color: var(--midGrey);
    border: none;
  }
`;

export default CertificateResults;
