function NativeCommunicator() {
    const callbacks = {};

    window.addEventListener('native-message', (payload) => {
        const { event, data } = JSON.parse(payload);

        if (callbacks[event]) {
            callbacks[event].forEach(cb => cb(data));
        }
    });


    function send(event, data) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({event, data}));
        }
    }

    function on(event, callback) {
        if (!callbacks[event]) {
            callbacks[event] = [];
        }

        callbacks[event].push(callback);

        console.info('on', event, callback);
    }

    function off(event, callback) {
        if (callbacks[event]) {
            console.info('off', event, callback);
            callbacks[event] = callbacks[event].filter(cb => cb !== callback);
        }
    }

    return { send, on, off };
}

export default NativeCommunicator();