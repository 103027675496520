import React from "react";

import SkeletonLine from "../ui/skeletons/SkeletonLine";

// Style
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

// Icons
import { css } from "emotion";

function LeapeoSkeletonListItem({ delay = 1 }) {
  return (
    <div className={componentStyles()}>
      <div className="line" style={{ marginBottom: "1.15rem" }}>
        <SkeletonLine
          animationDelay={10 * delay}
          width="14"
          height="14"
          style={{ marginRight: "0.5rem", borderRadius: "100%" }}
        />
        <SkeletonLine animationDelay={10 * delay} width="80" height="10" style={{ marginRight: "0.35rem" }} />
        <SkeletonLine animationDelay={10 * delay} width="40" height="10" style={{ marginRight: "0.35rem" }} />
        <SkeletonLine animationDelay={10 * delay} width="120" height="10" style={{ marginRight: "0.35rem" }} />
      </div>
      <div className="line">
        <SkeletonLine
          animationDelay={10 * delay}
          width="80"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="105"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />

        <SkeletonLine
          animationDelay={10 * delay}
          width="90"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="90"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="80"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="120"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="80"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="40"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="90"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
        <SkeletonLine
          animationDelay={10 * delay}
          width="80"
          height="5"
          style={{ marginRight: "0.25rem", marginBottom: "0.6rem" }}
        />
      </div>
    </div>
  );
}

const componentStyles = () => css`
  display: block;
  background-color: var(--white);
  border-top: 1px var(--midGrey) solid;
  border-bottom: 1px var(--midGrey) solid;
  margin-bottom: -1px;
  padding: 0.75rem;
  color: var(--black);
  position: relative;

  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${breakpoints.md}px;
    border: 1px var(--midGrey) solid;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export default LeapeoSkeletonListItem;
