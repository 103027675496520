import { useEffect, useState } from "react";

import pageConfig from "../../../config/pageConfig";

/**
 *
 * @param pages
 * @param search
 */
const useFilteredChildrenNavigationListPage = (page, search) => {
  const [updatedPage, setUpdatedPage] = useState(page);

  useEffect(() => {
    if (!page?.pages) {
      return;
    }

    const sortOrder = page.config ? page.config[pageConfig.NAVIGATION_LIST_SORT_ORDER] : null;
    let pages = page.pages;

    pages = filter(pages, search);

    if (sortOrder) {
      pages.sort((a, b) => {
        const nameA = a.title.toUpperCase(); // ignore upper and lowercase
        const nameB = b.title.toUpperCase(); // ignore upper and lowercase
        
        if (nameA < nameB) {
          if (sortOrder === "title_asc") return -1;
          if (sortOrder === "title_desc") return 1;
        }
        if (nameA > nameB) {
          if (sortOrder === "title_asc") return 1;
          if (sortOrder === "title_desc") return -1;
        }
        return 0;
      });
    }
    setUpdatedPage({ ...page, pages });
  }, [page, search]);

  return updatedPage;
};

/**
 * Filters pages based on search
 * @param { Page[] } pages
 * @param { string } search
 */
function filter(pages, search) {
  if (!search) {
    return pages;
  }

  return pages.filter((page) => page.title.toLowerCase().search(search?.toLowerCase()) !== -1);
}

export default useFilteredChildrenNavigationListPage;
