import React, { useState } from "react";
import { css } from "emotion";
import { AlertDecagramIcon, DownloadIcon, FileIcon, FileOutlineIcon, PencilIcon, TrashCanOutlineIcon } from "mdi-react";
import { useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { useDispatch, useSelector } from "react-redux";
import { addToast, showDialog } from "../../../actions/uiActions";
import { durations } from "../../../config/animations";
import breakpoints from "../../../config/breakpoints";
import useLang from "../../../hooks/useLang";
import styleTypes from "../../../config/styleTypes";
import colors from "../../../style/colors";
import highlightMatch from "../../../utilities/highlight-match";
import req from "../../../utilities/request-utility";
import ButtonRounded from "../../ui/ButtonRounded";
import InlineSpinner from "../../ui/InlineSpinner";
import TextInput from "../../ui/TextInput";

/**
 * List of statuses. This is used to determine UI-state
 */
const statuses = {
  uploading: "uploading",
  afterUpload: "afterUpload",
  publishing: "publishing",
  published: "published",
  editing: "editing",
  deleted: "deleted",
};

/**
 * @param {Object} props
 * @param {Object} props.post - The post to be displayed
 * @param {Object} props.file - The uploaded file (comes from dropdown or fileupload)
 * @param {Number} props.subTypeId - The subtypeId currently being worked on
 * @param {String} props.searchterm - The searchterm from the overview. Is used to highlight matching results
 * @param {Function} props.removeFile - Callback function from parent that removes the current file item
 * @param {Boolean} props.enableFileManegementMode - Toggles the edit and delete buttons.
 */
function FileListItem({
  post = undefined,
  file = undefined,
  subTypeId,
  searchterm,
  removeFile,
  enableFileManagementMode = false,
  uploadAllTrigger,
  initialStatus = statuses.published,
}) {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const dispatch = useDispatch();
  const lang = useLang();

  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    // if uploadAllTrigger has changed and the current item doesnt have a post it means
    // this item hasn't yet been published. The button can potentially be clicked multiple times
    // so for the next time it is pressed only those that has no post created shall be published
    if (uploadAllTrigger > 0 && status !== statuses.published) handlePublish();
  }, [uploadAllTrigger]);

  /**
   * This is the object that the markup reads from. Data can come from either file or post
   * ---
   * This enables us to concatenate different data-sources without the view caring about it
   * The data sources are:
   * - File uploaded from drag-n-drop or fileupload
   * - An existing post
   * - A just created post
   *
   * The `post && !file` potentially could need to be more specific
   */
  let [viewData, setViewData] = useState(
    post && !file
      ? // This is the most common case (we're dealing with a existing post no magic going one)
        {
          id: post.id,
          title: post.title,
          file: post.files[0],
        }
      : // This is the lees common case: we're dealing with a just now uploaded file that hasn't yet been
        // posts to become a post
        {
          title: file.title,
          file: file.file,
        }
  );

  /**
   * Form data
   */
  // This is used so when editing and pressing cancel we know what the title initially was
  const [postTitleOnMount, setPostTitleOnMount] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});

  /* Removal goes through this function that applies the animation, wait for it to finish and then forwards the deletion */
  function removeFileProxy(clientSideId) {
    // Trigger removal animation
    setStatus(statuses.deleted);

    // Wait for animation and then do the actual deletion
    setTimeout(() => {
      removeFile(clientSideId);
    }, durations.normal);
  }

  useEffect(() => {
    if (post && !file) {
      setStatus(statuses.published);
      setPostTitleOnMount(post.title);
    }
    if (!post && file) {
      setStatus(statuses.uploading);
      uploadFile(file);
      setPostTitleOnMount(file.title);
    }
  }, []);

  /**
   * Upload file to S3 so we have a URL to use when attaching it to a news post
   *
   */
  function uploadFile(file) {
    const formData = new FormData();
    formData.append(`file`, file.file); // "file" in the form fields is a not to clever naming convention.
    req()
      .post(`files?filenameWish=${encodeURIComponent(file.title)}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        setUploadedFile(data);
        setViewData((s) => ({ ...s, file: { ...s.file, file: data.filename } }));
        setStatus(statuses.afterUpload);
      })
      .catch((err) => dispatch(addToast({ template: "error" })));
  }

  function handlePublish() {
    setStatus(statuses.publishing);

    // Data for the news post
    const postData = {
      title: viewData.title,
      content: " ", // Content is required... 🥷
      files: [{ baseURL: uploadedFile.baseURL, file: uploadedFile.filename }],
      endDate: "99999999", // Never
    };

    req()
      .post(`news/${subTypeId}`, postData)
      .then(({ data }) => {
        setViewData({
          id: data.id,
          title: data.title,
          file: data.files[0],
        });
        setStatus(statuses.published);
      })
      .catch((err) => dispatch(addToast({ template: "error" })));
  }

  function handleEditStart(e) {
    e.stopPropagation();
    e.preventDefault();
    setStatus(statuses.editing);
  }

  function handleEditSave(e) {
    e.stopPropagation();
    e.preventDefault();

    req()
      .patch(`news/${subTypeId}/${viewData.id}`, { title: viewData.title, files: [viewData.file] })
      .then(({ data }) => setStatus(statuses.published))
      .catch((err) => dispatch(addToast({ template: "error" })));
  }

  function onDelete(e) {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      showDialog({
        icon: <AlertDecagramIcon />,
        styleType: styleTypes.error,
        title: lang.deleteFile,
        content: lang.deleteNewsFileAcrhiveDialogContent,
        primaryActionTitle: lang.yesDeleteFile,
        primaryAction: () => {
          setStatus(statuses.deleted);
          req().delete(`news/${subTypeId}/${viewData.id}`);
        },
      })
    );
  }

  /** For styling and markup convenience the <a> tag is shared for existing and new files
   * For newly uploaded files we dont want the box to download/navigate to the file and we
   * therefore prevent the navigation for any statuses that isnt published.
   */
  function handlePreventDefault(e) {
    if (status !== statuses.published || enableFileManagementMode === true) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  return (
    <AnimateHeight
      height={
        // Hide if deleted
        status === statuses.deleted ||
        // Also hide if not published and filemanegement mode is off
        (enableFileManagementMode === false && status !== statuses.published)
          ? 0
          : "auto"
      }
      duration={durations.normal}
      animateOpacity={true}
    >
      <a
        href={`${viewData.file.baseURL}${viewData.file.file}`}
        rel="noreferrer noopener"
        target="_blank"
        className={componentStyle()}
        onClick={handlePreventDefault}
        draggable={false}
      >
        <div className="icon-wrapper">
          {status === statuses.uploading ? (
            <InlineSpinner size="20" style={{ margin: "0.125rem" }} />
          ) : (
            <FileOutlineIcon style={{ color: primaryColor, width: "1.15rem", height: "1.15rem" }} />
          )}
        </div>

        <div className="content-wrapper">
          {/* Title */}
          <div className="title-wrapper">
            {status === statuses.published ? (
              <span style={{ color: primaryColor, fontWeight: 700 }}>
                {highlightMatch(viewData.title, enableFileManagementMode ? "" : searchterm, "JSX", {
                  display: "inline-block",
                  borderBottom: `1px var(--darkGrey) dashed`,
                  backgroundColor: colors.yellowLight,
                  borderRadius: "3px",
                })}
              </span>
            ) : (
              <TextInput value={viewData.title} onChange={(e) => setViewData({ ...viewData, title: e.target.value })} />
            )}
          </div>

          <div className="subtitle-wrapper">
            {status === statuses.uploading ? <p className="meta">{lang.uploading}</p> : <></>}
          </div>

          {/* Actions */}
          <AnimateHeight height={enableFileManagementMode ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
            <div className="actions-wrapper">
              {/* Being uploaded */}
              {status === statuses.uploading && (
                <>
                  <ButtonRounded
                    onClick={() => removeFileProxy(file.clientSideId)}
                    size={"small"}
                    secondary={true}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {lang.cancel}
                  </ButtonRounded>
                </>
              )}

              {[statuses.afterUpload, statuses.publishing].includes(status) && (
                <>
                  <ButtonRounded
                    onClick={() => removeFileProxy(file.clientSideId)}
                    size={"small"}
                    secondary={true}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {lang.cancel}
                  </ButtonRounded>
                  <ButtonRounded
                    onClick={handlePublish}
                    active={status === statuses.publishing}
                    size={"small"}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {lang.publish}
                  </ButtonRounded>
                </>
              )}

              {[statuses.editing].includes(status) && (
                <>
                  <ButtonRounded
                    onClick={() => {
                      setViewData({ ...viewData, title: postTitleOnMount });
                      setStatus(statuses.published);
                    }}
                    size={"small"}
                    secondary={true}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {lang.cancel}
                  </ButtonRounded>
                  <ButtonRounded
                    onClick={handleEditSave}
                    size={"small"}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {lang.save}
                  </ButtonRounded>
                </>
              )}

              {/* Being uploaded */}
              {[statuses.published, statuses.deleted].includes(status) && (
                <>
                  <ButtonRounded
                    onClick={handleEditStart}
                    size={"small"}
                    secondary={true}
                    styleType={styleTypes.neutral}
                    style={{ marginRight: "0.5rem" }}
                  >
                    <PencilIcon /> {lang.edit}
                  </ButtonRounded>
                  <ButtonRounded onClick={onDelete} size={"small"} secondary={true} styleType={styleTypes.error}>
                    <TrashCanOutlineIcon /> {lang.delete}
                  </ButtonRounded>
                </>
              )}
            </div>
          </AnimateHeight>
        </div>
      </a>
    </AnimateHeight>
  );
}

const componentStyle = (primaryColor) => css`
  display: flex;
  justify-content: space-between;
  color: var(--darkGrey);
  background-color: var(--white);
  border: 1px var(--midGrey) solid;
  border-width: 1px 0;
  padding: 0.8rem 0.65rem;
  margin: 0 auto -1px auto;
  max-width: ${breakpoints.lg}px;

  &:hover {
    background-color: var(--ultraLightGrey);
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    border-width: 1px;
    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }
  }

  .icon-wrapper {
    padding: 0.1rem 0.5rem 0 0;
  }

  .content-wrapper {
    flex-grow: 1;

    .title-wrapper {
      padding: 0 0 0.25rem 0;
      overflow-wrap: anywhere;
      hyphens: auto;
    }

    p.meta {
      overflow-wrap: anywhere;
      hyphens: auto;
    }
    .actions-wrapper {
      display: flex;
      padding: 0.55rem 0 0 0;
      svg {
        width: 1.15rem;
        height: 1.15rem;
        margin-right: 0.35rem;
      }
    }
  }
`;

export { FileListItem as default, statuses };
