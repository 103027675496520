// Libs
import React from "react";
import { css } from "emotion";
import { ChevronRightIcon } from "mdi-react";
import { format, parse } from "date-fns";

// Redux
import { useSelector } from "react-redux";

// Components
import ActionWrapper from "../ActionWrapper";

// Utilities and config
import markdownParser from "../../../utilities/markdown-parser";
import getUserLocale from "../../../utilities/get-user-locale";
import breakpoints from "../../../config/breakpoints";
import useLang from "../../../hooks/useLang";

const markdownConfig = {
  config: { ALLOWED_TAGS: ["strong"] },
};

const NotificationListItem = ({
  description,
  hasInteractedWith,
  date,
  triggeredByUser,
  userCountInNotificationGroup,
  onClick,
  imageLeft,
}) => {
  // Redux state
  const { user } = useSelector((state) => state.auth);
  const lang = useLang();

  const getNotificationText = () => {
    let text = "";

    if (userCountInNotificationGroup > 2) {
      text = `**${triggeredByUser.name}** ${lang.and.toLowerCase()} ${
        userCountInNotificationGroup - 1
      } ${lang.otherUsers.toLowerCase()} ${description}`;
    } else if (userCountInNotificationGroup > 1) {
      text = `**${triggeredByUser.name}** ${lang.and.toLowerCase()} ${
        userCountInNotificationGroup - 1
      } ${lang.otherUser.toLowerCase()} ${description}`;
    } else {
      text = `**${triggeredByUser.name}** ${description}`;
    }

    return markdownParser(text, markdownConfig);
  };

  return (
    <ActionWrapper className={`${componentStyles()}`} onClick={onClick} style={{ width: "100%" }}>
      <div className={`notification ${hasInteractedWith ? "seen" : "unseen"}`}>
        <div className="text-image-container">
          {imageLeft && imageLeft}
          <div>
            <p
              className={`notificationText ${hasInteractedWith ? "seen" : "unseen"}`}
              dangerouslySetInnerHTML={getNotificationText()}
            />
            <p className="date">{format(parse(date, "yyyyMMddHHmmss", 0), "HH:mm - dd. MMMM yyyy", getUserLocale(user))}</p>
          </div>
        </div>
        <ChevronRightIcon className="navigation-arrow-indicator" />
      </div>
    </ActionWrapper>
  );
};

const componentStyles = () => css`
  border: 1px var(--midGrey) solid;
  border-radius: 0px;
  margin-bottom: -1px;
  background-color: var(--white);

  .notificationText {
    &.seen {
      color: var(--midDarkGrey);
    }
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }
    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }
  }
  .notification {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.65rem;

    p {
      color: var(--black);
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 1rem;
    }

    &.unseen {
      background-color: var(--primary-color_alpha-005);
      border-left: 3px solid var(--primary-color);
      margin-left: -1px;
    }

    .text-image-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        flex-shrink: 0;
      }
    }

    .navigation-arrow-indicator {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      color: var(--midDarkGrey);
      margin-left: 1rem;
    }

    .date {
      color: var(--midDarkGrey);
      font-size: 0.8rem;
      margin: 0.25rem 0;
    }
  }
`;

export default NotificationListItem;
