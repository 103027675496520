import React, { useState } from "react";
import useCurrentPage from "../../../hooks/useCurrentPage";
import Page from "../../ui/Page";
import TabBar from "../../ui/TabBar";
import TopBar from "../../ui/TopBar";
import HighscoreUserListContainer from "../../highscore/components/HighscoreUserListContainer";
import { css } from "emotion";
import useSWR from "swr";
import { swrFetcher } from "../../../utilities/swr-fetcher";
import RegistrationTippingHighscore from "./registration-tipping-highscore/RegistrationTippingHighscore";
import RegistrationTippingMyAnswer from "./registration-tipping-highscore/RegistrationTippingMyAnswer";
import TabView from "../../ui/TabView";

function RegistrationTippingHighscoreOverview(props) {
  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    {
      title: "Highscore",
      onClick() {
        setActiveTabIndex(0);
      },
    },
    {
      title: "Mit Svar",
      onClick() {
        setActiveTabIndex(1);
      },
    },
  ];

  return (
    <Page title={page.title}>
      <TopBar useDefaultBackButton title={page.title}></TopBar>
      <TabBar tabs={tabs} activeTabIndex={activeTabIndex}></TabBar>
      <TabView
        activeTabIndex={activeTabIndex}
        tabs={[
          <RegistrationTippingHighscore registrationId={page.dataId} />,
          <RegistrationTippingMyAnswer registrationId={page.dataId} />,
        ]}
      />
    </Page>
  );
}

export default RegistrationTippingHighscoreOverview;
