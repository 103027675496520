// Libs
import { endOfWeek, format as dateFormat, startOfWeek, startOfMonth, endOfMonth } from "date-fns";
import dayjs from "../../../utilities/dayjs";
import getUserLocale from "../../../utilities/get-user-locale";

export default function getDateInterval({ date = new Date(), locale, format = "yyyyMMdd", config = undefined }) {
  if (config && config.TIME_REGISTRATION_CUSTOM_TIME_PERIOD === "CG_JENSEN") {
    const startDate = dayjs().subtract(1, "month").startOf("month").add(19, "days").format("YYYYMMDD");
    const endDate = dayjs().startOf("month").add(18, "days").format("YYYYMMDD");
    return { startDate, endDate };
  }

  const startDate = dateFormat(startOfWeek(date, locale), format, getUserLocale(locale));
  const endDate = dateFormat(endOfWeek(date, locale), format, getUserLocale(locale));
  return { startDate, endDate };
}
