// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useDispatch } from "react-redux";

// Components
import ButtonRounded from "../../../../ui/ButtonRounded";
import PageContent from "../../pageContent/PageContent";
import StatusBox from "../../../../ui/StatusBox";

// Styles
import CreatePageStyle from "./CreatePage.style";

// Hooks
import useCreatePage from "./hooks/useCreatePage";

// Utilities
import useDisableCreatePageButton from "./hooks/useDisableCreatePageButton";

const CreatePage = (props) => {
  const { createPage, error, loading } = useCreatePage();
  const { disableButton: disableSubmitButton } = useDisableCreatePageButton();

  const [disabledCreate, setDisableCreate] = useState(true);
  const [page, setPage] = useState({
    title: "",
    title_fl: "",
    description: "",
    description_fl: "",
    icon: "",
    module: "",
    pages: [],
    parentId: props.parentPageId || 0,
    showAtFrontPage: true,
    active: true,
    noTargetGroup: true,
    meta: {},
    targetGroup: {
      users: [],
      userGroups: [],
      masterGroups: [],
      customGroups: [],
      jobTitles: [],
      modified: false,
    },
  });

  function onChangeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;

    setPage((page) => {
      return { ...page, [name]: value };
    });
  }

  useEffect(() => {
    const disable = disableSubmitButton(page);

    if (disabledCreate !== disable) setDisableCreate(disable);
  }, [page]);

  return (
    <div
      data-test-id="page-builder-create-page-container"
      className={`${CreatePageStyle()} ${props.className ? props.className : ""}`}
    >
      <div className="create-page-wrapper">
        {error && <StatusBox style={{ marginTop: "2rem" }} />}
        {!error && (
          <>
            <PageContent page={page} isCreateMode={true} onChangeHandler={onChangeHandler} />
            <div className="create-btn-wrapper">
              <ButtonRounded
                data-test-id={"create-btn"}
                disabled={disabledCreate}
                active={loading}
                className="create-btn"
                onClick={() => createPage(page)}
              >
                Create page
              </ButtonRounded>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreatePage;
