// Libs
import React from "react";

// Components
import Checkbox from "../components/table/Checkbox";
import ActionCell from "../components/table/ActionCell";

export default function (columns) {
  /******************  ACTUAL DATA COLUMNS ***************/

  let initialColumns = [
    {
      Header: ({ toggleRowSelected, rows, selectedFlatRows }) => {
        const title = "Toggle All Rows Selected";
        // The "select all checkbox" should be marked as selected, when alle the selectable rows has been selected (there is as many selected rows as there is rows which hasn't been exported)
        const checked = rows.filter((row) => !row.values.exported).length === selectedFlatRows.length;
        // The "select all checkbox" should be marked as indeterminate, when one or more of the selectable rows has been selected, but NOT ALL of the selectable rows are selected.
        const indeterminate =
          selectedFlatRows.length > 0 && rows.filter((row) => !row.values.exported).length < selectedFlatRows.length;
        // The "select all checkbox" should be disabled if all the visible rows has been exported.
        const disabled = rows.filter((row) => !row.values.exported).length === 0;
        const overiddenOnChange = (e) => {
          rows.forEach((row) => {
            if (!row.values.exported) {
              toggleRowSelected(row.id, e.currentTarget.checked);
            }
          });
        };

        const modifiedToggleAllRowsProps = {
          onChange: overiddenOnChange,
          checked: checked,
          title: title,
          indeterminate: indeterminate,
          disabled: disabled,
        };

        return (
          <div>
            <Checkbox {...modifiedToggleAllRowsProps} />
          </div>
        );
      },

      accessor: "id",
      id: "bulk-select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <div>
          <Checkbox exported={row.values.exported} {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
  ];

  initialColumns = [...initialColumns, ...columns];

  initialColumns.push({
    Header: "Handlinger",
    accessor: "id",
    id: "id",
    disableFilters: true,
    Cell: ({ value, row, cell }) => (
      <ActionCell exported={row.values.exported} value={value} user={cell.row.original.user} />
    ),
  });

  /******************  APPROVALS, EXPORT AND ACTIONS ***************/

  return initialColumns;
}
