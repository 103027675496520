// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import CheersForPeersPosts from "./CheersForPeersPosts";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Actions
import { getRegistrationPosts, resetAll } from "../../actions/registrationActions";

// Config
import { feedTypes, postLayoutModes } from "../registration/config";

const CheersToMe = (props) => {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  let { dataId: registrationId } = queryString.parse(window.location.search);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(getRegistrationPosts({ registrationId, feedType: feedTypes.toMe }));
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView>
        <CheersForPeersPosts layoutMode={postLayoutModes.accordion} feedType={feedTypes.toMe} />
      </ScrollView>
    </Page>
  );
};

export default CheersToMe;
