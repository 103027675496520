// Libraries
import { css } from "emotion";

// Media
import { ReactComponent as EkkoAppLogoSvg } from "../../images/logo/ekko-app-logo-horizontal.svg";

export default function ({ className, style = {} }) {
  return (
    <div style={style} className={`${componentStyles()} ${className || ""}`}>
      <EkkoAppLogoSvg className="logo" />
    </div>
  );
}

const componentStyles = () => css`
  .logo {
    height: 100%;
    width: 100%;
  }
`;
