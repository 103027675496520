
/** Checks if language is valid
 * @name isValidTranslatorLanguge
 * @param {String} language - `en`, `da` etc
 * @param {Array} translatorLanguage
 * @returns {Boolean} - Returns true if translatorLanguageIdentifier is valid - otherwise false
 */
export default ({ language = "", translatorLanguages = [] }) => {
  const exists = translatorLanguages.find((translatorLanguage) => translatorLanguage.identifier === language);

  if (exists) {
    return true;
  } else {
    return false;
  }
};
