import { css } from "emotion";
import ButtonRounded from "../../ButtonRounded";
import styleTypes from "../../../../config/styleTypes";
import colors from "../../../../style/colors";
import { hideModalPage, showDialog } from "../../../../actions/uiActions";
import { magicNumbers } from "../config/magicNumbers";
import { useDispatch, useSelector } from "react-redux";
import { resetTargetGroup } from "../actions/targetGroupModalActions";
import useLang from "../../../../hooks/useLang";

function MainActionsBar({ onTargetGroup }) {
  const dispatch = useDispatch();
  const lang = useLang();
  const targetGroup = useSelector((s) => s.targetGroupModal.targetGroup);

  return (
    <div className={"main-actions-bar " + componentStyle()}>
      <ButtonRounded
        onClick={() =>
          dispatch(
            showDialog({
              styleType: styleTypes.error,
              title: lang.resetTargetGroup,
              content: lang.resetChosenTargetGroup,
              primaryActionTitle: lang.yesResetTargetGroup,
              primaryAction: () => dispatch(resetTargetGroup()),
            })
          )
        }
        styleType={styleTypes.error}
        secondary={true}
      >
        {lang.reset}
      </ButtonRounded>
      <ButtonRounded
        data-test-id={"btn__target-group-modal__save"}
        onClick={() => {
          onTargetGroup(targetGroup);
          dispatch(hideModalPage());
        }}
      >
        {lang.save}
      </ButtonRounded>
    </div>
  );
}

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors.white};
  padding: 0.65rem;
  border-bottom: 1px ${colors.midGrey} solid;
  height: ${magicNumbers.mainActionsBarHeight}px;

  button {
    margin: 0 0.35rem;
  }
`;

export default MainActionsBar;
