import React from "react";
import { HelpCircleIcon } from "mdi-react";
import { ReactComponent as CustomHPBygIcon } from "../images/customIcons/hp-byg.svg";
import { ReactComponent as CustomOxneholmIcon } from "../images/customIcons/oxneholm-logo.svg";
import { ReactComponent as CustomOxneholmOutlineIcon } from "../images/customIcons/oxneholm-logo_2.svg";

export default function (iconName = "", styles = null) {
  if (!iconName) return <HelpCircleIcon style={styles} />;

  iconName = iconName.trim().toLowerCase();

  switch (iconName) {
    case "custom-hp-byg":
      return <CustomHPBygIcon style={styles} />;

    case "custom-oxneholm-logo":
      return <CustomOxneholmIcon style={styles} />;

    case "custom-oxneholm-logo-outline":
      return <CustomOxneholmOutlineIcon style={styles} />;

    default:
      return <HelpCircleIcon style={styles} />;
  }
}
