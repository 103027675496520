import { getMonthAndDateString, getYearString, parseMonthAndDate } from "./parse-date";
import { parseISO, getYear } from "date-fns";

function calculateAge({ year, birthday }) {
  return year - getYear(parseISO(birthday));
}

/* 
  In the function below we will loop through the array of birthdays to order them in groups of birthdays happening on the same date.
  The result is the finalBirthdays array consisting of objects, one for each date where one or more users have their birthdays.
  Inside each object is yet another array containing all birthdays happening on that specific date.
  Example: 

    finalBirthdays = [{
    date: "0825"
    birthdaysContainer: [{ 
      name: "Example Name",
      birthday: "19890825",
      age: 32,
      ...
    }, {
      name: "Second Example Name",
      birthday: "19740825",
      age: 47,
      ...
    }]
    title: "25/08/2021"
  }, ...]
*/

export default ({ birthdays, language, stateBirthdays = [], year }) => {
  try {
    /*  The birthdays dates used to be in the format "MMDD", they are now in the format "YYYYMMDD". 
    In order to make the old code work is you'll see birthday.birthday.substring(4, 8)), several places. One day we could clean this up */

    // Get currentdate in the format "MMDD"
    const currentDate = getMonthAndDateString();

    // If there already is existing birthdays from the redux state -> append them
    const finalBirthdays = [...stateBirthdays] || [];

    // The index is used below to check whether or not a group has already been created for the date where the birthday in question is happening. If the index is -1 no group is created and we should create a new. This is the default.
    let index = -1;
    let currentYear = parseInt(getYearString());

    birthdays.forEach((birthday) => {
      // If the birthday is happening today
      // There is not much difference between the code in the if and else block. One day we could clean this up.
      if (currentDate === birthday.birthday.substring(4, 8) && year === currentYear) {
        // Check if a birthday object has been created for today
        index = finalBirthdays.findIndex((groupDate) => groupDate.date === birthday.birthday.substring(4, 8));
        // If a birthday object exist for today push the birthday in question to the existing object
        if (index >= 0) {
          finalBirthdays[index].birthdaysContainer.push({
            ...birthday,
            age: calculateAge({ year, birthday: birthday.birthday }),
          });
        }
        // If a birthday object does not exist for today create and push the full object
        else {
          finalBirthdays.push({
            date: birthday.birthday.substring(4, 8),
            birthdaysContainer: [{ ...birthday, age: calculateAge({ year, birthday: birthday.birthday }) }],
            title: `${language.today}`,
          });
        }
      }

      // If it's any other date than the current
      else {
        /// Check if a birthday object has been created for the date of the birthday in question
        index = finalBirthdays.findIndex((groupDate) => groupDate.date === birthday.birthday.substring(4, 8));
        // If a birthday object exist for the date of the birthday in question push the birthday in question to the existing object
        if (index >= 0) {
          finalBirthdays[index].birthdaysContainer.push({
            ...birthday,
            age: calculateAge({ year, birthday: birthday.birthday }),
          });
        }
        // If a birthday object does not exist for the date of the birthday in question create and push the full object
        else {
          finalBirthdays.push({
            date: birthday.birthday.substring(4, 8),
            birthdaysContainer: [{ ...birthday, age: calculateAge({ year, birthday: birthday.birthday }) }],
            title: `${parseMonthAndDate(birthday.birthday.substring(4, 8))}/${year}`,
          });
        }
      }
    });
    // Return the final structured birthdays
    return finalBirthdays;
  } catch (err) {
    throw err;
  }
};
