import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showNextToast } from "../../actions/uiActions";
import Toast from "./Toast";
import { CSSTransition } from "react-transition-group";
import { durations } from "../../config/animations";

const ToastHandler = props => {
  const { toasts, showNextToast } = props;

  return (
    <CSSTransition
      in={Boolean(toasts.length && toasts[0].active !== false)}
      timeout={durations.normal}
      mountOnEnter={true}
      unmountOnExit={true}
      classNames="toast"
    >
      {toasts.length > 0 ? <Toast {...toasts[0]} key={toasts[0].title} closeCallback={showNextToast} /> : <></>}
    </CSSTransition>
  );
};

const mapStateToProps = state => ({
  toasts: state.ui.toasts
});
const mapDispatchToProps = dispatch => ({
  showNextToast: bindActionCreators(showNextToast, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastHandler);
