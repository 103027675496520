import { css } from "emotion";
import { swrFetcher } from "../../utilities/swr-fetcher";
import useSWR from "swr";
import { WebIcon } from "mdi-react";
import { useSelector } from "react-redux";

/**
 * @param {import("../../utilities/extract-links-from-markdown").PostLinkData} anon
 */
export default function PostLink({ url, label, onClick }) {
  const { data, isLoading, error } = useSWR(`meta/og-extractor/?url=${encodeURIComponent(url)}`, swrFetcher);

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  return (
    <a className={`${style({ primaryColor })}`} href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <div className="thumbnail-container">
        {data?.image && <img src={data.image} alt={label} />}
        {!data?.image && <WebIcon />}
      </div>
      <div className="info-container-outer">
        <div className="info-container-inner">
          <p className="label">{data?.title ?? label}</p>
          <p className={`url ${data?.title || label ? "nowrap" : ""}`}>{url}</p>
        </div>
      </div>
      <div className="link-icon"></div>
    </a>
  );
}

const style = ({ primaryColor }) => css`
  display: grid;
  padding: 5px;

  grid-template-columns: 40px 1fr auto;
  gap: 5px;

  border: 1px solid var(--midGrey);
  border-bottom: none;

  .thumbnail-container {
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--lightGrey);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    svg {
      fill: ${primaryColor};
    }
  }

  .info-container-outer {
    position: relative;
  }

  .info-container-inner {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-size: 0.9rem;
    color: var(--black);

    .label {
      font-weight: 700;
      vertical-align: top;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .url,
    .label {
      overflow: hidden;
      text-overflow: ellipsis;

      &.nowrap {
        white-space: nowrap;
      }
    }
  }
`;
