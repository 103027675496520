// Libraries
import React, { useState } from "react";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";

// Components
import { LocationIcon, CheckIcon, CloseIcon, ErrorOutlineIcon } from "mdi-react";
import InlineSpinner from "./InlineSpinner";

// Styles
import colors from "../../style/colors";
import { useEffect } from "react";
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../utilities/color-modifiers";

// Actions
import { addToast } from "../../actions/uiActions";

const GeoLocator = ({ onGetLocation, initialValue }) => {
  const [error, setError] = useState(null);
  const [coords, setCoords] = useState(null);
  const [fetching, setFetching] = useState(false);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.language);

  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  useEffect(() => {
    if (initialValue) {
      setCoords(initialValue);
    }

    if (!navigator.geolocation) {
      setError(lang.geolocationNotSupported);
    }
  }, []);

  const handleGetLocation = () => {
    setFetching(true);
    setError(null);

    // Get location
    navigator.geolocation.getCurrentPosition(
      (location) => {
        onGetLocation({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          accuracy: location.coords.accuracy,
          link: `https://maps.google.com/?q=${location.coords.latitude},${location.coords.longitude}`,
        });
        setCoords(location.coords);
        setFetching(false);
      },
      (error) => {
        setError(error.message);

        dispatch(
          addToast({
            title: lang.error,
            content: lang.errorGeneral,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
          })
        );

        if (error.code === error.PERMISSION_DENIED) {
          setError(lang.weDontHavePermissionToUseYourLocation);
        } else {
          setError(lang.errorGeneral);
        }

        setFetching(false);
      }
    );
  };

  return (
    <>
      <div className={`${componentStyle(primaryColor)}`} onClick={handleGetLocation}>
        <div className="icon">
          {!coords && !error && !fetching && <LocationIcon />}
          {coords && !error && !fetching && <CheckIcon className="success" />}
          {error && !fetching && <CloseIcon className="error" />}
          {fetching && <InlineSpinner className="fetching" size={20} />}
        </div>
        <div className="content">
          {!coords && !error && <p>{lang.saveLocation}</p>}
          {coords && !error && (
            <p>
              {coords.latitude}, {coords.longitude}
            </p>
          )}
          {error && <p>{error}</p>}
        </div>
      </div>
    </>
  );
};

const componentStyle = (primaryColor) => css`
  cursor: pointer;

  border: 1px solid var(--midGrey);
  border-radius: 3px;
  display: flex;

  /* Vertical center */
  align-items: center;
  min-height: 40px;

  :hover {
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
  }

  :active {
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
    transition: background-color 140ms ease;
  }

  .content {
    border-left: 1px solid var(--midGrey);
    padding: 8px;

    p {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }

  .icon {
    height: 40px;
    width: 39px;

    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .success {
      fill: var(--green);
    }

    .error {
      fill: var(--red);
    }

    .fetching {
      width: 20px;
      height: 20px;
    }
  }

  color: var(--darkGrey);
`;

export default GeoLocator;
