// Action Types
import {
  END_OF_NOTIFICATIONS_FEED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  RESET_NOTIFICATIONS,
} from "../actions/actionTypes";

const initialState = {
  notifications: [],
  loading: false,
  error: false,
  endOfFeed: false,
  showSkeleton: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload.notifications],
        loading: false,
        error: false,
        showSkeleton: false,
      };

    case END_OF_NOTIFICATIONS_FEED:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: true,
        showSkeleton: false,
      };

    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        showSkeleton: false,
      };

    case RESET_NOTIFICATIONS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
