import req from "./request-utility";
export const swrFetcher = (...args) =>
  req()(...args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      let error = {};
      error.text = new Error("An error occurred while fetching the data."); // Error message

      error.info = err; // Error object
      error.status = err.response.status; // Error status code
      throw error;
    });
