import React from "react";
import { css } from "emotion";
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import Button from "../ui/Button";
import { CheckIcon, TimerIcon } from "mdi-react";
import { connect, useSelector } from "react-redux";
import Challenge from "./Challenge";
import { bindActionCreators } from "redux";
import { showModalPage } from "../../actions/uiActions";
import ScrollView from "../ui/ScrollView";

const ChallengeResults = (props) => {
  const { correctAnswers, totalQuestions, answerTimeSeconds } = props.results;

  const { challenge } = useSelector((state) => state.academy.challenge);

  // Language
  const lang = useSelector((state) => state.language.language);

  return (
    <ScrollView className={componentStyle(breakpoints, colors)}>
      <div className="container">
        <div className="my-best-score-container">
          <p>{lang.result}</p>
          <div>
            <CheckIcon size={16} color={colors.darkGrey} />
            <p>
              {lang.__placeholderCorrectAnswers__outOf__placeholderNumberOfQuestions__correct
                .replace(/{{placeholderCorrectAnswers}}/gi, correctAnswers)
                .replace(/{{placeholderNumberOfQuestions}}/gi, totalQuestions)}
            </p>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <TimerIcon size={16} color={colors.darkGrey} />
            <p>{`${answerTimeSeconds} ${lang.seconds.toLowerCase()}`}</p>
          </div>

          <Button
            onClick={() =>
              props.showModalPage({
                title: `${challenge.title}`,
                content: <Challenge challenge={challenge} />,
                pageStyle: { backgroundColor: colors.white },
              })
            }
          >
            {lang.tryAgain}
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};
const componentStyle = (breakpoints, colors) => css`
  background-color: var(--white);
  width: 100%;
  height: 100%;

  .container {
    max-width: ${breakpoints.md}px;
    margin: auto;
    padding: 1rem;
    overflow: auto;
  }

  .my-best-score-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    max-width: ${breakpoints.md}px;

    p:first-child {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        margin-left: 0.5rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
});
export default connect(null, mapDispatchToProps)(ChallengeResults);
