// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import tinycolor from "tinycolor2";

// Utilites
import { showModalPage } from "../../actions/uiActions";

// Components
import ActionWrapper from "./ActionWrapper";
import { ThumbsUpIcon, ThumbsUpOutlineIcon } from "mdi-react";
import InlineSpinner from "./InlineSpinner";
import CommentLikesOverviewModal from "../news/CommentLikesOverviewModal";

// styles
import colors from "../../style/colors";

// Actions
import { refreshPostCommentLikes } from "../../actions/socialActions";
import { getActiveFromPrimaryColor, getHoverFromPrimaryColor } from "../../utilities/color-modifiers";

const CommentLikeButton = (props) => {
  // Props
  const { onCommentLike, comment, submittingLikedOnCommentId, isCommentLiked } = props;

  // Redux state
  const { primaryColor } = useSelector((state) => state.appConfig);

  // React States
  const [likeCount, setLikeCount] = useState(comment.likes.length);

  // Is the comment liked
  let isLiked = isCommentLiked(comment);

  useEffect(() => {
    setLikeCount(comment.likes.length);
  }, [comment.likes]);

  return (
    <div className={componentStyle(isLiked, likeCount, primaryColor)}>
      <ActionWrapper disableFocusStyle={true}>
        <div className="comment-like">
          <div className="toggle-like-button" onClick={() => onCommentLike(comment)}>
            {isLiked && submittingLikedOnCommentId !== comment.id && <ThumbsUpIcon />}
            {!isLiked && submittingLikedOnCommentId !== comment.id && <ThumbsUpOutlineIcon />}
            {isLiked && submittingLikedOnCommentId === comment.id && (
              <InlineSpinner size="16" style={{ marginBottom: "-0.5rem" }} color={colors.white} />
            )}
            {!isLiked && submittingLikedOnCommentId === comment.id && (
              <InlineSpinner size="16" style={{ marginBottom: "-0.5rem" }} color={colors.darkGrey} />
            )}
          </div>
          <div className="vertical-divider" />
          <div
            className="number-of-likes"
            onClick={() =>
              props.showModalPage({
                title: "Likes",
                content: <CommentLikesOverviewModal likes={comment.likes} />,
                useScrollView: false,
                pageStyle: { backgroundColor: colors.white },
              })
            }
          >
            {likeCount > 99 ? <p>{"99+"}</p> : <p>{likeCount}</p>}
          </div>
        </div>
      </ActionWrapper>
    </div>
  );
};

const height = 24;

const componentStyle = (liked, likeCount, primaryColor) => css`
  .comment-like {
    display: flex;
    align-items: center;
    border-radius: ${height / 2}px;
    border: 1px solid var(--midGrey);
    height: ${height}px;
    margin: 0 8px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;

      svg {
        fill: ${liked ? colors.white : colors.darkGrey};
        transition: color 220ms ease;
        width: 0.85rem;
        height: 0.85rem;
        margin: 0 8px 0 12px;
      }

      p {
        color: var(--darkGrey);
        line-height: ${height}px;
        transition: color 220ms ease;
        font-size: 0.75rem;
        margin: 0 12px 0 8px;
        font-weight: bold;
      }
    }

    .toggle-like-button {
      height: ${height - 2}px;
      background-color: ${liked ? primaryColor : colors.white};
      border-top-left-radius: ${height / 2}px;
      border-bottom-left-radius: ${height / 2}px;

      &:active {
        background-color: ${tinycolor(liked ? primaryColor : colors.white)
          .setAlpha(0.8)
          .toString()};
      }

      ${likeCount === 0 &&
      css`
        border-top-right-radius: ${height / 2}px;
        border-bottom-right-radius: ${height / 2}px;
      `}

      /* If comment is liked add a single pixel to margin right so the button keeps the same width */
      ${liked &&
      css`
        margin-right: 1px;
      `}
    }

    .vertical-divider {
      display: ${likeCount === 0 || liked ? "none" : "unset"};
      width: 1px;
      background-color: var(--midGrey);
      height: ${height - 12}px;
    }

    .number-of-likes {
      display: ${likeCount === 0 ? "none" : "unset"};
      flex-grow: 1;
      border-top-right-radius: ${height / 2}px;
      border-bottom-right-radius: ${height / 2}px;

      &:hover {
        background-color: ${getHoverFromPrimaryColor(primaryColor)};
        transition: background-color 60ms ease;
      }
      &:active {
        background-color: ${getActiveFromPrimaryColor(primaryColor)};
      }

      p {
        text-align: center;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  refreshPostCommentLikes: bindActionCreators(refreshPostCommentLikes, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(CommentLikeButton);
