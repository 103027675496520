// Libs
import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Config
import pageConfig from "../../../config/pageConfig";
import contactTypes from "../config/contactsTypes";
import groupTypes from "../config/groupTypes";

// Components
import { ContactsGroupList } from "./ContactsGroupList";
import { ContactsList } from "./ContactList";
import TabView from "../../ui/TabView";

// Hooks
import useCurrentPage from "../../../hooks/useCurrentPage";

// Utilities
import { getGroupType } from "../utilities/getGroupType";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";

// Actions
import {
  clearAllContacts,
  getRelatedContactsApps,
  setActiveTab,
  setPhoneBookFiltersValue,
  setPreviousState,
} from "../../../actions/contactsActions";

const ContactsTabViewContainer = (props) => {
  const dispatch = useDispatch();
  const { activeTab, previousState, filters } = useSelector((state) => state.contact);

  const { HIDE_PHONEBOOK_TAB, HIDE_KEYPERSONS_TAB, GROUP_PHONEBOOK, GROUP_KEYPERSONS } = pageConfig;

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const config = page.config;

  useLayoutEffect(() => {
    dispatch(clearAllContacts());
    dispatch(getRelatedContactsApps());

    if (previousState !== null) {
      dispatch(setActiveTab(previousState.activeTab));
      dispatch(setPhoneBookFiltersValue({ key: "selectedAppId", value: previousState.selectedAppId }));
      dispatch(setPreviousState(null));
    }
  }, []);

  const navigateToContactsGroup = ({ group = null, contactType }) => {
    const tab = contactType === contactTypes.keyPerson ? 1 : 0;

    // Save state for when navigating back
    dispatch(
      setPreviousState({
        activeTab: tab,
        selectedAppId: filters.selectedAppId,
      })
    );

    // Navigate to show all contacts
    if (group === null) {
      pageNavigatorV2({
        path: `${props.match.url}/groups/0/?mainContactPageId=${page.id}&contactType=${contactType}`,
        direction: "forward",
      });
      return;
    }

    // Navigate to the contacts of the specified group
    const groupType = config
      ? getGroupType(config[contactType === contactTypes.phoneBook ? GROUP_PHONEBOOK : GROUP_KEYPERSONS])
      : groupTypes.groups;

    pageNavigatorV2({
      path: `${props.match.url}/groups/${group.id}/?mainContactPageId=${page.id}&pageTitle=${group.title}&contactType=${contactType}&groupType=${groupType}`,
      direction: "forward",
    });
  };

  const getGroupBy = (contactType) => {
    // If a config has been provided telling us to group the provided contactType [phoneBook || keypersons] we'll do so, otherwise we return "all"
    let groupType = groupTypes.all;

    if (contactType === contactTypes.phoneBook && config && config[GROUP_PHONEBOOK]) {
      groupType = getGroupType(config[GROUP_PHONEBOOK]);
    } else if (contactType === contactTypes.keyPerson && config && config[GROUP_KEYPERSONS]) {
      groupType = getGroupType(config[GROUP_KEYPERSONS]);
    }

    return groupType;
  };

  const getContactGroupOrContactList = ({ contactType, useTabView }) => {
    // Depending on the whether contacts should be grouped or not, we return either a list of all contacts, or a list of all groups
    if (getGroupBy(contactType) !== groupTypes.all)
      return (
        <ContactsGroupList
          contactType={contactType}
          groupBy={getGroupBy(contactType)}
          navigateToContactsGroup={navigateToContactsGroup}
        />
      );
    else return <ContactsList mainPage={page} contactType={contactType} tabBarIsIncluded={useTabView} />;
  };

  // In the rare case that none of the tabs should be shown, return an empty fragment
  if (config && config[HIDE_PHONEBOOK_TAB] && config[HIDE_KEYPERSONS_TAB]) {
    return <></>;
  }

  // If only one of the tabs should be shown we just return a single component, not in a tabview
  if (config && (config[HIDE_PHONEBOOK_TAB] || config[HIDE_KEYPERSONS_TAB])) {
    const contactTypeToShow = config[HIDE_PHONEBOOK_TAB] ? contactTypes.keyPerson : contactTypes.phoneBook;
    return getContactGroupOrContactList({ contactType: contactTypeToShow, useTabView: false });
  }

  // If both of them should be shown, we return them in a tabview
  return (
    <TabView
      useScrollView={false}
      activeTabIndex={activeTab}
      tabs={[
        getContactGroupOrContactList({ contactType: contactTypes.phoneBook, useTabView: true }),
        getContactGroupOrContactList({ contactType: contactTypes.keyPerson, useTabView: true }),
      ]}
    />
  );
};
export default ContactsTabViewContainer;
