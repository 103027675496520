// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import StatusBox from "../ui/StatusBox";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";

// Utilities
import pageNavigatorV2 from "../../utilities/page-navigator-v2";

// Config
import breakpoints from "../../config/breakpoints";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

// Icons
import { CellphoneIcon } from "mdi-react";

const NativeApp = (props) => {
  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const { language: lang } = useSelector((state) => state.language);

  useEffect(() => {
    window.location = page.dataId;
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView>
        <div className={componentStyle()}>
          <StatusBox
            icon={<CellphoneIcon />}
            style={{ marginTop: "2rem" }}
            title={lang.youveOpenedANativeApp}
            content={lang.reasonsANativeAppDoesNotOpen}
            action={{
              callback: () => pageNavigatorV2({ mode: "pop" }),
              title: lang.goToFrontPage,
            }}
          />
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  max-width: ${breakpoints.xs}px;
  margin: auto;
`;

export default NativeApp;
