// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import dayjs from "dayjs";

// Components
import ScrollView from "../../ui/ScrollView";
import InlineSpinner from "../../ui/InlineSpinner";

// Hooks
import req from "../../../utilities/request-utility";
import ButtonRounded from "../../ui/ButtonRounded";
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";
import { AlertDecagramIcon, LoginVariantIcon, LogoutVariantIcon } from "mdi-react";
import styleTypes from "../../../config/styleTypes";
import { addToast } from "../../../actions/uiActions";

const baseToast = {
  icon: <AlertDecagramIcon />,
  styleType: styleTypes.error,
  duration: 12000,
  title: "Ups. Der er sket en fejl",
};

const ScheduleOverviewPunchClockTab = (props) => {
  const dispatch = useDispatch();
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const [punchClock, setPunchClock] = useState({
    data: {},
    loading: true,
  });

  async function getCheckInStatus() {
    let { data } = await req()("punch-clock");

    setPunchClock({
      ...punchClock,
      loading: false,
      data,
    });
  }

  // Get the groups
  useEffect(() => {
    getCheckInStatus();
  }, []);

  async function checkOut() {
    setPunchClock({ ...punchClock, loading: true });
    req()
      .post("punch-clock/stop")
      .then(({ data }) => {
        setPunchClock({
          ...punchClock,
          loading: false,
          data: {
            checkedIn: false,
            timestamp: "",
          },
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch(addToast({ ...baseToast, content: err.response.data.message }));
          setPunchClock({ ...punchClock, loading: false });
        }
      });
  }

  async function checkIn() {
    setPunchClock({ ...punchClock, loading: true });
    req()
      .post("punch-clock/start")
      .then(({ data }) => {
        setPunchClock({
          ...punchClock,
          loading: false,
          data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch(addToast({ ...baseToast, content: err.response.data.message }));
          setPunchClock({ ...punchClock, loading: false });
        }
      });
  }

  function getColor() {
    if (punchClock.loading && punchClock.data.checkedIn === undefined) return "yellow";
    if (punchClock.data.checkedIn) return "green";
    if (!punchClock.data.checkedIn) return "red";
  }

  return (
    <ScrollView>
      <div className={componentStyle(primaryColor) + ` ${getColor()}`}>
        <>
          <h2>
            {/* Loading state */}
            {punchClock.loading && punchClock.data.checkedIn === undefined && (
              <>
                <div className="status-dot yellow" /> Tjekker status...
              </>
            )}

            {/* Checkedd in */}
            {/* Checkedd out */}
            {punchClock.data.checkedIn !== undefined && (
              <>
                <div className={`status-dot ${punchClock.data.checkedIn ? "green" : "red"}`} />
                {punchClock.data.checkedIn ? "Du er checked ind" : "Du er checked ud"}
              </>
            )}
          </h2>

          {/* Loading */}
          {punchClock.loading && punchClock.data.checkedIn === undefined && (
            <InlineSpinner size={20} style={{ marginBottom: "1.15rem" }} />
          )}

          {/* Checked in */}
          {/* Checked out */}
          {punchClock.data.checkedIn !== undefined && (
            <p>
              {punchClock.data.checkedIn
                ? `Du checkede ind ${dayjs(punchClock.data.timestamp, "YYYYMMDDHHmmss").format("DD/MM HH:mm")}`
                : "Tryk for at tjekke ind"}
            </p>
          )}

          <ButtonRounded
            className="button"
            disabled={punchClock.loading}
            onClick={punchClock.data.checkedIn ? checkOut : checkIn}
            active={punchClock.loading && punchClock.data.checkedIn !== undefined}
          >
            {punchClock.loading && "..."}
            {!punchClock.loading && punchClock.data.checkedIn && (
              <>
                <LogoutVariantIcon className="button-icon" /> Check ud
              </>
            )}
            {!punchClock.loading && !punchClock.data.checkedIn && (
              <>
                <LoginVariantIcon className="button-icon" />
                Check ind
              </>
            )}
          </ButtonRounded>
        </>
      </div>
    </ScrollView>
  );
};

const componentStyle = (primaryColor) => css`
  margin: 1rem auto;
  padding: 0.65rem;
  border: 1px var(--midGrey) solid;
  border-radius: 3px;
  background-color: var(--white);
  max-width: ${breakpoints.md}px;
  border-left: 3px solid ${colors.midDarkGrey};

  &.red {
    border-left-color: var(--red);
    h2 {
      color: var(--red);
    }
  }
  &.yellow {
    border-left-color: var(--yellow);
  }
  &.green {
    border-left-color: var(--green);
    h2 {
      color: var(--green);
    }
  }

  div.status-dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: inline-block;
    margin-right: 0.35rem;
    vertical-align: middle;
    margin-top: -3px;

    &.yellow {
      background-color: var(--yellow);
    }
    &.green {
      background-color: var(--green);
    }
    &.red {
      background-color: var(--red);
    }
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    text-align: center;
  }

  p {
    margin-bottom: 1rem;
    color: var(--darkGrey);
    text-align: center;
  }

  .button {
    width: 100%;

    .button-icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.4rem;
      margin-top: -2px;
    }
  }
`;

export default ScheduleOverviewPunchClockTab;
