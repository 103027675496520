import React from "react";
import { css } from "emotion";
import { contentSettingsList } from "./PageBuilderSettings.config";
import { ChevronRightIcon } from "mdi-react";
import ListItem from "../ui/ListItem";
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import ScrollView from "../ui/ScrollView";
import breakpoints from "../../config/breakpoints";

const PageBuilderSettings = (props) => {
  const navigateToSubSettings = (subIdentifier) => {
    pageNavigatorV2({ path: `${props.match.url}/${subIdentifier}`, direction: "forward" });
  };

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={"Page Builder Indstillinger"} />
      <ScrollView className={`${componentStyle} ${props.className ? props.className : ""}`}>
        {contentSettingsList.map(({ title, icon, identifier }) => (
          <ListItem
            key={identifier}
            maxWidth={breakpoints.lg}
            onClick={() => navigateToSubSettings(identifier)}
            iconLeft={icon}
            clickable={true}
            title={title}
            iconRight={<ChevronRightIcon />}
          />
        ))}
      </ScrollView>
    </Page>
  );
};

const componentStyle = css`
  height: 100%;
  width: 100%;
  padding: 1rem 0;
`;

export default PageBuilderSettings;
