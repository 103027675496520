import getAppName from "../utilities/get-app-name";
/**
 * Utiltity function to animate transitions between routes
 * -------------------------------------
 * Take two arguments:
 * @param {Object} page - Page objecct to get link to
 * @param {String} mode - "relative" or "absolute"
 * @param {Object} options - additional options. Defaults to empty object (is not required)
 * @param {String} options.suffix - String of stuff to go after page's url.
 */
export default function (page, mode, options = {}) {
  /**
   * Function that returns a url for a given page object
   * -----------------------------------------------
   * page must be a page object.
   * mode must be a string of either "relative" or "absolute"
   *
   * Absolute:
   * at: url.com/app/some-page/
   * navigate to: /some-other-page/
   * result: url.com/some-other-page&
   *
   * Relative:
   * at: url.com/app/some-page/
   * navigate to: some-other-page/
   * result: url.com/app/some-page/some-other-page/
   * */

  let prefix = "";

  if (mode === "absolute") {
    prefix += `/${getAppName()}/`;
  }

  let url = `${prefix}${page.module}/${page.id}/${options.suffix ? options.suffix : ""}`;

  // For every other module (with a dataId)
  if (page.dataId) {
    return `${url}?dataId=${page.dataId}`;
    // For every other module (without a dataId)
  } else {
    return url;
  }
}
