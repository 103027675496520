// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloudOffOutlineIcon } from "mdi-react";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast } from "../../../actions/uiActions";

// Config
import styleTypes from "../../../config/styleTypes";

const UseSharedExtraAnswers = () => {
  const dispatch = useDispatch();

  const [sharedAnswers, setSharedAnswers] = useState([]);

  const { registration } = useSelector((state) => state.registration.form);
  const { selectedDate } = useSelector((state) => state.timeRegistration);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSharedAnswers();
  }, [registration]);

  function getExternalOptionId(registration) {
    let currentQuestion = registration.categories[1].questions[0];
    let dependentOnQuestionId = currentQuestion.config ? currentQuestion.config.SHARING_DEPENDENT_ON_QUESTION_ID : null;

    if (!dependentOnQuestionId) return null;

    let externalOptionId = null;
    registration.categories.forEach((category) =>
      category.questions.forEach((question) => {
        if (question.id === dependentOnQuestionId && question.answer && question.answer.value) {
          externalOptionId = question.answer.value.id;
        }
      })
    );

    return externalOptionId;
  }

  async function getSharedAnswers() {
    try {
      if (
        !registration ||
        (registration && Array.isArray(registration.categories) && registration.categories.length < 2) ||
        (registration &&
          Array.isArray(registration.categories) &&
          registration.categories.length >= 2 &&
          Array.isArray(registration.categories[1].questions)) === false
      )
        return;

      let externalOptionId = getExternalOptionId(registration);
      let questionId = registration.categories[1].questions[0].id;

      if (!externalOptionId || !questionId) {
        setLoading(false);
        setSharedAnswers([]);
        return;
      }

      const { data: answers } = await req().post(
        `time-registrations/${registration.id}/time-sheets/${selectedDate}/shared-answers?questionId=${questionId}`,
        {
          externalOptionId: externalOptionId,
        }
      );

      setLoading(false);
      setSharedAnswers(hideMySharedAnswersWhenEditing(answers));
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          styleType: styleTypes.error,
          title: "Kunne ikke hente delte tillæg",
          content: "Vi kan ikke hente data lige nu",
          duration: "20000",
          icon: <CloudOffOutlineIcon />,
        })
      );
    }
  }

  function hideMySharedAnswersWhenEditing(sharedAnswers) {
    const answers = registration.categories[1].questions[0].answer
      ? registration.categories[1].questions[0].answer.title
      : [];

    let filteredSharedAnswered = sharedAnswers.filter(
      (sharedAnswer) => answers.some((answer) => answer.keyId === sharedAnswer.value.keyId) === false
    );

    return filteredSharedAnswered;
  }

  return {
    sharedAnswers,
    loading,
  };
};

export default UseSharedExtraAnswers;
