import store from "../../../store";
import pageConfig from "../../../config/pageConfig";

export function getTitlesForTabBar({ config }) {
  const lang = store.getState().language.language;
  const { PHONEBOOK_LANG_OVERWRITE, KEYPERSONS_LANG_OVERWRITE, HIDE_PHONEBOOK_TAB, HIDE_KEYPERSONS_TAB } = pageConfig;

  let tabs = [];

  // If only one of the tabs should be shown, we dont provide any tabs, as we then just show the content of either phonebook or keypersons. Likewise we provide an empty array in the unlikely event that none of them should be shown.
  if (config && (config[HIDE_PHONEBOOK_TAB] || config[HIDE_KEYPERSONS_TAB])) return [];

  // Otherwise both of the tabs should be shown, and we just need to know if the name of them should be customised and that costomisation has been provided. If not we just push the default name for each.
  if (config && config[PHONEBOOK_LANG_OVERWRITE] && lang[config[PHONEBOOK_LANG_OVERWRITE]]) {
    tabs.push(lang[config[PHONEBOOK_LANG_OVERWRITE]]);
  } else {
    tabs.push(lang.phonebook);
  }

  if (config && config[KEYPERSONS_LANG_OVERWRITE] && lang[config[KEYPERSONS_LANG_OVERWRITE]]) {
    tabs.push(lang[config[KEYPERSONS_LANG_OVERWRITE]]);
  } else {
    tabs.push(lang.keyPersons);
  }

  return tabs;
}
