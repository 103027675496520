import { useDispatch } from "react-redux";
import React from "react";

// Config
import styleTypes from "../../../config/styleTypes";

// Icons
import { ExportIcon } from "mdi-react";

// Actions
import { showDialog } from "../../../actions/uiActions";
import { exportTimeSheets } from "../../../actions/timeRegistrationActions";

export default function UseHandleExport() {
  const dispatch = useDispatch();
  const handleExport = ({ value, timeRegistrationId, callback = () => {} }) => {
    dispatch(
      showDialog({
        styleType: styleTypes.neutral,
        title: "Eksporter registrering",
        icon: <ExportIcon />,
        content: "Er du sikker på at du vil eksporterer denne registrering? Dette kan ikke fortrydes",
        primaryActionTitle: "Ja, eksporter registrering",
        primaryAction: () => {
          dispatch(exportTimeSheets({ timeRegistrationId, timeSheetIds: [value], callback }));
        },
      })
    );
  };

  return handleExport;
}
