// libs
import React from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import { ChevronRightIcon, InfoOutlineIcon } from "mdi-react";
import dayjs from "dayjs";

// Components
import ListItem from "../../ui/ListItem";
import ReverseScrollView from "./reverseScrollView";
import { ListItemSkeletonGroup } from "../../ui/ListItemSkeleton";
import InlineSpinner from "../../ui/InlineSpinner";
import Notification from "../../ui/Notification";
import StatusBox from "../../ui/StatusBox";

// Redux
import { getChatRooms } from "../../../actions/liveChatActions";

// Util
import highlightMatch from "../../../utilities/highlight-match";
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import isUserOnline from "../utilities/isUserOnline";
import breakpoints from "../../../config/breakpoints";

function ChatRoomList(props) {
  const dispatch = useDispatch();
  const { chatRoomList, searchTerm, oneToOne = true, url } = props;

  // Redux
  const onlineUsers = useSelector((state) => state.liveChat.onlineUsers);
  const { language: lang } = useSelector((state) => state.language);
  const user = useSelector((state) => state.auth.user);

  function clickOnButtonHandler(chatRoom) {
    // navigate to chatRoomPage
    pageNavigatorV2({
      path: `${url}${chatRoom.id}`,
      direction: "forward",
    });
  }

  function handleDebounceScrollEnd() {
    // get chat room then scrolling to the end of the feed
    if (chatRoomList.loading === false) dispatch(getChatRooms({ searchTerm: searchTerm, scrollEnd: true }));
  }

  function messageFrom(sender) {
    if (sender.id === user.id) {
      return lang.you;
    } else {
      return sender.name.split(" ")[0];
    }
  }

  return (
    <ReverseScrollView className={componentStyle()} onScrollEnd={() => handleDebounceScrollEnd()} reverse={false}>
      {/** no chats */}
      {chatRoomList.loading === false && chatRoomList.error === false && chatRoomList.chatRooms.length === 0 && (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noConversations}
          content={lang.youHaveNoConversations}
        />
      )}

      {/** chat list loading */}
      {chatRoomList.loading === true && chatRoomList.error === false && chatRoomList.chatRooms.length === 0 && (
        <ListItemSkeletonGroup />
      )}

      {/** chat list failure */}
      {chatRoomList.loading === false && chatRoomList.error === true && (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.errorCouldNotGetConversations}
          content={lang.errorCouldNotGetConversationsInformation}
        />
      )}

      {/** chat list success */}
      {chatRoomList.error === false &&
        chatRoomList.chatRooms.map((chatRoom) => (
          <ListItem
            key={chatRoom.id}
            style={chatRoom.notificationCount && { borderLeft: "3px solid var(--greenBright)" }}
            className="chatRoomItem"
            title={highlightMatch(chatRoom.title, searchTerm)}
            titleStyle={{ marginBottom: "0", marginTop: "0.15rem" }}
            middleTitle={`${messageFrom(chatRoom.lastMessage.sender)}: ` + chatRoom.lastMessage.text}
            middleTitleStyle={{
              textOverflow: `ellipsis`,
              overflow: "hidden",
              display: "inline-block",
              whiteSpace: "nowrap",
              maxWidth: "100%",
              fontSize: "16px",
              marginBottom: "0",
            }}
            subTitle={lang.lastestMessage + ": " + dayjs(chatRoom.lastMessage.createdAt).locale(user.language).fromNow()}
            subTitleStyle={{ marginTop: "-0.15rem" }}
            imageLeft={
              <div className="imageContainer">
                {oneToOne === true &&
                  getProfilePicture(chatRoom.user, 60, { marginRight: "0.5rem", marginBottom: "-2px", padding: "0" })}
                {isUserOnline({
                  userObject: chatRoom.user,
                  onlineUsers: onlineUsers,
                }) === true && <span className="onlineDot" />}
              </div>
            }
            notificationsRight={chatRoom.notificationCount && <Notification notifications={chatRoom.notificationCount} />}
            iconRight={<ChevronRightIcon />}
            clickable={true}
            onClick={() => clickOnButtonHandler(chatRoom)}
          />
        ))}
      {/** loading when scrolling */}
      {chatRoomList.loading === true && chatRoomList.error === false && chatRoomList.chatRooms.length > 0 && (
        <InlineSpinner />
      )}
    </ReverseScrollView>
  );
}
const componentStyle = () => css`
  padding-top: 5rem;
  margin-bottom: 1rem;
  flex-direction: column;
  padding-bottom: 2rem;

  .chatRoomItem {
    max-width: ${breakpoints.lg}px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
  }

  .imageContainer {
    position: relative;
  }

  .onlineDot {
    position: absolute;
    top: 82%;
    left: 60%;
    z-index: 10;
    height: 12px;
    width: 12px;
    background-color: var(--greenBright);
    border-radius: 50%;
    display: inline-block;
  }
`;

export default ChatRoomList;
