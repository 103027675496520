// Libs
import React from "react";

import getIconFromApprovalStatus from "./getIconFromApprovalStatus";

export default (registration) => {
  /* If there is no hours/numbers of work registered, we do not show an icon. 
    If there there is work registered, we show a green tick, if it has been aproved, a red warning triangle, if it has been rejected and an hourglass, if it is still waiting to be either approved or rejected*/
  if (registration.count === 0) {
    return <div className="empty-icon"></div>;
  } else {
    return getIconFromApprovalStatus(registration.approvalStatus);
  }
};
