import queryString from "query-string";
import getAppName from "./get-app-name";

const CACHE_NAME = `${getAppName()}-login-transfer`;
const ENDPOINT_NAME = `/${getAppName()}-login-transfer`;
const TOKEN_KEY = "token";

/** Takes token from query-param and stores it in a cache named `{appName}-login-transfer` under a route named `/{appName}-login-transfer` as `{ token: <token> }` */
export async function storeToken() {
  // Parse query-strings
  let queryStrings = queryString.parse(window.location.search);

  // In case the token isn't there don't save it as it would overwrite the saved one when opening the page without it
  if (!queryStrings["transfer-token"]) return;

  // This might fail for browsers that doesn't support the cache api or if the app somehow is being used without https
  try {
    // Try to open cache and store login-transfer token in it
    const cache = await caches.open(CACHE_NAME);
    // Create a non-existing api-call with the token data
    const response = new Response(JSON.stringify({ [TOKEN_KEY]: queryStrings["transfer-token"] }));
    // Store the non-existing api-call at a url for later retrieval
    await cache.put(ENDPOINT_NAME, response);
  } catch (err) {
    console.log("ERROR!! while trying to save login-tranfer token", err);
  }
}

/** Retrieves the token from the fictional api-endpoint
 * @returns {string} access-token from query-strings
 */
export function getToken() {
  return new Promise(async (resolve, reject) => {
    try {
      // open cache and try to match request
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.match(ENDPOINT_NAME);

      // Reject promise if no response is found
      if (!response) return reject("TOKEN_NOT_FOUND");

      // Get response body and resolve with the token
      const responseBody = await response.json();
      resolve(responseBody[TOKEN_KEY]);
    } catch (err) {
      reject(err);
    }
  });
}
