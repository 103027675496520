import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import { useSelector } from "react-redux";

function NumericInput(props) {
  const color = useSelector((state) => state.appConfig.primaryColor);

  // HTML5 Number input is extremely inconsistent across browsers, so we need to handle keydown events to prevent non-numeric characters
  // This has to be done with onKeyDown, as Safari doesn't correctly add the value of the input in the onChange event, if it contains non-numeric characters
  const onKeyDown = (e) => {
    // Reject any key that isn't a number or a control key or backspace
    if (!/[\d\s]/.test(e.key) && !e.ctrlKey && e.key !== "Backspace" && e.key !== "Tab") {
      e.preventDefault();
    }
  };

  return (
    <input
      type="number"
      className={`${style({ ...props, primaryColor: color })} ${props?.className || ""} ${
        props?.disabled ? "disabled" : ""
      }`}
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onKeyDown={onKeyDown}
      value={props.value}
      defaultValue={props.defaultValue || undefined}
      style={props.style}
      autoComplete="off"
      disabled={props.disabled}
      data-test-id={props["data-test-id"]}
      {...(props.register ? props.register : {})}
      min={props.min}
      max={props.max}
      id={props.id}
    />
  );
}

const style = (props) => css`
  background-color: ${props.backgroundColor || colors.white};
  border: 1px solid ${props.backgroundColor || colors.midGrey};
  padding: 0.5rem;
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 1rem;
  color: var(--black);
  resize: vertical;
  font-family: "Open Sans", sans-serif;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
    border: 1px solid ${props.primaryColor};
  }

  &.disabled {
    touch-action: none;
    pointer-events: none;
  }
`;

export default NumericInput;
