// Libs
import React from "react";

// Utils
import iconConvertor from "../utilities/icon-converter";
import colors from "../style/colors";

// UI
import Notification from "./ui/Notification";
import ActionWrapper from "./ui/ActionWrapper";

/**
 * @param { Object } page
 * @param { function } onClick
 * @returns { JSX.Element }
 * @constructor
 */
const SideMenuItem = ({ page, onClick = () => {} }) => {
  return (
    <ActionWrapper
      className="pageWrapper"
      onClick={() => {
        onClick();
      }}
    >
      {iconConvertor(page.icon, {
        flexShrink: 0,
        color: colors.darkGrey,
        marginRight: "0.25rem",
        width: "1.25rem",
        height: "1.25rem",
      })}
      <p className="page-title">{page.title}</p>
      {!page.notifications ? null : (
        <Notification
          notifications={
            page.notifications
              ? page.config?.NOTIFICATION_TEXT_OVERRIDE
                ? page.config.NOTIFICATION_TEXT_OVERRIDE
                : page.notifications
              : null
          }
          style={{ marginRight: "0.75rem" }}
        />
      )}
    </ActionWrapper>
  );
};

export default SideMenuItem;
