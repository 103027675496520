import { css } from "emotion";
import React from "react";
import tinycolor from "tinycolor2";
import colors from "../../../style/colors";

const ScheduleBadge = ({ badgeName = "" }) => {
  if (!badgeName) return null;

  if (badgeName === "FOR_SALE") {
    return (
      <div className={componentStyles() + " for-sale"}>
        <p>Til salg</p>
      </div>
    );
  }

  if (badgeName === "OPEN") {
    return (
      <div className={componentStyles() + " open"}>
        <p>Åben</p>
      </div>
    );
  }

  return null;
};

const componentStyles = () => css`
  padding: 0.2rem 0.6rem;
  border-radius: 4px;

  p {
    font-size: 0.8rem;
    font-weight: 700;
  }

  &.open {
    background-color: ${tinycolor(colors.blue).setAlpha(0.1).toString()};
    p {
      color: var(--blue);
    }
  }
  &.for-sale {
    background-color: ${tinycolor(colors.green).setAlpha(0.1).toString()};
    p {
      color: var(--green);
    }
  }
`;

export default ScheduleBadge;
