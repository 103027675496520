// Libraries
import React from "react";

// Components
import DropDown from "../../../../ui/dropDown/components/DropDown";

import { SettingsIcon } from "mdi-react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import { showModalPage } from "../../../../../actions/uiActions";
import RegistrationSettings from "../../../../pageBuilderSettings/settings/registrations/RegistrationSettings";
import useRegistrationOptions from "../../../../../hooks/useRegistrationOptions";

const RegistrationOptions = ({ onChange, page }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.language);
  const { data: registrations } = useRegistrationOptions({ groupBy: "active", sort: "alphabetical" });

  const onChangeHandler = (e) => {
    onChange({ target: { name: "meta", value: { ...page.meta, [e.target.name]: e.target.value } } });
  };

  const navigateToRegistrationSettings = () => {
    let activeRegistrations = [];

    if (registrations.length > 0) activeRegistrations = [...activeRegistrations, ...registrations[0].options];
    if (registrations.length > 1) activeRegistrations = [...activeRegistrations, ...registrations[1].options];

    let registration = activeRegistrations.find(
      (registration) => registration.id === parseInt(page.meta.registrationId, 10)
    );

    if (registration) {
      dispatch(
        showModalPage({
          title: registration.title,
          content: <RegistrationSettings registrationId={page.meta.registrationId} showAsModal={true} />,
          useScrollView: true,
        })
      );
    }
  };

  return (
    <div className={componentStyle()}>
      <div className="line" />
      <div className="field-container">
        <p className="title">{lang?.specialConfigurations}</p>
        <div className="field-wrapper">
          <label>{lang?.registrationContent}</label>
          <div className="dropdown-wrapper">
            <DropDown
              data-test-id={"page-builder-registration-options"}
              className="dropdown"
              name={"registrationId"}
              sections={registrations}
              groupBy={"sections"}
              usePlaceholder
              value={page?.meta?.registrationId}
              onChange={onChangeHandler}
            />
            <button onClick={navigateToRegistrationSettings} disabled={!page?.meta?.registrationId} className="svg-wrapper">
              <SettingsIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  .line {
    border-top: 1px solid var(--midGrey);
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    
    
    p.title {
      margin-right: 0.75rem;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }
    
    .field-wrapper {
      width: 100%;
      margin-top: 0.5rem;
      
      label {
        font-size: 0.9rem;
        color: var(--darkGrey);
      }
      
      .dropdown-wrapper {
        display: flex;
        
        
        .dropdown {
          width: 100%;
        }
        
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3rem;
          border: 1px solid var(--midGrey);
          border-left: none;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          cursor: pointer;
          background-color: var(--white);
          
          svg {
            height: 1.25rem;
            width: 1.25rem;
          }

          &:hover {
            background-color: var(--ultraLightGrey);
            cursor: pointer;
            transition: background-color 80ms ease;
          }
          &:active {
            background-color: var(--lightGrey);
            transition: background-color 140ms ease;
          }
        }
      }
    }
`;

export default RegistrationOptions;
