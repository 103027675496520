// Lib
import dayjs from "dayjs";

// Config
import statusType from "../config/statusType";

export default function addColorToTableNameCell(dates) {
  // Default - the user has met all days in the current period
  let colorStatus = statusType.met;

  let { totalNumberOfNotWeekendDays, totalNumberOfMetDays } = dates.reduce(
    (previous, { date, count }) => {
      let day = dayjs(date.toString(), "YYYYMMDD").day();

      // Increment if day is not saturday and sunday (only week days)
      if (day !== 0 && day !== 6) {
        previous.totalNumberOfNotWeekendDays++;
        previous.totalNumberOfMetDays = count > 0 ? previous.totalNumberOfMetDays + 1 : previous.totalNumberOfMetDays;
      }

      return previous;
    },
    // Initial values
    { totalNumberOfNotWeekendDays: 0, totalNumberOfMetDays: 0 }
  );

  // Hasn't met any days in the current period
  if (totalNumberOfMetDays === 0) colorStatus = statusType.absent;

  // Has met at least once in the current period
  if (totalNumberOfMetDays > 0 && totalNumberOfMetDays < totalNumberOfNotWeekendDays) colorStatus = statusType.sick;

  return colorStatus;
}
