// Libs
import React from "react";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

// Hooks
import TableOverview from "./components/TableOverview";

const TimeRegistrationDepartment = () => {
  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={"Afdelingsoversigt"} />
      <TableOverview />
    </Page>
  );
};

export default TimeRegistrationDepartment;
