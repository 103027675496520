import getAppName from "./get-app-name";

export default (state) => {
  try {
    const stateToSave = {
      appConfig: { ...state.appConfig },
      auth: { ...state.auth },
      language: { ...state.language },
      pages: { ...state.pages },
      themeQuiz: { ...state.themeQuiz },
    };

    // Clear navigationStack
    // The navigation stack should only be valid for as long as the app is opened so clear it so it isn't loaded from ls
    stateToSave.pages.navigationStack = [];

    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem(`${getAppName()}-state`, serializedState);
  } catch (err) {
    // Could not write state to localstorage
  }
};
