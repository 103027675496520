// Libs
import React from "react";
import { useSelector } from "react-redux";

/**
 * @name useLang
 * @returns {object} language-object
 */
const useLang = () => {
  const lang = useSelector((s) => s.language.language);
  return lang;
};

export default useLang;
