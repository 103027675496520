import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_FAILURE_HANDLED,
  AD_SIGN_IN_FAILURE,
  SIGN_OUT,
  CHECK_TOKEN,
  CHECK_TOKEN_VALID,
  CHECK_TOKEN_INVALID,
  CHECK_TOKEN_SERVICE_UNAVAILABLE,
  RENEW_TOKEN_SUCCESS,
  RENEW_TOKEN_FAILURE,
  UPDATE_USER_DATA,
  RENEW_PASSWORD,
  RENEW_PASSWORD_FAILED,
  RENEW_PASSWORD_SUCCESS,
  RENEW_PASSWORD_HANDLED,
  AD_SIGN_IN_FAILURE_HANDLED,
} from "../actions/actionTypes";

const initialState = {
  renewingPassword: false,
  renewPasswordSuccess: false,
  renewPasswordError: false,
  signingIn: false,
  signInError: false,
  adLoginFailed: false,
  isAuthenticated: false,
  checkingToken: false,
  user: {},
  token: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case SIGN_IN:
      return {
        ...state,
        signingIn: true,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signingIn: false,
        signInError: false,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };

    case SIGN_IN_FAILURE:
      return {
        ...state,
        signingIn: false,
        signInError: true,
        isAuthenticated: false,
        token: null,
        user: {},
      };

    case SIGN_IN_FAILURE_HANDLED:
      return {
        ...state,
        signInError: false,
      };

    case AD_SIGN_IN_FAILURE:
      return {
        ...state,
        adLoginFailed: true,
      };

    case AD_SIGN_IN_FAILURE_HANDLED:
      return {
        ...state,
        adLoginFailed: false,
      };

    case SIGN_OUT:
      return {
        ...state,
        signingIn: false,
        signInError: false,
        isAuthenticated: false,
        token: null,
        user: {},
      };

    case CHECK_TOKEN:
      return {
        ...state,
        checkingToken: true,
      };

    case CHECK_TOKEN_VALID:
      return {
        ...state,
        isAuthenticated: true,
        checkingToken: false,
      };

    case CHECK_TOKEN_INVALID:
      return {
        ...state,
        signingIn: false, // Prevent eternal spinner-bug
        isAuthenticated: false,
        token: null,
        checkingToken: false,
      };

    case CHECK_TOKEN_SERVICE_UNAVAILABLE:
      return {
        ...state,
        signingIn: false, // Prevent eternal spinner-bug
        checkingToken: false,
      };

    case RENEW_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token, // Token
        user: action.payload.user, // Token
      };

    case RENEW_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: {},
      };

    case RENEW_PASSWORD:
      return {
        ...state,
        renewingPassword: true,
      };

    case RENEW_PASSWORD_SUCCESS:
      return {
        ...state,
        renewPasswordSuccess: true,
        renewingPassword: false,
      };

    case RENEW_PASSWORD_FAILED:
      return {
        ...state,
        renewPasswordError: true,
        renewingPassword: false,
      };

    case RENEW_PASSWORD_HANDLED:
      return {
        ...state,
        renewPasswordError: false,
        renewPasswordSuccess: false,
      };

    default:
      return state;
  }
}
