// Libs
import React, { useEffect, useState, Fragment } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import AnimateHeight from "react-animate-height";

// Components
import { ChevronDownIcon } from "mdi-react";
import FormBuilder from "../../ui/FormBuilder";
import ButtonRounded from "../../ui/ButtonRounded";
import RegistrationSharedAnswer from "./RegistrationSharedAnswer";

// Styles & config
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";
import { durations } from "../../../config/animations";

// Hooks
import useSharedExtraAnswers from "../hooks/useSharedExtraAnswers";
import Spinner from "../../ui/InlineSpinner";

const RegistrationFormExtended = ({ categories, updateRegistrationFormFields, notValidQuestionsIds } = props) => {
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const { language: lang } = useSelector((state) => state.language);
  const [extended, setExtended] = useState(false);

  const { sharedAnswers, loading: loadingExtraSharedAnswers } = useSharedExtraAnswers();

  function doesContainAnAnsweredQuestion(categories) {
    if (categories.length === 0) return false;
    return categories[1].questions.some((question) => question.answer && Boolean(question.answer.title) === true);
  }

  useEffect(() => {
    if (categories && doesContainAnAnsweredQuestion(categories)) setExtended(true);
  }, [categories]);

  function showSharedExtraAnswers() {
    if (Array.isArray(sharedAnswers) && sharedAnswers.length === 0) return;
    if (loadingExtraSharedAnswers) return <Spinner style={{ marginTop: "1rem" }} size={25} title={"Henter delte tillæg"} />;

    return sharedAnswers.map((sharedAnswer) => (
      <RegistrationSharedAnswer key={sharedAnswer.id} answer={sharedAnswer} style={{ marginTop: "1rem" }} />
    ));
  }

  return (
    <div className={componentStyle(primaryColor)}>
      <AnimateHeight
        height={extended ? "auto" : 0}
        duration={durations.normal}
        animateOpacity={true}
        style={{ width: "100%" }}
      >
        <div className="line" />
        <div className="extended-questions-container">
          <div className="sub-head">
            <p>{`${lang.other} ${lang.bonus.toLowerCase()}`}</p>
          </div>
          <div style={{ width: "100%" }}>
            {Array.isArray(categories) &&
              categories.length > 0 &&
              categories[1].questions.map((question, questionIndex) => (
                <div key={`fragment-${question.id}`} style={{ marginBottom: "1.5rem" }}>
                  <FormBuilder
                    key={question.id}
                    question={question}
                    questionIndex={questionIndex}
                    updateFields={updateRegistrationFormFields}
                    categoryIndex={1}
                    notValidArr={notValidQuestionsIds}
                  />

                  {question.config && question.config.ALLOW_SHARING_ANSWER && showSharedExtraAnswers()}
                </div>
              ))}
          </div>
        </div>
      </AnimateHeight>
      <div className="line" style={{ marginBottom: "1rem" }} />

      <ButtonRounded secondary={true} onClick={() => setExtended((extended) => !extended)}>
        <ChevronDownIcon style={{ transform: `rotate(${extended ? "180deg" : "0deg"})` }} />
        <p>{extended ? lang.lessOptions : lang.moreOptions}</p>
      </ButtonRounded>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div.line {
    border-bottom: 1px solid var(--lightGrey);
    width: 100%;
  }

  div.extended-questions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    width: 100%;
    margin: 0 auto;
    max-width: ${breakpoints.xs}px;

    div.sub-head {
      width: 100%;
      display: flex;
      justify-content: start;
      margin-bottom: 1rem;

      p {
        font-weight: bold;
        color: ${primaryColor};
      }
    }
  }
`;

export default RegistrationFormExtended;
