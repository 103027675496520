// libs
import React from "react";
import { css } from "emotion";

function UserOnlineList(props) {
  const { userList } = props;
  return (
    <div className={componentStyle()}>
      online:
      {/* users */}
      {userList.map((user, index) => (
        <div key={index} className="user">
          {user.user.name}
        </div>
      ))}
    </div>
  );
}
const componentStyle = () => css`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .user {
    width: auto;
  }
`;

export default UserOnlineList;
