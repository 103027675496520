// Should match only the links that are not inside a markdown link (note that the links must start with a http or https protocol)
const RAW_LINKS_REGEX = /(?<url>https?:\/\/[^\s]+[\w])/gim;

// Should match only the links that are inside a markdown link (protocol agnostic)
const MARKDOWN_LINKS_REGEX = /\[(?<header>.*)\] ?\((?<url>.*)\)/gim;

export default function (markdown) {
  // Find the links in the markdown using the regex
  let links = [...markdown.matchAll(RAW_LINKS_REGEX)] || [];
  let markdownLinks = [...markdown.matchAll(MARKDOWN_LINKS_REGEX)] || [];

  // We can't use negative lookbehind since iOS Safari < 16.3 doesn't support it
  // So the only way is to match for both raw and markdown links and then remove the markdown links from the raw links

  // Remove the markdown links from the raw links
  links = links.filter((raw) =>
    markdownLinks.some((md) => raw.index < md.index && raw.index + raw.length > md.index + md.length)
  );

  // Cleanup link and remove potential `&nbsp;` at the end of the link
  links = links.map((link) => link.groups?.url?.replace(/&nbsp/gi, ""));

  // Organize into objects
  links = links.map((link) => ({ url: link }));

  links.forEach(({ url }) => {
    markdown = markdown.replace(url, `[${url}](${url})`);
  });

  return markdown;
}
