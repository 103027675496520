import pageNavigator_v2 from "../../utilities/page-navigator-v2";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import iconConverter from "../../utilities/icon-converter";
import Notification from "../ui/Notification";
import React from "react";

const FrontPageMenuItem = ({ page, className }) => {
  const config = page.config;

  return (
    <div
      data-test-id={`frontpage-icon__${page.module}_${page.title}`}
      onClick={() =>
        pageNavigator_v2({
          path: linkPathCreator(page, "absolute"),
          direction: "forward",
          pageId: page.id,
          page: page,
        })
      }
      className={className}
    >
      {page.title !== null && iconConverter(page.icon)}
      {page.title}
      <Notification
        style={{ position: "absolute", top: "calc(50% - 50px)", left: "calc(50% + 9px)" }}
        notifications={
          page.notifications
            ? config?.NOTIFICATION_TEXT_OVERRIDE
              ? config.NOTIFICATION_TEXT_OVERRIDE
              : page.notifications
            : null
        }
      />
    </div>
  );
};

export default FrontPageMenuItem;
