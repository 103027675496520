import React from "react";
import DropDown from "../../../../../ui/dropDown/components/DropDown";
import useSWR from "swr";
import { swrFetcher } from "../../../../../../utilities/swr-fetcher";

const NavigationSubMenuSortingSelector = (props) => {
  const { data: options, isValidating: loading } = useSWR(
    "admin/pages/configurations/navigation/sub-pages-sort-options",
    swrFetcher
  );

  return (
    <DropDown
      usePlaceholder={props.usePlaceholder}
      placeholder={props.placeholder}
      name={props.name}
      className={props.className}
      onChange={props.onChange}
      options={options}
      value={props?.value ?? undefined}
      loading={loading}
    />
  );
};

export default NavigationSubMenuSortingSelector;
