// libs
import React, { forwardRef } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const MessageItem = forwardRef((props, ref) => {
  const { messages, senderId, createdAt } = props;
  const user = useSelector((state) => state.auth.user);

  // return true is messages is from the user
  function findSenderMessages(senderId, userId) {
    if (senderId === userId) return true;
    return false;
  }

  return (
    <div
      className={`${componentStyle(findSenderMessages(senderId, user.id))} ${
        findSenderMessages(senderId, user.id) ? "senderContainer" : "receiverContainer"
      }`}
      ref={ref}
    >
      {/* message group */}
      {messages.map((message, index) => (
        <p key={index} className={`text ${findSenderMessages(senderId, user.id) ? "senderMessage" : "receiverMessage"}`}>
          {message.text}
        </p>
      ))}
      {/* date */}
      <p className="date">{dayjs(createdAt).format("DD/MM-YYYY HH:mm")}</p>
    </div>
  );
});
const componentStyle = (sender) => css`
  @media screen and (min-width: 1000px) {
    max-width: 400px;
  }

  height: auto;
  max-width: 65%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    flex-grow: 1;
  }

  &.receiverContainer {
    width: auto;
    margin-left: 0.8rem;
    align-self: flex-start;
  }

  &.senderContainer {
    width: auto;
    margin-right: 0.8rem;
    align-self: flex-end;
  }

  .date {
    width: auto;
    font-size: 12px;
    color: var(--darkGrey);
    align-self: ${sender ? "flex-end" : "flex-start"};
  }

  .text {
    margin-bottom: 0.5rem;
    border-radius: 10px;
    padding: 0.6rem;
    font-size: 14px;
    width: fit-content;
    max-width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-break: break-word;

    &.receiverMessage {
      background-color: var(--white);
      border: 1px solid var(--midGrey);
    }

    &.senderMessage {
      background-color: var(--primary-color);
      color: var(--white);
      align-self: flex-end;
    }
  }
`;

export default MessageItem;
