import { format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDate } from "../../../actions/timeRegistrationActions";
import { showModalPage } from "../../../actions/uiActions";
import UseConvertDateFormat from "../../../hooks/useConvertDateFormat";
import useLang from "../../../hooks/useLang";
import colors from "../../../style/colors";
import getUserLocale from "../../../utilities/get-user-locale";
import TimeRegistration from "../modal/TimeRegistration";
import capitalizeFirstLetter from "../utilities/capitalizeFirstLetter";

const useHandleOverviewRegistrationClick = () => {
  const lang = useLang();
  const dispatch = useDispatch();
  const convertDateFormat = UseConvertDateFormat();
  const user = useSelector((s) => s.auth.user);

  /**
   * @param {Object} anon
   * @param {Object} anon.registration
   * @param {Number} anon.activeTab
   * @param {String} anon.summaryMode
   */
  return function ({ registration, activeTab = 0, summaryMode }) {
    dispatch(setSelectedDate(convertDateFormat(registration.date)));
    dispatch(
      showModalPage({
        title: `${lang.timeRegistration} - ${capitalizeFirstLetter(
          format(parse(registration.date, "yyyyMMdd", 0), "EEEE dd/MM-yyyy", getUserLocale(user))
        )}`,
        content: <TimeRegistration activeTab={activeTab} date={registration.date} summaryMode={summaryMode} />,
        useScrollView: false,
        pageStyle: { backgroundColor: colors.white },
      })
    );
  };
};

export default useHandleOverviewRegistrationClick;
