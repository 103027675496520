// Libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage } from "../../actions/uiActions";
import { refreshPostComments } from "../../actions/newsActions";
import PropTypes from "prop-types";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";

// Components
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import Spinner from "../ui/InlineSpinner";

// Utils
import req from "../../utilities/request-utility";
import PostCommentForm from "../ui/PostCommentForm";

/** Modal page for editing comments.
 *
 * Adding comments is done inline in the Post component.
 * Is implemented as a component passed to a redux action
 *
 * @example
 * ```jsx
 * showModalPage({
 *   title: `${lang.edit} ${lang.comment.toLowerCase()}`,
 *   content: <NewsCommentModal comment={comment} postId={id} />
 * });
 * ```
 */

function NewsCommentModal(props) {
  const [savingChanges, setSavingChanges] = useState(false);
  const { refreshPostComments, postId, subTypeId, feedType, comment } = props;

  async function saveComment(comment) {
    setSavingChanges(true);
    props.updateModalPage({ actionRight: <InlineSpinner style={{ marginRight: "1rem" }} size="18" color={colors.white} /> });
    await req()
      .patch(`news/${subTypeId}/${postId}/comments/${comment.id}`, { comment: comment.content, image: comment.image })
      .then(() => {
        refreshPostComments({ postId, subTypeId, feedType });
        setTimeout(props.hideModalPage, 300);
      })
      .catch(() => props.addToast({ template: "error" }));
  }

  return (
    <ScrollView className={componentStyle()}>
      {savingChanges && <Spinner />}
      {!savingChanges && (
        <PostCommentForm
          minHeight={"35vh"}
          comment={comment}
          boxPadding={boxPadding}
          postId={comment.id}
          onComment={(comment) => saveComment(comment)}
          submittingComment={true}
          showForm={true}
        />
      )}
    </ScrollView>
  );
}

const boxPadding = "0.65";

const componentStyle = () => css`
  padding: 1rem;
  background-color: var(--white);
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsCommentModal);

NewsCommentModal.propTypes = {
  /** The id of the post to which the comment is being edited */
  postId: PropTypes.number,
  /** The comment being edited (it has more attributes, but only id and comment is needed as the author is implicitly defined through auth) */
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
  }),
};
