// Libs
import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import Post from "../../ui/Post";
import RegistrationPostContent from "./RegistrationPostContent";

// Utilities and config
import getUserLocale from "../../../utilities/get-user-locale";
import { feedTypes, postLayoutModes } from "../config";
import { parseDatetimeExtended } from "../../../utilities/parse-date";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import capitalizeFirstLetter from "../../timeRegistration/utilities/capitalizeFirstLetter";
import isPostLiked from "../utilities/isPostLiked";
import translationTypes from "../../../config/translationTypes";
import readAloudTypes from "../../../config/readAloudTypes";
import showReadAloud from "../../../utilities/showReadAloud";
import highlightMatch from "../../../utilities/highlight-match";

// Hooks
import useAuthorModal from "../../../hooks/useAuthorModal";
import useTranslation from "../../../hooks/useTranslation";
import useReadAloud from "../../../hooks/useReadAloud";
import useRegistrationContextMenu from "./useRegistrationContextMenu";
import usePostRenderEffect from "../../../hooks/usePostRenderEffect";

// Actions
import { showContextMenu } from "../../../actions/uiActions";
import { likePost, readRegistrationPost, unlikePost } from "../../../actions/registrationActions";

const RegistrationPost = (props) => {
  const dispatch = useDispatch();

  // Props
  const { post, feedType, searchTerm, layoutMode, disableLikesAndComment, page } = props;

  // Redux states
  const { user } = useSelector((state) => state.auth);
  const { postsWithLoadingSwipes, postsWithLoadingLikes, registrationConfig } = useSelector((state) => state.registration);
  const appConfig = useSelector((state) => state.appConfig);

  // Local states
  const [contentState, setContentState] = useState(post.answers);
  const [titleState, setTitleState] = useState(post.title);

  // Get the registrationId / dataId
  let registrationId = queryString.parse(window.location.search).dataId;

  // Hooks
  const authorModal = useAuthorModal();
  const contextMenu = useRegistrationContextMenu({ ...props, page, registrationConfig });

  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedTitle,
    content: translatedContent,
  } = useTranslation({
    type: translationTypes.registrationPost,
    args: { postId: post.id, registrationId },
  });

  const { isPlayingAudio, isLoadingAudio, readAloudContent } = useReadAloud({
    type: readAloudTypes.registrationPost,
    args: { postId: post.id, registrationId },
  });

  // useEffects
  useEffect(() => {
    if (translatedTitle) setTitleState(translatedTitle);
    if (translatedContent) setContentState(translatedContent);
  }, [translatedTitle, translatedContent]);

  usePostRenderEffect(() => {
    setContentState(post.answers);
    setTitleState(post.title);
  }, [post]);

  // Context menu
  const onContextMenuClick = () => {
    dispatch(showContextMenu(contextMenu));
  };

  return (
    <Post
      hideLikes={registrationConfig?.hideLikesOnPosts}
      showContextMenuToggle={true}
      contextMenuToggleCallback={() => onContextMenuClick(post)}
      title={titleState ? capitalizeFirstLetter(titleState) : null}
      key={`registrationPost-${post.id}`}
      showNotifications={
        post.lastUpdatedDate &&
        page.lastVisitDate &&
        feedType === feedTypes.mine &&
        parseInt(post.lastUpdatedDate, 10) >= parseInt(page.lastVisitDate, 10)
      }
      disableSwipe={feedType === feedTypes.mine}
      enableTranslation={showReadAloud({
        enableReadAloud: appConfig.enableReadAloud,
        languageCode: currentTranslationLanguage,
      })}
      enableReadAloud={appConfig.enableReadAloud}
      translateContent={translateContent}
      readContent={() => readAloudContent({ currentTranslationLanguage })}
      isPlayingAudio={isPlayingAudio}
      isLoadingAudio={isLoadingAudio}
      isTranslating={isTranslating}
      isTranslated={isTranslated}
      useExpandCollapseLayout={layoutMode === postLayoutModes.accordion}
      expandCollapseToggle={
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* title and author */}
          {post.title && post.author ? (
            <>
              {getProfilePictureFromUserObject(post.author)}
              <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                <p>{highlightMatch(capitalizeFirstLetter(post.title), searchTerm)}</p>
                <p className="meta" style={{ color: "var(--darkGrey)" }}>
                  {post.author.name}
                </p>
                <p className="meta" style={{ color: "var(--darkGrey)" }}>
                  {parseDatetimeExtended(post.date)}
                </p>
              </div>
            </>
          ) : post.title && post.date ? ( // title and date
            <>
              <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                <p>{highlightMatch(capitalizeFirstLetter(post.title), searchTerm)}</p>
                <p className="meta" style={{ color: "var(--darkGrey)" }}>
                  {parseDatetimeExtended(post.date)}
                </p>
              </div>
            </>
          ) : post.title ? ( // title
            <>
              <p>{capitalizeFirstLetter(post.title)}</p>
            </>
          ) : post.author ? ( // author and date
            <>
              {getProfilePictureFromUserObject(post.author)}
              <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                <p>{post.author.name}</p>
                <p className="meta" style={{ color: "var(--darkGrey)" }}>
                  {parseDatetimeExtended(post.date)}
                </p>
              </div>
            </>
          ) : (
            // only date
            <p>{parseDatetimeExtended(post.date)}</p>
          )}
        </div>
      }
      date-test-id="registration-post"
      date={format(parse(post.date, "yyyyMMddHHmmss", 0), "do MMMM yyyy", getUserLocale(user))}
      componentContent={
        <RegistrationPostContent
          postId={post.id}
          registrationId={registrationId}
          feedType={feedType}
          mailGroup={post.mailGroup}
          answers={contentState}
          searchTerm={searchTerm}
          author={post.author}
        />
      }
      points={post.points}
      author={registrationConfig.hideAuthorInfo ? null : post.author}
      disableLikesAndComments={disableLikesAndComment || feedType === feedTypes.mine}
      likes={post.likes}
      onLike={
        isPostLiked({ post: post, user })
          ? () => dispatch(unlikePost({ registrationId, postId: post.id, feedType }))
          : () => dispatch(likePost({ registrationId, postId: post.id, feedType }))
      }
      liked={isPostLiked({ post: post, user })}
      likeTextIdentifiers={registrationConfig ? registrationConfig.likeTextIdentifiers : null}
      submittingLike={postsWithLoadingLikes.indexOf(post.id) !== -1}
      read={!!post.readDate}
      readDate={post.readDate}
      onAuthorClick={() => authorModal(post.author.id)}
      submittingSwipe={postsWithLoadingSwipes.indexOf(post.id) !== -1}
      onSwipeEnd={() => dispatch(readRegistrationPost({ registrationId, postId: post.id, feedType }))}
      titleStyle={{ marginBottom: "0.5rem", marginTop: "0.5rem", color: "var(--dark)" }}
    />
  );
};

export default RegistrationPost;
