// Libraries
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { CSSTransition } from "react-transition-group";

// Styles
import colors from "../../../style/colors";

// Components
import ActionWrapper from "../../ui/ActionWrapper";
import ScrollView from "../../ui/ScrollView";

// Utils
import { css } from "emotion";
import { CloseIcon, TrashCanOutlineIcon } from "mdi-react";
import DropDownList from "../dropDown/DropDownList";
import Button from "../../ui/Button";
import Overlay from "../../ui/Overlay";
import common from "../../../style/common";
import breakpoints from "../../../config/breakpoints";

/**
 * @param {*} props
 * @param {Boolean} props.active - Shows and hides the modal
 * @param {JSX} props.content 
 * @param {Function} props.hideFilters - function that hides the filters 
 
 */
function FilterModal({ active, hideFilters, children, onUseFilterClick = () => {}, onClearFiltersClick = () => {} }) {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const lang = useSelector((s) => s.language.language);
  const dispatch = useDispatch();

  function handleUseFiltersButton() {
    hideFilters();
    onUseFilterClick();
  }
  function handleClearFiltersButton() {
    hideFilters();
    onClearFiltersClick();
  }

  return (
    <>
      <CSSTransition in={active} timeout={400} mountOnEnter={true} unmountOnExit={true} classNames="social-filters">
        <div className={componentStyles(primaryColor)}>
          {/* Top bar */}
          <div className="top-bar">
            <p className="title">{lang.filters}</p>
            <ActionWrapper onClick={hideFilters}>
              <CloseIcon />
            </ActionWrapper>
          </div>

          <ScrollView className="filters-content" style={{ padding: "1rem" }}>
            <div className="content-wrapper">
              {/* Content */}
              {children}

              {/* Buttons */}
              <Button buttonType="tertiary" style={{ marginTop: "2rem" }} onClick={handleUseFiltersButton}>
                Use filters
              </Button>
              <Button buttonType="secondary" style={{ marginTop: "0.5rem" }} onClick={handleClearFiltersButton}>
                <TrashCanOutlineIcon style={{ height: "1.25rem", width: "1.25rem", marginBottom: "-0.25rem" }} />{" "}
                {lang.clear} {lang.all.toLowerCase()} {lang.filters.toLowerCase()}
              </Button>
            </div>
          </ScrollView>
        </div>
      </CSSTransition>
      <Overlay active={active} onClick={hideFilters} style={{ zIndex: 2 }} />
    </>
  );
}

const componentStyles = (primaryColor) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  z-index: 3;
  color: var(--black);
  display: flex;
  flex-direction: column;
  transform-origin: top;
  

  @media screen and (min-width: ${breakpoints.lg}px) {
    .content-wrapper {
      max-width: ${breakpoints.lg}px;
      margin: 0 auto;
    }
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    color: ${primaryColor};
    border-bottom: 1px var(--lightGrey) solid;
    height: calc(${common.topBarHeight}px + env(safe-area-inset-top));
    background-color: var(--white);
    z-index: 1;
    padding-top: env(safe-area-inset-top);

    p {
      line-height: ${common.topBarHeight}px;
      padding-left: 1rem;
    }

    svg {
      width: ${common.topBarHeight}px;
      height: ${common.topBarHeight}px;
      padding: 0.65rem;
    }
  }

  .filters-content {
    background-color: var(--white);
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  label {
    color: var(--darkGrey);
    margin-bottom: 0.75rem;
    display: block;
  }

  .checkbox-element {
    display: flex;
    color: var(--darkGrey);
    margin-bottom: 1.5rem;

    svg {
      margin-right: 0.75rem;
    }
  }

  /* Top-bar animation */
  &.social-filters-enter .top-bar {
    opacity: 0;
    transform: translateX(100%);
  }
  &.social-filters-enter-active .top-bar {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 200ms ease, transform 200ms ease;
  }
  &.social-filters-exit .top-bar {
    opacity: 1;
    transform: translateX(0px);
  }
  &.social-filters-exit-active .top-bar {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms ease, transform 300ms ease;
    transition-delay: 100ms;
  }

  /* filters-content animation */
  &.social-filters-enter .filters-content {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.social-filters-enter-active .filters-content {
    opacity: 1;
    transform: translateY(0);
    transition: transform 200ms ease, opacity 200ms ease;
    transition-delay: 100ms;
  }
  &.social-filters-exit .filters-content {
    opacity: 1;
    transform: translateY(0);
  }
  &.social-filters-exit-active .filters-content {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 200ms ease, opacity 200ms ease;
  }
`;

export default FilterModal;
