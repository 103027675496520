import { css } from "emotion";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import { Legend, LineChart, ResponsiveContainer, XAxis, YAxis, Line, CartesianGrid, Tooltip } from "recharts";
import { useEffect, useState } from "react";
import req from "../../utilities/request-utility";
import SkeletonLine from "../ui/skeletons/SkeletonLine";
import { useSelector } from "react-redux";

const IGChartModule = () => {
  const [data, setData] = useState(null);
  const [y_min, setYMin] = useState(0);
  const [y_max, setYMax] = useState(0);
  const [latest_investment, setLatestInvestment] = useState(null);
  const [latest_value, setLatestValue] = useState(null);
  const [latest_rate_of_return, setLatestRateOfReturn] = useState(null);

  const { language: lang } = useSelector((state) => state.language);

  useEffect(() => {
    req()
      .get("/installatoer-gruppen-stock")
      .then((res) => {
        // The format of the data is as follows:
        // [
        //   {
        //     id: 1,
        //     investment: 1000,
        //     value: 1200,
        //     quarter: "20231" <- YEAR + QUARTER
        //     rateOfReturn: 1.2
        //   }
        // ]

        // We need to transform this data into the format that the chart library expects:
        // [
        //   {
        //     name: 'Investments',
        //     color: "#838c98",
        //     data: [
        //       {
        //         quarter: "Q1 2023",
        //         value: 1000
        //       }
        //     ]
        //   },
        //   {
        //     name: 'Value',
        //     color: "#4cab44",
        //     data: [
        //       {
        //         quarter: "Q1 2023",
        //         value: 1200
        //       }
        //     ]
        //   }
        // ]

        // Simultaneously, keep track of the highest and lowest value, so we can set the y-axis domain accordingly

        let inv_min = Infinity;
        let inv_max = 0;

        let val_min = Infinity;
        let val_max = 0;

        // Find the min and max values, and transform the data into the format that the chart library expects
        const investments = res.data.map((item) => {
          if (item.investment < inv_min) inv_min = item.investment;
          if (item.investment > inv_max) inv_max = item.investment;

          const q = String(item.quarter);

          return {
            quarter: `Q${q.substring(4, 5)} ${q.substring(0, 4)}`, //
            value: item.investment,
          };
        });

        // Find the min and max values, and transform the data into the format that the chart library expects
        const values = res.data.map((item) => {
          if (item.value < val_min) val_min = item.value;
          if (item.value > val_max) val_max = item.value;

          const q = String(item.quarter);

          return {
            quarter: `Q${q.substring(4, 5)} ${q.substring(0, 4)}`, //
            value: item.value,
          };
        });

        while (investments.length < 4) {
          // Pad the data with empty quarters so the chart looks nice
          const quarter = investments[investments.length - 1].quarter;

          // We have to increment the year if we're going from Q4 to Q1
          let y = parseInt(quarter.substring(3, 7));
          let q = parseInt(quarter.substring(1, 2));

          if (quarter.substring(0, 2) === "Q4") {
            y++;
            q = 0;
          }

          investments.push({
            quarter: `Q${q + 1} ${y}`,
            value: null,
          });
        }

        // This entire section is to make some nice bounds for the y-axis domain.
        // The stock should prefferably be in the middle, so we have to find the min and max values of both investments and values,
        // and then find the min and max of those two values.
        //
        // We then add some padding to the min and max values, so the stock is not right at the edge of the chart.
        // The padding is 50% of the difference between the min and max values.
        let min = Math.min(inv_min, val_min);
        let max = Math.max(inv_max, val_max);

        const diff = max - min;

        min -= diff * 0.5;
        max += diff * 0.5;

        // For readability, we want the y-axis domain to round to the nearest tens, hundreds, thousands, etc.
        // E.g. instead of having a bound from 867 to 1234, we want a bound from 800 to 1300.
        const min_power = Math.floor(Math.log10(min));
        const max_power = Math.floor(Math.log10(max));

        const min_divisor = Math.pow(10, min_power);
        const max_divisor = Math.pow(10, max_power);

        const y_min = Math.floor(min / min_divisor) * min_divisor;
        const y_max = Math.ceil(max / max_divisor) * max_divisor;

        const data = [
          // Disabled, IG doesn't need this.
          // {
          //   name: lang.investments,
          //   color: "#838c98",
          //   data: [], 
          // },
          {
            name: lang.value,
            color: "#4cab44",
            data: values,
          },
        ];

        const latest_investment = res.data[res.data.length - 1].investment;
        const latest_value = res.data[res.data.length - 1].value;
        const latest_rate_of_return = res.data[res.data.length - 1].rateOfReturn;

        setData(data);
        setYMin(y_min);
        setYMax(y_max);
        setLatestInvestment(latest_investment);
        setLatestValue(latest_value);
        setLatestRateOfReturn(latest_rate_of_return);
      });
  }, []);

  return (
    <Page backgroundColor="white">
      <TopBar title={lang.myStocks} useDefaultBackButton={true} />
      <div className={componentStyle()}>
        <div className="latest-numbers">
          <div className="number-box">
            <div className="label">{lang.investments}</div>
            <div className="value">
              {latest_investment === null ? (
                <SkeletonLine
                  height={24}
                  width={100}
                  style={{ display: "block", marginTop: "0.25rem", height: "1em", width: "100%" }}
                />
              ) : (
                Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(latest_investment)
              )}
            </div>
          </div>
          <div className="number-box">
            <div className="label">{lang.value}</div>
            <div className="value">
              {latest_value === null ? (
                <SkeletonLine
                  height={24}
                  width={100}
                  style={{ display: "block", marginTop: "0.25rem", height: "1em", width: "100%" }}
                />
              ) : (
                Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(latest_value)
              )}
            </div>
          </div>
          <div className="number-box">
            <div className="label">{lang.returns}</div>
            <div className="value">
              {latest_rate_of_return === null ? (
                <SkeletonLine
                  height={24}
                  width={100}
                  style={{ display: "block", marginTop: "0.25rem", height: "1em", width: "100%" }}
                />
              ) : (
                latest_rate_of_return + " %"
              )}
            </div>
          </div>
        </div>
        <div className="chart">
          <div>
            {data === null ? (
              <SkeletonLine
                height={24}
                width={100}
                style={{ display: "block", marginTop: "0.25rem", height: "100%", width: "100%" }}
              />
            ) : (
              <ResponsiveContainer width={"99%"} height={"100%"}>
                <LineChart data={data}>
                  <XAxis dataKey="quarter" type="category" allowDuplicatedCategory={false} />
                  <YAxis
                    dataKey="value"
                    domain={[y_min, y_max]}
                    tickFormatter={(tick) => {
                      return Intl.NumberFormat("da-DK", { notation: "compact", compactDisplay: "short" }).format(tick);
                    }}
                  />
                  <CartesianGrid stroke="#a1a1a1" strokeDasharray="3 3" />
                  <Legend />
                  <Tooltip />
                  {data.map((item, index) => {
                    return (
                      <Line
                        dataKey="value"
                        type="monotone"
                        stroke={item.color}
                        data={item.data}
                        name={item.name}
                        key={item.name}
                        strokeWidth={3}
                      />
                    );
                  })}
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  max-width: 750px;
  margin: 0 auto;

  padding: 1rem;
  gap: 1rem;

  .latest-numbers {
    display: grid;

    gap: 1rem;

    padding: 1rem;

    @media (min-width: 560px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .number-box {
      border: var(--lightGrey) solid 1px;
      border-radius: 15px;
      padding: 0.5rem 0.725rem;

      .label {
        color: var(--midDarkGrey);
        font-size: 0.9rem;
        font-weight: 400;
      }

      .value {
        color: var(--darkGrey);
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }

  /* Since a flex child cannot be smaller than its content, we have to force the chart out of normal flow with position absolute.
     This forces the chart to actually fit into the container, and not overflow it.
  */
  .chart {
    flex-grow: 1;
    height: 100%;
    width: 100%;

    box-sizing: border-box;

    position: relative;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;

export default IGChartModule;
