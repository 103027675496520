export default function isTopBarVisible() {
  // If there is no top bar return false
  if (!document.querySelector(".top-bar")) {
    return false;
  } else {
    return true;
  }

  // get topbar object from  the position relative ti the viewport
  // var rect = document.querySelector(".top-bar").getBoundingClientRect();

  // if (
  //   rect.top >= 0 && // top distance from viewport
  //   rect.left >= 0 && // left distance from viewport
  //   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && // make sure the topbar is no filling more than the whole screen height
  //   rect.right <= (window.innerWidth || document.documentElement.clientWidth) // make sure the topbar is no filling more than the whole screen width
  // ) {
  //   return true;
  // } else {
  //   return false;
  // }
}
