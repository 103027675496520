// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import Spinner from "../ui/InlineSpinner";
import { InfoOutlineIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";

const LearningbankIframe = (props) => {
  // Local state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iframe, setIframe] = useState(null);

  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { pages } = useSelector((state) => state.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  // Get the iFrame URL
  useEffect(() => {
    getSSOURL();

    // eslint-disable-next-line
  }, []);

  const getSSOURL = async () => {
    try {
      const { data } = await req()(`learningbank/SSOURL`);
      setIframe(data.url);
      setLoading(false);
    } catch (err) {
      setError(err?.response?.status);
      setLoading(false);
    }
  };

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <div className={componentStyle()} style={props.style}>
        {loading && !error && (
          <div className="loading-container">
            <Spinner />
            <p>{lang.loadingPage}</p>
          </div>
        )}
        {!loading && error && (
          <StatusBox
            style={{ marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={error === 403 ? lang.errorAccessDenied : lang.somethingWentWrong}
            content={error === 403 ? lang.shouldHaveAccessContactSupport : lang.tryAgainOrContactSupport}
          />
        )}
        {!loading && !error && <iframe src={iframe} title="Learningbank" />}
      </div>
    </Page>
  );
};

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  div.loading-container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 0.125rem;
      color: var(--darkGrey);
    }
  }
`;

export default LearningbankIframe;
