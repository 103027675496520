import axios from "axios";
import store from "../store";

/**
 * Utility to make requests. Cane easily be used inside components that are rendered
 * after authentication. You can simply import it as:
 * import req from "./utilities/request-utility"
 *
 * This will export a preconfigured request utility that are authenticated and points
 * to our node api.
 *
 * Note that's this is just an axios-instance so use axios's documentation for usage.
 *
 * A simple example would be:
 * ------
 * import req from "./utilities/request-utility"
 * req().get("/users/2").then(res => console.log(res))
 * req()("/users/2").then(res => console.log(res))
 * req().post("/users/", {name: "New user's name"}).then(res => console.log(res))
 *
 */

// let state = store.getState();
// export default axios.create({
//   baseURL: state.appConfig.apiUrl,
//   headers: {
//     authorization: "bearer " + state.auth.token
//   }
// })

export default () => {
  let state = store.getState();
  return axios.create({
    baseURL: state.appConfig.apiUrl,
    headers: {
      authorization: "bearer " + state.auth.token,
    },
  });
};
