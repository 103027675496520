// libs
import React, { useRef, useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownIcon, InfoOutlineIcon } from "mdi-react";

// Components
import MessageItem from "./messageItem";
import ReverseScrollView from "./reverseScrollView";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";
import InformationBox from "../../ui/InformationBox";

// Redux
import { getMessages } from "../../../actions/liveChatActions";

function MessageContainer(props) {
  const dispatch = useDispatch();
  const { messageList, newMessage } = props;
  const timerDebounceRef = useRef();
  const firstElementInList = useRef();
  const [seenNewMessage, setSeenNewMessage] = useState(true);
  const [moveToNewElement, setMoveToNewElement] = useState(false);

  // Redux
  const currentChatRoom = useSelector((state) => state.liveChat.currentChatRoom);
  const { language: lang } = useSelector((state) => state.language);

  function handleDebounceScrollEnd() {
    // get messages
    if (messageList.loading === false) dispatch(getMessages(currentChatRoom.chatRoom.id));
  }

  function handleDebounceNewElement() {
    // if timer is set, reset timer
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }

    // Start the timer and save it in timerDebounceRef
    timerDebounceRef.current = setTimeout(() => {
      // set new message state after 50ms
      if (newMessage) {
        setSeenNewMessage(true);
        setMoveToNewElement(false);
        props.newMessageFalse();
      }
    }, 100);
  }

  return (
    <ReverseScrollView
      className={componentStyle()}
      onScrollEnd={() => handleDebounceScrollEnd()}
      newElementInScrollView={() => {
        if (newMessage) setSeenNewMessage(false);
      }}
      onScrollNewElement={() => handleDebounceNewElement()}
      newElement={newMessage ? firstElementInList : null}
      moveToNewElement={moveToNewElement}
      reverse={true}
    >
      {/* loading then no messages */}
      {messageList.loading === true && messageList.error === false && messageList.messages.length === 0 && (
        <InlineSpinner primaryColor={"var(--primary-color)"} />
      )}

      {/* newMessage ui */}
      {seenNewMessage === false && (
        <button className="infoContainer" onClick={() => setMoveToNewElement(true)}>
          <ArrowDownIcon />
          <p className="infoText">{lang.newMessages}</p>
        </button>
      )}

      {/* could not get messages */}
      {messageList.loading === false && messageList.error === true && (
        <StatusBox
          style={{ top: "25%", width: "100%", position: "absolute" }}
          icon={<InfoOutlineIcon />}
          title={lang.errorCouldNotGetMessages}
          content={lang.errorCouldNotGetMessagesInformation}
        />
      )}

      {/* messages */}
      {messageList.messages.length > 0 &&
        messageList.error === false &&
        messageList.messages.map((message, index) => (
          <MessageItem
            key={index}
            ref={(ref) => {
              if (index === 0) firstElementInList.current = ref;
            }}
            messages={message.messages}
            senderId={message.senderId}
            createdAt={message.createdAt}
          />
        ))}

      {/* information */}
      {messageList.messages.length === 0 && messageList.loading === false && messageList.error === false && (
        <InformationBox
          icon={<InfoOutlineIcon />}
          style={{ marginTop: "0.65rem", marginLeft: "0.8rem", marginRight: "0.8rem", marginBottom: "auto" }}
          description={lang.sendMessageToStartConversation}
        />
      )}

      {/* loading then there is already messages */}
      {messageList.loading === true && messageList.error === false && messageList.messages.length > 0 && (
        <InlineSpinner
          className="scrollLoading"
          size={20}
          style={{ marginTop: "8rem" }}
          title={lang.gettingMessages}
          primaryColor={"var(--primary-color)"}
        />
      )}
    </ReverseScrollView>
  );
}
const componentStyle = () => css`
  display: flex;
  max-height: 100%;
  height: 100%;
  width: 100%;
  padding-bottom: calc(5.5rem + env(safe-area-inset-top));
  scrollbar-gutter: auto;

  .scrollLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    align-self: center;
    justify-content: center;
    margin-bottom: -1rem;
  }

  .infoContainer {
    position: fixed;
    top: calc(100% - 7rem);
    right: 25%;
    background-color: var(--primary-color);
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    svg {
      margin-left: -1rem;
      margin-right: 0.5rem;
      height: 1.15rem;
      width: 1.15rem;
      color: var(--white);
      padding-top: 0.1rem;
    }
  }

  .infoText {
    color: var(--white);
    font-size: 16px;
  }
`;

export default MessageContainer;
