import dayjs from "dayjs";
export default ({ currentMessages, newMessages }) => {
  let groupMessages = currentMessages;
  /*
    A message array from the api looks like this
      [
        {
          id: 3,
          chatId: 1,
          text: "test 3",
          createdAt: "20230922191505",
          senderId: 1,
        },
        {
          id: 2,
          chatId: 1,
          text: "test 2",
          createdAt: "20230922190305",
          senderId: 1,
        },
        {
          id: 1,
          chatId: 1,
          text: "test",
          createdAt: "20230922190005",
          senderId: 1,
        },
      ];
    To group messages the structure have to change so it look like this
      [
        {
          chatId: 1,
          createdAt: "20230922191505",
          senderId: 1,
          messages: [
            {
              id: 3,
              text: "test 3",
            },
          ],
        },
        {
          chatId: 1,
          createdAt: "20230922190305",
          senderId: 1,
          messages: [
            {
              id: 1,
              text: "test",
            },
            {
              id: 2,
              text: "test 2",
            },
          ],
        },
      ];

    By doing this we ocan split the ui up into two sections, createdAt and senderId 
    for the container and messages for itself.

    The order is reversed so the newest is the first element, the reason for this is
    that in the messagesList you have to scroll up to see old messages.
*/
  for (let i = 0; i < newMessages.length; i++) {
    const message = newMessages[i];

    if (groupMessages.length === 0) {
      groupMessages.push({
        createdAt: message.createdAt,
        chatId: message.chatId,
        senderId: message.senderId,
        messages: [
          {
            id: message.id,
            text: message.text,
          },
        ],
      });
    } else if (
      // check for date on the incoming messages versus the old
      parseInt(groupMessages[groupMessages.length - 1].createdAt) <
        parseInt(dayjs(message.createdAt).add(5, "minutes").format("YYYYMMDDHHmmss")) &&
      groupMessages[groupMessages.length - 1].senderId === message.senderId
    ) {
      groupMessages[groupMessages.length - 1].messages.unshift({ id: message.id, text: message.text });
    } else {
      groupMessages.push({
        createdAt: message.createdAt,
        chatId: message.chatId,
        senderId: message.senderId,
        messages: [
          {
            id: message.id,
            text: message.text,
          },
        ],
      });
    }
  }

  return groupMessages;
};
