import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import InlineSpinner from "../ui/InlineSpinner";

// Icons
import { DeleteIcon, ArrowRightIcon, ArrowLeftIcon, RotateLeftIcon, RotateRightIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";
import Image from "./Image";

const fileTypes = {
  video: "video",
  image: "image",
};

/** Component used to upload images.
 * @param {Object} props
 * @param {String} props.placeholder - A placeholder to be used beside the button
 * @param {Object} props.style - A style object
 * @param {String} props.boxPadding - A valid css property with unit. ie: `1rem`, `16px` etc. Is used as negative margins to make the image slider be page-wide
 * @param {Integer} props.allowedAmountOfImages - Limits the amount of images that can be uploaded. Defaults to 1
 * @param {Boolean} props.disablePreview - Disables previews of uploaded images (if you want to implement this yourself)
 * @param {Boolean} props.disableDelete - Disables the delete-button
 * @param {Array} props.uploadedFiles - Specify a list of existing images, mostly used for editing content
 * @param {Function} props.onFileUpdate - Is called every time there is a change in the images array. Is passed all the images
 * @param {Boolean} props.disableVideoUpload - Disbables the selection of videos
 *
 * @example
 * ```jsx
 * <ImageUploadMultiple
 *   style={{ marginBottom: "2.5rem" }}
 *   onFileUpdate={({ files: images }) => setFormData({ ...formData, images })}
 * />
 * ```
 *
 */

function ImageUploadPreview(props) {
  let {
    image,
    index,
    files,
    rotateImage,
    scrollImagePreviewToRight,
    changeOrder,
    deleteFile,
    disableDelete,
    disabled = false,
  } = props;

  const { language: lang } = useSelector((state) => state.language);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // If image is loading return loader
  if (image.status === "uploading") {
    return (
      <div className={`image-preview ${componentStyle(primaryColor)}`} key={index}>
        <InlineSpinner title={image.progress < 100 ? Math.round(image.progress) + "%" : lang.workingWithFile} />
        <div className="progress-bar-container">
          <div style={{ flexGrow: image.progress || 0 }} className="progress-bar colored" />
          <div style={{ flexGrow: 100 - (image.progress || 0) }} className="progress-bar transparent" />
        </div>
      </div>
    );

    // If the file has loaded and it is an image return the image component
  } else if (image.status === "uploaded" && image.fileType === fileTypes.image) {
    return (
      <div className={`image-preview ${componentStyle(primaryColor)}`} key={index}>
        <Image
          src={props.urlPrefix ? `${image.baseURL}/${image.image}` : `${image.baseURL}h_${previewHeight}/${image.image}`}
          alt=""
          onLoad={() => scrollImagePreviewToRight()}
        />

        <div className="image-toolbar">
          <ArrowLeftIcon className={index === 0 ? "disabled" : ""} onClick={() => changeOrder("left", index)} />
          <ArrowRightIcon
            className={index === files.length - 1 ? "disabled" : ""}
            onClick={() => changeOrder("right", index)}
          />
          <RotateLeftIcon onClick={() => rotateImage("left", index)} />
          <RotateRightIcon onClick={() => rotateImage("right", index)} />
          <DeleteIcon onClick={!disabled ? () => deleteFile(image, index) : {}} />
        </div>
      </div>
    );
  }

  // If the file has loaded and it is a video return the video component
  else if (image.status === "uploaded" && image.fileType === fileTypes.video) {
    return (
      <div className={`video-preview ${componentStyle(primaryColor)}`} key={index}>
        <video
          controls
          controlsList="nodownload"
          src={`${image.baseURL}/${image.video}`}
          onLoad={() => scrollImagePreviewToRight()}
        />
        {!disableDelete && <DeleteIcon id="deleteIcon" onClick={!disabled ? () => deleteFile(image, index) : {}} />}
      </div>
    );
  } else return null;
}

const previewHeight = 250;

const componentStyle = (primaryColor) => css`
  display: inline-flex;
  white-space: initial;
  position: relative;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border: 1px var(--midGrey) solid;
  border-radius: 3px;
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }

  svg {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    color: var(--darkGrey);
    background-color: var(--white);

    &.disabled {
      color: var(--midLightGrey);
      cursor: default;
      touch-action: none;
      pointer-events: none;
    }
  }

  #deleteIcon {
    position: absolute;
    top: -1px;
    right: -1px;
    border-bottom-left-radius: 4px;
    border: 1px solid var(--midGrey);
  }

  &.image-preview {
    height: ${previewHeight}px;
    min-width: 250px;
    max-width: 60vw;

    img  {
      height: 200px;
      align-self: flex-start;
      transition: transform 300ms ease;
    }
  }

  &.video-preview {
    height: 250px;
    min-width: 250px;
    /* width: 100%; */
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .image-toolbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: var(--white);
    position: absolute;
    bottom: 0;
  }
  /* Progress bar */
  .progress-bar-container {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 3px;
    display: flex;
    justify-content: space-between;

    .progress-bar {
      height: 3px;
    }

    .progress-bar.colored {
      background-color: ${primaryColor};
    }
  }
`;

export default ImageUploadPreview;
