import React, { useMemo } from "react";
import { css } from "emotion";
import DropDown from "../../../../ui/dropDown/components/DropDown";
import useSWR from "swr";
import { swrFetcher } from "../../../../../utilities/swr-fetcher";
import { formatCategoryQuestions } from "./QuestionAsTitle.utils";

const QuestionAsTitle = ({ id = "", name = "", registrationId, onChange, value, onClear }) => {
  const { data, isValidating: loading } = useSWR(`/admin/registrations/${registrationId}/categories`, swrFetcher);

  const sections = useMemo(() => {
    return formatCategoryQuestions(data);
  }, [data]);

  return (
    <div id={id} name={name} className={componentStyle()}>
      <DropDown
        data-test-id={"question-as-title-dropdown"}
        onChange={(value) => onChange(value)}
        value={value}
        onClear={onClear}
        showOnClear={true}
        loading={loading}
        usePlaceholder={true}
        placeholder={"Vælg et spørgsmål"}
        sections={sections}
        groupBy={"sections"}
      />
    </div>
  );
};

const componentStyle = () => css``;

export default QuestionAsTitle;
