import { useDispatch } from "react-redux";

// Actions
import { updateTimeSheetApprovalStatus } from "../../../actions/timeRegistrationActions";

// Config
import approvalStatus from "../config/approvalStatus";

export default function UseHandleAccept() {
  const dispatch = useDispatch();

  function handleAccept({ value, timeRegistrationId, callback = () => {} }) {
    dispatch(
      updateTimeSheetApprovalStatus({
        timeSheetIds: [value],
        status: approvalStatus.approved,
        timeRegistrationId,
        callback,
      })
    );
  }

  return handleAccept;
}
