// Libs
import React, { useEffect } from "react";
import approvalStatus from "../../../config/approvalStatus";
import { resetTableFilter, resetTableFilters, updateTableFilters } from "../../../../../actions/timeRegistrationActions";
import { useDispatch, useSelector } from "react-redux";

const state = {
  approved: "Godkendt",
  rejected: "Afvist",
  pending: "Afventer",
};

const StatusDropDownFilter = ({ column }) => {
  const { setFilter, preFilteredRows, filteredRows, id } = column;

  const dispatch = useDispatch();
  const filterValue = useSelector((state) => state.timeRegistration.table.filters[`${id}`]);

  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] === approvalStatus.approved) options.add(state.approved);
      else if (row.values[id] === approvalStatus.rejected) options.add(state.rejected);
      else {
        options.add(state.pending);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const getValue = (value) => {
    if (value === state.approved) return approvalStatus.approved;
    if (value === state.rejected) return approvalStatus.rejected;
    if (value === state.pending) return approvalStatus.pending;
    return "";
  };

  function onChangeHandler(e) {
    setFilter(e.target.value);
    dispatch(
      updateTableFilters({
        filters: { [`${id}`]: e.target.value },
      })
    );
  }

  useEffect(() => {
    setFilter(filterValue);
  }, [filterValue]);

  // I commented this out, as I can't see why we would do this, but as I dont know the thoughts behind I am leaving it as a comment
  // useEffect(() => {
  //   if (filteredRows.length === 0) {
  //     dispatch(resetTableFilter({ filterProperty: `${id}` }));
  //   }
  // }, [filteredRows]);

  // Render a multi-select box
  return (
    <div className="input-container">
      <label>{column.render("Header")}</label>
      <select value={filterValue || ""} onChange={onChangeHandler}>
        <option value="">Vis alle</option>
        {options.map((option, i) => (
          <option key={i} value={getValue(option)}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StatusDropDownFilter;
