// ActionTypes
import {
  GET_PAGE_BUILDER_PAGES,
  GET_PAGE_BUILDER_PAGES_FAILURE,
  GET_PAGES_BUILDER_PAGES_SUCCESS,
  PAGE_BUILDER_CHANGE_HANDLER_FAILURE,
  PAGE_BUILDER_CHANGE_HANDLER_SUCCESS,
  PAGE_BUILDER_UPDATE_PAGES,
  PAGE_BUILDER_UPDATE_PAGES_FAILURE,
  PAGE_BUILDER_UPDATE_PAGES_SUCCESS,
  PAGE_BUILDER_UPDATE_CURRENT_PARENT_PAGE_ID,
  ADD_USER_TO_PAGE_BUILDER_TARGET_GROUP,
  REMOVE_USER_FROM_PAGE_BUILDER_TARGET_GROUP,
  ADD_ALL_USERS_TO_PAGE_BUILDER_TARGET_GROUP,
  REMOVE_ALL_USERS_FROM_PAGE_BUILDER_TARGET_GROUP,
  UPDATE_PAGE_BUILDER_SHOW_OPTIONS,
  PAGE_BUILDER_AUTH_FAILURE,
  PAGE_BUILDER_AUTH_SUCCESS,
} from "../actions/actionTypes";

// Utilities
import findPage from "../components/pageBuilder/utilities/findPage";
import updatePageInPages from "../components/pageBuilder/utilities/updatePageInPages";

// Config
import { SHOW_ALL } from "../components/pageBuilder/config/filterTypes";

const initialState = {
  initialPages: [],
  pages: [],
  filters: {
    active: SHOW_ALL,
    showOptions: false,
  },
  currentParentPageId: undefined,
  pagesHasBeenChanged: false,
  loadingPageUpdate: false,
  loadingPages: true,
  error: false,
  authorized: true, // Set to true by default, will be set to false if the user is not authorized, improves UX
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAGE_BUILDER_PAGES:
      return {
        ...state,
        loadingPages: true,
        error: false,
      };

    case GET_PAGES_BUILDER_PAGES_SUCCESS:
      return {
        ...state,
        initialPages: action.payload,
        pages: action.payload,
        loadingPages: false,
        error: false,
      };

    case GET_PAGE_BUILDER_PAGES_FAILURE:
      return {
        ...state,
        loadingPages: false,
        error: true,
      };

    case PAGE_BUILDER_CHANGE_HANDLER_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        pagesHasBeenChanged: true,
      };

    case PAGE_BUILDER_CHANGE_HANDLER_FAILURE: {
      return {
        ...state,
        error: true,
      };
    }

    case PAGE_BUILDER_UPDATE_PAGES: {
      return {
        ...state,
        loadingPageUpdate: true,
      };
    }

    case PAGE_BUILDER_UPDATE_PAGES_SUCCESS: {
      return {
        ...state,
        initialPages: action.payload,
        pages: action.payload,
        pagesHasBeenChanged: false,
        loadingPageUpdate: false,
      };
    }

    case PAGE_BUILDER_UPDATE_PAGES_FAILURE: {
      return {
        ...state,
        loadingPageUpdate: false,
        pagesHasBeenChanged: false,
        error: true,
      };
    }

    case PAGE_BUILDER_UPDATE_CURRENT_PARENT_PAGE_ID: {
      return {
        ...state,
        currentParentPageId: action.payload,
      };
    }

    case ADD_USER_TO_PAGE_BUILDER_TARGET_GROUP: {
      let pages = [...state.pages];

      let newPage = findPage({ pageId: action.payload.pageId, pages });
      newPage.targetGroups.users = [...newPage.targetGroups.users, action.payload.user];
      newPage.targetGroups.modified = true;

      let updatedPages = updatePageInPages({ newPage, pages });

      return {
        ...state,
        pages: updatedPages,
        pagesHasBeenChanged: true,
      };
    }

    case REMOVE_USER_FROM_PAGE_BUILDER_TARGET_GROUP: {
      let pages = [...state.pages];

      let newPage = findPage({ pageId: action.payload.pageId, pages });
      newPage.targetGroups.users = newPage.targetGroups.users.filter((user) => user.id !== action.payload.user.id);
      newPage.targetGroups.modified = true;

      let updatedPages = updatePageInPages({ newPage, pages });

      return {
        ...state,
        pages: updatedPages,
        pagesHasBeenChanged: true,
      };
    }

    case ADD_ALL_USERS_TO_PAGE_BUILDER_TARGET_GROUP: {
      let pages = [...state.pages];

      let newPage = findPage({ pageId: action.payload.pageId, pages });
      newPage.targetGroups.users = [...newPage.targetGroups.users, ...action.payload.users];
      newPage.targetGroups.modified = true;

      let updatedPages = updatePageInPages({ newPage, pages });

      return {
        ...state,
        pages: updatedPages,
        pagesHasBeenChanged: true,
      };
    }

    case REMOVE_ALL_USERS_FROM_PAGE_BUILDER_TARGET_GROUP: {
      let pages = [...state.pages];

      let newPage = findPage({ pageId: action.payload.pageId, pages });
      newPage.targetGroups.users = [];
      newPage.targetGroups.modified = true;

      let updatedPages = updatePageInPages({ newPage, pages });

      return {
        ...state,
        pages: updatedPages,
        pagesHasBeenChanged: true,
      };
    }

    case PAGE_BUILDER_AUTH_SUCCESS: {
      return {
        ...state,
        authorized: true,
      };
    }

    case PAGE_BUILDER_AUTH_FAILURE: {
      return {
        ...state,
        authorized: false,
      };
    }

    default:
      return state;
  }
}
