import { css } from "emotion";
import DynamicIcon from "../Icons/DynamicIcon";
import { durations } from "../../../config/animations";
import { useDispatch, useSelector } from "react-redux";
import { moveLeft, moveRight, removeMedia, rotateLeft, rotateRight } from "../../../actions/mediaUploadActions";
import MediaPreviewElement from "./preview/MediaPreviewElement";

/**
 * @param { Object } props
 * @param { import("../../../reducers/mediaUploadReducer").MediaUploadFile } props.media
 */
const MediaUploadPreview = ({ media }) => {
  const dispatch = useDispatch();

  const onRemove = () => {
    dispatch(removeMedia(media.id));
  };

  const lang = useSelector((state) => state.language.language);

  return (
    <div className={componentStyle()}>
      <div className="container">
        <div className="media-container">
          <MediaPreviewElement media={media} />
        </div>
        <div className="controls">
          {media.file.type.startsWith("image") && (
            <>
              <button onClick={() => dispatch(moveLeft(media))}>
                <DynamicIcon icon="arrow-left"></DynamicIcon>
              </button>
              <button onClick={() => dispatch(moveRight(media))}>
                <DynamicIcon icon="arrow-right"></DynamicIcon>
              </button>
              <button onClick={() => dispatch(rotateLeft(media.id))}>
                <DynamicIcon icon="rotate-left"></DynamicIcon>
              </button>
              <button onClick={() => dispatch(rotateRight(media.id))}>
                <DynamicIcon icon="rotate-right"></DynamicIcon>
              </button>
            </>
          )}

          <div className="spacer"></div>
          <button onClick={onRemove}>
            <DynamicIcon icon="trash"></DynamicIcon>
          </button>
        </div>
      </div>
      <div className="info-text">
        {media.state === "error" && (
          <p className="error">
            <DynamicIcon icon="error"></DynamicIcon>
            {lang.errorCouldNotUploadFile}
          </p>
        )}
        {media.state === "pending" && (
          <p className="pending">
            <DynamicIcon icon="clock"></DynamicIcon>
            {lang.pending}
          </p>
        )}
        {media.state === "uploading" && (
          <p className="uploading">
            <DynamicIcon icon="loading" className="loading"></DynamicIcon>
            {lang.uploading}... {Math.floor((media.progress || 0) * 100)}%
          </p>
        )}
        {media.state === "uploaded" && <p className="uploaded">&nbsp;</p>}
        {media.state === "pre-existing" && <p className="uploaded">&nbsp;</p>}
      </div>
    </div>
  );
};

const componentStyle = () => css`
  max-width: 300px;

  display: inline-block;

  .container {
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr;

    border: 1px solid var(--midGrey);
    border-radius: 4px;

    overflow: hidden;

    height: 300px;
    min-width: 300px;

    .media-container {
      height: 100%;
      width: 100%;

      position: relative;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .controls {
      flex-shrink: 0;

      display: flex;
      height: 40px;

      button {
        width: 40px;

        background: none;
        border: none;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: var(--lightGrey);
          cursor: pointer;
        }
      }

      .spacer {
        flex-grow: 1;
      }
    }
  }

  .info-text {
    font-size: 0.9rem;
    padding-top: 4px;

    svg {
      height: 1rem;
      width: 1rem;
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;

      gap: 4px;
    }

    .error {
      color: var(--red);
    }

    .pending,
    .uploading {
      color: var(--darkGrey);

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(359deg);
        }
      }

      > .loading {
        animation: spin ${durations.slow}ms linear infinite;
      }
    }

    .uploaded {
      color: var(--green);
    }

    .rotate-90 {
      transform: rotate(90deg);
    }

    .rotate-180 {
      transform: rotate(180deg);
    }

    .rotate-270 {
      transform: rotate(270deg);
    }
  }
`;

export default MediaUploadPreview;
