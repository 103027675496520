// Libs
import React, { useState, Fragment, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import Button from "../ui/Button";
import SickLeaveModal from "./SickLeaveModal";
import InlineSpinner from "../ui/InlineSpinner";
import DatePicker from "../ui/DatePicker";

// Styles
import { BedIcon } from "mdi-react";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

// Utilities
import { parseDate } from "../../utilities/parse-date";
import req from "../../utilities/request-utility";
import getPageFromId from "../../utilities/get-page-from-id";

// Config
import typeOfEndDateConfig from "./config/typeOfEndDate";

// Actions
import { addToast, showModalPage } from "../../actions/uiActions";
import CheckboxGroup from "../ui/CheckboxGroup";
import ScrollView from "../ui/ScrollView";
import pageConfig from "../../config/pageConfig";

const SickLeave = (props) => {
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const { config } = page;

  // Language state from redux
  const { language: lang } = useSelector((state) => state.language);

  // Local state
  const [registration, setRegistration] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [wellForm, setWellForm] = useState({
    wellEndDate: format(new Date(), "yyyyMMdd"),
  });
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [notValidIds, setNotValidIds] = useState([]);

  // Variables
  const typeOfEndDate = config && config[pageConfig.SICKLEAVE_TYPE_OF_END_DATE];
  const showComments = config && config[pageConfig.SICKLEAVE_SHOW_COMMENTS];

  useEffect(() => {
    getSickStatus();
    // eslint-disable-next-line
  }, []);

  const getSickStatus = async () => {
    try {
      const { data: registrationData } = await req()(`sick-leave/status`);
      setDescription(registrationData.description);
      setRegistration(registrationData.registration);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitWellRegistration = () => {
    setLoading(true);
    req()
      .put(`sick-leave/forms/well/${registration.id}`, wellForm)
      .then(() => {
        props.addToast({
          styleType: "success",
          title: lang.thankYouForTheRegistration,
          content: lang.registrationReceived,
          duration: 5000,
        });
        setRegistration(null);
        getSickStatus();
      })
      .catch((err) => {
        setLoading(false);
        props.addToast({
          styleType: "error",
          title: lang.somethingWentWrong,
          content: lang.errorSavingRegistrationToastContent,
          duration: 5000,
        });
      });
  };

  // 20210325 <= 20210327;

  // Validation - checks if the required fields are filled
  useEffect(() => {
    // Added registraion to dependancy list to fix issue where a user could submit a "i am now well" registration
    // without filling out any data
    if (acceptConditions && registration) {
      const { sickStartDate } = registration;
      const { wellEndDate } = wellForm;
      const validationArr = [];
      const today = format(new Date(), "yyyyMMdd");
      if (!wellEndDate) validationArr.push(1);

      if (wellEndDate && sickStartDate > wellEndDate.replace(/-/g, "")) {
        props.addToast({
          styleType: "error",
          title: lang.unvalidDate,
          content: lang.lastSickdayCantBeBeforeFirst,
          duration: 10000,
        });
        validationArr.push(1);
      }

      if (wellEndDate && today.replace(/-/g, "") < wellEndDate.replace(/-/g, "")) {
        props.addToast({
          styleType: "error",
          title: lang.unvalidDate,
          content: lang.submitWellCantBeInFuture,
          duration: 10000,
        });
        validationArr.push(1);
      }

      setNotValidIds([...validationArr]);
    }
    // eslint-disable-next-line
  }, [acceptConditions, wellForm, registration]);

  const getStringForSickEndDate = (sickEndDate) => {
    // in the database shorter and longer sick leave has been stored as one of these two strings. To make it backwards compatible I keep using them.
    if (sickEndDate === "Kortere fravær") {
      return lang.illnessShorterDuration;
    } else if (sickEndDate === "Længere fravær") {
      return lang.illnessLongerDuration;
    } else return sickEndDate;
  };

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={lang.sickLeave} />
      <ScrollView className={componentStyle()}>
        {/** Loading **/}
        {loading && (
          <div className="spinner-container">
            <InlineSpinner />
          </div>
        )}

        <div className="sick-leave-outer-container">
          {/** BEFORE APPLYING THE SICK LEAVE **/}
          {!registration && !loading && (
            <Fragment>
              <p className="description">{description || ""}</p>
              <p className="description">{lang.rememberYouAlwaysShouldSubmitWell}</p>
              <Button
                onClick={() =>
                  props.showModalPage({
                    title: lang.sickLeave,
                    content: (
                      <SickLeaveModal
                        getSickStatus={getSickStatus}
                        typeOfEndDate={typeOfEndDate}
                        showComments={showComments}
                      />
                    ),
                    useScrollView: false,
                  })
                }
              >
                {lang.createSickLeave}
              </Button>
            </Fragment>
          )}

          {/** SUBMIT -> NO LONGER SICK LEAVE **/}
          {registration && !loading && (
            <Fragment>
              <div className="sick-leave-container">
                <p className="title">
                  <BedIcon /> {lang.sickLeave}
                </p>

                <p className="sick-leave-label">{lang.firstSickDay}</p>
                <p className="sick-leave-answer">{parseDate(registration.sickStartDate)}</p>
                <p className="sick-leave-label">
                  {typeOfEndDate === typeOfEndDateConfig.estimate ? `${lang.expectedDuration}:` : lang.expectedLastSickDay}
                </p>
                <p className="sick-leave-answer">
                  {typeOfEndDate === typeOfEndDateConfig.estimate
                    ? getStringForSickEndDate(registration.sickEndDate)
                    : parseDate(registration.sickEndDate)}
                </p>
              </div>
              <p className={notValidIds.some((id) => id === 1) ? "sick-label not-valid-title" : "sick-label"}>
                {lang.lastSickDay}
              </p>
              <DatePicker
                style={{ marginBottom: "2rem" }}
                className={notValidIds.some((id) => id === 1) ? "not-valid" : ""}
                name="date"
                defaultDate={parseDate(wellForm.wellEndDate, "yyyy-MM-dd")}
                onDateChange={(date) => setWellForm({ ...wellForm, wellEndDate: date.replace(/-/g, "") })}
              />

              <CheckboxGroup
                style={{ marginBottom: "1.5rem" }}
                options={[{ id: 1, title: lang.iDeclareOnFaithAndLaws }]}
                onSelect={() => setAcceptConditions(!acceptConditions)}
              />
              <Button
                disabled={!acceptConditions || notValidIds.length > 0}
                buttonType={!acceptConditions || notValidIds.length > 0 ? "disabled" : ""}
                onClick={() => submitWellRegistration()}
              >
                {lang.submitWellAgain}
              </Button>
            </Fragment>
          )}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  height: 100%;
  width: 100%;
  background-color: var(--white);
  padding: 1rem;

  div.sick-leave-outer-container {
    max-width: ${breakpoints.md}px;
    margin: auto;

    div.not-valid {
      border: 1px solid var(--redMedium);
    }

    p.not-valid-title {
      color: var(--redMedium);
    }

    p.description {
      margin-bottom: 1rem;
    }

    p.description:last-of-type {
      margin-bottom: 2rem;
    }

    p.sick-label {
      margin-bottom: 0.5rem;
    }

    div.sick-leave-container {
      border: 1px solid var(--lightGrey);
      border-radius: 3px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;

      p.title {
        display: flex;
        font-size: 1.12rem;
        font-weight: bold;
        align-items: center;
        margin-bottom: 0.85rem;

        svg {
          margin-right: 0.5rem;
          width: 1.85rem;
          height: auto;
          color: var(--darkGrey);
        }
      }

      p.attention-title {
        font-size: 0.85rem;
        color: var(--darkGrey);
      }

      p.sick-leave-label {
        color: var(--darkGrey);
        margin-bottom: 0.125rem;
      }

      p.sick-leave-answer {
        margin-bottom: 0.85rem;
      }
    }

    p.sick-date-label {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  div.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(SickLeave);
