import { css } from "emotion";
import TextInput from "../TextInput";
import getEmbedLink from "../../../utilities/get-video-embed-link";

function EmbedLinkInput({ value, onChange, disabled, style }) {
  const embedLink = getEmbedLink(value);

  return (
    <div className={componentStyle({ disabled })} style={style}>
      <div className="input-container">
        <TextInput value={value} onChange={onChange} disabled={disabled}></TextInput>
        <span className="description">Modtager YouTube og Vimeo links</span>
      </div>
      {embedLink && (
        <iframe
          src={embedLink}
          title="Embedded Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
}

const componentStyle = ({ disabled }) => css`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  ${disabled
    ? css`
        opacity: 0.5;
      `
    : ""}

  .input-container {
    display: flex;
    flex-direction: column;

    .description {
      margin-top: 0.25rem;
      font-size: 0.8rem;
      color: var(--black);
    }

    .error {
      color: var(--red);
    }
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
`;

export default EmbedLinkInput;
