// Libs
import React from "react";
import { useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import ActionWrapper from "../../../ui/ActionWrapper";

// Icons
import { TickCircleIcon, CancelIcon, DotsVerticalIcon, ExportIcon } from "mdi-react";

// Style
import colors from "../../../../style/colors";

// Hooks
import UseHandleAccept from "../../hooks/useHandleAccept";
import UseHandleReject from "../../hooks/useHandleReject";
import UseHandleExport from "../../hooks/useHandleExport";
import UseToggleContextMenu from "../../hooks/useToggleContextMenu";

const ActionCell = (props) => {
  const handleAccept = UseHandleAccept();
  const handleReject = UseHandleReject();
  const handleExport = UseHandleExport();
  const toggleContextMenu = UseToggleContextMenu();

  const permissions = useSelector((state) => state.timeRegistration.permissions);
  const timeSheet = useSelector((state) => state.timeRegistration.table.timeSheets).find(
    (timeSheet) => timeSheet.id === props.value
  );

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  const { value, exported, user } = props;

  const buttonStyles = { margin: "0 0.35rem", borderRadius: "50%", width: "1.5rem", height: "1.5rem" };

  return (
    <div className={`actions-cell ${exported ? "disabled" : ""}`}>
      {permissions.canApprove && (
        <>
          <ActionWrapper
            style={buttonStyles}
            title="Afvis"
            onClick={(e) => {
              e.stopPropagation();
              handleReject({ value, timeRegistrationId });
            }}
          >
            <CancelIcon color={colors.red} />
          </ActionWrapper>
          <ActionWrapper
            style={buttonStyles}
            title="Godkend"
            onClick={(e) => {
              e.stopPropagation();
              handleAccept({ value, timeRegistrationId });
            }}
          >
            <TickCircleIcon color={colors.green} />
          </ActionWrapper>
        </>
      )}
      {permissions?.canExport && timeSheet?.timeRegistrationData?.isApproved && (
        <ActionWrapper
          style={buttonStyles}
          title="Eksporter"
          onClick={(e) => {
            e.stopPropagation();
            handleExport({ value, timeRegistrationId });
          }}
        >
          <ExportIcon />
        </ActionWrapper>
      )}
      {(permissions.canExport || permissions.canRegisterForOthers) && (
        <ActionWrapper
          style={buttonStyles}
          onClick={(e) => {
            e.stopPropagation();
            toggleContextMenu({ value, timeSheet, timeRegistrationId, user });
          }}
        >
          <DotsVerticalIcon color={colors.midDarkGrey} />
        </ActionWrapper>
      )}
    </div>
  );
};

export default ActionCell;
