import { css } from "emotion";
import React from "react";
import { useSelector } from "react-redux";
import { ListItemSkeletonGroup } from "../../ui/ListItemSkeleton";
import HighscoreListItem from "./HighscoreListItem";
import breakpoints from "../../../config/breakpoints";

/**
 * Please note that this component is *not* used in the main Highscore.jsx component, but adapted from it.
 *
 * At some point, this should probably be refactored to be used in the main Highscore.jsx component.
 */
const HighscoreUserListContainer = ({ loading, scores }) => {
  const { language: lang } = useSelector((state) => state.language);

  return (
    <div className={style()}>
      <div className="score-header">
        <p>{lang.rank}</p>
        <p className="name">{lang.name}</p>
        <p>{lang.points}</p>
      </div>
      {loading && <ListItemSkeletonGroup></ListItemSkeletonGroup>}
      {!loading &&
        scores?.map((score, index) => {
          return <HighscoreListItem key={score.id} rank={index + 1} user={score} score={score.score} />;
        })}
      {/* {!loading && <HighscoreListItem rank={1} user={user} score={10} />} */}
    </div>
  );
};

const style = () => css`
  border-radius: 3px;
  border-top: 1px solid var(--midGrey);
  border-bottom: 1px solid var(--midGrey);
  background-color: var(--white);
  margin-bottom: 2rem;

  .score-header {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 0.5px solid var(--midGrey);
    border-right: 0.5px solid var(--midGrey);
    text-align: right;
    padding: 0.75rem;
    font-size: 0.75rem;
    color: var(--darkGrey);
    display: flex;

    p.name {
      flex: 1;
      text-align: start;
      padding-left: 0.5rem;
    }
  }
`;

export default HighscoreUserListContainer;
