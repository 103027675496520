// Libraries
import React, { useEffect } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Utilities
import getHoursAndMinutes from "../../utilities/get-hours-and-minutes";

// Styles
import colors from "../../style/colors";

const { hours, minutes } = getHoursAndMinutes({ allowedMinutes: undefined });

/**
 * @typedef {Object | null} TimePickerValue
 * @property {string} hours
 * @property {string} minutes
 */

/**
 * @typedef {Object} TimePickerProps
 * @property {TimePickerValue} value
 * @property {Function} onChange
 * @property {TimeRange} range
 * @property {TimePickerValue} min - Default: { hours: 0, minutes: 0 }
 * @property {TimePickerValue} max - Default: { hours: 23, minutes: 59 }
 */

/**
 * Time picker component
 * Alternative to the old time picker, this is a stateless component.
 * @param {TimePickerProps} props
 * */
const TimePicker = ({
  value = { minutes: undefined, hours: undefined },
  onChange,
  min = { hours: 0, minutes: 0 },
  max = { hours: 23, minutes: 59 },
}) => {
  const { language: lang } = useSelector((state) => state.language);

  function onHoursChange(e) {
    onChange && onChange({ ...value, hours: e.target.value });
  }

  function onMinutesChange(e) {
    onChange && onChange({ ...value, minutes: e.target.value });
  }

  return (
    <div className={componentStyle()}>
      <select className="hours" onChange={(e) => onHoursChange(e)} value={value ? value.hours : ""}>
        <option hidden={true} value="">
          {lang.hours}
        </option>
        {hours.map((hour) => {
          return (
            <option key={hour} value={hour} disabled={parseInt(hour) < min.hours || parseInt(hour) > max.hours}>
              {hour}
            </option>
          );
        })}
      </select>
      :
      <select className="minutes" onChange={(e) => onMinutesChange(e)} value={value ? value.minutes : ""}>
        <option hidden={true} value="">
          {lang.minutes}
        </option>
        {minutes.map((minute) => {
          return (
            <option key={minute} value={minute} disabled={parseInt(minute) < min.minutes || parseInt(minute) > max.minutes}>
              {minute}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const componentStyle = () => css`
  height: 40px;
  width: 100%;
  display: flex;
  gap: 0.25rem;

  align-items: center;

  select {
    height: 100%;
    width: 100%;

    font-size: 1rem;
    border-radius: 3px;
    border: 1px solid var(--midGrey);
    padding: 0.5rem;
    color: var(--darkGrey);
    text-align-last: center;
    --moz-appearance: none;
    -webkit-appearance: none;
  }
`;

export default TimePicker;
