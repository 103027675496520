// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Hooks
import useHighscore from "../hooks/useHighscore";
import useAuthorModal from "../../../hooks/useAuthorModal";

// Components
import ListItem from "../../ui/ListItem";
import StatusBox from "../../ui/StatusBox";
import { ListItemSkeletonGroup } from "../../ui/ListItemSkeleton";

// Styles
import breakpoints from "../../../config/breakpoints";
import colors from "../../../style/colors";

// Utilities
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../../utilities/get-user-group-title";

const Highscore = (props) => {
  const userId = useSelector((state) => state.auth.user.id);
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const lang = useSelector((state) => state.language.language);

  const { highscore, loading, error } = useHighscore({ highscoreId: props.highscoreId });

  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  const FirstListContainer = ({ title, children }) => (
    <div className="high-score-container">
      <h1>{title}</h1>
      <div className="scores-container">
        <div className="score-header">
          <p>{lang.rank}</p>
          <p className="name">{lang.name}</p>
          {highscore && <p>{highscore.title}</p>}
        </div>
        {children}
      </div>
    </div>
  );

  return (
    <div className={`${componentStyle(primaryColor)} ${props.className || ""}`}>
      {loading && !error && (
        <FirstListContainer>
          <ListItemSkeletonGroup />
        </FirstListContainer>
      )}
      {!loading && error && <StatusBox className="error-box" />}
      {!loading && !error && (
        <FirstListContainer>
          {highscore.scores.map(({ id, value, rank, user = null, title = null }) => (
            <ListItem
              clickable={user ? true : false}
              maxWidth={breakpoints.lg}
              itemNumberLeft={rank}
              onClick={user ? () => authorModal(user.id) : null}
              className={`list-item ${user && user.id === userId ? "current-user" : ""}`}
              imageLeft={getProfilePicture(user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              key={id}
              title={user ? user.name : title}
              subTitle={getUserGroupTitle(user)}
              iconRight={value}
            />
          ))}
        </FirstListContainer>
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  overflow: auto;

  .error-box {
    margin-top: 1rem;
  }

  .high-score-container {
    width: 100%;
    max-width: ${breakpoints.lg}px;
    margin: 1rem auto;
  }

  .list-item:last-of-type {
    border-radius: 0 0 3px 3px;
  }

  .list-item.current-user {
    font-weight: 700;
    color: ${primaryColor};
    background-color: var(--ultraLightGrey);
  }

  .scores-container {
    border-radius: 3px;
    border-top: 1px solid var(--midGrey);
    border-bottom: 1px solid var(--midGrey);
    background-color: var(--white);
    margin-bottom: 2rem;

    .score-header {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      border-left: 0.5px solid var(--midGrey);
      border-right: 0.5px solid var(--midGrey);
      text-align: right;
      padding: 0.75rem;
      font-size: 0.75rem;
      color: var(--darkGrey);
      display: flex;

      p.name {
        flex: 1;
        text-align: start;
        padding-left: 0.5rem;
      }
    }
  }
`;

export default Highscore;
