// Libs
import React, { useEffect, useState } from "react";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import QuizResults from "./QuizResults";
import AcademyPreview from "../academy/AcademyPreview";

// Utilities and config
import { connect, useSelector } from "react-redux";
import getPageFromId from "../../utilities/get-page-from-id";
import { bindActionCreators } from "redux";
import { showContextMenu, showModalPage } from "../../actions/uiActions";
import { academyTypes } from "../../config/academyTypes";
import breakpoints from "../../config/breakpoints";

// Actions
import { getQuizzesOverview } from "../../actions/academyActions";

// Styles and UI
import { CheckCircleIcon, CheckDecagramIcon, InfoOutlineIcon } from "mdi-react";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ListItem from "../ui/ListItem";
import ContextMenuButton from "../ui/ContextMenuButton";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { css } from "emotion";
import colors from "../../style/colors";

const QuizOverview = (props) => {
  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // Page for navigation
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  // UI Layout
  const [tabs] = useState([lang.new, lang.completed]);
  const [activeTab, setActiveTab] = useState(0);

  // Quiz redux stats
  const { newQuizzes, completedQuizzes, loading } = useSelector((state) => state.academy.quiz);

  // Fetches the quizzes on componentDidMount (NOT the whole quiz)
  useEffect(() => {
    // Get new quizzes names and preview data
    props.getQuizzesOverview();
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />
      {loading && (
        <div style={{ marginTop: "3rem" }}>
          <Spinner />
        </div>
      )}
      {!loading && (
        <TabView
          tabStyle={{ padding: "1rem 0" }}
          activeTabIndex={activeTab}
          tabs={[
            <>
              {newQuizzes.length > 0 &&
                newQuizzes.map((q, index) => (
                  <ListItem
                    clickable={true}
                    dataTestId={`quiz-row-${index}`}
                    key={index}
                    maxWidth={breakpoints.md}
                    onClick={() => {
                      props.showModalPage({
                        title: lang.quiz,
                        content: <AcademyPreview quiz={q} type={academyTypes.quiz} />,
                        pageStyle: { backgroundColor: colors.white },
                      });
                    }}
                    title={q.title}
                  />
                ))}
              {newQuizzes.length === 0 && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.thereIsNoQuizzesNow}
                  content={" "}
                />
              )}
            </>,
            <>
              {completedQuizzes.length > 0 &&
                completedQuizzes.map((q, index) => (
                  <ListItem
                    key={index}
                    maxWidth={breakpoints.md}
                    style={{ height: "4.5rem" }}
                    title={q.title}
                    subTitleStyle={{ paddingTop: "0.5rem" }}
                    content={
                      <div className={componentStyle()}>
                        <CheckDecagramIcon size={16} style={{ marginRight: "0.4rem" }} />
                        <p>{`${q.correctAnswers} / ${q.totalAnswers} ${lang.correct.toLowerCase()}`}</p>
                      </div>
                    }
                    iconRight={
                      <ContextMenuButton
                        dataTestId={"show-quiz-options"}
                        onClick={() => {
                          props.showContextMenu([
                            {
                              "data-test-id": "view-answers",
                              icon: <CheckCircleIcon />,
                              title: lang.viewAnswers,
                              callback: () => {
                                props.showModalPage({
                                  title: lang.quizAnswers,
                                  content: <QuizResults quizId={q.id} />,
                                  useScrollView: false,
                                  pageStyle: { backgroundColor: colors.white },
                                });
                              },
                            },
                          ]);
                        }}
                      />
                    }
                  />
                ))}

              {completedQuizzes.length === 0 && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.youHaveNotCompletedAnyQuizzesYet}
                  content={" "}
                />
              )}
            </>,
          ]}
        />
      )}
    </Page>
  );
};

const componentStyle = (props) => css`
  display: flex;
  align-items: center;

  p,
  svg {
    color: var(--darkGrey);
  }

  p {
    font-size: 0.8rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getQuizzesOverview: bindActionCreators(getQuizzesOverview, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(QuizOverview);
