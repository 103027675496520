// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCurrentPageBuilderPage = ({ pageId }) => {
  const [page, setPage] = useState(undefined);

  const pages = useSelector((state) => state.pageBuilder.pages);

  function locatePage(pageId, pages) {
    let locatedPage = undefined;

    for (let page of pages) {
      if (page.id === pageId) locatedPage = page;

      if (Array.isArray(page.pages) && page.pages.length > 0) {
        let foundLocatedPage = locatePage(pageId, page.pages);
        if (foundLocatedPage) locatedPage = foundLocatedPage;
      }
    }

    return locatedPage;
  }

  useEffect(() => {
    let locatedPage = locatePage(parseInt(pageId, 10), pages);
    setPage(locatedPage);
  }, [pages]);

  return { page };
};

export default useCurrentPageBuilderPage;
