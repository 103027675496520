// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import { FileOutlineIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";

const FileViewer = (props) => {
  // Props
  const { href, style, className, title } = props;

  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  return (
    <a
      rel="noreferrer noopener"
      target="_blank"
      href={href}
      style={style}
      className={`${componentStyle()} ${className}`}
      key={`formbuilder-view-${href}`}
    >
      <FileOutlineIcon />
      <p>{title || lang.file}</p>
    </a>
  );
};

const componentStyle = () => css`
  text-decoration: none;
  display: block;
  color: var(--darkGrey);
  border-bottom: 1px var(--midGrey) solid;
  padding: 0.5rem;
  position: relative;
  transition: background-color 180ms ease;

  &:last-child {
    border-bottom: 0px;
  }

  &:active {
    background-color: var(--lightGrey);
    transition: background-color 180ms ease;
  }

  p {
    padding-left: 1.75rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0.6rem;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    fill: var(--darkGrey);
  }
`;

export default FileViewer;
