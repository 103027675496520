// Libs
import React from "react";

// This filter is actually not shown anywhere. It is overwritting in all the columns actually using a filter.
function DefaultFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      // This is not ideal... When using the global filter (the search bar), the default filter was shown for all the columns, which filter was set to diabled...
      style={{ display: "none" }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Søg i ${count} rækker...`}
    />
  );
}

export default DefaultFilter;
