// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce/lib";

// Components
import TextInput from "../../../../ui/TextInput";

// Utilities
import iconConverter from "../../../../../utilities/icon-converter";

// Styles
import colors from "../../../../../style/colors";

const IconSelector = (props) => {
  const { onChange, icon } = props;

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  const [localIcon, setLocalIcon] = useState(props.icon);

  const [debounceIconChange] = useDebouncedCallback(({ icon }) => {
    setLocalIcon(icon);
  }, 500);

  function onChangeHandler(e) {
    onChange(e);
    debounceIconChange({ icon: e.target.value });
  }

  return (
    <div className={componentStyle(primaryColor)}>
      <TextInput
        data-test-id={props["data-test-id"]}
        className="icon-input"
        name="icon"
        onChange={onChangeHandler}
        value={icon || ""}
      />
      <div className="icon-wrapper">{iconConverter(localIcon)}</div>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  display: flex;
  width: 100%;

  .icon-input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    border: 1px solid var(--midGrey);
    border-left: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:focus {
      outline: 0;
      border: 1px solid ${primaryColor};
    }
  }
`;

export default IconSelector;
