// Action types
import {
  GET_BIRTHDAYS,
  GET_BIRTHDAYS_FAILURE,
  GET_BIRTHDAYS_SUCCESS,
  RESET_BIRTHDAYS,
  SET_IS_NO_BIRTHDAYS,
  SET_NEW_BIRTHDAY_YEAR,
  SET_BIRTHDAYS_FILTER_VALUE,
  SET_RELATED_APPS_BIRTHDAYS,
} from "../actions/actionTypes";

// Utilities
import { getYearString } from "../utilities/parse-date";

const initialState = {
  birthdays: [],
  newBirthdays: [],
  newBirthdaysOffset: 0,
  isNextYear: 0,
  isNoBirthdays: false,
  isNoBirthdaysCount: 0,
  currentYear: parseInt(getYearString()),
  loading: false,
  error: false,
  relatedApps: [],
  filters: {
    active: false,
    selectedAppId: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BIRTHDAYS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_BIRTHDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newBirthdays: [...action.payload.birthdays],
        newBirthdaysOffset: action.payload.offset,
        isNoBirthdaysCount: 0,
      };

    case GET_BIRTHDAYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SET_NEW_BIRTHDAY_YEAR:
      return {
        ...state,
        loading: false,
        error: false,
        birthdays: [...state.birthdays, ...state.newBirthdays],
        newBirthdays: [],
        newBirthdaysOffset: 0,
        isNextYear: 1,
        currentYear: state.currentYear + 1,
        isNoBirthdaysCount: state.isNoBirthdaysCount + 1,
      };

    case SET_IS_NO_BIRTHDAYS:
      return {
        ...state,
        loading: false,
        error: false,
        isNoBirthdays: true,
      };

    case RESET_BIRTHDAYS:
      return {
        ...initialState,
        relatedApps: [...state.relatedApps],
        filters: { ...state.filters },
      };

    case SET_BIRTHDAYS_FILTER_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };

    case SET_RELATED_APPS_BIRTHDAYS:
      return {
        ...state,
        relatedApps: action.payload,
      };

    default:
      return state;
  }
}
