import React, { useEffect } from "react";
import { css } from "emotion";
import { CheckboxBlankOutlineIcon, CheckboxMarkedIcon } from "mdi-react";
import { useSelector } from "react-redux";
import colors from "../../../../style/colors";

const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  const { checked, exported } = rest;
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const { enableCSVDownload } = useSelector((state) => state.timeRegistration.table.filters);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className={componentStyles(primaryColor)}>
      {checked && (!exported || enableCSVDownload) && <CheckboxMarkedIcon className={"checkbox-icon checked"} />}
      {!checked && (!exported || enableCSVDownload) && <CheckboxBlankOutlineIcon className={"checkbox-icon blank"} />}
      {exported && !enableCSVDownload && <CheckboxBlankOutlineIcon className={"checkbox-icon disabled "} />}

      <input
        className={`actual-checkbox ${exported && !enableCSVDownload && "disabled"}`}
        type="checkbox"
        onClick={(e) => e.stopPropagation()}
        ref={resolvedRef}
        {...rest}
      />
    </div>
  );
});

const componentStyles = (primaryColor) => css`
  position: relative;
  width: 22px;
  height: 22px;

  .actual-checkbox {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
  }

  .checkbox-icon {
    position: absolute;

    &.checked {
      color: ${primaryColor};
    }
    &.blank {
      color: var(--darkGrey);
    }
    &.disabled {
      color: var(--darkGrey);
    }
  }
`;

export default Checkbox;
