import getAppName from "./get-app-name";

export default () => {
  try {
    const serializedState = localStorage.getItem(`${getAppName()}-state`);
    if (!serializedState) return undefined;
    let state = JSON.parse(serializedState);

    // Compatibility hack. This can be removed in two months after release of search-feature
    if (!state.pages.navigationStack) state.pages.navigationStack = [];

    return state;
  } catch (err) {
    return undefined;
  }
};
