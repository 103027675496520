import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import dayjs from "dayjs";

// Actions
import { addToast, hideModalPage, showDialog } from "../../actions/uiActions";
import { updateUserData } from "../../actions/authActions";

// Utilities
import req from "../../utilities/request-utility";
import getUserGroupTitle from "../../utilities/get-user-group-title";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Components
import Button from "../ui/Button";
import ImageUploadSingle from "../ui/ImageUploadSingle";
import TextInput from "../ui/TextInput";
import TextareaInput from "../ui/TextareaInput";
import ScrollView from "../ui/ScrollView";
import {
  TrashIcon,
  CheckCircleOutlineIcon,
  AlertCircleIcon,
  LockIcon,
  InfoOutlineIcon,
  CakeIcon,
  DateRangeIcon,
} from "mdi-react";
import InformationBox from "../ui/InformationBox";

// Style
import colors from "../../style/colors";

class UserProfileEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { ...this.props.user },
      submittingForm: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitForm = () => {
    this.setState({ submittingForm: true });

    // Submit form
    req()
      .put(`/users/${this.props.user.id}/`, this.state.formData)
      .then((res) => {
        this.setState({ submittingForm: false });
        this.props.updateUserData(res.data);
        this.props.addToast({
          styleType: "success",
          title: this.props.lang.profileUpdated,
          content: this.props.lang.yourChangesWereSaved,
          icon: <CheckCircleOutlineIcon />,
          duration: 5000,
        });
        this.props.getUserData();
        this.props.hideModalPage();
      })
      .catch((err) => {
        this.setState({ submittingForm: false });
        this.props.addToast({
          styleType: "error",
          title: this.props.lang.oopsAnErrorOccured,
          content: this.props.lang.couldNotEditYourProfileTryAgain,
          icon: <AlertCircleIcon />,
          duration: 20000,
        });
        // TODO: Implement error logging someLogger(err)
      });
  };

  closeForm = () => {
    let { showDialog, lang, hideModalPage } = this.props;

    showDialog({
      title: lang.heyThere,
      content: lang.closeFormWarningContent,
      primaryActionTitle: lang.yesCloseForm,
      primaryAction: hideModalPage,
      secondaryActionTitle: lang.noDontCloseForm,
    });
    // this.props.hideModalPage();
  };

  render() {
    let { user, appConfig, lang } = this.props;
    let { formData, submittingForm } = this.state;
    let marginBottom = 1;

    return (
      <ScrollView
        style={{ backgroundColor: colors.white, padding: "1rem", flexShrink: 1 }}
        className={componentStyles(this.props)}
      >
        {appConfig.hasUserSync && (
          <InformationBox
            icon={<InfoOutlineIcon />}
            style={{ marginBottom: "1.5rem" }}
            title={"Obs!"}
            description={lang.userSyncInfo}
          />
        )}
        <div className="user-header">
          {getProfilePicture(formData, 48)}
          <div style={{ paddingLeft: "1rem" }}>
            <h2>{user.name}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{getUserGroupTitle(user)}</p>

            {/* TODO: Currently we are not checking for baseURL too, but we should! Once every profile picture has one... */}
            {formData.profilePicture.image && (
              <p className="fake-link" onClick={() => this.setState({ formData: { ...formData, profilePicture: {} } })}>
                <TrashIcon /> {lang.deleteProfilePicture}
              </p>
            )}
          </div>
        </div>

        {/* TODO: Currently we are not checking for baseURL too, but we should! Once every profile picture has one... */}
        {!formData.profilePicture.image && (
          <Fragment>
            <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.profilePicture}</label>
            <ImageUploadSingle
              style={{ marginBottom: marginBottom + "rem" }}
              onFile={(file) =>
                this.setState({ formData: { ...formData, profilePicture: { image: file.file, baseURL: file.baseURL } } })
              }
            />
          </Fragment>
        )}

        <label style={{ marginBottom: marginBottom * 0.5 + "rem", display: "flex" }}>
          {appConfig.hasUserSync && <LockIcon />} {lang.phone}
        </label>
        <TextInput
          className="input-field"
          disabled={appConfig.hasUserSync}
          onChange={this.handleChange}
          name="phone"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.phone}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem", display: "flex" }}>
          {appConfig.hasUserSync && <LockIcon />}
          {lang.altPhone}
        </label>
        <TextInput
          className="input-field"
          disabled={appConfig.hasUserSync}
          onChange={this.handleChange}
          name="alternativePhone"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.alternativePhone}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem", display: "flex" }}>
          {appConfig.hasUserSync && <LockIcon />}
          {lang.email}
        </label>
        <TextInput
          className="input-field"
          disabled={appConfig.hasUserSync}
          onChange={this.handleChange}
          name="email"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.email}
          backgroundColor={colors.ultraLightGrey}
        />
        {"birthday" in user && (
          <>
            <label style={{ marginBottom: marginBottom * 0.5 + "rem", display: "flex" }}>{lang.birthday}</label>
            <TextInput
              type="date"
              className="input-field"
              onChange={this.handleChange}
              name="birthday"
              style={{ marginBottom: marginBottom + "rem" }}
              value={dayjs(formData.birthday).format("YYYY-MM-DD") || formData.birthday}
              backgroundColor={colors.ultraLightGrey}
            />
          </>
        )}

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.aboutMe}</label>
        <TextareaInput
          onChange={this.handleChange}
          name="about"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.about}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.myCompetencies}</label>
        <TextareaInput
          onChange={this.handleChange}
          name="skills"
          style={{ marginBottom: marginBottom * 2 + "rem" }}
          value={formData.skills}
          backgroundColor={colors.ultraLightGrey}
        />

        <Button
          buttonType="primary"
          primaryColor={appConfig.primaryColor}
          title={lang.saveChanges}
          style={{ marginBottom: marginBottom * 0.5 + "rem" }}
          active={submittingForm}
          onClick={this.submitForm}
        />

        <Button
          buttonType="secondary"
          title={lang.cancel}
          style={{ marginBottom: marginBottom + "rem" }}
          onClick={this.closeForm}
        />
      </ScrollView>
    );
  }
}

const componentStyles = (props) => css`
  /* All first level labels, input and textareas */
  & > input,
  & > textarea,
  & > label {
    display: block;
  }

  label {
    display: flex;
    align-items: center;

    svg {
      height: 1rem;
      width: 1rem;
      fill: var(--darkGrey);
      margin-right: 0.25rem;
    }
  }

  div.user-header {
    display: flex;
    margin-bottom: 1rem;

    p.fake-link {
      color: ${props.appConfig.primaryColor};
      svg {
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }

  .input-field[disabled] {
    color: ${colors.midDarkGrey};
    background-color: var(--white);
    padding-top: 0;
    padding-left: 0;
    border: none;
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserData: bindActionCreators(updateUserData, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileEditModal);
