// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { css } from "emotion";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import ActionWrapper from "../ui/ActionWrapper";
import StatusBox from "../ui/StatusBox";
import NavigationGridItem from "./NavigationGridItem";
import NavigationListItem from "./NavigationListItem";

// Utilities
import pageNavigatorV2, { pageTransitions } from "../../utilities/page-navigator-v2";
import markdownParser from "../../utilities/markdown-parser";
import iconConverter from "../../utilities/icon-converter";
import pageNavigator_v2 from "../../utilities/page-navigator-v2";

// Hooks
import useFilteredNavigationListPages from "./hooks/useFilteredNavigationListPages";
import useCurrentPage from "../../hooks/useCurrentPage";

// Style
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

// Icons
import { ArrowLeftIcon, FeatureSearchOutlineIcon, SearchIcon } from "mdi-react";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import SearchInput from "../ui/SearchInput";
import getAppName from "../../utilities/get-app-name";

function NavigationList(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const _page = useCurrentPage({ pageId: props.match.params.pageId });
  const page = useFilteredNavigationListPages(_page, searchTerm);

  const lang = useSelector((state) => state.language.language);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const [hasCoverImage, setHasCoverImage] = useState(
    !!(page.coverImage && page.coverImage.image && page.coverImage.baseURL)
  );

  useEffect(() => {
    if (page) {
      setHasCoverImage(!!(page.coverImage && page.coverImage.image && page.coverImage.baseURL));
    }
  }, [page]);

  // We only enable search if there's more than 10 menuitems in the list, and if the navigation type is indeed list and not grid.
  const enableSearch = _page?.pages?.length > 10 && _page.module === "navigationList";

  function getNavigationContent(maxWidth = false, color = primaryColor) {
    {
      /** NAVIGATION GRID **/
    }
    if (page.module === "navigationGrid") {
      return (
        <div className={gridStyle()}>
          {page?.pages?.length === 0 && enableSearch && (
            <StatusBox
              icon={<FeatureSearchOutlineIcon />}
              title={lang.noResults}
              content={lang.trySearchingForSomethingElse}
            />
          )}

          {page?.pages?.length > 0 &&
            page.pages.map((subPage) => {
              return <NavigationGridItem key={subPage.id} page={subPage} maxWidth={maxWidth} color={color} />;
            })}
        </div>
      );
    }

    {
      /** NAVIGATION LIST **/
    }
    return (
      <>
        {page?.pages?.length === 0 && enableSearch && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
          />
        )}

        {page?.pages?.length > 0 &&
          page?.pages?.map((subPage) => (
            <NavigationListItem key={subPage.id} page={subPage} maxWidth={maxWidth} color={color} />
          ))}

        {enableSearch && !hasCoverImage && (
          <FloatingSearchInput
            className="search-input"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            placeholder={lang.typeToFilter}
            onClearSearch={() => {
              setSearchTerm("");
            }}
          />
        )}
      </>
    );
  }

  // If the page has a description or a cover image, then return a navigationlist with cover image
  if (page.description || hasCoverImage) {
    return (
      <Page backgroundColor={colors.white} className={componentStyles({ primaryColor, coverImage: page.coverImage })}>
        <ActionWrapper className="floating-back-button" onClick={() => pageNavigatorV2({ mode: "pop" })}>
          <ArrowLeftIcon />
        </ActionWrapper>

        <ActionWrapper
          className="floating-back-button right"
          onClick={() => {
            pageNavigator_v2({ path: `/${getAppName()}/search/${page.id}`, direction: pageTransitions.modalPageUp });
          }}
        >
          <FeatureSearchOutlineIcon style={{ padding: "0.6rem" }} />
        </ActionWrapper>

        <ScrollView className="navigation-list-scroll-view">
          {hasCoverImage && <div className="cover-image"></div>}
          <div className={`content-container ${hasCoverImage ? "with-cover-image" : "without-cover-image"}`}>
            {enableSearch && (
              <SearchInput
                className="search-input"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder={lang.typeToFilter}
                onClearSearch={() => {
                  setSearchTerm("");
                }}
              />
            )}

            <div className="content-wrapper">
              <h2 className="with-cover-image-page-title">
                {page.icon && iconConverter(page.icon, { marginBottom: "-3px" })} {page.title}
              </h2>
              {page.description && (
                <div className="markdown-content" dangerouslySetInnerHTML={markdownParser(page.description)}></div>
              )}

              {(!page?.pages || (page?.pages && page?.pages?.length === 0)) && (
                <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
              )}
              {page?.pages && page?.pages?.length !== 0 && getNavigationContent(false, primaryColor)}
            </div>
          </div>
        </ScrollView>
      </Page>
    );
  }

  // Else return the normal navigation list
  else {
    return (
      <Page className={componentStyles({ primaryColor })}>
        <TopBar
          title={page.title}
          useDefaultBackButton={true}
          actionRight={
            <ActionWrapper
              onClick={() => {
                pageNavigator_v2({ path: `/${getAppName()}/search/${page.id}`, direction: pageTransitions.modalPageUp });
              }}
            >
              <FeatureSearchOutlineIcon />
            </ActionWrapper>
          }
        />
        <ScrollView style={{ padding: "1rem 0", paddingTop: enableSearch ? "4.35rem" : "1rem" }}>
          {(!_page.pages || (_page.pages && _page.pages.length === 0)) && (
            <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
          )}

          {_page?.pages?.length !== 0 && getNavigationContent(breakpoints.lg, primaryColor)}
        </ScrollView>
      </Page>
    );
  }
}

const IMAGE_HEIGHT = 175;
const CONTAINER_BORDER_RADIUS = 10;
const componentStyles = ({ primaryColor, coverImage = {} }) => css`
  .with-cover-image-page-title {
    color: ${primaryColor};
    margin-bottom: 0.65rem;
    font-size: 1.35rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .floating-back-button {
    svg {
      position: fixed;
      top: calc(1rem + env(safe-area-inset-top));
      left: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.35rem;
      border-radius: 50%;
      background-color: ${primaryColor};
      color: var(--white);
      z-index: 5;
      filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));
      /* box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2), 0 3px 20px rgba(0, 0, 0, 0.1); */

      &:hover {
        background-color: ${tinycolor(primaryColor).lighten(4).toString()};
      }

      &:active {
        background-color: ${tinycolor(primaryColor).darken(4).toString()};
      }
    }
    &.right svg {
      right: 1rem;
      left: initial;
    }
  }

  .cover-image {
    top: 0;
    width: 100%;
    position: absolute;
    height: ${IMAGE_HEIGHT + 50}px;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1000px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_1400/`}${coverImage.image});
    }
    @media screen and (min-width: 500px) and (max-width: 999px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_1000/`}${coverImage.image});
    }
    @media screen and (max-width: 499px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_500/`}${coverImage.image});
    }

    &:after {
      content: "";
      display: block;
      top: 100%;
      width: 100%;
      height: 50px;
      margin-top: ${IMAGE_HEIGHT}px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.7) 63%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 10000;
    }
  }

  .navigation-list-scroll-view {
    .content-container {
      position: relative;
      position: relative;
      width: 100%;
      padding: 1rem 1rem 4rem 1rem;
      background-color: var(--white);

      &.with-cover-image {
        margin-top: ${IMAGE_HEIGHT - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT - CONTAINER_BORDER_RADIUS}px);
        box-shadow: 0px -9px 11px 0px rgba(0, 0, 0, 0.06), 0px -24px 40px 0px rgba(0, 0, 0, 0.1);
        border-radius: ${CONTAINER_BORDER_RADIUS}px ${CONTAINER_BORDER_RADIUS}px 0 0;

        .search-input {
          background-color: var(--white);
          margin: 0 auto 1rem auto;
          padding-left: 2.65rem;
          padding-right: 2rem;
          border: solid 1px var(--lightGrey);
          max-width: 750px;
        }

        .list-item-container {
          border-left: 1px var(--midGrey) solid;
          border-right: 1px var(--midGrey) solid;

          &:first-of-type {
            border-radius: 4px 4px 0 0;
          }
          &:last-of-type {
            border-radius: 0 0 4px 4px;
          }
        }
      }

      &.without-cover-image {
        padding-top: 60px;
        min-height: 100vh;
      }

      .content-wrapper {
        max-width: ${breakpoints.md}px;
        margin-left: auto;
        margin-right: auto;
        margin-top: env(safe-area-inset-top);

        div.markdown-content {
          margin-bottom: 1.35rem;
          line-height: 1.5;
          color: var(--black);
          user-select: text;

          *::selection {
            background: ${primaryColor};
            color: var(--white);
            border-radius: 3px;
          }

          a {
            color: ${primaryColor};
            text-decoration: underline;
          }

          p {
            white-space: break-spaces;
          }

          p,
          ul,
          li {
            margin-bottom: 0.5rem;
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin-top: 1.5rem;
            margin-bottom: 0.65rem;
            &:first-child {
              margin-top: 0rem;
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 1.5rem;
          }

          ol {
            margin-left: 1.5rem;
          }
        }

        .list-item {
          :nth-child(3) {
            border-radius: 3px 3px 0 0;
          }
          &:last-of-type {
            border-radius: 0 0 3px 3px;
          }
        }
      }
    }

    @media screen and (max-width: ${breakpoints.xs}px) {
      .content-container.with-cover-image {
        margin-top: ${IMAGE_HEIGHT * 0.8 - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT * 0.8 - CONTAINER_BORDER_RADIUS}px);
      }
      .cover-image {
        height: ${IMAGE_HEIGHT * 0.8 + 50}px;
        &:after {
          margin-top: ${IMAGE_HEIGHT * 0.8}px;
        }
      }
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      .content-container.with-cover-image {
        margin-top: ${IMAGE_HEIGHT * 1.5 - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT * 1.5 - CONTAINER_BORDER_RADIUS}px);

        padding-top: 2rem;
      }
      .cover-image {
        height: ${IMAGE_HEIGHT * 1.5 + 50}px;
        &:after {
          margin-top: ${IMAGE_HEIGHT * 1.5}px;
        }
      }
      h2 {
        margin-bottom: 1rem;
        font-size: 1.85rem;
      }

      div.markdown-content {
        margin-bottom: 1.55rem;
      }
    }
  }
`;

const gridStyle = () => css`
  max-width: ${breakpoints.md}px;
  border: 1px solid var(--midGrey);
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
`;
export default NavigationList;
