/** Checks if container is scrollable
 * @param {HTMLElement} el - Element to check
 * @returns {Boolean}
 */
export default function (el) {
  if (el.clientHeight < el.scrollHeight) {
    return true;
  } else {
    return false;
  }
}
