import { createPortal } from "react-dom";
import { useState } from "react";
import {
  useHover,
  useDismiss,
  useRole,
  useInteractions,
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
} from "@floating-ui/react";
import { css } from "emotion";
import colors from "../../../../style/colors";

const CellForExtrasWithPopover = ({ value = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: "absolute",
    placement: "bottom",
  });

  const hover = useHover(context, { move: false });
  const role = useRole(context, { role: "tooltip" });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss, role]);

  return (
    <>
      <div className="popover-trigger" ref={refs.setReference} {...getReferenceProps()}>
        {value.length}
      </div>

      {createPortal(
        <>
          {isOpen && (
            <div
              className={popoverStyle}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              {value.map((answer) => (
                <div className={"popover__extras-wrapper"} key={`${answer?.value?.title}__${answer?.value?.id}`}>
                  <p>
                    <span className="label">Tillæg: </span>
                    {answer?.value?.title}
                  </p>
                  <p>
                    <span className="label">Antal: </span>
                    {answer?.value?.amount}
                  </p>
                  <p>
                    <span className="label">Kommentar: </span>
                    {answer?.value?.comment}
                  </p>
                  {answer?.isShared && (
                    <>
                      <p>
                        {" "}
                        <span className="label">Delt tillæg: </span> Ja
                      </p>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </>,
        document.body
      )}
    </>
  );
};

const popoverStyle = css`
  background-color: ${colors.white};
  border: 1px ${colors.midGrey} solid;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.05) 0px 8px 16px;

  .popover__extras-wrapper {
    border-bottom: 1px ${colors.midGrey} solid;
    padding: 0.15rem 0;

    &:last-of-type {
      border-bottom: 0px;
    }
    p {
      color: ${colors.black};
      padding: 0.35rem 0.65rem;
      span.label {
        color: ${colors.darkGrey};
      }
    }
    p:nth-child(even) {
      background-color: ${colors.ultraLightGrey};
    }
  }
`;

export default CellForExtrasWithPopover;
