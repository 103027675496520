function shouldShowEventButtons(event) {
  if (!event) return false;
  const { isAttending, attendingHasExpired, isAttendingAllowed, seatsAvailable, numberOfAttendees } = event;

  return (
    isAttending === null &&
    !attendingHasExpired &&
    isAttendingAllowed &&
    (!seatsAvailable || seatsAvailable > numberOfAttendees)
  );
}

export default shouldShowEventButtons;
