function getPagesPath({ pageId, pages }) {
  let pagePaths = [];

  for (let page of pages) {
    if (page.id === pageId) pagePaths = [page];

    if(Array.isArray(page.pages) && page.pages.length > 0) {
      let foundPageArray = getPagesPath({pageId, pages: page.pages});
      if(foundPageArray.length > 0) pagePaths = [page, ...foundPageArray]
    }
  }

  return pagePaths;
}

export default getPagesPath;
