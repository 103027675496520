function getChildPageIds({ parentPageId, pages }) {
  if (!pages || !parentPageId) return [];

  let childPageIds = [];

  for (let page of pages) {
    if (page.id === parentPageId || page.parentId === parentPageId) {
      childPageIds.push(page.id);

      if (Array.isArray(page.pages) && page.pages.length > 0) {
        childPageIds = [...childPageIds, ...getChildPageIds({ parentPageId: page.id, pages: page.pages })];
      }
    }

    if (Array.isArray(page.pages) && page.pages.length > 0) {
      childPageIds = [...childPageIds, ...getChildPageIds({ parentPageId, pages: page.pages })];
    }
  }

  return childPageIds;
}

export default getChildPageIds;
