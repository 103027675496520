// Libraries
import { useEffect } from "react";
import { css } from "emotion";
import { useState } from "react";
import { useSelector } from "react-redux";

// Utilities
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../../utilities/color-modifiers";

// Style
import colors from "../../../style/colors";

// Components
import { CheckboxBlankCircleOutlineIcon, CheckboxMarkedCircleIcon } from "mdi-react";

export const CheckboxPill = ({ label, onChange = () => {}, value = true }) => {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div className={style({ checked, primaryColor })} onClick={() => onChange(!value)}>
      <label htmlFor={`checkbox-${label}`}>
        {checked ? <CheckboxMarkedCircleIcon /> : <CheckboxBlankCircleOutlineIcon />}
        {label}
      </label>
      <input type="checkbox" defaultChecked={checked} />
    </div>
  );
};

const style = ({ checked, primaryColor }) => css`
  /* Apply very large border to get capsule shape */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid var(--midGrey);
  padding: 0.25rem 0.65rem 0.25rem 0.5rem;
  color: ${checked ? primaryColor : colors.midDarkGrey};
  cursor: pointer;

  &:hover {
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
  }

  &:active {
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: inherit;
    font-size: 0.8rem;
    font-weight: bold;

    svg {
      width: 0.85rem;
      height: 0.85rem;
      margin-right: 0.35rem;
    }
  }
`;
