// Libs
import React, { useEffect, useState } from "react";
import { PlusIcon } from "mdi-react";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ActionWrapper from "../ui/ActionWrapper";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import CheersForPeersPosts from "./CheersForPeersPosts";
import RegistrationFormBuilder from "../registration/RegistrationFormBuilder";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Config
import { feedTypes } from "../registration/config";

// Actions
import { getPostsStatistics, getRegistrationPosts, resetAll } from "../../actions/registrationActions";
import { showModalPage } from "../../actions/uiActions";
import CheersForPeersStatistics from "./CheersForPeersStatistics";

const CheersForPeersOverview = (props) => {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  let { dataId: registrationId } = queryString.parse(window.location.search);

  const { language: lang } = useSelector((state) => state.language);

  const tabs = [lang.forMe, lang.forOthers];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(resetAll());
  }, []);

  const getPostsByActiveTab = () => {
    if (activeTab === 0) dispatch(getRegistrationPosts({ registrationId, feedType: feedTypes.toMe }));
    if (activeTab === 1) dispatch(getRegistrationPosts({ registrationId, feedType: feedTypes.all }));
  };

  useEffect(() => {
    getPostsByActiveTab();
  }, [activeTab]);

  function refreshContent() {
    // Select feedType based on active tab
    let currentFeedType = [feedTypes.toMe, feedTypes.all][activeTab];

    dispatch(getRegistrationPosts({ registrationId, feedType: currentFeedType, offset: 0 }));
    dispatch(getPostsStatistics({ registrationId }));
  }

  function onCreateNewCheersClick() {
    dispatch(
      showModalPage({
        title: `${page.title}`,
        content: <RegistrationFormBuilder {...props} postSubmitCallback={refreshContent} />,
      })
    );
  }

  return (
    <Page>
      <TopBar
        title={page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper data-test-id="btn-show-add-post-modal" onClick={onCreateNewCheersClick}>
            <PlusIcon />
          </ActionWrapper>
        }
      />
      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      <TabView
        onScrollEnd={() => getPostsByActiveTab()}
        activeTabIndex={activeTab}
        tabs={[
          <>
            <CheersForPeersStatistics style={{ margin: "0.5rem auto 0.5rem auto" }} />
            <CheersForPeersPosts feedType={feedTypes.toMe} />
          </>,
          <>
            <CheersForPeersStatistics style={{ margin: "0.5rem auto 0.5rem auto" }} />
            <CheersForPeersPosts feedType={feedTypes.all} />,
          </>,
        ]}
      />
    </Page>
  );
};

export default CheersForPeersOverview;
