// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import RadioGroup from "../ui/RadioGroup";
import Button from "../ui/Button";
import Spinner from "../ui/InlineSpinner";

// Styles & config
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import ImageCarousel from "../ui/ImageCarousel";

// Utilities
import req from "../../utilities/request-utility";

// Actions
import { getActiveThemeQuizzes, hideThemeQuizFloatingActionButton } from "../../actions/themeQuizActions";
import { addToast } from "../../actions/uiActions";
import { AlertOctagonIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";

const ThemeQuizQuestion = (props) => {
  const dispatch = useDispatch();

  const { answers, title, images, id: questionId, quizId, campaignId } = props.question;
  const { language: lang } = useSelector((state) => state.language);

  // State
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [answerResult, setAnswerResult] = useState({});
  const [showResults, setShowResults] = useState(false);

  const [answerId, setAnswerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submitAnswer = async () => {
    try {
      setLoading(true);
      setSubmittingAnswer(true);
      let { data } = await req().post(`/quiz/${quizId}/answers`, { answerId, questionId, campaignId });
      setAnswerResult(data);
      setShowResults(true);
      setLoading(false);

      dispatch(getActiveThemeQuizzes());
      dispatch(hideThemeQuizFloatingActionButton());
    } catch (error) {
      setError(true);
      setSubmittingAnswer(false);
      setLoading(false);
      dispatch(
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "20000",
          title: lang.errorGeneral,
          content: lang.errorGeneral,
        })
      );
    }
  };

  return (
    <ScrollView className={componentStyle(props)}>
      {loading && !error && <Spinner title={lang.loading} />}
      {!loading && error && <StatusBox />}
      {!loading && !error && (
        <div className="question-container">
          {images.length > 0 && <ImageCarousel images={images} />}
          <p className="question">{title}</p>
          {!showResults && (
            <>
              <RadioGroup
                className="radio-group"
                options={answers}
                onSelect={(radioId) => {
                  setAnswerId(answers.find((a) => a.id === radioId).id);
                }}
              />
              <Button onClick={submitAnswer} active={submittingAnswer}>
                {lang.sendAnswer}
              </Button>
            </>
          )}

          {showResults && (
            <>
              <RadioGroupShowCorrectAnswers
                answerCorrectAction={() => {
                  dispatch(
                    addToast({
                      styleType: "success",
                      title: `${lang.congratulations}!`,
                      content: lang.youAnsweredCorrectly,
                      duration: 20000,
                    })
                  );
                }}
                answerWrongAction={() => {
                  dispatch(
                    addToast({
                      styleType: "error",
                      title: `${lang.unfortunately}...`,
                      content: lang.yourAnswerWasIncorrect,
                      duration: 20000,
                    })
                  );
                }}
                userAnswer={{ answerId: answerResult.quizAnswerId, isCorrect: answerResult.correct }}
                answers={answers}
              />
            </>
          )}
        </div>
      )}
    </ScrollView>
  );
};

const componentStyle = () => css`
  height: 100%;
  width: 100%;
  background-color: var(--white);
  padding: 1rem 1rem 5rem 1rem;

  .question-container {
    width: 100%;
    margin: 0 auto;
    max-width: ${breakpoints.md}px;

    p.question {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .radio-group {
      margin-top: 0.5rem;
    }

    button {
      margin-top: 2rem;
    }
  }
`;

export default ThemeQuizQuestion;
