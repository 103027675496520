// Libs
import React from "react";
import { useSelector } from "react-redux";
import { format, parse } from "date-fns";

// Utilities
import getUserLocale from "../utilities/get-user-locale";

const UseConvertDateFormat = ({ fromDateFormat = "yyyyMMdd", toDateFormat = "yyyy-MM-dd" } = {}) => {
  const { user } = useSelector((state) => state.auth);

  return (date) => {
    if (!date) return null;
    return format(parse(date, fromDateFormat, 0), toDateFormat, getUserLocale(user));
  };
};

export default UseConvertDateFormat;
