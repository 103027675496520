import { css } from "emotion";
import tinycolor from "tinycolor2";
import colors from "./colors";

const cssVariablesStyle = (primaryColor) => css`
  --primary-color: ${primaryColor};

  --primary-color_lighten-5: ${tinycolor(primaryColor).lighten(5).toString()};
  --primary-color_darken-5: ${tinycolor(primaryColor).darken(5).toString()};
  --primary-color_alpha-005: ${tinycolor(primaryColor).setAlpha(0.05).toString()};
  --primary-color_alpha-01: ${tinycolor(primaryColor).setAlpha(0.1).toString()};
  --primary-color_alpha-015: ${tinycolor(primaryColor).setAlpha(0.15).toString()};

  --green-color_lighten-5: ${tinycolor(colors.green).lighten(5).toString()};
  --green-color_darken-5: ${tinycolor(colors.green).darken(5).toString()};
  --green-color_alpha-005: ${tinycolor(colors.green).setAlpha(0.05).toString()};
  --green-color_alpha-01: ${tinycolor(colors.green).setAlpha(0.1).toString()};
  --green-color_alpha-015: ${tinycolor(colors.green).setAlpha(0.15).toString()};

  --red-color_lighten-5: ${tinycolor(colors.red).lighten(5).toString()};
  --red-color_darken-5: ${tinycolor(colors.red).darken(5).toString()};
  --red-color_alpha-005: ${tinycolor(colors.red).setAlpha(0.05).toString()};
  --red-color_alpha-01: ${tinycolor(colors.red).setAlpha(0.1).toString()};
  --red-color_alpha-015: ${tinycolor(colors.red).setAlpha(0.15).toString()};
`;

export default cssVariablesStyle;
