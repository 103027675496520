// Libs
import React from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ChristmasHighscoreMain from "./ChristmasHighscoreMain";
import ScrollView from "../ui/ScrollView";

const ChristmasHighscoreOverview = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={lang.christmasCalendarHighscore} />
      <ScrollView>
        <ChristmasHighscoreMain />
      </ScrollView>
    </Page>
  );
};

/* 
  17/10-21 I have commented this out as we currently arent gonna have two tabs, but just one top 10 for the whole company. 
  However Henrik said to not remove the code, as the customers might very well request a top 10 for each departmnet at some point.
  Currently the files ChristmasHighscoreDepartment.js and getMyDepartmentsChristmasHigscore.js is not in use.
*/

// Page (local) state
//   const [activeTab, setActiveTab] = useState(0);
//   const tabs = [lang.top10, lang.myDepartment];

//   return (
//     <Page>
//       <TopBar useDefaultBackButton={true} title={lang.christmasCalendarHighscore} />
//       <TabBar
//         activeTabIndex={activeTab}
//         tabs={tabs.map((tab, tabIndex) => ({
//           title: tab,
//           onClick: () => {
//             setActiveTab(tabIndex);
//           },
//         }))}
//       />
//       <TabView activeTabIndex={activeTab} tabs={[<ChristmasHighscoreMain />, <ChristmasHighscoreDepartment />]} />
//     </Page>
//   );
// };

export default ChristmasHighscoreOverview;
