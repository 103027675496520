// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Utilities
import iconConverter from "../utilities/icon-converter";

const UseIcon = ({iconName = ""}) => {

  const [icon, setIcon] = useState(iconConverter(iconName))
  const icons = useSelector(state => state.ui.icons)


  useEffect(() => {
    setIcon(iconConverter(iconName))
  }, [iconName, icons])

  return { icon };
};

export default UseIcon;