import React from "react";
import { useSelector } from "react-redux";
import RadioGroup from "../../../../ui/RadioGroup";

const HighScoreContent = ({ page, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.language);

  const [hideUserSection, setHideUserSection] = React.useState(page?.meta?.hideUserSection);
  const [hideUserGroupSection, setHideUserGroupSection] = React.useState(page?.meta?.hideUserGroupSection);

  const onHideUserSectionChangeHandler = (selectedId) => {
    setHideUserSection(!hideUserSection);
    onChangeHandler({
      target: { name: "meta", value: { ...page?.meta, hideUserSection: selectedId !== 1 } },
    });
  };

  const onHideUserGroupSectionChangeHandler = (selectedId) => {
    setHideUserGroupSection(!hideUserGroupSection);
    onChangeHandler({
      target: { name: "meta", value: { ...page?.meta, hideUserGroupSection: selectedId !== 1 } },
    });
  };

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{`${lang?.highscore} ${lang?.settings?.toLowerCase()}`}</p>
        <div className="field-wrapper" style={{ marginTop: "0.5rem" }}>
          <label className="title">{lang.shouldUserSectionBeVisible}</label>
          <RadioGroup
            className="radio-group-selector"
            onSelect={onHideUserSectionChangeHandler}
            selectedValue={hideUserSection ? 2 : 1}
            options={[
              {
                id: 1,
                title: lang.yes,
                value: false,
              },
              {
                id: 2,
                title: lang.no,
                value: true,
              },
            ]}
          />
        </div>
        <div className="field-wrapper">
          <label className="title">{lang.shouldUserGroupSectionBeVisible}</label>
          <RadioGroup
            className="radio-group-selector"
            onSelect={onHideUserGroupSectionChangeHandler}
            selectedValue={hideUserGroupSection ? 2 : 1}
            options={[
              {
                id: 1,
                title: lang.yes,
                value: false,
              },
              {
                id: 2,
                title: lang.no,
                value: true,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default HighScoreContent;
