// Libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import { format, parse } from "date-fns";
import tinycolor from "tinycolor2";
import { CSSTransition } from "react-transition-group";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import TimeRegistrationListItemSkeleton from "./components/TimeRegistrationListItemSkeleton";
import DataSelector from "../ui/dataSelector/DataSelector";
import StatusBox from "../ui/StatusBox";
import ActionWrapper from "../ui/ActionWrapper";
import ButtonRounded from "../ui/ButtonRounded";

// Config
import styleTypes from "../../config/styleTypes";
import summaryModes from "./config/summaryModes";
import { durations } from "../../config/animations";
import dataIdentifiers from "../../config/dataIdentifiers";

// Styles
import {
  ContentCopyIcon,
  PlusCircleOutlineIcon,
  InformationOutlineIcon,
  CloudOffOutlineIcon,
  AlertDecagramIcon,
} from "mdi-react";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import common from "../../style/common";
import basicAnimations from "../../style/basic-animations";
import sizes from "../../style/sizes";

// Utilities
import getUserLocale from "../../utilities/get-user-locale";
import getformattedHour from "../../utilities/get-formatted-hour";
import getStatusDependentIcon from "./utilities/getStatusDependentIcon";
import getDateInterval from "./utilities/getDateInterval";
import req from "../../utilities/request-utility";
import dayjs from "../../utilities/dayjs";
import getDatesArray from "./utilities/getDatesArray";
import capitalizeFirstLetter from "./utilities/capitalizeFirstLetter";
import dataServices from "./dataServices";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";
import useConvertDateFormat from "../../hooks/useConvertDateFormat";

// Actions
import { setSelectedUser, getPermissions } from "../../actions/timeRegistrationActions";
import { addToast } from "../../actions/uiActions";
import RejectedRegistrations from "./components/RejectedRegistrations";
import useHandleOverviewRegistrationClick from "./hooks/useHandleOverviewRegistrationClick";
import TotalCount from "./components/TotalCount";
import DateSelectionBar from "./components/DateSelectionBar";
import RegistrationApprovedWithChanges from "./components/RegistrationApprovedWithChanges";
import getRegistrationTitle from "./utilities/getRegistrationTitle";

const TimeRegistrationOverView = ({ adminMode = false, match }) => {
  // Hooks
  const dispatch = useDispatch();
  const convertDateFormat = useConvertDateFormat();
  const handleClickOnReg = useHandleOverviewRegistrationClick();
  const page = useCurrentPage({ pageId: match.params.pageId });

  // Redux state
  const { user } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { selectedUser } = useSelector((state) => state.timeRegistration);
  const { language: lang } = useSelector((state) => state.language);
  const isRegistrationFormLoading = useSelector((state) => state.registration.form.loading);
  const modalPagesState = useSelector((state) => state.ui.modalPages);

  // Local state
  const [loading, setLoading] = useState(true);
  const [fetchingRegistrations, setFetchingRegistrations] = useState(true);
  const [registrations, setRegistrations] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [copying, setCopying] = useState(false);
  const [copyingFrom, setCopyingFrom] = useState({});
  const [copyingTo, setCopyingTo] = useState({});
  const [summaryMode, setSummaryMode] = useState(summaryModes.HOURS);

  useEffect(() => {
    setInitialDateInterval();
    dispatch(setSelectedUser(""));
    dispatch(getPermissions({ timeRegistrationId: page.dataId }));
    setLoading(false);
    updateSummaryMode();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    getRegistrations();
    // eslint-disable-next-line
  }, [startDate, endDate, selectedUser]);

  useEffect(() => {
    if (!loading && (isRegistrationFormLoading === false || modalPagesState.length === 0)) {
      setTimeout(() => {
        getRegistrations();
      }, 500);
    }
    // eslint-disable-next-line
  }, [isRegistrationFormLoading, modalPagesState]);

  useEffect(() => {
    let totalSum = null;

    if (summaryMode === summaryModes.HOURS) {
      totalSum = registrations.reduce((prevValue, currentValue) => prevValue + currentValue.hours, 0);
      totalSum = getformattedHour(totalSum);
    }

    if (summaryMode === summaryModes.COUNT) {
      totalSum = registrations.reduce((prevValue, currentValue) => prevValue + currentValue.count, 0);
    }

    setTotalSum(totalSum);

    // eslint-disable-next-line
  }, [registrations]);

  const updateSummaryMode = () => {
    if (!page.config || (page.config && !page.config.SUMMARY_MODE)) return;
    setSummaryMode(page.config.SUMMARY_MODE);
  };

  const setInitialDateInterval = () => {
    const { startDate, endDate } = getDateInterval({ locale: getUserLocale(user), format: "yyyyMMdd", config: page.config });
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const isDateRangeValid = (startDate, endDate) => {
    // Check that max-days aren't exceeded
    let localStartDate = startDate.replace(/-/gi, "");
    let localEndDate = endDate.replace(/-/gi, "");

    if (localStartDate > localEndDate) {
      dispatch(
        addToast({
          styleType: styleTypes.neutral,
          title: "Ugyldigt dato-valg",
          content: "Start-datoen skal være før slut-datoen",
          icon: <AlertDecagramIcon />,
        })
      );
      return false;
    }

    if (dayjs(localEndDate, "YYYYMMDD").diff(dayjs(localStartDate, "YYYYMMDD"), "days") > 100) {
      dispatch(
        addToast({
          styleType: styleTypes.neutral,
          title: "Ugyldigt dato-valg",
          content: "Der kan max vises 100 dage på en gang",
          icon: <AlertDecagramIcon />,
        })
      );
      return false;
    }

    // no issues found with date-range
    return true;
  };

  async function getRegistrations() {
    try {
      if (!isDateRangeValid(startDate, endDate)) return;

      const datesArray = getDatesArray(startDate, endDate, getUserLocale(user));
      const initialData = {
        date: "",
        count: 0,
        hours: 0,
        formattedHour: "0:00",
        approved: false,
        rejected: false,
      };

      // maps through the array of dates and returns an array of objects - one object for each date with the initial data.
      const placeholderRegistrations = datesArray.map((date) => ({ ...initialData, date }));

      // Sets the registrations to this initial array, so we can show as much data as possible, while fetching potential saved data from the db
      setFetchingRegistrations(true);
      setRegistrations(placeholderRegistrations);

      const registrations = await dataServices.getRegistrationsForOverview({
        startDate,
        endDate,
        page,
        placeholderRegistrations,
      });

      setRegistrations(registrations);

      setFetchingRegistrations(false);
    } catch (err) {
      console.log(err);
      dispatch(
        addToast({
          styleType: styleTypes.error,
          title: "Kunne ikke hente data",
          content: "Vi kan ikke hente data lige nu. Vær opmærksom på at dine registreringer ikke vil fremgå af oversigten!",
          duration: "20000",
          icon: <CloudOffOutlineIcon />,
        })
      );
      setFetchingRegistrations(false);
    }
  }

  const handleCopy = (e, registration) => {
    // This is to stop the onClickEvent bubbling up to the listItem, when the copy icon is clicked.
    e.stopPropagation();
    setCopying(true);
    setCopyingFrom(registration);
  };

  const handleCopyToDate = (e, registration) => {
    e.stopPropagation();
    setCopyingTo(registration);
  };

  const resetCopying = () => {
    setCopying(false);
    setCopyingFrom({});
    setCopyingTo({});
  };

  const showCantCopyReason = (e) => {
    e.stopPropagation();
    dispatch(
      addToast({
        title: "For mange registreringer",
        content: "Der er mere end 8 registreringer på denne dag. Der kan kun kopieres fra dage med max 8 registreringer.",
        styleType: styleTypes.neutral,
        duration: 8000,
      })
    );
  };

  const getRightContent = (registration) => {
    return (
      <div className="content-right-container">
        {/*
          All these components are wrapped in a relative parent and positioned absolute so they can be animated in
          and out on top of each other without shifting around.
        */}
        <div className="icon-toggle-container">
          {/* Copying to date icon */}
          <CSSTransition
            in={copying && copyingTo.date !== registration.date && registration.date !== copyingFrom.date}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={basicAnimations.fadeAndScaleDown}
          >
            <ActionWrapper className="list-item-copy-button" onClick={(e) => handleCopyToDate(e, registration)}>
              <PlusCircleOutlineIcon color={colors.midDarkGrey} />{" "}
            </ActionWrapper>
          </CSSTransition>

          {/* Copying to date */}
          <CSSTransition
            in={!copying && registration.count > 0 && registration.count <= 8}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={basicAnimations.fadeAndScaleDown}
          >
            <ActionWrapper className="list-item-copy-button" onClick={(e) => handleCopy(e, registration)}>
              <ContentCopyIcon color={colors.midDarkGrey} />
            </ActionWrapper>
          </CSSTransition>

          {/* To many registrations to copy */}
          <CSSTransition
            in={!copying && registration.count > 8}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={basicAnimations.fadeAndScaleDown}
          >
            <ActionWrapper className="list-item-copy-button" onClick={(e) => showCantCopyReason(e)}>
              <InformationOutlineIcon color={colors.midDarkGrey} />
            </ActionWrapper>
          </CSSTransition>

          {/* Confirm copy content */}
          {copying && copyingTo.date === registration.date && (
            <div style={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <ButtonRounded
                styleType={styleTypes.neutral}
                size={sizes.small}
                onClick={(e) => {
                  e.stopPropagation();
                  resetCopying();
                }}
              >
                Annuller
              </ButtonRounded>
              <ButtonRounded
                style={{ whiteSpace: "nowrap", marginLeft: "0.5rem" }}
                styleType={styleTypes.ok}
                size={sizes.small}
                onClick={(e) => {
                  e.stopPropagation();
                  copyRegistration(registration.date);
                }}
              >
                Bekræft kopiering
              </ButtonRounded>
            </div>
          )}
        </div>

        {/* Summary applies to all scenarios */}
        <p>{summaryMode === summaryModes.HOURS ? getformattedHour(registration.hours) : registration.count}</p>
      </div>
    );
  };

  const copyRegistration = async (date) => {
    try {
      let URL = `time-registrations/${page.dataId}/time-sheets/${date}/copy?fromDate=${copyingFrom.date}`;

      if (selectedUser) URL += `&selectedUserId=${selectedUser.id}`;

      setFetchingRegistrations(true);
      resetCopying();

      let { data: updatedRegistrations } = await req().post(URL);

      setFetchingRegistrations(false);
      if (!updatedRegistrations) return;

      setRegistrations((registrations) =>
        registrations.map((registration) => {
          const updatedRegistration = updatedRegistrations.find(
            (updatedRegistration) => updatedRegistration.date === registration.date
          );

          if (updatedRegistration) return updatedRegistration;
          return registration;
        })
      );
    } catch (error) {
      if (error.response.status === 408) {
        dispatch(
          addToast({
            styleType: styleTypes.error,
            title: "Det tog for lang tid at kopiere indholdet",
            content: "Kopiering bliver nødt til at blive foretaget manuelt",
            duration: "20000",
            icon: <CloudOffOutlineIcon />,
          })
        );
      } else {
        dispatch(
          addToast({
            styleType: styleTypes.error,
            title: "Kunne ikke kopier",
            content: "Noget gik galt, da vi prøvede at kopier indholdet",
            duration: "20000",
            icon: <CloudOffOutlineIcon />,
          })
        );
      }
      resetCopying();
      setFetchingRegistrations(false);
    }
  };

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={lang.timeRegistration} />
      <ScrollView className={componentStyle(primaryColor)}>
        {/** Loading **/}
        {loading && (
          <div className="spinner-container">
            <InlineSpinner />
          </div>
        )}

        <div className="outer-container">
          {/* If you see this page in admin mode, show the select user (DataSelector) component */}
          {adminMode && (
            <div className="user-selector-container">
              <DataSelector
                className="user-selector"
                dataIdentifier={dataIdentifiers.CURRENT_APP_USERS}
                onSelect={(option) => dispatch(setSelectedUser(option))}
                onRemove={() => {
                  dispatch(setSelectedUser(""));
                  setRejectedRegistrations([]);
                }}
              />
            </div>
          )}

          <DateSelectionBar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

          <RejectedRegistrations adminMode={adminMode} page={page} />

          <RegistrationApprovedWithChanges adminMode={adminMode} page={page} />

          {!(adminMode && !selectedUser) && <TotalCount fetchingRegistrations={fetchingRegistrations} totalSum={totalSum} />}

          {/* If you see this page in admin mode and you havn't selected a user: show a status box to prompt the admin to select a user 
          - otherwise if you either are not in admin mode or have selected a user, show the relevant hours */}
          {adminMode && !selectedUser && (
            <StatusBox
              style={{ marginTop: "3rem" }}
              icon={<InformationOutlineIcon />}
              title="Vælg en bruger"
              content="Vælg en bruger fra menuen i toppen for at se timer her"
            />
          )}

          {(!adminMode || selectedUser) && (
            <div className="registrations-container">
              {fetchingRegistrations &&
                registrations.map((registration, index) => (
                  <TimeRegistrationListItemSkeleton
                    key={registration.date}
                    className={"list-item"}
                    index={index}
                    title={getRegistrationTitle(registration)}
                  />
                ))}

              {!fetchingRegistrations &&
                registrations.map((registration) => (
                  <ListItem
                    key={registration.date}
                    className={`list-item ${copying && registration.date === copyingFrom.date ? "disabled" : ""}`}
                    iconLeft={getStatusDependentIcon(registration)}
                    title={getRegistrationTitle(registration)}
                    contentRight={getRightContent(registration)}
                    onClick={() => (!copying ? handleClickOnReg({ registration }) : null)}
                  />
                ))}

              {/* Copy header */}
              <CSSTransition
                in={copying}
                timeout={durations.normal}
                mountOnEnter={true}
                unmountOnExit={true}
                classNames="copy-header"
              >
                <div className="copying-information-container">
                  <div className="icon-text-container">
                    <ContentCopyIcon />
                    <p>{`Kopier fra ${convertDateFormat(copyingFrom.date)}`}</p>
                  </div>
                  <p className="content">Tryk på den dag, du vil kopiere timerne til</p>
                  <button onClick={() => resetCopying()}>Annuller</button>
                </div>
              </CSSTransition>
            </div>
          )}

          {/* If admin mode an no user dont show */}
          {!(adminMode && !selectedUser) && <TotalCount fetchingRegistrations={fetchingRegistrations} totalSum={totalSum} />}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = (primaryColor) => css`
  height: 100%;
  width: 100%;
  background-color: var(--lightGrey);

  /* Animation */
  .copy-header-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  .copy-header-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.fast}ms ease;
  }
  .copy-header-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  .copy-header-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity ${durations.slow}ms ease, transform ${durations.normal}ms ease;
  }

  .copying-information-container {
    background-color: ${primaryColor};
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 0.7rem 1rem 1.1rem 1rem;
    position: fixed;
    left: 0;
    top: ${common.topBarHeight}px;

    .icon-text-container {
      display: flex;
      align-items: baseline;

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.35rem;
      }

      p {
        font-weight: 600;
      }
    }

    p.content {
      font-size: 0.9rem;
      opacity: 0.8;
      margin-bottom: 0.35rem;
    }

    button {
      line-height: 2rem;
      border-radius: 1rem;
      color: var(--white);
      font-size: 0.9rem;
      font-weight: 700;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0px;
      padding: 0rem 0.8rem;
      cursor: pointer;
    }
  }
  div.outer-container {
    max-width: ${breakpoints.md}px;
    margin: auto;
    .user-selector-container {
      background-color: var(--white);
      .user-selector {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
    }
  }

  .selected-period-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    align-items: baseline;
    background-color: var(--white);
    border: 1px solid var(--midGrey);
    padding: 1rem;

    .date-container {
      display: flex;
      align-items: baseline;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      margin-left: -15px;
      cursor: pointer;
    }

    .date-picker {
      border: none;
      border-bottom: 1px dotted var(--midGrey);
      width: fit-content;
      background: transparent;
      display: block;
      margin: 0 5px;
      color: var(--darkGrey);
      font-family: ${common.fontStack};
      font-size: 1rem;
      outline: 0;
      line-height: 1.5rem;
    }
  }

  .rejected-registrations-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 1rem 0;

    p.headline {
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem 0.5rem;
    }
  }

  .total-sum-container {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    .count {
      font-weight: 600;
    }
    .fake-count {
      opacity: 0.2;
      margin-left: 1rem;
      background-color: var(--darkGrey);
      border-radius: 12px;
      width: 12px;
      height: 22px;
      animation: blink 1.8s infinite;
    }
  }

  .overlay-container {
    position: relative;
  }

  .list-item {
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid var(--midGrey);
    padding: 0;
    height: 63px;

    &.approved {
      border-left: 3px solid ${colors.green};
    }

    &.rejected {
      border-left: 3px solid var(--red);
      background-color: ${tinycolor(colors.redLight).lighten(0.8).toString()};
    }

    .icon-left {
      padding: 1rem 0.5rem 1rem 1rem;
      .empty-icon {
        width: 24px;
      }
    }

    .content {
      .date {
        color: var(--darkGrey);
        padding: 0rem 0.5rem 0rem 1rem;
      }
    }

    .content-right-container {
      display: flex;
      align-items: center;

      .icon-toggle-container {
        width: 100%;
        height: 100%;
        position: relative;
        margin-left: -3rem; // prevents buttons to overlap with content on small devices < 370px
        min-width: 2.5rem;
        min-height: 2.5rem;
        background-color: #fff;
        box-shadow: -17px 0px 12px -6px #fff; // puts a white gradient over text to the left

        .list-item-copy-button {
          position: absolute;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          top: 0;
          left: 0;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }

          &:after {
            position: absolute;
            display: inline-block;
            content: "";
            height: 20px;
            width: 1px;
            background-color: var(--lightGrey);
            right: -0.4rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      p {
        font-weight: 600;
        min-width: 4.5rem;
        text-align: center;
      }
    }

    p {
      line-height: 3.75rem;
    }

    &.disabled {
      pointer-events: none;

      svg,
      p {
        opacity: 0.2;
        transition: opacity 200ms ease;
      }
    }
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export default TimeRegistrationOverView;
