// Libs
import React from "react";

// ActionTypes
import {
  END_OF_REGISTRATIONS_POSTS,
  GET_REGISTRATION_FORM_FAILURE,
  GET_REGISTRATION_FORM_SUCCESS,
  GET_REGISTRATION_POSTS,
  GET_REGISTRATION_POSTS_FAILURE,
  GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
  GET_REGISTRATION_POSTS_SUCCESS,
  LIKE_REGISTRATION_POST,
  LIKE_REGISTRATION_POST_FAILURE,
  READ_REGISTRATION_POST,
  READ_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
  REFETCH_REGISTRATION_POST_SUCCESS,
  RESET_REGISTRATION,
  RESET_REGISTRATION_FEED,
  RESET_REGISTRATION_FORM,
  SUBMIT_REGISTRATION_FORM_FAILURE,
  SUBMIT_REGISTRATION_FORM_STARTED,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  SUBMIT_UPDATED_REGISTRATION_FORM_FAILURE,
  SUBMIT_UPDATED_REGISTRATION_FORM_STARTED,
  SUBMIT_UPDATED_REGISTRATION_FORM_SUCCESS,
  UNLIKE_REGISTRATION_POST,
  UNLIKE_REGISTRATION_POST_FAILURE,
  UPDATE_REGISTRATION_FORM,
  GET_REGISTRATION_CONFIG_SUCCESS,
  GET_REGISTRATION_CONFIG_FAILURE,
  UPDATE_REGISTRATION_FORM_ACTIVE_REQUEST_HANDLERS,
  GET_REGISTRATION_TABS_SUCCESS,
  GET_REGISTRATION_TABS_FAILURE,
  SET_CURRENT_TAB,
  RESET_REGISTRATION_TAB_FEED,
  GET_REGISTRATION_TABS_POSTS,
  GET_REGISTRATION_TABS_POSTS_SUCCESS,
  GET_REGISTRATION_TABS_POSTS_FAILURE,
  END_OF_REGISTRATIONS_TABS_POSTS,
  REFETCH_REGISTRATION_TABS_POST_SUCCESS,
  REFETCH_REGISTRATION_TABS_POST_FAILURE,
  UPDATE_REGISTRATION_QUESTION_ANSWER,
  UPDATE_REGISTRATION_QUESTION_ANSWER_SUCCESS,
  UPDATE_REGISTRATION_QUESTION_ANSWER_FAILURE,
  UPDATE_REGISTRATION_ADMIN_REPLY_QUESTION_EDIT,
  DEACTIVATE_REGISTRATION_POST_SUCCESS,
} from "./actionTypes";

// Utilities & config
import req from "../utilities/request-utility";
import getNotValidRegistrationQuestionId from "../utilities/get-not-valid-registration-question-ids";
import { feedTypes } from "../components/registration/config";

// Actions
import { addToast } from "./uiActions";

// Styles
import { ErrorOutlineIcon, TrashCircleIcon, CheckDecagramIcon } from "mdi-react";
import { getPages } from "./pagesActions";

import styleTypes from "../config/styleTypes";

/** Fetches posts from api.
 *  Appends posts onto already existing posts.
 *  Use clearFeed() to empty feed.
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {String} feedType
 * @param {Integer} limit
 * @param {Integer} offset
 */
export function getRegistrationPosts({
  registrationId,
  feedType = feedTypes.all,
  searchTerm = "",
  limit = 5,
  offset = null,
  postId = null,
}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().registration[feedType];
      if (state.posts.length === 0) limit = 2;

      if (offset === null) offset = state.posts.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_REGISTRATION_POSTS, payload: { feedType } });

      if (postId == null) {
        await getRegistrationPostForFeed();
      } else {
        await getRegistrationPostSingle();
      }

      async function getRegistrationPostForFeed() {
        let URL;

        if (feedType === feedTypes.all) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&searchTerm=${searchTerm}&offset=${offset}`;
        }
        if (feedType === feedTypes.mostLiked) {
          URL = `registration/${registrationId}/posts-most-liked/?limit=${limit}&searchTerm=${searchTerm}&offset=${offset}`;
        }
        if (feedType === feedTypes.mine) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&searchTerm=${searchTerm}&offset=${offset}&showMyPostsOnly=1`;
        }
        if (feedType === feedTypes.toMe) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&offset=${offset}&showPostsToMeOnly=1`;
        }

        const { data } = await req()(URL);

        handleFetchedPosts(data);
      }

      async function getRegistrationPostSingle() {
        const { data: post } = await req()(`registration/${registrationId}/posts/${postId}`);
        handleFetchedPosts([post]);
      }

      function handleFetchedPosts(posts) {
        if (posts.length) {
          dispatch({
            type: GET_REGISTRATION_POSTS_SUCCESS,
            payload: { posts, feedType },
          });
        } else {
          dispatch({
            type: END_OF_REGISTRATIONS_POSTS,
            payload: { feedType },
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_REGISTRATION_POSTS_FAILURE, payload: { feedType } });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetPosts,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Marks the post as read.
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {string} feedType
 */
export function readRegistrationPost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: READ_REGISTRATION_POST,
        payload: postId,
      });

      await req().put(`registration/${registrationId}/posts/${postId}/read`);

      if (feedType === feedTypes.all) {
        refreshRegistrationPost({ registrationId, postId, feedType })(dispatch, getState);
      }

      if (feedType === feedTypes.processflow) {
        refetchRegistrationTabPost({
          registrationId,
          tab: getState().registration[feedType].currentTab.tab,
          postId,
          feedType,
        })(dispatch, getState);
      }

      // Update pages so notification count goes down when you go back
      getPages()(dispatch, getState);
    } catch (err) {
      dispatch({
        type: READ_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Fetches and update the newest version of the post and updates it in the posts array
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {string} feedType
 */
export function refreshRegistrationPost({ registrationId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: post } = await req()(`registration/${registrationId}/posts/${postId}`);

      dispatch({
        type: REFETCH_REGISTRATION_POST_SUCCESS,
        payload: { post, feedType },
      });
    } catch (err) {
      dispatch({
        type: REFETCH_REGISTRATION_POST_FAILURE,
        payload: { feedType },
      });
    }
  };
}

/**
 * Gets the updated versions of likes for the given registration post.
 * Is used for when like/unlike of a registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function refreshRegistrationPostLikes({ registrationId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: likes } = await req()(`registration/${registrationId}/posts/${postId}/likes`);
      dispatch({
        type: REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
        payload: { likes, feedType, postId },
      });
    } catch (err) {
      dispatch({
        type: REFETCH_REGISTRATION_POST_LIKES_FAILURE,
        payload: { feedType },
      });
    }
  };
}

/**
 * Resets the specified anniversaries feedType
 * @param {String} feedType
 */
export function resetFeed(feedType) {
  return function (dispatch) {
    dispatch({
      type: RESET_REGISTRATION_FEED,
      payload: feedType,
    });
  };
}

/**
 * Resets the registration feed - this include all feedTypes
 */
export function resetAll() {
  return function (dispatch) {
    dispatch({
      type: RESET_REGISTRATION,
    });
  };
}

/**
 * Removes the like from the registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function unlikePost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: UNLIKE_REGISTRATION_POST,
        payload: postId,
      });

      await req().delete(`registration/${registrationId}/posts/${postId}/like`);

      refreshRegistrationPostLikes({ registrationId, postId, feedType })(dispatch, getState);

      if (feedType === feedTypes.all) {
        resetFeed(feedTypes.mostLiked)(dispatch, getState);
        getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked })(dispatch, getState);
      }
    } catch (error) {
      dispatch({
        type: UNLIKE_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Adds a like to the registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function likePost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: LIKE_REGISTRATION_POST,
        payload: postId,
      });

      await req().put(`registration/${registrationId}/posts/${postId}/like`);

      refreshRegistrationPostLikes({ registrationId, postId, feedType })(dispatch, getState);

      if (feedType === feedTypes.all) {
        resetFeed(feedTypes.mostLiked)(dispatch, getState);
        getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked })(dispatch, getState);
      }
    } catch (error) {
      const { language: lang } = getState().language;
      dispatch({
        type: LIKE_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function getPostsStatistics({ registrationId }) {
  return async function (dispatch, getState) {
    try {
      const { data: statistics } = await req()(`registration/${registrationId}/posts/statistics`);

      if (!statistics) return;

      dispatch({
        type: GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
        payload: statistics,
      });
    } catch (error) {
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotFetchStatistics,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function updateRegistrationForm({ value, questionIndex, categoryIndex }) {
  return async function (dispatch, getState) {
    try {
      let questions = "questions";
      let answer = "answer";

      let registration = getState().registration.form.registration;

      registration = {
        ...registration,
        categories: [
          ...registration.categories.map((category, cIndex) =>
            categoryIndex === cIndex
              ? {
                  ...category,
                  [questions]: [
                    ...category.questions.map((question, qIndex) =>
                      qIndex === questionIndex ? { ...question, [answer]: value } : question
                    ),
                  ],
                }
              : category
          ),
        ],
      };

      let notValidQuestionIds = getNotValidRegistrationQuestionId(registration);

      dispatch({
        type: UPDATE_REGISTRATION_FORM,
        payload: { registration, notValidQuestionIds },
      });
    } catch (error) {
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function getRegistrationForm({ registrationId, postId = null }) {
  return async function (dispatch) {
    try {
      let URL = `registration/${registrationId}`;

      if (postId) URL += `?postId=${postId}`;

      const { data: registration } = await req()(URL);

      let notValidQuestionIds = getNotValidRegistrationQuestionId(registration);

      dispatch({
        type: GET_REGISTRATION_FORM_SUCCESS,
        payload: {
          registration,
          notValidQuestionIds,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_REGISTRATION_FORM_FAILURE,
      });
    }
  };
}

export function submitRegistrationForm({ callback = null, notValidCallBack = null }) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const registration = state.registration.form.registration;
      const timeRegistration = state.timeRegistration;

      let notValidQuestionIds = getNotValidRegistrationQuestionId(registration);

      // Check if all necessary questions has been answered (validation)
      if (notValidQuestionIds.length > 0) {
        if (notValidCallBack) return notValidCallBack();
        const { language: lang } = getState().language;
        return dispatch(
          addToast({
            title: lang.missingFields,
            content: lang.fillAllFields,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
      }

      dispatch({ type: SUBMIT_REGISTRATION_FORM_STARTED });

      // Construct array of query param sets (`someKey=someValue`). In the end they will be joined by `&`
      let potentialQueryParams = [];
      if (timeRegistration.selectedUser) potentialQueryParams.push(`selectedUserId=${timeRegistration.selectedUser.id}`);
      if (timeRegistration.selectedDate) potentialQueryParams.push(`date=${timeRegistration.selectedDate}`);
      // Worst case they will end up as `?` for no query params, which is perfectly fine http wise
      potentialQueryParams = `?${potentialQueryParams.join("&")}`;

      await req().post(`registration/${registration.id}/posts${potentialQueryParams}`, { fields: registration.categories });

      if (callback) callback();

      dispatch({
        type: SUBMIT_REGISTRATION_FORM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SUBMIT_REGISTRATION_FORM_FAILURE,
      });
    }
  };
}

export function submitUpdatedRegistrationForm({ postId, callback = null, notValidCallBack = null }) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const registration = state.registration.form.registration;
      const timeRegistration = state.timeRegistration;

      let notValidQuestionIds = getNotValidRegistrationQuestionId(registration);

      // Check if all necessary questions has been answered (validation)
      if (notValidQuestionIds.length > 0) {
        if (notValidCallBack) return notValidCallBack();
        const { language: lang } = getState().language;
        return dispatch(
          addToast({
            title: lang.missingFields,
            content: lang.fillAllFields,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
      }

      dispatch({ type: SUBMIT_UPDATED_REGISTRATION_FORM_STARTED });

      let queryParams = "";
      if (timeRegistration.selectedUser) queryParams = `?selectedUserId=${timeRegistration.selectedUser.id}`;

      let URL = `registration/${registration.id}/posts/${postId}${queryParams}`;

      await req().put(URL, { fields: registration.categories });

      if (callback) callback();

      dispatch({
        type: SUBMIT_UPDATED_REGISTRATION_FORM_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: SUBMIT_UPDATED_REGISTRATION_FORM_FAILURE });
    }
  };
}

export function resetRegistrationForm() {
  return function (dispatch) {
    dispatch({ type: RESET_REGISTRATION_FORM });
  };
}

/**
 * When a dropdown is changed it will cause a semi-rapid flicker in the submit button
 * as it goes from active -> disabled -> active.
 * To prevent this i am debouncing dispatches for 400ms so we wont actually dispatch anything
 * before there has been inactivity for 400ms. If a request has finished within 400ms it will
 * therefor not trigger this flickering
 */
let timeout;
let queue = [];
export function updateActiveRequestHandlers(number) {
  return function (dispatch) {
    clearTimeout(timeout);
    queue.push(number); // Add number to queue

    // Start timeout. If its runs out before being invoked again it will execute
    timeout = setTimeout(() => {
      // Run through queue and dispatch an item while removing it from the array.
      // And yes i am actually mutating the array with queue.shift()
      // Functional Programming-wise not best practice 🤷‍♂️
      while (queue.length) {
        dispatch({ type: UPDATE_REGISTRATION_FORM_ACTIVE_REQUEST_HANDLERS, payload: queue.shift() });
      }
    }, 400);
  };
}

export function getRegistrationConfiguration(registrationId) {
  return async function (dispatch) {
    try {
      const { data: registrationConfig } = await req()(`registration/${registrationId}/config`);

      if (registrationConfig) {
        dispatch({
          type: GET_REGISTRATION_CONFIG_SUCCESS,
          payload: registrationConfig,
        });
      }
    } catch (error) {
      dispatch({ type: GET_REGISTRATION_CONFIG_FAILURE });
    }
  };
}

export function deactivatePost({ postId, registrationId, feedType }) {
  return async function (dispatch, getState) {
    const lang = getState().language.language;

    await req()
      .put(`registration/${registrationId}/posts/${postId}/deactivate`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            addToast({
              title: lang.yourPostIsDeactivated,
              icon: <CheckDecagramIcon />,
              styleType: styleTypes.ok,
              duration: 5000,
            })
          );

          dispatch({ type: DEACTIVATE_REGISTRATION_POST_SUCCESS, payload: { feedType: feedType, postId: postId } });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          dispatch(addToast({ template: "accessDenied" }));
        } else {
          dispatch(addToast({ template: "error" }));
        }
      });
  };
}

export function getRegistrationTabs(registrationId) {
  return async function (dispatch) {
    try {
      const { data: registrationsTabs } = await req()(`registration/${registrationId}/tabs`);

      if (registrationsTabs) {
        dispatch({ type: GET_REGISTRATION_TABS_SUCCESS, payload: registrationsTabs });
      }
    } catch {
      dispatch({
        type: GET_REGISTRATION_TABS_FAILURE,
        payload: {},
      });
    }
  };
}

export function getRegistrationTabPosts({
  registrationId,
  feedType = feedTypes.processflow,
  tab = null,
  searchTerm = "",
  limit = 5,
  offset = null,
}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().registration[feedType];
      let posts = state.tabs[tab].posts;

      if (posts.length === 0) limit = 2;

      if (offset === null) offset = posts.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_REGISTRATION_TABS_POSTS, payload: { feedType } });

      if (feedType !== feedTypes.processflow || !tab) return;

      let URL = `registration/${registrationId}/posts/?limit=${limit}&searchTerm=${searchTerm}&offset=${offset}&tab=${tab}`;

      const { data } = await req()(URL);

      handleFetchedPosts(data);

      function handleFetchedPosts(posts) {
        if (posts.length) {
          dispatch({
            type: GET_REGISTRATION_TABS_POSTS_SUCCESS,
            payload: { posts, feedType, tab: tab },
          });
        } else {
          dispatch({
            type: END_OF_REGISTRATIONS_TABS_POSTS,
            payload: { feedType },
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_REGISTRATION_TABS_POSTS_FAILURE, payload: { feedType } });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetPosts,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function setCurrentTab(tab, title, index) {
  return function (dispatch) {
    dispatch({
      type: SET_CURRENT_TAB,
      payload: { tab, title, index },
    });
  };
}

export function resetTabFeed(tab) {
  return function (dispatch) {
    dispatch({
      type: RESET_REGISTRATION_TAB_FEED,
      payload: tab,
    });
  };
}

export function refetchRegistrationTabPost({ registrationId, tab = null, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      tab = tab ? tab : Object.keys(getState().registration[feedType].tabs)[0];

      let { data: post } = await req()(`registration/${registrationId}/posts/${postId}?tab=${tab}`);

      dispatch({
        type: REFETCH_REGISTRATION_TABS_POST_SUCCESS,
        payload: { post, feedType, tab },
      });
    } catch (err) {
      dispatch({
        type: REFETCH_REGISTRATION_POST_FAILURE,
        payload: feedType,
      });
    }
  };
}
