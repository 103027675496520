// Libs
import React, { useRef, useEffect } from "react";
import { css } from "emotion";
import { lock, unlock } from "tua-body-scroll-lock";

const ReverseScrollView = (props) => {
  const targetRef = useRef(null);

  function onScroll(e) {
    // if hit scrollEnd on scrollView make function happen
    if (props.reverse === true) {
      if (
        typeof props.onScrollEnd === "function" &&
        e.target.scrollHeight + e.target.scrollTop < e.target.clientHeight + 50
      ) {
        props.onScrollEnd();
      }
    } else {
      if (
        typeof props.onScrollEnd === "function" &&
        e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 50
      ) {
        props.onScrollEnd();
      }
    }

    // if there new element and the user is on the object do function
    if (props.reverse === true && props.newElement) {
      if (typeof props.onScrollNewElement === "function" && e.target.scrollTop + props.newElement.current.clientHeight > 0) {
        props.onScrollNewElement();
      }
    }
  }

  useEffect(() => {
    // if there is new element
    if (props.reverse === true && props.newElement) {
      if (!props.newElement.current) return;
      // if user is not on the object do function
      if (
        typeof props.newElementInScrollView === "function" &&
        targetRef.current.scrollTop + props.newElement.current.clientHeight < 0
      ) {
        props.newElementInScrollView();
      } else {
        // if element is in view scroll to element
        targetRef.current.scrollTo({
          behavior: "instant",
          top: 100,
        });
      }
    }

    // move to new element
    if (props.moveToNewElement === true && props.newElement) {
      targetRef.current.scrollTo({
        behavior: "smooth",
        top: 100,
      });
    }
  });

  return (
    <div
      id={props.id}
      ref={targetRef}
      className={`scroll-view ${componentStyle(props)} ${props.className || ""} ${props.maxWidth ? "use-max-width" : ""}`}
      style={props.style}
      onScroll={onScroll}
      data-test-id={props["data-test-id"]}
    >
      {props.children}
    </div>
  );
};

const componentStyle = (props) => css`
  flex: 100% 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-direction: column-reverse;

  &.use-max-width {
    max-width: ${props.maxWidth}px;
    margin: 0 auto;
  }
`;

export default ReverseScrollView;
