/**
 * Takes a filename (or any other string)
 * and tries with very low effort to turn it into something more human readable.
 *
 * examples:
 * `medarbejder-rapport_05-final.pdf` will be `medarbejder rapport 05 final`
 * `83-_21312__78923-aaaarbejdsmiljø.pdf` will be `83  21312  78923 aaaarbejdsmiljø`
 *
 */
export default function getGuestimateTitleFromFileName(string = "") {
  // Replace "-" and "_" with " "
  string = string.replace(/[\-\_]/g, " ");

  // Remove file-ending
  string = string.split(".").slice(0, -1).join(" ");

  return string;
}
