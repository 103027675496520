import req from "../utilities/request-utility";
import {
  GET_CERTIFICATE_OVERVIEW_FAILED,
  GET_CERTIFICATE_OVERVIEW_SUCCESS,
  GET_CHALLENGE_OVERVIEW_FAILED,
  GET_CHALLENGE_OVERVIEW_SUCCESS,
  GET_QUIZZES_OVERVIEW_FAILED,
  GET_QUIZZES_OVERVIEW_SUCCESS,
  SET_CERTIFICATE_LOADING,
  SET_CHALLENGE_LOADING,
  SET_QUIZ_LOADING
} from "./actionTypes";

export function getQuizzesOverview() {
  return async function(dispatch) {
    try {
      dispatch({ type: SET_QUIZ_LOADING });
      const { data: newQuizzes } = await req()(`quiz/new`);
      const { data: completedQuizzes } = await req()(`quiz/completed`);
      dispatch({
        type: GET_QUIZZES_OVERVIEW_SUCCESS,
        payload: { newQuizzes, completedQuizzes }
      });
    } catch (err) {
      dispatch({ type: GET_QUIZZES_OVERVIEW_FAILED });
    }
  };
}

export function getChallengeOverview(challengeId) {
  return async function(dispatch) {
    try {
      dispatch({ type: SET_CHALLENGE_LOADING });
      const { data: challenge } = await req()(`challenge/${challengeId}`);
      const { data: highScore } = await req()(`challenge/${challengeId}/highscore`);
      const { data: bestScore } = await req()(`challenge/${challengeId}/my-best-score`);

      dispatch({
        type: GET_CHALLENGE_OVERVIEW_SUCCESS,
        payload: { challenge, highScore, bestScore }
      });
    } catch (err) {
      dispatch({ type: GET_CHALLENGE_OVERVIEW_FAILED });
    }
  };
}

export function getCertificateOverview() {
  return async function(dispatch) {
    try {
      dispatch({ type: SET_CERTIFICATE_LOADING });

      const { data: newCertificates } = await req()(`certificate/`);
      const { data: completedCertificates } = await req()(`certificate/completed`);

      dispatch({
        type: GET_CERTIFICATE_OVERVIEW_SUCCESS,
        payload: { newCertificates, completedCertificates }
      });
    } catch (err) {
      dispatch({ type: GET_CERTIFICATE_OVERVIEW_FAILED });
    }
  };
}
