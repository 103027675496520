/** The markdown-editor in ekko updater spits out some weird things. This function tries to clean it up as good as it can. */

export default function (markDownString = "") {
  // Should be fixed with default value but apparently null, will not be set as "".
  // Now strings can't be the number 0, "", false, null, [] and undefined
  if (!markDownString) markDownString = "";

  /**
   * Markdown coming from ekko updater CAN be invalid for bold. A markdown editor is supposed to strip excess spaces
   * from line endings when having formatting but ekko updater doesn't do this. This means that if you mark something as bold
   * and then leave a trailing space, it won't work and the user will see * characteres instead.
   *
   * Example from ekko updater:
   * **This is bold **\n\nHere is the next Line
   *               ^- This space is the problem
   *
   * We previously had a "catch-all" solution that had the bug of stripping the last character from the word before bold (ie. "**Her er en overskrift **" -> "*Her er en overskrif**").
   * This is fixed now by having individual handling for return, newline and whitespace
   *
   * so {any none-whitespace character} + {one or more of space or the weird database space (00A0)} + {exactly two asterix'es} is replaced with "** "
   *
   * Lets say you had the string: "**This is bold **\n\nHere is the next Line"
   * This string would then be:   "**This is bold** \n\nHere is the next Line"
   */
  markDownString = markDownString.replace(/(\s| )\*\*(\r)/g, "**\r");
  markDownString = markDownString.replace(/(\s| )\*\*(\n)/g, "**\n");
  markDownString = markDownString.replace(/(\s| )\*\*(\s)/, "**&nbsp;");

  markDownString = markDownString.replace(/(\s| )\_\_(\r)/g, "__\r");
  markDownString = markDownString.replace(/(\s| )\_\_(\n)/g, "__\n");
  markDownString = markDownString.replace(/(\s| )\_\_(\s)/, "__&nbsp;");

  markDownString = markDownString.replace(/\s\*\*+$/, "**"); // end of string is " **"
  markDownString = markDownString.replace(/\s\_\_+$/, "__"); // end of string is " __"

  markDownString = markDownString.replace(/[^\s_][ \u00A0]+\_($|\s)/g, "_ ");

  markDownString = markDownString.replace(/\]\s\(/g, "]("); //replaces "] (" with "]("

  /*
    For reference:
    There is to types of line-feeds: New paragraph (or line-feed, end of line etc) and soft feeds. Normally they will ne
    \n for soft feeds and
    \r\n\ for new paragraphs. 

    For some reason i have figured out that with ekko updater this is what they actually are:

    \r\n\r\n/ = New paragraph (Just enter in word, google docs etc)
    \s\s\r\n/ = Soft feed (shift-enter in word, google docs etc) 
  */
  markDownString = markDownString.replace(/\r\n\r\n/g, " &nbsp;\n\n");
  markDownString = markDownString.replace(/\s\s\r\n/g, " &nbsp;\n");

  /* If &nbsp is a characther after a word make new line(\n) instead. 
     The reason we are doing this is because of how lists work with our markdownparsser.
     If there is a &nbsp characther after a word in the list, the list thinks &nbsp is part of the list and therefore we need to switch it out with a new line instead*/
  markDownString = markDownString.replace(/\w&nbsp;/g, (token) => token.substring(0, 1) + "\n");

  return markDownString;
}
