// Libs
import React, { useEffect, useState, Fragment } from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { useSelector } from "react-redux";

// Styles
import colors from "../../style/colors";

// Icons
import { CheckCircleIcon } from "mdi-react";

const TrafficLightRadioGroup = (props) => {
  const [activeRadioId, setActiveRadioId] = useState(null);

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  const onSelect = (radioId) => {
    // Emit event
    props.onSelect(radioId);
    setActiveRadioId(radioId);
  };

  useEffect(() => {
    if (props.selectedValue) setActiveRadioId(props.selectedValue);
    // eslint-disable-next-line
  }, []);

  const getTrafficLabels = (title, optionTag = "") => {
    if (title === 1 || optionTag === "RED") return "red-label";
    if (title === 2 || optionTag === "YELLOW") return "yellow-label";
    if (title === 3 || optionTag === "GREEN") return "green-label";
  };

  return (
    <div className={`${componentStyle(primaryColor)} ${props.className || ""}`} style={props.style && props.style}>
      {props.options.map((radio, index) => (
        <Fragment key={index}>
          {activeRadioId === radio.id ? (
            <label
              className={`radio selected ${getTrafficLabels(parseInt(radio.title), radio.optionTag)}`}
              htmlFor={radio.id}
              onClick={!props.disabled ? () => onSelect(radio.id) : {}}
              key={`radio-group-${radio.id}-${radio.title}`}
            >
              <CheckCircleIcon />
            </label>
          ) : (
            <label
              className={`radio ${getTrafficLabels(parseInt(radio.title), radio.optionTag)}`}
              htmlFor={radio.id}
              onClick={!props.disabled ? () => onSelect(radio.id) : {}}
              key={`radio-group-${radio.id}-${radio.title}`}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

const spacing = 5;

const componentStyle = (primaryColor) => css`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--midGrey);
  border-radius: 3px;

  .radio {
    flex: 33.333% 0 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    -webkit-appearance: none;
    cursor: pointer;
    border: ${spacing}px solid var(--white);

    &:nth-child(1) {
      position: relative;

      &:before {
        position: absolute;
        display: block;
        content: "";
        width: 1px;
        height: calc(100% + ${spacing * 2}px);
        left: calc(100% + ${spacing - 1}px);
        background-color: var(--midGrey);
      }
    }

    // Center item
    &:nth-child(2) {
      margin: 0 -${spacing}px;
      position: relative;

      &:before {
        position: absolute;
        display: block;
        content: "";
        width: 1px;
        height: calc(100% + ${spacing * 2}px);
        left: calc(100% + ${spacing - 1}px);
        background-color: var(--midGrey);
      }
    }

    svg {
      position: absolute;
      fill: ${primaryColor};
      height: 1.5rem;
      width: 1.5rem;
      right: 8px;
    }

    &.selected,
    &.selected {
      font-weight: 700;
      border: 4px solid ${primaryColor};
      border-radius: 3px;

      &:after {
        position: absolute;
        display: block;
        content: "";
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 3px var(--white) solid;
        border-radius: 2px;
      }
    }

    &.green-label {
      background-color: var(--green);

      &:hover {
        background-color: ${tinycolor(colors.green).lighten(5).toString()};
      }

      &:active {
        background-color: ${tinycolor(colors.green).darken(5).toString()};
      }
    }

    &.red-label {
      background-color: var(--redMedium);

      &:hover {
        background-color: ${tinycolor(colors.redMedium).lighten(5).toString()};
      }

      &:active {
        background-color: ${tinycolor(colors.redMedium).darken(5).toString()};
      }
    }

    &.yellow-label {
      background-color: var(--yellow);

      &:hover {
        background-color: ${tinycolor(colors.yellow).darken(5).toString()};
      }

      &:active {
        background-color: ${tinycolor(colors.yellow).darken(5).toString()};
      }
    }
  }
`;

export default TrafficLightRadioGroup;
