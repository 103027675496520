import cacheNames from "../config/cacheNames";
const { appConfigImages } = cacheNames;

export const cacheAppConfigImages = async (appConfig) => {
  const { frontPageImage = {}, customerLogo = {}, icon = {} } = appConfig;

  const backgroundImageURLs = getBackgroundImageURLs(frontPageImage.baseURL, frontPageImage.image);
  const logoNameURL = customerLogo.baseURL + customerLogo.image;
  const iconNameURL = icon.baseURL + icon.image;

  const imageURLs = [logoNameURL, iconNameURL, ...backgroundImageURLs];

  const cache = await caches.open(appConfigImages);
  const cacheKeys = await cache.keys();

  // Add new image to the cache
  for (const url of imageURLs) {
    if (await cache.match(url)) continue;

    try {
      const response = await fetch(url);

      if (!response) continue;

      await cache.put(url, response);
    } catch (error) {
      console.log("ERROR! while trying to save to cache");
    }
  }

  // Remove all the old images from the cache
  for (const cacheEntree of cacheKeys) {
    if (!imageURLs.includes(cacheEntree.url)) {
      await cache.delete(cacheEntree);
    }
  }
};

const getBackgroundImageURLs = (baseURL, image) => {
  const darkeningOverlay = "b_black,o_50,";
  const sizes = [500, 800, 1200, 1800];

  return sizes.reduce((initial, size) => {
    initial.push(getCloudinaryImageFormat(baseURL, image, size, ""));
    initial.push(getCloudinaryImageFormat(baseURL, image, size, darkeningOverlay));
    return initial;
  }, []);
};

export const getCloudinaryImageFormat = (baseURL, image, size, overlay) => {
  return `${baseURL}${overlay}h_${size},q_auto,f_auto/${image}`;
};
