// Libs
import React, { useEffect, useState } from "react";
import { ChevronRightIcon, InfoOutlineIcon } from "mdi-react";
import breakpoints from "../../../config/breakpoints";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import req from "../../../utilities/request-utility";
import InlineSpinner from "../../ui/InlineSpinner";
import ListItem from "../../ui/ListItem";
import StatusBox from "../../ui/StatusBox";
import ScrollView from "../../ui/ScrollView";

const ScheduleOverviewGroupsTab = (props) => {
  // Local state
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Get the groups
  useEffect(() => {
    req()(`schedules/groups`)
      .then(({ data: groups }) => {
        setGroups(groups);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <ScrollView style={{ padding: "1rem 0", height: "100%" }}>
      {loading && !error && <InlineSpinner />}

      {!loading && error && (
        <StatusBox title={lang.noShiftGroupsCreatedYet} icon={<InfoOutlineIcon />} content={lang.tryAgainOrContactSupport} />
      )}

      {!loading && !error && groups.length === 0 && (
        <StatusBox title={lang.noShiftGroupsCreatedYet} icon={<InfoOutlineIcon />} content={lang.tryAgainOrContactSupport} />
      )}

      {!loading &&
        !error &&
        groups.length > 0 &&
        groups.map(({ id, title }) => (
          <ListItem
            clickable={true}
            dataTestId={"schedule-overview-item"}
            key={`schedule-overview-group-item-${id}`}
            maxWidth={breakpoints.md}
            title={title}
            iconRight={<ChevronRightIcon />}
            onClick={() => pageNavigatorV2({ path: `${props.match.url}/${id}`, direction: "forward" })}
          />
        ))}
    </ScrollView>
  );
};

export default ScheduleOverviewGroupsTab;
