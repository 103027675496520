export default function getUnansweredRequiredQuestionIds({ questions }) {
  let unansweredIds = [];

  questions
    .filter((q) => q.isRequired)
    .forEach(({ id, answer }) => {
      if (!answer) unansweredIds.push(id);

      if (typeof answer === "object" && (answer?.title === "" || answer?.title === null)) unansweredIds.push(id);
    });

  return unansweredIds;
}
