// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Styles
import colors from "../../style/colors";

// Components
import ActionWrapper from "./ActionWrapper";

// Utilities
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../utilities/get-user-group-title";

// Actions

const Author = (props) => {
  const { user, onClick, subTitle, profilePictureSize = 50, style = {} } = props;

  if (!user) {
    const lang = useSelector((state) => state.language.language);
    return (
      <div className={componentStyle()}>
        {getProfilePictureFromUserObject({}, profilePictureSize, { marginRight: "0.5rem" })}
        <p className="author">{lang.deletedUser}</p>
      </div>
    );
  }

  return (
    <ActionWrapper className={componentStyle()} onClick={onClick} data-test-id="btn-post-author" style={style}>
      {user && getProfilePictureFromUserObject(user, profilePictureSize, { marginRight: "0.5rem" })}
      <div className="author-and-date-wrapper">
        <p className="author">{user.name}</p>
        <p className="author-info">{getUserGroupTitle(user)}</p>
        {subTitle && <p className="date">{subTitle}</p>}
      </div>
    </ActionWrapper>
  );
};

const componentStyle = () => css`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: ${0.75 * 0.5}rem;

  .author-and-date-wrapper {
    text-align: left;

    p.author {
      color: var(--trueBlack);
      margin: -1px 0 0rem 0;
      font-size: 0.9rem;
    }

    p.date,
    p.author-info {
      color: ${colors.midDarkGrey};
      font-size: 0.8rem;
    }
  }

  & > div {
    margin-bottom: 0px !important;
  }

  svg {
    width: 1rem;
    height: 1rem;
    color: var(--darkGrey);
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  p {
    font-size: 0.9rem;
    color: var(--darkGrey);
  }
`;

export default Author;
