// Libraries
import React, { useContext } from "react";

// Components
import TextareaInput from "../../../../ui/TextareaInput";
import ImageUploadSingle from "../../../../ui/ImageUploadSingle";
import NavigationSubMenuSortingSelector from "./ui/NavigationSubMenuSortingSelector";
import CloneNavigationSelector from "./ui/cloneNavigationSelector/CloneNavigationSelector";
import ButtonRounded from "../../../../ui/ButtonRounded";
import { ChevronRightIcon } from "mdi-react";
import useNavigateToSubPages from "../../../hooks/useNavigateToSubPages";
import { PageBuilderContext } from "../../../context/PageBuilderContext";
import { useSelector } from "react-redux";

const NavigationContent = ({ onChange, page, applicationLanguages, isCreateMode }) => {
  const { description, description_fl, coverImage, meta, pages: childPages = [] } = page;

  const lang = useSelector((state) => state.language.language);

  const { moduleId } = useContext(PageBuilderContext);
  const { navigateToSubPages } = useNavigateToSubPages({ moduleId });

  return (
    <div style={{ marginTop: "1rem" }}>
      {!isCreateMode && (
        <ButtonRounded
          className="action-btn"
          style={{ marginBottom: "1rem" }}
          onClick={() => navigateToSubPages(page.id)}
          secondary={true}
        >
          <p>{`${lang.viewSubPages} ${childPages.length ? `(${childPages.length})` : ""}`}</p>
          <ChevronRightIcon />
        </ButtonRounded>
      )}

      <div className="field-container">
        <p className="title">{lang.description}</p>
        <div className="field-wrapper">
          <label>{applicationLanguages?.primary?.name || ""}</label>
          <TextareaInput name="description" onChange={onChange} value={description || ""} />
        </div>
        {applicationLanguages?.foreign?.name && (
          <div className="field-wrapper">
            <label>{applicationLanguages?.foreign?.name}</label>
            <TextareaInput name="description_fl" onChange={onChange} value={description_fl || ""} />{" "}
          </div>
        )}
      </div>

      <div className="field-container">
        <p className="title">{lang?.pageCover}</p>
        <div className="field-wrapper">
          <label>{lang?.clickToUploadImage}</label>
          <ImageUploadSingle
            onFile={(file) =>
              onChange({ target: { name: "coverImage", value: { baseURL: file.baseURL, image: file.file } } })
            }
            removeFile={() => onChange({ target: { name: "coverImage", value: { baseURL: null, image: null } } })}
            uploadedFile={coverImage || undefined}
          />
        </div>
      </div>

      <div className="field-container">
        <p className="title">{lang?.sortOrderOfSubPages}</p>
        <div className="field-wrapper">
          <label>{lang?.chooseSortOrder}</label>
          <NavigationSubMenuSortingSelector
            usePlacerholder={true}
            placeholder={lang?.clickToSelectSortOrder}
            name={"navigationSubPagesSortOrder"}
            onChange={(e) => onChange({ target: { name: "meta", value: { ...meta, [e.target.name]: e.target.value } } })}
            value={page?.meta?.navigationSubPagesSortOrder}
          />
        </div>
      </div>

      {isCreateMode && (
        <div className="field-container">
          <p className="title">{lang?.cloneFolderStructure}</p>
          <div className="field-wrapper">
            <label>{lang?.chooseFolderToClone}</label>
            <CloneNavigationSelector
              onChange={(clonePageId) =>
                onChange({ target: { name: "meta", value: { ...meta, clonePageId: clonePageId } } })
              }
            />
            <label>{lang?.noteOnlyNewsModulesWillGetContent}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationContent;
