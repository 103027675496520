// libs
import React, { useEffect, useState, useRef } from "react";
import { css } from "emotion";
import { matchPath, useHistory, useParams } from "react-router";
import { SendIcon, InfoOutlineIcon, CheckCircleOutlineIcon } from "mdi-react";
import dayjs from "dayjs";

// Components
import MessageContainer from "./messageContainer";
import TextareaInput from "../../ui/TextareaInput";
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import ListItem from "../../ui/ListItem";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";

// Util
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import getLastTimeUserWasOnlineString from "../utilities/getLastTimeUserWasOnlineString";
import isUserOnline from "../utilities/isUserOnline";

// Hooks
import useAuthorModal from "../../../hooks/useAuthorModal";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getChatRoom,
  getMessages,
  removeChatRoom,
  updateMessages,
  setSocket,
  removeSocket,
  setOnlineUsers,
} from "../../../actions/liveChatActions";
import { addToast } from "../../../actions/uiActions";

function ChatRoomPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialized = useRef(false);
  const [text, setText] = useState("");
  const [receivedOnlineUsers, setReceivedOnlineUsers] = useState(null);
  const [receiverMessage, setReceiverMessage] = useState(null);
  const { chatId } = useParams();

  // redux
  const socket = useSelector((state) => state.liveChat.socket);
  const user = useSelector((state) => state.auth.user);
  const messageList = useSelector((state) => state.liveChat.messages);
  const chatRoom = useSelector((state) => state.liveChat.currentChatRoom);
  const onlineUsers = useSelector((state) => state.liveChat.onlineUsers);
  const { language: lang } = useSelector((state) => state.language);
  const appConfig = useSelector((state) => state.appConfig);

  // Used for showing the user modal
  const authorModal = useAuthorModal();

  // listen for users
  socket?.on("getUsers", (users) => {
    setReceivedOnlineUsers(users);
  });

  // listen for new messages
  socket?.on("getMessage", (message) => {
    setReceiverMessage(message);
  });

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      // set socket
      dispatch(setSocket());

      // get chat room
      dispatch(getChatRoom({ chatId: chatId }));
    }

    return () => {
      if (initialized.current) {
        // leave room
        dispatch(removeChatRoom());

        // get rerouted url and check if it match
        const urlMatch1 = matchPath(history.location.pathname, {
          path: "/:appname/liveChat/:pageId",
          exact: false,
          strict: false,
        });

        // disconnect socket if url is not a match
        if (!urlMatch1) dispatch(removeSocket());
      }
    };
  }, []);

  useEffect(() => {
    if (socket && receivedOnlineUsers) {
      dispatch(setOnlineUsers(receivedOnlineUsers));
    }
  }, [receivedOnlineUsers]);

  useEffect(() => {
    // if new message update messages
    if (receiverMessage) dispatch(updateMessages(receiverMessage));
  }, [receiverMessage]);

  useEffect(() => {
    // if there is a chat room get messages
    if (Object.keys(chatRoom.chatRoom).length !== 0) dispatch(getMessages(chatRoom.chatRoom.id));
  }, [chatRoom]);

  function sendMessage(receiver) {
    if (!text) return;
    // initalize message
    const message = {
      chatId: chatRoom.chatRoom.id,
      text: text,
      createdAt: dayjs().format("YYYYMMDDHHmmss"),
      senderId: user.id,
    };
    // send message
    socket.emit("sendMessage", message, receiver, (newMessage) => {
      if (newMessage) {
        setText("");
      } else {
        dispatch(
          addToast({
            styleType: "error",
            title: "Message",
            content: "Could not send message",
            icon: <CheckCircleOutlineIcon />,
            duration: 3000,
          })
        );
      }
    });
  }

  const handleKeyPress = (event, receiver) => {
    const isMobile = window.navigator.userAgent.match(/iPad|iPhone|iPod|Android|SamsungBrowser/);
    if (!event.shiftKey && event.key === "Enter" && !isMobile) {
      sendMessage(receiver);
    }
  };

  return (
    <Page>
      <TopBar title={"Chat"} useDefaultBackButton={true} />

      {/* chat dosen't exist */}
      {chatRoom.loading === false && chatRoom.error === false && Object.keys(chatRoom.chatRoom).length === 0 && (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noConversation}
          content={lang.conversationDoNotExist}
        />
      )}
      {/* loading */}
      {chatRoom.loading === true && chatRoom.error === false && <InlineSpinner />}
      {/* error */}
      {chatRoom.loading === false && chatRoom.error === true && (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.error}
          content={lang.errorConversationInformation}
        />
      )}
      {/* show chatRoom */}
      {chatRoom.loading === false && chatRoom.error === false && Object.keys(chatRoom.chatRoom).length > 0 && (
        <div className={componentStyle()}>
          {/* Show user */}
          <ListItem
            style={{ width: "100%" }}
            onClick={() => authorModal(chatRoom.chatRoom.user.id)}
            title={chatRoom.chatRoom.user.name}
            middleTitle={chatRoom.chatRoom.user.jobTitle}
            subTitle={
              isUserOnline({
                userObject: chatRoom.chatRoom.user,
                onlineUsers: onlineUsers,
              }) === true
                ? lang.onlineNow
                : lang.lastActive +
                  ": " +
                  getLastTimeUserWasOnlineString({
                    userObject: chatRoom.chatRoom.user,
                    language: user.language,
                    lang: lang,
                  })
            }
            imageLeft={getProfilePicture(chatRoom.chatRoom.user, 60, { marginRight: "0.5rem", marginBottom: "-3px" })}
          />
          {/* messages container */}
          <MessageContainer
            messageList={messageList}
            newMessage={receiverMessage}
            newMessageFalse={() => setReceiverMessage(null)}
          />
          {/* container for inputfield and send button */}
          <div className="sendMessage-container">
            {/* {text.length > 0 && <p className="count">{`${text.length}/${10000}`}</p>} */}
            <TextareaInput
              value={text}
              minHeight={"match-parent"}
              rows={1}
              className="textInputArea"
              onChange={(e) => setText(e.target.value)}
              placeholder={lang.whatDoYouWantToSay}
              onKeyPress={(e) => handleKeyPress(e, chatRoom.chatRoom.user)}
              resize={true}
              maxLength={500}
            />
            <button className="button" onClick={() => sendMessage(chatRoom.chatRoom.user)}>
              <SendIcon />
            </button>
          </div>
        </div>
      )}
    </Page>
  );
}
const componentStyle = () => css`
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  .sendMessage-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    bottom: 1.5rem;
    width: calc(100% - 0.8rem);
    position: fixed;
  }

  .count {
    position: absolute;
    display: inline-block;
    font-size: 0.5rem;
    height: 1rem;
    padding: 0rem;
    width: fit-content;
    background-color: var(--white);
    top: -0.25rem;
    left: 2rem;
  }

  .textInputArea {
    width: 100%;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    border-radius: 6px;
    max-height: 7.75rem;
    overflow: hidden;
  }

  button {
    width: 2.7rem;
    height: 38px;
    border-radius: 50%;
    border: 0;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: end;

    svg {
      width: 1.15rem;
      height: 1.15rem;
      color: var(--white);
      margin-left: 2px;
      margin-top: 0px;
    }
  }
`;

export default ChatRoomPage;
