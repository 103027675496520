// Libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as queryString from "query-string";
import { CSSTransition } from "react-transition-group";
import { useDebouncedCallback } from "use-debounce";

// Components
import { css } from "emotion";
import InlineSpinner from "../ui/InlineSpinner";
import ScrollView from "../ui/ScrollView";
import ImageCarousel from "../ui/ImageCarousel";
import StepBox from "../ui/StepBox";
import RegistrationPostSuccess from "./RegistrationPostSuccess";
import FormBuilder from "../ui/FormBuilder";
import DropDownList from "../ui/dropDown/DropDownList";
import StickyStepper from "../ui/StickyStepper";
import FileViewer from "../ui/FileViewer";

// Styles and config
import colors from "../../style/colors";
import { feedTypes, formBuilderModes, localStorageAction } from "./config";
import { DecagramCheckIcon, ErrorOutlineIcon } from "mdi-react";
import { durations } from "../../config/animations";

// Utilities
import req from "../../utilities/request-utility";
import markDownParser from "../../utilities/markdown-parser";
import registrationLocalStorageHandler from "../../utilities/use-registration-local-storage";
import questionVisibleForUser from "./utilities/questionVisibleForUser";

// Config and stylepostsposts
import breakpoints from "../../config/breakpoints";
import common from "../../style/common";

// Actions
import { addToast, hideModalPage, updateModalPage } from "../../actions/uiActions";
import {
  refreshRegistrationPost,
  resetAll,
  setCurrentTab,
  getRegistrationTabPosts,
  resetTabFeed,
} from "../../actions/registrationActions";
import StatusBox from "../ui/StatusBox";
import styleTypes from "../../config/styleTypes";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import getUnansweredRequiredQuestionIds from "./utilities/getUnansweredRequiredQuestionIds";

const boxPadding = "0.75";

const RegistrationFormBuilder = (props) => {
  // Initiate Redux dispatcher
  const dispatch = useDispatch();

  // Props
  const formMode = props.formMode || null;
  const useStandaloneFormMode = props.useStandaloneFormMode || false;
  const postId = props.post ? props.post.id : null;

  // Query parameters
  let registrationId = queryString.parse(window.location.search).dataId;

  // Language
  const lang = useSelector((state) => state.language.language);

  // current tab
  const { tabs, currentTab } = useSelector((state) => state.registration[feedTypes.processflow]);

  const user = useSelector((state) => state.auth.user);

  // Local state
  const [registration, setRegistration] = useState({
    fields: [],
    images: [],
    title: null,
    completedText: null,
    description: null,
    notValidArr: [],
    mailGroupAnswered: true,
    mailGroup: {
      groups: [],
      selected: null,
    },
    documents: [],
  });
  const { fields, images, title, completedText, description, notValidArr, mailGroupAnswered, mailGroup, documents } =
    registration;

  const [pageUI, setPageUI] = useState({
    loading: true,
    error: false,
    currentStep: 0,
    animationDirection: "",
  });
  const { loading, error, currentStep, animationDirection } = pageUI;

  useEffect(() => {
    // If any required questions are flagged as unanswered, then for every change see if the questions are still
    // unanswered. This is needed in order to remove the red highlighting of the question
    if (fields && !loading && notValidArr.length > 0) {
      let unansweredQuestionIds = getUnansweredRequiredQuestionIds({ questions: fields[currentStep].questions });
      setRegistration({ ...registration, notValidArr: unansweredQuestionIds });
    }
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    // If mailgroup gets selected unflag that the mailgroup needed to get answered
    if (mailGroup.groups.length > 0 && !mailGroupAnswered) {
      let mailGroupSelected = false;
      if (mailGroup.selected) mailGroupSelected = true;
      setRegistration({ ...registration, mailGroupAnswered: mailGroupSelected });
    }
  }, [mailGroup]);

  // Debounce function to save to local storage
  const [debouncedSaveToLocalStorage] = useDebouncedCallback(() => {
    const { fields, title, completedText, images, description, mailGroup } = registration;
    registrationLocalStorageHandler(localStorageAction.saving, {
      id: registrationId,
      fields,
      title,
      completedText,
      images,
      description,
      mailGroup,
    });
  }, 500);

  // The initial load has 3 ways it can load in registration
  // - From local storage
  // - From when
  useEffect(() => {
    (async function getRegistrationForm() {
      try {
        // LOCAL STORAGE - Load from local storage
        if (props.loadFromLocaleStorage) {
          let localStorage = registrationLocalStorageHandler(localStorageAction.get, { id: registrationId });
          setRegistration({
            ...registration,
            fields: localStorage.fields,
            images: localStorage.images,
            description: localStorage.description,
            mailGroup: localStorage.mailGroup,
          });
          setPageUI({ ...pageUI, loading: false });
        }
        // DEFAULT - Load a new registration from API
        else {
          // Get the backend registration
          const { data } = await req()(`registration/${registrationId}?postId=${postId || ""}`);

          if (props.useStandaloneFormMode && props.updatePageTitle) {
            props.updatePageTitle(data.title);
          } else {
            dispatch(
              updateModalPage({
                title: data.title,
              })
            );
          }

          setRegistration({
            ...registration,
            fields: data.categories,
            title: data.title,
            completedText: data.completedText || "",
            images: data.images,
            description: data.description,
            mailGroup: { ...registration.mailGroup, groups: data.mailGroups },
            documents: data.documents,
          });

          setPageUI({ ...pageUI, loading: false });
        }
      } catch (err) {
        dispatch(
          addToast({
            title: lang.errorGeneral,
            content: lang.errorFetchingForm,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
        setPageUI({ ...pageUI, error: true, loading: false });
      }
    })();

    // eslint-disable-next-line
  }, []);

  async function submitAnswer() {
    function submittedAftermath() {
      if (props.feedType !== feedTypes.processflow) dispatch(resetAll());

      if (props.feedType === feedTypes.processflow) {
        const tabKey = Object.keys(tabs)[0];
        dispatch(setCurrentTab(tabKey, tabs[tabKey], 0));
        dispatch(resetTabFeed(currentTab.tab));
        dispatch(getRegistrationTabPosts({ tab: currentTab.tab, registrationId, feedType: props.feedType, offset: 0 }));
      }

      if (props.postSubmitCallback) props.postSubmitCallback();

      if (useStandaloneFormMode) {
        dispatch(
          addToast({
            title: lang.thanksForAnswering,
            content: `${lang.answerReceived}. ${completedText}`,
            styleType: styleTypes.ok,
            icon: <DecagramCheckIcon />,
            duration: 15000,
          })
        );
        pageNavigatorV2({ mode: "pop" });
      } else {
        dispatch(
          updateModalPage({
            title: title,
            content: (
              <RegistrationPostSuccess
                completedText={formMode === formBuilderModes.edit ? lang.editedSuccessfully : completedText}
              />
            ),
          })
        );
      }
    }
    function submittedErrorHandler() {
      dispatch(hideModalPage());
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: lang.errorFetchingForm,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }

    function editedAftermath() {
      dispatch(hideModalPage());
      if (props.feedType === feedTypes.processflow) {
        dispatch(resetTabFeed(currentTab.tab));
        dispatch(getRegistrationTabPosts({ tab: currentTab.tab, registrationId, feedType: props.feedType, offset: 0 }));
      } else {
        dispatch(refreshRegistrationPost({ registrationId, postId, feedType: props.feedType }));
      }
    }

    setPageUI({ ...pageUI, loading: true });

    try {
      if (formMode === formBuilderModes.edit) {
        await req().put(`registration/${registrationId}/posts/${postId || ""}`, { fields });
        editedAftermath();
      } else {
        await req().post(`registration/${registrationId}/posts`, { fields, mailGroup: mailGroup.selected });
        registrationLocalStorageHandler(localStorageAction.delete, { id: registrationId });
        submittedAftermath();
      }
    } catch (error) {
      submittedErrorHandler();
    }
  }

  const updateFields = (value, index) => {
    let questions = "questions";
    let answer = "answer";
    setRegistration((registration) => ({
      ...registration,
      fields: [
        ...fields.map((category, categoryIndex) =>
          currentStep === categoryIndex
            ? {
                ...category,
                [questions]: [
                  ...category.questions.map((question, questionIndex) =>
                    questionIndex === index ? { ...question, [answer]: value } : question
                  ),
                ],
              }
            : category
        ),
      ],
    }));

    if (!formMode) debouncedSaveToLocalStorage();
  };

  function goToPreviousStep() {
    setPageUI({ ...pageUI, currentStep: currentStep - 1, animationDirection: "left" });
  }

  function goToNextStep() {
    setPageUI({ ...pageUI, currentStep: currentStep + 1, animationDirection: "right" });
  }

  const nextOrSubmit = async () => {
    let mailGroupSelected = true;
    if (!mailGroup.selected && mailGroup.groups.length > 0) {
      mailGroupSelected = false;
    }

    let unansweredRequiredQuestions = getUnansweredRequiredQuestionIds({ questions: fields[currentStep].questions });
    setRegistration({ ...registration, notValidArr: unansweredRequiredQuestions, mailGroupAnswered: mailGroupSelected });

    if (unansweredRequiredQuestions.length > 0 || (!mailGroupSelected && formMode !== formBuilderModes.edit)) {
      return dispatch(
        addToast({
          title: lang.missingFields,
          content: lang.fillAllFields,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }

    if (currentStep === fields.length - 1) {
      await submitAnswer();
    } else {
      goToNextStep();
    }
  };

  return (
    <div className={componentStyle()}>
      {loading && !error && (
        <div className="spinner-container">
          <InlineSpinner style={{ paddingTop: "5rem" }} />
        </div>
      )}
      {!loading && error && <StatusBox style={{ paddingTop: "1rem" }} />}
      {!loading &&
        !error &&
        fields &&
        fields.length > 0 &&
        fields.map((step, stepIndex) => (
          <CSSTransition
            key={stepIndex}
            timeout={durations.normal}
            classNames={`step-animation-${animationDirection}`}
            in={stepIndex === currentStep}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <div className={`step-container step-index-${stepIndex}`}>
              <ScrollView className="content-wrapper">
                <div style={{ maxWidth: breakpoints.md, margin: "auto" }}>
                  <div style={{ marginBottom: "3rem" }}>
                    <StepBox
                      title={step.title}
                      currentStep={stepIndex + 1}
                      lastStep={fields.length}
                      style={{ marginBottom: "2rem" }}
                    />
                    <div style={{ marginBottom: "2rem" }}>
                      <h2>{step.title}</h2>
                    </div>
                    {stepIndex === 0 && images.length > 0 && <ImageCarousel boxPadding={boxPadding} images={images} />}
                    {stepIndex === 0 && description && (
                      <div className="content-container" dangerouslySetInnerHTML={markDownParser(description || "")} />
                    )}
                    {/* Files */}
                    {stepIndex === 0 && documents && documents.length > 0 && (
                      <div className="attachments-container">
                        {documents.map((document) => (
                          <FileViewer
                            key={`file-viewer-compontent${document.file}`}
                            href={`${document.baseURL || ""}${document.file}`}
                            title={document.title}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  {/** Visible on last category **/}
                  {mailGroup.groups.length > 0 && stepIndex === 0 && formMode !== formBuilderModes.edit && (
                    <div className="mail-receiver-wrapper">
                      <p className={`${!mailGroupAnswered ? "not-valid-title" : ""}`} style={{ marginBottom: "0.5rem" }}>
                        {lang.selectReceiver}
                      </p>
                      <DropDownList
                        className={`${!mailGroupAnswered ? "not-valid" : ""}`}
                        dropDownListContent={mailGroup.groups}
                        selectedContentId={(mailGroup.selected && mailGroup.selected.id) || 0}
                        onChange={(e) => {
                          setRegistration({
                            ...registration,
                            mailGroup: {
                              ...mailGroup,
                              selected: mailGroup.groups.find((g) => g.id === parseInt(e.target.value)),
                            },
                          });
                          debouncedSaveToLocalStorage();
                        }}
                      />
                    </div>
                  )}

                  {step.questions.map((question, questionIndex) => (
                    <FormBuilder
                      key={question.id}
                      question={question}
                      questionIndex={questionIndex}
                      updateFields={updateFields}
                      notValidArr={notValidArr}
                      style={{ marginBottom: "1.5rem" }}
                    />
                  ))}
                </div>
              </ScrollView>

              {/**
               * This is placed below the content in the markup in order to avoid z-index for layering. Otherwise the datepicker
               * would show on top of the buttons in this container
               */}
              <StickyStepper
                previousStep={goToPreviousStep}
                nextOrSubmit={nextOrSubmit}
                step={stepIndex}
                maxStep={fields.length - 1}
              />
            </div>
          </CSSTransition>
        ))}
    </div>
  );
};

const componentStyle = () => css`
  background-color: var(--white);
  flex: 100% 1 1;
  height: 100%;

  .content-container {
    margin-bottom: 1rem;

    p {
      margin-bottom: 0.75rem;
    }

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
      margin-bottom: 0.75rem;
    }
    &:last-child {
    }
  }

  .mail-receiver-wrapper {
    margin-bottom: 1.5rem;

    .not-valid {
      border: 1px solid var(--redMedium);
      border-radius: 5px;

      /* Select in dropdown is what need focus */
      select:focus {
        outline: 0;
        border: 1px solid var(--redMedium);
      }
    }

    .not-valid-title {
      color: var(--redMedium);
    }
  }

  .attachments-container {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    margin-bottom: 1rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: ${colors.darkGrey};
      border-bottom: 1px ${colors.midGrey} solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:active {
        background-color: ${colors.lightGrey};
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: ${colors.darkGrey};
      }
    }
  }

  .step-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .content-wrapper {
      /* Top padding should be double top bar to account for StickyStepper */
      padding: calc(${common.topBarHeight * 2 + 16}px + env(safe-area-inset-top)) 1rem ${common.topBarHeight + 16}px 1rem;
      height: 100%;
      justify-content: center;
    }
  }

  /* UYSE FROM HERE ON AND DOWN */
  .step-animation-left-enter {
    opacity: 0;
    transform: translateX(-200px);
  }
  .step-animation-left-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .step-animation-left-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .step-animation-left-exit-active {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }

  .step-animation-right-enter {
    opacity: 0;
    transform: translateX(200px);
  }
  .step-animation-right-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .step-animation-right-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .step-animation-right-exit-active {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
`;

export default RegistrationFormBuilder;
