// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ListItem from "../ui/ListItem";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Style
import { InfoOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

// Icons
import { CheckIcon, TimerIcon } from "mdi-react";

// Redux
import { bindActionCreators } from "redux";

// Config
import breakpoints from "../../config/breakpoints";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";
import { css } from "emotion";
import { getChallengeOverview } from "../../actions/academyActions";
import * as queryString from "query-string";
import ChallengePreview from "./ChallengePreview";

const ChallengeOverview = (props) => {
  // Language
  const lang = useSelector((state) => state.language.language);

  // Page for navigation
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  const { user } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Url parameters
  const authorModal = useAuthorModal();

  // UI Layout
  const [tabs] = useState([lang.quiz, lang.highscore]);
  const [activeTab, setActiveTab] = useState(0);

  // Quiz redux state
  const { challenge, highScore, bestScore, loading } = useSelector((state) => state.academy.challenge);

  // Challenge id
  let challengeId = queryString.parse(window.location.search).dataId;

  useEffect(() => {
    // Get the challengeId / dataId
    props.getChallengeOverview(challengeId);
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      {loading && (
        <div style={{ marginTop: "3rem" }}>
          <Spinner />
        </div>
      )}
      {!loading && !challenge.id && (
        <StatusBox style={{ marginTop: "1rem" }} icon={<InfoOutlineIcon />} title={lang.thereIsNoChallenge} content={" "} />
      )}
      {!loading && challenge.id && (
        <TabView
          activeTabIndex={activeTab}
          tabs={[
            <div className={componentStyle(breakpoints, colors)} style={{ marginTop: "1rem" }}>
              <div className="content">
                <ChallengePreview quiz={challenge} />

                {bestScore && (
                  <div className="my-best-score-container">
                    <p>{lang.bestResult}</p>
                    <div>
                      <CheckIcon size={16} color={colors.darkGrey} />
                      <p>
                        {lang.__placeholderCorrectAnswers__outOf__placeholderNumberOfQuestions__correct
                          .replace(/{{placeholderCorrectAnswers}}/gi, bestScore.correctAnswers)
                          .replace(/{{placeholderNumberOfQuestions}}/gi, bestScore.numberOfQuestions)}
                      </p>
                    </div>
                    <div>
                      <TimerIcon size={16} color={colors.darkGrey} />
                      <p>{`${bestScore.time} ${lang.seconds.toLowerCase()}`}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>,

            <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
              {highScore.length > 0 && (
                <>
                  <ListItem
                    maxWidth={breakpoints.md}
                    iconRight={lang.correctAnswers}
                    iconRightStyle={{ fontSize: "0.9rem", color: colors.midDarkGrey, minWidth: "9rem" }}
                    contentRight={lang.timeAndSeconds}
                    contentRightStyle={{ fontSize: "0.9rem", color: colors.midDarkGrey, minWidth: "3rem" }}
                  />
                  {highScore.map((hs, index) => (
                    <ListItem
                      key={index}
                      maxWidth={breakpoints.md}
                      iconLeft={getProfilePicture(hs.user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
                      title={hs.user.name}
                      titleStyle={hs.user.id === user.id ? { fontWeight: "bold", color: primaryColor } : {}}
                      subTitle={hs.user.userGroupTitle}
                      iconRightStyle={
                        hs.user.id === user.id
                          ? { minWidth: "7rem", fontWeight: "bold", color: primaryColor }
                          : { minWidth: "7rem" }
                      }
                      iconRight={`${hs.correctAnswers}/${hs.numberOfQuestions}`}
                      contentRight={`${hs.time}`}
                      contentRightStyle={
                        hs.user.id === user.id
                          ? { minWidth: "3rem", textAlign: "center", fontWeight: "bold", color: primaryColor }
                          : { minWidth: "3rem", textAlign: "center" }
                      }
                      onClick={() => authorModal(hs.user.id)}
                    />
                  ))}
                </>
              )}
              {highScore.length === 0 && (
                <StatusBox
                  className={"no-highScore-container"}
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noOneHasTakenQuizYet}
                  content={" "}
                />
              )}
            </div>,
          ]}
        />
      )}
    </Page>
  );
};

const componentStyle = (breakpoints, colors) => css`
  .academy-preview-container {
    border: 1px solid var(--lightGrey);
  }

  .no-highScore-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .content {
    margin: auto;
    max-width: ${breakpoints.md}px;
  }

  .my-best-score-container {
    margin: 1rem auto 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top: 1px solid var(--midGrey);
    border-bottom: 1px solid var(--midGrey);
    background-color: var(--white);
    max-width: ${breakpoints.md}px;

    p:first-child {
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        margin-left: 0.5rem;
      }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      border: 1px solid var(--midGrey);
      border-radius: 3px;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getChallengeOverview: bindActionCreators(getChallengeOverview, dispatch),
});

export default connect(null, mapDispatchToProps)(ChallengeOverview);
