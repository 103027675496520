import { css } from "emotion";
import breakpoints from "../../../../config/breakpoints";
import ListItem from "../../../ui/ListItem";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";

function RegistrationTippingMyAnswer({ registrationId }) {
  const { data: matches, isLoading: loading } = useSWR(`/registration/${registrationId}/tippingMyAnswer`, swrFetcher);

  return (
    <div className={style()}>
      <div className="header">
        <div>Kamp</div>
        <div className="spacer"></div>
        <div>Svar</div>
      </div>
      {!loading &&
        matches &&
        matches.map((match, index) => (
          <ListItem
            key={match.match}
            clickable={false}
            maxWidth={breakpoints.lg}
            className={``}
            title={match.match}
            contentRight={
              <div className={`match-answer ${match.isMatchPlayed ? "" : "unplayed"}`}>
                <span className={`${match.answer === "1" && match.isMatchPlayed ? (match.correct ? "correct" : "incorrect") : ""} ${match.answer === "1" ? 'answer'  : ''}`}>1</span>
                <div>/</div>
                <span className={`${match.answer === "X" && match.isMatchPlayed ? (match.correct ? "correct" : "incorrect") : ""} ${match.answer === "X" ? 'answer'  : ''}`}>X</span>
                <div>/</div>
                <span className={`${match.answer === "2" && match.isMatchPlayed ? (match.correct ? "correct" : "incorrect") : ""} ${match.answer === "2" ? 'answer'  : ''}`}>2</span>
              </div>
            }
            subTitle={match.date}
          />
        ))}
    </div>
  );
}

const style = () => css`
  max-width: ${breakpoints.lg}px;

  width: 100%;
  margin: 2rem auto 0 auto;

  > .header {
    background-color: var(--white);

    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 0.5px solid var(--midGrey);
    border-top: 0.5px solid var(--midGrey);
    border-right: 0.5px solid var(--midGrey);

    padding: 0.75rem;
    font-size: 0.75rem;
    color: var(--darkGrey);

    display: flex;

    > .spacer {
      flex-grow: 1;
    }
  }

  > .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .match-answer {
    display: flex;
    width: 70px;
    justify-content: space-between;

    font-weight: 700;
    color: var(--darkGrey);

    .correct {
      color: var(--green);
    }

    .incorrect {
      color: var(--red);
    }

    &.unplayed > div, &.unplayed > span:not(.answer) {
      opacity: 0.5;
    }
  }
`;

export default RegistrationTippingMyAnswer;
