import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import useSWR from "swr";
import { swrFetcher } from "../../utilities/swr-fetcher";

// Redux actions
import { showContextMenu } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import notificationErrorTypes from "../../config/notificationErrorTypes";

// Components
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import SocialPost from "./SocialPost";
import ScrollView from "../ui/ScrollView";
import { MoreVertIcon, InformationOutlineIcon, ArrowForwardIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import getLinkPathFromPageObject from "../../utilities/get-link-path-from-page-object";
import { PostSkeletonGroup } from "../ui/PostSkeleton";
import NotificationNotFound from "../ui/NotificationNotFound";

/** `SocialFeed` handles general feed behaviour. This includes fetching posts,
 * displaying error-messages if fetching fails and controlling feed end conditions
 * etc.
 *
 * Note that `SocialFeed` is a view and is meant to be rendered by the router.
 * It doesn't really make sense to use this component directly.
 *
 * @example
 * ```jsx
 * <Route exact key={996} path="/:appname/Social/:pageId" component={SocialFeed} />
 * ```
 *
 */
function SocialSingle(props) {
  const { match } = props;
  const { pages } = useSelector((state) => state.pages);
  const { language: lang } = useSelector((state) => state.language);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  let URL = `social/${page.dataId}/posts/${match.params.postId}`;

  const {
    data: post,
    error: error,
    isValidating: loading,
  } = useSWR(URL, swrFetcher, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // If the error is 404, we don't want to retry
      if (error.status === 404) {
        return;
      }

      // if error is not 404, retry 3 times with 5 seconds delay
      if (retryCount >= 3) return;
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
  });

  function goToSocial() {
    pageNavigatorV2({ path: getLinkPathFromPageObject(page, "absolute"), direction: "forward", pageId: page.id });
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={`Post from ${page.title}`}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showContextMenu([
                  {
                    title: `Gå til ${page.title}`,
                    icon: <ArrowForwardIcon />,
                    callback: goToSocial,
                  },
                ])
              )
            }
          >
            <MoreVertIcon />
          </ActionWrapper>
        }
      />

      <ScrollView style={{ padding: "1rem 0" }} id="social-scroll-view">
        {/* Spinner */}
        {loading && !error && <PostSkeletonGroup title={false} swipe={false} numberOfPosts={1} />}

        {/* Error */}
        {!loading && error && <NotificationNotFound error={error} type={notificationErrorTypes.postNotFound} lang={lang} />}

        {/* Posts */}
        {!loading && !error && <SocialPost post={post} key={`social-post-${post.id}`} />}

        {!loading && !error && (
          <StatusBox
            style={{ marginTop: "2rem" }}
            icon={<InformationOutlineIcon />}
            title={lang.thisIsASearchResult}
            content={lang.thePostIsFrom__placeholder__.replace(/{{placeholder}}/gi, page.title)}
            action={{
              title: lang.visit__placeholder__.replace(/{{placeholder}}/gi, page.title),
              callback: goToSocial,
            }}
          />
        )}
      </ScrollView>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  lang: state.language.language,
  posts: state.social.posts,
  endOfFeed: state.social.endOfFeed,
  postsLoading: state.social.loading,
  postsError: state.social.error,
});

export default connect(mapStateToProps)(SocialSingle);
