/**
 * Returns hours and minutes for a 24-hour clock
 * @param { number[] | undefined } allowedMinutes
 * @returns {{hours: string[], minutes: []}}
 */
const getHoursAndMinutes = ({ allowedMinutes = undefined } = undefined) => {

  let hours = [];
  let minutes = [];

  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      hours.push("0" + JSON.stringify(i));
    } else {
      hours.push(i);
    }
  }
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push("0" + JSON.stringify(i));
    } else {
      minutes.push(i);
    }
  }

  if (allowedMinutes) minutes = minutes.filter((m) => allowedMinutes.includes(parseInt(m)));

  return { hours, minutes };
};

export default getHoursAndMinutes;