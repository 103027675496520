import {
  GET_NEWS_POSTS,
  GET_NEWS_POSTS_FAILURE,
  GET_NEWS_POSTS_SUCCESS,
  SET_END_OF_NEWS_FEED,
  RESET_NEWS_FEED,
  RESET_NEWS_FEEDS,
  REFETCH_NEWS_POST_SUCCESS,
  REFETCH_NEWS_POST_COMMENTS,
  REFETCH_NEWS_POST_COMMENTS_FAILURE,
  REFETCH_NEWS_POST_COMMENTS_SUCCESS,
  REFETCH_NEWS_POST_LIKES_SUCCESS,
  LIKE_NEWS_POST,
  LIKE_NEWS_POST_FAILURE,
  LIKE_NEWS_POST_SUCCESS,
  UNLIKE_NEWS_POST,
  UNLIKE_NEWS_POST_FAILURE,
  UNLIKE_NEWS_POST_SUCCESS,
  ADD_NEWS_POST_COMMENT,
  ADD_NEWS_POST_COMMENT_SUCCESS,
  ADD_NEWS_POST_COMMENT_FAILURE,
  SET_POST_AS_DELETED,
  UNSET_POST_AS_DELETED,
  REMOVE_POST_FROM_FEED,
  READ_NEWS_POST,
  READ_NEWS_POST_SUCCESS,
  READ_NEWS_POST_FAILURE,
  REFETCH_NEWS_POST_COMMENTS_LIKES,
  SET_UNREAD_NEWS_POSTS_COUNT,
  GET_FRONTEND_ADMIN_MAPPINGS_SUCCESS,
  RESET_FRONTEND_ADMIN_MAPPINGS,
  UPDATE_NEWS_POST_PIN_ON_FEED,
} from "../actions/actionTypes";

const initialState = {
  unread: {
    posts: [],
    count: 0,
    loading: false,
    error: false,
    endOfFeed: false,
  },
  read: {
    posts: [],
    loading: false,
    error: false,
    endOfFeed: false,
  },
  all: {
    posts: [],
    loading: false,
    error: false,
    endOfFeed: false,
  },
  deletedPosts: [],
  postsWithLoadingLikes: [],
  postsWithLoadingComments: [],
  postsWithLoadingSwipes: [],
  postsWithCommentsBeingAdded: [],
  frontendAdminMappings: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    /************ Post feed ************/
    case GET_NEWS_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: true,
          error: false,
        },
      };

    case GET_NEWS_POSTS_FAILURE:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: true,
          endOfFeed: true,
        },
      };

    case GET_NEWS_POSTS_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          posts: [...state[action.payload.feedType].posts, ...action.payload.posts],
        },
      };

    case SET_END_OF_NEWS_FEED:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          endOfFeed: true,
          loading: false,
        },
      };

    case SET_UNREAD_NEWS_POSTS_COUNT:
      return {
        ...state,
        unread: {
          ...state.unread,
          count: action.payload,
        },
      };

    case RESET_NEWS_FEEDS:
      return { ...initialState, frontendAdminMappings: state.frontendAdminMappings };

    case RESET_NEWS_FEED:
      return {
        ...state,
        [action.payload]: {
          posts: [],
          loading: false,
          error: false,
          endOfFeed: false,
        },
      };

    /************ Likes ************/
    case LIKE_NEWS_POST:
    case UNLIKE_NEWS_POST:
      return {
        ...state,
        postsWithLoadingLikes: [...state.postsWithLoadingLikes, action.payload],
      };

    case LIKE_NEWS_POST_SUCCESS:
    case LIKE_NEWS_POST_FAILURE:
    case UNLIKE_NEWS_POST_SUCCESS:
    case UNLIKE_NEWS_POST_FAILURE:
      return {
        ...state,
        postsWithLoadingLikes: state.postsWithLoadingLikes.filter((p) => p !== action.payload),
      };

    case REFETCH_NEWS_POST_LIKES_SUCCESS: {
      /**
       * @param {integer} action.payload.postId
       * @param {array} action.payload.likes
       * @param {string} action.payload.action.payload.feedType
       */

      let { feedType, post, postId } = action.payload;
      let replaceIndex = state[feedType].posts.findIndex((p) => p.id === postId);

      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.slice(0, replaceIndex), post, ...state[feedType].posts.slice(replaceIndex + 1)],
        },
      };
    }

    case REFETCH_NEWS_POST_COMMENTS_LIKES: {
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          posts: state[action.payload.feedType].posts.map((post) =>
            post.id !== action.payload.newsId
              ? post
              : {
                  ...post,
                  comments: post.comments.map((comment) =>
                    comment.id === action.payload.commentId
                      ? {
                          ...comment,
                          likes: action.payload.likes,
                        }
                      : comment
                      ? {
                          ...comment,
                          comments: comment.comments.map((childComment) =>
                            childComment.id === action.payload.commentId
                              ? {
                                  ...childComment,
                                  likes: action.payload.likes,
                                }
                              : childComment
                          ),
                        }
                      : comment
                  ),
                }
          ),
        },
      };
    }

    /************ Comments ************/
    case ADD_NEWS_POST_COMMENT:
      return {
        ...state,
        postsWithCommentsBeingAdded: [...state.postsWithCommentsBeingAdded, action.payload],
      };

    case ADD_NEWS_POST_COMMENT_FAILURE:
    case ADD_NEWS_POST_COMMENT_SUCCESS:
      return {
        ...state,
        postsWithCommentsBeingAdded: state.postsWithCommentsBeingAdded.filter((id) => id !== action.payload),
      };

    case REFETCH_NEWS_POST_COMMENTS:
      return {
        ...state,
        postsWithLoadingComments: [...state.postsWithLoadingComments, action.payload],
      };

    case REFETCH_NEWS_POST_COMMENTS_FAILURE:
      return {
        ...state,
        postsWithLoadingComments: state.postsWithLoadingComments.filter((id) => id !== action.payload),
      };

    case REFETCH_NEWS_POST_COMMENTS_SUCCESS: {
      let { feedType, postId, comments } = action.payload;
      let replaceIndex = state[feedType].posts.findIndex((p) => p.id === postId);

      return {
        ...state,
        postsWithLoadingComments: state.postsWithLoadingComments.filter((id) => id !== postId),
        [feedType]: {
          ...state[feedType],
          posts: [
            ...state[feedType].posts.slice(0, replaceIndex),
            { ...state[feedType].posts[replaceIndex], comments },
            ...state[feedType].posts.slice(replaceIndex + 1),
          ],
        },
      };
    }
    /************ Single post  ************/
    case REFETCH_NEWS_POST_SUCCESS: {
      let { feedType, post } = action.payload;
      let replaceIndex = state[feedType].posts.findIndex((p) => p.id === post.id);

      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.slice(0, replaceIndex), post, ...state[feedType].posts.slice(replaceIndex + 1)],
        },
      };
    }

    case READ_NEWS_POST:
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes, action.payload],
      };

    case READ_NEWS_POST_SUCCESS:
    case READ_NEWS_POST_FAILURE:
      return {
        ...state,
        postsWithLoadingSwipes: state.postsWithLoadingSwipes.filter((p) => p !== action.payload),
      };

    case SET_POST_AS_DELETED:
      /** Adds post-id to the array of deleted posts
       * @param {integer} action.payload - Id of post to set as removed
       */
      return {
        ...state,
        deletedPosts: [...state.deletedPosts, action.payload],
      };

    case UNSET_POST_AS_DELETED:
      /** Remove post-id from the array of deleted posts
       * @param {integer} action.payload - Id of post to set as removed
       */
      return {
        ...state,
        deletedPosts: state.deletedPosts.filter((p) => p.id === action.payload),
      };

    case REMOVE_POST_FROM_FEED: {
      /** Remove post-id from the array of deleted posts
       * @param {Object} action.payload - Id of post to set as removed
       * @param {integer} action.payload.postId - Id of post to set as removed
       * @param {string} action.payload.feedType - the feedType currently being used
       */
      let { feedType, postId } = action.payload;
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: state[feedType].posts.filter((p) => p.id !== postId),
        },
      };
    }

    case UPDATE_NEWS_POST_PIN_ON_FEED:
      let { postId, feedType } = action.payload;
      let [post] = state[feedType].posts.filter((p) => p.id === postId);
      let deletedpostArray = state.deletedPosts.filter((p) => p.id === postId);
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: [
            {
              ...post,
              isPinned: true,
            },
            ...state[feedType].posts.filter((p) => p.id !== postId),
          ],
        },
        deletedPosts: deletedpostArray,
      };

    case GET_FRONTEND_ADMIN_MAPPINGS_SUCCESS:
      return {
        ...state,
        frontendAdminMappings: action.payload,
      };

    case RESET_FRONTEND_ADMIN_MAPPINGS:
      return {
        ...state,
        frontendAdminMappings: [],
      };

    default:
      return state;
  }
}
