import { css } from "emotion";
import { AlertCircleIcon, CheckCircleIcon, HourglassEmptyIcon } from "mdi-react";
import { useEffect, useState } from "react";
import pushNotificationsChecks from "../../../utilities/push-notifications/push-notifications-checks";

/**
 * Recursive function to perform the checks defined in pushNotificationsChecks.
 * Will keep iterating over the checks until the last one has been made then it will return
 * a complete list of the checks with their individual results
 */
function performChecks({ checkIndex = 0, checks = pushNotificationsChecks } = {}) {
  let result = checks[checkIndex].performCheck();

  // Updates the current check in the modifiedChecks array
  let modifiedCopyOfChecks = [
    ...checks.slice(0, checkIndex),
    {
      ...checks[checkIndex],
      result: result === true ? "ok" : "error",
    },
    ...checks.slice(checkIndex + 1),
  ];

  // Perform checks until we're at the last check
  if (checkIndex + 1 < checks.length) {
    // Note that we have to return the function call in order for the recursion to work.
    return performChecks({ checkIndex: ++checkIndex, checks: modifiedCopyOfChecks });
  } else {
    // We're at the last check so lets return the complete list of checks including the results
    return modifiedCopyOfChecks;
  }
}

export default function NotificationCheckModal(props) {
  let [performedChecks, setPerformedChecks] = useState([]);

  useEffect(() => {
    let result = performChecks();
    setPerformedChecks(result);
  }, []);

  return (
    <div className={componentStyle()}>
      <div className="check-container">
        <p className="header">Notification check</p>

        {performedChecks.map((check) => {
          return (
            <p className="check">
              {!check.result && <HourglassEmptyIcon />}
              {check.result === "ok" && <CheckCircleIcon style={{ color: "var(--green)" }} />}
              {check.result === "error" && <AlertCircleIcon style={{ color: "var(--red)" }} />}
              {check.name}
            </p>
          );
        })}
      </div>
    </div>
  );
}

const componentStyle = () => css`
  max-width: 700px;
  margin: 1rem auto;

  div.check-container {
    padding: 1rem;
    border-radius: 3px;
    border: 1px solid var(--midGrey);
    margin: 0 1rem;

    p.header {
      font-weight: 700;
      color: var(--black);
      margin-bottom: 1rem;
    }

    .check {
      margin: 0 0 0.65rem 0;
      color: var(--black);
      svg {
        margin-bottom: -3px;
        margin-right: 0.35rem;
        width: 1.15rem;
        height: 1.15rem;
      }
    }
  }
`;
