// Libs
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import TabBar from "../../ui/TabBar";

// Actions
import { setActiveTab } from "../../../actions/contactsActions";

// Hooks
import useCurrentPage from "../../../hooks/useCurrentPage";

// Utilities
import { getTitlesForTabBar } from "../utilities/getTitlesForTabBar";

const ContactsTabBar = ({ match }) => {
  const dispatch = useDispatch();

  const page = useCurrentPage({ pageId: match.params.pageId });
  const activeTab = useSelector((state) => state.contact.activeTab);
  const tabs = getTitlesForTabBar({ config: page.config });

  if (tabs.length === 0) return null;

  return (
    <TabBar
      activeTabIndex={activeTab}
      tabs={tabs.map((tab, tabIndex) => ({
        title: tab,
        onClick: () => dispatch(setActiveTab(tabIndex)),
      }))}
    />
  );
};

export default ContactsTabBar;
