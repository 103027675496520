import { css } from "emotion";
import MainActionsBar from "./components/MainActionsBar";
import SelectionPanel from "./components/SelectionPanel";
import TargetGroupPanel from "./components/TargetGroupPanel";
import { magicNumbers } from "./config/magicNumbers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getInitialData, setInitialTargetGroup } from "./actions/targetGroupModalActions";
import { hideModalPage, showDialog, updateModalPage } from "../../../actions/uiActions";
import styleTypes from "../../../config/styleTypes";
import useLang from "../../../hooks/useLang";

/**
 * @param {Object} props
 * @param {Function} props.onTargetGroup eventHandler to receive a targetGroup-object when user is finished creating a targetGroup
 * @returns
 */
function TargetGroupModal({ targetGroup = {}, onTargetGroup }) {
  const dispatch = useDispatch();
  const lang = useLang();
  const stateTargetGroup = useSelector((s) => s.targetGroupModal.targetGroup);
  const [changeCounter, setChangeCounter] = useState(0);
  const changeCounterRef = useRef(changeCounter);

  function closeModalPage() {
    if (changeCounterRef.current > 1) {
      // There is actual changes and user needs to be prompt before closing
      dispatch(
        showDialog({
          styleType: styleTypes.error,
          title: lang.dialog_unsavedChangesTitle,
          content: lang.dialog_unsavedChangesContent,
          primaryActionTitle: lang.dialog_unsavedChangesPrimaryAction,
          primaryAction: () => dispatch(hideModalPage()),
        })
      );
    } else {
      // There are no changes, so modalpage can just be closed
      dispatch(hideModalPage());
    }
  }

  useEffect(() => {
    dispatch(setInitialTargetGroup(targetGroup));
    dispatch(getInitialData());
    dispatch(updateModalPage({ closeCallback: closeModalPage }));
  }, []);

  useEffect(() => {
    setChangeCounter((s) => s + 1);
    changeCounterRef.current = changeCounter;
  }, [stateTargetGroup]);

  return (
    <div className={componentStyle()}>
      <MainActionsBar onTargetGroup={onTargetGroup} />
      <div className="panel-wrapper">
        <SelectionPanel />
        <TargetGroupPanel />
      </div>
    </div>
  );
}

const componentStyle = (props) => css`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .hide-on-desktop {
    display: none;
  }

  .checkbox-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.25rem;

    &.checked {
      color: var(--primary-color);
    }

    &.unchecked {
      color: var(--darkGrey);
    }
  }

  .search-input {
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
  }

  .list-items-container {
    padding: 1rem;
  }

  .group-selected-icon {
    width: 0.8rem;
    height: 0.8rem;
    margin-bottom: -2px;
    margin-right: 0.15rem;
    color: var(--primary-color);
  }

  .show-group-members-button {
    margin: -0.25rem;
    padding: 0.25rem;
    &:hover {
      background-color: var(--lightGrey);
      color: var(--primary-color);
    }
  }

  .remove-entry-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;

    &:hover {
      background-color: var(--lightGrey);
    }

    svg {
      color: var(--darkGrey);
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .target-group-tab {
    padding: 0 0.85rem;
    margin: 0 -0.85rem;
    border-radius: 10px;

    &.highlight {
      background-color: var(--yellowLight);
      transition: background-color 150ms ease;
    }

    &.neutral {
      transition: background-color 600ms ease;
    }
  }

  .loading-container {
    margin-top: 2rem;

    p {
      text-align: center;
      color: var(--darkGrey);
    }
  }

  .panel-wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .panel {
      flex: 50%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: var(--lightGrey);

      .panel-header {
        background-color: var(--white);
        flex-grow: 0;
        flex-shrink: 0;

        .upper-row-container {
          position: relative;
        }

        .target-group-panel-toggle {
          margin-bottom: 1px;
        }

        .see-target-group-users {
          display: inline-block;
          margin-left: 0.3rem;
          font-size: 0.9rem;
        }

        .see-members-button {
          position: absolute;
          top: 0.45rem;
          right: 0.65rem;
        }

        h2 {
          padding: 0.65rem 1rem 0.25rem 1rem;
          color: var(--darkGrey);
          font-size: 1rem;

          span.target-group-info {
            font-weight: 400;

            @media screen and (max-width: 480px) {
              display: none;
            }
          }

          svg {
            margin-bottom: -4px;
            width: 1.35rem;
            height: 1.35rem;
          }
        }

        span.count {
          opacity: 0.7;
          font-size: 0.9rem;
        }
      }

      &:nth-child(1) {
        border-right: 1px var(--midGrey) solid;
      }

      .actions-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.65rem;
      }
    }

    .tab-view {
      flex-grow: 1;
    }
  }

  @media screen and (min-width: 1200px) {
    .target-group-panel-toggle {
      &:hover {
        cursor: initial;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .hide-on-mobile {
      display: none;
    }

    .hide-on-desktop {
      display: block;
    }

    .panel.selection-panel {
      padding-bottom: ${magicNumbers.bottomBarHeight}px; /* !! Magic number !! Leaves room for fixed bottom-bar covering the panel. */
    }
    .panel.target-group-panel {
      border-top: 1px var(--midGrey) solid;
      top: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      transition: transform 600ms cubic-bezier(1, 0, 0, 1);
      transform: translateY(calc(100% - ${magicNumbers.bottomBarHeight}px));
      z-index: 4;

      &.is-visible {
        transition: transform 600ms cubic-bezier(0.7, 0, 0, 1);
        transform: translateY(
          ${magicNumbers.bottomBarHeight + magicNumbers.mainActionsBarHeight - 1}px
        ); /* -1 is to account for double border */
        .chevron-icon {
          transform: scaleY(-1);
          transition: transform 300ms ease;
        }
      }

      .target-group-panel-toggle {
        display: flex;
        align-items: center;
        width: 100%;
        padding-bottom: 1rem;
        padding-left: 1rem;

        .chevron-icon {
          transition: transform 300ms ease;
          margin-bottom: -3px;
          color: var(--darkGrey);
        }

        .seperator {
          margin: 0 0 -3px 0.5rem;
          height: 14px;
          width: 1px;
          background-color: var(--midGrey);
        }
      }
    }
  }
`;

export default TargetGroupModal;
