// Libs
import dayjs from "dayjs";

// Config
import statusType from "../config/statusType";

export default function addColorToCell(status, date) {
  let day = dayjs(date.toString(), "YYYYMMDD").day();
  if ((day === 0 || day === 6) && status !== statusType.met) return "";

  return status;
}
