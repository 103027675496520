// Libs
import React from "react";

// Utilities
import req from "../utilities/request-utility";
import {
  END_OF_NOTIFICATIONS_FEED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  RESET_NOTIFICATIONS,
} from "./actionTypes";

// Components
import { ErrorOutlineIcon } from "mdi-react";

// Actions
import { addToast } from "./uiActions";

export function getNotifications({ limit = 10, offset = null } = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().notification;

      let notifications = [];

      if (offset === null) offset = state.notifications.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_NOTIFICATIONS });

      const { data } = await req()(`notifications/?offset=${offset}`);
      notifications = data;

      /* Explanation of the reduce function
      
      In order to make sure we only have 1 notification per action per post, I use the reduce function to create an object, with 1 key for each different action per post
      {
        action_#1_newsID_#1: [{notification},{notification} ]
        action_#2_newsID_#1: [{notification},{notification} ]
        action_#1_newsID_#2: [{notification},{notification} ]
        action_#1_newsID_#2: [{notification},{notification} ]
      }
      */

      if (notifications.length) {
        const groupedNotificationsObject = notifications.reduce((notifications, notification) => {
          let key = `${notification.action}_${notification.topLevelContentId}${
            notification.parentContentId ? `_${notification.parentContentId}` : ""
          }`;

          notifications[key] = notifications[key] || [];
          notifications[key].push(notification);
          return notifications;
        }, {});

        let groupedNotificationsArray = [];
        for (let key in groupedNotificationsObject) {
          let currentGroup = groupedNotificationsObject[key];

          // Make a set of unique users involved in order to make an accurate
          // "<user> and <N> others".
          let uniqueUserIds = new Set();
          currentGroup.forEach((n) => {
            uniqueUserIds.add(n?.triggeredByUser?.id);
          });

          const sortedGroup = currentGroup.sort(compareDates);

          groupedNotificationsArray.push({
            notificationGroup: [...sortedGroup],
            userCountInNotificationGroup: uniqueUserIds.size,
            ...sortedGroup[0],
          });
        }

        function compareDates(a, b) {
          return b.date - a.date;
        }

        dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          payload: { notifications: groupedNotificationsArray },
        });
      } else {
        dispatch({ type: END_OF_NOTIFICATIONS_FEED });
      }
    } catch (error) {
      dispatch({ type: GET_NOTIFICATIONS_FAILURE });
    }
  };
}

export function resetNotifications() {
  return async function (dispatch) {
    dispatch({ type: RESET_NOTIFICATIONS });
  };
}
