// Config
import { SHOW_ALL } from "../components/pageBuilder/config/filterTypes";

// ActionTypes
import { UPDATE_PAGE_BUILDER_FILTER_PAGES_BY, UPDATE_PAGE_BUILDER_SHOW_OPTIONS } from "../actions/actionTypes";

const initialState = {
  filters: {
    active: SHOW_ALL,
  },
  showOptions: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE_BUILDER_FILTER_PAGES_BY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        },
      };
    }

    case UPDATE_PAGE_BUILDER_SHOW_OPTIONS: {
      return {
        ...state,
        showOptions: action.payload,
      };
    }

    default:
      return state;
  }
}
