import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import req from "../../utilities/request-utility";

// Actions
import { addToast } from "../../actions/uiActions";
import { renewToken } from "../../actions/authActions";
import { getLanguage } from "../../actions/languageActions";

// Components
import UserProfile from "./UserProfile";
import UserPointlog from "./UserPointlog";
import UserSettings from "./UserSettings";
import TopBar from "../../components/ui/TopBar";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";
import Page from "../../components/ui/Page";
import { CogOutlineIcon, TrophyVariantOutlineIcon, AccountOutlineIcon } from "mdi-react";
import saveStateToLocalStorage from "../../utilities/save-state-to-local-storage";
import InlineSpinner from "../ui/InlineSpinner";
import store from "../../store";

function NewMyProfile(props) {
  // Redux state
  const authUser = useSelector((state) => state.auth.user);
  const { language: lang } = useSelector((state) => state.language);
  const { appConfig } = useSelector((state) => state);

  // Props
  const { match } = props;

  // Variables
  const enablePointLog = appConfig.enableHighscore || false;

  // removes the entire page while reload happens
  const [reloadingLanguage, setReloadingLanguage] = useState(false);

  // Tab
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Profile
  const [user, setUser] = useState({
    data: null,
    loading: true,
    error: false,
  });

  // Pointlog
  const [pointlog, setPointlog] = useState({
    data: null,
    loading: true,
    error: false,
  });

  // Settings
  const [settings, setSettings] = useState({
    data: null,
    loading: true,
    error: false,
  });

  // An array of settings that are currently being updated
  const [activeIdentifiers, setActiveIdentifiers] = useState([]);

  useEffect(() => {
    // Get tabId from queryParam
    let queryStrings = queryString.parse(window.location.search);

    if (queryStrings.tab) setActiveTabIndex(Number(queryStrings.tab));

    // Fetch data from api
    getUserData();
    getPointlog();
    getSettings();

    // eslint-disable-next-line
  }, []);

  async function getUserData() {
    try {
      let { data } = await req().get(`/users/${match.params.userId}/`);
      setUser({
        ...user,
        data,
        loading: false,
      });
    } catch (err) {
      setUser({
        ...user,
        loading: false,
        error: true,
      });
    }
  }

  async function getPointlog() {
    try {
      let { data } = await req().get(`/pointlog/`);
      setPointlog({
        ...pointlog,
        data,
        loading: false,
      });
    } catch (err) {
      setPointlog({
        ...pointlog,
        loading: false,
        error: true,
      });
    }
  }

  async function getSettings() {
    try {
      let { data } = await req()(`settings`);
      setSettings({
        ...settings,
        data,
        loading: false,
        error: false,
      });
    } catch (err) {
      setSettings({
        ...settings,
        loading: false,
        error: true,
      });
    }
  }

  async function updateSetting(setting) {
    try {
      // Activeate spinner (after 300ms)
      let timeout = setTimeout(() => setActiveIdentifiers([...activeIdentifiers, setting.identifier]), 300);

      // Get settingsdatas
      let { data } = await req().put(`/settings/${setting.identifier}`, { value: setting.value });

      // Cancel spinner (request finished in less than 300ms)
      clearTimeout(timeout);

      // Update settingsdata
      setSettings({
        ...settings,
        data,
      });
    } catch (err) {
      props.addToast({
        styleType: "error",
        title: lang.networkError,
        content: lang.errorCouldNotFetchFrom__placeholder__.replace(/{{placeholder}}/gi, lang.settings.toLowerCase()),
        duration: 20000,
      });
    } finally {
      // Remove spinner
      setTimeout(() => {
        let index = activeIdentifiers.indexOf(setting.identifier);
        index && setActiveIdentifiers([...activeIdentifiers.slice(0, index), ...activeIdentifiers.slice(index, 0)]);
      }, 300);

      // If language id is the setting being updated update jwt token and refresh page
      if (setting.identifier === "languageId") {
        setReloadingLanguage(true);

        props.renewToken(() => {
          props.getLanguage(() => {
            saveStateToLocalStorage(store.getState());
            document.location.reload(true);
          });
        });
      }
    }
  }

  function getTabs() {
    // Create tabs (This is not the most beautiful code)
    // The profile will always be the first tab
    let tabs = [
      <>
        <AccountOutlineIcon /> {lang.myProfile}
      </>,
    ];
    // If enablePointLog is set for this app add the pointlog
    if (enablePointLog)
      tabs.push(
        <>
          <TrophyVariantOutlineIcon /> {lang.pointlog}
        </>
      );
    // the settings tab will also always be present
    tabs.push(
      <>
        <CogOutlineIcon /> {lang.settings}
      </>
    );
    return tabs;
  }

  function getTabComponents() {
    // This is sort of the same deal as with the tabs. Every app has profile and settings but only some have a pointlog
    // Start by creating an array with the profile as the first element
    let tabComponents = [
      <UserProfile
        userData={user.data}
        userDataLoading={user.loading}
        userDataError={user.error}
        getUserData={getUserData}
        showContactButtons={false}
        allowUserToEdit={Number(match.params.userId) === authUser.id ? true : false}
      />,
    ];
    // If pointlog is enabled for this app, also add  this to the array
    if (enablePointLog) {
      tabComponents.push(
        <UserPointlog
          pointlog={pointlog.data}
          pointlogLoading={pointlog.loading}
          pointlogError={pointlog.error}
          primaryColor={appConfig.primaryColor}
        />
      );
    }
    // Add the settings to the array too
    tabComponents.push(
      <UserSettings
        settings={settings.data}
        settingsLoading={settings.loading}
        settingsError={settings.error}
        updateSetting={updateSetting}
        activeIdentifiers={activeIdentifiers}
      />
    );

    return tabComponents;
  }

  if (reloadingLanguage) {
    return (
      <Page>
        <TopBar title="..." />
        <TabBar tabs={[]} />
        <InlineSpinner title="Updating language..." style={{ marginTop: "3rem" }} />
      </Page>
    );
  }

  return (
    <Page>
      <TopBar title={lang.profile} useDefaultBackButton={true} />

      {Number(match.params.userId) === authUser.id && (
        <TabBar
          activeTabIndex={activeTabIndex}
          tabs={getTabs().map((tab, tabIndex) => ({
            title: tab,
            onClick: () => {
              setActiveTabIndex(tabIndex);
            },
          }))}
        />
      )}
      <TabView activeTabIndex={activeTabIndex} tabs={getTabComponents()} useScrollView={true} />
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  renewToken: bindActionCreators(renewToken, dispatch),
  getLanguage: bindActionCreators(getLanguage, dispatch),
});

export default connect(null, mapDispatchToProps)(NewMyProfile);
