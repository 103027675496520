// ActionTypes
import { GET_CHECKLISTS, GET_CHECKLISTS_FAILURE, GET_CHECKLISTS_SUCCESS, SET_CHECKLIST_TAB } from "../actions/actionTypes";

const initialState = {
  activeTab: 0,
  completed: {
    loading: false,
    error: false,
    checklists: [],
  },
  uncompleted: {
    loading: false,
    error: false,
    checklists: [],
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CHECKLIST_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case GET_CHECKLISTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: true,
        },
      };

    case GET_CHECKLISTS_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          checklists: action.payload.checklists,
        },
      };

    case GET_CHECKLISTS_FAILURE:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: true,
        },
      };

    default:
      return state;
  }
}
