// Libs
import { ErrorOutlineIcon } from "mdi-react";
import React from "react";

// Utilities and config
import req from "../utilities/request-utility";

import filterBirthdays from "../utilities/filter-birthdays";

// Actions
import { addToast } from "./uiActions";

// Action types
import {
  GET_BIRTHDAYS,
  GET_BIRTHDAYS_FAILURE,
  GET_BIRTHDAYS_SUCCESS,
  RESET_BIRTHDAYS,
  SET_BIRTHDAYS_FILTER_VALUE,
  SET_IS_NO_BIRTHDAYS,
  SET_NEW_BIRTHDAY_YEAR,
  SET_RELATED_APPS_BIRTHDAYS,
} from "./actionTypes";

export function getRelatedBirthdayApps() {
  return async function (dispatch, getState) {
    try {
      let { data } = await req()(`birthdays/related-apps`);

      dispatch({
        type: SET_RELATED_APPS_BIRTHDAYS,
        payload: data,
      });

      // Set default option for related apps
      const myApp = getState().appConfig;
      dispatch({
        type: SET_BIRTHDAYS_FILTER_VALUE,
        payload: { key: "selectedAppId", value: myApp.appId },
      });
    } catch {} // not critical if it fails.
  };
}

export function getBirthdays({ limit = 10 } = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().birthday;
      let currentAppId = getState().appConfig.appId;
      let offset = state.newBirthdaysOffset;
      let isNextYear = state.isNextYear;
      const { language: lang } = getState().language;

      if (state.loading || state.error || state.isNoBirthdays) return;
      dispatch({ type: GET_BIRTHDAYS });

      /* We loop through the birthdays to find out how many we have fetched and thereby what the offset should be.
         Each date might contain more than one birthday which is why we increase the offset with the length of the birthday container. */
      if (offset !== 0) {
        offset = 0;
        state.newBirthdays.forEach((outerBirthday) => {
          offset += outerBirthday.birthdaysContainer.length;
        });
      }

      // Construct the URL
      let URL = `birthdays/?limit=${limit}`;
      URL += `&offset=${offset}`;
      URL += `&nextYear=${isNextYear}`;
      URL += `&withYear=1`;

      let selectedAppId = state.filters.selectedAppId;
      if (selectedAppId && selectedAppId === "ALL") URL += `&includeUsersFromRelatedApps=1`;
      if (selectedAppId && selectedAppId !== "ALL" && selectedAppId !== currentAppId)
        URL += `&showUsersFromRelatedAppId=${selectedAppId}`;

      // Get the birthdays
      let { data: apiBirthdays } = await req()(URL);

      // This array seems a bit unneccesary
      let birthdays = apiBirthdays;

      if (apiBirthdays.length < 1) {
        /*  If the API doesn't return any birthdays we first try to increment the year, to see if we have just reached the end of birthdays in the current year.
            If the API once again return an empty array we conclude that there is indeed no birthdays at all in the current app.
            We keep count of how many times in a row the API has returned an empty array with the variable isNoBirthdaysCount, 
            which start out being 0 (and for every succesful return is set back to 0). For every empty array returned it is incremented with 1, meaning that if it is more than 1,
            we conclude that the current app does not have any birthdays to show.
        */

        if (state.isNoBirthdaysCount > 1) {
          dispatch({
            type: SET_IS_NO_BIRTHDAYS,
          });
        } else {
          // --> Merge newBirthdays into the birthdays array
          // --> Reset the newBirthdays to an empty array (ready to hold only the birthdays for the current year. This is used in the filter birthday function, which organises birthdays occuring on the same day into the same object and does not take account of the year.)
          // --> Set the newBirthdaysOffset to 0
          // --> Set the isNextYear to 1
          // --> Update the currentYear (plus it with 1 year)
          dispatch({
            type: SET_NEW_BIRTHDAY_YEAR,
          });
          // Fetch the birthdays again
          getBirthdays();
        }
      } else {
        // Manipulate the birthdays to store the ones with the same date in section together
        // --> Append the newly fetched birthdays to the newBirthdays array
        // --> update the newBirthdaysOffset count
        // Return the birthdays array with the new birthdays attached
        birthdays = filterBirthdays({
          birthdays: apiBirthdays,
          language: lang,
          stateBirthdays: state.newBirthdays,
          year: state.currentYear,
        });

        dispatch({
          type: GET_BIRTHDAYS_SUCCESS,
          payload: { birthdays, offset: apiBirthdays.length },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BIRTHDAYS_FAILURE,
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.couldNotFetchBirthdays,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function showBirthdayFilters() {
  return {
    type: SET_BIRTHDAYS_FILTER_VALUE,
    payload: { key: "active", value: true },
  };
}

export function hideBirthdayFilters() {
  return {
    type: SET_BIRTHDAYS_FILTER_VALUE,
    payload: { key: "active", value: false },
  };
}

/** Updates a key in the filters object
 * @param {String} key - The identifier in the object. I.e. `selectedAppId`
 * @param {Any} value - The value to set to the corresponding key ie.: `42`
 */
export function setBirthdayFilterValue({ key, value }) {
  return {
    type: SET_BIRTHDAYS_FILTER_VALUE,
    payload: { key, value },
  };
}

export function resetAll() {
  return async function (dispatch, getState) {
    dispatch({
      type: RESET_BIRTHDAYS,
    });
  };
}
