import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { showContextMenu, showModalPage, hideModalPage, showDialog, showLightBox } from "../../actions/uiActions";
import Author from "../ui/Author";

// Components
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

import UserProfileEditModal from "./UserProfileEditModal";
import {
  DotsVerticalIcon,
  EditIcon,
  PhoneIcon,
  PhoneClassicIcon,
  EmailOutlineIcon,
  FileDocumentOutlineIcon,
  InformationOutlineIcon,
  ClipboardTextOutlineIcon,
  MapMarkerIcon,
  AccountIcon,
  OpenInNewIcon,
  CakeIcon,
  DateRangeIcon,
  BusinessIcon,
} from "mdi-react";
import ContactMenuOptions from "../contacts/components/ContactsMenuOptions";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../utilities/get-user-group-title";
import req from "../../utilities/request-utility";

// Style
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import dayjs from "dayjs";
import useAuthorModal from "../../hooks/useUserModal";

const UserProfile = (props) => {
  const appId = useSelector((s) => s.appConfig.appId);
  let showEmployeeId = [35, 36, 37, 38, 39, 41, 42].includes(appId) ? false : true;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});
  const userModal = useAuthorModal();

  let {
    userDataLoading = undefined,
    userDataError = undefined,
    userData,
    externalUserId = undefined,
    useExternalData = false,
    getUserData,
    allowUserToEdit,
    showContactButtons = true,
    showContextMenu,
    showModalPage,
    hideModalPage,
    showDialog,
    showLightBox,
    lang,
  } = props;

  function getExternalUser(userId) {
    req()(`users/${userId}`)
      .then(({ data: user }) => {
        setUser(user);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (useExternalData && externalUserId) {
      getExternalUser(externalUserId);
      return;
    }

    setLoading(userDataLoading);
    setError(userDataError);
    setUser(userData);
  }, [userDataLoading, userDataError, userData, externalUserId, useExternalData]);

  const getUsersBirthday = (birthday) => {
    if (birthday?.length === 4) {
      return <p className="text-content">{dayjs(birthday, "MMDD").format("D. MMMM")}</p>;
    } else if (birthday?.length === 8) {
      return <p className="text-content">{dayjs(birthday, "YYYYMMDD").format("D. MMMM, YYYY")}</p>;
    } else return <p>{birthday}</p>;
  };

  return (
    <>
      {loading && !error && <InlineSpinner style={{ marginTop: "1rem" }} />}
      {!loading && error && <StatusBox style={{ marginTop: "2rem" }} />}
      {!loading && !error && (
        <div className={`user-profile ${componentStyles(props)}`}>
          <div className={`header ${allowUserToEdit ? "" : "public-profile"}`}>
            {getProfilePicture(user, allowUserToEdit ? 45 : 256, {}, () => {
              showLightBox({
                images: [user.profilePicture],
              });
            })}
            <div>
              <h3>{user.name}</h3>
              <p className="subTitel">{getUserGroupTitle(user)}</p>
              {user.keyPerson && user?.masterGroup?.title && <p className="subTitel">{user.masterGroup.title}</p>}
              {user.application && <p style={{ color: colors.darkGrey }}>{user.application.title}</p>}
              {showContactButtons && (
                <ContactMenuOptions
                  size="large"
                  styling="filled"
                  showDownloadContactButton={true}
                  style={{ margin: "1rem 0 0 0" }}
                  {...user}
                />
              )}
            </div>

            {allowUserToEdit && (
              <DotsVerticalIcon
                className="header-context-menu-toggle"
                onClick={() =>
                  // Showps context menu
                  showContextMenu([
                    {
                      icon: <EditIcon />,
                      title: lang.edit,
                      callback: () =>
                        // Shows modal page (if edit is pressed)
                        showModalPage({
                          title: lang.editProfile,
                          content: () => <UserProfileEditModal user={user} getUserData={getUserData} />,
                          closeCallback: () =>
                            showDialog({
                              title: lang.heyThere,
                              content: lang.closeFormWarningContent,
                              primaryActionTitle: lang.yesCloseForm,
                              primaryAction: hideModalPage,
                              secondaryActionTitle: lang.noDontCloseForm,
                            }),
                          useScrollView: false,
                        }),
                    },
                  ])
                }
              />
            )}
          </div>
          <div className="content">
            <>
              <p className="label">
                <PhoneIcon /> {lang.phone}
              </p>
              <a href={"tel:" + user.phone || ""}>{user.phone ? user.phone : ""}</a>

              {user.alternativePhone && (
                <>
                  <p className="label">
                    <PhoneClassicIcon /> {lang.altPhone}
                  </p>
                  <a href={"tel:" + user.alternativePhone || ""}>{user.alternativePhone}</a>
                </>
              )}
            </>

            <>
              <p className="label">
                <EmailOutlineIcon /> {lang.email}
              </p>
              <a href={"mailto:" + user.email || ""}>{user.email ? user.email : ""}</a>
            </>

            {user.information && (
              <>
                <p className="label">
                  <InformationOutlineIcon /> {lang.information}
                </p>
                <p className="text-content">{user.information}</p>
              </>
            )}

            {user.certificates && user.certificates.length > 0 && (
              <div className="user-documents">
                <p className="label">
                  <FileDocumentOutlineIcon /> {lang.documents}
                </p>

                {user.certificates.map((certificate) => (
                  <a href={`${certificate.baseURL}${certificate.src}`} target="_blank" rel="noopener noreferrer">
                    {certificate.name}
                  </a>
                ))}
              </div>
            )}

            {!user.keyPerson && (
              <>
                <p className="label">
                  <InformationOutlineIcon />
                  {lang.aboutMe}
                </p>
                <p className="text-content">{user.about ? user.about : ""}</p>
              </>
            )}

            {!user.keyPerson && (
              <>
                <p className="label">
                  <ClipboardTextOutlineIcon /> {lang.myCompetencies}
                </p>
                <p className="text-content">{user.skills ? user.skills : ""}</p>
              </>
            )}

            {"birthday" in user && (
              <>
                <p className="label">
                  <CakeIcon />
                  {lang.birthday}
                </p>
                {getUsersBirthday(user.birthday)}
              </>
            )}

            {!user.keyPerson && (
              <>
                <p className="label">
                  <DateRangeIcon />
                  {lang?.employmentDate}
                </p>
                <p className="text-content">
                  {user.employmentDate ? dayjs(user.employmentDate).format("D. MMMM, YYYY") : ""}
                </p>
              </>
            )}

            {user.manager && (
              <>
                <p className="label">
                  <BusinessIcon />
                  {lang?.closestLeader}
                </p>
                <div
                  style={{ border: `1px ${colors.lightGrey} solid`, borderRadius: "3px", padding: "0.5rem 0.5rem 0 0.5rem" }}
                >
                  <Author user={user.manager} onClick={() => userModal(user.manager.id)} style={{ width: "100%" }} />
                </div>
              </>
            )}

            {showEmployeeId && user.externalId && (
              <>
                <p className="label">
                  <AccountIcon /> {lang.employeeId}
                </p>
                <p className="text-content">{user.externalId}</p>
              </>
            )}
            {user.address && (
              <>
                <p className="label">
                  <MapMarkerIcon /> {lang.address}
                </p>
                <p className="text-content">{user.address ? user.address : ""}</p>
              </>
            )}

            {user.externalLink && (
              <>
                <p className="label">
                  <OpenInNewIcon /> {lang.link}
                </p>
                <a className="text-content" target="blank" rel="noreferrer noopener" href={user.externalLink}>
                  {user.externalLink}
                </a>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const boxPadding = "0.5";

const componentStyles = (props) => css`
  background-color: var(--white);
  margin: ${props.allowUserToEdit ? 1 : 0}rem 0;
  position: relative;
  border-top: 1px var(--midGrey) solid;
  border-bottom: 1px var(--midGrey) solid;

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${breakpoints.lg}px;
    border-radius: 4px;
    border: 1px var(--midGrey) solid;
    margin: 1rem auto;
    flex: initial !important; /* Removes forced 100% height */
  }

  /* .contact-menu-options {
    justify-content: center;
    margin-top: 0.5rem;

    .wrapper {
      margin: 0 1rem;

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  } */

  .header-context-menu-toggle {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .header {
    display: flex;
    padding: ${boxPadding * 1.5}rem ${boxPadding}rem;
    border-bottom: 1px var(--midGrey) solid;

    &.public-profile {
      flex-direction: column;
      align-items: center;

      & > div {
        margin: 1rem 0;
        text-align: center;
      }
    }

    & > div {
      margin-right: 0.75rem;
    }

    & > div,
    & > svg {
      display: inline-block;
      vertical-align: text-top;
    }
  }
  .subTitel {
    margin: 0.35rem 0;
  }

  .content {
    margin: ${boxPadding * 2}rem;

    .label {
      color: var(--black);
      margin-bottom: ${boxPadding * 0.7}rem;

      svg {
        width: 1.15rem;
        height: 1.15rem;
        color: var(--darkGrey);
        margin-right: 0.25rem;
        margin-bottom: -3px;
      }
    }

    a,
    p.text-content {
      margin-bottom: ${boxPadding * 3.25}rem;
      display: block;
    }

    .user-documents {
      a {
        margin-bottom: 0.35rem;
      }
      a:last-of-type {
        margin-bottom: ${boxPadding * 3.25}rem;
      }
    }

    p {
      color: var(--darkGrey);
    }

    a {
      color: ${props.appConfig.primaryColor};
      text-decoration: underline;
    }

    .text-content {
      white-space: pre-wrap;
    }
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  user: state.auth.user,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showLightBox: bindActionCreators(showLightBox, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
