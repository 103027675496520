// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Styles
import colors from "../../style/colors";

/**
 *
 * @param {Object} props
 * @param {string} props.className
 * @param {Object} props.style
 * @param {string} props.color - Accent color
 * @param {HTMLElement} props.icon - Icon
 * @param {string} props.title
 * @param {string} props.description
 * @param {ReactElement} props.children
 */
const InformationBox = (props) => {
  const { primaryColor } = useSelector((state) => state.appConfig);
  const color = props.color || primaryColor;

  return (
    <div className={`${componentStyle({ color })} ${props.className || ""}`} style={props.style}>
      <div className="title-wrapper">
        {props.icon}
        <p className="information-box__title">{props.title}</p>
      </div>
      <p className="information-box__description">{props.description || props.children}</p>
    </div>
  );
};

const componentStyle = ({ color }) => css`
  display: flex;
  flex-direction: column;
  border-left: ${color};
  border: 1px solid var(--midGrey);
  border-radius: 3px;
  padding: 0.75rem;
  border-left: 3px solid ${color};

  .title-wrapper {
    display: flex;
    align-items: center;

    p.information-box__title {
      font-weight: bold;
      color: ${color};
      line-height: 1.5;
      margin: 0;
    }

    svg {
      fill: ${color};
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.25rem;
    }
  }

  p.information-box__description {
    color: var(--darkGrey);
    white-space: pre-wrap;
    line-height: 1.35;
    margin: 0.25rem 0 0 0;
  }
`;

export default InformationBox;
