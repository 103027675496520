import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { feedTypes } from "./config";
import { css } from "emotion";
import queryString from "query-string";

// Redux actions
import { getPosts, resetFeeds, getFrontendAdminMapping } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { InfoOutlineIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import FloatingSearchInput from "../ui/FloatingSearchInput";

// Config
import pageConfig from "../../config/pageConfig";
import { durations } from "../../config/animations";

// Utilities
import highlightMatch from "../../utilities/highlight-match";
import smoothScrollToFixedNumber from "../../utilities/smooth-scroll-to-fixed-number";
import useCurrentPage from "../../hooks/useCurrentPage";
import extractValueFromObject from "../../utilities/extract-value-from-object";
import NewsPost from "./NewsPost";

// Context
import { PageContext } from "./context/PageContext";

function NewsFeedFaq(props) {
  const dispatch = useDispatch();
  // page setup
  const { getPosts, resetFeeds } = props;
  const lang = useSelector((state) => state.language.language);
  const [activeAccordionId, setActiveAccordionId] = useState();
  const [hasScrolled, setHasScrolled] = useState(false);

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const includeFilesFromLinkCollection = page.config && page.config[pageConfig.INCLUDE_FILES_FROM_LINK_COLLECTION] === true;

  const sort = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SORT_ORDER });
  const isSwipeable = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SWIPEABLE });

  // Data
  const { posts: reduxPosts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  // Search term to filter the FAQs
  const [searchTerm, setSearchTerm] = useState("");

  const [posts, setPosts] = useState([]);
  const [filteredFAQs, setFilteredFAQs] = useState([...posts]);

  // Filter FAQs
  const filterFAQs = () => {
    const newFilteredFAQs = posts.filter(
      (post) =>
        post?.title?.toLowerCase()?.search(searchTerm) !== -1 || post?.content?.toLowerCase()?.search(searchTerm) !== -1
    );
    setFilteredFAQs(newFilteredFAQs);
  };

  useEffect(() => {
    filterFAQs();
    // eslint-disable-next-line
  }, [searchTerm]);

  // Open the first element if FAQ only has one post
  useEffect(() => {
    if (posts.length === 1) setActiveAccordionId(posts[0].id);
    filterFAQs();
  }, [posts]);

  useEffect(() => {
    setPosts(reduxPosts);
  }, [reduxPosts]);

  // Smooth scroll to scrollToId query-param
  useEffect(() => {
    if (filteredFAQs.length === 1) setActiveAccordionId(filteredFAQs[0].id);
    else if (filteredFAQs.length > 1 || !filteredFAQs.length) setActiveAccordionId();
  }, [filteredFAQs]);

  useEffect(() => {
    // resetFeeds();
    // getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, limit: 10000 });
    let queryStrings = queryString.parse(window.location.search);
    if (queryStrings.scrollToId) {
      // Only run once
      if (hasScrolled === true || !queryStrings.scrollToId) return;

      if (!loading && !error && posts.length) {
        setTimeout(() => {
          scrollToId(queryStrings.scrollToId);
          setHasScrolled(true);
        }, 500);
      }
    }
    // eslint-disable-next-line
  }, [posts, loading, error]);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, limit: 10000, sort, includeFilesFromLinkCollection });
    setTimeout(() => dispatch(getFrontendAdminMapping()), 1000);
    // setQueryStrings(queryString.parse(window.location.search));
    // eslint-disable-next-line
  }, []);

  function scrollToId(id) {
    setActiveAccordionId(parseInt(id));

    setTimeout(() => {
      let container = document.querySelector(`.scroll-view`);
      let accordion = document.querySelector(`[data-accordion-id='${id}']`);
      if (container && accordion)
        smoothScrollToFixedNumber({ element: container, scrollTo: accordion.offsetTop - 120, duration: durations.slow });
    }, 300);
  }

  const callBackOnTranslation = (translatedPost) => {
    const translatedPosts = posts.map((post) => {
      if (post.id === translatedPost.postId) {
        return { ...post, title: translatedPost.title, content: translatedPost.content };
      }
      return post;
    });

    setPosts(translatedPosts);
  };

  return (
    <Page>
      <PageContext.Provider value={page.id}>
        <TopBar useDefaultBackButton={true} title={page.title} />
        <ScrollView className={container()} style={posts.length > 1 ? { paddingTop: "70px" } : { paddingTop: "1rem" }}>
          {/* Loading */}
          {loading && !error && !endOfFeed && (
            <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
          )}

          {/* Nothing matches search*/}
          {filteredFAQs.length === 0 && posts.length > 0 && !loading && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noResults}
              content={lang.trySearchingForSomethingElse}
            />
          )}

          {/* End of feed with posts */}
          {posts.length > 0 && !loading && endOfFeed && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noMorePosts}
              content={lang.reachedEndOfFeed}
            />
          )}

          {/* End of feed without posts */}
          {posts.length === 0 && !loading && endOfFeed && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noPostsHere}
              content={lang.comeBackSoon}
            />
          )}

          {/* Content */}
          {filteredFAQs.map((post, questionId) => {
            return (
              <NewsPost
                key={post.id}
                data-accordion-id={post.id}
                post={{
                  ...post,
                  content: highlightMatch(post.content, searchTerm, "MARKDOWN"),
                }}
                subTypeId={page.dataId}
                useExpandCollapseLayout={true}
                expandCollapseToggle={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>{highlightMatch(post.title, searchTerm)}</p>
                  </div>
                }
                isExpanded={activeAccordionId === post.id}
                disableSwipe={!isSwipeable}
                disableLikesAndComments={true}
                callBackOnTranslation={callBackOnTranslation}
                showTitle={false}
                feedType={feedTypes.all}
              />
            );
          })}
        </ScrollView>
        {posts.length > 1 && (
          <FloatingSearchInput
            className="search-contacts-input"
            dataTestId={"inputField"}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            value={searchTerm}
            placeholder={lang.typeToFilter}
            onClearSearch={() => {
              setSearchTerm("");
            }}
          />
        )}
      </PageContext.Provider>
    </Page>
  );
}

const container = () => css`
  padding-bottom: 3rem;

  span.match {
    display: inline-block;
    border-bottom: 1px var(--darkGrey) dashed;
    background-color: var(--yellowLight);
    border-radius: 3px;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedFaq);
