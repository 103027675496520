import TabBar from "../../TabBar";
import { useDispatch, useSelector } from "react-redux";
import { setSelectionPanelTabIndex } from "../actions/targetGroupModalActions";
import InlineSpinner from "../../InlineSpinner";
import useLang from "../../../../hooks/useLang";

const StyledSpinner = () => (
  <InlineSpinner size="12" style={{ display: "inline-block", marginBottom: "-10px", marginLeft: "4px" }} />
);

function SelectionPanelTabBar(props) {
  const dispatch = useDispatch();
  const lang = useLang();
  const { users, userGroups, masterGroups, customGroups, jobTitles } = useSelector((s) => s.targetGroupModal);
  const { activeTabIndex } = useSelector((s) => s.targetGroupModal.selectionPanel);

  return (
    <TabBar
      activeTabIndex={activeTabIndex}
      minimalPadding={true}
      tabs={[
        {
          title: (
            <>
              {lang.users}
              {users.loading && <StyledSpinner />}
              {!users.loading && <span className="count"> {users.data.length}</span>}
            </>
          ),
          onClick: () => dispatch(setSelectionPanelTabIndex(0)),
          "data-test-id": "selection-panel-tab-bar__users",
        },
        {
          title: (
            <>
              {lang.subGroups}
              {userGroups.loading && <StyledSpinner />}
              {!userGroups.loading && <span className="count"> {userGroups.data.length}</span>}
            </>
          ),
          onClick: () => dispatch(setSelectionPanelTabIndex(1)),
          "data-test-id": "selection-panel-tab-bar__userGroups",
        },
        {
          title: (
            <>
              {lang.mainGroups}
              {masterGroups.loading && <StyledSpinner />}
              {!masterGroups.loading && <span className="count"> {masterGroups.data.length}</span>}
            </>
          ),
          onClick: () => dispatch(setSelectionPanelTabIndex(2)),
          "data-test-id": "selection-panel-tab-bar__masterGroups",
        },
        {
          title: (
            <>
              {lang.customGroups}
              {customGroups.loading && <StyledSpinner />}
              {!customGroups.loading && <span className="count"> {customGroups.data.length}</span>}
            </>
          ),
          onClick: () => dispatch(setSelectionPanelTabIndex(3)),
          "data-test-id": "selection-panel-tab-bar__customGroups",
        },
        {
          title: (
            <>
              {lang.jobTitles}
              {jobTitles.loading && <StyledSpinner />}
              {!jobTitles.loading && <span className="count"> {jobTitles.data.length}</span>}
            </>
          ),
          onClick: () => dispatch(setSelectionPanelTabIndex(4)),
          "data-test-id": "selection-panel-tab-bar__jobTitles",
        },
      ]}
    />
  );
}

export default SelectionPanelTabBar;
