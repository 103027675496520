import { css } from "emotion";
import { AccountCircleIcon } from "mdi-react";

/**
 * @param {Object} props
 * @param {Object} props.lang - Language object state
 * @param {Function} props.appConfig - Application config state
 */

const UserDisplayListItemExample = (props) => {
  const { lang, appConfig } = props;

  return (
    <div className={componentStyle()}>
      <p className="example-title">{lang.example} 1:</p>
      <div className="example">
        <AccountCircleIcon />
        <div className="text-container">
          <p className="author">
            {lang.firstName} {lang.lastName}
          </p>
          {appConfig.hideEmployeeId === false && <p className="author-employeeNumber">{`(${lang.employeeNumber})`}</p>}
          <p className="author-info">
            {appConfig.showMasterGroup && <span>{lang.mainGroup}</span>}
            {appConfig.showMasterGroup && (appConfig.showUserGroup || appConfig.showJobTitle) && <span>, </span>}
            {appConfig.showUserGroup && <span>{lang.subGroup}</span>}
            {appConfig.showUserGroup && appConfig.showJobTitle && <span>, </span>}
            {appConfig.showJobTitle && <span>{lang.jobTitle}</span>}
          </p>
          <p className="date">
            2 {lang.hours.toLowerCase()} {lang.ago}
          </p>
        </div>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  margin-top: 1rem;

  .example-title {
    margin-bottom: 0.35rem;
    color: var(--darkGrey);
  }

  .example {
    border: 1px solid var(--midGrey);
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0.5rem;

    svg {
      width: 58px;
      height: 58px;
      fill: var(--midDarkGrey);
    }

    .text-container {
      padding-left: 0.5rem;
      p.author {
        color: var(--trueBlack);
        margin-bottom: 0.15rem;
        font-size: 0.9rem;
      }
      p.author-info,
      p.date {
        color: var(--midDarkGrey);
        font-size: 0.8rem;
      }

      p.author-employeeNumber {
        color: var(--trueBlack);
        font-size: 0.8rem;
      }
    }
  }
`;

export default UserDisplayListItemExample;
