const YOUTUBE_WATCH_MATCH = /(?:https:\/\/)?(?:www.)?youtube.com\/watch\?v=(?<id>[A-Za-z0-9_-]*)/;
const YOUTU_BE_MATCH = /(?:https:\/\/)?(?:www.)?youtu.be\/(?<id>[A-Za-z0-9_-]*)/;
const YOUTUBE_NOOKIE_MATCH = /(?:https:\/\/)?(?:www.)?youtube-nocookie.com\/embed\/(?<id>[A-Za-z0-9_-]*)/;

const VIMEO_MATCH = /(?:https:\/\/)?(?:www.)?vimeo.com\/(?<id>[0-9]*)/;
const VIMEO_PLAYER_MATCH = /(?:https:\/\/)?(?:player.)?vimeo.com\/video\/(?<id>[0-9]*)/;

const REGEXES = [YOUTUBE_WATCH_MATCH, YOUTU_BE_MATCH, YOUTUBE_NOOKIE_MATCH, VIMEO_MATCH, VIMEO_PLAYER_MATCH];

/**
 * Takes a link from YouTube or Vimeo video and returns the embed link
 *
 * It supports the following formats:
 * - https://www.youtube.com/watch?v=[videoId]
 * - https://youtu.be/[videoId]
 * - https://www.youtube-noookie.com/embed/[videoId]
 *
 * - https://vimeo.com/[videoId]
 * - https://player.vimeo.com/video/[videoId]
 *
 * @param {string} link YouTube or Vimeo link
 * @returns {string} The embed link - e.g. https://www.youtube-nocookie.com/embed/[videoId]
 */
function getEmbedLink(link) {
  if (!link) return;

  let match;

  for (let regex of REGEXES) {
    match = link.match(regex);
    if (match) break;
  }

  if (!match) return null;

  if (link.includes("youtube")) {
    return `https://www.youtube-nocookie.com/embed/${match.groups.id}?rel=0`;
  } else if (link.includes("vimeo")) {
    return `https://player.vimeo.com/video/${match.groups.id}`;
  } else {
    return null;
  }
}

export default getEmbedLink;
