// Libraries
import React from "react";

import { css } from "emotion";

function DebugItem(props) {
  const { label, code = null, result } = props;

  return (
    <div className={componentStyles()}>
      <div className="info-wrapper">
        <p className="label">{label}</p>
        {code && <p className="code">{code}</p>}
        <p className="result">{result}</p>
      </div>
    </div>
  );
}

const componentStyles = () => css`
  .info-wrapper {
    background-color: #fff;
    border: 1px #ccc solid;
    padding: 0.5rem;
    max-width: 800px;
    margin: 0.25rem auto;
  }

  .label {
    font-weight: 700;
    margin: 0 0 0.5rem 0;
  }

  p.code {
    margin: 0 0 0.5rem 0;
    font-family: monospace;
    background-color: #eaf2f7;
    color: #0f1452;
    padding: 0.25rem 0.5rem;
    font-weight: 400;
    border-radius: 3px;
    font-size: 0.8rem;
  }

  p.result {
    font-size: 0.8rem;
    font-family: monospace;
    background-color: #dfffef;
    padding: 0.5rem;
    color: #064a28;
    border-radius: 4px;
  }
`;

export default DebugItem;
