import { AlertIcon } from "mdi-react";
import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useSelector } from "react-redux";
import { durations } from "../../../config/animations";
import colors from "../../../style/colors";
import getFormattedHour from "../../../utilities/get-formatted-hour";
import ListItem from "../../ui/ListItem";
import Notification from "../../ui/Notification";
import summaryModes from "../config/summaryModes";
import dataServices from "../dataServices";
import useHandleOverviewRegistrationClick from "../hooks/useHandleOverviewRegistrationClick";
import getRegistrationTitle from "../utilities/getRegistrationTitle";

/**
 * @param {Object} props
 * @param {Object} props.page
 * @param {Boolean} props.adminMode
 */
const RejectedRegistrations = ({ adminMode, page }) => {
  const handleClickOnReg = useHandleOverviewRegistrationClick();
  const [rejectedRegistrations, setRejectedRegistrations] = useState([]);
  const { selectedUser, summaryMode } = useSelector((s) => s.timeRegistration);
  const modalPages = useSelector((s) => s.ui.modalPages);

  useEffect(() => {
    if (adminMode === false || selectedUser) getRejectedRegistrations();
  }, []);

  // This is a bit hacky... When looking at rejected registrations a modalPage is opened.
  // When this is done, the notification is potentialle gone (user has edited or deleted)
  // and so should the rejectedRegistration-element be
  // We are therefore refetching this everytime the amount of modalpages changes
  useEffect(() => {
    if (adminMode === false || selectedUser) getRejectedRegistrations();
  }, [modalPages]);

  async function getRejectedRegistrations() {
    const rejectedRegistrations = await dataServices.getRejectedRegistrations({ page, selectedUser });
    setRejectedRegistrations(rejectedRegistrations);
  }

  return (
    <AnimateHeight
      height={rejectedRegistrations.length === 0 ? 0 : "auto"}
      duration={durations.normal}
      animateOpacity={true}
      delay={300}
    >
      <div className="rejected-registrations-container">
        <p className="headline">Afviste registreringer</p>
        {rejectedRegistrations.map((registration) => (
          <ListItem
            className="list-item rejected"
            key={`rejected-${registration.date}`}
            iconLeft={<AlertIcon color={colors.red} />}
            title={getRegistrationTitle(registration)}
            contentRight={
              <div className="content-right-container">
                <Notification notifications={registration.count} style={{ height: "19px", fontSize: "0.8rem" }} />
                {<p>{summaryMode === summaryModes.HOURS ? getFormattedHour(registration.hours) : registration.count}</p>}
              </div>
            }
            onClick={() => handleClickOnReg({ registration, activeTab: 1 })}
          />
        ))}
      </div>
    </AnimateHeight>
  );
};

export default RejectedRegistrations;
