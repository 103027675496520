// Libs
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { hideModalPage, showContextMenu } from "../../../actions/uiActions";

// Icons
import { ExportIcon, PencilIcon, TrashCanOutlineIcon } from "mdi-react";

// Hooks
import UseHandleExport from "../hooks/useHandleExport";
import UseHandleEdit from "../hooks/useHandleEdit";
import UseHandleDelete from "../hooks/useHandleDelete";

export default function UseToggleContextMenu() {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.timeRegistration.permissions);
  const handleExport = UseHandleExport();
  const handleEdit = UseHandleEdit();
  const handleDelete = UseHandleDelete();

  function toggleContextMenu({ value, timeRegistrationId, user, timeSheet, callback = () => {} }) {
    let contextMenu = [];

    if (permissions?.canExport && timeSheet?.timeRegistrationData?.isApproved) {
      contextMenu.push({
        icon: <ExportIcon />,
        title: "Eksporter",
        callback: () => handleExport({ value, timeRegistrationId, callback }),
      });
    }

    if (permissions?.canRegisterForOthers) {
      contextMenu.push({
        icon: <PencilIcon />,
        title: "Rediger",
        callback: () =>
          handleEdit({
            user,
            timeSheet,
            callback,
          }),
      });

      contextMenu.push({
        icon: <TrashCanOutlineIcon />,
        title: "Slet",
        callback: () =>
          handleDelete({
            value,
            timeRegistrationId,
            callback: () => {
              dispatch(hideModalPage());
            },
          }),
      });
    }

    dispatch(showContextMenu(contextMenu));
  }
  return toggleContextMenu;
}
