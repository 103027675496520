// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Styles
import colors from "../../style/colors";

// Utilities
import getHoursAndMinutes from "../../utilities/get-hours-and-minutes";
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../utilities/color-modifiers";

const timeTypes = {
  minutes: "minutes",
  hours: "hours",
};

const TimePicker = (props) => {
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinutes, setSelectedMinutes] = useState(null);
  const [time, setTime] = useState({
    minutes: [],
    hours: [],
  });

  // PrimaryColor from state
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Language
  const { language: lang } = useSelector((state) => state.language);

  // Props
  const { className, style, disabled = false } = props;

  const onSelect = (e, type) => {
    if (type === timeTypes.hours) setSelectedHour(e.target.value);
    if (type === timeTypes.minutes) setSelectedMinutes(e.target.value);
  };

  const fillTimeOptions = () => {
    let allowedMinutes = (props.config && props.config.ALLOWED_MINUTES) || undefined;

    const { minutes, hours } = getHoursAndMinutes({ allowedMinutes });
    setTime({ minutes, hours });
  };

  useEffect(() => {
    fillTimeOptions();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.selectedValue) {
      const values = props.selectedValue.split(":");
      setSelectedHour(values[0]);
      setSelectedMinutes(values[1]);
    }
    // eslint-disable-next-line
  }, [props.selectedValue]);

  useEffect(() => {
    if (selectedMinutes !== null && selectedHour !== null && !disabled) {
      props.onSelect(`${selectedHour}:${selectedMinutes}`);
    }
    // eslint-disable-next-line
  }, [selectedMinutes, selectedHour]);

  // Reset the timer, when the timer value is reset
  useEffect(() => {
    if (!props.shouldResetTime) return;
    setSelectedHour(null);

    setSelectedMinutes(null);
  }, [props.shouldResetTime]);

  return (
    <div className={`${componentStyle(colors, primaryColor)} ${className || ""}`} style={style}>
      {/** HOURS **/}
      <select
        style={{ marginRight: "0.25rem" }}
        className={selectedHour ? "selected-value" : ""}
        value={selectedHour ? selectedHour : ""}
        name="hours"
        disabled={disabled}
        onChange={(e) => onSelect(e, timeTypes.hours)}
      >
        <option hidden={true} value="">
          {lang.hours}
        </option>
        {time.hours.map((hour, index) => (
          <option key={`timePicker-hour-${index}`} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <span>:</span>
      {/** MINUTES **/}
      <select
        style={{ marginLeft: "0.25rem" }}
        className={selectedMinutes ? "selected-value" : ""}
        value={selectedMinutes ? selectedMinutes : ""}
        name="minutes"
        disabled={disabled}
        onChange={(e) => onSelect(e, timeTypes.minutes)}
      >
        <option hidden={true} value="">
          {lang.minutes}
        </option>
        {time.minutes.map((hour, index) => (
          <option key={`timePicker-minute-${index}`} value={hour}>
            {hour}
          </option>
        ))}
      </select>
    </div>
  );
};

const componentStyle = (colors, primaryColor) => css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;

  .selected-value {
    color: var(--black);
  }

  span {
    display: flex;
    align-items: center;
  }

  select {
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border: 1px solid var(--midGrey);
    padding: 0.5rem;
    color: var(--darkGrey);
    text-align-last: center;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select:hover {
    cursor: pointer;
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
    transition: background-color 60ms ease;
  }

  select:focus {
    outline: 0;
    border: 1px solid ${primaryColor};
  }

  select:active {
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
    transition: background-color 100ms ease;
  }
`;

TimePicker.propTypes = {
  /** Function to be executed when an option is selected */
  onSelect: PropTypes.func,

  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,

  /** Default value or target value **/
  selectedValue: PropTypes.string,
};

export default TimePicker;
