import React from "react";
import { css } from "emotion";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import { ImageIcon, ThumbsUpOutlineIcon, CommentMultipleOutlineIcon } from "mdi-react";

const PostSkeleton = ({ index = 1, title = true, author = true, image = true, swipe = true, likesAndComments = true }) => (
  <div className={componentStyle()}>
    {/* Title */}
    {title && (
      <div className="container title-container" style={{ animationDelay: `${index * 1}ms` }}>
        <div className="fake-text title">
          <div className="tile tile-1"></div>
          <div className="tile tile-2"></div>
          <div className="tile tile-3"></div>
        </div>
      </div>
    )}
    {/* Author */}
    {author && (
      <div className="author-container container" style={{ animationDelay: `${index * 10}ms` }}>
        {getProfilePictureFromUserObject({}, 50, { marginRight: "0.5rem", marginBottom: "-3px" })}
        <div className="fake-author-date-container">
          <div className="fake-text author">
            <div className="tile tile-1"></div>
            <div className="tile tile-2"></div>
          </div>
          <div className="fake-text role">
            <div className="tile tile-1"></div>
          </div>
          <div className="fake-text date">
            <div className="tile tile-1"></div>
            <div className="tile tile-2"></div>
            <div className="tile tile-3"></div>
          </div>
        </div>
      </div>
    )}
    {/* Body */}
    <div className="fake-body-container container" style={{ animationDelay: `${index * 20}ms` }}>
      {image && (
        <div className="image-container">
          <ImageIcon color={colors.darkGrey} />
        </div>
      )}
      <div className="fake-text body">
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
        <div className="tile tile-4"></div>
        <div className="tile tile-5"></div>
        <div className="tile tile-6"></div>
        <div className="tile tile-7"></div>
        <div className="tile tile-8"></div>
        <div className="tile tile-9"></div>
        <div className="tile tile-10"></div>
        <div className="tile tile-11"></div>
        <div className="tile tile-12"></div>
        <div className="tile tile-13"></div>
        <div className="tile tile-14"></div>
        <div className="tile tile-15"></div>
      </div>
    </div>
    {(swipe || likesAndComments) && (
      <div className="interactions-container container" style={{ animationDelay: `${index * 10}ms` }}>
        {swipe && <div className="swipe"></div>}
        {likesAndComments && (
          <div className="like-comment-container">
            <div className="like-container">
              <div className="circle">
                <ThumbsUpOutlineIcon color={colors.darkGrey} />
              </div>
              <div className="fake-text likes">
                <div className="tile tile-1"></div>
                <div className="tile tile-2"></div>
                <div className="tile tile-3"></div>
                <div className="tile tile-4"></div>
              </div>
            </div>
            <div className="comment-container">
              <div className="circle">
                <CommentMultipleOutlineIcon color={colors.darkGrey} />
              </div>
              <div className="fake-text comments">
                <div className="tile tile-1"></div>
                <div className="tile tile-2"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

const PostSkeletonGroup = (props) => {
  const { numberOfPosts } = props;
  return (
    <>
      {[...Array(numberOfPosts)].map((x, i) => (
        <PostSkeleton key={i} index={i} {...props} />
      ))}
    </>
  );
};

const componentStyle = () => css`
  margin: 0 auto;
  background-color: ${colors.white};
  border: 1px solid ${colors.midGrey};
  padding: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
  max-width: ${breakpoints.md}px;
  overflow: hidden;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .container {
    margin-bottom: 1.5rem;
    opacity: 0.2;
    animation: blink 1.8s infinite;
  }

  .author-container {
    margin-top: 1.5rem;
    justify-content: stretch;
    align-items: center;
    display: flex;

    &:first-child {
      margin-top: 0px;
    }
  }

  .fake-body-container {
    flex-grow: 1;
    margin-top: 1.5rem;

    .image-container {
      min-width: 300px;
      min-height: calc(300px / 16 * 9);
      background-color: ${colors.midGrey};
      position: relative;
      border-radius: 3px;
      padding: 10px;
      margin-bottom: 1.5rem;

      svg {
        display: block;
        position: absolute;
        display: block;
        left: calc(50% - 2rem);
        top: calc(50% - 2rem);
        opacity: 0.6;
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .fake-text {
    display: flex;

    .tile {
      background-color: ${colors.darkGrey};
      border-radius: 4px;
      margin-right: 0.45rem;
    }

    &.title {
      margin-bottom: 0.9rem;
      height: 1.25rem;
      display: flex;
      align-items: flex-end;

      .tile {
        height: 0.75rem;
      }

      .tile-1 {
        width: 75px;
      }
      .tile-2 {
        width: 60px;
      }
      .tile-3 {
        width: 95px;
      }
    }

    &.author {
      margin-bottom: 0.5rem;
      .tile {
        height: 7px;
      }
      .tile-1 {
        width: 35px;
        margin-right: 0.25rem;
      }
      .tile-2 {
        width: 50px;
      }
    }

    &.role {
      margin-bottom: 0.3rem;
      .tile-1 {
        height: 4px;
        width: 65px;
        background-color: ${colors.midGrey};
      }
    }

    &.date {
      .tile {
        height: 4px;
        background-color: ${colors.midGrey};
      }
      .tile-1 {
        width: 20px;
        margin-right: 0.25rem;
      }
      .tile-2 {
        width: 35px;
        margin-right: 0.25rem;
      }
      .tile-3 {
        width: 25px;
      }
    }

    &.body {
      flex-wrap: wrap;
      .tile {
        margin-bottom: 0.7rem;
        height: 7px;
      }
      .tile-1 {
        width: 40px;
      }
      .tile-2 {
        width: 55px;
      }
      .tile-3 {
        width: 30px;
      }
      .tile-4 {
        width: 15px;
      }
      .tile-5 {
        width: 50px;
      }

      .tile-6 {
        width: 20px;
      }
      .tile-7 {
        width: 30px;
      }
      .tile-8 {
        width: 45px;
      }
      .tile-9 {
        width: 15px;
      }
      .tile-10 {
        width: 50px;
      }
      .tile-111 {
        width: 35px;
      }
      .tile-12 {
        width: 55px;
      }
      .tile-13 {
        width: 50px;
      }
      .tile-14 {
        width: 50px;
      }
      .tile-15 {
        width: 50px;
      }
    }

    &.likes {
      .tile {
        height: 7px;
        background-color: ${colors.midGrey};
      }
      .tile-1 {
        width: 10px;
        margin-left: 0.45rem;
      }
      .tile-2 {
        width: 30px;
      }
      .tile-3 {
        width: 25px;
      }
      .tile-4 {
        width: 15px;
      }
    }
    &.comments {
      .tile {
        height: 7px;
        background-color: ${colors.midGrey};
      }
      .tile-1 {
        width: 10px;
      }
      .tile-2 {
        width: 55px;
      }
    }
  }
  .interactions-container {
    margin-bottom: 0px;
    .swipe {
      height: 40px;
      max-width: 80vw;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid ${colors.darkGrey};
      border-radius: 20px;
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    .like-comment-container {
      display: flex;
      justify-content: space-between;
      .like-container,
      .comment-container {
        display: flex;
        align-items: center;
      }
      .comment-container {
        flex-direction: row-reverse;
      }
      .circle {
        background-color: ${colors.lightGrey};
        display: flex;
        height: 2.25rem;
        width: 2.25rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        overflow: hidden;

        svg {
          position: absolute;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export { PostSkeletonGroup, PostSkeleton };
