// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { ErrorOutlineIcon } from "mdi-react";

// Utilities
import req from "../../utilities/request-utility";

// Components
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";
import Spinner from "../ui/InlineSpinner";

// Styling
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import { useSelector } from "react-redux";
import { addToast } from "../../actions/uiActions";
import ScrollView from "../ui/ScrollView";

const QuizResults = (props) => {
  const [quiz, setQuiz] = useState({});
  const [spinner, setSpinner] = useState(true);

  // Language
  const lang = useSelector((state) => state.language.language);

  useEffect(() => {
    req()
      .get(`quiz/${props.quizId}/answers`)
      .then(({ data: quiz }) => {
        setQuiz(quiz);
        setSpinner(false);
      })
      .catch((err) => {
        addToast({
          title: `${lang.errorGeneral}`,
          content: `${lang.errorCouldNotGetQuiz}`,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ScrollView className={componentStyle(breakpoints, colors)}>
      <div className="content-wrapper">
        {spinner && <Spinner />}
        {!spinner && quiz && (
          <>
            <p className="title">{quiz.title}</p>
            <p className="sub-title">
              {lang.youGot__correctAnswers__outOf__quizQuestions__correctYouHaveEarned__point__ViewYourAnswerBelow
                .replace(/{{correctAnswers}}/gi, quiz.correctAnswers)
                .replace(/{{quizQuestions}}/gi, quiz.quizQuestions.length)
                .replace(/{{point}}/gi, quiz.points)}
            </p>
            <hr />
            {quiz.quizQuestions.map((q) => (
              <div style={{ marginBottom: "2rem" }} key={`question-${q.id}`}>
                <p className="sub-title">{q.question}</p>
                <RadioGroupShowCorrectAnswers
                  answers={q.quizAnswers.map(({ answer: title, correct, id }) => ({ title, correct, id }))}
                  userAnswer={{ answerId: q.userAnswer.quizAnswerId }}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </ScrollView>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: var(--white);
  height: 100%;

  div.content-wrapper {
    padding: 1.6rem;
    overflow: auto;
    max-width: ${breakpoints.md}px;
    margin: auto;
  }

  .title {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  .sub-title {
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
  }

  hr {
    margin: 1.8rem 0;
    height: 1px;
    color: var(--midGrey);
    background-color: var(--midGrey);
    border: none;
  }
`;

export default QuizResults;
