import store from "../../../store";
import requestUtility from "../../../utilities/request-utility";
import { mergeSkeletonAndFetchedRegistrations } from "../utilities/mergeSkeletonAndFetchedRegistrations";

/**
 *
 * @param {Object} anon
 * @param {Object} anon.page - pageObject of the current page
 * @param {Object} anon.selectedUser - selectedUser
 * @param {String} anon.startDate - `YYYYMMDD` formatted date
 * @param {String} anon.endDate - `YYYYMMDD` formatted date
 * @param {String} anon.placeholderRegistrations - The initially generated registrations to merge with the fetched ones
 */
async function getRegistrationsForOverview({ startDate, endDate, page, placeholderRegistrations }) {
  const selectedUser = store.getState().timeRegistration.selectedUser;

  let queryParams = ``;
  queryParams += `fromDate=${startDate}`;
  queryParams += `&toDate=${endDate}`;
  if (selectedUser) queryParams += `&selectedUserId=${selectedUser.id}`;

  let URL = `time-registrations/${page.dataId}/time-sheets?${queryParams}`;

  let { data: fetchedRegistrationData } = await requestUtility()(URL);

  // We then need to merge the fetched, saved registrations into the previously created array containing an object for each day in the selected time period.
  // This way we end up showing every day in the selected time period, and if any hours has been saved for a specific day this will show, if not the initial data will show.
  const mergedRegistrations = mergeSkeletonAndFetchedRegistrations(fetchedRegistrationData, placeholderRegistrations);

  return mergedRegistrations;
}

export default getRegistrationsForOverview;
