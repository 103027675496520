// Libs
import React, { useState, useRef, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Utilities
import deletedUser from "../../utilities/deleted-user";
import showReadAloud from "../../utilities/showReadAloud";

// Redux actions
import {
  addToast,
  showContextMenu,
  showModalPage,
  updateModalPage,
  showDialog,
  hideModalPage,
} from "../../actions/uiActions";
import {
  readPost,
  likePost,
  unlikePost,
  addComment,
  resetFeed,
  getPosts,
  refreshPostComments,
  removePostFromFeed,
  refreshPostCommentLikes,
  pinPost,
  unpinPost,
  refreshPostLikes,
} from "../../actions/newsActions";

// Components
import Post from "../ui/Post";
import CheckQuestionsModal from "./CheckQuestionsModal";

import NewsCommentModal from "./NewsCommentModal";
import UserProfile from "../profile/UserProfile";
import NewsPostModal from "./NewsPostModal";
import CertificateResults from "../certificate/CertificateResults";
import NewsTargetGroupModal from "./components/NewsTargetGroupModal";
import ReminderSmsModal from "./components/ReminderSmsModal";

// Icons
import {
  AlertOctagramIcon,
  CheckCircleIcon,
  EditIcon,
  TrashCanOutlineIcon,
  TrashCircleIcon,
  PinIcon,
  AccountGroupIcon,
  ChatIcon,
} from "mdi-react";

// Utils and config
import req from "../../utilities/request-utility";
import getUserLocale from "../../utilities/get-user-locale";
import { feedTypes } from "./config";
import translationTypes from "../../config/translationTypes";
import readAloudTypes from "../../config/readAloudTypes";
import { quizTypes } from "../../config/quizTypes";
import colors from "../../style/colors";
import { formatNewsDatetime } from "./utilities/format-news-datetime";
import styleTypes from "../../config/styleTypes";
import getIsLiked from "../../utilities/getIsLiked";
import trackAction from "../../services/tracking/trackAction";
import trackingActions from "../../config/trackingActions";

// Hooks
import useTranslation from "../../hooks/useTranslation";
import useReadAloud from "../../hooks/useReadAloud";
import useNewsPostViewTracker from "./hooks/useNewsPostViewTracker";

// Context
import { PageContext } from "./context/PageContext";

/**
 * NewsPost is a proxy-component that adds news-specific functionality to the Post component
 * It sits between `<NewsFeedTabs/>` and `<Post/>` component.
 *
 * @example
 * ```jsx
 * {posts.map(post => <NewsPost post={post} key={`news-post-${post.id}`} />)}
 * ```
 *
 */
function NewsPost(props) {
  // actions
  const {
    addToast,
    lang,
    refreshPostComments,
    removePostFromFeed,
    showContextMenu,
    showDialog,
    showModalPage,
    getPosts,
    updateModalPage,
    addComment,
    resetFeed,
    user,
    likePost,
    readPost,
    readActionLangOverwrite,
    subTypeId,
    feedType,
    unlikePost,
    disableSwipe = false,
    useExpandCollapseLayout = false,
    expandCollapseToggle,
    disableLikesAndComments = false,
    showTitle = true,
    isExpanded = false,
    pinPost,
    unpinPost,
    callBackOnTranslation,
  } = props;

  const page = useContext(PageContext);

  const {
    postsWithLoadingLikes,
    postsWithLoadingComments,
    postsWithCommentsBeingAdded,
    deletedPosts,
    frontendAdminMappings,
  } = useSelector((state) => state.news);

  // Reference for comment container element (used for auto scroll when adding comments)
  const commentsContainer = useRef();
  const newsPostRef = useRef(null);

  //props
  const { disableInteractionsPreview, style } = props;

  // Post content
  const {
    author,
    comments,
    commentCount,
    content,
    date,
    documentOwner,
    approvedBy,
    files,
    id,
    hideAuthorInApp,
    images,
    links,
    likes = [],
    points,
    quiz = undefined,
    read,
    readDate,
    title,
    video,
    videoEmbed,
    isPinned = false,
    noTargetGroup = true,
    isLikedByUser,
    likeCount,
    allowLikesAndComments,
  } = props.post;

  // hook for tracking views on a post
  useNewsPostViewTracker({ newsPostRef, subTypeId, postId: id, useExpandCollapseLayout });

  const [titleState, setTitleState] = useState(title);
  const [contentState, setContentState] = useState(content);

  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedTitle,
    content: translatedContent,
  } = useTranslation({
    callBackOnTranslation,
    type: translationTypes.newsPost,
    args: { postId: id, subTypeId },
  });

  const { isPlayingAudio, isLoadingAudio, readAloudContent } = useReadAloud({
    type: readAloudTypes.newsPost,
    args: { postId: id, subTypeId },
  });

  useEffect(() => {
    if (translatedTitle) setTitleState(translatedTitle);
    if (translatedContent) setContentState(translatedContent);
  }, [translatedTitle, translatedContent]);

  useEffect(() => {
    // When the user is searching in the posts (currently only used in NewsFeedFaq), the content is updated with highlighted content
    setContentState(content);
    // eslint-disable-next-line
  }, [content]);

  const isLiked = isLikedByUser;
  // const isLiked = getIsLiked({ user: props.user, likes, isLikedByUser });

  /**************** Generel  ****************/

  const [isAdmin] = useState(() => {
    // if either user is admin or frontendAdminMappings includes the current page
    if (user.admin || frontendAdminMappings.includes(subTypeId)) {
      return true;
    } else {
      return false;
    }
  });

  /**************** Handle swipe  ****************/
  const [swipeCompleted, setSwipeCompleted] = useState(false);
  const sliderRef = useRef(undefined);
  function handleSwipeEnd(quiz) {
    // If questions, show the check-questions modal
    if (quiz && quiz.questions && quiz.type === quizTypes.QUIZ) {
      handleSwipeEndForQuiz();
    } else if (quiz && quiz.questions && quiz.type === quizTypes.CERTIFICATE) {
      handleSwipeEndForCertificate();
    }
    // If no questions just read the post
    else {
      setSwipeCompleted(true);
      readPost({ subTypeId, feedType, postId: id, callback });

      function callback() {
        // If the feedType is unread, fetch readposts again
        if (feedType === feedTypes.unread) {
          resetFeed(feedTypes.read);
          // Wait 500ms before getting posts. This offsets the request so it fires after
          // the request to update the current post
          setTimeout(() => getPosts({ subTypeId, feedType: feedTypes.read }), 4000);
        }
      }
    }
  }

  function handleSwipeEndForQuiz() {
    setSwipeCompleted(true);
    readPost({ subTypeId, feedType, postId: id });
    openCheckQuestionsModal(props.post);
  }

  function handleSwipeEndForCertificate() {
    openCheckQuestionsModal(props.post, (questions) => {
      // ⚠️ !! Attention !! ⚠️
      //
      // Because the component initially was used to show correct/incorrect answers, the returned value from the API was an array consisting of
      // the answered questions.
      //
      // To make newsQuizzes backwards compatible, we now determine if the user has passed the quiz based on the format of the data returned from the API
      // If the quiz is of type QUIZ, then we don't need to worry about anything.
      // But if the quiz if of type CERTIFICATE, the format of the data returned from the API determines if the user has passed the quiz.
      // If the user has answered correctly an object containing the number of correct answered questions and all the questions will be returned.
      // If the user doesn't answer correctly, then an object containing the number of correct answered questions and an empty array for the questions.
      // It important to note that the questions hold the correct answers, so we only want to return them if the user has passed the quiz.
      // In the case here we only need to concern ourselves with questions though...

      if (Array.isArray(questions) && questions.length > 0) {
        readPost({ subTypeId, feedType, postId: id });
        setSwipeCompleted(true);
      } else {
        sliderRef.current.resetSlider();
      }
    });
  }

  /**************** Comment Likes  ****************/
  const [submittingLikedOnCommentId, setSubmittingLikeOnCommentId] = useState(false);
  async function toggleCommentLike(comment) {
    let timer = setTimeout(() => setSubmittingLikeOnCommentId(comment.id), 300);
    if (isCommentLiked(comment)) {
      await req().delete(`news/${subTypeId}/news/${id}/comments/${comment.id}/like`);
    } else {
      await req().put(`news/${subTypeId}/news/${id}/comments/${comment.id}/like`);
    }

    props.refreshPostCommentLikes({
      subTypeId: subTypeId,
      newsId: id,
      feedType: feedType,
      commentId: comment.id,
      callback: () => {
        clearTimeout(timer);
        setSubmittingLikeOnCommentId(false);
      },
    });
  }

  function isCommentLiked(comment) {
    // l && l.id is a null-check. If a user is deleted they are represented as NULL in the likes array
    if (comment.likes && comment.likes.filter((l) => l && l.id === user.id).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  /**************** Check-Question  ****************/
  function openCheckQuestionsModal(post, callback = () => {}) {
    showModalPage({
      title: lang.checkQuestion,
      content: <CheckQuestionsModal callback={callback} postId={post.id} quiz={post.quiz} subTypeId={subTypeId} />,
      closeCallback:
        post.quiz.type === quizTypes.CERTIFICATE
          ? () => {
              sliderRef.current.resetSlider();
              props.hideModalPage();
            }
          : () =>
              showDialog({
                title: lang.heyThere,
                content: lang.dialogCloseCheckQuestionsContent,
                primaryActionTitle: lang.dialogCloseCheckQuestionsPrimary,
                primaryAction: props.hideModalPage,
                secondaryActionTitle: lang.dialogCloseCheckQuestionsSecondary,
                styleType: styleTypes.error,
                icon: <AlertOctagramIcon />,
              }),
      useScrollView: false,
    });
  }

  /**************** Comments  ****************/
  function onCommentContextMenu({ comment, postId, subTypeId, feedType }) {
    showContextMenu([
      {
        icon: <EditIcon />,
        title: lang.edit,
        callback: () =>
          showModalPage({
            title: `${lang.edit} ${lang.comment.toLowerCase()}`,
            content: <NewsCommentModal comment={comment} postId={postId} subTypeId={subTypeId} feedType={feedType} />,
            useScrollView: false,
          }),
      },
      {
        icon: <TrashCanOutlineIcon />,
        title: lang.delete,
        callback: () =>
          showDialog({
            title: `${lang.delete} ${lang.comment.toLowerCase()}`,
            content: lang.deleteCommentInfo,
            primaryActionTitle: lang.deleteCommentConfirm,
            primaryAction: () => deleteComment({ comment, postId, subTypeId, feedType }),
            secondaryActionTitle: lang.deleteCommentCancel,
            styleType: styleTypes.error,
            icon: <AlertOctagramIcon />,
          }),
      },
    ]);
  }

  function deleteComment({ comment, postId, subTypeId, feedType }) {
    req()
      .delete(`news/${subTypeId}/${postId}/comments/${comment.id}`)
      .then(() => refreshPostComments({ subTypeId, postId, feedType }))
      .catch((err) => addToast({ template: "error" }));
  }

  // Auto refresh comments
  const [autoRefreshComments, setAutoRefreshComments] = useState(false);

  // Register side-effect to refetch commetns every 15 seconds
  useEffect(() => {
    let interval;

    // If autorefreshCommetns is set to true, call refreshPostComments every 15 seconds
    if (autoRefreshComments) {
      refreshPostComments({ subTypeId, postId: id, feedType });
      interval = setInterval(() => void refreshPostComments({ subTypeId, postId: id, feedType }), 15000);
    } else {
      // If autoRefreshComments is set to false clear the interval
      clearInterval(interval);
    }

    // Cleanup function to remove interval if component unmounts
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [autoRefreshComments, id]);

  async function showAuthorModal() {
    if (!author) return;

    showModalPage({
      content: <UserProfile userDataLoading={true} userDataError={false} />,
    });
    let { data: userData } = await req()(`users/${author.id}`);
    updateModalPage({
      content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
    });
  }

  function updatePin() {
    //dialog depended on isPinned is true or not which leads to pin/unpin logic
    if (!isPinned) {
      showDialog({
        title: lang.pinPost,
        content: lang.pinPostInfo,
        primaryActionTitle: lang.pinPostConfirm,
        primaryAction: () => pinPost({ postId: id, subTypeId: subTypeId, feedType: feedTypes.all }),
        secondaryActionTitle: lang.pinPostCancel,
        styleType: styleTypes.neutral,
        icon: <AlertOctagramIcon />,
      });
    } else {
      showDialog({
        title: lang.unpinPost,
        content: lang.unpinPostInfo,
        primaryActionTitle: lang.unpinPostConfirm,
        primaryAction: () => unpinPost({ postId: id, subTypeId: subTypeId }),
        secondaryActionTitle: lang.unpinPostCancel,
        styleType: styleTypes.neutral,
        icon: <AlertOctagramIcon />,
      });
    }
  }

  function handleReminderSmsClick() {
    showModalPage({
      title: lang.reminderSms,
      content: <ReminderSmsModal subTypeId={subTypeId} newsId={id} page={page} />,
      useScrollView: false,
      pageStyle: { backgroundColor: colors.white },
    });
  }

  function handleTargetGroupClick() {
    showModalPage({
      title: lang.targetGroup,
      content: <NewsTargetGroupModal subTypeId={subTypeId} newsId={id} />,
      useScrollView: false,
    });
  }

  function onPostContextMenu({ postId, subTypeId, feedType }) {
    let contextMenu = [];

    if (quiz && quiz.type === quizTypes.CERTIFICATE && read) {
      contextMenu.push({
        icon: <CheckCircleIcon />,
        title: lang.viewAnswers,
        callback: () => {
          showModalPage({
            title: lang.checkQuestion,
            content: <CertificateResults hideTitle={true} quizId={quiz.id} />,
            useScrollView: false,
            pageStyle: { backgroundColor: colors.white },
          });
        },
      });
    }

    /* The regular user should be able to see the targetgroup if such exists.
     * An admin user should always have access to the targetgroup overview, as it in this case also provides statitics about who has seen the post. */
    if (!noTargetGroup || user.admin) {
      contextMenu.push({
        icon: <AccountGroupIcon />,
        title: `${lang.see} ${lang.targetGroup.toLowerCase()}`,
        callback: handleTargetGroupClick,
      });
    }

    if (isAdmin) {
      // Reminder SMS button
      contextMenu.push({
        icon: <ChatIcon />,
        title: lang.createReminderSms,
        callback: handleReminderSmsClick,
      });

      // Pin button
      contextMenu.push({
        icon: <PinIcon />,
        title: isPinned ? lang.unpin : lang.pin,
        callback: updatePin,
      });

      contextMenu.push({
        icon: <EditIcon />,
        title: lang.edit,
        "data-test-id": "context-menu__edit",
        callback: () =>
          showModalPage({
            useScrollView: false,
            title: `${lang.edit} ${lang.post}`,
            content: <NewsPostModal postId={postId} subTypeId={subTypeId} feedType={feedType} />,
          }),
      });

      contextMenu.push({
        icon: <TrashCanOutlineIcon />,
        title: lang.delete,
        "data-test-id": "context-menu__delete",
        callback: () =>
          showDialog({
            title: `${lang.delete} ${lang.post}`,
            content: lang.deletePostInfo,
            primaryActionTitle: lang.deletePostConfirm,
            primaryAction: () => deletePost({ postId, subTypeId, feedType }),
            secondaryActionTitle: lang.deletePostCancel,
            styleType: styleTypes.error,
            icon: <AlertOctagramIcon />,
          }),
      });
    }

    showContextMenu(contextMenu);
  }

  function deletePost({ postId, subTypeId, feedType }) {
    req()
      .delete(`news/${subTypeId}/${postId}`)
      .then(() => {
        removePostFromFeed({ postId, subTypeId, feedType });
        addToast({
          title: lang.yourPostIsDeleted,
          icon: <TrashCircleIcon />,
          styleType: styleTypes.neutral,
          duration: 5000,
        });
      })
      .catch(() => {
        addToast({ template: "error" });
      });
  }

  function refreshLikes() {
    props.refreshPostLikes({ subTypeId, postId: id, feedType });
  }

  function onTranslate(e) {
    translateContent(e);
    trackAction({ action: trackingActions.TRANSLATE_CLICKED_NEWS_POST });
  }

  function onReadAloud() {
    readAloudContent({ currentTranslationLanguage });

    trackAction({ action: trackingActions.READ_ALOUD_CLICKED_NEWS_POST });
  }

  return (
    <div ref={newsPostRef}>
      <Post
        // Content
        id={id}
        data-test-id="news-post"
        date={formatNewsDatetime(date, getUserLocale(user))}
        title={showTitle ? titleState : ""}
        // Content and translation
        content={contentState}
        enableMarkdownContent={true}
        isTranslating={isTranslating}
        isTranslated={isTranslated}
        enableTranslation={props.appConfig.enableTranslation}
        enableReadAloud={showReadAloud({
          enableReadAloud: props.appConfig.enableReadAloud,
          languageCode: currentTranslationLanguage,
        })}
        translateContent={onTranslate}
        readContent={onReadAloud}
        isPlayingAudio={isPlayingAudio}
        isLoadingAudio={isLoadingAudio}
        useExpandCollapseLayout={useExpandCollapseLayout}
        expandCollapseToggle={expandCollapseToggle}
        isExpanded={isExpanded}
        // Misc
        author={author || deletedUser}
        images={images}
        hideAuthorInApp={hideAuthorInApp}
        isPinned={isPinned}
        noTargetGroup={noTargetGroup}
        handleTargetGroupClick={handleTargetGroupClick}
        likes={likes}
        likeCount={likeCount}
        links={links}
        requestPostRefresh={refreshLikes}
        documentOwner={documentOwner}
        approvedBy={approvedBy}
        readActionLangOverwrite={readActionLangOverwrite}
        allowLikesAndComments={allowLikesAndComments}
        commentCount={commentCount}
        comments={
          !comments
            ? null
            : comments.map(({ id, comment: content, author, date, image, likes, comments, parentCommentId }) => ({
                id,
                content,
                author,
                date,
                image,
                likes,
                parentCommentId,
                comments:
                  comments.length > 0
                    ? comments.map(({ id, comment: content, author, date, image, likes, comments, parentCommentId }) => ({
                        id,
                        content,
                        author,
                        date,
                        image,
                        likes,
                        comments,
                        parentCommentId,
                      }))
                    : [],
              }))
        }
        points={points}
        documents={files}
        video={video}
        videoEmbed={videoEmbed}
        read={read}
        readDate={readDate}
        // Config
        className="news-post"
        key={`news-post-${id}`}
        showContextMenuToggle={isAdmin || (quiz && quiz.type === quizTypes.CERTIFICATE && read)}
        disableInteractionsPreview={disableInteractionsPreview}
        disableLikesAndComments={disableLikesAndComments}
        // Likes
        onLike={
          isLiked
            ? () => unlikePost({ subTypeId, feedType, postId: id })
            : () => likePost({ subTypeId, feedType, postId: id })
        }
        liked={isLiked}
        onCommentLike={toggleCommentLike}
        submittingLike={postsWithLoadingLikes.indexOf(id) !== -1}
        isCommentLiked={isCommentLiked}
        submittingLikedOnCommentId={submittingLikedOnCommentId}
        // Read status
        sliderRef={sliderRef}
        disableSwipe={disableSwipe}
        onSwipeEnd={() => handleSwipeEnd(quiz)}
        swipeCompleted={swipeCompleted}
        animateOut={deletedPosts.indexOf(id) !== -1}
        onComment={(comment, parentCommentId = null) =>
          addComment({ subTypeId, feedType, postId: id, comment, commentsContainer, parentCommentId })
        }
        onAuthorClick={showAuthorModal}
        contextMenuToggleCallback={() => onPostContextMenu({ postId: id, subTypeId, feedType })}
        onCommentContextMenu={(comment) => onCommentContextMenu({ subTypeId, feedType, postId: id, comment, quiz })}
        // State and loading-stuff
        submittingComment={postsWithCommentsBeingAdded.indexOf(id) !== -1}
        loadingComments={postsWithLoadingComments.indexOf(id) !== -1}
        commentsContainerRef={commentsContainer}
        //style
        style={style}
        onCommentShow={() => setAutoRefreshComments(true)}
        onCommentHide={() => setAutoRefreshComments(false)}
      />
    </div>
  );
}

NewsPost.defaultProps = {
  disableInteractionsPreview: false,
  style: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appConfig: state.appConfig,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  likePost: bindActionCreators(likePost, dispatch),
  readPost: bindActionCreators(readPost, dispatch),
  unlikePost: bindActionCreators(unlikePost, dispatch),
  addComment: bindActionCreators(addComment, dispatch),
  removePostFromFeed: bindActionCreators(removePostFromFeed, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
  resetFeed: bindActionCreators(resetFeed, dispatch),
  getPosts: bindActionCreators(getPosts, dispatch),
  refreshPostCommentLikes: bindActionCreators(refreshPostCommentLikes, dispatch),
  refreshPostLikes: bindActionCreators(refreshPostLikes, dispatch),
  pinPost: bindActionCreators(pinPost, dispatch),
  unpinPost: bindActionCreators(unpinPost, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewsPost);
