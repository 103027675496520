import { useSelector } from "react-redux";
import breakpoints from "../../../config/breakpoints";
import useAuthorModal from "../../../hooks/useAuthorModal";
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../../utilities/get-user-group-title";
import ListItem from "../../ui/ListItem";
import { css } from "emotion";

function HighscoreListItem({ user, rank, score }) {
  const { user: authUser } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);

  const authorModal = useAuthorModal();

  return (
    <ListItem
      clickable={true}
      maxWidth={breakpoints.lg}
      className={`${style(primaryColor)} ${authUser.id === user.id ? "current-user" : ""}`}
      itemNumberLeft={rank}
      onClick={() => authorModal(user.id)}
      imageLeft={getProfilePicture(user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
      key={`${rank}-${user.name}`}
      title={user.name}
      subTitle={getUserGroupTitle(user)}
      iconRight={score}
    />
  );
}

const style = (primaryColor) => css`
  :last-of-type {
    border-radius: 0 0 3px 3px;
  }

  &.current-user {
    font-weight: 700;
    color: ${primaryColor};
    background-color: var(--ultraLightGrey);
  }
`;

export default HighscoreListItem;
