// Libraries
import React from "react";
import { css } from "emotion";

// Components
import ListItem from "../ui/ListItem";
import DynamicIcon from "../ui/Icons/DynamicIcon";
import { ChevronRightIcon } from "mdi-react";
import Notification from "../ui/Notification";

// Utilities
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import iconConverter from "../../utilities/icon-converter";

// Styles
import colors from "../../style/colors";

const NavigationListItem = ({ page, maxWidth, color }) => {
  const handleNavigation = () => {
    if (page.locked) {
      return;
    }

    pageNavigatorV2({
      path: linkPathCreator(page, "absolute"),
      direction: "forward",
      pageId: page.id,
      page: page,
    });
  };

  return (
    <ListItem
      dataTestId={`navigation-list-item-${page.title}`}
      maxWidth={maxWidth}
      clickable={true}
      onClick={handleNavigation}
      className={`${componentStyle({ locked: page.locked })}`}
      title={page.title}
      iconLeft={page.icon ? iconConverter(page.icon, { color }) : null}
      notificationsRight={page.notifications > 0 && !page.locked && <Notification notifications={page.notifications} />}
      iconRight={
        page.locked ? <DynamicIcon style={{ height: "24px", width: "24px" }} icon={"lock"} /> : <ChevronRightIcon />
      }
      key={page.id}
    />
  );
};

const componentStyle = ({ locked = false }) => css`
  ${locked
    ? `
      background: var(--midLightGrey) !important;
      pointer-events: none;
    `
    : `
      background: var(--white) !important;
    `};
`;

export default NavigationListItem;
