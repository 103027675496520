// Libs
import React from "react";
import { css } from "emotion";
import { LockIcon } from "mdi-react";

// UI
import GridItem from "../ui/GridItem";
import Notification from "../ui/Notification";
import colors from "../../style/colors";

// Utils
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import iconConverter from "../../utilities/icon-converter";

const NavigationGridItem = ({ page, maxWidth, color }) => {
  const handleNavigation = () => {
    if (page.locked) {
      return;
    }

    pageNavigatorV2({
      path: linkPathCreator(page, "absolute"),
      direction: "forward",
      pageId: page.id,
      page: page,
    });
  };

  return (
    <GridItem
      maxWidth={maxWidth}
      clickable={true}
      onClick={handleNavigation}
      className={`list-item ${componentStyle({ locked: page.locked })}`}
      title={page.title}
      iconLeft={page.icon ? iconConverter(page.icon, { color, width: "2.25rem", height: "2.25rem" }) : null}
      iconRight={<LockIcon />}
      notificationsRight={
        page.locked ? <LockIcon /> : page.notifications > 0 && <Notification notifications={page.notifications} />
      }
    />
  );
};

const componentStyle = ({ locked = false }) => css`
  ${locked
    ? `
      background: var(--midLightGrey) !important;
      pointer-events: none;
    `
    : `
      background: var(--white);
    `};
`;

export default NavigationGridItem;
