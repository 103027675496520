import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { feedTypes } from "./config";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import NewsPost from "./NewsPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { PlusIcon, InfoOutlineIcon } from "mdi-react";
import NewsPostModal from "./NewsPostModal";
import ScrollView from "../ui/ScrollView";
import colors from "../../style/colors";

// Context
import { PageContext } from "./context/PageContext";

function NewsFeedSingle(props) {
  // page setup
  const { match, getPosts, resetFeeds, showModalPage } = props;
  const pages = useSelector((state) => state.pages.pages);
  const lang = useSelector((state) => state.language.language);
  const admin = useSelector((state) => state.auth.user.admin);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all });
    // eslint-disable-next-line
  }, []);

  // Fetches more posts if scrollview isn't yet scrollable
  useEffect(() => {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view");
      if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight)
        getPosts({ subTypeId: page.dataId, feedType: feedTypes.all });
    }, 500); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly

    // eslint-disable-next-line
  }, [posts, loading, error]);

  return (
    <Page backgroundColor={colors.white}>
      <PageContext.Provider value={page.id}>
        <TopBar
          useDefaultBackButton={true}
          title={page.title}
          actionRight={
            !admin ? null : (
              <ActionWrapper
                data-test-id="btn-show-add-post-modal"
                onClick={() =>
                  showModalPage({
                    useScrollView: false,
                    content: <NewsPostModal subTypeId={page.dataId} feedType={feedTypes.all} />,
                  })
                }
              >
                <PlusIcon />
              </ActionWrapper>
            )
          }
        />

        <ScrollView onScrollEnd={() => getPosts({ subTypeId: page.dataId, feedType: feedTypes.all })}>
          <div style={{ height: "1rem" }} />

          {/* Content */}
          {posts.map((p) => (
            <NewsPost
              style={{ border: "0px transparent solid" }}
              post={p}
              feedType={feedTypes.all}
              subTypeId={page.dataId}
              key={`news-post-${p.id}`}
              disableSwipe={page.config && page.config.disableSwipe === false ? false : true}
            />
          ))}

          {/* Loading */}
          {loading && !error && !endOfFeed && (
            <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
          )}

          {/* End of feed without posts */}
          {posts.length === 0 && endOfFeed && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noPostsHere}
              content={lang.comeBackSoon}
            />
          )}
        </ScrollView>
      </PageContext.Provider>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedSingle);
