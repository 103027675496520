import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CellphoneIphoneIcon } from "mdi-react";
import qeuryString from "query-string";

// Utilities
import keyPressProxy from "./utilities/keyPressProxy";

// Actions
import { signIn } from "../../actions/authActions";

// UI
import PasswordDots from "./PasswordDots";
import Numpad from "../ui/Numpad";
import DropDownList from "../ui/dropDown/DropDownList";
import { css } from "emotion";
import colors from "../../style/colors";
import countryCodes from "./config/countryCodes";

const PhoneLogin = (props) => {
  const dispatch = useDispatch();
  const signingIn = useSelector((s) => s.auth.signingIn);

  const [state, setState] = useState({
    countryCode: "",
    phone: "",
    password: "",
    phoneEntered: false,
    passwordEntered: false,
    showNumpad: true,
  });

  const refProps = useRef(props);
  const refState = useRef(state);
  const loginHint = qeuryString.parse(window.location.search).loginHint;

  useEffect(() => {
    refState.current = state;
    refProps.current = props;
  }, [state, props]);

  useEffect(() => {
    if (state.phone) localStorage.setItem("last-used-phone-login", state.phone);
    if (state.countryCode) localStorage.setItem("last-used-country-code", state.countryCode);
    if (state.phone && props.sendCurrentLoginToParent) props.sendCurrentLoginToParent(state.phone);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    // Try to get last used phone
    let potentialPhoneNumber = localStorage.getItem("last-used-phone-login");
    if (potentialPhoneNumber) setState({ ...state, phone: potentialPhoneNumber, phoneEntered: true });

    // Try to get last used country code from local storage, if none saved use 45
    let potentialCountryCode = localStorage.getItem("last-used-country-code") || "+45";
    setState({ ...state, countryCode: potentialCountryCode });

    if (loginHint) {
      setState({ ...state, phone: loginHint, phoneEntered: true });
    }

    // Bind event listeners for keys
    document.addEventListener("keyup", keyProxy);
    document.addEventListener("keydown", handleTabKey);

    return () => {
      // Unbind event listeners for keys
      document.removeEventListener("keyup", keyProxy);
      document.removeEventListener("keydown", handleTabKey);
    };
    // eslint-disable-next-line
  }, []);

  let { showNumpad, phone, password, phoneEntered } = state;

  function handleTabKey(e) {
    if (refProps.current.enableInput === false) return;

    // Moves focus between phone and password with tab and shift-tab
    let { phoneEntered } = refState.current;

    // If tab is pressed and phone is not entered (and mode is phone)
    if (e.key === "Tab" || (e.key === "Enter" && !phoneEntered)) {
      e.preventDefault();
      setState({ ...refState.current, phoneEntered: true });
    }

    if (e.shiftKey && e.key === "Tab" && phoneEntered) {
      e.preventDefault();
      setState({ ...refState.current, phoneEntered: false });
    }
  }

  function keyProxy(e) {
    handleKeyPress(keyPressProxy(e));
  }

  function handleKeyPress(key) {
    if (refProps.current.enableInput === false) return;

    let freshState = refState.current;
    let { phoneEntered, passwordEntered, password, phone, countryCode } = refState.current;

    // Handle delete button
    if (key === "delete") {
      if (!phoneEntered) {
        setState({ ...freshState, phone: freshState.phone.slice(0, -1) });
      } else if (phoneEntered && !passwordEntered && password.length > 0) {
        setState({ ...freshState, password: password.slice(0, -1) });
      } else if (phoneEntered && passwordEntered) {
        setState({ ...freshState, passwordEntered: false });
      } else if (phoneEntered && !passwordEntered) {
        setState({ ...freshState, phoneEntered: false });
      }
      return;
    }
    // If logintype is phone and phone isn't entered, append values to phone
    if (!phoneEntered) {
      setState({ ...freshState, phone: freshState.phone + key });

      // If logintype is phone and phone is entered, append values to password (as long as theres is 3 or less characters)
    } else if (phoneEntered && key !== "delete" && key !== "enter" && !passwordEntered && password.length < 3) {
      setState({ ...freshState, password: password + key });

      // If logintype is phone and phone is entered, and the password is exactly 3 characters long, the current character is
      // the last in the password. Therefore append it to the password and automatically try to login
    } else if (phoneEntered && key !== "delete" && key !== "enter" && !passwordEntered && password.length === 3) {
      setState({ ...freshState, password: password + key, passwordEntered: true });
      tryLogin({
        phone,
        countryCode: countryCode,
        password: password + key,
      });
    }

    function tryLogin(credentials) {
      dispatch(signIn(credentials));

      setState({
        ...refState.current,
        password: "",
        passwordEntered: false,
      });
    }
  }

  return (
    <>
      <div
        className={`input phone ${componentStyles()}`}
        onClick={() =>
          setState({
            ...state,
            phoneEntered: false,
          })
        }
      >
        <CellphoneIphoneIcon className="login-type-icon" />
        <DropDownList
          className={"country-code"}
          onChange={(e) => {
            setState({ ...state, countryCode: e.target.value });
          }}
          dropDownListContent={countryCodes}
          selectedContentId={state.countryCode || "+45"}
          usePlaceholder={false}
        />
        <p data-test-id={"phone-auth-number"}>{phone}</p>
        {!phoneEntered && <div className="phone-cursor" />}
      </div>
      <PasswordDots
        hide={signingIn}
        active={phoneEntered}
        password={password}
        onClick={() => {
          setState({
            ...state,
            phoneEntered: true,
          });
        }}
      />
      <Numpad active={showNumpad} onKeyPress={(key) => handleKeyPress(key)} />
    </>
  );
};

const componentStyles = () => css`
  .country-code {
    cursor: pointer;
    width: 74px;
    /* border: 1px solid var(--midGrey); */
    border-radius: 2px;
    padding-right: 1px;
    padding-left: 5px;
    margin-right: 0.5rem;

    select {
      cursor: pointer;
      font-size: 0.8rem;
      height: 25px;
      line-height: 20px;
      padding-left: 5px;
      padding-right: 0;
      z-index: 1;

      /* &:focus {
        border: 1px solid transparent;
      } */
    }
    svg {
      margin-left: 0;
    }
  }
`;

export default PhoneLogin;
