import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import * as queryString from "query-string";

// Components
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import StatusBox from "../ui/StatusBox";
import RadioGroup from "../ui/RadioGroup";
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";
import Button from "../ui/Button";
import InlineSpinner from "../ui/InlineSpinner";

// Utils
import req from "../../utilities/request-utility";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Icons
import { AlertOctagonIcon, TimerSandIcon, TrophyVariantOutlineIcon, GiftIcon, EmoticonHappyIcon, HelpIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";

// Config
import breakpoints from "../../config/breakpoints";
import Image from "../ui/Image";
import { addToast } from "../../actions/uiActions";
import ChristmasHighscoreMain from "../christmasHighscore/ChristmasHighscoreMain";
import markDownParser from "../../utilities/markdown-parser";
import { UserListItem } from "../ui/users/UserListItem";

const ChristmasCalendarQuestion = (props) => {
  const dispatch = useDispatch();

  // Destructure the props
  const {
    questionId,
    christmasCalenderMetaData: { noDailyWinner, correctAnswerBonus, answerBonus },
  } = props;

  // Language state from redux
  const { language: lang } = useSelector((state) => state.language);

  // Today's question
  const [question, setQuestion] = useState({});
  const [questionLoading, setQuestionLoading] = useState(true);
  const [questionError, setQuestionError] = useState(false);

  // answer
  const [answer, setAnswer] = useState(null);

  // submitting answer
  const [submittingAnswer, setSubmittingAnswer] = useState(false);

  // The 24 questions
  const [activeTab, setActiveTab] = useState(0);

  // Array of 3 random picture URL's to use to show the small christmas figues
  const [arrayOfImages, setArrayOfImages] = useState([]);

  // The URL's to choose 3 random URL's from
  const arrayOfImageNames = [
    "christmas-calendar-santa.png",
    "christmas-calendar-polar.png",
    "christmas-calendar-alf.png",
    "christmas-calendar-ginger.png",
    "christmas-calendar-deer.png",
    "christmas-calendar-snowman.png",
  ];

  let christmasQuizId = queryString.parse(window.location.search).dataId;

  useEffect(() => {
    setQuestionLoading(true);
    let localSetOfImages = new Set();
    while (localSetOfImages.size < 3) {
      let rand = Math.floor(Math.random() * 6);
      localSetOfImages.add(arrayOfImageNames[rand]);
    }
    setArrayOfImages([...localSetOfImages]);

    req()(`christmas-quizzes/${christmasQuizId}/questions/${questionId}`)
      .then(({ data }) => {
        setQuestion(data);
        setQuestionLoading(false);
      })
      .catch(() => {
        setQuestionLoading(false);
        setQuestionError(true);
        dispatch(
          addToast({
            styleType: "error",
            icon: <AlertOctagonIcon />,
            duration: "60000",
            title: lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.question.toLowerCase()),
            content: `${lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.question.toLowerCase())}. ${
              lang.tryAgainOrContactSupport
            }`,
          })
        );
      });

    // eslint-disable-next-line
  }, []);

  function submitAnswer() {
    setSubmittingAnswer(true);

    // JSON som skal sendes i request body
    const body = {
      answerId: answer,
      questionId,
      correctAnswerBonus,
      answerBonus,
    };

    req()
      .post(`christmas-quizzes/${christmasQuizId}/answers`, body)
      .then((res) => {
        setSubmittingAnswer(false);
        let localQuestion = {
          ...question,
          myAnswerId: answer,
          myAnswerCorrect: res.data.myAnswerCorrect,
          answers: getFotmattedAnswers(res.data.correctAnswerId, question.answers),
        };

        setQuestion(localQuestion);
      })
      .catch((err) => {
        setSubmittingAnswer(false);
        dispatch(
          addToast({
            title: lang.error,
            content: `${lang.errorAddingPost}. ${lang.tryAgainOrContactSupport}`,
            icon: <AlertOctagonIcon />,
            styleType: "warning",
            duration: 10000,
          })
        );
      });
  }

  function getFotmattedAnswers(correctAnswerId, answers) {
    if (correctAnswerId) {
      return answers.map((answer) => {
        if (answer.id === correctAnswerId) {
          return { ...answer, correct: true };
        }
        return { ...answer, correct: false };
      });
    }
    return [...answers];
  }

  function getUserInfo() {
    return <UserListItem className={"container-winner-info"} user={question.winner} profilePictureSize={50}></UserListItem>;
  }

  function getQuestionComponent() {
    return (
      <div className={`${componentStyle()} container`}>
        {question.video && (
          <div className="video-wrapper">
            <video
              controls
              controlsList="nodownload"
              poster={`${question.video.baseURL}f_jpg/${question.video.video}`}
              src={`${question.video.baseURL}f_auto,q_auto,w_1600/${question.video.video}`}
            ></video>
          </div>
        )}

        {question?.questionImage?.baseURL && question?.questionImage?.image && (
          <Image
            alt="question.questionImage.image"
            src={`${question.questionImage.baseURL}/${question.questionImage.image}`}
          />
        )}

        <p className="question-title bold">{question.title}</p>
        {question.answers && question.myAnswerId === null && (
          <RadioGroup style={{ marginBottom: "2rem" }} options={question.answers} onSelect={setAnswer} />
        )}
        {question.answers && question.myAnswerId !== null && (
          <RadioGroupShowCorrectAnswers
            showIfCorrectAnswer={true}
            style={{ marginBottom: "2rem" }}
            userAnswer={{ answerId: question.myAnswerId, isCorrect: question.myAnswerCorrect }}
            answers={question.answers}
          />
        )}
        {question.myAnswerId === null && (
          <Button
            disabled={answer === null}
            onClick={submitAnswer}
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
          >
            <p style={{ marginRight: "0.4rem" }}>
              {submittingAnswer ? (
                <InlineSpinner color={colors.white} size={24} style={{ marginTop: "-6px", marginBottom: "-8px" }} />
              ) : (
                lang.submitAnswer
              )}
            </p>
          </Button>
        )}
        {question.myAnswerId && question.myAnswerId !== null && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<EmoticonHappyIcon />}
            title={`${lang.thanksForAnswering}!`}
            // content={question.winner && !noDailyWinner ? lang.winnerHasBeenDrawn : lang.infoDailyDraw}
            content={
              question.myAnswerCorrect ? `${lang.youAnsweredCorrectly}!` : `${lang.yourAnswerWasWrongTryAgainTomorrow}🎅`
            }
          />
        )}
      </div>
    );
  }

  function getPriceComponent() {
    return (
      <div className={`${componentStyle()} container price-container ${question.winner && "winner-found"}`}>
        {question.giftImage && <Image src={`${question.giftImage.baseURL}/${question.giftImage.image}`} />}
        <p className="price-title bold">{question.giftTitle}</p>
        <p className="price-description" dangerouslySetInnerHTML={markDownParser(question.giftDescription || " ")} />
      </div>
    );
  }

  function getWinnerComponent() {
    return (
      <>
        {question.winner === null ? (
          <>
            {getPriceComponent()}
            <StatusBox
              style={{ margin: "2rem 0" }}
              icon={<TimerSandIcon />}
              title={lang.todaysWinnerNotFound}
              content={lang.howWeFindAWinner}
            />
          </>
        ) : (
          <>
            <div className={`${componentStyle()} container winner-container`}>
              {question.winner && question.winner !== null && (
                <>
                  <h2>
                    {`${lang.congratsTodaysWinner}!`}{" "}
                    <span role="img" aria-label="party popper">
                      🎉
                    </span>
                  </h2>
                  {getUserInfo()}
                </>
              )}
            </div>
            {getPriceComponent()}
          </>
        )}
      </>
    );
  }

  const getTabs = () => {
    const tabs = [
      <>
        <HelpIcon /> {lang.question}
      </>,
    ];
    if (!noDailyWinner) {
      tabs.push(
        <>
          <GiftIcon /> {lang.winner}
        </>
      );
    }
    tabs.push(
      <>
        <TrophyVariantOutlineIcon /> {lang.highscore}
      </>
    );
    return tabs;
  };

  const getTabView = () => {
    const tabView = [getQuestionComponent()];
    if (!noDailyWinner) {
      tabView.push(getWinnerComponent());
    }
    tabView.push(<ChristmasHighscoreMain christmasQuizId={christmasQuizId} />);
    return tabView;
  };

  return (
    <>
      <TabBar
        activeTabIndex={activeTab}
        tabs={getTabs().map((tab, tabIndex) => ({
          title: tab,
          onClick: () => void setActiveTab(tabIndex),
        }))}
      />
      {/* Loading */}
      {questionLoading && !questionError && <InlineSpinner style={{ margin: "2rem" }} />}
      {/* Error */}
      {!questionLoading && questionError && <StatusBox style={{ margin: "2rem" }} />}
      {/* Content */}
      {!questionLoading && !questionError && <TabView activeTabIndex={activeTab} tabs={getTabView()} useScrollView={true} />}
    </>
  );
};

const componentStyle = () => css`
  &.container {
    margin: 1rem 0 3rem 0;
    background-color: var(--white);
    padding: 1rem;
    white-space: normal;
    border-top: 1px var(--midGrey) solid;
    border-bottom: 1px var(--midGrey) solid;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1rem auto;
      border: 1px var(--midGrey) solid;
      border-radius: 3px;
      max-width: ${breakpoints.md}px;
    }
    &.no-background {
      background-color: transparent;
    }

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
    }

    video,
    iframe {
      width: 100%;
      border-radius: 3px;
      border: 1px var(--lightGrey) solid;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    img {
      width: 100%;
    }

    p {
      &.question-title,
      &.price-title {
        margin: 1rem 0;
      }
    }

    &.price-container {
      text-align: center;
      margin-bottom: 1rem;
    }

    &.winner-container {
      margin-bottom: 1rem;
    }

    .container-winner-info {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      padding: 5px 10px;

      .list-item-container {
        padding: 0;
      }

      img {
        margin-left: 1rem;
      }
    }

    .image-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 400px;
      margin: 3rem auto;
      img {
        width: 30vw;
        max-width: 100px;
        height: auto;
        align-self: baseline;
      }
    }
  }
`;

export default ChristmasCalendarQuestion;
