// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// Components
import Spinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";
import ButtonRounded from "../../ui/ButtonRounded";

// Config
import { registrationTypes as questionTypes } from "../../registration/config";
import breakpoints from "../../../config/breakpoints";
import approvalStatus from "../config/approvalStatus";
import logType from "../config/logType";
import logAction from "../config/logAction";
import { showModalPage } from "../../../actions/uiActions";
import TimeRegistrationHistory from "../../timeRegistration/modal/TimeRegistrationHistory";

// Icons
import {
  TickCircleIcon,
  AlertIcon,
  HourglassEmptyIcon,
  PlusCircleOutlineIcon,
  PencilIcon,
  InformationOutlineIcon,
  ExportIcon,
  HistoryIcon,
} from "mdi-react";

// Utilities
import req from "../../../utilities/request-utility";

// Style
import colors from "../../../style/colors";

// Actions
import RegistrationDetailModalActions from "./RegistrationDetailModalActions";
import ImageCarousel from "../../ui/ImageCarousel";

const RegistrationDetailModal = ({ timeRegistrationId, timeSheetId }) => {
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const dispatch = useDispatch();

  const [timeSheet, setTimeSheet] = useState({
    data: null,
    loading: true,
    error: false,
  });

  useEffect(() => {
    getTimesheet();
  }, []);

  const getTimesheet = async () => {
    try {
      setTimeSheet({ ...timeSheet, loading: true });
      const { data: timeSheetData } = await req()(
        `admin/time-registrations/${timeRegistrationId}/time-sheets/${timeSheetId}`
      );
      setTimeSheet({ ...timeSheet, data: timeSheetData, loading: false, error: false });
    } catch (error) {
      setTimeSheet({ ...timeSheet, loading: false, error: true });
    }
  };

  function openExtendedHistoryModal() {
    dispatch(
      showModalPage({
        content: <TimeRegistrationHistory timeSheetId={timeSheetId} timeRegistrationId={timeRegistrationId} />,
      })
    );
  }

  const { data, loading, error } = timeSheet;

  function formatQuestionAnswer(question) {
    if (question.type === questionTypes.DROPDOWN_FORM_BUILDER && Array.isArray(question.answer)) {
      return question.answer.map((answer) => (
        <div key={answer.id} className="box-wrapper">
          <div className="title-wrapper">
            <p>{answer.value.title}</p>
            {answer.isShared && <p className="shared">Delt tillæg</p>}
          </div>
          <p>{answer.value.amount}</p>
          <p>{answer.value.comment}</p>
        </div>
      ));
    } else if (question.type === questionTypes.IMAGE) {
      return (
        <ImageCarousel
          style={{ marginRight: "1rem", marginTop: "0.5rem" }}
          images={question.answer.map(({ value }) => ({ baseURL: value.baseURL, image: value.image }))}
        />
      );
    } else if (question.answer.value) {
      return (
        <p>
          {question.answer.value} {question.answer.optionId ? `(${question.answer.optionId})` : ""}
        </p>
      );
    } else {
      return (
        <p className="no-answer">
          <InformationOutlineIcon /> {`Ingen ${question.title.toLowerCase()}`}
        </p>
      );
    }
  }

  return (
    <div className={componentStyle(primaryColor)}>
      {loading && !error && <Spinner className="spinner" />}
      {!loading && error && <StatusBox />}
      {!loading && !error && (
        <>
          <RegistrationDetailModalActions
            user={data.user}
            exported={data.timeRegistrationData.exported}
            value={data.id}
            getTimesheet={() => getTimesheet()}
          />
          <div className="data-section">
            <h2>Stamdata</h2>

            <div className="content-container">
              {data.questions.map((question) => (
                <div key={question.id} className="information-container">
                  <label>{question.title}</label>
                  {formatQuestionAnswer(question)}
                </div>
              ))}
            </div>
          </div>

          <div className="data-section">
            <h2>Metadata</h2>
            <div className="content-container">
              <div className="information-container">
                <label>Dato</label>
                <p>{dayjs(String(data.timeRegistrationData.date), "YYYYMMDD").format("YYYY-MM-DD")}</p>
              </div>

              <div className="information-container">
                <label>Indrapporteret</label>
                <p>{dayjs(String(data.timeRegistrationData.createdAt), "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm")}</p>
              </div>

              <div className="information-container">
                <label>Medarbejder</label>
                <p>{data.user.name}</p>
              </div>

              {data.timeRegistrationData.approvals.map((approval) => (
                <div key={approval.id} className="information-container">
                  <label>{`Godkendt ${approval.groupName}`}</label>
                  <div className="approved-status">
                    {approval.status === approvalStatus.approved && (
                      <>
                        <TickCircleIcon color={colors.green} />
                        <div>
                          <p>
                            {dayjs(String(approval.approvedByUser.updatedAt), "YYYYMMDDhhmmss").format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </p>
                          <p>{approval.approvedByUser.name}</p>
                        </div>
                      </>
                    )}
                    {approval.status === approvalStatus.rejected && (
                      <>
                        <AlertIcon color={colors.red} />
                        <div>
                          <p>
                            {dayjs(String(approval.approvedByUser.updatedAt), "YYYYMMDDhhmmss").format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </p>
                          <p>{approval.approvedByUser.name}</p>
                        </div>
                      </>
                    )}
                    {approval.status === approvalStatus.pending && (
                      <>
                        <HourglassEmptyIcon color={colors.midDarkGrey} />
                        <p>Afventer godkendelse</p>
                      </>
                    )}
                  </div>
                </div>
              ))}

              <div className="information-container">
                <label>Eksporteret</label>
                {data.timeRegistrationData.exported === true && <p>Ja</p>}
                {data.timeRegistrationData.exported === false && <p>Nej</p>}
              </div>
            </div>
          </div>

          <div className="data-section" id="history-data-section">
            <h2>Historik</h2>
            <ButtonRounded
              style={{ marginTop: "-1rem", marginBottom: "1rem" }}
              secondary={true}
              size="small"
              onClick={openExtendedHistoryModal}
            >
              <HistoryIcon style={{ width: "1.15rem", height: "1.15rem", marginRight: "0.35rem" }} /> Se udvidet historik
            </ButtonRounded>

            <div className="content-container history">
              {data.timeRegistrationData.logs.map((log) => (
                <div key={log.id}>
                  {/* Approvals */}
                  {log.status === approvalStatus.rejected && log.type === logType.APPROVAL && (
                    <div className="information-container">
                      <AlertIcon color={colors.red} />
                      <div className="text-container">
                        <label>Afvist {log.groupName}</label>
                        <p>
                          {dayjs(String(log.approvedByUser.updatedAt), "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")} -{" "}
                          {log.approvedByUser.name}
                        </p>
                        {log.approvedByUser.comment && (
                          <p className="text-content">{`Kommentar: ${log.approvedByUser.comment}`}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {log.status === approvalStatus.approved && log.type === logType.APPROVAL && (
                    <div className="information-container">
                      <TickCircleIcon color={colors.green} />
                      <div className="text-container">
                        <label>Godkendt {log.groupName}</label>
                        <p>
                          {dayjs(String(log.approvedByUser.updatedAt), "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")} -{" "}
                          {log.approvedByUser.name}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Data logs (User actions to the time-sheet) */}
                  {log.action === logAction.CREATED && log.type === logType.DATA_LOG && (
                    <div className="information-container">
                      <PlusCircleOutlineIcon color={colors.midDarkGrey} />
                      <div className="text-container">
                        <label>Oprettet</label>
                        <p>
                          {dayjs(String(log.registeredByUser.updatedAt), "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")} -{" "}
                          {log.registeredByUser.name}
                        </p>
                      </div>
                    </div>
                  )}

                  {log.action === logAction.EDITED && log.type === logType.DATA_LOG && (
                    <div className="information-container">
                      <PencilIcon color={colors.midDarkGrey} />
                      <div className="text-container">
                        <label>Redigeret</label>
                        <p>{`${dayjs(String(log.registeredByUser.updatedAt), "YYYYMMDDhhmmss").format(
                          "YYYY-MM-DD HH:mm:ss"
                        )} - ${log.registeredByUser.name}`}</p>
                      </div>
                    </div>
                  )}

                  {log.action === logAction.EXPORTED && log.type === logType.DATA_LOG && (
                    <div className="information-container">
                      <ExportIcon color={colors.midDarkGrey} />
                      <div className="text-container">
                        <label>Eksporteret</label>
                        <p>{`${dayjs(String(log.registeredByUser.updatedAt), "YYYYMMDDhhmmss").format(
                          "YYYY-MM-DD HH:mm:ss"
                        )} - ${log.registeredByUser.name}`}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  max-width: ${breakpoints.xl + 200}px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  align-items: stretch;

  .spinner {
    margin: 2rem auto 0;
  }

  .data-section {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid var(--lightGrey);

    &:last-of-type {
      border-bottom: none;
    }

    h2 {
      margin: 2rem 0;
    }

    p.no-answer {
      display: flex;
      align-items: center;
      color: var(--darkGrey);
      font-size: 0.9rem;
      margin-top: 0.25rem;

      svg {
        margin-right: 0.35rem;
        width: 1.15rem;
        height: 1.15rem;
        color: var(--darkGrey);
      }
    }

    .content-container {
      height: 100%;
      width: 100%;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      width: 33%;
      border-bottom: none;
      .content-container {
        border-right: 1px solid var(--lightGrey);

        &.history {
          border-right: none;
        }
      }
    }

    @media screen and (max-width: ${breakpoints.md}px) {
      &#history-data-section {
        margin-bottom: 3rem;
      }
    }

    .information-container {
      padding: 0.5rem 0;

      label {
        color: var(--darkGrey);
      }

      div.box-wrapper {
        border: 1px solid var(--midGrey);
        border-radius: 3px;
        padding: 0.5rem;
        margin: 0.5rem 2rem 0.5rem 0;

        div.title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: ${colors.midDarkGrey};

            &.shared {
              padding: 0.125rem 0.25rem;
              color: ${primaryColor};
              border: 1px solid var(--midGrey);
              border-radius: 3px;
              font-size: 0.75rem;
            }
          }
        }
      }

      .approved-status {
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.5rem;
          flex-shrink: 0;
        }
      }

      p.text-content {
        white-space: pre-wrap;
      }
    }

    .history {
      border-left: 1px dashed var(--lightGrey);
      height: fit-content;

      .information-container {
        margin-left: -12px;
        display: flex;
        align-items: flex-start;

        svg {
          margin-right: 1rem;
          flex-shrink: 0;
        }
      }
    }
  }

  #history-data-section {
    padding-right: calc(1rem + 12px);
  }
`;

export default RegistrationDetailModal;
