import { getDateString, parseDate } from "./parse-date";

export default (anniversaries, language, stateAnniversaries = []) => {
  try {
    // Current date that has same form as the anniversaries dates
    const currentDate = getDateString();

    // If there already is existing anniversaries from the redux state -> append them
    const finalAnniversaries = [...stateAnniversaries] || [];

    let index = -1;

    anniversaries.forEach(anniversary => {
      // If it's the current day
      if (currentDate === anniversary.anniversaryDate) {
        // Check is the already has been a created an object with an array to store the anniversaries
        index = finalAnniversaries.findIndex(groupDate => groupDate.date === anniversary.anniversaryDate);
        if (index >= 0) {
          // Push the anniversaries to the position of the finalAnniversaries array with the matching date
          finalAnniversaries[index].anniversaries.push(anniversary);
        } else {
          // Create a new object to contain the anniversaries
          finalAnniversaries.push({
            date: anniversary.anniversaryDate,
            anniversaries: [anniversary],
            title: language.today,
          });
        }
      }
      // If it's any other date than the current
      else {
        // Check is the already has been a created an object with an array to store the anniversaries
        index = finalAnniversaries.findIndex(groupDate => groupDate.date === anniversary.anniversaryDate);
        if (index >= 0) {
          // Push the anniversaries to the position of the finalAnniversaries array with the matching date
          finalAnniversaries[index].anniversaries.push(anniversary);
        } else {
          // Create a new object to contain the anniversaries
          finalAnniversaries.push({
            date: anniversary.anniversaryDate,
            anniversaries: [anniversary],
            title: parseDate(anniversary.anniversaryDate),
          });
        }
      }
    });

    // Return the final structured anniversaries
    return finalAnniversaries;
  } catch (err) {
    throw err;
  }
};
