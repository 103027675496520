// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";

// Styles
import useCurrentPage from "../../hooks/useCurrentPage";
import pageConfig from "../../config/pageConfig";
import ScheduleOverviewGroupsTab from "./components/ScheduleOverviewGroupsTab";
import ScheduleOverviewMyScheduleTab from "./components/ScheduleOverviewMyScheduleTab";
import ScheduleOverviewAvailableShiftsTab from "./components/ScheduleOverviewAvailableShiftsTab";
import ScheduleOverviewPunchClockTab from "./components/ScheduleOverviewPunchClockTab";

const ScheduleOverview = (props) => {
  const page = useCurrentPage({ pageId: props.match.params.pageId });

  const [tabs, setTabs] = useState([{ title: "Grupper" }]);
  const [activeTab, setActiveTab] = useState(0);

  // Set the tabs
  useEffect(() => {
    let localTabs = [];

    // Be aware that the order of the statements determine the order of the tabs :)
    if (page.config && page.config[pageConfig.SHOW_PUNCH_CLOCK_TAB] === true) localTabs.push("Stempelur");
    if (page.config && page.config[pageConfig.SHOW_MY_SCHEDULE_TAB] === true) localTabs.push("Mine vagter");
    if (page.config && page.config[pageConfig.SHOW_FREE_SHIFTS_TAB] === true) localTabs.push("Ledige vagter");
    localTabs.push("Grupper"); // Default tab. Will always be there

    setTabs(localTabs);
  }, [page]);

  function getTabComponents() {
    let localTabs = [];

    // Be aware that the order of the statements determine the order of the tabs :)
    if (page.config && page.config[pageConfig.SHOW_PUNCH_CLOCK_TAB] === true) {
      localTabs.push(<ScheduleOverviewPunchClockTab />);
    }

    if (page.config && page.config[pageConfig.SHOW_MY_SCHEDULE_TAB] === true) {
      localTabs.push(<ScheduleOverviewMyScheduleTab />);
    }

    if (page.config && page.config[pageConfig.SHOW_FREE_SHIFTS_TAB] === true) {
      localTabs.push(<ScheduleOverviewAvailableShiftsTab />);
    }

    localTabs.push(<ScheduleOverviewGroupsTab match={props.match} />); // Default tab. Will always be there

    return localTabs;
  }

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      {tabs.length > 1 && (
        <TabBar
          tabs={tabs.map((title, index) => ({ title, onClick: () => setActiveTab(index) }))}
          activeTabIndex={activeTab}
          onClick={(activeTab) => void setActiveTab(activeTab)}
        />
      )}

      <TabView activeTabIndex={activeTab} useScrollView={false} tabs={getTabComponents()} />
    </Page>
  );
};

export default ScheduleOverview;
