// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import PageBuilderContent from "./components/PageBuilderContent";
import PageBuilderHeader from "./components/PageBuilderHeader";
import TopBar from "../ui/TopBar";

// Context
import { PageBuilderContext } from "./context/PageBuilderContext";

// Actions
import { getPageBuilderPages, updateParentPageId } from "../../actions/pageBuilderActions";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";
import useApplicationLanguages from "../../hooks/useApplicationLanguages";
import { PageBuilderAuth } from "./components/PageBuilderAuth";
import ActionWrapper from "../ui/ActionWrapper";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import { ArrowLeftIcon } from "mdi-react";
import getAppName from "../../utilities/get-app-name";

const PageBuilder = (props) => {
  const dispatch = useDispatch();

  const { applicationLanguages } = useApplicationLanguages();

  const pageId = props.match.params.pageId;

  const page = useCurrentPage({ pageId });
  const pages = useSelector((state) => state.pageBuilder.pages);
  const authorized = useSelector((state) => state.pageBuilder.authorized);

  useEffect(() => {
    dispatch(updateParentPageId({ parentPageId: undefined }));

    if (!pages || (pages && pages.length === 0)) dispatch(getPageBuilderPages());
  }, []);

  const navigateBack = () => {
    pageNavigatorV2({ path: `/${getAppName()}`, direction: "backward" });
  };

  return (
    <Page>
      <PageBuilderContext.Provider value={{ applicationLanguages, disableDraggableAction: false, moduleId: page.id }}>
        <TopBar
          data-test-id={"page-builder-top-bar"}
          actionLeft={
            <ActionWrapper onClick={navigateBack}>
              <ArrowLeftIcon />
            </ActionWrapper>
          }
          title={page.title}
        />
        {authorized && (
          <>
            <PageBuilderHeader {...props} />
            <PageBuilderContent pages={pages} {...props} />
          </>
        )}
        {!authorized && <PageBuilderAuth />}
      </PageBuilderContext.Provider>
    </Page>
  );
};

export default PageBuilder;
