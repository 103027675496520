export const filterModifiedPages = ({ pages }) => {
  try {
    let modifiedPages = [];

    for (let page of pages) {
      if (page?.flags?.modified) modifiedPages.push(page);

      if (Array.isArray(page.pages) && page.pages.length > 0) {
        modifiedPages = [...modifiedPages, ...filterModifiedPages({ pages: page.pages })];
      }
    }

    return modifiedPages;
  } catch (error) {
    console.log(error);
  }
};
