// Libs
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import * as queryString from "query-string";

// Components
import TabBar from "../../ui/TabBar";
import TabView from "../../ui/TabView";
import StatusBox from "../../ui/StatusBox";
import Notification from "../../ui/Notification";

// Style
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";
import { AlertDecagramIcon, InfoOutlineIcon } from "mdi-react";
import RegistrationCard from "../components/RegistrationCard";
import InlineSpinner from "../../ui/InlineSpinner";
import RegistrationForm from "../components/RegistrationForm";

// Actions
import { setSelectedDate } from "../../../actions/timeRegistrationActions";
import { getRegistrationForm, resetRegistrationForm } from "../../../actions/registrationActions";

// Utilities
import req from "../../../utilities/request-utility";
import summaryModes from "../config/summaryModes";
import getformattedHour from "../../../utilities/get-formatted-hour";

// Config
import approvalStatuses from "../config/approvalStatuses";
import { addToast } from "../../../actions/uiActions";
import styleTypes from "../../../config/styleTypes";

const TimeRegistration = (props) => {
  const { date, summaryMode } = props;

  const dispatch = useDispatch();
  // Redux
  const lang = useSelector((state) => state.language.language);
  const selectedUser = useSelector((s) => s.timeRegistration.selectedUser);

  // UI Layout
  const [tabs] = useState([`${lang.register} ${lang.hours.toLowerCase()}`, lang.registrations]);
  const [activeTab, setActiveTab] = useState(props.activeTab || 0);
  const [totalValue, setTotalTotal] = useState("");

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  // State
  const [loading, setLoading] = useState(true);
  const [registrations, setRegistrations] = useState([]);
  const [rejectedRegistrationsCount, setRejectedRegistrationsCount] = useState(0);

  useEffect(() => {
    dispatch(setSelectedDate(date));
    getTodaysRegistrations();
    getTotalValue();

    dispatch(resetRegistrationForm());
    dispatch(getRegistrationForm({ registrationId: timeRegistrationId }));

    getTodaysRegistrations();
    // eslint-disable-next-line
  }, []);

  const getRejectedRegistrationsCount = (registrations) => {
    let count = 0;

    registrations
      // Start by selecting all those that are not approved
      .filter((r) => !r.timeRegistrationData.isApproved)
      // Then map through the rejected registrations
      .map((registration) => {
        // If the main registration isn't approved we will map through
        // its approvals individually and add 1 to count for every found
        // rejection
        if (registration?.timeRegistrationData?.isApproved === false) {
          // Get all the approvals that are rejected and add the amount
          // to count
          count += registration?.timeRegistrationData?.approvals.filter(
            (a) => a.status === approvalStatuses.REJECTED
          ).length;
        }
      });

    return count;
  };

  const refreshData = async () => {
    await Promise.all([getTotalValue(), getTodaysRegistrations()]);
  };

  const getTodaysRegistrations = async () => {
    try {
      let queryParams = "";
      if (selectedUser) queryParams = `?selectedUserId=${selectedUser.id}`;
      const { data: registrations } = await req()(
        `time-registrations/${timeRegistrationId}/time-sheets/${date}${queryParams}`
      );

      setRejectedRegistrationsCount(getRejectedRegistrationsCount(registrations));
      setRegistrations(registrations);
      setLoading(false);
    } catch (error) {
      dispatch(
        addToast({
          styleType: styleTypes.error,
          title: "Kunne ikke hente data",
          content: `${lang.errorGeneral} ${lang.tryAgainOrContactSupport}`,
          icon: <AlertDecagramIcon />,
        })
      );
    }
  };

  const getTotalValue = async () => {
    try {
      let queryParam = "";
      queryParam += `?fromDate=${date}&toDate=${date}`;
      if (selectedUser) queryParam += `&selectedUserId=${selectedUser.id}`;

      const { data: registrations } = await req()(`time-registrations/${timeRegistrationId}/time-sheets${queryParam}`);

      if (registrations.length === 0) return;

      let registration = registrations[0];

      let result = "";

      if (summaryMode === summaryModes.HOURS) {
        result = getformattedHour(registration.hours);
      }

      if (summaryMode === summaryModes.COUNT) {
        if (registration.count === 1) result = `${registration.count} ${lang.registration.toLowerCase()}`;
        else {
          result = `${registration.count} ${lang.registrations.toLowerCase()}`;
        }
      }

      setTotalTotal(result);
    } catch (error) {
      dispatch(
        addToast({
          styleType: styleTypes.error,
          title: "Kunne ikke hente data",
          content: `${lang.errorGeneral} ${lang.tryAgainOrContactSupport}`,
          icon: <AlertDecagramIcon />,
        })
      );
    }
  };

  const getShowRegisteredHoursTab = () => {
    if (registrations.length === 0) {
      return (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noRegistrations}
          content={lang.NoHoursRegistered}
        />
      );
    }
    return (
      <div className={componentStyle()}>
        {registrations.map((registration) => (
          <RegistrationCard key={registration.id} registration={registration} refreshData={refreshData} />
        ))}
      </div>
    );
  };

  return (
    <>
      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title:
            tabIndex === 0 ? (
              tab
            ) : (
              <>
                {tab}
                <Notification
                  style={{ fontSize: "12px", height: "18px", marginLeft: "0.5rem" }}
                  notifications={rejectedRegistrationsCount}
                />
              </>
            ),
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      {loading && <InlineSpinner style={{ margin: "2rem" }} />}
      {!loading && (
        <TabView
          activeTabIndex={activeTab}
          tabs={[<RegistrationForm totalValue={totalValue} />, getShowRegisteredHoursTab()]}
        />
      )}
    </>
  );
};

const componentStyle = () => css`
  background-color: var(--white);

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 1rem auto;
  }
`;

export default TimeRegistration;
