import React from "react";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import GroupedUserList from "../ui/GroupedUserList";
import StatusBox from "../ui/StatusBox";
import componentStyle from "../arrivals-and-departures/style/styles";
import Spinner from "../ui/InlineSpinner";

/**
 * @param { object } props
 * @param { object } props.groups
 * @param { object } props.page
 * @param { object } props.error
 * @param { boolean } props.loading
 * @return { JSX.Element }
 * @constructor
 */
const GroupedUserPage = ({ groups, page, error, loading }) => {
  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <div className={componentStyle()}>
        {groups && <GroupedUserList groups={groups} className={"users-container"} />}
        {error && (
          <div className="status-container">
            <StatusBox status="error" className="status-box" />
          </div>
        )}
        {loading && (
          <div className={"status-container"}>
            <Spinner status="loading" className="status-box" />
          </div>
        )}
      </div>
    </Page>
  );
};

export default GroupedUserPage;
