import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";

// Components
import InformationBox from "../../ui/InformationBox";
import ButtonRounded from "../../ui/ButtonRounded";
import ActionWrapper from "../../ui/ActionWrapper";
import NewsTargetGroupModal from "./NewsTargetGroupModal";
import TextareaInput from "../../ui/TextareaInput";

// Icons
import { InfoOutlineIcon, SendIcon, AccountGroupIcon, AlertOctagonIcon, ChatIcon } from "mdi-react";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast, showModalPage, hideModalPage } from "../../../actions/uiActions";

// Hooks
import useLang from "../../../hooks/useLang";

// Config
import breakpoints from "../../../config/breakpoints";

const ReminderSmsModal = ({ subTypeId, newsId, page }) => {
  const dispatch = useDispatch();
  const lang = useLang();

  // Redux state
  const { title: appName } = useSelector((state) => state.appConfig);

  // State
  const [appLanguages, setAppLanguages] = useState({ primary: {}, foreign: {} });
  const [smsContent, setSmsContent] = useState({ primary: "", foreign: "" });
  const [receiversCount, setReceiversCount] = useState("");

  useEffect(() => {
    getReminderSmsContent();
    getApplicationLanguages();
    getReceiversCount();
  }, []);

  const getReminderSmsContent = async () => {
    try {
      const { data } = await req()(`news/${subTypeId}/${newsId}/getReminderSmsContent?pageId=${page}&appName=${appName}`);
      setSmsContent(data);
    } catch (error) {
      dispatch(
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "10000",
          title: lang.networkError,
          content: lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/g, lang.contentOfSms.toLowerCase()),
        })
      );
    }
  };

  const getReceiversCount = async () => {
    try {
      let { data: members } = await req().get(`news/${subTypeId}/${newsId}/target-group/members`);
      const reminderSmsReceiversCount = members.filter((user) => !user.read).length;
      setReceiversCount(reminderSmsReceiversCount);
    } catch (error) {
      dispatch(
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "10000",
          title: lang.networkError,
          content: lang.networkError,
        })
      );
    }
  };

  const getApplicationLanguages = async () => {
    req()("meta/app-languages")
      .then(({ data }) => {
        setAppLanguages(data);
      })
      .catch(() => {
        dispatch(
          addToast({
            styleType: "error",
            icon: <AlertOctagonIcon />,
            duration: "10000",
            title: lang.networkError,
            content: lang.couldNotFetchLanguages,
          })
        );
      });
  };

  const handleOpenTargetGroupModal = () => {
    dispatch(
      showModalPage({
        title: lang.receivers,
        content: (
          <NewsTargetGroupModal
            subTypeId={subTypeId}
            newsId={newsId}
            onlyUnread={true}
            customisedDescription={lang.receivers}
          />
        ),
        useScrollView: false,
      })
    );
  };

  const handleInputChange = ({ language, e }) => {
    setSmsContent({ ...smsContent, [language]: e.target.value });
  };

  const handleSendReminderSms = async () => {
    try {
      /* In order to generically load the jsx, the smsContent object has the keys "primary" and "foreign".
       * But now that we are sending the smsContent to the api, and has to send a message to each user based on their selected language I want to refactor the object to have the keys reflecting the actual languages of the app in question */

      // First I refactor the key for the primary language to reclect the actual primary language
      let localSmsContent = {
        [appLanguages?.primary?.abbreviation]: smsContent?.primary,
      };

      // If a secondary language exists, I likewise refactor the key, to reflect the actual secondary language
      if (appLanguages?.foreign?.abbreviation) {
        localSmsContent[appLanguages.foreign.abbreviation] = smsContent.foreign;
      }

      await req().post(`news/${subTypeId}/${newsId}/sendReminderSms`, { smsContent: localSmsContent });
      dispatch(
        addToast({
          styleType: "success",
          icon: <ChatIcon />,
          duration: "10000",
          title: `${lang.success}!`,
          content: lang.reminderSmsSent,
        })
      );
      dispatch(hideModalPage());
    } catch (error) {
      dispatch(
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "10000",
          title: lang.networkError,
          content: lang.messageNotSent,
        })
      );
    }
  };

  const getSmsContentJSX = ({ language }) => {
    return (
      <div className="text-container">
        {/* We only show the name of the language, if the app uses two languages */}
        {appLanguages?.foreign?.id && (
          <p className="label">
            {/* If we show the name of the language, we always show the local name = the name of the language in it own language, eg: Dansk or English. */}
            {`${appLanguages?.[language]?.localName} ${
              // Unless the language is english (id = 3) we also show the english name afterwards in parenthesis, eg: Dansk (Danish) - this would be repetitative if the language is English, eg: English (English)
              appLanguages?.[language]?.id && appLanguages?.[language]?.id !== 3
                ? "(" + appLanguages[language]?.name + ")"
                : ""
            }`}
          </p>
        )}
        <TextareaInput value={smsContent[language]} onChange={(e) => handleInputChange({ language, e })} />
      </div>
    );
  };
  return (
    <div className={componentStyle()}>
      <InformationBox
        icon={<InfoOutlineIcon />}
        title={`${lang.whoWillReceiveSms}?`}
        description={lang.reminderSmsDescription}
      />

      <div>
        <div className="headline-container">
          <h2>{lang.contentOfSms}</h2>
          <ActionWrapper className="count-container" onClick={handleOpenTargetGroupModal}>
            <AccountGroupIcon />
            {receiversCount} {lang.receivers.toLowerCase()}
          </ActionWrapper>
        </div>

        {getSmsContentJSX({ language: "primary" })}

        {/* If the app uses a second language, we also get the sms content for the foreign language */}
        {appLanguages?.foreign?.id && getSmsContentJSX({ language: "foreign" })}
      </div>

      <div className="button-container">
        <ButtonRounded
          onClick={() => {
            handleSendReminderSms();
          }}
        >
          <SendIcon /> {lang.sendSms}
        </ButtonRounded>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  margin: 1rem 0 3rem 0;
  padding: 1rem;
  max-width: ${breakpoints.md};
  margin: 0 auto;

  .headline-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .count-container {
      color: var(--primary-color);
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  .text-container {
    margin: 1rem 0;

    .label {
      margin-bottom: 0.5rem;
    }
  }

  .button-container {
    display: flex;
    justify-content: right;

    button {
      svg {
        margin-right: 5px;
        width: 20px;
      }
    }
  }
`;

export default ReminderSmsModal;
