import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { css } from "emotion";

// Actions
import { openSideMenu } from "../actions/sideMenuActions";
import { showModalPage, addToast, showNextToast } from "../actions/uiActions";
import { getActiveThemeQuizzes } from "../actions/themeQuizActions";
import { getPages } from "../actions/pagesActions";
import { getFrontendAdminMapping } from "../actions/newsActions";

// Style
import colors from "../style/colors";
import breakpoints from "../config/breakpoints";

// Utilities
import pageNavigator_v2 from "../utilities/page-navigator-v2";
import clearLocalStorage from "../utilities/clear-local-storage";
import getAppName from "../utilities/get-app-name";
import pageTransitions from "../config/page-transitions";
import req from "../utilities/request-utility";

// Components
import Page from "../components/ui/Page";
import TopBar from "../components/ui/TopBar";
import Notification from "./ui/Notification";
import ActionWrapper from "./ui/ActionWrapper";
import StatusBox from "./ui/StatusBox";
import FrontPageMenuItem from "./FrontPage/FrontPageMenuItem";

// Icons
import { MenuIcon, SearchIcon, CheckboxBlankCircleIcon, BellOutlineIcon } from "mdi-react";
import BreakingBar from "./ui/BreakingBar";
import ButtonRounded from "./ui/ButtonRounded";

function getFirstNameFromFullName(nameString) {
  if (!nameString) return "";

  // No spaces (nothing to split by)
  if (nameString.split(" ").length === 1) return nameString;

  return nameString.split(" ")[0];
}

const FrontPage = (props) => {
  const [clickCount, setClickCount] = useState(0);
  const [greeter, setGreeter] = useState(null);
  const [showBreakingBar, setShowBreakingBar] = useState(false);
  const [breakingBarData, setBreakingBarData] = useState({});
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
  const [christmasCalendarNotificationsCount, setChristmasCalendarNotificationsCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  const { language: lang } = useSelector((state) => state.language);
  const appConfig = useSelector((state) => state.appConfig);

  const { disableFrontpageImageDarkening = false, christmasCalendarId = null, disableNotificationCenter = true } = appConfig;
  const { pages } = props;

  // Count total amount of available notifications
  useEffect(() => {
    if (pages.pages.length < 1) return;
    let amount = 0;
    pages.pages.forEach((page) => (amount += page.notifications));
    setTotalNotificationsCount(amount);
  }, [pages]);

  useEffect(() => {
    // Toggles dialog if name is pressed 15 times
    if (clickCount === 15) {
      if (window.confirm("You are about to reset the app. This will log you out. Are you sure you want to continue?")) {
        clearLocalStorage((err) => {
          window.location = window.location.origin + "/" + getAppName();
        });
      } else {
        setClickCount(0);
      }
    }

    // eslint-disable-next-line
  }, [clickCount]);

  // This is set because of notifications - when a user clicks on a module, the pages need to update..
  useEffect(() => {
    handleGreeting();
    checkForChristmasCalendarNotifications();

    setTimeout(() => {
      props.getActiveThemeQuizzes();

      // Get the count of new notifications
      req()(`notifications/unseen-count`).then(({ data: notificationCount }) => {
        setNotificationCount(notificationCount);
      });

      checkForBreakingBanner();
      props.getFrontendAdminMapping();
    }, 2500);

    // eslint-disable-next-line
  }, []);

  function handleGreeting() {
    try {
      req()(`greeter/`).then(({ data: greeter }) => {
        setGreeter(greeter);
      });
    } catch (err) {
      setGreeter({ content: props.lang.hello });
    }
  }

  function checkForBreakingBanner() {
    try {
      req()(`frontpage-banners/`).then(({ data }) => {
        if (data) {
          setBreakingBarData(data);
          setShowBreakingBar(true);
        }
      });
    } catch (err) {}
  }

  function dismissBreakingBanner(id) {
    setShowBreakingBar(false);

    try {
      req()
        .put(`frontpage-banners/${id}/dismissal`)
        .then(({ data }) => {
          checkForBreakingBanner();
        });
    } catch (err) {}
  }

  function checkForChristmasCalendarNotifications() {
    if (!christmasCalendarId) return;
    req()(`christmas-quizzes/${christmasCalendarId}/notification-count`)
      .then(({ data: notificationCount }) => {
        setChristmasCalendarNotificationsCount(notificationCount);
      })
      .catch(() => {});
  }

  return (
    <Page backgroundColor={colors.white}>
      {/* Topbar */}
      <TopBar
        //potential delete
        className={`${topbar()}`}
        allowActionsOverflow={true}
        actionLeft={
          <ActionWrapper onClick={props.openSideMenu} data-test-id={"side-menu-frontpage"} style={{ overflow: "visible" }}>
            <MenuIcon style={{ fill: props.appConfig.primaryColor }} />
            <Notification
              notifications={totalNotificationsCount}
              style={{ position: "absolute", top: "env(safe-area-inset-top)px + 2px", left: "20px" }}
            />
          </ActionWrapper>
        }
        title={
          props.appConfig &&
          props.appConfig.customerLogo && (
            <img
              src={props.appConfig.customerLogo.baseURL + props.appConfig.customerLogo.image}
              alt={`Logo for ${props.appConfig.title}`}
            />
          )
        }
        atFrontPage={true}
        actionRight={
          <>
            <ActionWrapper
              onClick={() => {
                pageNavigator_v2({ path: `/${getAppName()}/search`, direction: pageTransitions.modalPageUp });
              }}
            >
              <SearchIcon
                style={{
                  fill: props.appConfig.primaryColor,
                  padding: "0.5rem",
                  width: "40px",
                  height: "40px",
                }}
              />
            </ActionWrapper>
            {!disableNotificationCenter && (
              <ActionWrapper
                data-test-id="social-feed__add-post"
                onClick={() => {
                  pageNavigator_v2({ path: `/${getAppName()}/notificationCenter`, direction: pageTransitions.modalPageUp });
                }}
                style={{ marginRight: "0.5rem" }}
              >
                <Notification
                  style={{ position: "absolute", top: "env(safe-area-inset-top)px + 2px", right: "0.4rem" }}
                  notifications={notificationCount}
                />
                <BellOutlineIcon
                  style={{
                    fill: props.appConfig.primaryColor,
                    padding: "0.5rem",
                    width: "40px",
                    height: "40px",
                  }}
                />
              </ActionWrapper>
            )}
          </>
        }
      />

      {/* Header */}
      <div className={`${header()}`} onClick={() => setClickCount(clickCount + 1)}>
        <BreakingBar
          active={showBreakingBar}
          content={breakingBarData.content}
          styleType={breakingBarData.styleType}
          id={breakingBarData.id}
          dismissBreakingBanner={dismissBreakingBanner}
        />

        {christmasCalendarId ? (
          <ButtonRounded
            secondary={true}
            style={{ position: "relative" }}
            onClick={() => {
              pageNavigator_v2({
                path: `/${getAppName()}/ChristmasQuiz?dataId=${christmasCalendarId}`,
                direction: "forward",
              });
            }}
          >
            <span style={{ marginRight: "0.25rem", marginBottom: "0.4rem" }}>🎄</span>
            {lang.christmasCalendar}

            <Notification
              notifications={christmasCalendarNotificationsCount}
              style={{ position: "absolute", top: "-10%", right: "-2%" }}
            />
          </ButtonRounded>
        ) : (
          <p className={`text-center greeting ${greeter && !showBreakingBar && "show"}`}>
            {greeter && greeter.content}, {getFirstNameFromFullName(props.userName)}
            {/* Users birthday */}
            {greeter && greeter.category === 6 && (
              <span role="img" aria-label="party-face">
                {" "}
                🥳
              </span>
            )}
          </p>
        )}
      </div>

      {/* Loading -> "Skeleton" icons with blinking animations */}
      {pages.pages.length === 0 && pages.loading && !pages.error && (
        <div className={`${iconsContainer({ props, disableFrontpageImageDarkening })} frontpage-navigation`}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, index) => (
            <div className={icons()} key={index}>
              <CheckboxBlankCircleIcon className="placeholder-icon" style={{ animationDelay: `${index * 30}ms` }} />
              <div className="fake-title" style={{ animationDelay: `${index * 30}ms` }}></div>
            </div>
          ))}
        </div>
      )}

      {/* App misconfigured */}
      {pages.pages.length === 0 && !pages.loading && !pages.error && (
        <div className={`${iconsContainer({ props, disableFrontpageImageDarkening })} frontpage-navigation`}>
          <StatusBox
            style={{ marginTop: "2rem", color: colors.white }}
            action={{ callback: props.getPages, title: "Try again" }}
            title={lang.appGotNoPages}
            content={lang.noPagesFoundTryAgainOrContact}
          />
        </div>
      )}

      {/* Error getting pages */}
      {pages.pages.length === 0 && !pages.loading && pages.error && (
        <StatusBox
          style={{ marginTop: "2rem" }}
          action={{ callback: props.getPages, title: "Try again" }}
          title={lang.errorLoadingPages}
          content={lang.noPagesFoundTryAgainOrContact}
        />
      )}

      {/* Pages present */}
      {pages.pages.length > 0 && (
        <div className={`${iconsContainer({ props, disableFrontpageImageDarkening })} frontpage-navigation`}>
          {pages.pages
            .filter((el) => el.showAtFrontPage)
            .map((page, index) => {
              if (page.module === "empty-tile") {
                return <div className={`${icons()} empty-tile`}></div>;
              }
              return <FrontPageMenuItem page={page} className={icons()} key={index} />;
            })}
        </div>
      )}
    </Page>
  );
};
const topbar = () => css`
  border: 2px solid red;
  svg {
    border: 2px solid red;
  }

  .action-right {
    border: 2px solid red;
  }
`;

const header = () => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
  height: 70px;

  p.greeting {
    transition: 300ms opacity ease, max-height 300ms ease;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }
`;

const iconsContainer = ({ props, disableFrontpageImageDarkening }) => css`
  flex: 0 1 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
  background-color: ${props.appConfig.primaryColor};
  background-image: url(${getBackgroundImageURL(props, 800, disableFrontpageImageDarkening)});

  @media screen and (max-height: 500px) {
    background-image: url(${getBackgroundImageURL(props, 500, disableFrontpageImageDarkening)});
  }
  @media screen and (min-height: 500px) and (max-height: 800px) {
    background-image: url(${getBackgroundImageURL(props, 800, disableFrontpageImageDarkening)});
  }
  @media screen and (min-height: 800px) and (max-height: 1200px) {
    background-image: url(${getBackgroundImageURL(props, 1200, disableFrontpageImageDarkening)});
  }
  @media screen and (min-height: 1201px) {
    background-image: url(${getBackgroundImageURL(props, 1800, disableFrontpageImageDarkening)});
  }
`;

const icons = () => css`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.333%;
  height: 25%;
  padding: 0.5rem;
  vertical-align: top;
  font-size: 0.8rem;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  transition: background-color 180ms ease;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};

  /* First column */
  &:nth-child(3n + 1) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }
  /* Second column */
  &:nth-child(3n + 2) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }

  &.empty-tile {
    pointer-events: none;
  }

  svg {
    margin-bottom: 0.5rem;
    width: 2rem;
    height: 2rem;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
    transition: width 400ms ease, height 400ms ease;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    font-size: 0.8rem;
    svg {
      margin-bottom: 0.55rem;
      width: 2.2rem;
      height: 2.2rem;
    }
  }
  @media screen and (min-width: ${breakpoints.xs + 1}px) and (max-width: ${breakpoints.xl}px) {
    font-size: 0.825rem;
    svg {
      margin-bottom: 0.6rem;
      width: 2.3rem;
      height: 2.3rem;
    }
  }
  @media screen and (min-width: ${breakpoints.xl + 1}px) {
    font-size: 0.9rem;
    svg {
      margin-bottom: 0.7rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  svg.placeholder-icon {
    width: 2.25rem;
    height: 2.25rem;
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  .fake-title {
    width: 65px;
    height: 7px;
    border-radius: 5px;
    margin-top: 7px;
    background-color: rgba(255, 255, 255, 0.6);
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    35% {
      opacity: 0.3;
    }
    55% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.3;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
    transition: background-color 120ms ease;
  }
  &:active {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.12);
    transition: background-color 120ms ease;
  }
`;

function getBackgroundImageURL(props, size = 800, disableFrontpageImageDarkening) {
  let darkeningOverlay = disableFrontpageImageDarkening ? "" : "b_black,o_50,";
  return `${props.appConfig.frontPageImage.baseURL}${darkeningOverlay}h_${size},q_auto,f_auto/${props.appConfig.frontPageImage.image}`;
}

const mapStateToProps = (state) => ({
  pages: state.pages,
  appConfig: state.appConfig,
  userName: state.auth.user.name,
  user: state.auth.user,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  getPages: bindActionCreators(getPages, dispatch),
  openSideMenu: bindActionCreators(openSideMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  showNextToast: bindActionCreators(showNextToast, dispatch),
  getActiveThemeQuizzes: bindActionCreators(getActiveThemeQuizzes, dispatch),
  getFrontendAdminMapping: bindActionCreators(getFrontendAdminMapping, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrontPage);
