// Libs
import React, { useState } from "react";

// Components

import NewsContentSelector from "./ui/NewsContentSelector";
import NewsPostsSortingDropdown from "./ui/NewsPostsSortingDropdown";
import TextInput from "../../../../ui/TextInput";
import RadioGroup from "../../../../ui/RadioGroup";
import { useSelector } from "react-redux";

const NewsContent = (props) => {
  const { page, isCreateMode = false } = props;

  const lang = useSelector((state) => state.language.language);
  const [enableOtherNewContent, setEnableOtherNewsContent] = useState(isCreateMode ? false : true);

  function onChangeHandler(e) {
    props.onChange({ target: { name: "meta", value: { ...props.page.meta, [e.target.name]: e.target.value } } });
  }

  function removeSelectedNewsTypeId() {
    let tempMeta = { ...props.page.meta };
    delete tempMeta?.subTypeId;
    props.onChange({ target: { name: "meta", value: tempMeta } });
  }

  function onUseExistingContentHandler() {
    props.onChange({ target: { name: "meta", value: { ...props.page.meta, name: null, subTypeId: null } } });

    if (enableOtherNewContent) {
      removeSelectedNewsTypeId();
      setEnableOtherNewsContent(false);
    } else {
      setEnableOtherNewsContent(true);
    }
  }

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{lang?.newsContent}</p>
        {isCreateMode && (
          <div className="field-wrapper">
            <label>{lang?.doesThereWishToUseExistingNewsContent}</label>
            <RadioGroup
              data-test-id={"page-builder-news-content-radio-group"}
              value={enableOtherNewContent ? 1 : 2}
              onSelect={onUseExistingContentHandler}
              className="show-at-front-page-group"
              selectedValue={enableOtherNewContent ? 1 : 2}
              options={[
                { id: 1, title: lang?.yes, value: true },
                { id: 2, title: lang?.no, value: false },
              ]}
            />
          </div>
        )}

        {!enableOtherNewContent && isCreateMode && (
          <div className="field-wrapper">
            <label>{`${lang?.chooseNameForNewsContent} *`}</label>
            <TextInput name="name" onChange={onChangeHandler} value={page?.meta?.name} />
          </div>
        )}

        {enableOtherNewContent && (
          <div className="field-wrapper">
            <label>{`${lang.choose} ${lang.newsContent?.toLowerCase() ?? ""}`}</label>
            <NewsContentSelector
              data-test-id={"page-builder-news-content-selector"}
              usePlaceholder={true}
              placeholder={lang?.clickToSelect}
              name="subTypeId"
              onChange={onChangeHandler}
              value={page?.meta?.subTypeId}
            />
          </div>
        )}
      </div>

      <div className="field-container">
        <p className="title">{`${lang?.sortOrderOfNewsPosts} *`}</p>
        <div className="field-wrapper">
          <label>{lang?.howShouldNewsPostsSort}</label>
          <NewsPostsSortingDropdown
            data-test-id={"page-builder-news-posts-sorting-dropdown"}
            usePlaceholder={true}
            placeholder={lang?.clickToSelect}
            name="newsPostsSortOrder"
            onChange={onChangeHandler}
            value={page?.meta?.newsPostsSortOrder ?? undefined}
          />
        </div>
      </div>
    </>
  );
};

export default NewsContent;
