// Libs
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import * as queryString from "query-string";
import { format, parse } from "date-fns";

// Utilities
import getUserLocale from "../../../../../utilities/get-user-locale";

// Actions
import { getTimeSheets, resetTimeSheets, updateTableFilters } from "../../../../../actions/timeRegistrationActions";

function DatesFilter({ column }) {
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector((state) => state.timeRegistration.table.filters);
  const { user } = useSelector((state) => state.auth);

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  function onChangeHandler(e) {
    const { setFilter } = column;
    const value = e.target.value.replace(/-/g, "");

    let tempStartDate = startDate;
    let tempEndDate = endDate;

    if (e.target.name === "startDate") {
      // The filter is an array, the from value should be on index 0 and the to value should be on index 1
      setFilter((old = []) => {
        return [value ? value : undefined, old[1]];
      });
      tempStartDate = value;
    }

    if (e.target.name === "endDate") {
      // The filter is an array, the from value should be on index 0 and the to value should be on index 1
      setFilter((old = []) => [old[0], value ? value : undefined]);
      tempEndDate = value;
    }

    dispatch(
      updateTableFilters({
        filters: { startDate: parseInt(tempStartDate), endDate: parseInt(tempEndDate) },
        callback: () => {
          dispatch(getTimeSheets({ timeRegistrationId }));
        },
      })
    );
  }

  function formatDate(date) {
    return format(parse(date, "yyyyMMdd", 0), "yyyy-MM-dd", getUserLocale(user));
  }

  return (
    <div className={componentStyle()}>
      <div className="input-container date-container">
        <label>Vis fra</label>
        <input name="startDate" value={formatDate(startDate)} type="date" onChange={onChangeHandler} />
      </div>
      <div className="input-container  date-container">
        <label>Vis til</label>
        <input name="endDate" value={formatDate(endDate)} type="date" onChange={onChangeHandler} />
      </div>
    </div>
  );
}

const componentStyle = () => css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default DatesFilter;
