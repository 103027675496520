import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Actions
import { addToast } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";

// Components
import TopBar from "../../components/ui/TopBar";
import Page from "../../components/ui/Page";
import ScrollView from "../../components/ui/ScrollView";
import HappyScoreContainer from "./HappyScoreContainer";
import Chart from "./HappyScoreChart";
import { colors as happyScoreColors } from "./config";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Icons
import { SmileyExcitedIcon, SmileyHappyIcon, SmileyNeutralIcon, SmileySadIcon, ThumbsUpIcon } from "mdi-react";

function HappyScore(props) {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { pages } = useSelector((state) => state.pages);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Page setup
  const [page, setPage] = useState({});

  // Value used to determine the employees work mood today
  const [dailySmileValue, setDailySmileValue] = useState(undefined);

  // Used to displaying the charts showing historic work mood
  const [happyScore, setHappyScore] = useState([]);

  // determening whether data has been fetched from the db
  const [loading, setLoading] = useState(true);

  // Showing any error occuring whilst fetching data from db
  const [error, setError] = useState(false);

  useEffect(() => {
    setPage(getPageFromId(pages, props.match.params.pageId));
    hasSubmittedToday();
    getHappyScores();
    // eslint-disable-next-line
  }, []);

  function failureToaster(errorMessage) {
    props.addToast({
      styleType: "error",
      title: lang.somethingWentWrong,
      content: `${errorMessage}`,
      icon: <ThumbsUpIcon />,
      duration: 6000,
    });
  }

  async function submitHappyScore(value) {
    try {
      const { data: submitted } = await req().post("/happyscore/", { value });
      if (submitted) {
        setDailySmileValue(submitted.value);

        // Get data again
        getHappyScores();

        props.addToast({
          styleType: "ok",
          title: lang.thanksForAnswering,
          content: lang.answerReceived,
          icon: <ThumbsUpIcon />,
          duration: 6000,
        });
      }
    } catch (error) {
      failureToaster(`${lang.errorCouldNotRegister}. ${lang.tryAgainOrContactSupport}`);
    }
  }

  async function hasSubmittedToday() {
    try {
      const { data: submitted } = await req()("/happyscore/submitted");
      if (submitted) {
        setDailySmileValue(submitted.value);
      }
    } catch (error) {
      failureToaster(`${lang.couldNotFetchHappyscore}. ${lang.tryAgainOrContactSupport}`);
    }
  }

  async function getHappyScores() {
    try {
      const happyScore = [];
      const { data: day } = await req()("/happyscore/day");
      happyScore.push({
        type: "barChart",
        title: lang.happyscoreToday,
        responses: day.responses > 0 ? day.responses : "",
        chartValues: day.scores,
      });
      const { data: week } = await req()("/happyscore/week");
      happyScore.push({
        type: "lineChart",
        title: lang.happyscoreWeek,
        chartValues: week,
      });
      const { data: year } = await req()("/happyscore/year");
      happyScore.push({
        type: "barChart",
        title: lang.happyscoreYear,
        chartValues: year,
      });
      setHappyScore(happyScore);
      setLoading(false);
    } catch (error) {
      failureToaster(`${couldNotFetchHappyscore}. ${tryAgainOrContactSupport}`);
      setLoading(false);
      setError(true);
    }
  }

  return (
    <Page className={container(primaryColor)}>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <ScrollView className="scroll-view">
        {loading && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}
        {!loading && error && <StatusBox />}
        {!loading && !error && (
          <>
            <HappyScoreContainer
              title={lang.yourWorkMood}
              subtitle={lang.selectTodaysHappyscore}
              message={lang.yourResponseIsAnonymous}
            >
              {dailySmileValue === undefined ? (
                <div className="smileyContainer">
                  <SmileySadIcon color={happyScoreColors.red} className="smiley" onClick={() => submitHappyScore(1)} />
                  <SmileyNeutralIcon
                    color={happyScoreColors.redLight}
                    className="smiley"
                    onClick={() => submitHappyScore(2)}
                  />
                  <SmileyHappyIcon
                    color={happyScoreColors.greenLight}
                    className="smiley"
                    onClick={() => submitHappyScore(3)}
                  />
                  <SmileyExcitedIcon color={happyScoreColors.green} className="smiley" onClick={() => submitHappyScore(4)} />
                </div>
              ) : (
                <div className="smileyContainer">
                  <SmileySadIcon
                    color={happyScoreColors.red}
                    className="smiley"
                    style={{ opacity: dailySmileValue !== 1 && "0.3" }}
                  />
                  <SmileyNeutralIcon
                    color={happyScoreColors.red}
                    className="smiley"
                    style={{ opacity: dailySmileValue !== 2 && "0.3" }}
                  />
                  <SmileyHappyIcon
                    color={happyScoreColors.greenLight}
                    className="smiley"
                    style={{ opacity: dailySmileValue !== 3 && "0.3" }}
                  />
                  <SmileyExcitedIcon
                    color={happyScoreColors.green}
                    className="smiley"
                    style={{ opacity: dailySmileValue !== 4 && "0.3" }}
                  />
                </div>
              )}
            </HappyScoreContainer>
            {happyScore.map((obj, index) => (
              <HappyScoreContainer key={index} title={obj.title} subtitle={obj.subtitle} responses={obj.responses}>
                {obj.chartValues && <Chart type={obj.type} values={obj.chartValues} />}
              </HappyScoreContainer>
            ))}
          </>
        )}
      </ScrollView>
    </Page>
  );
}

const container = (primaryColor) => css`
  .spinner-container {
    margin: 5rem auto;
  }

  .scroll-view {
    width: 100%;
  }

  .smileyContainer {
    margin-top: 2rem;
  }

  .smiley {
    cursor: pointer;
    height: 4rem;
    width: 25%;
    display: inline-block;
    text-align: center;
    color: ${primaryColor};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(HappyScore);
