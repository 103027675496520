import React, { useCallback } from "react";
import SearchPageBreadcrumb from "../SeachPageBreadcrumb";
import { ChevronRightIcon } from "mdi-react";
import pageNavigatorV2 from "../../../../utilities/page-navigator-v2";
import getUserLocale from "../../../../utilities/get-user-locale";
import pageTransitions from "../../../../config/page-transitions";
import { useSelector } from "react-redux";
import { getExcerptAndHighlightMatch } from "../utils/getExcerptAndHightlightMatch";
import { format, parse } from "date-fns";
import getLinkPathFromPageObject from "../../../../utilities/get-link-path-from-page-object";

const getURL = (result) => {
  return `${getLinkPathFromPageObject(result.page, "absolute", { suffix: result.data.id })}`;
};

export const SearchResultsRegistrationPost = ({ result }) => {
  const { data, page } = result;
  const content = result.data.content;

  const user = useSelector((s) => s.auth.user);
  const { searchTerm } = useSelector((s) => s.searchPage);

  const getPostDateSuffix = useCallback(
    (data) => {
      const { date: rawDate, user: postUser } = data.meta;

      const date = format(parse(rawDate.substring(0, 12), "yyyyMMddHHmm", 0), "do MMMM yyyy HH:mm", getUserLocale(user));

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ChevronRightIcon className="page-seperator" />
          <div className={`page-indicator`}>
            {postUser.name ? <p>{`Besvarelse af ${postUser.name}`}</p> : <p>{`Besvarelse fra ${date}`}</p>}
          </div>
        </div>
      );
    },
    [user]
  );

  return (
    <div
      className={`list-element`}
      key={`div__registration__post__${result.data.id}`}
      onClick={() => pageNavigatorV2({ path: getURL(result), direction: pageTransitions.forward })}
    >
      <div style={{ display: "flex", marginBottom: "0.35rem" }}>
        <SearchPageBreadcrumb
          key={`breadcrumb__registration-post__${result.data.id}`}
          page={page}
          suffix={getPostDateSuffix(data)}
        />
      </div>

      <p
        className="title"
        dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content: result.data.title, searchTerm })}
      />

      <div className="content" dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content, searchTerm })} />

      <ChevronRightIcon className="navigation-arrow-indicator" />
    </div>
  );
};

export default SearchResultsRegistrationPost;
