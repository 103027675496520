// Libs
import React, { createRef, useEffect } from "react";
import { css } from "emotion";
import { lock, unlock } from "tua-body-scroll-lock";

const ScrollView = (props) => {
  const targetRef = createRef();

  function onScroll(e) {
    if (
      typeof props.onScrollEnd === "function" &&
      e.target.scrollTop + e.target.getBoundingClientRect().height + (200 || props.scrollThreshold) > e.target.scrollHeight
    ) {
      props.onScrollEnd();
    }

    if (typeof props.onScroll === "function") {
      // For legacy implementations where the scrollView's scroll is handled in parent component
      props.onScroll(e);
    }
  }

  useEffect(() => {
    let localRef = null;
    if (targetRef.current) localRef = targetRef.current;

    lock(localRef);

    return () => {
      unlock(localRef);
    };
  }, []);

  return (
    <div
      id={props.id}
      ref={targetRef}
      className={`scroll-view ${componentStyle(props)} ${props.className || ""} ${props.maxWidth ? "use-max-width" : ""}`}
      style={props.style}
      onScroll={onScroll}
      data-test-id={props["data-test-id"]}
    >
      {props.children}
    </div>
  );
};

const componentStyle = (props) => css`
  flex: 100% 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* height: 100%; // Added as ScrollViews otherwise doesn't seem to be working inside TabViews with useScrollView={false} */

  &.use-max-width {
    max-width: ${props.maxWidth}px;
    margin: 0 auto;
  }
`;

export default ScrollView;
