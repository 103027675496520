// Libs
import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { useSelector } from "react-redux";

// Components
import { MailOutlineIcon, MessageIcon, PhoneIcon, PhoneClassicIcon, DownloadIcon } from "mdi-react";
import ActionWrapper from "../../ui/ActionWrapper";

// Config
import breakpoints from "../../../config/breakpoints";
import colors from "../../../style/colors";

function getSizeClassName(className = "small") {
  if (className === "large") return "large";
  return className;
}

/**
 * The VCard N Sequence is: Family Name; Given Name; Additional Names; Honorific Prefixes; Honorific Suffixes
 * https://tools.ietf.org/html/rfc6350#section-6.2.2
 * We will ignore the additional names and honorific prefixes and honorific suffixes
 * @param name - The name of the contact eg. "John Unknown Doe"
 * @returns - The name in the VCard N Sequence eg. "Doe;John Unknown;;"
 */
function generateVCardName({ name }) {
  const nameParts = name.split(" ");

  const familyName = nameParts.pop();
  const givenName = nameParts.join(" ");

  return `${familyName};${givenName};;`;
}

function createVCardFileAndTriggerDownload({ email = "", phone = "", alternativephone = "", name = "", image = "" }) {
  let vcfContent = `
BEGIN:VCARD
VERSION:3.0
N;CHARSET=UTF-8:${generateVCardName({ name })}
FN;CHARSET=UTF-8:${name}
TEL;TYPE=WORK,VOICE:${phone && !phone.includes("+") ? "+45" : ""}${phone}
TEL;TYPE=HOME,VOICE:${alternativephone && !alternativephone.includes("+") ? "+45" : ""}${alternativephone}
EMAIL;TYPE=PREF,INTERNET:${email}
PHOTO;VALUE=URL;TYPE=JPG:${image.image}
AGENT:BEGIN:VCARD
  VERSION:3.0
  N;CHARSET=UTF-8:${generateVCardName({ name })}
  FN;CHARSET=UTF-8:${name}
  TEL;TYPE=WORK,VOICE:${phone && !phone.includes("+") ? "+45" : ""}${phone}
  TEL;TYPE=HOME,VOICE:${alternativephone && !alternativephone.includes("+") ? "+45" : ""}${alternativephone}
  EMAIL;TYPE=PREF,INTERNET:${email}
  PHOTO;VALUE=URL;TYPE=JPG:${image.image}
  END:VCARD
END:VCARD`;

  let el = document.createElement("a");
  let fileName = "contact.vcf";
  // data in download file
  let file = new Blob([vcfContent], { type: "text/vcard;charset=utf8" });
  file.name = fileName; // the actual filename
  el.download = fileName; // the download-property on the a tag
  el.href = URL.createObjectURL(file); // Set href on a tag to the file
  el.style.display = "none"; // hides element before appending it to the dom
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

function getStylingClassName(className = "transparent") {
  if (className === "filled") return "filled";
  return className;
}

/**
 * This component is very confusing since it has two data sources that parses un-named spreaded data.
 * This means that props can either be a user object or a contact object.
 *
 * email
 * phone
 * alternativePhone
 * contactPersonEmail
 * contactPersonPhone
 * contactPersonRegularPhone
 *
 */
const ContactMenuOptions = (props) => {
  const {
    email,
    contactPersonEmail,
    phone,
    contactPersonPhone,
    contactPersonRegularPhone,
    alternativePhone,
    showDownloadContactButton = false,
  } = props;
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  // This component has two datasources (contacts and regular user objects) which makes the logic a bit weird
  // ie. `{(contactPersonRegularPhone || alternativePhone) && !(phone || contactPersonPhone) ? null : (`
  // What does this actually mean? If the contact has a normal phone AND the user has an alternativePhone AND
  // the user does not have a phone OR the contact does not have a phone THEN dont render a call button.
  // So i am gonna consolidate these data sources into one so the view doesnt have to worry about there being two and
  // hopefully this makes it a bit more clear
  const viewData = {
    phone: phone || contactPersonPhone,
    alternativePhone: alternativePhone || contactPersonRegularPhone, // regular probably means old-fashioned not-a-mobile phone
    email: email || contactPersonEmail,
  };

  function call(phone, e) {
    e.stopPropagation();
    if (!phone) return;
    window.location.href = `tel:${phone}`;
  }

  function sendMail(email, e) {
    e.stopPropagation();
    if (!email) return;
    window.location.href = `mailto:${email}`;
  }

  function sendSms(phone, e) {
    e.stopPropagation();
    if (!phone) return;
    window.location.href = `sms:${phone}`;
  }

  function downloadContact(e) {
    createVCardFileAndTriggerDownload({
      email: viewData.email,
      phone: viewData.phone,
      alternativephone: viewData.alternativephone,
      name: props.name,
      image: props.profilePicture,
    });
  }

  return (
    <div
      className={`${componentStyle({ primaryColor })} ${props.className || ""} ${getSizeClassName(
        props.size
      )} ${getStylingClassName(props.styling)}`}
      style={props.style && props.style}
    >
      {/* Call primary phone */}
      <ActionWrapper
        className={`wrapper ${viewData.phone ? "enabled" : "disabled"}`}
        onClick={(e) => call(viewData.phone, e)}
      >
        <PhoneIcon className="icon" />
      </ActionWrapper>

      {/* Call secondary phone */}
      {viewData.alternativePhone && (
        <ActionWrapper
          className={`wrapper ${viewData.alternativePhone ? "enabled" : "disabled"}`}
          onClick={(e) => call(viewData.alternativePhone, e)}
        >
          <PhoneClassicIcon className="icon" />
        </ActionWrapper>
      )}

      {/* Send text message */}
      <ActionWrapper
        className={`wrapper ${viewData.phone ? "enabled" : "disabled"}`}
        onClick={(e) => sendSms(viewData.phone, e)}
      >
        <MessageIcon className="icon" />
      </ActionWrapper>

      {/* Email */}
      <ActionWrapper
        className={`wrapper ${viewData.email ? "enabled" : "disabled"}`}
        onClick={(e) => sendMail(viewData.email, e)}
      >
        <MailOutlineIcon className="icon" />
      </ActionWrapper>

      {/* Save contact */}
      {showDownloadContactButton && (
        <ActionWrapper className={"wrapper enabled"} onClick={(e) => downloadContact(e)}>
          <DownloadIcon className="icon" />
        </ActionWrapper>
      )}
    </div>
  );
};

const componentStyle = ({ primaryColor }) => css`
  display: inline-flex;

  .wrapper {
    display: flex;
    align-items: center;
    border-radius: 50%;
  }

  &.large {
    .wrapper {
      margin: 0 0.5rem;
    }

    .icon {
      padding: 0.7rem;
      height: 2.65rem;
      width: 2.65rem;
    }
  }

  &.small {
    .icon {
      margin: 0.5rem;
      height: 1.25rem;
      width: 1.25rem;

      @media screen and (max-width: ${breakpoints.md}px) {
        margin: 0.5rem;
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }

  &.transparent {
    .wrapper {
      &.enabled {
        color: ${primaryColor};
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: var(--lightGrey);
      }
    }
  }

  &.filled {
    .wrapper {
      &.enabled {
        color: var(--white);
        background-color: ${primaryColor};
        cursor: pointer;
        transition: background-color 160ms ease;

        &:hover {
          transition: background-color 90ms ease;
          background-color: ${tinycolor(primaryColor).lighten(10).toString()};
        }
        &:active {
          transition: background-color 90ms ease;
          background-color: ${tinycolor(primaryColor).darken(10).toString()};
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: var(--white);
        background-color: var(--lightGrey);
      }
    }
  }
`;

export default ContactMenuOptions;
