// Libs
import React, { useState } from "react";
import { css } from "emotion";

// Styles
import colors from "../../../style/colors";

// Components
import FilterSection from "./FilterSection";
import Table from "./Table";

const TableOverview = () => {
  const [filters, setFilters] = useState({});

  return (
    <div className={componentStyle()}>
      <FilterSection onChange={(filters) => setFilters(filters)} />
      <Table className="table" filters={filters} />
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  background-color: var(--lightGrey);
  position: relative;
  overflow: hidden;

  .table {
    margin-top: 1rem;
  }
`;

export default TableOverview;
