// Libraries
import { useEffect, useState } from "react";
import Dialog from "./Dialog";

// Utilities
import req from "../../../utilities/request-utility";

/**
 * DialogContentFeedback will be used to return data from the content component.
 * This way the content can handle inputs and other domain specific logic, which the dialog
 * can react to. For example, there may be a requirement that the user has scrolled to the bottom
 * of the content before the dialog actions are enabled.
 * @typedef { Object } DialogContentFeedback
 * @param { boolean } satisfied - Whether the content feedback is satisfied or not
 * @param { any } data - Any data that the content component wants to return to the dialog, sent to the backend when an action is triggered.
 */

/**
 * StartupDialogs will be used to display dialogs that are required to be shown on startup.
 * @returns {JSX.Element}
 * @constructor
 */
const StartupDialogs = ({}) => {
  const [dialogs, setDialogs] = useState([]);
  const [dialog, setDialog] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    req()("/dialogs/startup").then((response) => {
      setDialogs(response.data);
    });
  }, []);

  // The useEffects are kind of odd.
  // On enter, I need to render the dialog before starting the animation.
  // On exit, I need to wait for the animation to finish before removing the dialog.
  useEffect(() => {
    if (dialogs.length === 0) {
      setActive(false);
    } else {
      setDialog(dialogs[0]);
    }
  }, [dialogs]);

  // Separate useEffects because when entering, I need to wait until the dialog is set before setting active to true.
  useEffect(() => {
    if (dialog) {
      setActive(true);
    }
  }, [dialog]);

  function onSubmitAction() {
    setDialogs(dialogs.slice(1));
  }

  return <Dialog dialog={dialog} active={active} onSubmitAction={onSubmitAction} />;
};
export default StartupDialogs;
