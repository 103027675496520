// Libraries
import { useEffect, useRef, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import DynamicIcon from "../../../Icons/DynamicIcon";
import tinycolor from "tinycolor2";

// Utilities
import markdownParser from "../../../../../utilities/markdown-parser";

// Styling
import { durations } from "../../../../../config/animations";
import smoothScrollToBottom from "../../../../../utilities/smooth-scroll-to-bottom";

/**
 * @param { string } text
 * @param { Function } onFeedback
 * @param { string } className
 * @param { any } ...rest
 * @constructor
 */
const ScrollRequiredMarkdown = ({ text, onFeedback, className, ...rest }) => {
  const divRef = useRef(null);

  const [scrollSatisfied, setScrollSatisfied] = useState(false);
  const [bottomRemaining, setBottomRemaining] = useState(true);
  const [markdown, setMarkdown] = useState(null);

  // While smooth scrolling to bottom, prevent showing/hiding scroll button based on scroll position
  const [forceHideScrollButton, setForceHideScrollButton] = useState(false);

  const { primaryColor } = useSelector((state) => state.appConfig);

  useEffect(() => {
    onFeedback({ satisfied: scrollSatisfied, data: null });
  }, [scrollSatisfied]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef.current]);

  useEffect(() => {
    setMarkdown(markdownParser(text));
  }, [text]);

  useEffect(() => {
    // Scroll to top when markdown is changed, in case another dialog is opened
    if (divRef.current) {
      divRef.current.scrollTop = 0;
      setScrollSatisfied(false);

      handleScroll();
    }
  }, [markdown]);

  const handleScroll = () => {
    if (!markdown) return;

    /** @type HTMLDivElement */
    const element = divRef.current;

    if (element.scrollHeight - element.scrollTop > element.clientHeight) {
      setBottomRemaining(true);
    }

    // Round is needed because somehow the scrollHeight is not an integer, and if you're 0.5px away from the bottom,
    // you can't scroll down anymore.
    if (Math.round(element.scrollHeight - element.scrollTop) <= element.clientHeight + 10) {
      setBottomRemaining(false);
      setForceHideScrollButton(false);
      setScrollSatisfied(true);
    }
  };

  const handleScrollButtonClick = () => {
    if (divRef.current) {
      setScrollSatisfied(true);

      // The button should hide immediately
      // If I just used setButtomRemaining(false), then during the handleScroll() call, the button would show again
      setForceHideScrollButton(true);

      smoothScrollToBottom(divRef.current, 300)
    }
  };

  return (
    <div className={`${className} ${componentStyle({ primaryColor })}`}>
      <div className={"text-container"}>
        <div ref={divRef} className={"text"} dangerouslySetInnerHTML={markdown}></div>
      </div>
      {bottomRemaining && <div className={"gradient"}></div>}
      <CSSTransition
        in={bottomRemaining && !forceHideScrollButton}
        timeout={500}
        classNames={"scroll-button"}
        mountOnEnter
        unmountOnExit
      >
        <button className={"scroll-button"} onClick={handleScrollButtonClick}>
          <DynamicIcon icon={"mdi:arrow-down"} />
        </button>
      </CSSTransition>
    </div>
  );
};

const componentStyle = ({ primaryColor }) => css`
  position: relative;

  .text-container {
    height: 100%;
  }

  .text {
    height: 100%;
    overflow-y: scroll;
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 30px;

    bottom: 0;

    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

    pointer-events: none;
  }

  .scroll-button {
    position: absolute;
    bottom: 10px;
    left: 50%;

    transform: translateX(-50%);

    width: 48px;
    height: 48px;

    border-radius: 50%;
    border: none;

    background: ${primaryColor};
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    &.scroll-button-enter {
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
    }

    &.scroll-button-enter-active {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      transition: transform ${durations.fast}ms cubic-bezier(0.64, 0.57, 0.67, 1.53), opacity ${durations.fast}ms;
    }

    &.scroll-button-exit {
      opacity: 1;
    }

    &.scroll-button-exit-active {
      opacity: 0;
      background: ${tinycolor(primaryColor).darken(10).toString()};
      transform: translateX(-50%) translateY(10px);
      transition: transform ${durations.fast}ms ease, opacity ${durations.fast}ms;
    }

    &:hover {
      cursor: pointer;
      background: ${tinycolor(primaryColor).brighten(10).toRgbString()};
    }
  }
`;

export default ScrollRequiredMarkdown;
