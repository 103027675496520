// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";

// Components
import ImageUploadSingle from "../ImageUploadSingle";
import breakpoints from "../../../config/breakpoints";
import TextInput from "../TextInput";
import Button from "../Button";
import { AlertDecagramIcon, ErrorOutlineIcon } from "mdi-react";
import UserDisplayToggleSettings from "./components/UserDisplayToggleSettings";
import UserDisplayListItemExample from "./components/UserDisplayListItemExample";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast, hideModalPage, showDialog } from "../../../actions/uiActions";
import { getAppConfig } from "../../../actions/appConfigActions";
import ColorPicker from "../ColorPicker";
import Toggle from "../Toggle";
import DropDownList from "../dropDown/DropDownList";

const ManageApplication = (props) => {
  const dispatch = useDispatch();

  // Redux state
  const {
    frontPageImage,
    icon,
    customerLogo,
    primaryColor,
    title,
    appId,
    showMasterGroup,
    showUserGroup,
    showJobTitle,
    hideEmployeeId,
    anniversariesFilterType,
    disableFrontpageImageDarkening,
    birthdaysAgeSetting,
  } = useSelector((state) => state.appConfig);
  const { language: lang } = useSelector((state) => state.language);

  // Local state
  const [appConfig, setAppConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [notValid, setNotValid] = useState(true);

  // Refs

  useEffect(() => {
    setAppConfig({
      frontPageImage,
      appIcon: icon,
      customerLogo,
      primaryColor,
      name: title,
      showMasterGroup,
      showUserGroup,
      showJobTitle,
      hideEmployeeId,
      anniversariesFilterType,
      birthdaysAgeSetting,
      disableFrontpageImageDarkening,
    });
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  // Validate that all inputs have been filled
  useEffect(() => {
    if (Object.keys(appConfig).length > 0) {
      if (
        Object.keys(appConfig.frontPageImage).length === 0 ||
        Object.keys(appConfig.appIcon).length === 0 ||
        Object.keys(appConfig.customerLogo).length === 0 ||
        appConfig.primaryColor.length === 0 ||
        appConfig.name.length === 0
      ) {
        return setNotValid(true);
      }
      setNotValid(false);
    }
    // eslint-disable-next-line
  }, [appConfig]);

  const update = () => {
    dispatch(
      showDialog({
        styleType: "warning",
        icon: <AlertDecagramIcon />,
        title: lang.changeAppSettings,
        content: lang.aboutToChangeAppSettings,
        primaryActionTitle: lang.yesSaveChanges,
        secondaryActionTitle: lang.noNotFinishedYet,
        primaryAction: () => {
          req()
            .patch(`apps/${appId}`, appConfig)
            .then(() => {
              dispatch(getAppConfig());
              dispatch(hideModalPage());
            })
            .catch((err) => {
              dispatch(
                addToast({
                  title: lang.errorGeneral,
                  content: lang.errorCouldNotRegister,
                  icon: <ErrorOutlineIcon />,
                  styleType: "error",
                  duration: 20000,
                })
              );
            });
        },
      })
    );
  };

  const onConfigChange = (key, value) => {
    setAppConfig({ ...appConfig, [key]: value });
  };

  return (
    <div
      className={`${componentStyle(props, appConfig.primaryColor)} ${props.className || ""}`}
      style={props.style && props.style}
    >
      {!loading && (
        <div className="wrapper">
          {/** Application Name **/}
          <p className="title">{lang.appName}</p>
          <p className="hint">{lang.appNameExplanation}</p>
          <TextInput
            style={{ marginBottom: "2rem" }}
            defaultValue={appConfig.name}
            onChange={(e) => setAppConfig({ ...appConfig, name: e.target.value })}
          />

          {/** Primary Color **/}
          <p className="title">{lang.primaryColor}</p>
          <p className="hint">{lang.primaryColorExplanation}</p>
          <ColorPicker
            style={{ marginBottom: "2rem" }}
            value={appConfig.primaryColor}
            onChange={(e) => setAppConfig({ ...appConfig, primaryColor: e.target.value })}
          />

          {/** Front Page Image **/}
          <p className="title" style={{ marginTop: "1rem" }}>
            {lang.frontPageImage}
          </p>
          <p className="hint">{lang.frontPageImageExplanation}</p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.frontPageImage.image ? "image-container front-page" : "image-container"}
            imagePreviewClassName={!appConfig.disableFrontpageImageDarkening ? "darken" : ""}
            uploadedFile={appConfig.frontPageImage}
            removeFile={() => setAppConfig({ ...appConfig, frontPageImage: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                frontPageImage: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/* Image darkening */}
          <div className="usergroup-settings">
            <p className="title" style={{ marginTop: "1rem" }}>
              {lang.darkenFrontPageImage}
            </p>
            <p className="hint">{lang.darkenFrontPageImageExplanation}</p>
            <div className="settings-container">
              <div className="setting-row">
                <p>{lang.darkenFrontPageImage}</p>
                <Toggle
                  on={!appConfig.disableFrontpageImageDarkening}
                  onClick={() =>
                    setAppConfig({
                      ...appConfig,
                      disableFrontpageImageDarkening: !appConfig.disableFrontpageImageDarkening,
                    })
                  }
                />
              </div>
            </div>
          </div>

          {/** App DynamicIcon Image **/}
          <p className="title" style={{ marginTop: "2rem" }}>
            {lang.appIcon}
          </p>
          <p className="hint">{lang.appIconExplanation}</p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.appIcon.image ? "image-container icon app" : "image-container "}
            uploadedFile={appConfig.appIcon}
            removeFile={() => setAppConfig({ ...appConfig, appIcon: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                appIcon: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/** Customer Image **/}
          <p className="title">{lang.firmLogo}</p>
          <p className="hint">{lang.firmLogoExplanation}</p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.customerLogo.image ? "image-container icon customer" : "image-container"}
            uploadedFile={appConfig.customerLogo}
            removeFile={() => setAppConfig({ ...appConfig, customerLogo: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                customerLogo: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/* User Display */}
          <div className="usergroup-settings">
            <p className="title">{lang.userDisplay}</p>
            <p className="hint">{lang.userDisplayExplanation}</p>
            {/** User Display Toggle Settings **/}
            <UserDisplayToggleSettings lang={lang} appConfig={appConfig} onConfigChange={onConfigChange} />

            {/** User Display List Item Example **/}
            <UserDisplayListItemExample lang={lang} appConfig={appConfig} />
          </div>

          <div>
            <p className="title">{lang.filterAnniversaries}</p>
            <p className="hint">{lang.filterAnniversariesExplanation}</p>
            <DropDownList
              style={{ marginBottom: "1.5rem" }}
              dropDownListContent={[
                { id: "ALL", title: lang.showAll },
                { id: "FIFTH", title: lang.showEveryFifthYear },
                { id: "ROUND", title: lang.showEveryTenthYear },
              ]}
              selectedContentId={appConfig.anniversariesFilterType}
              value={appConfig.anniversariesFilterType}
              name="anniversariesFilterType"
              onChange={(e) => {
                setAppConfig({ ...appConfig, anniversariesFilterType: e.target.value });
              }}
            />
          </div>

          <div>
            <p className="title">{lang.birthdays}</p>
            <p className="hint">{lang.filterBirthdaysExplanation}</p>
            <DropDownList
              dropDownListContent={[
                { id: "all", title: lang.showAll },
                { id: "round", title: lang.roundBirthdays },
                { id: "none", title: lang.dontShowAge },
              ]}
              selectedContentId={appConfig.birthdaysAgeSetting}
              value={appConfig.birthdaysAgeSetting}
              name="birthdayAgeFilter"
              onChange={(e) => {
                setAppConfig({ ...appConfig, birthdaysAgeSetting: e.target.value });
              }}
            />
          </div>

          {/** Save Changes **/}
          <Button disabled={notValid} onClick={() => update()} style={{ margin: "3rem 0 0.6rem 0" }}>
            {lang.saveChanges}
          </Button>
          <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
            {lang.cancel}
          </Button>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  background-color: var(--white);
  display: flex;
  justify-content: center;

  .darken {
    filter: brightness(0.5);
    transition: filter 250ms ease;
  }

  .wrapper {
    width: 100%;
    max-width: ${breakpoints.md}px;
    background-color: var(--white);
    padding: 1rem 0.65rem 4rem 0.65rem;

    p.title {
      margin-bottom: 0.25rem;
    }
    p.hint {
      margin-bottom: 0.75rem;
      font-size: 0.9rem;
      color: var(--darkGrey);
    }

    .image-container {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.icon {
        padding: 2rem 3rem;

        &.app {
          img {
            width: 25%;
          }
        }

        &.customer {
          img {
            width: 75%;
          }
        }
      }
    }

    .usergroup-settings {
      margin-bottom: 2rem;
      .settings-container {
        border: 1px solid var(--midGrey);
        border-radius: 3px;
        border-bottom: none;

        .setting-row {
          border-bottom: 1px solid var(--midGrey);
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
        }
      }
    }
  }
`;

export default ManageApplication;
