// Libs
import React, { useEffect, useState } from "react";
import * as queryString from "query-string";
import { css } from "emotion";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// Components
import ScrollView from "../ui/ScrollView";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ListItem from "../ui/ListItem";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

// Utilities
import req from "../../utilities/request-utility";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

// Config
import breakpoints from "../../config/breakpoints";

// Colors
import colors from "../../style/colors";

const TimeRegistrationExportHistory = (props) => {
  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  const page = useCurrentPage({ pageId: props.match.params.pageId });

  const [exportedFiles, setExportedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function getExports() {
    try {
      setLoading(true);
      const { data: fetchedExportedFiles } = await req()(
        `admin/time-registrations/${timeRegistrationId}/time-sheets/exports`
      );
      setExportedFiles(fetchedExportedFiles);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getExports();
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView className={componentStyle()}>
        <div className="container-wrapper">
          {loading && !error && <Spinner className="spinner" />}
          {!loading && error && <StatusBox className="error-box" />}
          {!loading &&
            !error &&
            exportedFiles.map((file) => (
              <ListItem
                className="exported-file"
                clickable={true}
                onClick={() => window.open(file.fileURL)}
                title={dayjs(String(file.createdAt), "YYYYMMDDHHmmss").utc().format("YYYY-MM-DD HH:mm:ss")}
                subTitle={file.user.name}
              />
            ))}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  justify-content: center;

  .spinner,
  .error-box {
    margin-top: 2rem;
  }

  .container-wrapper {
    width: 100%;
    max-width: ${breakpoints.lg}px;

    .exported-file {
      &:first-of-type {
        margin-top: 1rem;
      }

      @media screen and (min-width: ${breakpoints.lg}px) {
        border-left: 1px solid var(--midGrey);
        border-right: 1px solid var(--midGrey);
      }
    }
  }
`;

export default TimeRegistrationExportHistory;
