import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";

/**
 * @param { Object } [groups]
 * @param { string } groups.label
 * @param { Object[] } groups.objects
 * @param { function(grouped: Object[]):JSX.Element } groupedItemsRenderFunction
 * @param { string } [className]
 */
const GroupedDataList = ({ groups, className = "", groupedItemsRenderFunction }) => {
  return (
    <div className={`${componentStyle()} ${className}`}>
      {groups.map((group) => (
        <React.Fragment key={group.label}>
          <p className="label">{group.label}</p>
          <div className="list-container">{group.objects.map((object) => groupedItemsRenderFunction(object))}</div>
        </React.Fragment>
      ))}
    </div>
  );
};

const componentStyle = () => css`
  .label {
    margin: 0 10px;
    font-size: 1rem;
    color: var(--darkGrey);
  }

  .list-container {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

export default GroupedDataList;
