export default function getNotValidRegistrationQuestionIds(registration) {
  let notValidQuestionIds = [];

  registration.categories.map((c) =>
    c.questions
      .filter(
        (question) =>
          question.isRequired === 1 &&
          (question.answer === null || question.answer === "" || (question.answer && question.answer.value === null))
      )
      .map((question) => notValidQuestionIds.push(question.id))
  );
  return notValidQuestionIds;
}
