import TinyAnimate from "TinyAnimate";
/** Smoothly scrools to top of given element in specified time
 * @param {Object} anon
 * @param {HTMLElement} anon.element required
 * @param {Number} anon.duration optional - default to 300
 * @param {Number} anon.scrollTo A pixel value to scroll to
 *
 */
export default function ({ element, duration = 300, scrollTo }) {
  //  Fail silently if element is missing...
  if (!element) return;

  let currentScroll = element.scrollTop;

  TinyAnimate.animate(
    currentScroll,
    scrollTo,
    duration,
    (x) => {
      element.scrollTop = x;
    },
    "easeInOutQuart"
  );
}
