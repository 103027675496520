// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

// Actions
import { getContactGroupNames } from "../../../actions/contactsActions";

// Components
import ScrollView from "../../ui/ScrollView";
import ListItem from "../../ui/ListItem";
import { ContactsListItemSkeletonGroup } from "./skeleton/ContactsListItemSkeleton";
import { ChevronRightIcon } from "mdi-react";

// Config
import groupTypes from "../config/groupTypes";
import contactsTypes from "../config/contactsTypes";
import breakpoints from "../../../config/breakpoints";
import contactTypes from "../config/contactsTypes";
import StatusBox from "../../ui/StatusBox";

/**
 * @param {Object} props
 * @param {Object} props.mainPage - Provides the config and id and the mainPage.
 * The mainPage.config helps determine what information should be visible of users (ListItem-level)
 * The mainPage.id helps hiding information about the users before they reach the app (API-level)
 * @param {Function} props.navigateToContactsGroup - Callback function that will navigate to an overview of the selected groups contacts
 * @param {String} props.contactType - Determines what kind of contacts should be fetched -> (phoneBook or keyPersons)
 * @param {String, Integer} props.groupId - Determines from what group should contacts be fetched
 * @param {String} props.groupType - What are the groupType -> all, masterGroup, useGroup, groups (KeyPersons).
 * This also determines the layout:
 *  all -> all contacts
 *  masterGroup, userGroup and group -> group titles
 * @param {boolean} props.tabBarIsIncluded - Helps position the searchBar height by the tabBars position
 */

export function ContactsGroupList({
  navigateToContactsGroup = null,
  contactType = contactsTypes.phoneBook,
  groupBy = groupTypes.all,
}) {
  const dispatch = useDispatch();

  const { groupNames: groups, loading, error } = useSelector((state) => state.contact[contactType]);
  const { activeTab, filters } = useSelector((state) => state.contact);
  const { language: lang } = useSelector((state) => state.language);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (contactType === contactTypes.keyPerson || filters.selectedAppId === null) return;

    getGroups();
  }, [filters.selectedAppId]);

  function getGroups() {
    dispatch(getContactGroupNames({ contactType: contactsTypes[contactType], groupBy }));
  }

  return (
    <ScrollView className={groupStyle()}>
      {groups.length > 0 && (
        <ListItem
          maxWidth={breakpoints.md}
          clickable={true}
          onClick={navigateToContactsGroup ? () => navigateToContactsGroup({ contactType }) : null}
          title={lang.showAll}
          iconRight={<ChevronRightIcon />}
        />
      )}
      {groups.map((group) => (
        <ListItem
          maxWidth={breakpoints.md}
          clickable={true}
          onClick={navigateToContactsGroup ? () => navigateToContactsGroup({ group, contactType }) : null}
          title={group.title}
          iconRight={<ChevronRightIcon />}
          key={group.id}
        />
      ))}

      {((groups.length === 0 && !loading) || error) && <StatusBox />}
      {loading && groups.length === 0 && <ContactsListItemSkeletonGroup />}
    </ScrollView>
  );
}

const groupStyle = () => css`
  height: 100%;
  padding-top: 1rem;
`;
