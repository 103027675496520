import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from "uuid";
import { InfoOutlineIcon, FeatureSearchOutlineIcon, PencilIcon } from "mdi-react";
import AnimateHeight from "react-animate-height";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import { feedTypes } from "./config";
import getPageFromId from "../../utilities/get-page-from-id";
import pageConfig from "../../config/pageConfig";
import { durations } from "../../config/animations";
import breakpoints from "../../config/breakpoints";
import getGuestimateTitleFromFileName from "../../utilities/get-guestimate-title-from-filename";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import FileListItem, { statuses as fileListItemStatuses } from "./components/FileListItem";
import ActionWrapper from "../ui/ActionWrapper";
import DragAndDropArea from "../ui/dragAndDropArea/DragAndDropArea";
import useLang from "../../hooks/useLang";
import ButtonRounded from "../ui/ButtonRounded";

function NewsFileArchive(props) {
  // page setup
  const admin = useSelector((state) => state.auth.user.admin);
  const { match, getPosts, resetFeeds } = props;
  const { pages } = useSelector((state) => state.pages);
  const lang = useLang();
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [searchterm, setSearchterm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const sort = (page.config && page.config[pageConfig.NEWS_POSTS_SORT_ORDER]) || null;
  const [addedFiles, setAddedFiles] = useState([]);
  const [uploadAllTrigger, setUploadAllTrigger] = useState(0);

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  const [enableFileManagementMode, setEnableFileManagementMode] = useState(false);

  useEffect(() => {
    if ((!searchterm && posts.length > 0) || enableFileManagementMode) {
      setFilteredPosts(posts);
    } else {
      setFilteredPosts(
        posts.filter((p) => {
          if (p.title.toLowerCase().indexOf(searchterm.toLowerCase()) !== -1) return true;
          return false;
        })
      );
    }
  }, [searchterm, posts, enableFileManagementMode]);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, sort, feedType: feedTypes.all, limit: 10000 });
    // eslint-disable-next-line
  }, []);

  function handleFileDrop(file) {
    // Put file into a usable "format"
    let fileObject = {
      clientSideId: uuidv4(),
      file,
      title: getGuestimateTitleFromFileName(file.name),
    };

    setAddedFiles((addedFiles) => [fileObject, ...addedFiles]);
  }

  function removeFile(clientSideId) {
    setAddedFiles((addedFiles) => addedFiles.filter((f) => f.clientSideId !== clientSideId));
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          admin && (
            <ActionWrapper onClick={() => setEnableFileManagementMode(!enableFileManagementMode)}>
              <PencilIcon />
            </ActionWrapper>
          )
        }
      />

      <AnimateHeight height={!enableFileManagementMode ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
        <FloatingSearchInput
          placeholder={lang.typeToFilter}
          onClearSearch={() => setSearchterm("")}
          onChange={(e) => setSearchterm(e.target.value)}
          value={searchterm}
          maxWidth={breakpoints.lg}
          style={{ zIndex: 1 }}
        />
      </AnimateHeight>

      <ScrollView
        style={{
          paddingTop: enableFileManagementMode ? "0.65rem" : "4.45rem",
          paddingBottom: "4rem",
          transition: `padding ${durations.normal}ms ease`,
        }}
      >
        <AnimateHeight height={enableFileManagementMode ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
          <DragAndDropArea
            onDrop={handleFileDrop}
            style={{ width: "95%", maxWidth: `${breakpoints.lg}px`, margin: "0rem auto 0.65rem auto" }}
          />
        </AnimateHeight>

        {/* If there are more than 2 files ready to be published show the publish all button */}
        <AnimateHeight
          height={enableFileManagementMode && addedFiles.length > 1 ? "auto" : 0}
          duration={durations.normal}
          animateOpacity={true}
        >
          <div style={{ display: "flex", justifyContent: "center", margin: "0 0 0.65rem 0" }}>
            <ButtonRounded onClick={() => setUploadAllTrigger((state) => state + 1)}>
              {lang.publish} {lang.all}
            </ButtonRounded>
          </div>
        </AnimateHeight>

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && addedFiles.length === 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {filteredPosts.length === 0 && posts.length > 0 && searchterm && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
            style={{ margin: "1rem auto" }}
          />
        )}

        {addedFiles.map((file) => (
          <FileListItem
            key={file.clientSideId}
            file={file}
            removeFile={removeFile}
            subTypeId={page.dataId}
            initialStatus={fileListItemStatuses.afterUpload}
            enableFileManagementMode={enableFileManagementMode}
            uploadAllTrigger={uploadAllTrigger}
          />
        ))}

        {/* Content */}
        {filteredPosts
          .filter((p) => p.files && p.files.length > 0)
          .map((post) => (
            <FileListItem
              post={post}
              key={post.id}
              searchterm={searchterm}
              subTypeId={page.dataId}
              enableFileManagementMode={enableFileManagementMode}
            />
          ))}
      </ScrollView>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFileArchive);
