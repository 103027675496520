// Libs
import React from "react";
import { css } from "emotion";

// Styles
import {
  CalendarBlankIcon,
  HourglassEmptyIcon,
  ImageIcon,
  AccountMultipleIcon,
  ChevronRightIcon,
  AccountOffIcon,
  AccountCheckIcon,
} from "mdi-react";

// Utilities
import { parseDate, parseDateAndTime } from "../../utilities/parse-date";
import { useSelector } from "react-redux";
import breakpoints from "../../config/breakpoints";
import Image from "../ui/Image";
import getEventThumbnail from "./utilities/getEventThumbnail";

/**
 *
 * Element used to display events as part of a list - use case could be an overview of upcoming events, where each
 * EventListItem is a row with the basic information about an event
 *
 * @param {Object} props
 * @param {Object} props.style
 * @param {Object} props.scrollTopRed
 * @param {Object} props.maxWidth
 * @param {Object} props.onClick
 * @param {Object} props.dataTestId
 *
 * @param {Object} props.event
 * @param {Object} props.event.imageLeft -> event image
 * @param {Object} props.event.title -> event title
 * @param {Object} props.event.iAmAttending -> am I attending the event
 * @param {Object} props.event.startDate -> start date for the event
 * @param {Object} props.event.endDate -> end date for the event
 * @param {Object} props.event.deadlineDate -> deadline date for the evemt
 * @param {Object} props.event.attendees -> the users that are attending the event
 *
 */

const minScreenWidth = 320;

const EventListItem = (props) => {
  // Props
  const {
    event,
    style,
    scrollTopRef,
    maxWidth,
    className,
    onClick,
    dataTestId,
    rightIcon = <ChevronRightIcon className="arrow-icon" />,
    hideAttendingIndicator = false,
  } = props;

  // Language
  const { language: lang } = useSelector((state) => state.language);

  const imageLeft = getEventThumbnail(event);

  // Destructure props.event and manipulate date-elements
  const {
    title,
    isAttending,
    startDate = parseDateAndTime(event.eventStartDate, event.eventStartTime),
    endDate = parseDateAndTime(event.eventEndDate, event.eventEndTime),
    deadlineDate,
    numberOfAttendees,
    hideAttendanceListInApp,
  } = event;

  return (
    <div
      style={style}
      ref={scrollTopRef}
      className={
        componentStyle(props) +
        `${maxWidth ? " enable-max-width" : ""} ${className || ""} ${
          !hideAttendingIndicator ? (isAttending !== null && isAttending === true ? "attending" : "not-attending") : ""
        }`
      }
      onClick={onClick}
      data-test-id={dataTestId}
    >
      <div className="event-content-container">
        {/* Title of event */}
        <p className="title">{title}</p>

        <div className="lower-container">
          {/** Image **/}

          {/** No image  **/}
          {!imageLeft && (
            <div className="event-image no-image">
              <ImageIcon />
            </div>
          )}
          {/** Image appended **/}
          {imageLeft && <Image className="event-image image-appended" src={imageLeft} />}

          <div className="info-box">
            {/* Start date */}
            <div className="info-row">
              <CalendarBlankIcon />
              <div className="date-container">
                <p>{startDate}</p>
                <p>{(endDate && ` - ${endDate}`) || ""}</p>
              </div>
            </div>

            {/* Deadline date */}
            {isAttending == null && (
              <div className="info-row">
                <HourglassEmptyIcon />
                <p>
                  {lang.attendBefore__placeholder__.replace(
                    "{{placeholder}}",
                    parseDate(deadlineDate) || parseDate(startDate)
                  )}
                </p>
              </div>
            )}

            {/* Attending the event icon */}
            {isAttending === false && (
              <div className="not-attending">
                <AccountOffIcon />
                <p>{lang.notAttending}</p>
              </div>
            )}

            {/* Not Attending the event icon */}
            {isAttending === true && (
              <div className="attending">
                <AccountCheckIcon />
                <p>{lang.youAreAttending}</p>
              </div>
            )}

            {/* Number of attendees */}

            <div className="info-row">
              <AccountMultipleIcon />
              {!hideAttendanceListInApp && (
                <p>{`${numberOfAttendees || 0} ${
                  numberOfAttendees === 1 ? lang.attending.toLowerCase() : lang.attendees.toLowerCase()
                }`}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="arrow-container">{rightIcon}</div>
    </div>
  );
};

const componentStyle = (props) => css`
  margin-bottom: -1px;
  background-color: var(--white);
  border-top: 1px solid var(--midGrey);
  border-bottom: 1px solid var(--midGrey);
  display: flex;
  padding: 0.5rem;
  color: var(--black);
  flex-shrink: 0;
  flex-direction: row;

  &.attending {
    border-left: 3px solid var(--greenBright);
  }

  &.not-attending {
    border-left: 3px solid var(--red);
  }

  div.event-content-container {
    display: flex;
    flex-direction: column;
  }

  p.title {
    margin-bottom: 0.5rem;
    white-space: normal;

    @media screen and (min-width: ${breakpoints.md}) {
      font-size: 1.05rem;
    }
  }

  div.arrow-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    svg.arrow-icon {
      fill: var(--midDarkGrey);
    }
  }

  div.lower-container {
    display: flex;

    div.info-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 0.5rem;

      div.info-row,
      div.not-attending,
      div.attending {
        display: flex;
        align-items: center;

        div.date-container {
          display: flex;
        }

        p {
          font-size: 0.8rem;
          color: var(--midDarkGrey);

          @media screen and (max-width: ${minScreenWidth}px) {
            font-size: 0.65rem;
          }

          @media screen and (min-width: ${breakpoints.md}px) {
            font-size: 0.9rem;
          }
        }

        svg {
          height: 0.9rem;
          width: auto;
          fill: var(--midDarkGrey);
          margin-right: 0.25rem;
        }
      }

      div.not-attending {
        p {
          color: var(--red);
          font-weight: bold;
        }

        svg {
          fill: var(--red);
        }
      }

      div.attending {
        p {
          color: var(--greenBright);
          font-weight: bold;
        }

        svg {
          fill: var(--greenBright);
        }
      }
    }

    .event-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      height: 4.5rem;
      width: 4.5rem;

      // For the smallest size phone (iphone 5 etc.)
      @media screen and (max-width: ${minScreenWidth}px) {
        padding: 0.65rem;
      }

      @media screen and (min-width: ${props.maxWidth}px) {
        padding: 1.5rem;
      }

      &.no-image {
        background-color: var(--ultraLightGrey);
        padding: 1rem;

        svg {
          height: 3rem;
          width: auto;
          fill: var(--midGrey);

          @media screen and (min-width: ${props.maxWidth}px) {
            height: 3.5rem;
          }
        }
      }

      &.image-appended {
        padding: 0;
        object-fit: cover;
      }
    }
  }

  &.enable-max-width {
    @media screen and (min-width: ${props.maxWidth}px) {
      max-width: ${props.maxWidth}px;
      margin-left: auto !important;
      margin-right: auto !important;
      border: 1px solid var(--midGrey);
    }

    &:first-of-type {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-of-type {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &:active,
  &:hover {
    ${props.clickable && `background-color: var(--ultraLightGrey);`}
    ${props.clickable && `cursor: pointer;`}
  }
`;

export default EventListItem;
