export const formatCategoryQuestions = (categories) => {
  if (!categories) return [];

  return categories.map((category) => {
    const { title, questions } = category;
    return {
      title,
      options: questions.map((question) => {
        const { id, title } = question;

        return {
          id,
          title,
        };
      }),
    };
  });
};
