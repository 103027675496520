// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import { format } from "date-fns";
import { useDebouncedCallback } from "use-debounce";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ActionWrapper from "../ui/ActionWrapper";
import ScrollView from "../ui/ScrollView";
import {
  DotsVerticalIcon,
  AccountIcon,
  AlertDecagramIcon,
  ClockOutlineIcon,
  DeleteIcon,
  ErrorOutlineIcon,
  CheckIcon,
} from "mdi-react";
import ChecklistTask from "./ChecklistTask";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import Button from "../ui/Button";

// Utilities
import pageNavigator_v2 from "../../utilities/page-navigator-v2";
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";
import { parseDatetime } from "../../utilities/parse-date";

// Actions
import { addToast, showContextMenu, showDialog } from "../../actions/uiActions";

// Styles
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

const Checklist = (props) => {
  // Page for navigation
  const { match } = props;
  const { checklistId } = match.params;

  // Redux state
  const pages = useSelector((state) => state.pages.pages);
  const { language: lang } = useSelector((state) => state.language);
  const { id: userId } = useSelector((state) => state.auth.user);

  // Local state
  const [checklist, setChecklist] = useState({
    title: null,
    checklistCategories: null,
    author: null,
    date: null,
    completedDate: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState("");
  const [notValidArr, setNotValidArr] = useState([]);
  const [validationCheck, setValidationCheck] = useState(false);

  // Dispatcher
  const dispatch = useDispatch();

  // Other
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const registrationId = page.dataId;

  // Get the checklist registration
  useEffect(() => {
    getChecklist();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Validation
    if (checklist.checklistCategories && !loading && notValidArr && validationCheck) {
      // Checks if all checklist items has been answered
      let questionIndex = checklist.checklistCategories
        .map(({ questions }) => {
          return questions
            .filter((question) => question.answer.title === null || question.answer.title === "")
            .map((question) => {
              return question.id;
            });
        })
        .flat();
      setNotValidArr(questionIndex);
    }

    // eslint-disable-next-line
  }, [checklist.checklistCategories]);

  // Debounce function to save to local storage
  const [debounceSaveChecklist] = useDebouncedCallback(async ({ questionIndex, categoryIndex }) => {
    try {
      let checklistAnswer = checklist.checklistCategories[categoryIndex].questions[questionIndex].answer;
      await req().put(
        `checklists/registrations/${registrationId}/checklists/${checklistId}/questions/${questionIndex}/answers/${checklistAnswer.id}`,
        checklistAnswer
      );
      getChecklist();
    } catch (err) {
      dispatch(
        addToast({
          title: lang.somethingWentWrong,
          content: lang.errorCouldNotSaveYourAnswer,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  }, 500);

  const getChecklist = async () => {
    try {
      const { data: checklist } = await req()(`checklists/registrations/${registrationId}/checklists/${checklistId}`);

      setChecklist(checklist);
      setLoading(false);
      setLastUpdatedTimestamp(format(new Date(), "HH:mm:ss"));
    } catch (err) {
      dispatch(
        addToast({
          title: lang.somethingWentWrong,
          content: lang.errorCouldNotFetchChecklist,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
      setError(true);
      setLoading(false);
    }
  };

  const updateChecklistCategories = (value, questionIndex, categoryIndex) => {
    let questions = "questions";
    let answer = "answer";

    setChecklist({
      ...checklist,
      checklistCategories: [
        ...checklist.checklistCategories.map((category, cIndex) =>
          categoryIndex === cIndex
            ? {
                ...category,
                [questions]: [
                  ...category.questions.map((question, qIndex) =>
                    qIndex === questionIndex ? { ...question, [answer]: value } : question
                  ),
                ],
              }
            : category
        ),
      ],
    });

    debounceSaveChecklist({ questionIndex, categoryIndex });
  };

  // Reset the previously answered checklist item
  const resetChecklistItem = async ({ categoryIndex, questionIndex }) => {
    try {
      let answer = checklist.checklistCategories[categoryIndex].questions[questionIndex].answer;
      await req().delete(
        `checklists/registrations/${registrationId}/checklists/${checklistId}/questions/${answer.questionId}/answers/${answer.id}`
      );
      getChecklist();
    } catch (err) {
      dispatch(
        addToast({
          title: lang.somethingWentWrong,
          content: lang.errorCouldNotResetTheField,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  };

  const deleteChecklist = async () => {
    try {
      await req().delete(`checklists/registrations/${registrationId}/checklists/${checklistId}`);
      pageNavigator_v2({
        path: `${match.url.split("/").slice(0, -1).join("/")}/?dataId=${page.dataId}`,
      });
    } catch (err) {
      dispatch(
        addToast({
          title: lang.somethingWentWrong,
          content: lang.errorCouldNotDeleteChecklist,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  };

  const closeChecklist = async () => {
    try {
      // Checks if all checklist items has been answered
      let questionIndex = checklist.checklistCategories
        .map(({ questions }) => {
          return questions
            .filter((question) => question.answer.title === null || question.answer.title === "")
            .map((question) => {
              return question.id;
            });
        })
        .flat();
      setNotValidArr(questionIndex);
      setValidationCheck(true);

      if (questionIndex.length > 0) {
        return dispatch(
          addToast({
            title: lang.missingFields,
            content: lang.fillAllFields,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
      }

      await req().put(`checklists/registrations/${registrationId}/checklists/${checklistId}/close`);
      getChecklist();
    } catch (err) {
      dispatch(
        addToast({
          title: lang.somethingWentWrong,
          content: lang.errorCouldNotCloseChecklist,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }
  };

  // Show more options on a checklist item (three dots icon)
  const onContextMenuClick = () => {
    let contextMenuItem = [];

    contextMenuItem.push({
      icon: <DeleteIcon />,
      title: lang.updateLastUpdated__placeholder__.replace(/{{placeholder}}/gi, lastUpdatedTimestamp),
      callback: () => getChecklist(),
    });

    if (checklist.author && checklist.author.id === userId) {
      contextMenuItem.push({
        icon: <DeleteIcon />,
        title: lang.delete,
        callback: () =>
          dispatch(
            showDialog({
              styleType: "warning",
              icon: <AlertDecagramIcon />,
              title: lang.deleteChecklist,
              content: lang.aboutToDeleteChecklistInfo,
              primaryActionTitle: lang.yesDeleteChecklist,
              secondaryActionTitle: lang.noKeepChecklist,
              primaryAction: () => deleteChecklist(),
            })
          ),
      });
    }

    dispatch(showContextMenu(contextMenuItem));
  };

  return (
    <Page>
      <TopBar
        title={page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper onClick={() => onContextMenuClick()}>
            <DotsVerticalIcon />
          </ActionWrapper>
        }
      />
      <ScrollView className={componentStyle()}>
        {loading && !error && <Spinner style={{ marginTop: "2rem" }} />}
        {!loading && error && <StatusBox />}
        {!loading && !error && (
          <Fragment>
            <div className="checklist-description-container">
              <p className="title">{checklist.title}</p>
              <div className="content-wrapper">
                <div className="info-wrapper">
                  <AccountIcon />
                  <p>{checklist.author.name}</p>
                </div>
                <div className="info-wrapper">
                  <ClockOutlineIcon />
                  <p>{parseDatetime(checklist.date)}</p>
                </div>
                {checklist.completedDate && (
                  <div className="info-wrapper">
                    <CheckIcon />
                    <p>{parseDatetime(checklist.completedDate)}</p>
                  </div>
                )}
              </div>
              {!checklist.completedDate && (
                <Button
                  onClick={() =>
                    dispatch(
                      showDialog({
                        icon: <AlertDecagramIcon />,
                        title: lang.finishChecklist,
                        content: lang.aboutToFinishedChecklist,
                        primaryActionTitle: lang.yesFinishChecklist,
                        secondaryActionTitle: lang.noNotFinishedYet,
                        primaryAction: () => closeChecklist(),
                      })
                    )
                  }
                  style={{ marginTop: "1rem" }}
                >
                  {lang.finishChecklist}
                </Button>
              )}
            </div>

            <div className="checklist-container">
              {checklist.checklistCategories.map(({ id, questions, title }, index) => (
                <div key={`checklist-category-${id}`}>
                  <p className="title">{title}</p>
                  {questions.map((question, questionIndex) => (
                    <ChecklistTask
                      key={`checklist-task-question-id-${question.id}`}
                      className={
                        notValidArr.some((invalidQuestionId) => invalidQuestionId === question.id) ? "not-valid" : ""
                      }
                      categoryIndex={index}
                      question={question}
                      questionIndex={questionIndex}
                      resetChecklistItem={resetChecklistItem}
                      submitChecklistItem={debounceSaveChecklist}
                      updateChecklistCategories={updateChecklistCategories}
                      notValidArr={notValidArr}
                      checklistIsCompleted={checklist.completedDate}
                    />
                  ))}
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  div.checklist-description-container {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 0.75rem 0.65rem;
    border-top: 1px solid var(--midGrey);
    border-bottom: 1px solid var(--midGrey);

    margin: auto;
    max-width: ${breakpoints.md}px;
    margin-top: 1rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      border: 1px solid var(--midGrey);
    }

    p.title {
      font-size: 1.15rem;
      margin-bottom: 0.65rem;
    }

    p.sub-title {
      color: ${colors.midDarkGrey};
      font-size: 0.85rem;
    }

    div.bottom-box-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 1rem;

      p.last-updated-title {
        color: ${colors.midDarkGrey};
        font-size: 0.75rem;
      }
    }
  }

  div.checklist-container {
    margin: auto;
    max-width: ${breakpoints.md}px;

    p.title {
      font-weight: bold;
      padding: 2rem 0.5rem 1rem 0.5rem;
    }
  }

  div.content-wrapper {
    display: flex;
    flex-direction: column;

    div.info-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0.35rem;
      color: var(--darkGrey);

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.3rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }
`;

export default Checklist;
