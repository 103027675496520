// Libs
import React, { useEffect, useRef } from "react";

/*
 * Purpose: Prevents effect from running on initial render
 * */

const usePostRenderEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default usePostRenderEffect;
