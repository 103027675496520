// Libs
import React from "react";

// Components
import InlineSpinner from "../../../ui/InlineSpinner";

// Style
import colors from "../../../../style/colors";
import ButtonRounded from "../../../ui/ButtonRounded";
import { useSelector } from "react-redux";

const BulkActionButtons = (props) => {
  const { canExport, canApprove } = useSelector((s) => s.timeRegistration.permissions);

  const {
    handleToggleBulkExport,
    handleToggleBulkApprove,
    handleToggleBulkCSV,
    handleCancelBulk,
    handleBulkExport,
    handleBulkApprove,
    handleBulkCSV,
    showBulkApprove,
    showBulkExport,
    showBulkCSV,
    selectedFlatRows,
    handlingRequest,
    config = {},
  } = props;

  const getBulkActionsButtons = () => {
    if (!showBulkExport && !showBulkApprove && !showBulkCSV) {
      return (
        <>
          {canApprove && <ButtonRounded onClick={() => handleToggleBulkApprove()}>Godkend flere</ButtonRounded>}
          {canExport && <ButtonRounded onClick={() => handleToggleBulkExport()}>Eksporter flere</ButtonRounded>}
          {canExport && config?.ENABLE_CSV_DOWNLOAD && (
            <ButtonRounded onClick={() => handleToggleBulkCSV()}>Fremvis som CSV</ButtonRounded>
          )}
        </>
      );
    } else if (showBulkExport) {
      return (
        <>
          <ButtonRounded secondary={true} onClick={() => handleCancelBulk({ bulkType: "export" })}>
            Annuller
          </ButtonRounded>
          <ButtonRounded onClick={() => handleBulkExport()}>
            {handlingRequest ? (
              <InlineSpinner
                style={{ display: "inline-block", margin: "0 0.25rem -0.6rem 0" }}
                size="16"
                color={colors.white}
              />
            ) : (
              `Eksporter ${selectedFlatRows.length}`
            )}
          </ButtonRounded>
        </>
      );
    } else if (showBulkApprove) {
      return (
        <>
          <ButtonRounded secondary={true} onClick={() => handleCancelBulk({ bulkType: "approve" })}>
            Annuller
          </ButtonRounded>
          <ButtonRounded onClick={() => handleBulkApprove()}>
            {handlingRequest ? (
              <InlineSpinner
                style={{ display: "inline-block", margin: "0 0.25rem -0.6rem 0" }}
                color={colors.white}
                size="16"
              />
            ) : (
              `Godkend ${selectedFlatRows.length}`
            )}
          </ButtonRounded>
        </>
      );
    } else if (showBulkCSV && config?.ENABLE_CSV_DOWNLOAD) {
      return (
        <>
          <ButtonRounded secondary={true} onClick={() => handleCancelBulk({ bulkType: "CSV" })}>
            Annuller
          </ButtonRounded>
          <ButtonRounded onClick={() => handleBulkCSV()}>
            {handlingRequest ? (
              <InlineSpinner
                style={{ display: "inline-block", margin: "0 0.25rem -0.6rem 0" }}
                color={colors.white}
                size="16"
              />
            ) : (
              `Fremvis ${selectedFlatRows.length} i CSV`
            )}
          </ButtonRounded>
        </>
      );
    }
  };

  return <div className="bulk-action-buttons-container">{getBulkActionsButtons()}</div>;
};

export default BulkActionButtons;
