import { parse, format } from "date-fns";

export function formatNewsDatetime(datetime, locale) {
  if (datetime.length === 8) {
    return format(parse(datetime, "yyyyMMdd", 0), "do MMMM yyyy", locale);
  }

  if (datetime.length === 12) {
    return format(parse(datetime, "yyyyMMddHHmm", 0), "do MMMM yyyy HH:mm", locale);
  }
}
