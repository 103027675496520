import req from "../utilities/request-utility";
import contactTypes from "../components/contacts/config/contactsTypes";
import {
  CLEAR_CONTACT_GROUP_NAMES,
  GET_CONTACT_GROUP_NAMES_STARTED,
  GET_CONTACT_GROUP_NAMES_SUCCESS,
  GET_CONTACT_GROUP_NAMES_FAILURE,
  SET_ACTIVE_TAB,
  GET_CONTACTS_STARTED,
  CONTACTS_END_OF_FEED,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  CLEAR_CONTACTS,
  SET_RELATED_APPS_CONTACTS,
  SET_CONTACT_FILTERS_ACTIVE_STATE,
  SET_CONTACT_FILTERS_VALUE,
  CLEAR_ALL_CONTACTS,
  SET_CONTACT_PREVIOUS_STATE,
} from "./actionTypes";
import groupTypes from "../components/contacts/config/groupTypes";

export function clearContactGroupNames({ contactType }) {
  return function (dispatch) {
    dispatch({ type: CLEAR_CONTACT_GROUP_NAMES, payload: { contactType } });
  };
}

export function getRelatedContactsApps() {
  return async function (dispatch, getState) {
    try {
      const selectedAppId = getState().contact.filters.selectedAppId;
      if (selectedAppId === null) {
        dispatch({
          type: SET_CONTACT_FILTERS_VALUE,
          payload: { key: "selectedAppId", value: getState().appConfig.appId },
        });
      }

      let { data } = await req()(`contacts/related-apps`);

      if (Array.isArray(data) && data.length === 0) return;

      dispatch({
        type: SET_RELATED_APPS_CONTACTS,
        payload: data,
      });

      // Set default option for related apps
    } catch {}
  };
}

export function showPhoneBookFilters() {
  return {
    type: SET_CONTACT_FILTERS_ACTIVE_STATE,
    payload: true,
  };
}
export function hidePhoneBookFilters() {
  return {
    type: SET_CONTACT_FILTERS_ACTIVE_STATE,
    payload: false,
  };
}

/** Updates a key in the filters object
 * @param {String} key - The identifier in the object. I.e. `selectedAppId`
 * @param {Any} value - The value to set to the corresponding key ie.: `42`
 */
export function setPhoneBookFiltersValue({ key, value }) {
  return {
    type: SET_CONTACT_FILTERS_VALUE,
    payload: { key, value },
  };
}

export function getContactGroupNames({ contactType, groupBy = groupTypes.masterGroup }) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_CONTACT_GROUP_NAMES_STARTED, payload: { contactType } });

      let selectedAppId = getState().contact.filters.selectedAppId;

      let URL = `contacts/overview?contactType=${contactType}&groupBy=${groupBy}`;
      if (selectedAppId && selectedAppId === "ALL" && contactType === contactTypes.phoneBook)
        URL += `&includeUsersFromRelatedApps=1`;
      if (selectedAppId && selectedAppId !== "ALL" && contactType === contactTypes.phoneBook)
        URL += `&showUsersFromRelatedAppId=${selectedAppId}`;

      let { data: groupNames } = await req()(URL);

      dispatch({
        type: GET_CONTACT_GROUP_NAMES_SUCCESS,
        payload: { groupNames, contactType },
      });
    } catch (error) {
      dispatch({ type: GET_CONTACT_GROUP_NAMES_FAILURE, payload: { contactType } });
    }
  };
}

/** The selected tab bar value, so the selected tab i remembered when navigating
 * @param {Integer} selectedTab
 * **/
export function setActiveTab(selectedTab) {
  return function (dispatch) {
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: selectedTab,
    });
  };
}

export function getContacts({ groupId = null, contactType, searchTerm = "", groupType }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().contact[contactType];

      let selectedAppId = getState().contact.filters.selectedAppId;

      let { loading, endOfFeed, error, contacts: reduxContacts } = state;

      let offset = reduxContacts.length;

      // Prevent duplicate results and spares api for duplicate requests
      if (loading || error || endOfFeed || selectedAppId === null) return;

      let contacts = [];

      dispatch({ type: GET_CONTACTS_STARTED, payload: { contactType } });

      if (contactType === contactTypes.keyPerson) {
        let URL = `contacts/?limit=12`;
        URL += `&offset=${offset}`;
        URL += `&searchTerm=${searchTerm}`;
        URL += `&contactGroupId=${groupId}`;

        const { data: kContacts } = await req()(URL);
        contacts = kContacts;
      }

      if (contactType === contactTypes.phoneBook) {
        let URL = `users/?limit=${searchTerm ? "50" : "12"}`;
        URL += `&offset=${offset}`;
        URL += `&searchTerm=${searchTerm}`;
        if (groupType === groupTypes.masterGroup) URL += `&masterGroupId=${groupId}`;
        if (groupType === groupTypes.userGroup) URL += `&userGroupId=${groupId}`;

        if (selectedAppId && selectedAppId === "ALL")
          // If user has only chosen it's own app then don't include users from other apps
          URL += `&includeUsersFromRelatedApps=1`;
        if (selectedAppId && selectedAppId !== "ALL") URL += `&showUsersFromRelatedAppId=${selectedAppId}`;

        const { data: pContacts } = await req()(URL);
        contacts = pContacts;
      }

      if (!contacts.length) {
        dispatch({ type: CONTACTS_END_OF_FEED, payload: { contactType } });
      } else {
        dispatch({ type: GET_CONTACTS_SUCCESS, payload: { contactType, contacts } });
      }
    } catch (error) {
      dispatch({ type: GET_CONTACTS_FAILURE, payload: { contactType } });
    }
  };
}

export function clearContacts({ contactType }) {
  return function (dispatch) {
    dispatch({ type: CLEAR_CONTACTS, payload: { contactType } });
  };
}

export function clearAllContacts() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ALL_CONTACTS });
  };
}

export function setPreviousState(state) {
  return function (dispatch) {
    dispatch({ type: SET_CONTACT_PREVIOUS_STATE, payload: state });
  };
}
