import { css } from "emotion";
import common from "../../../style/common";

export default () => css`
  padding-top: ${common.topBarHeight};
  height: 100%;

  overflow-y: auto;

  .users-container {
    padding: 10px 0;

    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 750px;

    margin: 0 auto;
  }

  .status-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
`;
