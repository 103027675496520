import { da, sv, enUS, nb, pl, fi } from "date-fns/locale";
import store from "../store";

export default function (user) {
  if (!user) {
    user = store.getState().auth.user;
  }

  switch (user.language) {
    case "da":
      return { locale: da };
    case "se":
      return { locale: sv };
    case "no":
      return { locale: nb };
    case "pl":
      return { locale: pl };
    case "fi":
      return { locale: fi };
    case "fs":
      return { locale: sv };
    default:
      return { locale: enUS };
  }
}
