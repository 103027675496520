/**
 * Merges the identifier with the prefix.
 * @param prefix - The prefix of the icon, eg. "mdi".
 * @param name - The name of the icon, eg. "account".
 * @return {string} - The merged identifier. Eg. "mdi:account".
 */
const mergeIdentifier = (prefix, name) => {
  return `${prefix}:${name}`;
};

export default mergeIdentifier;
