// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Actions
import { updateModalPage, hideModalPage, addToast } from "../../actions/uiActions";

// Components
import RadioGroup from "../ui/RadioGroup";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";
import { ApprovalIcon } from "mdi-react";
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";
import Button from "../ui/Button";
import Translator from "../ui/Translator";
import ReadAloud from "../ui/ReadAloud";

// Config
import breakpoints from "../../config/breakpoints";
import { quizTypes } from "../../config/quizTypes";
import translationTypes from "../../config/translationTypes";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import colors from "../../style/colors";

// Hooks
import useTranslation from "../../hooks/useTranslation";
import useReadAloud from "../../hooks/useReadAloud";
import readAloudTypes from "../../config/readAloudTypes";

function CheckQuestionsModal(props) {
  let { quiz, subTypeId, postId, addToast, callback = (arg) => {} } = props;

  const [questions, setQuestions] = useState(quiz.questions);
  const [answers, setAnswers] = useState([]);
  const [active, setActive] = useState(false);
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const [showNotPassedCertificate, setShowNotPassedCertificate] = useState(false);
  const [notPassedScore, setNotPassedScore] = useState(0);

  const lang = useSelector((s) => s.language.language);

  function getUserAnswerByQuestionId(questionId) {
    return answers.filter((a) => a.questionId === questionId)[0];
  }

  function getTotalAmountOfObtainedPoints(questionsWithAnswers) {
    let obtainedPoints = 0;
    let correctlyAnsweredQuestions = questionsWithAnswers.filter((q) => q.isUserAnswerCorrect);
    if (correctlyAnsweredQuestions.length === 0) return obtainedPoints;
    correctlyAnsweredQuestions.forEach((question) => {
      obtainedPoints += question.points;
    });
    return obtainedPoints;
  }

  function getTitle() {
    let numberOfQuestion = questionsWithAnswers.length;
    let correctAnswers = questionsWithAnswers.filter((q) => q.isUserAnswerCorrect).length;

    if (correctAnswers === 0) {
      return lang.checkQuestionsSummaryTitleBad;
    } else if (correctAnswers > 0 && correctAnswers < numberOfQuestion) {
      return lang.checkQuestionsSummaryTitleAverage;
    } else if (correctAnswers === numberOfQuestion) {
      return lang.checkQuestionsSummaryTitleGood;
    } else {
      // if something goes wrong: fall back to "Status"
      return "Status";
    }
  }

  async function submitAnswers(answers) {
    try {
      let { data } = await req().post(`/news/${subTypeId}/${postId}/answers`, answers);

      if (quiz.type === quizTypes.CERTIFICATE) {
        let result = data;
        result.questions = mergeCorrectQuestionsWithTranslatedQuestions(result.questions);
        handleCertificateAnswers(result);
      }
      if (quiz.type === quizTypes.QUIZ) {
        let correctAnswers = data;
        correctAnswers = mergeCorrectQuestionsWithTranslatedQuestions(correctAnswers);
        handleQuizAnswers(correctAnswers);
      }
    } catch {
      addToast({ template: "error" });
      setActive(false);
    }
  }

  function handleQuizAnswers(answers) {
    setQuestionsWithAnswers(answers);
    setShowCorrectAnswers(true);
    setActive(false);
    props.updateModalPage({ closeCallback: null });
  }

  function handleCertificateAnswers(result) {
    const { totalCorrectAnswers, questions } = result;

    // ⚠️ !! Attention !! ⚠️
    //
    // Because the component initially was used to show correct/incorrect answers, the returned value from the API was an array consisting of
    // the answered questions.
    //
    // To make newsQuizzes backwards compatible, we now determine if the user has passed the quiz based on the format of the data returned from the API
    // If the quiz is of type QUIZ, then we don't need to worry about anything.
    // But if the quiz if of type CERTIFICATE, the format of the data returned from the API determines if the user has passed the quiz.
    // If the user has answered correctly an object containing the number of correct answered questions and all the questions will be returned.
    // If the user doesn't answer correctly, then an object containing the number of correct answered questions and an empty array for the questions.
    // It important to note that the questions hold the correct answers, so we only want to return them if the user has passed the quiz.

    if (callback) callback(questions);

    if (Array.isArray(questions) && questions.length > 0) {
      setQuestionsWithAnswers(questions);
      setShowCorrectAnswers(true);
      setActive(false);
      props.updateModalPage({ closeCallback: null });
    } else {
      setNotPassedScore(totalCorrectAnswers);
      setShowNotPassedCertificate(true);
    }
  }

  /** Handle `<RadioGroup />` selects */
  function onSelect(answerId, questionId) {
    /* Run through answers. If an object with the questionId exists
     * in the answers-array update it. Otherwise create a new answers
     * object.
     */

    // Potential index of existing answer
    let answerIndex = answers.findIndex((a) => a.questionId === questionId);

    // Checks if answer exists
    if (answerIndex === -1) {
      setAnswers([...answers, { questionId, answerId }]);
    } else {
      setAnswers(answers.map((answer) => (answer.questionId === questionId ? { questionId, answerId } : answer)));
    }
  }

  /*
   * So what is happening here?
   *
   * Let's say we have translated some questions and their answers. When we then submit the answers to the API,
   * we receive all the correct answers.
   *
   * Because the correct answers and the questions returned from the API isn't translated, we therefore need to merge the correct answers and their question
   * with the translated answers and questions in the app so the content makes sense for the user.
   * */
  function mergeCorrectQuestionsWithTranslatedQuestions(correctQuestions) {
    return correctQuestions.map((correctQuestion) => {
      const translatedQuestion = questions.find((question) => question.id === correctQuestion.id);
      if (translatedQuestion) {
        return {
          ...correctQuestion,
          question: translatedQuestion.question,
          answers: correctQuestion.answers.map((answer) => {
            const translatedAnswer = translatedQuestion.answers.find(
              (translatedAnswer) => translatedAnswer.id === answer.id
            );
            if (translatedAnswer)
              return {
                ...answer,
                answer: translatedAnswer.answer,
              };
            return answer;
          }),
        };
      }

      return correctQuestion;
    });
  }

  /* Updated the question title when a question has been translated */
  function updateQuestionContent({ translatedQuestion, translatedAnswers }) {
    setQuestions((questions) =>
      questions.map((question) =>
        question.id === translatedQuestion.id
          ? {
              ...question,
              question: translatedQuestion.title,
              answers: question.answers.map((answer) => {
                const translatedAnswer = translatedAnswers.find((translatedAnswer) => translatedAnswer.id === answer.id);

                if (translatedAnswer) return { ...answer, answer: translatedAnswer.title };
                return answer;
              }),
            }
          : question
      )
    );
  }

  if (!quiz.questions) return <StatusBox />;

  return (
    <ScrollView style={{ backgroundColor: colors.white }} className={componentStyles()}>
      <div className="content-wrapper">
        {showNotPassedCertificate && (
          <div className="not-passed-quiz">
            <h2 className="title">{getTitle()}</h2>
            <p style={{ marginBottom: "0.5rem" }}>
              {lang.youGot__placeholderCorrectAnswers__outOf__placeholderAmountOfAnswers__notPassedQuiz
                .replace(/{{placeholderCorrectAnswers}}/gi, notPassedScore)
                .replace(/{{placeholderAmountOfAnswers}}/gi, quiz.questions.length)}
            </p>
            <p style={{ marginBottom: "2rem" }}>
              {lang.youNeedAtLeast__placeholder__toPassTheQuiz.replace(/{{placeholder}}/gi, quiz.passScore)}
            </p>
          </div>
        )}

        {/* Show answerable questions */}
        {!showNotPassedCertificate && !showCorrectAnswers && (
          <>
            {questions.map((question) => (
              <>
                <CheckQuestion
                  quizId={quiz.id}
                  question={question}
                  onSelect={onSelect}
                  updateQuestionContent={updateQuestionContent}
                />
              </>
            ))}
            <Button
              style={{ marginTop: "2rem" }}
              disabled={answers.length !== quiz.questions.length}
              active={active}
              onClick={() => submitAnswers(answers)}
            >
              {lang.save}
            </Button>
          </>
        )}

        {/* Show summary of users answers */}
        {showCorrectAnswers && (
          <>
            <div className="summary">
              <h2>{getTitle()}</h2>
              <p>
                {lang.youGot__placeholderCorrectAnswers__outOf__placeholderAmountOfAnswers__andEarned__placeholderPoint__point
                  .replace(
                    "{{placeholder-correct-answers}}",
                    questionsWithAnswers.filter((q) => q.isUserAnswerCorrect).length
                  )
                  .replace("{{placeholder-amount-of-answers}}", questionsWithAnswers.length)
                  .replace("{{placeholder-point}}", getTotalAmountOfObtainedPoints(questionsWithAnswers))}
              </p>
            </div>

            {questionsWithAnswers.map((question) => (
              <>
                <h2 style={{ marginBottom: "0.5rem", color: question.isUserAnswerCorrect ? colors.green : colors.red }}>
                  {question.isUserAnswerCorrect && (
                    <ApprovalIcon
                      style={{
                        color: colors.green,
                        width: "1.4rem",
                        height: "1.4rem",
                        marginBottom: "-0.3rem",
                        marginRight: "0.35rem",
                      }}
                    />
                  )}
                  {question.question}
                </h2>
                <RadioGroupShowCorrectAnswers
                  style={{ marginBottom: "2rem" }}
                  userAnswer={getUserAnswerByQuestionId(question.id)}
                  answers={question.answers.map(({ id, answer, correct }) => ({ id, title: answer, correct }))}
                />
              </>
            ))}
            <Button onClick={props.hideModalPage}>{lang.ok}</Button>
          </>
        )}
      </div>
    </ScrollView>
  );
}

const CheckQuestion = ({ quizId, question, updateQuestionContent, onSelect }) => {
  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedQuestion,
    content: translatedAnswers,
  } = useTranslation({
    type: translationTypes.newsCheckQuestion,
    args: { questionId: question.id, quizId },
  });

  const { isPlayingAudio, isLoadingAudio, readAloudContent } = useReadAloud({
    type: readAloudTypes.newsCheckQuestion,
    args: { questionId: question.id, quizId, orderOfAnswers: question.answers.map(({ id }) => id) },
  });

  useEffect(() => {
    if (translatedQuestion && translatedAnswers) updateQuestionContent({ translatedQuestion, translatedAnswers });
  }, [translatedQuestion, translatedAnswers]);

  return (
    <div className="question">
      <h2 style={{ marginBottom: "0.5rem" }}>{question.question}</h2>
      <RadioGroup
        onSelect={(e) => onSelect(e, question.id)}
        options={question.answers.map(({ id, answer }) => ({ id, title: answer }))}
      />
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Translator
          style={{ marginTop: "0.5rem", marginLeft: "0.125rem" }}
          isTranslated={isTranslated}
          isTranslating={isTranslating}
          onTranslate={translateContent}
        />
        <ReadAloud
          isLoadingAudio={isLoadingAudio}
          isPlayingAudio={isPlayingAudio}
          onReadContent={() => readAloudContent({ currentTranslationLanguage })}
        />
      </div>
    </div>
  );
};

const componentStyles = () => css`
  padding: 1rem 1rem 4rem 1rem;

  .not-passed-quiz {
    .title {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }

  .question {
    margin-top: 2rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .content-wrapper {
    max-width: ${breakpoints.md}px;
    margin: 0 auto;
  }

  div.summary {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(CheckQuestionsModal);
