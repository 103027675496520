import React, { useEffect, useState } from "react";

export const UseLastUsedDropDownValues = ({ key = null, options = [] }) => {
  const [storedValues, setStoredValues] = useState([]);

  function setValues() {
    try {
      if (!key) return [];

      // Get from localstorage
      let values = window.localStorage.getItem(key);
      values = JSON.parse(values);
      values = options?.filter((a) => values && values.some((v) => a.id === v.id));
      setStoredValues(values);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  useEffect(() => {
    if (options && options.length > 0) setValues();
  }, [options]);

  const updateValues = ({ valueId }) => {
    try {
      let storedValuesCopy = [...storedValues];
      let value = options.find((value) => valueId == value.id);

      let index = storedValuesCopy.findIndex((v) => v.id === value.id);

      // Removes the element at the index
      if (index !== -1) storedValuesCopy.splice(index, 1);

      storedValuesCopy.unshift(value);
      setStoredValues(storedValuesCopy);
      localStorage.setItem(key, JSON.stringify(storedValuesCopy));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValues, updateValues];
};
