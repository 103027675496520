import TabBar from "../../TabBar";
import { useDispatch, useSelector } from "react-redux";
import { setTargetGroupPanelTabIndex } from "../actions/targetGroupModalActions";
import { useEffect, useState } from "react";
import { groupTypes } from "../config/groupTypes";
import useLang from "../../../../hooks/useLang";

function TargetGroupPanelTabBar(props) {
  const dispatch = useDispatch();
  const lang = useLang();
  const { activeTabIndex } = useSelector((s) => s.targetGroupModal.targetGroupPanel);
  const { users, userGroups, masterGroups, customGroups, jobTitles } = useSelector((s) => s.targetGroupModal.targetGroup);

  const [groupsWithChanges, setGroupsWithChanges] = useState([]);
  const [lastRenderCounts, setLastRenderCounts] = useState({
    users: 0,
    userGroups: 0,
    masterGroups: 0,
    customGroups: 0,
    jobTitles: 0,
  });

  /**
   * To highlight which tabs has changes we saved which numbers of results were in each tab
   * at last render and compare it to the next render.
   */
  function addAndScheduleRemovalOfGroupChange(type) {
    setGroupsWithChanges((s) => [...s, type]);
    setTimeout(() => {
      setGroupsWithChanges((s) => s.filter((group) => group !== type));
    }, 600);
  }

  useEffect(() => {
    if (lastRenderCounts.users !== users.length) addAndScheduleRemovalOfGroupChange(groupTypes.users);
    if (lastRenderCounts.userGroups !== userGroups.length) addAndScheduleRemovalOfGroupChange(groupTypes.userGroups);
    if (lastRenderCounts.masterGroups !== masterGroups.length) addAndScheduleRemovalOfGroupChange(groupTypes.masterGroups);
    if (lastRenderCounts.customGroups !== customGroups.length) addAndScheduleRemovalOfGroupChange(groupTypes.customGroups);
    if (lastRenderCounts.jobTitles !== jobTitles.length) addAndScheduleRemovalOfGroupChange(groupTypes.jobTitles);

    setLastRenderCounts({
      users: users.length,
      userGroups: userGroups.length,
      masterGroups: masterGroups.length,
      customGroups: customGroups.length,
      jobTitles: jobTitles.length,
    });
  }, [users, userGroups, masterGroups, customGroups, jobTitles]);

  return (
    <>
      <TabBar
        activeTabIndex={activeTabIndex}
        minimalPadding={true}
        tabs={[
          {
            title: (
              <div className={`target-group-tab ${groupsWithChanges.includes(groupTypes.users) ? "highlight" : "neutral"}`}>
                {lang.users} <span className="count">{users.length}</span>
              </div>
            ),
            onClick: () => dispatch(setTargetGroupPanelTabIndex(0)),
            "data-test-id": "target-group-panel-tab-bar__users",
          },
          {
            title: (
              <div
                className={`target-group-tab ${groupsWithChanges.includes(groupTypes.userGroups) ? "highlight" : "neutral"}`}
              >
                {lang.subGroups} <span className="count">{userGroups.length}</span>
              </div>
            ),
            onClick: () => dispatch(setTargetGroupPanelTabIndex(1)),
            "data-test-id": "target-group-panel-tab-bar__userGroups",
          },
          {
            title: (
              <div
                className={`target-group-tab ${
                  groupsWithChanges.includes(groupTypes.masterGroups) ? "highlight" : "neutral"
                }`}
              >
                {lang.mainGroups} <span className="count">{masterGroups.length}</span>
              </div>
            ),
            onClick: () => dispatch(setTargetGroupPanelTabIndex(2)),
            "data-test-id": "target-group-panel-tab-bar__masterGroups",
          },
          {
            title: (
              <div
                className={`target-group-tab ${
                  groupsWithChanges.includes(groupTypes.customGroups) ? "highlight" : "neutral"
                }`}
              >
                {lang.customGroups} <span className="count">{customGroups.length}</span>
              </div>
            ),
            onClick: () => dispatch(setTargetGroupPanelTabIndex(3)),
            "data-test-id": "target-group-panel-tab-bar__customGroups",
          },
          {
            title: (
              <div
                className={`target-group-tab ${groupsWithChanges.includes(groupTypes.jobTitles) ? "highlight" : "neutral"}`}
              >
                {lang.jobTitles} <span className="count">{jobTitles.length}</span>
              </div>
            ),
            onClick: () => dispatch(setTargetGroupPanelTabIndex(4)),
            "data-test-id": "target-group-panel-tab-bar__jobTitles",
          },
        ]}
      />
    </>
  );
}

export default TargetGroupPanelTabBar;
