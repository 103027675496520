// Libraries
import { useState } from "react";
import { useDispatch } from "react-redux";
import { css } from "emotion";

// Components
import TextInput from "../../ui/TextInput";
import ButtonRounded from "../../ui/ButtonRounded";

// Actions
import { authenticate } from "../../../actions/pageBuilderActions";

export const PageBuilderAuth = () => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(authenticate({ password }));
  };

  return (
    <div className={componentStyle()}>
      <label htmlFor={"secret-password"}>Enter access token to continue</label>
      <TextInput
        className={"password-input"}
        type={"password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        data-test-id="page-builder__password-input"
      />
      <ButtonRounded onClick={handleSubmit} data-test-id="page-builder__password-submit-button">
        Submit
      </ButtonRounded>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  gap: 1rem;

  height: 100%;

  .password-input {
    width: 300px;
  }
`;
