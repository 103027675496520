import rollbar from "rollbar";
// Libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Other
import store from "./store";
import "./style/base-styles.css";
import "./style/route-animations.css";
import "./style/basic-animations.css";
import App from "./App";
import ErrorCatcher from "./components/ui/ErrorCatcher";
import registerServiceWorker from "./registerServiceWorker";
import setManifest from "./utilities/set-manifest";
import { storeToken } from "./utilities/login-transfer-token-utils";

import packageJson from "../package.json";

// First thing to do so it wont fail
setManifest();

var rollbarConfig = {
  accessToken: "22ff3eec4ab746e1b7d28a9944a29909",
  captureUncaught: true,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
  },
  payload: {
    environment: process.env.NODE_ENV,
    code_version: packageJson.version,
  },
};

const Rollbar = new rollbar(rollbarConfig);
window.Rollbar = Rollbar;

ReactDOM.render(
  <ErrorCatcher>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorCatcher>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store
}

registerServiceWorker();
storeToken();
