// Libs
import React from "react";
import { css } from "emotion";

// Config
import pageConfig from "../../../config/pageConfig";

// Components
import { PhoneIcon, MailOutlineIcon, ClipboardTextOutlineIcon } from "mdi-react";

// Styles
import colors from "../../../style/colors";

// Utilities
import highlightMatch from "../../../utilities/highlight-match";

const ContactListContent = ({ config, contact, searchTerm = "" }) => {
  if (!config) return null;

  return (
    <div className={componentStyle()}>
      {contact?.phone && (
        <div className="content-wrapper">
          <div className="icon-wrapper">
            <PhoneIcon />
          </div>
          <p>{highlightMatch(contact.phone, searchTerm)}</p>
        </div>
      )}
      {contact?.email && (
        <div className="content-wrapper">
          <div className="icon-wrapper">
            <MailOutlineIcon />
          </div>
          <p>{highlightMatch(contact.email, searchTerm)}</p>
        </div>
      )}
      {config[pageConfig.SHOW_COMPETENCES] && contact.skills && (
        <div className="content-wrapper">
          <div className="icon-wrapper">
            <ClipboardTextOutlineIcon />
          </div>
          <p>{highlightMatch(contact.skills, searchTerm)}</p>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  padding-top: 0.125rem;

  .content-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.125rem;

    .icon-wrapper {
      display: flex;
      flex-shrink: 1;

      svg {
        height: 0.85rem;
        width: 0.85rem;
        fill: ${colors.darkGrey};
        margin-right: 0.25rem;
      }
    }

    p {
      font-size: 0.8125rem;
      color: ${colors.darkGrey};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export default ContactListContent;
