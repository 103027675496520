import React, { useEffect, useRef } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import colors from "../../style/colors";
import common from "../../style/common";

/** Component to handle multiline textinput
 *
 * ```
 *  <TextareaInput
          name="comment"
          value={this.state.comment}
          onChange={this.handleChange}
          placeholder="Indtast din kommentar her"
          style={{ marginBottom: baseDistance * 2 + "rem" }}
        />
 * ```
 */
const TextareaInput = (props) => {
  const {
    rows,
    className,
    id,
    placeholder,
    onChange,
    name,
    value,
    initText,
    disabled = false,
    maxLength = 50000,
    onKeyPress,
    resize = false,
  } = props;

  const ref = useRef();

  useEffect(() => {
    if (ref.current !== null && resize) {
      // reset height every render
      ref.current.style.height = `0px`;

      // set height of element to scrollHeight
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      disabled={disabled}
      rows={rows}
      ref={ref}
      className={`${style(props)} ${className || ""}`}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      value={value}
      defaultValue={initText}
      style={props.style}
      data-test-id={props["data-test-id"]}
      autoComplete="off"
      maxLength={maxLength}
      onKeyDown={onKeyPress}
      {...(props.register ? props.register : {})}
    />
  );
};

const style = (props) => css`
  background-color: ${props.backgroundColor || colors.white};
  border: 1px solid ${props.backgroundColor || colors.midGrey};
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 1rem;
  color: var(--black);
  resize: vertical;
  min-height: ${props.minHeight || "120px"};
  font-family: ${common.fontStack};
  padding: 0.5rem;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
    border: 1px solid ${props.primaryColor};
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

TextareaInput.propTypes = {
  /** Sets an HTML attribute */
  name: PropTypes.string,
  /** Visible text when you haven't anything typed in */
  placeholder: PropTypes.string,
  /** Function that executes everytime you type something in this component */
  onChange: PropTypes.func,
  /** sets a className for easy styling */
  className: PropTypes.string,
  /** Used for overriding styles */
  style: PropTypes.object,
  /** Validation - changes the styling if true **/
  showValidation: PropTypes.bool,
};

export default connect(mapStateToProps)(TextareaInput);
