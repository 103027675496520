import DynamicIcon from "../ui/Icons/DynamicIcon";
import React from "react";

const identifiers = {
  registrations: "registrations",
};

export const contentSettingsList = [
  {
    title: "Registreringer",
    icon: <DynamicIcon icon={"note-edit-outline"} />,
    identifier: identifiers.registrations,
  },
];
