import { format, parse } from "date-fns";
import getUserLocale from "../../../utilities/get-user-locale";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

const getRegistrationTitle = (registration) => {
  const day = capitalizeFirstLetter(format(parse(registration.date, "yyyyMMdd", 0), "EEEE", getUserLocale()));
  const date = format(parse(registration.date, "yyyyMMdd", 0), "dd/MM", getUserLocale());
  return (
    <>
      {day}
      <span className="date">{date}</span>
    </>
  );
};

export default getRegistrationTitle;
