// Libraries
import React from "react";
import { css } from "emotion";

// Components
import { UserListItem } from "../ui/users/UserListItem";

// UI
import ScrollView from "../ui/ScrollView";

// Styling
import colors from "../../style/colors";

const CommentLikesOverviewModal = ({ likes }) => {
  return (
    <ScrollView className={style(colors)}>
      <div className="user-list">
        {/* Objects in the likes array are users  */}
        {likes.map((user) => (
          <UserListItem key={user.id} user={user} />
        ))}
      </div>
    </ScrollView>
  );
};

const style = (colors) => css`
  background-color: var(--lightGrey);
  height: 100%;
  padding: 1rem 0;
`;

export default CommentLikesOverviewModal;
