import React from "react";
import { css } from "emotion";
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";

const TimeRegistrationListItemSkeleton = (props) => {
  const { title, index } = props;
  return (
    <div className={`${componentStyle()} ${props.className || ""}`}>
      <div className="left-content-container">
        <div className="fake-icon-left" style={{ animationDelay: `${index * 10}ms` }} />

        <div className="title">
          <p>{title}</p>
        </div>
      </div>

      <div className="fake-content-right" style={{ animationDelay: `${index * 20}ms` }}>
        <div className="fake-icon-right"></div>
        <div className="border"></div>
        <div className="fake-number-right"></div>
      </div>
    </div>
  );
};

const componentStyle = (props) => css`
  background-color: var(--white);
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--midGrey);

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-width: ${breakpoints.lg}px;
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1px solid var(--midGrey);
  }

  p {
    line-height: 3.75rem;
  }
  .left-content-container {
    display: flex;
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .fake-icon-left,
  .fake-icon-right,
  .fake-number-right {
    opacity: 0;
    background-color: var(--darkGrey);
    border-radius: 12px;
    margin: 1rem 1rem 1rem 1rem;
    width: 22px;
    height: 24px;
    animation: blink 1.8s infinite;
  }

  .fake-content-right {
    align-items: center;
    display: flex;
    .fake-icon-right {
      /* margin-right: 0px;
      padding-right: 1rem;
      width: 24px !important;
      border-right: 1px solid var(--midGrey); */
    }
    .border {
      height: 1rem;
      border-right: 1px solid var(--lightGrey);
    }
    .fake-number-right {
      width: 34px;
    }
  }

  .title {
    .date {
      color: var(--darkGrey);
      padding: 0rem 0.5rem 0rem 1rem;
    }
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export default TimeRegistrationListItemSkeleton;
