import { ChevronUpIcon, TargetArrowIcon } from "mdi-react";
import ActionWrapper from "../../ActionWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  hideTargetGroupPanel,
  removeAllCustomGroupsFromTargetGroup,
  removeAllJobTitlesFromTargetGroup,
  removeAllMasterGroupsFromTargetGroup,
  removeAllUserGroupsFromTargetGroup,
  removeAllUsersFromTargetGroup,
  removeCustomGroupFromTargetGroup,
  removeJobTitleFromTargetGroup,
  removeMasterGroupFromTargetGroup,
  removeUserFromTargetGroup,
  removeUserGroupFromTargetGroup,
  showTargetGroupPanel,
} from "../actions/targetGroupModalActions";
import TargetGroupPanelTabBar from "./TargetGroupPanelTabBar";
import TabView from "../../TabView";
import { useEffect, useState } from "react";
import req from "../../../../utilities/request-utility";
import { useDebouncedCallback } from "use-debounce";
import InlineSpinner from "../../InlineSpinner";
import TargetGroupPanelTab from "./TargetGroupPanelTab";
import { groupTypes } from "../config/groupTypes";
import showGroupMembers from "../utilities/showGroupMembers";
import useAuthorModal from "../../../../hooks/useAuthorModal";
import useLang from "../../../../hooks/useLang";
import ButtonRounded from "../../ButtonRounded";
import styleTypes from "../../../../config/styleTypes";

function TargetGroupPanel(props) {
  const dispatch = useDispatch();
  const lang = useLang();
  const authorModal = useAuthorModal();
  const [membersCount, setMembersCount] = useState("");
  const [loadingMembersCount, setLoadingMembersCount] = useState("");
  const { isTargetGroupPanelVisible, targetGroupPanel, targetGroup } = useSelector((s) => s.targetGroupModal);

  const [getTargetGroupMembersCount] = useDebouncedCallback(async () => {
    let { data } = await req().post(`target-groups/utilities/count-members`, targetGroup);
    setMembersCount(data.count);
    setLoadingMembersCount(false);
  }, 250);

  function seeTargetGroup(e) {
    e.stopPropagation();
    showGroupMembers({ type: groupTypes.targetGroup, title: lang.targetGroup, authorModal, targetGroup });
  }

  useEffect(() => {
    setLoadingMembersCount(true);
    getTargetGroupMembersCount();
  }, [targetGroup]);

  return (
    <div className={`panel target-group-panel ${isTargetGroupPanelVisible ? "is-visible" : "is-hidden"} `}>
      <div className="panel-header">
        <div className="upper-row-container">
          <ActionWrapper
            data-test-id="target-group-panel-toggle"
            className="target-group-panel-toggle"
            onClick={() => dispatch(isTargetGroupPanelVisible ? hideTargetGroupPanel() : showTargetGroupPanel())}
          >
            <ChevronUpIcon className="chevron-icon hide-on-desktop" />
            <div className="seperator" />
            <h2>
              <TargetArrowIcon style={{ marginBottom: "-6px" }} /> {lang.see} {lang.targetGroup.toLowerCase()}{" "}
              <span className="target-group-info">
                {loadingMembersCount && (
                  <>
                    <InlineSpinner size="12" style={{ display: "inline-block", marginBottom: "-9px" }} /> {lang.counting}{" "}
                    {lang.users}...
                  </>
                )}
                <p className="see-target-group-users">{!loadingMembersCount && `${membersCount} ${lang.users}`}</p>
              </span>
            </h2>
          </ActionWrapper>
          <ButtonRounded
            className="see-members-button"
            onClick={seeTargetGroup}
            styleType={styleTypes.neutral}
            secondary={true}
            size="small"
          >
            Se medlemmer
          </ButtonRounded>
        </div>

        <TargetGroupPanelTabBar />
      </div>

      <TabView
        activeTabIndex={targetGroupPanel.activeTabIndex}
        tabs={[
          <TargetGroupPanelTab
            groupType={groupTypes.users}
            removeAllFunction={removeAllUsersFromTargetGroup}
            removeOneFunction={removeUserFromTargetGroup}
            titleField="name"
          />,
          <TargetGroupPanelTab
            groupType={groupTypes.userGroups}
            removeAllFunction={removeAllUserGroupsFromTargetGroup}
            removeOneFunction={removeUserGroupFromTargetGroup}
          />,
          <TargetGroupPanelTab
            groupType={groupTypes.masterGroups}
            removeAllFunction={removeAllMasterGroupsFromTargetGroup}
            removeOneFunction={removeMasterGroupFromTargetGroup}
          />,
          <TargetGroupPanelTab
            groupType={groupTypes.customGroups}
            removeAllFunction={removeAllCustomGroupsFromTargetGroup}
            removeOneFunction={removeCustomGroupFromTargetGroup}
          />,
          <TargetGroupPanelTab
            groupType={groupTypes.jobTitles}
            removeAllFunction={removeAllJobTitlesFromTargetGroup}
            removeOneFunction={removeJobTitleFromTargetGroup}
            countField="count"
            idField="title"
          />,
        ]}
      />
    </div>
  );
}

export default TargetGroupPanel;
