// Libs
import React from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";

import colors from "../../style/colors";
import Button from "./Button";
import { SmileySadIcon } from "mdi-react";

/** 
 * @param {Object} props 
 * @param {React.ReactElement} props.icon 
 * @param {String} props.title - The title to display
 * @param {String} props.content - The content to display
 * @param {Object} props.action - An optional action. I.e. "Try again" or "Contact support" etc.
 * @param {Function} props.action.callback - The function to invoke when clicking on the action
 * @param {String} props.action.title - The title of the action/button
 * 
 * 
 * Element to notify users of the current status of content, eg. No more readable posts, an error occured while fetching data. then use this component
 *  Use icon to indicate how good/bad the current statusbox is
 *  ```
 *
 *  <StatusBox
              style={{ marginTop: "2rem" }}
              title="There was an error"
              content="Could not load checklist"
              icon={<SmileySadIcon />}
              action={{
                callback: this.getChecklist,
                title: "Try again"
              }}
            />
 * ```
 * ```
 * If you need to add an action to the statusbox, this is the required structure
 *            {
                callback: this.getChecklist,
                title: "Try again"
              }
 * ```
 */
const StatusBox = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  return (
    <div style={props.style} className={`${componentStyle(props)} ${props.className}`} data-test-id="status-box">
      {props.icon || <SmileySadIcon />}
      {props.title !== false && <h2 style={{ marginBottom: "0.25rem" }}>{props.title || lang.oops || "Oops."}</h2>}
      <p>{props.content || lang.oopsAnErrorOccured || "An error occurred"}</p>
      {props.action && (
        <Button buttonType="secondary" fullWidth={false} style={{ margin: "1rem auto" }} onClick={props.action.callback}>
          {props.action.title}
        </Button>
      )}
    </div>
  );
};

const componentStyle = (props) => css`
  svg {
    width: 2rem;
    height: 2rem;
  }

  margin-left: auto;
  margin-right: auto;
  padding: 0 3rem;
  text-align: center;
  color: var(--darkGrey);
  white-space: initial;
`;

StatusBox.propTypes = {
  /** Override styles locally */
  style: PropTypes.object,
  /** Svg icon from mdi-react which indicates severity of statusbox */
  icon: PropTypes.object,
  /** Main title of statusbox */
  title: PropTypes.string,
  /** More descriptive textblock to further explain the main title and status */
  content: PropTypes.string,
  /** Object holding logic regarding "try again functionality" */
  action: PropTypes.object,
};
export default StatusBox;
