import dayjs from "dayjs";

function shouldNotificationOptionBeAvailableInPostModal({ formData }) {
  // a start time is chosen
  if (formData.time) return false;

  // If we got this far that means that a start time wasn't chosen.
  // Lets see if the post date differs from todays date
  let currentDate = dayjs().format("YYYYMMDD");
  let postDate = dayjs(formData.date).format("YYYYMMDD");

  if (currentDate !== postDate) return false;

  return true;
}

export default shouldNotificationOptionBeAvailableInPostModal;
