import React from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import colors from "../../style/colors";
import PropTypes from "prop-types";
import breakpoints from "../../config/breakpoints";

/** Components used on TopComponent Happyscore to style its element to a tile-like layout
 * 
 * PLEASE NOTE, it used props.children
 * ```
 * {happyScore.map((obj, index) => (
            <HappyScoreContainer key={index} title={obj.title} subtitle={obj.subtitle} responses={obj.responses}>
              {obj.chartValues && <Chart type={obj.type} values={obj.chartValues} />}
            </HappyScoreContainer>
          ))}
 * ```
 */
const HappyScoreContainer = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { primaryColor } = useSelector((state) => state.appConfig);

  return (
    <div className={container(primaryColor)}>
      <div className="title">{props.title}</div>
      {props.responses && (
        <div className="responses">
          {props.responses} {lang.response}
        </div>
      )}
      <div>{props.subtitle}</div>
      <div className="message">{props.message}</div>
      {props.children}
    </div>
  );
};

const container = (primaryColor) => css`
  margin-top: 1rem;
  padding: 1rem;
  border-top: 1px var(--midGrey) solid;
  border-bottom: 1px var(--midGrey) solid;
  background-color: var(--white);
  height: auto;

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-width: ${breakpoints.lg}px;
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    margin: 1rem auto;
  }

  .title {
    display: inline-block;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .responses {
    display: inline-block;
    font-size: 0.8rem;
    padding-left: 1rem;
  }

  .message {
    font-weight: bold;
  }
`;

HappyScoreContainer.propTypes = {
  /** Numerical value which represents number of responses*/
  responses: PropTypes.number,
  /** String value representing element title */
  title: PropTypes.string,
  /** Descriptive body of text, related to title of element */
  subTitle: PropTypes.string,
  /** String of text used for "PLEASE NOTE"- situations */
  message: PropTypes.string,
  /** Array passed by react, it holds all tags between <> and </> of the implementation */
};

export default HappyScoreContainer;
