// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { updateTableFilters } from "../../../../../actions/timeRegistrationActions";

const ExportDropDownFilter = ({ column }) => {
  const { setFilter, preFilteredRows, filteredRows } = column;

  const dispatch = useDispatch();
  const filterValue = useSelector((state) => state.timeRegistration.table.filters.exported);
  const lang = useSelector((state) => state.language.language);

  const exportedStatus = {
    exported: lang.exported,
    notExported: lang.notExported,
  };

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values.exported === true) options.add("true");
      if (row.values.exported === false) options.add("false");
    });

    return [...options.values()];
  }, [preFilteredRows]);

  function onChangeHandler(e) {
    let value = e.target.value;

    if (value !== "") {
      value = getBooleanValue(e.target.value);
    }

    setFilter(value);
    dispatch(
      updateTableFilters({
        filters: { exported: value },
      })
    );
  }

  function getTitle(value) {
    if (value === true) return exportedStatus.exported;
    if (value === false) return exportedStatus.notExported;
  }

  function getBooleanValue(value) {
    if (value === "true" || value === true) return true;
    return false;
  }

  useEffect(() => {
    if (filterValue !== "") {
      setFilter(getBooleanValue(filterValue));
    }
    setFilter(filterValue);
  }, [filterValue]);

  // Render a multi-select box
  return (
    <div className="input-container">
      <label>{column.render("Header")}</label>
      <select value={filterValue === "" ? "" : filterValue} onChange={onChangeHandler}>
        <option value="">Vis alle</option>
        {options.map((option, i) => (
          <option key={i} value={getBooleanValue(option)}>
            {getTitle(getBooleanValue(option))}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ExportDropDownFilter;
