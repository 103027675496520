// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useSubPages = ({ parentPageId }) => {
  const [parentPage, setParentPage] = useState(undefined);
  const { pages } = useSelector((state) => state.pageBuilder);

  function findParentPage({ parentPageId, pages }) {
    let parentPage = undefined;

    if (!Array.isArray(pages)) return parentPage;

    for (let page of pages) {
      if (page.id === parentPageId) return page;
      let locatedSubParentPage = findParentPage({ parentPageId, pages: page.pages });
      if (locatedSubParentPage) parentPage = locatedSubParentPage;
    }

    return parentPage;
  }

  useEffect(() => {
    let locatedParentPage = findParentPage({ parentPageId, pages });

    if (locatedParentPage) setParentPage(locatedParentPage);
  }, [pages]);

  return {
    pages: parentPage ? parentPage.pages : [],
  };
};

export default useSubPages;
