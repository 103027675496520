// Libs
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import dayjs from "dayjs";

// Components
import DropDown from "../../ui/dropDown/components/DropDown";
import DatePicker from "../../ui/DatePicker";
import { AlertDecagramIcon } from "mdi-react";
import { format as dateFormat, startOfMonth, endOfMonth, addMonths } from "date-fns";

// Style
import colors from "../../../style/colors";

// Config
import breakpoints from "../../../config/breakpoints";
import styleTypes from "../../../config/styleTypes";

// Utilities
import { parseDate } from "../../../utilities/parse-date";
import getUserLocale from "./../../../utilities/get-user-locale";

// Hooks
import useUserGroups from "../hooks/useUserGroups";

// Actions
import { addToast } from "../../../actions/uiActions";

const FilterSection = (props) => {
  const dispatch = useDispatch();

  const { userGroups, loading: userGroupsLoading } = useUserGroups();

  const user = useSelector((state) => state.auth.user);

  const [filter, setFilter] = useState({
    fromDate: dateFormat(startOfMonth(new Date()), "yyyyMMdd", getUserLocale(user)),
    toDate: dateFormat(addMonths(endOfMonth(new Date()), 1), "yyyyMMdd", getUserLocale(user)),
    userGroupId: undefined,
  });

  useEffect(() => {
    props.onChange(filter);
  }, [filter]);

  function onChangeHandler(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "fromDate" || name === "toDate") {
      value = dayjs(value, "YYYY-MM-DD").format("YYYYMMDD");

      if ((name === "fromDate" && value > toDate) || (name === "toDate" && value < fromDate)) {
        dispatch(
          addToast({
            styleType: styleTypes.neutral,
            title: "Ugyldigt dato-valg",
            content: "Start-datoen skal være før slut-datoen",
            icon: <AlertDecagramIcon />,
          })
        );
        return;
      }
    }

    setFilter({ ...filter, [name]: value });
  }

  const { fromDate, toDate } = filter;
  return (
    <div className={componentStyle()}>
      <div className="filter-wrapper">
        <label>
          Vis fra
          <DatePicker
            onChange={onChangeHandler}
            value={dayjs(fromDate).format("YYYY-MM-DD")}
            name="fromDate"
            defaultDate={parseDate(fromDate, "yyyy-MM-dd")}
            className="filter"
          />
        </label>
        <label>
          Vis til
          <DatePicker
            onChange={onChangeHandler}
            value={dayjs(toDate).format("YYYY-MM-DD")}
            name="toDate"
            defaultDate={parseDate(toDate, "yyyy-MM-dd")}
            className="filter"
          />
        </label>
        <label>
          Afdeling
          <DropDown
            loading={userGroupsLoading}
            onChange={onChangeHandler}
            name="userGroupId"
            options={userGroups}
            className="filter"
            usePlaceholder={true}
            placeholder={"Vælg afdeling"}
          />
        </label>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  top: 0;
  width: 100%;
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--midGrey);

  div.filter-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${breakpoints.md}px;
    flex-wrap: wrap;

    label {
      flex: 1 1 170px;
      margin: 0.5rem;

      .filter {
        margin-top: 0.5rem;

        label {
          margin: 0;
        }
      }
    }
  }
`;

export default FilterSection;
