import {
  GET_CURRENT_ROOM,
  GET_CURRENT_ROOM_SUCCESS,
  GET_CURRENT_ROOM_FAILURE,
  REMOVE_ROOM,
  SET_SOCKET,
  REMOVE_SOCKET,
  SET_ONLINE_USERS,
  GET_CHAT_ROOMS,
  GET_CHAT_ROOMS_SUCCESS,
  GET_CHAT_ROOMS_FAILURE,
  UPDATE_CHAT_ROOM_IN_CHAT_LIST,
  REMOVE_CHAT_ROOMS,
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_CHAT_MESSAGES,
  UPDATE_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_FAILURE,
  REMOVE_USERS,
} from "../actions/actionTypes";

const initialState = {
  socket: null,
  onlineUsers: [],
  usersList: {
    users: [],
    loading: true,
    error: false,
    endOfFeed: false,
  },
  chatRooms: {
    chatRooms: [],
    loading: true,
    error: false,
    endOfFeed: false,
  },
  currentChatRoom: {
    chatRoom: {},
    loading: true,
    error: false,
  },
  messages: {
    messages: [],
    loading: false,
    error: false,
    offset: 0,
    endOfFeed: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ROOM:
      return {
        ...state,
        currentChatRoom: {
          ...state.currentChatRoom,
          loading: true,
          error: false,
        },
      };

    case GET_CURRENT_ROOM_SUCCESS:
      return {
        ...state,
        currentChatRoom: {
          chatRoom: action.payload.chatRoom,
          loading: false,
          error: false,
        },
      };

    case GET_CURRENT_ROOM_FAILURE:
      return {
        ...state,
        currentChatRoom: {
          ...state.currentChatRoom,
          loading: false,
          error: true,
        },
      };

    case REMOVE_ROOM:
      return {
        ...state,
        currentChatRoom: {
          ...initialState.currentChatRoom,
        },
        messages: {
          messages: [],
          loading: false,
          error: false,
          offset: 0,
          endOfFeed: false,
        },
      };

    case SET_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };

    case REMOVE_SOCKET:
      return {
        ...state,
        socket: initialState.socket,
      };

    case SET_ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.payload,
      };

    case GET_CHAT_ROOMS:
      return {
        ...state,
        chatRooms: {
          ...state.chatRooms,
          loading: true,
          error: false,
        },
      };

    case GET_CHAT_ROOMS_SUCCESS:
      return {
        ...state,
        chatRooms: {
          chatRooms: action.payload.chatRooms,
          loading: false,
          error: false,
          endOfFeed: action.payload.endOfFeed,
        },
      };

    case GET_CHAT_ROOMS_FAILURE:
      return {
        ...state,
        chatRooms: {
          ...state.chatRooms,
          loading: false,
          error: true,
        },
      };

    case UPDATE_CHAT_ROOM_IN_CHAT_LIST:
      return {
        ...state,
        chatRooms: {
          ...state.chatRooms,
          chatRooms: action.payload,
        },
      };

    case REMOVE_CHAT_ROOMS:
      return {
        ...state,
        chatRooms: {
          ...initialState.chatRooms,
        },
      };

    case GET_USERS_STARTED:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          loading: true,
          error: false,
        },
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          users: action.payload.users,
          loading: false,
          error: false,
          endOfFeed: action.payload.endOfFeed,
        },
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          error: true,
        },
      };

    case REMOVE_USERS:
      return {
        ...state,
        usersList: {
          ...initialState.usersList,
        },
      };

    case GET_CHAT_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
          error: false,
        },
      };

    case GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          messages: action.payload.groupedMessages,
          offset: state.messages.offset + action.payload.offset,
          loading: false,
          error: false,
          endOfFeed: action.payload.endOfFeed,
        },
      };

    case GET_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
          error: true,
        },
      };

    case UPDATE_CHAT_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          messages: action.payload,
          offset: state.messages.offset + 1,
        },
      };

    default:
      return state;
  }
}
