import markdownParser from "./markdown-parser";
import regexEscape from "./regex-escape";

export default function ({ content = "", searchTerm = "", maxLength = 100, removeMarkdownFormatting = true }) {
  // Type cast to be sure
  content = String(content);
  searchTerm = String(searchTerm);
  maxLength = parseInt(maxLength);

  // Removes all markdown formatting to prevent characters like "**" and "_" to be shown in the excerpt
  if (removeMarkdownFormatting === true) {
    content = markdownParser(content, { config: { ALLOWED_TAGS: [] } }).__html;
    content = content.replace(/&nbsp;/gim, " ");
  }

  // If content is shorter than maxLength just return it as is
  if (content.length < maxLength) return content;

  const REGEXP = new RegExp(regexEscape(String(searchTerm)), "igm");
  const MATCH_INDEX = content.search(REGEXP);
  let excerpt = "";

  // If match is in the start or isn't found return content sliced to maxLength from 0
  if (MATCH_INDEX === 0 || MATCH_INDEX === -1) {
    excerpt = content.slice(0, maxLength);
    if (excerpt.length < content.length) excerpt = `${excerpt.trim()}...`;
  }

  // If match is in end return end "..." + content sliced maxLength from end
  else if (MATCH_INDEX === MATCH_INDEX + searchTerm.length) {
    excerpt = content.slice(maxLength * -1);
    if (excerpt.length < content.length) excerpt = `...${excerpt.trim()}`;
  }

  // If match is in the middle return 1/3 of maxLenght before and 2/3 of maxlength after
  else if (MATCH_INDEX !== 0 && MATCH_INDEX !== MATCH_INDEX + searchTerm.length) {
    // This should be 25% into the text OR 0
    let excerptStart = MATCH_INDEX - Math.round(maxLength * 0.25);

    if (excerptStart < 0) {
      excerptStart = 0;
    }

    excerpt = content.slice(
      excerptStart, // Percent of maxlength to go in front of match
      excerptStart + maxLength // Percent of maxlength to go after match
    );
    if (excerptStart === 0 && excerpt.length < content.length) {
      excerpt = `${excerpt.trim()}...`;
    } else if (excerpt.length < content.length) {
      excerpt = `...${excerpt.trim()}...`;
    }
  }

  return excerpt;
}
