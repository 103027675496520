import req from "../../utilities/request-utility";

async function trackAction({ action }) {
  try {
    await req().put(`tracking/${action}`);
  } catch (err) {
    console.error(err);
  }
}

export default trackAction;
