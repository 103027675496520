import React from "react";
import TextInput from "../../../../ui/TextInput";

const NativeLinkContent = ({ page, onChangeHandler }) => {
  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">URL til native linke</p>
        <p className="hint">{`Et native link URL har formatted: [app name]://[action]. Eksempelvis "ekkoapp://native_app"`} </p>
        <TextInput
          data-test-id={"external-link-input"}
          value={page?.meta?.URL}
          placeholder={"Indtast URL"}
          name="URL"
          onChange={(e) =>
            onChangeHandler({
              target: { name: "meta", value: { ...page.meta, [e.target.name]: e.target.value } },
            })
          }
        />
      </div>
    </>
  );
};

export default NativeLinkContent;
