// Libs
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import {
  getCategories,
  getEquipment,
  getMyBookings,
  getMyBookingsCount,
  resetState,
  setEquipmentBookingId,
  setFilterValue,
} from "../../actions/equipmentBookingActions";
import InlineSpinner from "../ui/InlineSpinner";
import EquipmentBookingListItem from "./EquipmentBookingListItem";
import { ChevronRightIcon, FilterOutlineIcon } from "mdi-react";
import colors from "../../style/colors";
import { parseDatetime } from "../../utilities/parse-date";
import pageNavigatorV2, { pageTransitions } from "../../utilities/page-navigator-v2";
import getAppName from "../../utilities/get-app-name";
import Notification from "../ui/Notification";
import ActionWrapper from "../ui/ActionWrapper";
import FilterModal from "../ui/FilterModal/FilterModal";
import basicAnimations from "../../style/basic-animations";
import { CSSTransition } from "react-transition-group";
import DropDownList from "../ui/dropDown/DropDownList";
import equipmentStatuses from "./equipmentStatuses";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import common from "../../style/common";
import { SET_EQUIPMENT_SEARCHTERM } from "../../actions/actionTypes";
import { useDebouncedCallback } from "use-debounce/lib";
import format from "date-fns/format";
import navigationHistory from "../../utilities/navigation-history";
import pageConfig from "../../config/pageConfig";

/* Local components */
function AllEquipmentTab({ data, loading, error, page }) {
  const hideEndDate =
    page && page.config && page.config[pageConfig.HIDE_END_DATE] ? page.config[pageConfig.HIDE_END_DATE] : false;

  return (
    <div style={{ padding: "4rem 0" }}>
      {data &&
        data.length > 0 &&
        data.map((equipment) => {
          const isAvailable = equipment.activeBooking && equipment.activeBooking.bookingStartDate ? false : true;

          return (
            <EquipmentBookingListItem
              key={`equipmentListItem-${equipment.id}`}
              title={equipment.title}
              subTitle={equipment.category.title}
              content={
                isAvailable || hideEndDate
                  ? equipment.content
                  : `Ledig: ${parseDatetime(equipment.activeBooking.bookingEndDate)}`
              }
              image={equipment.images && equipment.images.length > 0 ? equipment.images[0] : null}
              isAvailable={isAvailable}
              iconRight={<ChevronRightIcon style={{ fill: colors.darkGrey }} />}
              onClick={() =>
                pageNavigatorV2({
                  path: `/${getAppName()}/equipment-booking/${page.id}/equipment/${equipment.id}`,
                  direction: pageTransitions.forward,
                })
              }
            />
          );
        })}

      {/* loading */}
      {loading && !error && <InlineSpinner />}
    </div>
  );
}

function MyEquipmentTab({ data, loading, error, page }) {
  const hideEndDate =
    page && page.config && page.config[pageConfig.HIDE_END_DATE] ? page.config[pageConfig.HIDE_END_DATE] : false;

  return (
    <div style={{ padding: "1rem 0" }}>
      {data &&
        data.length > 0 &&
        data.map((equipment) => {
          const now = format(new Date(), "yyyyMMddHHmm");
          const isAvailable = equipment.activeBooking && equipment.activeBooking.bookingStartDate > now ? true : false;

          return (
            <EquipmentBookingListItem
              title={equipment.title}
              subTitle={equipment.category.title}
              /** If end date is disabled, don't display it. */
              content={
                isAvailable || hideEndDate
                  ? equipment.content
                  : `Ledig: ${parseDatetime(equipment.activeBooking.bookingEndDate)}`
              }
              image={equipment.images && equipment.images.length > 0 ? equipment.images[0] : null}
              isAvailable={isAvailable}
              iconRight={<ChevronRightIcon style={{ fill: colors.darkGrey }} />}
              onClick={() =>
                pageNavigatorV2({
                  path: `/${getAppName()}/equipment-booking/${page.id}/equipment/${equipment.id}`,
                  direction: pageTransitions.forward,
                })
              }
            />
          );
        })}

      {/* loading */}
      {loading && !error && <InlineSpinner />}
    </div>
  );
}

function EquipmentBookingOverview(props) {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(false);

  // page setup
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);

  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const { allEquipment, myBookings, filters, categories, searchterm } = useSelector((s) => s.equipmentBooking);

  // UI Layout
  const tabs = [
    "Udstyr",
    <>
      <Notification notifications={myBookings.count} /> Mine bookninger
    </>,
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(resetState());
    dispatch(setEquipmentBookingId(page.dataId));
    getDataByActiveTab();
    dispatch(getMyBookingsCount());
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    dispatch(getEquipment());
  }, [filters.bookingStatus, filters.categoryId]);

  useEffect(() => {
    debouncedGetEquipment();
  }, [searchterm]);

  const [debouncedGetEquipment] = useDebouncedCallback(() => {
    dispatch(getEquipment());
  }, 300);

  function getDataByActiveTab() {
    if (activeTab === 0) dispatch(getEquipment());
    if (activeTab === 1) dispatch(getMyBookings());
  }

  function isScrollViewFilled() {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view.tab");
      if (
        currentFeed &&
        currentFeed.scrollHeight <= currentFeed.clientHeight &&
        !allEquipment.error &&
        !allEquipment.loading
      ) {
        getDataByActiveTab();
      }
    }, 100); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly
  }

  function getActiveFiltersCount() {
    let count = 0;
    if (filters.bookingStatus !== "all") count++;
    if (filters.categoryId !== "all") count++;
    return count;
  }

  function handleClearFilters() {
    dispatch(setFilterValue({ key: "bookingStatus", value: "all" }));
    dispatch(setFilterValue({ key: "categoryId", value: "all" }));
  }

  useLayoutEffect(() => {
    setTimeout(() => isScrollViewFilled(), 300);
  }, [allEquipment, activeTab]);

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          <CSSTransition
            in={activeTab === 0}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={basicAnimations.fade}
          >
            <ActionWrapper style={{ position: "relative" }} onClick={() => setShowFilters(!showFilters)}>
              <FilterOutlineIcon />
              <Notification
                style={{ position: "absolute", top: "2px", right: "4px" }}
                notifications={getActiveFiltersCount()}
              />
            </ActionWrapper>
          </CSSTransition>
        }
      />
      <TabBar tabs={tabs.map((t, i) => ({ title: t, onClick: () => setActiveTab(i) }))} activeTabIndex={activeTab} />
      <TabView
        activeTabIndex={activeTab}
        onScrollEnd={getDataByActiveTab}
        tabs={[<AllEquipmentTab {...allEquipment} page={page} />, <MyEquipmentTab {...myBookings} page={page} />]}
      />

      <CSSTransition
        in={activeTab === 0}
        timeout={900}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames={basicAnimations.fadeAndMoveLeft}
      >
        <FloatingSearchInput
          style={{ top: `calc(${common.topBarHeight * 2 + 12}px + env(safe-area-inset-top))` }}
          onChange={(e) => dispatch({ type: SET_EQUIPMENT_SEARCHTERM, payload: e.target.value })}
          value={searchterm}
          searching={allEquipment.loading}
          onClearSearch={() => dispatch({ type: SET_EQUIPMENT_SEARCHTERM, payload: "" })}
        />
      </CSSTransition>

      <FilterModal active={showFilters} hideFilters={() => setShowFilters(false)} onClearFiltersClick={handleClearFilters}>
        <>
          <label>Status</label>
          <DropDownList
            style={{ marginBottom: "1.5rem" }}
            dropDownListContent={[
              { id: equipmentStatuses.all, title: "Både ledige og udlånte" },
              { id: equipmentStatuses.booked, title: "Udlånt" },
              { id: equipmentStatuses.available, title: "Ledig" },
            ]}
            selectedContentId={filters.bookingStatus}
            value={filters.bookingStatus}
            name="bookingStatus"
            onChange={(e) => {
              dispatch(setFilterValue({ key: e.target.name, value: e.target.value }));
            }}
          />
          <label>Kategori</label>
          <DropDownList
            dropDownListContent={[{ id: "all", title: "Alle kategorier" }, ...categories]}
            selectedContentId={filters.categoryId}
            value={filters.categoryId}
            name="categoryId"
            onChange={(e) => {
              dispatch(setFilterValue({ key: e.target.name, value: e.target.value }));
            }}
          />
        </>
      </FilterModal>
    </Page>
  );
}

export default EquipmentBookingOverview;
