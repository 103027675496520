// Libraries
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { css } from "emotion";
import { CSSTransition } from "react-transition-group";

// Styling
import breakpoints from "../../../config/breakpoints";
import { durations } from "../../../config/animations";

// Components
import ScrollRequiredMarkdown from "./content/common/ScrollRequiredMarkdown";
import Overlay from "../Overlay";
import DynamicIcon from "../Icons/DynamicIcon";
import Button from "../Button";
import StatusBox from "../StatusBox";

// Utilities
import req from "../../../utilities/request-utility";
import ButtonRounded from "../ButtonRounded";

const Dialog = ({ dialog, active, onSubmitAction }) => {
  const { primaryColor } = useSelector((state) => state.appConfig);

  const auth = useSelector((state) => state.auth);

  // Any retrieved data from the content component
  const [feedback, setFeedback] = useState(null);

  const [submitting, setSubmitting] = useState(null);
  const [errored, setErrored] = useState(false);

  const lang = useSelector((s) => s.language.language);

  async function submitAction(action) {
    setSubmitting(action);

    try {
      const response = await req()("dialogs/action", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          actionId: action.id,
          data: feedback,
        }),
      });
      onSubmitAction(action);
      setSubmitting(null);
      if (response?.data && response?.data?.intention === "GET_DEEP_LINK_TO_IOS_APP_STORE") {
        window.location = response.data?.deeplink; // Window.open doesn't work. Dunno why. window.location does 🤷‍♂️
      }
    } catch (e) {
      setErrored(true);
      setSubmitting(false);
    }
  }

  function parseTextContent(text) {
    // Match all instances of {{variable}}
    const matches = text.match(/{{(.*?)}}/g);

    if (matches === null) {
      return text;
    }

    let parsedText = text;

    matches.forEach((match) => {
      // Switch on the variable name
      switch (match) {
        case "{{USER_FULLNAME}}":
          parsedText = parsedText.replace(match, auth.user.name);
          break;
        case "{{USER_EXTERNAL_ID}}":
          parsedText = parsedText.replace(match, auth.user.externalId);
          break;
        default:
          parsedText = parsedText.replace(match, "");
          break;
      }
    });

    return parsedText;
  }

  function getContent(content) {
    if (!content) return <div></div>;

    if (content.type === "SCROLL_REQUIRED_TEXT") {
      return (
        <ScrollRequiredMarkdown
          className={"content"}
          onFeedback={setFeedback}
          text={parseTextContent(content.text)}
        ></ScrollRequiredMarkdown>
      );
    }

    if (content.type === "TEXT") {
      return <div dangerouslySetInnerHTML={markdownParser(content.text)}></div>;
    }
  }

  return (
    <div className={componentStyle({ primaryColor, active })}>
      <Overlay active={active} style={{ zIndex: 100 }} />
      <CSSTransition in={active} classNames={"dialog-container"} timeout={300} mountOnEnter={true} unmountOnExit={true}>
        <div className={"dialog-container"}>
          <div className={"dialog"}>
            <div className={"header"}>
              <h1>{dialog?.title}</h1>
              {dialog?.actions.find((action) => action.type === "DISMISS") && !errored && (
                <button onClick={() => submitAction(dialog.actions.find((action) => action.type === "DISMISS"))}>
                  <DynamicIcon icon={"mdi:close"} />
                </button>
              )}
            </div>
            {!errored && (
              <>
                {getContent(dialog?.content)}
                <div className={"actions"}>
                  {dialog?.actions.map((action) => {
                    if (action.type === "PRIMARY_BUTTON") {
                      return (
                        <ButtonRounded
                          key={action.id}
                          disabled={!feedback?.satisfied || submitting !== null}
                          onClick={() => submitAction(action)}
                          active={submitting?.id === action.id}
                        >
                          {action.label}
                        </ButtonRounded>
                      );
                    }
                    if (action.type === "SECONDARY_BUTTON") {
                      return (
                        <ButtonRounded
                          buttonType={"secondary"}
                          secondary
                          key={action.id}
                          disabled={!feedback?.satisfied || submitting !== null}
                          onClick={() => submitAction(action)}
                          active={submitting?.id === action.id}
                        >
                          {action.label}
                        </ButtonRounded>
                      );
                    }
                  })}
                </div>
              </>
            )}
            {errored && (
              <div className={"error"}>
                <StatusBox></StatusBox>
                <Button buttonType={"error"} onClick={() => onSubmitAction("error")}>
                  {lang.ok}
                </Button>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const componentStyle = (props) => css`
  position: absolute;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;

  justify-content: center;
  align-items: center;

  pointer-events: ${props.active ? "all" : "none"};

  .dialog-container {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1000;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: clip;

    @media screen and (min-width: ${breakpoints.xs}px) {
      padding: 1rem;
    }

    &.dialog-container-enter {
      opacity: 0;
      transform: translate3d(0, 2.5%, 0);
    }

    &.dialog-container-enter-active {
      transform: translate3d(0, 0%, 0);
      opacity: 1;
      transition: transform ${durations.fast}ms ease, opacity ${durations.fast}ms;
    }

    &.dialog-container-exit {
      opacity: 1;
    }

    &.dialog-container-exit-active {
      opacity: 0;
      transform: translate3d(0, 2.5%, 0);
      transition: transform ${durations.normal}ms ease, opacity ${durations.normal}ms;
    }
  }

  .dialog {
    width: 100%;
    height: 100%;

    overflow: clip;

    @media screen and (min-width: ${breakpoints.xs}px) {
      max-width: ${breakpoints.md}px;
      max-height: 100%;
      border-radius: 5px;
      height: auto;
    }

    display: grid;

    grid-template-rows: fit-content(100%) 1fr;

    background-color: white;

    .header {
      display: flex;

      button {
        background: none;
        border: none;
        height: 100%;
        aspect-ratio: 1;
        align-self: center;
        color: var(--darkGrey);

        :hover {
          cursor: pointer;
          background: var(--lightGrey);
        }

        :active {
          background: var(--midGrey);
        }
      }

      h1 {
        font-size: 1.5rem;
        padding: 1rem;
        flex-grow: 1;

        font-weight: bold;

        color: ${props.primaryColor};
      }
    }

    .content {
      overflow: auto;

      p {
        margin: 1rem;
        color: var(--black);
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .actions {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;

      gap: 0.5rem;

      button {
        width: 100%;

        @media screen and (min-width: ${breakpoints.xs}px) {
          padding: 0.5rem 1rem;
          width: auto;
        }
      }
    }

    .error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      max-width: ${breakpoints.xs}px;
      margin: 0 auto;
      padding: 1rem;
    }
  }
`;

export default Dialog;
