// Libs
import React from "react";
import { useDispatch } from "react-redux";
import * as queryString from "query-string";

// Components
import RegistrationForm from "../../timeRegistration/components/RegistrationForm";

// Config
import formMode from "../../timeRegistration/config/formMode";

// Style
import colors from "../../../style/colors";

// Actions
import { hideModalPage, showModalPage } from "../../../actions/uiActions";
import { getTimeSheets, setSelectedDate, setSelectedUser } from "../../../actions/timeRegistrationActions";
import { getRegistrationForm } from "../../../actions/registrationActions";

export default function UseHandleEdit() {
  const dispatch = useDispatch();

  const handleEdit = ({ user, timeSheet, callback = () => {} }) => {
    let timeRegistrationId = queryString.parse(window.location.search).dataId;

    dispatch(() => {
      dispatch(setSelectedUser(user));
      dispatch(getRegistrationForm({ registrationId: timeRegistrationId, postId: timeSheet.id }));
      dispatch(setSelectedDate(timeSheet.timeRegistrationData.date));
      dispatch(
        showModalPage({
          title: `Rediger - ${timeSheet.timeRegistrationData.date} - ${timeSheet.user.name}`,
          content: (
            <RegistrationForm
              hideTotalHours={true}
              postId={timeSheet.id}
              mode={formMode.edit}
              onSubmitCallback={() => {
                dispatch(setSelectedUser(""));
                dispatch(getTimeSheets({ timeRegistrationId }));
                callback();
              }}
            />
          ),
          useScrollView: false,
          pageStyle: { backgroundColor: colors.white },
          closeCallback: () => {
            dispatch(setSelectedUser(""));
            dispatch(hideModalPage());
          },
        })
      );
    });
  };

  return handleEdit;
}
