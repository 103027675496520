import axios, { AxiosError } from "axios";
import req from "../utilities/request-utility";
import {
  ADD_FILE,
  BEGIN_UPLOAD_FILE,
  REMOVE_MEDIA,
  ROTATE_MEDIA,
  SET_MEDIA_INDEX,
  SET_MEDIA_TOKEN,
  SET_MEDIA_UPLOAD_PROGRESS,
  SET_MEDIA_URL,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
} from "./actionTypes";

export function getToken() {
  return async function (dispatch) {
    const { data: token } = await req()(`media/token`);

    dispatch({
      type: SET_MEDIA_TOKEN,
      payload: token,
    });
  };
}

export function rotateRight(mediaId) {
  return async function (dispatch, getState) {
    const { files } = getState().mediaUpload;

    /** @type { import("../reducers/mediaUploadReducer").MediaUploadFile } */
    const media = files.find((file) => file.id === mediaId);

    let rotation = parseInt(media.url?.match(/a_(\d+)/)?.[1]);

    if (rotation) {
      if (rotation === 270) {
        rotation = 0;
      } else {
        rotation = (rotation + 90) % 360;
      }
    } else if (!rotation) {
      rotation = 90;
    }

    const transformation = rotation ? `a_${rotation}/` : "";

    const url = "https://res.cloudinary.com/toecho/image/upload/" + transformation + media.id + ".jpg";

    dispatch({
      type: SET_MEDIA_URL,
      payload: {
        id: media.id,
        url,
      },
    });
  };
}

export function rotateLeft(mediaId) {
  return async function (dispatch, getState) {
    const { files } = getState().mediaUpload;

    /** @type { MediaUploadFile } */
    const media = files.find((file) => file.id === mediaId);

    let rotation = parseInt(media.url?.match(/a_(\d+)/)?.[1]);

    if (rotation) {
      if (rotation === 0) {
        rotation = 270;
      } else {
        rotation = (rotation - 90) % 360;
      }
    } else if (!rotation) {
      rotation = 270;
    }

    const transformation = rotation ? `a_${rotation}` : "";

    const url = "https://res.cloudinary.com/toecho/image/upload/" + transformation + "/" + media.id + ".jpg";

    dispatch({
      type: SET_MEDIA_URL,
      payload: {
        id: media.id,
        url,
      },
    });
  };
}

export function moveRight(media) {
  return async function (dispatch, getState) {
    // We want to change the order within the same type of media
    // so we get the index of all files of all files as they are ordered
    // in the state, and then we filter out the files that are not of the same type
    const filesWithIndex = getState()
      .mediaUpload.files.map((file, index) => {
        return {
          ...file,
          index,
        };
      })
      .filter((file) => file.type === media.type);

    const currentFile = filesWithIndex.find((file) => file.id === media.id);

    if (filesWithIndex.indexOf(currentFile) === filesWithIndex.length - 1) {
      return;
    }

    const nextFile = filesWithIndex[filesWithIndex.indexOf(currentFile) + 1];

    dispatch({
      type: SET_MEDIA_INDEX,
      payload: {
        index: nextFile.index,
        file: currentFile,
      },
    });
  };
}

export function moveLeft(media) {
  return async function (dispatch, getState) {
    // We want to change the order within the same type of media
    // so we get the index of all files of all files as they are ordered
    // in the state, and then we filter out the files that are not of the same type
    const filesWithIndex = getState()
      .mediaUpload.files.map((file, index) => {
        return {
          ...file,
          index,
        };
      })
      .filter((file) => file.type === media.type);

    const currentFile = filesWithIndex.find((file) => file.id === media.id);

    if (filesWithIndex.indexOf(currentFile) === 0) {
      return;
    }

    const previousFile = filesWithIndex[filesWithIndex.indexOf(currentFile) - 1];

    dispatch({
      type: SET_MEDIA_INDEX,
      payload: {
        index: previousFile.index,
        file: currentFile,
      },
    });
  };
}

/**
 *
 * @param {import("../reducers/mediaUploadReducer").MediaUploadFile} media
 */
export function addPreExistingMedia(media) {
  return async function (dispatch) {
    dispatch({
      type: ADD_FILE,
      payload: media,
    });
  };
}

/**
 *
 * @param {File} file
 * @returns
 */
export function queueFile(file) {
  return async function (dispatch, getState) {
    /** @type { import("../reducers/mediaUploadReducer").MediaUploadState } */
    const mediaUpload = getState().mediaUpload;

    const { token, queue, context } = mediaUpload;

    const aborter = new AbortController();

    if (file.type.startsWith("image/")) {
      const { data } = await req().post(`media/image/signature`, { token, context });

      const media = {
        id: data.public_id,
        signature: data.signature,
        parameters: data.parameters,
        file,
        state: "pending",
        aborter: aborter,
        type: "image",
      };

      dispatch({
        type: ADD_FILE,
        payload: media,
      });

      queue.add(async () => await upload(media, dispatch));
    }

    if (file.type.startsWith("video/")) {
      const { data } = await req().post(`media/video/signature`, { token, context });

      const media = {
        id: data.public_id,
        signature: data.signature,
        parameters: data.parameters,
        file,
        state: "pending",
        aborter: aborter,
        type: "video",
      };

      dispatch({
        type: ADD_FILE,
        payload: media,
      });

      queue.add(async () => await upload(media, dispatch));
    }
  };
}

/**
 * @param {import("../reducers/mediaUploadReducer").MediaUploadFile} media
 */
export function removeMedia(mediaId) {
  return async function (dispatch, getState) {
    /** @type { MediaUploadState } */
    const mediaUpload = getState().mediaUpload;

    const media = mediaUpload.files.find((file) => file.id === mediaId);

    if (media.state === "uploading") {
      media.aborter.abort();
    }

    if (media.state === "uploaded" && media.file.type.startsWith("image/")) {
      try {
        axios.post(`https://api.cloudinary.com/v1_1/toecho/delete_by_token`, {
          token: media.cld_delete_token,
        });
      } catch (error) {}
    }

    // Videos are async, so cloudinary doesn't return the delete token
    // Instead, all uploaded media will have the 'unhandled' tag
    // untill it is removed (Should be done when the post is saved)
    //
    // A script could be made to remove all unhandled media that is older than 24 hours
    //
    // TODO: Remove unhandled media after 24 hours

    dispatch({
      type: REMOVE_MEDIA,
      payload: media.id,
    });
  };
}

/**
 * @param {import("../reducers/mediaUploadReducer").MediaUploadFile} file
 */
async function upload(file, dispatch) {
  try {
    dispatch({
      type: BEGIN_UPLOAD_FILE,
      payload: file.id,
    });

    const { signature, parameters } = file;
    const type = file.file.type.startsWith("image/") ? "image" : "video";

    const data = new FormData();
    data.append("file", file.file);
    data.append("signature", signature);

    Object.entries(parameters).forEach(([key, value]) => {
      data.append(key, value);
    });

    const chunks = split(file.file);

    let res;

    let uploadedChunkCount = 0;

    for (const chunk of chunks) {
      res = await send(chunk, `https://api.cloudinary.com/v1_1/toecho/${type}/upload`, file.aborter);

      uploadedChunkCount++;

      dispatch({
        type: SET_MEDIA_UPLOAD_PROGRESS,
        payload: {
          id: file.id,
          progress: uploadedChunkCount / chunks.length,
        },
      });
    }

    dispatch({
      type: UPLOAD_FILE_SUCCESS,
      payload: {
        id: file.id,
        cld_delete_token: res.data.delete_token,
      },
    });
  } catch (error) {
    if (error.code !== "ERR_CANCELED") {
      dispatch({
        type: UPLOAD_FILE_FAILURE,
        payload: file.id,
      });
    }
  }

  return file;

  /**
   * @param {{ data: Blob, start: number, end: number }} chunk
   * @param {string} url
   * @param {AbortController} aborter
   */
  async function send(chunk, url, aborter) {
    const { signature, parameters } = file;

    const data = new FormData();
    data.append("file", chunk.data);
    data.append("signature", signature);

    Object.entries(parameters).forEach(([key, value]) => {
      data.append(key, value);
    });

    return await axios.post(url, data, {
      headers: {
        "X-Unique-Upload-Id": file.id,
        "Content-Range": `bytes ${chunk.start}-${chunk.end - 1}/${file.file.size}`,
      },
      signal: aborter.signal,
    });
  }

  /**
   * Split the file into chunks
   * @param {File} file
   */
  function split(file) {
    if (file.size < 10000000) {
      return [{ data: file, start: 0, end: file.size }];
    }

    const chunks = [];
    let offset = 0;

    while (offset < file.size) {
      const chunk = file.slice(offset, offset + 10000000);
      chunks.push({ data: chunk, start: offset, end: offset + chunk.size });
      offset += 10000000;
    }

    return chunks;
  }
}
