import { UPDATE_PAGE_BUILDER_FILTER_PAGES_BY, UPDATE_PAGE_BUILDER_SHOW_OPTIONS } from "./actionTypes";

export function updatePageFilter(filter, value) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_PAGE_BUILDER_FILTER_PAGES_BY,
      payload: { filter, value },
    });
  };
}

export function showOptionsHandler(value) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_PAGE_BUILDER_SHOW_OPTIONS,
      payload: value,
    });
  };
}
