// Libs
import React from "react";
import { css } from "emotion";

// Components
import { ChevronDownIcon, ChevronUpIcon, DragIcon, FolderOutlineIcon, WebIcon, AccountGroupIcon } from "mdi-react";

// Styles
import colors from "../../../../style/colors";

// Hooks
import useIcon from "../../../../hooks/useIcon";

// Context
import { PageBuilderContext } from "../../context/PageBuilderContext";
import getTargetGroupPlaceholder from "../../utilities/getTargetGroupPlaceholder";
import { useSelector } from "react-redux";

const PageListItemHeader = (props) => {
  const { language: lang } = useSelector((state) => state.language);

  const { title, module, icon, active, targetGroup } = props.page;

  const { icon: fetchedIcon } = useIcon({ iconName: icon });

  function getTargetGroupsCount() {
    const { users, userGroups, masterGroups, customGroups } = targetGroup;

    let count = 0;

    count += users?.length || 0;
    count += userGroups?.length || 0;
    count += masterGroups?.length || 0;
    count += customGroups?.length || 0;

    return count;
  }

  return (
    <PageBuilderContext.Consumer>
      {({ disableDraggableAction }) => (
        <div className={`${componentStyle()}`}>
          <div {...props.dragHandleProps} style={{ display: disableDraggableAction ? "none" : "unset" }}>
            <DragIcon className="drag-icon" />
          </div>

          <div className="page-content">
            <div className="section-wrapper page-title">
              <div className="icon-wrapper">{fetchedIcon}</div>
              <p>{title || ""}</p>
              <div className={`online-status ${active ? "on" : "off"}`}>
                <WebIcon />
              </div>
            </div>
            <div className="section-wrapper page-type">
              <div className="sub-options first">
                <FolderOutlineIcon />
                <p data-test-id={"module-type"}>{module || ""}</p>
              </div>

              <div className="sub-options">
                <AccountGroupIcon />
                <p className="target-group">
                  {getTargetGroupPlaceholder({
                    targetGroup,
                    noUsersSelectedOverwrite: `${lang.all} ${lang.users?.toLowerCase()}`,
                  })}
                </p>
              </div>
            </div>
          </div>

          {!props.expanded && <ChevronDownIcon />}
          {props.expanded && <ChevronUpIcon />}
        </div>
      )}
    </PageBuilderContext.Consumer>
  );
};

const componentStyle = () => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.65rem 0.5rem 0.65rem 0.65rem;

  svg.drag-icon {
    height: 1.75rem;
    width: 1.75rem;
  }

  .page-content {
    flex: 1;
    overflow: hidden;
    flex-direction: column;
    margin-left: 0.5rem;

    & > .section-wrapper {
      display: flex;
      align-items: center;

      .online-status {
        display: flex;
        align-items: center;

        svg {
          height: 1.25rem;
          width: 1.25rem;
          margin-left: 0.25rem;
        }

        &.on {
          color: var(--green);
        }

        &.off {
          color: var(--midGrey);
        }
      }

      div.icon-wrapper {
        width: 1.125rem;
        height: 1.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.page-title {
        svg {
          width: 1.125rem;
          height: 1.125rem;
        }

        p {
          margin-left: 0.35rem;
          font-size: 1rem;
        }
      }

      &.page-type {
        margin-top: 0.25rem;

        svg {
          margin-left: 0.1rem;
          height: 0.9rem;
          width: 0.9rem;
        }

        p {
          color: ${colors.midDarkGrey};
          margin-left: 0.35rem;
          font-size: 0.85rem;
        }
      }

      .sub-options {
        overflow: auto;
        
        &.first {
          flex-shrink: 0;
        }

        svg {
          flex-shrink: 0;
        }

        &:first-of-type {
          margin-left: 0;
        }

        p.target-group {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        display: flex;
        align-items: center;
        margin-left: 0.75rem;
      }
    }
  }
`;

export default PageListItemHeader;
