import React from "react";
import { LockIcon } from "mdi-react";
import { css } from "emotion";

const PhoneLogin = ({ onClick, password = "", active, hide }) => {
  return (
    <div className={`input password ${componentStyles()} ${hide ? "hide" : ""}`} onClick={onClick}>
      <LockIcon className="lock-icon login-type-icon" />
      <div className="password-dots-container">
        <div
          className={
            (password[0] ? "password-dot filled " : "password-dot ") + (active && password.length === 0 ? "blink" : "")
          }
        />
        <div
          className={
            (password[1] ? "password-dot filled " : "password-dot ") + (active && password.length === 1 ? "blink" : "")
          }
        />
        <div
          className={
            (password[2] ? "password-dot filled " : "password-dot ") + (active && password.length === 2 ? "blink" : "")
          }
        />
        <div
          className={
            (password[3] ? "password-dot filled " : "password-dot ") + (active && password.length === 3 ? "blink" : "")
          }
        />
      </div>

      <br />
    </div>
  );
};

const componentStyles = () => css`
  &.hide {
    opacity: 0;
    max-height: 0px;
    transition: opacity 400ms ease, max-height 400ms ease;
  }

  opacity: 1;
  max-height: 60px;
  transition: opacity 400ms ease, max-height 400ms ease;
`;

export default PhoneLogin;
