import store from "../../../../store";
import { showModalPage, updateModalPage } from "../../../../actions/uiActions";
import { groupTypes } from "../config/groupTypes";
import req from "../../../../utilities/request-utility";
import InlineSpinner from "../../InlineSpinner";
import ListItem from "../../ListItem";
import colors from "../../../../style/colors";
import getProfilePictureFromUserObject from "../../../../utilities/get-profile-picture-from-user-object";
import breakpoints from "../../../../config/breakpoints";
import StatusBox from "../../StatusBox";
import { InformationOutlineIcon } from "mdi-react";

async function getMembers({ type, id, targetGroup }) {
  if (type === groupTypes.userGroups) {
    let { data } = await req()(`user-groups/${id}/users`);
    return data;
  }

  if (type === groupTypes.masterGroups) {
    let { data } = await req()(`master-groups/${id}/users`);
    return data;
  }

  if (type === groupTypes.customGroups) {
    let { data } = await req()(`activity-groups/${id}/users`);
    return data;
  }

  if (type === groupTypes.jobTitles) {
    let { data } = await req()(`job-titles/${id}/users`);
    return data;
  }
  if (type === groupTypes.targetGroup) {
    let { data } = await req().post(`target-groups/utilities/members`, targetGroup);
    return data;
  }
}

function getSubTitle({ user }) {
  let titleFragments = [];

  if (user.userGroup) titleFragments.push(user.userGroup.title);
  if (user.masterGroup) titleFragments.push(user.masterGroup.title);
  if (user.jobTitle) titleFragments.push(user.jobTitle);

  return titleFragments.join(", ");
}

export default async function showGroupMembers({ type, id, title, clickEvent = false, authorModal, targetGroup = {} }) {
  if (clickEvent) clickEvent.stopPropagation();

  const lang = store.getState().language.language;

  store.dispatch(
    showModalPage({
      title,
      content: (
        <>
          <InlineSpinner size="26" style={{ marginTop: "2rem" }} />{" "}
          <p style={{ textAlign: "center", color: "var(--darkGrey)" }}>
            {lang.loading} {lang.users.toLowerCase()}...
          </p>
        </>
      ),
    })
  );

  let members = await getMembers({ id, type, targetGroup });

  store.dispatch(
    updateModalPage({
      content: (
        <div style={{ padding: "1rem 0" }}>
          {members.length === 0 && <StatusBox title=" " icon={<InformationOutlineIcon />} content={lang.noUsersFound} />}

          {members.map((user) => (
            <ListItem
              style={{ border: `1px ${colors.midGrey} solid` }}
              imageLeft={getProfilePictureFromUserObject(user, undefined, {
                marginRight: "0.65rem",
                verticalAlign: "middle",
              })}
              title={user.name}
              maxWidth={breakpoints.md}
              subTitle={getSubTitle({ user })}
              clickable={true}
              onClick={() => authorModal(user.id)}
            />
          ))}
        </div>
      ),
    })
  );
}
