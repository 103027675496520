// Libs
import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addToast } from "../../actions/uiActions";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

// Components
import { UploadIcon, AlertCircleIcon, FileOutlineIcon, TrashCanOutlineIcon, DeleteIcon } from "mdi-react";
import InlineSpinner from "../ui/InlineSpinner";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";
import Image from "./Image";

/** Used for handling a single image/file upload, please note that a POST request is sent to our api,
 *  with the required data, and you should get the response you need, from there. */
class ImageUploadSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
    };
  }

  onFileSelect = (e) => {
    // TODO: Validate file size and type
    let file = e.target.files[0];

    // Check if file is selected
    if (!file) return; // No file is selected

    // Check file size
    if (file.size > 200000000) {
      return this.props.addToast({
        type: "error",
        title: this.props.lang.tooBig,
        content: this.props.lang.fileIsToBigDescription,
        icon: <AlertCircleIcon />,
      });
    } else if (file.size > 39999900) {
      // TO DO! Reimplement with dialog, once that is implemented
      return this.props.addToast({
        type: "info",
        title: this.props.lang.bigFileDetected,
        content: this.props.lang.bigFileDetectedDescription,
        icon: <AlertCircleIcon />,
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    this.uploadFile(formData);
    this.setState({
      image: { status: "uploading", progress: 0 },
    });

    // Reset input
    e.target.value = "";
  };

  uploadFile = (file) => {
    req()
      .post("/images/", file, { onUploadProgress: (e) => this.onUploadProgress(e) })
      .then(({ data }) => data)
      .then((data) => this.onFileUploadSuccess(data))
      .catch((err) => this.onFileUploadFailure(err));
  };

  deleteFile = (index) => {
    // let files = [...this.state.files];
    // files[index].status = "deleted";
    // this.setState({
    //   files: files
    // });
    // this.props.onFileUpdate(this.state.files.filter(f => f.status === "uploaded"));
  };

  onUploadProgress = (progress) => {
    let progressPercent = (progress.loaded / progress.total) * 100;
    this.setState({
      image: {
        ...this.state.image,
        progress: progressPercent,
      },
    });
  };

  onFileUploadSuccess = (res) => {
    this.setState({
      image: {
        status: "uploaded",
        baseURL: res.baseURL,
        image: res.image,
        deleteToken: res.deleteToken,
      },
    });
    this.props.onFile({ file: res.image, baseURL: res.baseURL, deleteToken: res.deleteToken });
  };

  onFileUploadFailure = (err, index) => {
    this.props.addToast({
      icon: <AlertCircleIcon />,
      styleType: "error",
      title: this.props.lang.error,
      content: this.props.lang.errorCouldNotUploadImage,
      duration: 10000,
    });

    if (this.state.files[index]) {
      this.setState({
        image: {
          ...this.state.image,
          status: "error",
        },
      });
    }
  };

  componentDidMount() {
    if (this.props.uploadedFile && this.props.uploadedFile.baseURL && this.props.uploadedFile.image)
      this.setState({ image: { ...this.props.uploadedFile, status: "uploaded" } });
  }

  onFileRemove = () => {
    this.props.removeFile();
    this.setState({ image: {} });
  };

  render() {
    let { placeholder, primaryColor, style, boxPadding, showImage = true } = this.props;
    let { image } = this.state;

    return (
      <div
        className={`${componentStyle(primaryColor, boxPadding)} ${this.props.className || ""} ${
          showImage && image.status === "uploaded" ? "image-wrapper" : ""
        }`}
        style={style}
      >
        {image.status === "uploaded" && showImage && (
          <>
            <Image
              className={"uploaded-image " + this.props.imagePreviewClassName || ""}
              src={`${image.baseURL}/${image.image}`}
            />
            <DeleteIcon className="remove-image" onClick={() => this.onFileRemove()} />
          </>
        )}

        {((image.status !== "uploaded" && showImage) || (!image.status && showImage) || !showImage) && (
          <label>
            {/** Default value **/}
            {!image.status && <UploadIcon />}

            {/** Image upload **/}
            {image.status === "uploading" && (
              <InlineSpinner
                size={20}
                style={{
                  borderRight: `1px solid var(--midGrey)`,
                  marginRight: "0.45rem",
                  padding: "0.5rem 0.45rem 0.1rem 0.45rem",
                }}
              />
            )}

            {/** Image uploaded **/}
            {image.status === "uploaded" && <FileOutlineIcon />}

            {/* Text (Placeholder or progress) */}
            {image.status === "uploading" && image.progress < 100 && <p>{Math.round(image.progress) + "%"}</p>}
            {image.status === "uploading" && image.progress > 100 && <p>{"Arbejder med filen..."}</p>}
            {image.status !== "uploading" && <p style={{ textOverflow: "ellipsis" }}>{placeholder}</p>}

            {/* Input */}
            <input type="file" accept="image/*" onChange={this.onFileSelect} />

            {/* Progress bar */}
            {image.status === "uploading" && (
              <div className="progress-bar-container">
                <div style={{ flexGrow: image.progress || 0 }} className="progress-bar colored" />
                <div style={{ flexGrow: 100 - (image.progress || 0) }} className="progress-bar transparent" />
              </div>
            )}

            {image.status === "uploaded" && this.props.removeFile && (
              <TrashCanOutlineIcon
                onClick={() => this.onFileRemove()}
                style={{ color: colors.red, border: "none", position: "absolute", right: 0 }}
              />
            )}
          </label>
        )}
      </div>
    );
  }
}

const componentHeight = 40; //px

const componentStyle = (primaryColor, boxPadding) => css`
  font-size: 1rem;
  color: var(--black);
  display: flex;
  border: 1px var(--midGrey) solid;

  &.image-wrapper {
    padding: 0 3rem;
    position: relative;

    .uploaded-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    svg.remove-image {
      width: 35px;
      height: 35px;
      cursor: pointer;
      position: absolute;
      right: -7px;
      top: 0;
      border: none;
      fill: var(--darkGrey);
      border-left: 1px solid var(--midGrey);
      border-bottom: 1px solid var(--midGrey);
      border-bottom-left-radius: 7px;

      &:hover {
        fill: ${tinycolor(colors.darkGrey).darken(15).toString()};
      }

      &:active {
        background-color: ${"rgba(0, 0, 0, 0.1)"};
        fill: ${tinycolor(colors.darkGrey).darken(20).toString()};
      }
    }
  }

  /* Upload button/Input */
  label {
    display: flex;
    flex-grow: 1;
    position: relative;
    align-items: center;
    height: ${componentHeight}px;
    background: var(--white);
    color: var(--black);
    font-family: ${common.fontStack};
    margin-bottom: ${boxPadding};
    border-radius: 3px;

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: var(--darkGrey);
      border-right: 1px solid var(--midGrey);
    }

    /* Progress bar */
    .progress-bar-container {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 3px;
      display: flex;
      justify-content: space-between;

      .progress-bar {
        height: 3px;
      }

      .progress-bar.colored {
        background-color: ${primaryColor};
      }
    }
  }

  svg {
    position: relative;
    width: ${componentHeight}px;
    height: ${componentHeight}px;
    margin-right: 0.45rem;
    padding: 0.45rem;
    color: var(--darkGrey);
    border-right: 1px solid var(--midGrey);
  }

  input[type="file"] {
    display: none;
  }
`;
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

ImageUploadSingle.propTypes = {
  /**  String value to be displayed while image is being uploaded*/
  placeholder: PropTypes.string,
  /** value used for providing a bit of padding in your own implementation */
  boxPadding: PropTypes.string,
  /** Used for overriding styles, on the specific implementation */
  style: PropTypes.string,
  /** The applications primary color */
  primaryColor: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadSingle);
