// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Utilities
import getChildPageIds from "../utilities/getChildPageIds";

const useNotAllowedToMoveToPages = ({ parentPageId = undefined }) => {
  const [pageIdsNotAllowedToBeMovedTo, setPageIdsNotAllowedToBeMovedTo] = useState([]);
  const pages = useSelector((state) => state.pageBuilder.pages);

  useEffect(() => {
    const pageIds = getChildPageIds({ parentPageId, pages });
    setPageIdsNotAllowedToBeMovedTo(pageIds);
  }, [parentPageId]);

  return { pageIdsNotAllowedToBeMovedTo };
};

export default useNotAllowedToMoveToPages;
