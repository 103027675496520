// LIbs
import React, { Fragment } from "react";
import { css } from "emotion";
import { InformationOutlineIcon, LocationIcon } from "mdi-react";
import { useSelector } from "react-redux";

// Config and utilities
import { registrationTypes } from "../config";
import highlightMatch from "../../../utilities/highlight-match";

// Components
import ImageCarousel from "../../ui/ImageCarousel";
import TrafficLightLabel from "../../ui/TrafficLightLabel";
import VideoPlayer from "../../ui/VideoPlayer";
// Styles
import UseFileDisplayFormat from "../hooks/UseFileDisplayFormat";
import AdminReplyQuestionInput from "./AdminReplyQuestionInput";

const RegistrationPostContent = ({ answers, mailGroup, searchTerm = "", postId, registrationId, feedType }) => {
  const categories = answers.reduce((acc, answer) => {
    const category = acc.find((c) => c.id === answer.question.category.id);
    if (category) {
      category.answers.push(answer);
    } else {
      acc.push({
        id: answer.question.category.id,
        title: answer.question.category.title,
        answers: [answer],
      });
    }
    return acc;
  }, []);

  const renderedQuestions = [];

  // For some questions there are multiple answers, but we only want to render the question title once
  // So we keep track of which questions we have already rendered
  function renderQuestionTitleOnce(question) {
    if (renderedQuestions.includes(question.id) || question.type === registrationTypes.TABS) {
      return <></>;
    } else {
      renderedQuestions.push(question.id);
      return <p className="question">{question.title}</p>;
    }
  }

  // Hooks
  const fileDisplayFormat = UseFileDisplayFormat();

  const { language: lang } = useSelector((state) => state.language);
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  return (
    <div className={componentStyle(primaryColor)}>
      <>
        {mailGroup && (
          <>
            <p className="question">{lang.receiver}</p>
            <p className="answer">{highlightMatch(mailGroup, searchTerm)}</p>
          </>
        )}

        {categories.map((category) => (
          <div className="category" key={`category-${category.id}`}>
            {categories.length > 1 && <h3 className="category-title">{category.title}</h3>}

            {category.answers.map((a) =>
              // Well... These are all the found variants of not answered. Answers can be created from:
              // - Legacy app
              // - Ekko Updater
              // - The new app
              // (Which is probably why there are this many variations)
              (a.title === null || a.title === "null" || a.title === "" || !a.title) &&
              (!a.value || a.value?.length === 0) &&
              a.question.type !== registrationTypes.ADMIN_REPLY_QUESTION ? (
                <Fragment key={`answer-${a.id}`}>
                  <p className="question">{a.question.title}</p>
                  {/** DESCRIPTION */}
                  {a.question.description && (
                    <>
                      <p className="description">{a.question.description}</p>
                    </>
                  )}
                  <p className="no-answer answer">
                    <InformationOutlineIcon /> {lang.noAnswer}
                  </p>
                </Fragment>
              ) : (
                <Fragment key={`answer-${a.id}`}>
                  {renderQuestionTitleOnce(a.question)}
                  {/** DESCRIPTION */}
                  {a.question.description && (
                    <>
                      <p className="description">{a.question.description}</p>
                    </>
                  )}

                  {/** CHECKBOX **/}
                  {a.question.type === registrationTypes.CHECKBOX && (
                    <li className="answer list-item" key={`checkbox-item-${a.id}`}>
                      {a.title}
                    </li>
                  )}

                  {/** RADIO **/}
                  {/** DROPDOWN **/}
                  {/** TEXT **/}
                  {/** TIME **/}
                  {/** DATE **/}
                  {/** RADIO_HORIZONTAL **/}
                  {(a.question.type === registrationTypes.RADIO ||
                    a.question.type === registrationTypes.MAIL_TEXT ||
                    a.question.type === registrationTypes.DROPDOWN ||
                    a.question.type === registrationTypes.TEXT ||
                    a.question.type === registrationTypes.TIME ||
                    a.question.type === registrationTypes.DATE ||
                    a.question.type === registrationTypes.NUMERIC ||
                    a.question.type === registrationTypes.RADIO_HORIZONTAL) && (
                    <>
                      <p className="answer">{highlightMatch(a.title, searchTerm)}</p>
                    </>
                  )}

                  {/** IMAGE AND VIDEO **/}
                  {a.question.type === registrationTypes.IMAGE_AND_VIDEO && (
                    <>
                      {Array.isArray(a.value) && (
                        <>
                          {a.value
                            .filter(({ video = undefined }) => video !== undefined)
                            .map(({ video, baseURL }) => (
                              <VideoPlayer baseURL={baseURL} video={video} />
                            ))}
                          <ImageCarousel images={a.value.filter(({ image = undefined }) => image !== undefined)} />
                        </>
                      )}
                    </>
                  )}

                  {/** VIDEO **/}
                  {a.question.type === registrationTypes.VIDEO && (
                    <>
                      {Array.isArray(a.value) &&
                        a.value.map(({ baseURL, video }) => <VideoPlayer baseURL={baseURL} video={video} />)}
                    </>
                  )}

                  {/** IMAGE **/}
                  {a.question.type === registrationTypes.IMAGE && a.value !== undefined && (
                    <>{Array.isArray(a.value) && <ImageCarousel images={a.value} boxPadding={"0"} />}</>
                  )}

                  {/** FILE **/}
                  {a.question.type === registrationTypes.FILE && <>{fileDisplayFormat(a.title)}</>}

                  {/** DATA_SELECT**/}
                  {a.question.type === registrationTypes.DATA_SELECT && (
                    <>
                      <p className="answer">{a.title && a.title.title}</p>
                    </>
                  )}

                  {/** DATE **/}
                  {a.question.type === registrationTypes.RADIO_TRAFFIC_LIGHT && (
                    <>
                      <TrafficLightLabel className="answer" value={parseInt(a.title)} />
                    </>
                  )}

                  {/** YES_NO **/}
                  {a.question.type === registrationTypes.YES_NO && (
                    <>
                      <p className="answer">
                        {a.title === "YES" ? lang.yes : ""}
                        {a.title === "NO" ? lang.no : ""}
                      </p>
                    </>
                  )}

                  {/** GEOLOCATION */}
                  {a.question.type === registrationTypes.GEOLOCATION && (
                    <>
                      <p className="answer geolocation">
                        <LocationIcon />
                        <a href={a.title.link} target="_blank">
                          {`${a.title.latitude.toFixed(8)}, ${a.title.longitude.toFixed(8)}`}
                        </a>
                      </p>
                    </>
                  )}

                  {/** TABS **/}
                  {a.question.type === registrationTypes.TABS && <></>}

                  {/** OTHER **/}
                  {!a.question.type && (
                    <>
                      <p className="answer">{a.title}</p>
                    </>
                  )}

                  {/** ADMIN_REPLY_QUESTION **/}
                  {a.question.type === registrationTypes.ADMIN_REPLY_QUESTION && (
                    <AdminReplyQuestionInput
                      questionAnswer={a}
                      postId={postId}
                      registrationId={registrationId}
                      feedType={feedType}
                    />
                  )}
                </Fragment>
              )
            )}
          </div>
        ))}
      </>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  user-select: text;

  *::selection {
    background: ${primaryColor};
    color: var(--white);
    border-radius: 3px;
  }

  p {
    color: var(--black);
    white-space: break-spaces;
  }

  .commentField {
    &:focus {
      outline: 0;
      border: 1px solid var(--redMedium);
    }
  }

  /* Documents amd links */
  .registration-file-attachment {
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    margin: 0.25rem 0rem ${0.5}rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: var(--darkGrey);
      border-bottom: 1px var(--midGrey) solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0;
      }

      &:active {
        background-color: var(--lightGrey);
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: var(--darkGrey);
      }
    }
  }

  .no-answer {
    display: flex;
    align-items: center;
    color: var(--darkGrey);
    font-size: 0.9rem;

    svg {
      margin-right: 0.35rem;
      width: 1.15rem;
      height: 1.15rem;
      color: var(--darkGrey);
    }
  }

  .geolocation {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.35rem;
      width: 1.15rem;
      height: 1.15rem;
      color: var(--black);
    }

    a {
      display: flex;
      align-items: center;

      color: var(--black);
      text-decoration: underline;

      svg {
        color: var(--black);
        margin-left: 0.35rem;
      }
    }
  }

  .checkbox-container {
    margin-bottom: 1.25rem;
  }

  .question {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  .answer {
    margin-bottom: 1.25rem;

    &.list-item {
      margin-bottom: 0.25rem;
      margin-left: 1rem;
    }
  }

  .description {
    margin-bottom: 0.5rem;
    color: var(--darkGrey);
  }

  .category-title {
    margin-bottom: 0.5rem;
    color: ${primaryColor};
  }
`;

export default RegistrationPostContent;
