import req from "../utilities/request-utility";

import {
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  SET_SEARCH_TERM,
  SET_SEARCH_INCLUDES,
  SET_SEARCH_PAGE_OFFSET,
  SET_END_OF_FEED_VALUE,
  SET_SEARCH_IN_SUB_PAGE_ID,
} from "./actionTypes";

export function setSearchTerm(searchTerm) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SEARCH_TERM,
      payload: searchTerm,
    });
  };
}

export function setSearchIncludes(sectionsIncluded) {
  return function (dispatch) {
    dispatch({
      type: SET_SEARCH_INCLUDES,
      payload: sectionsIncluded,
    });
  };
}

export function clearAll() {
  return function (dispatch, getState) {
    dispatch({ type: SET_SEARCH_TERM, payload: "" });
    dispatch({ type: SET_SEARCH_PAGE_OFFSET, payload: 0 });
    dispatch({ type: SET_END_OF_FEED_VALUE, payload: false });
    dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: [] });
  };
}

export function setSearchInSubPageId({ pageId }) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SEARCH_IN_SUB_PAGE_ID,
      payload: pageId,
    });
  };
}

// This variable has to live outside the exported function in order to not get
// reset after each invoke
let lastSearchTerm = "";
export function getResults({ forceFetch = false } = {}) {
  return function (dispatch, getState) {
    /**
     * Cases:
     *
     * 1) Fresh search
     *    Conditions: No results
     *    Action: Set pageOffset to 0 and initiate search
     * 2) More results for previous search
     *    conditions: lastSearchTerm matches current searchTerm
     *    Action: Increment pageOffset by 1, initate search and append results to exisitng ones
     * 3) New search
     *    condition: lastSearchTerm doesn't match current searchTerm
     *    Action: Clear results, set page Offset to 0 and initiate search
     * 4) Clearing search
     *    condition: lastSearchTerm doesn't match current searchTerm
     *    Action: Clear results, set page Offset to 0 and initiate search
     */
    let state = getState().searchPage;

    // The feed is at the end. Don't proceed with more results
    if (!forceFetch && state.endOfFeed === true && lastSearchTerm === state.searchTerm) {
      // console.log("at feed end. Don't get results");
      return;
    } else {
      dispatch({ type: SET_END_OF_FEED_VALUE, payload: false });
    }

    dispatch({ type: GET_SEARCH_RESULTS });

    // If searchterm isn't set, handle is at reset and clear all
    if (!state.searchTerm) {
      dispatch({ type: SET_SEARCH_PAGE_OFFSET, payload: 0 });
      dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: [] });
      return;
    }

    let sectionsIncluded = "";
    if (Array.isArray(state.sectionsIncluded) && state.sectionsIncluded.length > 0) {
      sectionsIncluded = state.sectionsIncluded.join(",");
    }

    let onlySubContentFromPageId = "";
    if (state.onlySearchInSubContentFromPageId) {
      onlySubContentFromPageId = `&onlySubContentFromPageId=${state.onlySearchInSubContentFromPageId}`;
    }

    // case 1 and 3 (turns out the outcome is the exact same)
    if (state.results.length === 0 || lastSearchTerm !== state.searchTerm || forceFetch) {
      dispatch({ type: SET_SEARCH_PAGE_OFFSET, payload: 0 });

      // &onlySubContentFromPageId=36933 Needs to be added here for local search
      req()(
        `search/all/${encodeURIComponent(
          state.searchTerm
        )}?pageOffset=0&sectionsIncluded=${sectionsIncluded}${onlySubContentFromPageId}`
      )
        .then((res) => {
          if (res.data.length === 0) dispatch({ type: SET_END_OF_FEED_VALUE, payload: true });
          dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: res.data });
        })
        .catch(() => void dispatch({ type: GET_SEARCH_RESULTS_FAILURE }));
      // Case 2
    } else if (lastSearchTerm === state.searchTerm) {
      dispatch({ type: SET_SEARCH_PAGE_OFFSET, payload: getState().searchPage.pageOffset + 1 });

      req()(
        `search/all/${encodeURIComponent(state.searchTerm)}?pageOffset=${
          getState().searchPage.pageOffset
        }&sectionsIncluded=${sectionsIncluded}${onlySubContentFromPageId}`
      )
        .then((res) => {
          if (res.data.length === 0) dispatch({ type: SET_END_OF_FEED_VALUE, payload: true });
          dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: [...state.results, ...res.data] });
        })
        .catch(() => void dispatch({ type: GET_SEARCH_RESULTS_FAILURE }));
    }

    // Set lastSearchTerm variable
    lastSearchTerm = getState().searchPage.searchTerm;
  };
}
