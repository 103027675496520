// Libs
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import getPageFromId from "../../../utilities/get-page-from-id";

// Components
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import TabBar from "../../ui/TabBar";
import TabView from "../../ui/TabView";
import EquipmentBookingSingleDetailsTab from "./EquipmentBookingSingleDetailsTab";
import EquipmentBookingSingleHistoryTab from "./EquipmentBookingSingleHistoryTab";
import EquipmentBookingSingleTimes from "./EquipmentBookingSingleTimes";
import { ArrowLeftIcon, BookOpenVariantIcon, ClipboardTextOutlineIcon, InformationOutlineIcon } from "mdi-react";
import { disableResetStateTemporarily } from "../../../actions/equipmentBookingActions";
import ActionWrapper from "../../ui/ActionWrapper";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import pageConfig from "../../../config/pageConfig";

function EquipmentBookingSingle({ match }) {
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state.pages);

  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const hideEndDate = page.config && page.config[pageConfig.HIDE_END_DATE] ? page.config[pageConfig.HIDE_END_DATE] : false;

  const tabTitles = hideEndDate
    ? [
        <>
          <InformationOutlineIcon /> Info
        </>,
        <>
          <BookOpenVariantIcon /> Historik
        </>,
      ]
    : [
        <>
          <InformationOutlineIcon /> Info
        </>,
        <>
          <ClipboardTextOutlineIcon /> Bookninger
        </>,
        <>
          <BookOpenVariantIcon /> Historik
        </>,
      ];

  const tabs = hideEndDate
    ? [<EquipmentBookingSingleDetailsTab match={match} />, <EquipmentBookingSingleHistoryTab match={match} />]
    : [
        <EquipmentBookingSingleDetailsTab match={match} />,
        <EquipmentBookingSingleTimes match={match} />,
        <EquipmentBookingSingleHistoryTab match={match} />,
      ];

  return (
    <Page>
      <TopBar
        actionLeft={
          <ActionWrapper
            onClick={() => {
              dispatch(disableResetStateTemporarily());
              pageNavigatorV2({ mode: "pop" });
            }}
          >
            <ArrowLeftIcon />
          </ActionWrapper>
        }
        title={page.title}
      />
      <TabBar
        tabs={tabTitles.map((tab, index) => ({ title: tab, onClick: () => setActiveTabIndex(index) }))}
        activeTabIndex={activeTabIndex}
      />

      <TabView activeTabIndex={activeTabIndex} tabs={tabs} />
    </Page>
  );
}

export default EquipmentBookingSingle;
