// Libs
import React from "react";
import { GlobeIcon } from "mdi-react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Components
import ActionWrapper from "./ActionWrapper";

const Translator = ({ isTranslating, isTranslated, onTranslate, style = {}, secondaryStyle = false }) => {
  const lang = useSelector((state) => state.language.language);

  const buttonStyle = secondaryStyle ? "translate-button-secondary-style" : "translate-button";

  return (
    <div className={componentStyle()} style={style}>
      {isTranslating && (
        <ActionWrapper className={buttonStyle}>
          <GlobeIcon /> {lang.translating}...
        </ActionWrapper>
      )}
      {!isTranslating && !isTranslated && (
        <ActionWrapper className={buttonStyle} onClick={onTranslate}>
          <GlobeIcon /> {lang.translate}
        </ActionWrapper>
      )}
      {!isTranslating && isTranslated && (
        <ActionWrapper className={buttonStyle} onClick={() => onTranslate({ explicitlyChangeLanguage: true })}>
          <GlobeIcon /> {lang.changeLanguage}
        </ActionWrapper>
      )}
    </div>
  );
};

const componentStyle = () => css`
  .translate-button {
    color: var(--darkGrey);
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.15rem 0.25rem;
    margin-left: -0.25rem;
    margin-right: 0.65rem;

    svg {
      margin: 0 0.3rem -2px 0;
      width: 0.9rem;
      height: 0.9rem;
      color: var(--darkGrey);
    }
  }

  .translate-button-secondary-style {
    color: var(--darkGrey);
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.15rem 0.25rem;
    margin-left: -0.25rem;
    margin-right: 0.65rem;
    border: 1px solid var(--midGrey);
    border-radius: 1rem;
    padding: 0.35rem 0.9rem;

    svg {
      margin: 0 0.3rem -2px 0;
      width: 0.9rem;
      height: 0.9rem;
      color: var(--darkGrey);
    }
  }
`;

export default Translator;
