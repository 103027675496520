import {
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_CONFIG_FAILURE,
  SET_APP_LANGUAGE,
  CLEAR_APP_CONFIG_LOGIN_TYPES,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: false,
  appId: null,
  title: "",
  primaryColor: "",
  apiUrl: "",
  icon: {},
  frontPageImage: {},
  customerLogo: {},
  allowedLoginTypes: [],
  enableHighscore: false,
  language: "en",
  allowInAppConfiguration: false,
  showMasterGroup: false,
  showUserGroup: false,
  showJobTitle: false,
  anniversariesFilterType: "ALL",
  birthdaysAgeSetting: null,
  christmasCalendarId: null,
  enablePushNotifications: false,
  mediaMaxFileSize: 0,
  trustedDomains: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APP_CONFIG:
      return {
        ...state,
        loading: true,
      };

    case GET_APP_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload, // We already remapped everything in the action-Creator so lets not do it again?
        // appId: action.payload.appId,
        // title: action.payload.title,
        // primaryColor: action.payload.primaryColor,
        // apiUrl: action.payload.apiUrl,
        // icon: action.payload.icon,
        // frontPageImage: action.payload.frontPageImage,
        // customerLogo: action.payload.customerLogo,
        // allowedLoginTypes: action.payload.allowedLoginTypes,
        // enableHighscore: action.payload.enableHighscore,
        // allowInAppConfiguration: action.payload.allowInAppConfiguration,
        // enableNewsTranslation: action.payload.enableNewsTranslation,
        // enableNewsReadAloud: action.payload.enableNewsReadAloud,
        // showMasterGroup: action.payload.showMasterGroup,
        // showUserGroup: action.payload.showUserGroup,
        // showJobTitle: action.payload.showJobTitle,
        // anniversariesFilterType: action.payload.anniversariesFilterType,
      };

    case GET_APP_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SET_APP_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case CLEAR_APP_CONFIG_LOGIN_TYPES:
      return {
        ...state,
        allowedLoginTypes: [],
      };

    default:
      return state;
  }
}
