// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";

// Config
import breakpoints from "../../config/breakpoints";
import { registrationTypes } from "../registration/config";

// Components
import ImageCarousel from "./ImageCarousel";
import RadioGroup from "./RadioGroup";
import HorizontalRadioGroup from "./HorizontalRadioGroup";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import YesAndNoGroup from "./YesAndNoGroup";
import TimePicker from "./TimePicker";
import TextareaInput from "./TextareaInput";
import FileViewer from "./FileViewer";
import VideoPlayer from "./VideoPlayer";
import NumericInput from "./NumericInput";

const FormBuilderView = ({ question, showQuestionTitles = true, style }) => {
  return (
    <div className={componentStyle()} key={`form-view-element-${question.title}`}>
      {registrationTypes[question.type] && (
        <div className="type-container" style={style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}

          {/** TEXT **/}
          {question.type === registrationTypes.TEXT && (
            <TextareaInput disabled={true} value={question?.answer?.value || ""} />
          )}

          {/** IMAGE **/}
          {question.type === registrationTypes.IMAGE && Array.isArray(question.answer.value) && (
            <ImageCarousel images={question.answer.value} />
          )}

          {/** VIDEO **/}
          {question.type === registrationTypes.VIDEO &&
            Array.isArray(question.answer.value) &&
            question.answer.value.map(({ video, baseURL }) => <VideoPlayer baseURL={baseURL} video={video} />)}

          {/** IMAGE AND VIDEO **/}
          {question.type === registrationTypes.IMAGE_AND_VIDEO && Array.isArray(question.answer.value) && (
            <>
              {question.answer.value
                .filter((a) => a.video !== undefined)
                .map(({ video, baseURL }) => (
                  <VideoPlayer baseURL={baseURL} video={video} />
                ))}
              <ImageCarousel images={question.answer.value.filter((a) => a.image !== undefined)} />
            </>
          )}

          {/** FILE **/}
          {question.type === registrationTypes.FILE && (
            <div className="file-wrapper">
              <FileViewer
                href={question.answer && question.answer.value}
                key={`formbuilder-view-${question.answer.id}`}
                title={question.answer && question.answer.value && question.answer.value.split("/").slice(-1)[0]}
              />
            </div>
          )}

          {/** RADIO **/}
          {question.type === registrationTypes.RADIO && (
            <RadioGroup
              disabled={true}
              selectedValue={question.answer.value ? question.answer.value[0] : null}
              options={question.options}
            />
          )}

          {/** RADIO HORIZONTAL **/}
          {question.type === registrationTypes.RADIO_HORIZONTAL && (
            <div className="type-container" style={style}>
              {showQuestionTitles && <p className="title">{question.title}</p>}
              <HorizontalRadioGroup disabled={true} selectedValue={question.answer.value} options={question.options} />
            </div>
          )}

          {/** CHECKBOX **/}
          {question.type === registrationTypes.CHECKBOX && (
            <div className="type-container" style={style}>
              {showQuestionTitles && <p className="title">{question.title}</p>}
              <CheckboxGroup disabled={true} selectedValues={question.answer.value} options={question.options} />
            </div>
          )}

          {/** DATE **/}
          {question.type === registrationTypes.DATE && (
            <DatePicker disabled={true} name="date" defaultDate={question.answer ? question.answer.value : ""} />
          )}

          {/** YES_NO **/}
          {question.type === registrationTypes.YES_NO && (
            <YesAndNoGroup disabled={true} selectedValue={question.answer.value} />
          )}

          {/** TIME **/}
          {question.type === registrationTypes.TIME && <TimePicker disabled={true} selectedValue={question.answer.value} />}

          {/** NUMERIC */}
          {question.type === registrationTypes.NUMERIC && (
            <NumericInput disabled={true} value={question.answer.value || ""} />
          )}
        </div>
      )}

      {/** EXTRA COMMENT **/}
      {question.answer.answerComment && question.answer.answerComment.length > 0 && (
        <div className="type-container" style={style}>
          <TextareaInput disabled={true} value={question.answer.answerComment || ""} />
        </div>
      )}

      {/** EXTRA IMAGE **/}
      {question.answer.answerImage && question.answer.answerImage.length > 0 && (
        <div className="type-container" style={style}>
          {showQuestionTitles && <p>{question.title}</p>}
          <ImageCarousel images={[{ image: question.answer.answerImage, baseURL: question.answer.answerImageBaseURL }]} />
        </div>
      )}
    </div>
  );
};

const boxPadding = 0.75;

const componentStyle = () => css`
  div.type-container {
    div.file-wrapper {
      border: 1px solid var(--midGrey);
      border-radius: 3px;
    }

    p.title {
      margin-bottom: 0.5rem;
    }

    video {
      max-width: 100%;
    }

    .not-valid {
      border: 1px solid var(--redMedium);
    }

    .show-validate-title {
      color: var(--redMedium);
    }
  }

  .seperator {
    height: 1px;
    background-color: var(--midGrey);
    margin: 1.5rem -${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1.5rem 0;
    }
  }
`;

export default FormBuilderView;
