import { AccountMultipleIcon } from "mdi-react";
import TabView from "../../TabView";
import SelectionPanelTabBar from "./SelectionPanelTabBar";
import { useSelector } from "react-redux";
import { groupTypes } from "../config/groupTypes";
import {
  addAllCustomGroupsToTargetGroup,
  addAllJobTitlesToTargetGroup,
  addAllMasterGroupsToTargetGroup,
  addAllUserGroupsToTargetGroup,
  addAllUsersToTargetGroup,
  addCustomGroupToTargetGroup,
  addJobTitleToTargetGroup,
  addMasterGroupToTargetGroup,
  addUserGroupToTargetGroup,
  addUserToTargetGroup,
  removeCustomGroupFromTargetGroup,
  removeJobTitleFromTargetGroup,
  removeMasterGroupFromTargetGroup,
  removeUserFromTargetGroup,
  removeUserGroupFromTargetGroup,
} from "../actions/targetGroupModalActions";
import SelectionPanelTab from "./SelectionPanelTab";
import { filterFunctions } from "../utilities/filterFunctions";
import useLang from "../../../../hooks/useLang";

function SelectionPanel() {
  const { activeTabIndex } = useSelector((s) => s.targetGroupModal.selectionPanel);
  const lang = useLang();

  return (
    <div className="panel selection-panel">
      <div className="panel-header">
        <h2>
          <AccountMultipleIcon /> {lang.choose} {lang.users.toLowerCase()}
        </h2>

        <SelectionPanelTabBar />
      </div>
      <TabView
        activeTabIndex={activeTabIndex}
        tabs={[
          <SelectionPanelTab
            titleField="name"
            groupType={groupTypes.users}
            addAllFunction={addAllUsersToTargetGroup}
            addOneFunction={addUserToTargetGroup}
            removeOneFunction={removeUserFromTargetGroup}
            filterFunction={filterFunctions.users}
          />,
          <SelectionPanelTab
            groupType={groupTypes.userGroups}
            addAllFunction={addAllUserGroupsToTargetGroup}
            addOneFunction={addUserGroupToTargetGroup}
            removeOneFunction={removeUserGroupFromTargetGroup}
            filterFunction={filterFunctions.userGroups}
          />,
          <SelectionPanelTab
            groupType={groupTypes.masterGroups}
            addAllFunction={addAllMasterGroupsToTargetGroup}
            addOneFunction={addMasterGroupToTargetGroup}
            removeOneFunction={removeMasterGroupFromTargetGroup}
            filterFunction={filterFunctions.masterGroups}
          />,
          <SelectionPanelTab
            groupType={groupTypes.customGroups}
            addAllFunction={addAllCustomGroupsToTargetGroup}
            addOneFunction={addCustomGroupToTargetGroup}
            removeOneFunction={removeCustomGroupFromTargetGroup}
            filterFunction={filterFunctions.customGroups}
          />,
          <SelectionPanelTab
            idField="title"
            countField="count"
            groupType={groupTypes.jobTitles}
            addAllFunction={addAllJobTitlesToTargetGroup}
            addOneFunction={addJobTitleToTargetGroup}
            removeOneFunction={removeJobTitleFromTargetGroup}
            filterFunction={filterFunctions.jobTitles}
          />,
        ]}
      />
    </div>
  );
}

export default SelectionPanel;
