/**
 * 
 * @param {{ media: import("../../../../reducers/mediaUploadReducer").MediaUploadFile }} media
 */
const MediaPreviewElement = ({ media }) => {
  if (media.file.type.startsWith("video")) {
    if (media.state === "pre-existing") {
      return (
        <video controls>
          <source src={media.url} type={media.file.type} />
        </video>
      )
    } else {
      return (
        <video controls>
          <source src={URL.createObjectURL(media.file)} type={media.file.type} />
        </video>
      )
    }
  }

  if (media.file.type.startsWith("image")) {
    if (media.state === "pre-existing" || media.state === "uploaded") {
      return (
        <img src={media.url} alt="preview" />
      )
    } else {
      return (
        <img src={URL.createObjectURL(media.file)} alt="preview" style={{ transform: `rotate(${media.rotation}deg)` }} />
      )
    }
  }
}

export default MediaPreviewElement;