// Libs
import React from "react";
import { css } from "emotion";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";

// Components
import { ArrowLeftIcon, HomeIcon, HomeOutlineIcon } from "mdi-react";
import ActionWrapper from "./ActionWrapper";

// Utilities
import pageNavigatorV2, { pageTransitions } from "../../utilities/page-navigator-v2";
import { hideModalPage } from "../../actions/uiActions";
import getAppName from "../../utilities/get-app-name";

/**
 * @param {Object} props
 * @param {ReactElement} props.actionLeft
 * @param {ReactElement} props.actionRight
 * @param {Boolean} props.useDefaultBackButton - If true, a default back button will be used
 */
function TopBar(props) {
  const dispatch = useDispatch();

  return (
    <div
      data-test-id={props["data-test-id"]}
      ref={props.scrollTopRef}
      className={
        style({
          atFrontPage: props.atFrontPage,

          allowActionsOverflow: props.allowActionsOverflow,
        }) + " top-bar"
      }
      style={props.style}
    >
      <div className="action-left" data-test-id="top-bar__action-left">
        {props.useDefaultBackButton === true && (
          <ActionWrapper onClick={() => pageNavigatorV2({ mode: "pop" })}>
            <ArrowLeftIcon />
          </ActionWrapper>
        )}

        {props.actionLeft || ""}
      </div>

      {props.title && (
        <div className="title">
          <p>{props.title}</p>
        </div>
      )}

      <div className="action-right">
        {props.actionRight || ""}
        {props.hideHomeIcon !== true && !props.atFrontPage && (
          <ActionWrapper
            onClick={() => {
              dispatch(hideModalPage());
              pageNavigatorV2({ mode: "push", path: `/${getAppName()}`, direction: pageTransitions.modalPageDown });
            }}
          >
            <HomeIcon />
          </ActionWrapper>
        )}
      </div>
    </div>
  );
}

const style = ({ atFrontPage, primaryColor, allowActionsOverflow }) => css`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  height: calc(${common.topBarHeight}px + env(safe-area-inset-top) px);
  background-color: ${atFrontPage ? colors.white : "var(--primary-color)"};
  color: ${atFrontPage ? colors.black : colors.white};
  box-shadow: ${atFrontPage ? "none" : common.boxShadowSubtle};
  z-index: 1;
  flex-shrink: 0;
  padding-top: env(safe-area-inset-top);

  svg {
    fill: ${atFrontPage ? colors.black : colors.white};
    /* background-color: ${atFrontPage ? colors.white : primaryColor}; */
    padding: ${common.topBarHeight * 0.256}px;
    height: ${common.topBarHeight}px;
    width: ${common.topBarHeight}px;

    &:active {
      fill: ${tinycolor(atFrontPage ? colors.black : colors.white)
        .setAlpha(0.8)
        .toString()};
    }
  }

  .action-left {
    flex-shrink: 0;
  }
  .title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-left: ${atFrontPage ? "40px" : "0px"};

    p {
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 700;
    }

    img {
      max-height: 35px;
      max-width: 100%;
      margin-top: 0.5rem;
      padding: 0 20px;
    }
  }
  .action-right {
    display: flex;
    flex-shrink: 0;
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

TopBar.propTypes = {
  /** Bool value that helps with styling element differently if its at the frontpage */
  atFrontPage: PropTypes.bool,
  /** Override styles with this */
  style: PropTypes.object,
  /** Click event to be executed when left Element is clicked */
  actionLeft: PropTypes.object,
  /** Click event to be executed when right Element is clicked */
  actionRight: PropTypes.object,
};

export default connect(mapStateToProps)(TopBar);
