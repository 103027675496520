import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { parse, formatDistanceToNow } from "date-fns";

// Redux actions
import {
  refreshPostCommentLikes,
  refreshPostComments,
  refreshPostLikes,
  removePostFromFeed,
  pinPost,
  unpinPost,
} from "../../actions/socialActions";
import { addToast, showContextMenu, showModalPage, updateModalPage, showDialog } from "../../actions/uiActions";

// Components
import Post from "../ui/Post";
import SocialCommentModal from "./SocialCommentModal";
import SocialPostModal from "./SocialPostModal";
import UserProfile from "../profile/UserProfile";
import { AlertDecagramIcon, PencilIcon, TrashCanOutlineIcon, PinOutlineIcon } from "mdi-react";

// Utils
import req from "../../utilities/request-utility";
import smoothScrollToBottom from "../../utilities/smooth-scroll-to-bottom";
import getUserLocale from "../../utilities/get-user-locale";
import styleTypes from "../../config/styleTypes";
import translationTypes from "../../config/translationTypes";
import readAloudTypes from "../../config/readAloudTypes";
import showReadAloud from "../../utilities/showReadAloud";

// Services
import useTranslation from "../../hooks/useTranslation";
import useReadAloud from "../../hooks/useReadAloud";

/**
 * SocialPost is a proxy-component that adds social-specific functionality to the Post component
 * It sits between `<SocialFeed/>` and `<Post/>` component.
 *
 * @example
 * ```jsx
 * {posts.map(post => <SocialPost post={post} key={`social-post-${post.id}`} />)}
 * ```
 *
 */
function SocialPost(props) {
  const {
    addToast,
    lang,
    refreshPostComments,
    refreshPostLikes,
    removePostFromFeed,
    showContextMenu,
    showDialog,
    showModalPage,
    updateModalPage,
    user,
    disableInteractionsPreview,
    style,
    pinPost,
    unpinPost,
  } = props;

  const dataId = useSelector((s) => s.social.currentDataId);

  // Post content
  const {
    id,
    content,
    author,
    images,
    likes,
    comments,
    date,
    deleted,
    file,
    videos,
    externalLink,
    isPinned = false,
  } = props.post;

  /**************** Generel  ****************/
  const [isAuthor] = useState(author && author.id === user.id);

  const [isAdmin] = useState(() => {
    // if either user is admin or frontendAdminMappings includes the current page
    if (user.admin) {
      return true;
    } else {
      return false;
    }
  });
  const appConfig = useSelector((state) => state.appConfig);

  const [contentState, setContentState] = useState(content);

  // TRANSLATION
  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedTitle,
    content: translatedContent,
  } = useTranslation({
    type: translationTypes.socialOrChatPost,
    args: { dataId: dataId, postId: id },
  });

  // READ ALOUD
  const { isPlayingAudio, isLoadingAudio, readAloudContent } = useReadAloud({
    type: readAloudTypes.socialOrChatPost,
    args: { dataId: dataId, postId: id },
  });

  useEffect(() => {
    if (translatedContent) setContentState(translatedContent);
  }, [translatedContent]);

  useEffect(() => {
    // Minor hack to update content after editing
    // This will lose the translation, but that's okay
    setContentState(content);
  }, [content]);

  function onPostContextMenu() {
    let contextMenu = [];

    if (isAdmin) {
      // if admin show pin/unpin button
      contextMenu.push({ title: isPinned ? lang.unpin : lang.pin, icon: <PinOutlineIcon />, callback: updatePin });
    }
    if (isAuthor) {
      //if auther show edit and delete buttons
      contextMenu.push({ title: lang.edit, icon: <PencilIcon />, callback: editPost });
      contextMenu.push({ title: lang.delete, icon: <TrashCanOutlineIcon />, callback: deletePost });
    }
    return showContextMenu(contextMenu);
  }

  async function showAuthorModal() {
    showModalPage({
      content: <UserProfile userDataLoading={true} userDataError={false} />,
    });
    let { data: userData } = await req()(`users/${author.id}`);
    updateModalPage({
      content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
    });
  }

  /**************** Posts  ****************/
  function editPost() {
    showModalPage({ title: "Edit post", content: <SocialPostModal mode="edit" post={props.post} />, useScrollView: false });
  }

  function updatePin() {
    if (isPinned) {
      showDialog({
        title: lang.unpinPost,
        content: lang.unpinPostInfo,
        primaryActionTitle: lang.unpinPostConfirm,
        secondaryActionTitle: lang.unpinPostCancel,
        styleType: styleTypes.neutral,
        icon: <AlertDecagramIcon />,
        primaryAction: () => unpinPost({ socialId: dataId, postId: id }),
      });
    } else {
      showDialog({
        title: lang.pinPost,
        content: lang.pinPostInfo,
        primaryActionTitle: lang.pinPostConfirm,
        secondaryActionTitle: lang.pinPostCancel,
        styleType: styleTypes.neutral,
        icon: <AlertDecagramIcon />,
        primaryAction: () => pinPost({ socialId: dataId, postId: id }),
      });
    }
  }

  function deletePost() {
    showDialog({
      styleType: "warning",
      icon: <AlertDecagramIcon />,
      title: lang.deletePost,
      content: lang.deletePostInfo,
      primaryActionTitle: lang.deletePostConfirm,
      secondaryActionTitle: lang.deletePostCancel,
      primaryAction: () =>
        req()
          .delete(`social/${dataId}/posts/${id}`)
          .then(() => removePostFromFeed(id))
          .catch((err) => addToast({ template: "error" })),
    });
  }

  /**************** Likes  ****************/
  const [submittingLike, setSubmittingLike] = useState(false);

  async function toggleLike() {
    let timer = setTimeout(() => setSubmittingLike(true), 300);

    if (isLiked()) {
      await req().delete(`social/${dataId}/posts/${id}/like`);
    } else {
      await req()
        .put(`social/${dataId}/posts/${id}/like`)
        .catch(() => {
          addToast({ template: "tooManyRequests" });
          setSubmittingLike(false);
        });
    }

    refreshPostLikes(id, () => {
      clearTimeout(timer);
      setSubmittingLike(false);
    });
  }

  function isLiked() {
    // l && l.id is a null-check. If a user is deleted they are represented as NULL in the likes array
    if (likes.filter((l) => l && l.id === user.id).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  /**************** Comment Likes  ****************/
  const [submittingLikedOnCommentId, setSubmittingLikeOnCommentId] = useState(false);

  async function toggleCommentLike(comment) {
    let timer = setTimeout(() => setSubmittingLikeOnCommentId(comment.id), 300);
    if (isCommentLiked(comment)) {
      await req().delete(`social/${dataId}/posts/${id}/comments/${comment.id}/like`);
    } else {
      await req().put(`social/${dataId}/posts/${id}/comments/${comment.id}/like`);
    }

    props.refreshPostCommentLikes({
      postId: id,
      commentId: comment.id,
      callback: () => {
        clearTimeout(timer);
        setSubmittingLikeOnCommentId(false);
      },
    });
  }

  function isCommentLiked(comment) {
    // l && l.id is a null-check. If a user is deleted they are represented as NULL in the likes array
    if (comment.likes && comment.likes.filter((l) => l && l.id === user.id).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  /**************** Comments  ****************/
  const [submittingComment, setSubmittingComment] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const commentsContainer = useRef();

  /** Adds comment to posts */
  async function addComment(comment, parentCommentId = null) {
    comment = { content: comment.content, image: comment.image };

    // If it's a nested comment - append parentCommentId
    if (parentCommentId) {
      comment = { ...comment, parentCommentId };
    }

    setSubmittingComment(true);

    await req()
      .post(`social/${dataId}/posts/${id}/comments`, comment)
      .catch(() => addToast({ template: "error" }));

    // Set state (done with comments submission -> now to refresh post)
    setSubmittingComment(false);
    setLoadingComments(true);

    // If the submitted comment is nested -> Don't auto scroll to bottom of comment feed
    if (parentCommentId) {
      refreshPostComments(id, () => {
        setLoadingComments(false);
      });
    } else if (!parentCommentId) {
      // The timer prevents double scrolling if network is faster than 300ms
      // First scroll is scrolling to spinner, Second scroll is scrolling to comment
      let timer = setTimeout(() => smoothScrollToBottom(commentsContainer.current), 300);

      refreshPostComments(id, () => {
        clearTimeout(timer);
        setLoadingComments(false);
        smoothScrollToBottom(commentsContainer.current);
      });
    }
  }

  function onCommentContextMenu(comment) {
    /** Contect menu triggered when clicking on a comment's more options */
    showContextMenu([
      { title: lang.edit, icon: <PencilIcon />, callback: () => editComment(comment) },
      { title: lang.delete, icon: <TrashCanOutlineIcon />, callback: () => deleteComment(comment.id) },
    ]);
  }

  function editComment(comment) {
    showModalPage({
      title: `${lang.edit} ${lang.comment.toLowerCase()}`,
      content: <SocialCommentModal comment={comment} postId={id} />,
      useScrollView: false,
    });
  }

  function deleteComment(commentId) {
    showDialog({
      styleType: "warning",
      icon: <AlertDecagramIcon />,
      title: lang.deleteComment,
      content: lang.deleteCommentInfo,
      primaryActionTitle: lang.deleteCommentConfirm,
      secondaryActionTitle: lang.deleteCommentCancel,
      primaryAction: () =>
        req()
          .delete(`social/${dataId}/posts/${id}/comments/${commentId}`)
          .then(() => refreshPostComments(id))
          .catch((err) => addToast({ template: "error" })),
    });
  }

  // Auto refresh comments
  const [autoRefreshComments, setAutoRefreshComments] = useState(false);

  // Register side-effect to refetch commetns every 15 seconds
  useEffect(() => {
    let interval;

    // If autorefreshCommetns is set to true, call refreshPostComments every 15 seconds
    if (autoRefreshComments) {
      refreshPostComments(id);
      interval = setInterval(() => void refreshPostComments(id), 15000);
    } else {
      // If autoRefreshComments is set to false clear the interval
      clearInterval(interval);
    }

    // Cleanup function to remove interval if component unmounts
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [autoRefreshComments, id]);

  return (
    <Post
      // Content
      id={id}
      date={`${formatDistanceToNow(parse(date, "yyyyMMddHHmmss", 0), getUserLocale(user))} ${lang.ago}`}
      content={contentState}
      enableMarkdownContent={true}
      author={author}
      images={images}
      isPinned={isPinned}
      // Quick fix - should probably implement multiple videos flow later
      video={videos.length > 0 && videos[0]}
      likes={likes}
      documents={file && file.path && [{ file: file.path, title: lang.attachedFile }]}
      comments={comments}
      // Config
      className="social-post"
      key={`social-post-${id}`}
      disableSwipe={true}
      disableInteractionsPreview={disableInteractionsPreview}
      style={style}
      links={externalLink ? [{ URL: externalLink, title: externalLink }] : []}
      // Actions
      onLike={toggleLike}
      onCommentLike={toggleCommentLike}
      onComment={addComment}
      onAuthorClick={showAuthorModal}
      contextMenuToggleCallback={onPostContextMenu}
      onCommentContextMenu={onCommentContextMenu}
      // State and loading-stuff
      animateOut={deleted === true}
      showContextMenuToggle={isAuthor || isAdmin}
      liked={isLiked()}
      isCommentLiked={isCommentLiked}
      submittingComment={submittingComment}
      submittingLike={submittingLike}
      submittingLikedOnCommentId={submittingLikedOnCommentId}
      loadingComments={loadingComments}
      commentsContainerRef={commentsContainer}
      onCommentShow={() => setAutoRefreshComments(true)}
      onCommentHide={() => setAutoRefreshComments(false)}
      //Transation and Read aloud
      enableTranslation={appConfig.enableTranslation}
      isTranslating={isTranslating}
      isTranslated={isTranslated}
      translateContent={translateContent}
      enableReadAloud={showReadAloud({
        enableReadAloud: appConfig.enableReadAloud,
        languageCode: currentTranslationLanguage,
      })}
      isLoadingAudio={isLoadingAudio}
      isPlayingAudio={isPlayingAudio}
      readContent={() => readAloudContent({ currentTranslationLanguage })}
    />
  );
}

SocialPost.defaultProps = {
  disableInteractionsPreview: false,
  style: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
  refreshPostLikes: bindActionCreators(refreshPostLikes, dispatch),
  removePostFromFeed: bindActionCreators(removePostFromFeed, dispatch),
  refreshPostCommentLikes: bindActionCreators(refreshPostCommentLikes, dispatch),
  pinPost: bindActionCreators(pinPost, dispatch),
  unpinPost: bindActionCreators(unpinPost, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SocialPost);
