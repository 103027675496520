import store from "../store";

export default () => {
  let date = new Date();
  let month = date.getMonth();
  let year = date.getFullYear();

  const lang = store.getState().language.language;

  if (month >= 0 && month <= 2) {
    return `${lang.first_quarter} ${year}`;
  } else if (month >= 3 && month <= 5) {
    return `${lang.second_quarter} ${year}`;
  } else if (month >= 6 && month <= 8) {
    return `${lang.third_quarter} ${year}`;
  } else if (month >= 9 && month <= 11) {
    return `${lang.fouth_quarter} ${year}`;
  }
};
