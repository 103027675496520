export default {
  all: "all",

  // PhoneBook
  masterGroup: "masterGroup",
  userGroup: "userGroup",

  // KeyPersons
  groups: "groups",
};
