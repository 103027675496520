// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import { ChevronDownIcon } from "mdi-react";

// Styles
import colors from "../../../../style/colors";
import common from "../../../../style/common";
import Spinner from "../../InlineSpinner";
import useLang from "../../../../hooks/useLang";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";

let groupByTypes = { options: "options", sections: "sections" };

const DropDown = (props) => {
  const {
    usePlaceholder = false,
    placeholder = false,
    enableSearch = false,
    options = [],
    sections = [],
    style,
    className,
    value,
    onChange,
    name,
    height = null,
    loading = false,
    groupBy = groupByTypes.options,
    showOnClear = false,
    onClear = () => {},
  } = props;

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const lang = useLang();

  return (
    <div style={style} className={`${componentStyle(primaryColor, height)} ${className || ""}`}>
      <select
        data-test-id={props["data-test-id"]}
        className={enableSearch ? "append bottom-component" : ""}
        value={value ? value : []}
        onChange={onChange}
        name={name}
      >
        {!loading && usePlaceholder && (
          <option value="" hidden>
            {placeholder || lang.clickToSelect}
          </option>
        )}

        {!loading &&
          groupBy === groupByTypes.options &&
          options.length > 0 &&
          options.map((item, itemIndex) => (
            <option name={item.name || item.title} value={item.id} key={itemIndex}>
              {item.name || item.title}
            </option>
          ))}

        {!loading &&
          groupBy === groupByTypes.sections &&
          sections.map((section, sectionIndex) => (
            <optgroup label={section.title} key={sectionIndex}>
              {section?.options.map((item, itemIndex) => (
                <option name={item.name || item.title} value={item.id} key={itemIndex}>
                  {item.name || item.title}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
      {loading && (
        <div className="loading-wrapper">
          <Spinner className="spinner" />
          <p>Indlæser data...</p>
        </div>
      )}

      <div className="icon-right-container">
        {value && showOnClear && <CloseCircleOutlineIcon className="clear-icon" onClick={onClear} />}
        <ChevronDownIcon className="dropdown-arrow" />
      </div>
    </div>
  );
};

const componentStyle = (primaryColor, height) => css`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid var(--midGrey);
  border-radius: 3px;
  background-color: var(--white);

  select option:hidden {
    display: none;
  }

  select {
    border: none;
    background: none;
    width: 100%;
    background: transparent;
    padding-left: 1rem;
    display: block;
    height: ${height ? height : "40px"};
    font-size: 1rem;
    font-family: ${common.fontStack};
    appearance: none;
    color: #6e6e6e;
    cursor: pointer;

    &:focus {
      outline: 0;
      border: 1px solid ${primaryColor};
    }

    &.append {
      &.bottom-component {
        z-index: 5;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .icon-right-container {
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: inherit;

    svg {
      color: var(--midDarkGrey);
      width: 1.25rem;
      height: 1.25rem;
    }

    svg.dropdown-arrow {
      margin-right: 0.5rem;
      pointer-events: inherit;
    }

    svg.clear-icon {
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }

  .loading-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    left: 0.25rem;
    top: 50%;
    transform: translateY(-50%);

    p {
      color: ${colors.midDarkGrey};
    }

    div.spinner {
      height: 1.15rem;
      width: 1.15rem;
      margin-right: 0.5rem;
    }
  }
`;

export default DropDown;
