import { css } from "emotion";
import Page from "../../../ui/Page";
import TopBar from "../../../ui/TopBar";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { endOfISOWeek, format, getISOWeek, parse, parseISO, startOfISOWeek } from "date-fns";
import NumericInput from "../../../ui/NumericInput";
import TextInput from "../../../ui/TextInput";
import Toggle from "../../../ui/Toggle";
import { useEffect, useState } from "react";
import DynamicIcon from "../../../ui/Icons/DynamicIcon";
import DropDownList from "../../../ui/dropDown/DropDownList";
import DropDown from "../../../ui/dropDown/components/DropDown";
import { da } from "date-fns/locale";

function dateHelper(date) {
  return format(parse(date, "yyyyMMdd", new Date()), "dd. MMMM yyyy", { locale: da });
}

function dateTimeHelper(date) {
  return format(parse(date, "yyyyMMddHHmm", new Date()), "dd. MMMM yyyy HH:mm");
}

function Table({ table, columns }) {
  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>;
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getAllCells().map((cell) => {
              return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
            })}
          </tr>
        ))}
        {table.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={columns.length}>Ingen data, tjek venligst at filtret er korrekt.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function ColasMyExternalTimeRegistration() {
  const { data, isValidating: loading } = useSWR("time-registrations/special/colas", swrFetcher);

  const [week, setWeek] = useState("");
  const [weekOptions, setWeekOptions] = useState([]);

  const helper = createColumnHelper();

  const columns = [
    helper.accessor("date", {
      header: "Dato",
      cell: (cell) => dateHelper(cell.getValue()),
    }),
    helper.accessor("start", {
      header: "Start",
      cell: (cell) => dateTimeHelper(cell.getValue()),
    }),
    helper.accessor("end", {
      header: "Slut",
      cell: (cell) => dateTimeHelper(cell.getValue()),
    }),
    helper.accessor("break", {
      header: "Pause",
      cell: (cell) => `${cell.getValue()} minutter`,
    }),
    helper.accessor("total", {
      header: "Total",
      cell: (cell) => `${cell.getValue()} timer`,
    }),
    helper.accessor((row) => getISOWeek(parse(row.date, "yyyyMMdd", new Date())), {
      header: "Uge",
      id: "week",
      cell: (cell) => cell.getValue(),
      filterFn: "weakEquals",
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  useEffect(() => {
    table.getColumn("week").setFilterValue(week);
  }, [week]);

  useEffect(() => {
    if (data) {
      const options = table
        .getPreFilteredRowModel()
        .rows.reduce((acc, row) => {
          const date = parse(row.getValue("date"), "yyyyMMdd", new Date());

          const week = getISOWeek(date);
          const isoWeekStart = startOfISOWeek(date);
          const isoWeekEnd = endOfISOWeek(date);
          const label = `Uge ${week} - ${format(isoWeekStart, "dd/MM/yyyy")} - ${format(isoWeekEnd, "dd/MM/yyyy")}`;

          if (!acc.find((item) => item.id == week)) {
            acc.push({ name: label, id: week });
          }

          return acc;
        }, [])
        .reverse();

      setWeekOptions(options);

      setTimeout(() => {
        setWeek(options[0].id);
      }, 0);
    }
  }, [data]);

  return (
    <Page>
      <TopBar useDefaultBackButton title="Ekstern tidregistrering" />
      <main className={style()}>
        {!data && loading && <p>Loading...</p>}
        {data && (
          <>
            <div className="filters">
              <DropDown
                options={weekOptions}
                onChange={(e) => setWeek(e.target.value)}
                value={week}
                className="week-dropdown"
              />

              <DynamicIcon icon="calendar" />
              {/* <TextInput placeholder="Medarbejder" className=""></TextInput> */}
              <div className="spacer"></div>
              {/* <Toggle on></Toggle> */}
            </div>
            <div className="table-container">{<Table table={table} columns={columns} />}</div>
          </>
        )}
      </main>
    </Page>
  );
}

const style = () => css`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  overflow-x: auto;
  width: 100%;
  height: 100%;

  > .filters {
    padding: 1rem 1rem 0 1rem;
    border-bottom: 1px solid var(--lightGrey);

    display: flex;
    gap: 0.25rem;

    align-items: center;

    color: var(--darkGrey);

    input {
      width: 8rem;
    }

    > .week-dropdown {
      background-color: var(--white);
    }
  }

  > .table-container {
    width: 100%;

    padding: 0 1rem 1rem 1rem;

    table {
      background: var(--white);
      border-spacing: 0;
      color: var(--darkGrey);
      white-space: nowrap;
      width: 100%;

      thead {
        text-align: left;

        th {
          padding: 0.5rem;
          border-bottom: 1px solid var(--lightGrey);
        }

        th:first-of-type {
          padding-left: 1.5rem;
        }

        th:last-of-type {
          padding-right: 1.5rem;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: var(--ultraLightGrey);
          }

          td {
            padding: 0.5rem;
            border-bottom: 1px solid var(--lightGrey);
          }

          td:first-of-type {
            padding-left: 1.5rem;
          }

          td:last-of-type {
            padding-right: 1.5rem;
          }
        }
      }
    }
  }
`;

export default ColasMyExternalTimeRegistration;
