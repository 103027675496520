import {
  END_OF_REGISTRATIONS_POSTS,
  GET_REGISTRATION_FORM_FAILURE,
  GET_REGISTRATION_FORM_SUCCESS,
  GET_REGISTRATION_POSTS,
  GET_REGISTRATION_POSTS_FAILURE,
  GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
  GET_REGISTRATION_POSTS_SUCCESS,
  LIKE_REGISTRATION_POST,
  LIKE_REGISTRATION_POST_FAILURE,
  READ_REGISTRATION_POST,
  READ_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
  REFETCH_REGISTRATION_POST_SUCCESS,
  RESET_REGISTRATION,
  RESET_REGISTRATION_FEED,
  RESET_REGISTRATION_FORM,
  SUBMIT_REGISTRATION_FORM_FAILURE,
  SUBMIT_REGISTRATION_FORM_STARTED,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  SUBMIT_UPDATED_REGISTRATION_FORM_FAILURE,
  SUBMIT_UPDATED_REGISTRATION_FORM_STARTED,
  SUBMIT_UPDATED_REGISTRATION_FORM_SUCCESS,
  UNLIKE_REGISTRATION_POST,
  UNLIKE_REGISTRATION_POST_FAILURE,
  UPDATE_REGISTRATION_FORM,
  UPDATE_REGISTRATION_FORM_ACTIVE_REQUEST_HANDLERS,
  UPDATE_REGISTRATION_ADMIN_REPLY_QUESTION_EDIT,
  UPDATE_REGISTRATION_QUESTION_ANSWER,
  UPDATE_REGISTRATION_QUESTION_ANSWER_SUCCESS,
  UPDATE_REGISTRATION_QUESTION_ANSWER_FAILURE,
  GET_REGISTRATION_CONFIG_SUCCESS,
  GET_REGISTRATION_CONFIG_FAILURE,
  DEACTIVATE_REGISTRATION_POST_SUCCESS,
  GET_REGISTRATION_TABS_SUCCESS,
  GET_REGISTRATION_TABS_FAILURE,
  SET_CURRENT_TAB,
  RESET_REGISTRATION_TAB_FEED,
  GET_REGISTRATION_TABS_POSTS,
  GET_REGISTRATION_TABS_POSTS_SUCCESS,
  GET_REGISTRATION_TABS_POSTS_FAILURE,
  END_OF_REGISTRATIONS_TABS_POSTS,
  REFETCH_REGISTRATION_TABS_POST_SUCCESS,
  REFETCH_REGISTRATION_TABS_POST_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  // Registration form
  form: {
    registration: null,
    notValidQuestionIds: [],
    error: false,
    loading: true,
    userId: null,
    activeRequestHandlers: 0,
  },

  // Posts
  all: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  mostLiked: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  mine: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  toMe: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  processflow: {
    tabs: {},
    currentTab: {},
    loading: false,
    endOfFeed: false,
    error: false,
  },
  postsWithLoadingSwipes: [],
  postsWithLoadingLikes: [],

  // Statistics
  statistics: {
    posts: {
      all: {
        sent: null,
      },
      mine: {
        sent: null,
        received: null,
      },
    },
  },

  // Regstration config
  registrationConfig: {
    hideAuthorInfo: true,
    likeTextIdentifiers: {
      youLikeThis: "youLikeThis",
      youAndOneOtherLikeThis: "youAnd__placeholder__other",
      youAndMultipleOthersLikeThis: "youAnd__placeholder__others",
      oneOrManyOtherLikeThis: "__placeholder__likeThis",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REGISTRATION_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: true,
          error: false,
        },
      };

    case GET_REGISTRATION_POSTS_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          posts: [...state[action.payload.feedType].posts, ...action.payload.posts],
        },
      };

    case GET_REGISTRATION_TABS_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: true,
          error: false,
        },
      };

    case GET_REGISTRATION_TABS_POSTS_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          tabs: {
            ...state[action.payload.feedType].tabs,
            [action.payload.tab]: {
              ...state[action.payload.feedType].tabs[action.payload.tab],
              posts: [...state[action.payload.feedType].tabs[action.payload.tab].posts, ...action.payload.posts],
            },
          },
        },
      };

    case GET_REGISTRATION_TABS_POSTS_FAILURE:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: true,
        },
      };

    case GET_REGISTRATION_POSTS_FAILURE:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: true,
        },
      };

    case END_OF_REGISTRATIONS_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case END_OF_REGISTRATIONS_TABS_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case READ_REGISTRATION_POST:
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes, action.payload],
      };

    case UNLIKE_REGISTRATION_POST:
    case LIKE_REGISTRATION_POST: {
      return {
        ...state,
        postsWithLoadingLikes: [...state.postsWithLoadingLikes, action.payload],
      };
    }

    case REFETCH_REGISTRATION_POST_SUCCESS: {
      const { feedType, post } = action.payload;
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes.map((postId) => postId !== post.id)],
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.map((p) => (p.id === post.id ? post : p))],
        },
      };
    }

    case REFETCH_REGISTRATION_TABS_POST_SUCCESS: {
      const { feedType, tab, post } = action.payload;
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          tabs: {
            ...state[feedType].tabs,
            [tab]: {
              ...state[feedType].tabs[tab],
              posts: [...state[feedType].tabs[tab].posts.map((p) => (p.id === post.id ? post : p))],
            },
          },
        },
      };
    }

    case REFETCH_REGISTRATION_POST_LIKES_SUCCESS: {
      const { feedType, postId, likes } = action.payload;
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.map((p) => (p.id === postId ? { ...p, likes } : p))],
        },
        postsWithLoadingLikes: [...state.postsWithLoadingLikes.filter((pId) => pId !== postId)],
      };
    }

    case REFETCH_REGISTRATION_TABS_POST_FAILURE: {
      const feedType = action.payload;
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          error: true,
        },
      };
    }

    case UNLIKE_REGISTRATION_POST_FAILURE:
    case LIKE_REGISTRATION_POST_FAILURE:
    case REFETCH_REGISTRATION_POST_LIKES_FAILURE: {
      return {
        ...state,
        postsWithLoadingLikes: [...state.postsWithLoadingLikes.map((postId) => postId !== action.payload)],
        ...state[action.payload.feedType],
        error: true,
      };
    }

    case GET_REGISTRATION_POSTS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          posts: {
            ...state.statistics.posts,
            all: action.payload.all,
            mine: action.payload.mine,
          },
        },
      };

    case READ_REGISTRATION_POST_FAILURE:
    case REFETCH_REGISTRATION_POST_FAILURE:
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes.map((postId) => postId !== action.payload)],
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          error: true,
        },
      };

    case RESET_REGISTRATION_FEED: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          posts: [],
          error: false,
          endOfFeed: false,
          loading: false,
        },
      };
    }

    case GET_REGISTRATION_FORM_SUCCESS: {
      return {
        ...state,
        form: {
          ...state.form,
          registration: action.payload.registration,
          notValidQuestionIds: action.payload.notValidQuestionIds,
          loading: false,
        },
      };
    }

    case GET_REGISTRATION_FORM_FAILURE: {
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };
    }

    case UPDATE_REGISTRATION_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          registration: action.payload.registration,
          notValidQuestionIds: action.payload.notValidQuestionIds,
        },
      };
    }

    case SUBMIT_UPDATED_REGISTRATION_FORM_STARTED:
    case SUBMIT_REGISTRATION_FORM_STARTED: {
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      };
    }

    case SUBMIT_UPDATED_REGISTRATION_FORM_SUCCESS:
    case SUBMIT_REGISTRATION_FORM_SUCCESS: {
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          error: false,
        },
      };
    }

    case SUBMIT_UPDATED_REGISTRATION_FORM_FAILURE:
    case SUBMIT_REGISTRATION_FORM_FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          error: true,
        },
      };

    case RESET_REGISTRATION_FORM:
      return {
        ...state,
        form: { ...initialState.form },
      };

    case RESET_REGISTRATION: {
      return {
        ...initialState,
      };
    }

    case UPDATE_REGISTRATION_FORM_ACTIVE_REQUEST_HANDLERS:
      return {
        ...state,
        form: {
          ...state.form,
          activeRequestHandlers: state.form.activeRequestHandlers + action.payload,
        },
      };

    case GET_REGISTRATION_CONFIG_SUCCESS:
      return {
        ...state,
        registrationConfig: {
          ...action.payload,
          likeTextIdentifiers: {
            ...initialState.registrationConfig.likeTextIdentifiers,
            ...action.payload.likeTextIdentifiers,
          },
        },
      };

    case GET_REGISTRATION_CONFIG_FAILURE:
      return {
        ...state,
        registrationConfig: initialState.registrationConfig,
      };

    case DEACTIVATE_REGISTRATION_POST_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          posts: state[action.payload.feedType].posts.filter((post) => post.id !== action.payload.postId),
        },
      };

    case GET_REGISTRATION_TABS_SUCCESS:
      const tabKey = Object.keys(action.payload)[0];
      return {
        ...state,
        processflow: {
          ...state.processflow,
          tabs: action.payload,
          currentTab: { tab: tabKey, title: action.payload[tabKey].title, index: 0 },
        },
      };

    case GET_REGISTRATION_TABS_FAILURE:
      return {
        ...state,
        processflow: {
          ...state.processflow,
          tabs: action.payload,
        },
      };

    case SET_CURRENT_TAB:
      return {
        ...state,
        processflow: {
          ...state.processflow,
          currentTab: { tab: action.payload.tab, title: action.payload.title, index: action.payload.index },
        },
      };

    case RESET_REGISTRATION_TAB_FEED:
      return {
        ...state,
        processflow: {
          ...state.processflow,
          tabs: {
            ...state.processflow.tabs,
            [action.payload]: {
              ...state.processflow.tabs[action.payload],
              posts: [],
            },
          },
          endOfFeed: false,
          loading: false,
          error: false,
        },
      };

    default:
      return state;
  }
}
