export default function (icon) {
  // Set touch icons
  document.getElementById("elAppleTouchIcon").setAttribute("href", `${icon.baseURL}${icon.image}`);
  document.getElementById("elAppleTouchIcon72x72").setAttribute("href", `${icon.baseURL}${icon.image}`);
  document.getElementById("elAppleTouchIcon114x114").setAttribute("href", `${icon.baseURL}${icon.image}`);
  document.getElementById("elFavicon").setAttribute("href", `${icon.baseURL}w_72,h_72,r_14/${icon.image}`);

  /* Insert splash screens for ios */
  document.querySelector("head").insertAdjacentHTML(
    "afterbegin",
    `<link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1136,h_640/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2436,h_1125/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1792,h_828/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_828,h_1792/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1334,h_750/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1242,h_2688/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2208,h_1242/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1125,h_2436/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1242,h_2208/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2732,h_2048/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2688,h_1242/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2224,h_1668/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_750,h_1334/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2048,h_2732/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2388,h_1668/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1668,h_2224/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_640,h_1136/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1668,h_2388/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_2048,h_1536/${icon.image}"
    />
    <link
      rel="apple-touch-startup-image"
      media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      href="${icon.baseURL}c_fill,g_xy_center,h_250,w_250,r_10/c_lpad,b_rgb:e8ebed,w_1536,h_2048/${icon.image}"
    />`
  );
}
