import { css } from "emotion";
import breakpoints from "../../../../../config/breakpoints";

export default  () => css`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  overflow: auto;

  .create-page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: ${breakpoints.lg}px;
    padding-bottom: 2rem;

    .create-btn-wrapper {
      display: flex;
      width: 100%;

      button {
        flex: 1;
        padding: 1rem;
        margin: 1rem 0.65rem;
      }
    }
  }
`;