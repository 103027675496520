// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ListItem from "../ui/ListItem";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";
import breakpoints from "../../config/breakpoints";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import getAppName from "../../utilities/get-app-name";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import { CheckCircleIcon, ChevronRightIcon, ClockOutlineIcon, FeatureSearchOutlineIcon } from "mdi-react";
import { parseDatetimeExtended } from "../../utilities/parse-date";

// Styles
import colors from "../../style/colors";
import iconConverter from "../../utilities/icon-converter";
import Notification from "../ui/Notification";
import * as queryString from "query-string";

const SurveyNavigationList = (props) => {
  const pages = useSelector((s) => s.pages.pages);
  const lang = useSelector((s) => s.language.language);
  const page = getPageFromId(pages, props.match.params.pageId);

  let { dataId: groupId } = queryString.parse(window.location.search) || undefined;

  const [surveys, setSurveys] = useState({
    data: [],
    error: false,
    loading: true,
  });

  function getPotentialPages() {
    if (!page.pages || page.pages.length === 0) return [];

    const subPages =
      page.pages.length === 1 && page.pages[0].pages && page.pages[0].pages.length > 0 ? page.pages[0].pages : page.pages;
    return subPages.map((subPage) => (
      <ListItem
        maxWidth={breakpoints.lg}
        clickable={true}
        onClick={() =>
          pageNavigatorV2({ path: linkPathCreator(subPage, "absolute"), direction: "forward", pageId: subPage.id })
        }
        title={subPage.title}
        iconLeft={subPage.icon ? iconConverter(subPage.icon, { color: colors.darkGrey }) : null}
        notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
        iconRight={<ChevronRightIcon />}
        key={subPage.id}
      />
    ));
  }

  useEffect(() => {
    const getSurveys = async () => {
      try {
        let URL = `surveys`;

        if (groupId) URL += `?groupId=${groupId}`;

        let { data } = await req()(URL);
        setSurveys({ error: false, loading: false, data });
      } catch (error) {
        setSurveys({ ...surveys, error: true, loading: false });
      }
    };
    getSurveys();
    // eslint-disable-next-line
  }, []);

  const { loading, error } = surveys;
  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <ScrollView style={{ padding: "1rem 0" }}>
        {loading && !error && <Spinner />}
        {!loading && error && <StatusBox />}
        {!loading && !error && surveys.data.length === 0 && (
          <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
        )}

        {getPotentialPages()}

        {!loading &&
          !error &&
          surveys.data.map((survey) => (
            <ListItem
              maxWidth={breakpoints.lg}
              clickable={true}
              onClick={
                survey.answer
                  ? () =>
                      pageNavigatorV2({
                        path: `/${getAppName()}/survey/${page.id}/${survey.id}?dataId=${survey.answer.id}`,
                        direction: "forward",
                      })
                  : () =>
                      pageNavigatorV2({
                        path: `/${getAppName()}/registration-form/${page.id}?dataId=${survey.id}`,
                        direction: "forward",
                      })
              }
              title={survey.title}
              iconLeft={
                survey.answer ? (
                  <CheckCircleIcon style={{ fill: colors.green }} />
                ) : (
                  <ClockOutlineIcon style={{ fill: colors.darkGrey }} />
                )
              }
              subTitle={
                survey.answer ? `Besvaret: ${parseDatetimeExtended(survey.answer.createdAt)}` : `Ikke besvaret endnu`
              }
              iconRight={<ChevronRightIcon />}
              key={survey.id}
            />
          ))}
      </ScrollView>
    </Page>
  );
};

export default SurveyNavigationList;
