/* This file also exists on the server. Make sure to keep them in sync (if you add or remove 
  anything from here, go to the addFile endpoint on the server and do the same) */
const ALLOWED_FILE_TYPES = [
  "doc",
  "docx",
  "odt",
  "txt",
  "pdf",
  "xls",
  "xlsx",
  "zip",
  "heic",
  "jpg",
  "jpeg",
  "png",
  "gif",
  "svg",
  "odp",
  "pps",
  "ppt",
  "pptx",
  "mp3",
  "mp4",
  "wav",
  "ogg",
];

export default ALLOWED_FILE_TYPES;
