import store from "../../../store";
import { isTargetGroupEmpty } from "../../../utilities/is-targetgroup-empty";

/**
 *
 * @param {Object} anon
 * @param {Object} anon.targetGroup TargetGroup object
 * @param {String} anon.noUsersSelectedOverwrite String to overwrite the default placeholder when no users is selected
 */
export default function getTargetGroupPlaceholder({
  targetGroup: { users = [], userGroups = [], masterGroups = [], customGroups = [], jobTitles = [] },
  noUsersSelectedOverwrite,
}) {
  const lang = store.getState().language.language;

  if (isTargetGroupEmpty({ users, userGroups, masterGroups, customGroups, jobTitles })) {
    if (noUsersSelectedOverwrite) {
      return noUsersSelectedOverwrite;
    } else {
      return lang.noUsersSelected?.trim();
    }
  }

  let title = `${lang.chosen?.trim()}: `;

  let selections = [];

  if (Array.isArray(users) && users.length > 0) {
    let count = users.length;
    selections.push(count === 1 ? `${count} ${lang.user}` : `${count} ${lang.users}`);
  }

  if (Array.isArray(userGroups) && userGroups.length > 0) {
    let count = userGroups.length;
    selections.push(count === 1 ? `${count} ${lang.subGroup}` : `${count} ${lang.subGroups}`);
  }

  if (Array.isArray(masterGroups) && masterGroups.length > 0) {
    let count = masterGroups.length;
    selections.push(count === 1 ? `${count} ${lang.mainGroup}` : `${count} ${lang.mainGroups}`);
  }

  if (Array.isArray(customGroups) && customGroups.length > 0) {
    let count = customGroups.length;
    selections.push(count === 1 ? `${count} ${lang.customGroup}` : `${count} ${lang.customGroups}`);
  }

  if (Array.isArray(jobTitles) && jobTitles.length > 0) {
    let count = jobTitles.length;
    selections.push(count === 1 ? `${count} ${lang.jobTitle}` : `${count} ${lang.jobTitles}`);
  }

  return title + selections.join(", ");
}
