// Libs
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Config
import { feedTypes } from "./config";
import pageConfig from "../../config/pageConfig";

// Redux actions
import { getPosts, resetFeeds, resetFeed, updateUnreadCount, getFrontendAdminMapping } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Components
import NewsPost from "./NewsPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import StatusBox from "../ui/StatusBox";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import Notification from "../ui/Notification";
import { PlusIcon, InfoOutlineIcon } from "mdi-react";
import NewsPostModal from "./NewsPostModal";
import { PostSkeletonGroup } from "../ui/PostSkeleton";

// Utilities
import useCurrentPage from "../../hooks/useCurrentPage";
import extractValueFromObject from "../../utilities/extract-value-from-object";

// Context
import { PageContext } from "./context/PageContext";

function NewsFeedTabs(props) {
  const dispatch = useDispatch();

  // page setup
  const { match, getPosts, resetFeeds, showModalPage } = props;
  const lang = useSelector((state) => state.language.language);
  const admin = useSelector((state) => state.auth.user.admin);
  const frontendAdminMappings = useSelector((state) => state.news.frontendAdminMappings);

  const page = useCurrentPage({ pageId: match.params.pageId });

  const sort = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SORT_ORDER });

  let readActionLangOverwrite = (page && page.config && page.config[pageConfig.READ_ACTION_LANG_OVERWRITE]) || false;

  // Data
  const { all, unread, read } = useSelector((state) => state.news);

  // UI Layout
  const tabs = [
    lang.all,
    <>
      {lang.unread} <Notification style={{ marginRight: "-15px" }} notifications={unread.count} />
    </>,
    lang.read,
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    resetFeeds();
    setTimeout(() => dispatch(getFrontendAdminMapping()), 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!page.dataId) return;
    dispatch(updateUnreadCount({ subTypeId: page.dataId }));
  }, [page, dispatch]);

  useEffect(() => {
    getPostsByActiveTab();
    // eslint-disable-next-line
  }, [activeTab]);

  function getPostsByActiveTab() {
    let curFeedType = "";
    if (activeTab === 0) curFeedType = feedTypes.all;
    if (activeTab === 1) curFeedType = feedTypes.unread;
    if (activeTab === 2) curFeedType = feedTypes.read;

    // let curFeedType = activeTab === 0 ? feedTypes.unread : feedTypes.read;
    getPosts({ subTypeId: page.dataId, feedType: curFeedType, sort });
  }

  /** Checks if feed is scrollable or at the end
   * If the feed isn't scrollable and it isn't at the end, you need to fetch more posts as a scroll
   * trigger could never be activated without scroll
   */
  useEffect(() => {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view.tab");
      if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) getPostsByActiveTab();
    }, 500); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly

    // eslint-disable-next-line
  }, [unread, read]);

  function hasAdminAccess() {
    return admin || frontendAdminMappings.some((subTypeId) => subTypeId == page.dataId);
  }
  return (
    <Page>
      <PageContext.Provider value={page.id}>
        <TopBar
          useDefaultBackButton={true}
          title={page.title}
          actionRight={
            hasAdminAccess() ? (
              <ActionWrapper
                data-test-id="btn-show-add-post-modal"
                onClick={() =>
                  showModalPage({
                    useScrollView: false,
                    content: (
                      <NewsPostModal
                        subTypeId={page.dataId}
                        feedType={activeTab === 0 ? feedTypes.unread : feedTypes.read}
                      />
                    ),
                  })
                }
              >
                <PlusIcon />
              </ActionWrapper>
            ) : null
          }
        />
        <TabBar tabs={tabs.map((t, i) => ({ title: t, onClick: () => setActiveTab(i) }))} activeTabIndex={activeTab} />
        <TabView
          activeTabIndex={activeTab}
          onScrollEnd={getPostsByActiveTab}
          tabs={[
            /***************** All *****************/
            <>
              <div style={{ height: "1rem" }} />

              {/* Content */}
              {all.posts.map((p) => (
                <NewsPost
                  readActionLangOverwrite={readActionLangOverwrite}
                  post={p}
                  feedType={feedTypes.all}
                  subTypeId={page.dataId}
                  key={`news-post-${p.id}`}
                />
              ))}

              {/* Loading */}
              {all.loading && !all.error && !all.endOfFeed && (
                <PostSkeletonGroup numberOfPosts={all.posts.length === 0 ? 2 : 1} />
              )}

              {/* End of feed with posts */}
              {all.posts.length > 0 && all.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noMorePosts}
                  content={lang.reachedEndOfFeed}
                />
              )}

              {/* End of feed without posts */}
              {all.posts.length === 0 && all.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noPostsHere}
                  content={lang.comeBackSoon}
                />
              )}
            </>,
            /***************** Unread *****************/
            <>
              <div style={{ height: "1rem" }} />

              {/* Content */}
              {unread.posts.map((p) => (
                <NewsPost
                  readActionLangOverwrite={readActionLangOverwrite}
                  post={p}
                  feedType={feedTypes.unread}
                  subTypeId={page.dataId}
                  key={`news-post-${p.id}`}
                />
              ))}

              {/* Loading */}
              {unread.loading && !unread.error && !unread.endOfFeed && (
                <PostSkeletonGroup numberOfPosts={unread.posts.length === 0 ? 2 : 1} />
              )}

              {/* End of feed with posts */}
              {unread.posts.length > 0 && unread.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noMorePosts}
                  content={lang.reachedEndOfFeed}
                />
              )}

              {/* End of feed without posts */}
              {unread.posts.length === 0 && unread.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noPostsHere}
                  content={lang.comeBackSoon}
                />
              )}
            </>,

            /***************** Read *****************/
            <>
              <div style={{ height: "1rem" }} />

              {/* Content */}
              {read.posts.map((p) => (
                <NewsPost
                  readActionLangOverwrite={readActionLangOverwrite}
                  post={p}
                  feedType={feedTypes.read}
                  subTypeId={page.dataId}
                  key={`news-post-${p.id}`}
                />
              ))}

              {/* Loading */}
              {read.loading && !read.error && !read.endOfFeed && (
                <PostSkeletonGroup numberOfPosts={read.posts.length === 0 ? 2 : 1} />
              )}

              {/* End of feed with posts */}
              {read.posts.length > 0 && read.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noMorePosts}
                  content={lang.reachedEndOfFeed}
                />
              )}

              {/* End of feed without posts */}
              {read.posts.length === 0 && read.endOfFeed && (
                <StatusBox
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.noPostsHere}
                  content={lang.comeBackSoon}
                />
              )}
            </>,
          ]}
        ></TabView>
      </PageContext.Provider>
    </Page>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
  resetFeed: bindActionCreators(resetFeed, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedTabs);
