import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage } from "../../actions/uiActions";
import { css } from "emotion";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { durations } from "../../config/animations";
import { CloseIcon } from "mdi-react";
import Page from "./Page";
import Overlay from "./Overlay";

// Components
import TopBar from "./TopBar";
import ActionWrapper from "./ActionWrapper";
import ScrollView from "./ScrollView";

const ModalPage = (props) => {
  let modalPages = props.modalPages;

  const getContent = ({ useScrollView, content }) => {
    if (useScrollView === false) {
      if (content && typeof content === "function") return content();
      if (content && typeof content === "object") return content;
    } else {
      return (
        <ScrollView>
          {content && typeof content === "function" && content()}
          {content && typeof content === "object" && content}
        </ScrollView>
      );
    }
  };

  return (
    <div className={componentStyles()}>
      <TransitionGroup appear={true} exit={true}>
        {modalPages.map(
          ({ id, active, closeCallback, title, actionRight, content, useScrollView = true, pageStyle = {} }) => (
            <CSSTransition key={id} timeout={durations.normal} classNames="modal-page-wrapper">
              <div>
                <Overlay active={true} />
                <Page data-test-id="modal-page" style={pageStyle} className={"modal-page"}>
                  <TopBar
                    actionLeft={
                      <ActionWrapper
                        onClick={closeCallback ? () => closeCallback(hideModalPage) : props.hideModalPage}
                        data-test-id="modal-page__action-left"
                      >
                        <CloseIcon />
                      </ActionWrapper>
                    }
                    actionRight={actionRight}
                    title={title}
                    hideHomeIcon={true}
                  />
                  {getContent({ useScrollView, content })}
                </Page>
              </div>
            </CSSTransition>
          )
        )}
      </TransitionGroup>
    </div>
  );
};

const componentStyles = () => css`
  position: fixed;
  top: 0;
  left: 0;
  will-change: auto;
  z-index: 10;

  // Before enter
  .modal-page-wrapper-enter,
  .modal-page-wrapper-appear {
    .overlay {
      opacity: 0;
    }

    .modal-page {
      opacity: 0;

      /**
     Animates from scren edge for screen less than 700px, for screen larger than 700px
     it will animate with a fixed pixel offset since the duration of the animations is fixed
     and a fast animation from screen edge has to rapid movement. Its also quite difficult
     to get it to run smooth on smaller hardware 
     */
      @media screen and (max-height: 700px) {
        transform: translate3d(0, 100%, 0);
      }
      @media screen and (min-height: 701px) {
        transform: translate3d(0, 300px, 0);
      }
    }
  }

  // Enter active
  .modal-page-wrapper-enter-active,
  .modal-page-wrapper-appear-active {
    .overlay {
      opacity: 1;
      transition: opacity ${durations.normal}ms;
    }

    .modal-page {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
    }
  }

  // Before exit
  .modal-page-wrapper-exit {
    .overlay {
      opacity: 1;
    }

    .modal-page {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }
  }

  // Before exit
  .modal-page-wrapper-exit-active {
    .overlay {
      opacity: 0;
      transition: opacity ${durations.normal}ms;
    }

    .modal-page {
      opacity: 0;
      @media screen and (max-height: 700px) {
        transform: translate3d(0, 100%, 0);
      }
      @media screen and (min-height: 701px) {
        transform: translate3d(0, 300px, 0);
      }
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
    }
  }

  .item-enter,
  .item-appear {
    transform: translateY(100%);
    opacity: 0;
  }
  .item-enter-active,
  .item-appear-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms ease-in, transform 500ms ease;
  }
  .item-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .item-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms ease-in, transform 500ms ease;
  }
`;

const container = () => css`
  position: fixed;
  top: 0;
  left: 0;
  will-change: auto;
  z-index: 10;

  .modal-page {
    position: fixed;
    top: 0;
    left: 0;
    will-change: auto;

    &.modal-page-enter {
      opacity: 0;

      /**
     Animates from scren edge for screen less than 700px, for screen larger than 700px
     it will animate with a fixed pixel offset since the duration of the animations is fixed
     and a fast animation from screen edge has to rapid movement. Its also quite difficult
     to get it to run smooth on smaller hardware 
     */
      @media screen and (max-height: 700px) {
        transform: translate3d(0, 100%, 0);
      }
      @media screen and (min-height: 701px) {
        transform: translate3d(0, 300px, 0);
      }
    }
    &.modal-page-enter-active {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
    }
    &.modal-page-exit {
      opacity: 1;
      transform: translate3d(0, 0px, 0);
    }
    &.modal-page-exit-active {
      opacity: 0;
      @media screen and (max-height: 700px) {
        transform: translate3d(0, 100%, 0);
      }
      @media screen and (min-height: 701px) {
        transform: translate3d(0, 300px, 0);
      }
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
    }
  }
`;

const mapStateToProps = (state) => ({
  modalPages: state.ui.modalPages,
  primaryColor: state.appConfig.primaryColor,
});

const mapDispatchToProps = (dispatch) => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPage);
