// Libs
import React from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { format, parse } from "date-fns";
import { css } from "emotion";

// Styles
import { InfoOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

// Components
import Post from "../ui/Post";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import RegistrationPostContent from "./components/RegistrationPostContent";

// Actions
import { showModalPage, updateModalPage } from "../../actions/uiActions";

// Utilities and config
import { postLayoutModes } from "./config";
import getUserLocale from "../../utilities/get-user-locale";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import { parseDatetimeExtended } from "../../utilities/parse-date";
import useAuthorModal from "../../hooks/useAuthorModal";

const RegistrationMostLikedPosts = (props) => {
  const { registrationConfig, layoutMode, searchTerm, showModalPage, updateModalPage } = props;

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // Redux state
  const { loading, endOfFeed, error, posts } = useSelector((state) => state.registration.mostLiked);

  // Used for getUserLocale()
  const { user } = useSelector((state) => state.auth);

  // To get the users information when clicked on the image
  const authorModal = useAuthorModal(showModalPage, updateModalPage);

  return (
    <div className={componentStyle()}>
      <div className="top-spacing-container" />
      <div className={componentStyle()}>
        {/* Content */}
        {posts.map((p) => (
          <Post
            key={`postId-${p.id}`}
            date-test-id="registration-post"
            className="post"
            useExpandCollapseLayout={layoutMode === postLayoutModes.accordion}
            expandCollapseToggle={
              <div style={{ display: "flex", alignItems: "center" }}>
                {p.author ? (
                  <>
                    {getProfilePictureFromUserObject(p.author)}
                    <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                      <p>{p.author.name}</p>
                      <p className="meta" style={{ color: colors.darkGrey }}>
                        {parseDatetimeExtended(p.date)}
                      </p>
                    </div>
                  </>
                ) : (
                  <p>parseDatetimeExtended(p.date)</p>
                )}
              </div>
            }
            date={format(parse(p.date, "yyyyMMddHHmmss", 0), "do MMMM yyyy", getUserLocale(user))}
            componentContent={<RegistrationPostContent answers={p.answers} searchTerm={searchTerm} />}
            likeTextIdentifiers={registrationConfig ? registrationConfig.likeTextIdentifiers : null}
            points={p.points}
            author={registrationConfig.hideAuthorInfo ? null : p.author}
            likes={p.likes}
            disableSwipe={true}
            onAuthorClick={() => authorModal(p.author.id)}
            hideLikeButton={true}
            readDate={p.readDate}
          />
        ))}

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* End of feed with posts */}
        {posts.length > 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "4rem", marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noMorePosts}
            content={lang.reachedEndOfFeed}
          />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "4rem", marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}
      </div>
    </div>
  );
};

const componentStyle = () => css`
  .top-spacing-container {
    height: 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationMostLikedPosts);
