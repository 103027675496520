import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styleTypeNormalizer from "../../utilities/style-type-normalizer";
import InlineSpinner from "./InlineSpinner";
import colors from "../../style/colors";

/** Generic button component to be used across our application.
 * @param {*} props
 * @param {string} props.styleType - The type of button. Supported types: `neutral|error|secondary`
 * @param {boolean} props.disabled - Makes the button disabled
 * @param {boolean} props.active - Shows a spinner inside the button
 * @param {function} props.onClick - Main event handler
 * @param {string} props['data-test-id'] - Test id
 * @param {reactElement} props.iconLeft - DEPRECATION WARNING! May be removed! See examples. DynamicIcon to display left of the title
 * @param {reactElement} props.iconRight - DEPRECATION WARNING! May be removed! See examples. DynamicIcon to display right of the title
 * @param {string} props.title - DEPRECATION WARNING! May be removed! See examples. Title of the button
 *
 * Previously we used props to control title and icons but have since opted in for using
 * the children prop, making it way more flexible.
 *
 * @example
 * ```jsx static
 * // Current usage
 * <Button>
 *   <TickIcon />
 *   Terminate
 * </Button>
 *
 *
 * // LEGACY USAGE! DONT DO THIS GOING FORWARD!
 * <Button
 *   iconLeft={<TickIcon style={{ marginRight: "0.5rem" }} />}
 *   title={"Terminate"}
 * />
 * ```
 */
const ColorPicker = ({ value, onChange, style = {} }) => (
  <div className={componentStyles()} style={style}>
    <input type="color" value={value} onChange={onChange} />
    <input type="text" value={value} onChange={onChange} />
  </div>
);

const componentStyles = (props) => css`
  border: 1px var(--midGrey) solid;
  border-radius: 3px;
  display: flex;
  position: relative;
  overflow: hidden;

  input[type="text"] {
    border: 0px;
    font-size: 0.95rem;
    color: var(--black);
    flex-grow: 1;
    padding-left: 60px;
    line-height: 42px;
  }
  input[type="color"] {
    position: absolute;
    left: -2px;
    top: -4px;
    border: 1px red solid;
    -webkit-appearance: none;
    width: 50px;
    height: 50px;
    margin: 0px;
    border: 0;
    background-color: transparent;
  }
`;

export default ColorPicker;
