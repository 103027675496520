// Config
import pageConfig from "../../../config/pageConfig";

export function getSubTitle({ contact, config, appConfig }) {
  let str = "";

  if (contact.application) str += `${contact.application.title}, `;

  if (appConfig.showUserGroup === true && contact.userGroup) str += `${contact.userGroup.title}, `;

  if (appConfig.showMasterGroup === true && contact.masterGroup) str += `${contact.masterGroup.title}, `;

  if (((config && config[pageConfig.HIDE_KEYPERSON_GROUP] !== true) || !config) && contact.contactGroup) {
    str += `${contact.contactGroup.title || contact.contactGroup.groupName || ""}, `;
  }
  if (contact.contactPersonTitle) str += `${contact.contactPersonTitle}, `;

  // Replaces trailing ", " with ""
  str = str.replace(/, $/gm, "");

  return str;
}
