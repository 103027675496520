// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Components
import ScrollView from "../ui/ScrollView";

// Utilities and config
import breakpoints from "../../config/breakpoints";

// Styles
import colors from "../../style/colors";
import req from "../../utilities/request-utility";
import Spinner from "../ui/InlineSpinner";
import FormBuilderView from "../ui/FormBuilderView";

/**
 * Shows the answers to the questions, on which the user answered when he attended the event
 */

const boxPadding = 0.75;

const EventAnswers = () => {
  // Event from redux store
  const { id: eventId } = useSelector((state) => state.event.selectedEvent);
  const { id: userId } = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (eventId && userId) {
      req()(`events/${eventId}/attendees/${userId}/answers`).then(({ data }) => {
        setQuestions(data.questions);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [eventId, userId]);

  return (
    <ScrollView className={componentStyle(breakpoints, colors)}>
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            {questions.map((question, questionIndex) => (
              <FormBuilderView
                style={{ marginBottom: "1.5rem" }}
                key={`event-form-question-answer-${questionIndex}`}
                question={question}
              />
            ))}
          </Fragment>
        )}
      </div>
    </ScrollView>
  );
};

const componentStyle = () => css`
  background-color: var(--white);
  width: 100%;
  height: 100%;
  overflow: auto;

  .container {
    background-color: var(--white);
    max-width: ${breakpoints.md}px;
    margin: auto;
    padding: ${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      padding-top: ${boxPadding * 2}rem;
    }
  }
`;

export default EventAnswers;
