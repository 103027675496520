// Libs
import React from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import DropDown from "../../ui/dropDown/components/DropDown";

// Styles
import colors from "../../../style/colors";

// Config
import { SHOW_ALL, ACTIVATED, DEACTIVATED } from "../config/filterTypes";

// Actions
import { updatePageFilter } from "../../../actions/pageBuilderFilterActions";

const PageBuilderHeaderOptions = (props) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.language);
  const filters = useSelector((state) => state.pageBuilderFilter.filters);

  function onChangeHandler(e) {
    dispatch(updatePageFilter(e.target.name, e.target.value));
  }

  return (
    <div className={`${componentStyle()} ${props.className ? props.className : ""}`}>
      <div className="option">
        <label>{`${lang.active} ${lang.modules?.toLowerCase() ?? ""}`}</label>
        <DropDown
          onChange={onChangeHandler}
          name="active"
          value={filters.active}
          options={[
            { id: SHOW_ALL, name: lang.showAll },
            { id: ACTIVATED, name: lang.active },
            { id: DEACTIVATED, name: lang.disabled },
          ]}
        />
      </div>
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  margin-top: 0.5rem;

  .option {
    flex: 1 0;

    label {
      color: var(--darkGrey);
    }
  }
`;

export default PageBuilderHeaderOptions;
