import React from "react";
import { registrationTypes } from "../../registration/config";

function RegistrationCardContent({ question }) {
  if (question.type === registrationTypes.DROPDOWN_FORM_BUILDER) {
    return (
      <>
        <span className="label">{question.title}:</span>{" "}
        {question?.answer.map((answer) => (
          <div key={`question-data-form-builder-${question.id}-${answer.id}`} className="box-wrapper">
            <div className="title-wrapper">
              <p>{answer.value?.title}</p>
              {answer.isShared && <p className="shared">Delt tillæg</p>}
            </div>
            <p>{answer.value?.amount}</p>
            <p>{answer.value?.comment}</p>
          </div>
        ))}
      </>
    );
  } else if (question?.answer?.externalOptionId) {
    return (
      <p key={`question-data-${question.id}`}>
        <span className="label">{question?.title}:</span> {question?.answer?.value}{" "}
        {question?.answer?.externalOptionId ? `(${question?.answer?.externalOptionId})` : ""}
      </p>
    );
  } else {
    return (
      <p key={`question-identifier-${question.id}`}>
        <span className="label">{question.title}:</span> {question.answer?.value}{" "}
        {question?.answer?.optionId ? `(${question.answer?.optionId})` : ""}
      </p>
    );
  }
}

export default RegistrationCardContent;
