import colors from "../../../../style/colors";
import { useDispatch, useSelector } from "react-redux";
import ButtonRounded from "../../ButtonRounded";
import styleTypes from "../../../../config/styleTypes";
import ListItem from "../../ListItem";
import useAuthorModal from "../../../../hooks/useAuthorModal";
import ActionWrapper from "../../ActionWrapper";
import showGroupMembers from "../utilities/showGroupMembers";
import StatusBox from "../../StatusBox";
import { InformationOutlineIcon, TrashCanOutlineIcon } from "mdi-react";
import { groupTypes } from "../config/groupTypes";
import { getSimpleUserSubTitle } from "../utilities/getUserSubTitle";
import InlineSpinner from "../../InlineSpinner";
import useLang from "../../../../hooks/useLang";
import { replaceNormalPlaceholder } from "../../../../utilities/lang-utilties";
import getGroupTypeName from "../utilities/getGroupTypeName";

function TargetGroupPanelTab({
  groupType,
  idField = "id",
  titleField = "title",
  countField = "membersCount",
  removeOneFunction,
  removeAllFunction,
}) {
  const lang = useLang();
  const dispatch = useDispatch();
  const authorModal = useAuthorModal();

  const { data, loading, error } = useSelector((s) => s.targetGroupModal[groupType]);
  const targetGroupData = useSelector((s) => s.targetGroupModal.targetGroup[groupType]);

  const matchingData = targetGroupData
    .map((tgIds) => data.find((d) => d[idField] === tgIds))
    .sort((a, b) => (a[titleField].toLowerCase() < b[titleField].toLowerCase() ? -1 : 1));

  if (loading) {
    return (
      <div className="loading-container">
        <InlineSpinner size="28" />
        <p className="meta">{lang.loading}...</p>
      </div>
    );
  }

  function onRemoveDataEntry(e, id) {
    e.stopPropagation();
    dispatch(removeOneFunction(id));
  }

  return (
    <div className="list-items-container">
      {targetGroupData.length > 0 && (
        <ButtonRounded
          style={{ marginBottom: "1rem" }}
          onClick={() => dispatch(removeAllFunction())}
          secondary={true}
          styleType={styleTypes.error}
        >
          {lang.removeAll}
        </ButtonRounded>
      )}

      {matchingData.length === 0 && (
        <StatusBox
          icon={<InformationOutlineIcon />}
          title={replaceNormalPlaceholder({
            text: lang.no__placeholder__added,
            replacement: getGroupTypeName({ lang, groupType }),
          })}
          content={replaceNormalPlaceholder({
            text: lang.add__placeholder__inTheLeftPanel,
            replacement: getGroupTypeName({ lang, groupType }),
          })}
        />
      )}

      {matchingData.map((dataEntry) => {
        return (
          <ListItem
            data-test-id={`target-group-panel__${groupType}__${dataEntry?.[idField]}`}
            key={`targetgroup__${groupType}__${dataEntry?.[idField]}`}
            style={{ border: `1px ${colors.midGrey} solid` }}
            title={dataEntry?.[titleField]}
            subTitle={
              groupType === groupTypes.users
                ? getSimpleUserSubTitle({ user: dataEntry })
                : `${dataEntry?.[countField]} ${lang.members} `
            }
            clickable={groupType !== groupTypes.users}
            iconRight={
              <ActionWrapper
                data-test-id={"btn__remove-entry"}
                className="remove-entry-icon"
                onClick={(e) => onRemoveDataEntry(e, dataEntry?.[idField])}
              >
                <TrashCanOutlineIcon />
              </ActionWrapper>
            }
            onClick={
              groupType !== groupTypes.users
                ? (e) =>
                    showGroupMembers({
                      type: groupType,
                      id: dataEntry?.[idField],
                      title: dataEntry?.[titleField],
                      clickEvent: e,
                      authorModal,
                    })
                : () => {} // Dummy function that does nothing
            }
          />
        );
      })}
    </div>
  );
}

export default TargetGroupPanelTab;
