// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast } from "../../../actions/uiActions";

// Components
import { ErrorOutlineIcon } from "mdi-react";

const UseHighscore = ({ highscoreId }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.language);

  const [highscore, setHighscore] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function getHighscores({ highscoreId }) {
    try {
      const { data } = await req()(`highscores/${highscoreId}`);

      setHighscore(data);
    } catch (error) {
      setError(true);
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: lang.errorCouldNotFetchScores,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getHighscores({ highscoreId });
  }, []);

  return { highscore, loading, error };
};

export default UseHighscore;
