// Libs
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AlertIcon, HourglassEmptyIcon, TickCircleIcon } from "mdi-react";

// Config
import colors from "../../../style/colors";
import approvalStatus from "../config/approvalStatus";

// components
import DropDownFilter from "../components/table/filters/DropDownFilter";
import DropDownFilterEmployees from "../components/table/filters/DropDownFilterEmployees";
import DatesFilter from "../components/table/filters/DatesFilter";
import StatusDropDownFilter from "../components/table/filters/StatusDropDownFilter";
import { registrationTypes } from "../../registration/config";
import ExportDropDownFilter from "../components/table/filters/ExportDropDownFilter";
import CellForExtrasWithPopover from "../components/table/CellForExtrasWithPopover";
dayjs.extend(customParseFormat);

/** Loops over the timesheets and converts them into a ReactTable friendly array of rows (no nested data)
 * @param {Object[]} timeSheets
 * @returns {Object} anon
 * @returns {Array} anon.columns - Columns to be used by react table
 * @returns {Array} anon.rows - TimeSheets mapped to rows
 *
 */
export default function mapTimeSheetsToTableStructure(timeSheets) {
  // To avoid generically generated columns to overwrite the default columns we are
  // adding the columns in two different arrays stored in an object.
  // As the very last thing in this function they two arrays are mixed back together
  const COLUMN_TYPES = {
    // These are columns generated from user-based questions
    QUESTION: "QUESTION",
    // These are columns generated per default for all companies using the time-registration module.
    MANDATORY: "MANDATORY",
  };

  let columns = { QUESTION: {}, MANDATORY: {} };
  let rows = [];

  // Early return: Returns
  if (!Array.isArray(timeSheets)) return { columns, rows };

  rows = timeSheets.map((timeSheet, index) => {
    let row = {};

    // push function (only push columns for first row, ignore the rest)

    const pushToColumns = (columnType, key, el) => {
      // There cannot be two columns with the same accessor and/or id (the id and the accessor are
      // often the same, as the id will be set to the value of the accessor if no id is provided)
      // Therefor i am implementing this check to avoid any crash-issues in case the form changes
      // later on
      let hasDuplicate = false;
      Object.keys(columns.QUESTION).map((key) => {
        if (columns.QUESTION[key].accessor && columns.QUESTION[key].accessor === el.accessor) hasDuplicate = true;
      });
      if (hasDuplicate) return;

      columns[columnType][key] = el;
    };

    const { timeRegistrationData = undefined } = timeSheet;

    //////////////////////////////
    // Basic info               //
    //////////////////////////////

    // id
    if (timeSheet.id) row.id = timeSheet.id;

    //////////////////////////////
    // Generel registrations data //
    //////////////////////////////

    // Loop over general registration-questions and use those that have `showInAdminTable` set to true
    if (Array.isArray(timeSheet.questions)) {
      timeSheet.questions
        .filter((q) => q.showInAdminTable) // only use chosen ones
        .forEach((question) => {
          // DROPDOWN_FORM_BUILDER answers are arrays, and for now we just want to get the length of the array in the table
          if (question.type === registrationTypes.DROPDOWN_FORM_BUILDER) {
            row[question.identifier] = question.answer;
            pushToColumns(COLUMN_TYPES.QUESTION, question.title, {
              Header: question?.titleAbbreviation || question.title,
              accessor: question.identifier,
              Cell: ({ value }) => <CellForExtrasWithPopover value={value} />,
            });
          } else {
            row[question.identifier] = question.answer.value;
            pushToColumns(COLUMN_TYPES.QUESTION, question.title, {
              Header: question?.titleAbbreviation || question.title,
              accessor: question.identifier,
              Filter: DropDownFilter,
              filter: "customIncludes",
              disableFilters: question.config && question.config.DISABLE_FILTERING,
            });
          }
        });

      // 🚨🚨🚨 Tillæg-answers are being added as columns here!

      timeSheet?.questions
        .filter(
          (question) =>
            question.type === registrationTypes.DROPDOWN_FORM_BUILDER &&
            question.config?.SHOW_DROPDOWN_FORM_BUILDER_ANSWERS_IN_ADMIN_TABLE
        )
        .forEach((question) => {
          let localSet = new Set();
          let counter = 2;

          let formBuilderAnswers = question.answer;

          formBuilderAnswers.map((answer) => {
            let title = `${answer.value.title} (tillæg)`;

            if (!localSet.has(title)) {
              localSet.add(title);

              pushFormBuilderAnswerToHeader(title, question.config);
              row[title] = answer.value.amount;
              return;
            }

            while (localSet.has(`${title} - ${counter}`)) counter++;

            title = `${answer.value.title} (tillæg) - ${counter}`;
            localSet.add(title);

            pushFormBuilderAnswerToHeader(title, question.config);
            row[title] = answer.value.amount;
          });
        });

      function pushFormBuilderAnswerToHeader(headerTitle, config) {
        pushToColumns(COLUMN_TYPES.QUESTION, headerTitle, {
          Header: headerTitle,
          Filter: DropDownFilter,
          accessor: headerTitle,
          filter: "customIncludes",
          disableFilters: config && config.DISABLE_FILTERING,
        });
      }
    }

    // User
    if (timeSheet.user && timeSheet.user.name) {
      let header = "Medarbejder";

      row.user = timeSheet.user;
      pushToColumns(COLUMN_TYPES.MANDATORY, header, {
        Header: header,
        accessor: "user.name",
        Filter: DropDownFilterEmployees,
        filter: "customIncludes",
        id: "user.id",
      });
    }

    //////////////////////////////
    // Time sheet specific data //
    //////////////////////////////

    // User selected data
    if (timeRegistrationData && timeRegistrationData.date) {
      let header = "Dato";

      row.date = dayjs(String(timeRegistrationData.date), "YYYYMMDD").format("YYYY-MM-DD");
      pushToColumns(COLUMN_TYPES.MANDATORY, header, {
        Header: header,
        accessor: "date",
        Filter: DatesFilter,
        filter: "dateBetween",
      });
    }

    // Creation data - sort of meta-data territory
    if (timeRegistrationData && timeRegistrationData.createdAt) {
      let header = "Indrapporteret";

      row.createdAt = dayjs(String(timeRegistrationData.createdAt), "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss");
      pushToColumns(COLUMN_TYPES.MANDATORY, header, {
        Header: header,
        accessor: "createdAt",
        disableFilters: true,
      });
    }

    if (timeRegistrationData && Array.isArray(timeRegistrationData.approvals)) {
      row.approvals = timeRegistrationData.approvals;
      timeRegistrationData.approvals.forEach((approval, index) => {
        let header = `Godkendt ${approval?.groupName}`;

        pushToColumns(COLUMN_TYPES.MANDATORY, header, {
          Header: header,
          id: `approvals[${[index]}].id`,
          accessor: `approvals[${[index]}].status`,
          Filter: StatusDropDownFilter,
          filter: "customIncludes",
          Cell: ({ value }) => {
            if (value === approvalStatus.approved) return <TickCircleIcon className="centered-svg" color={colors.green} />;
            if (value === approvalStatus.rejected) return <AlertIcon className="centered-svg" color={colors.red} />;
            return <HourglassEmptyIcon className="centered-svg" color={colors.midDarkGrey} />;
          },
        });
      });
    }

    // Is exported
    if (timeRegistrationData && timeRegistrationData.exported !== undefined) {
      let header = "Eksporteret";
      row.exported = timeRegistrationData.exported;

      pushToColumns(COLUMN_TYPES.MANDATORY, header, {
        Header: header,
        accessor: "exported",
        Filter: ExportDropDownFilter,
        filter: function (rows, columnIds, filterValue) {
          if (filterValue === true || filterValue === false) {
            return rows.filter(({ original: row }) => row.exported === filterValue);
          }
          return rows;
        },
        Cell: ({ value }) => value === true && <TickCircleIcon className="centered-svg" color={colors.green} />,
      });
    }

    return row;
  });

  let columnsArray = [];

  for (let column in columns[COLUMN_TYPES.QUESTION]) {
    if (!column) return;
    columnsArray.push(columns[COLUMN_TYPES.QUESTION][column]);
  }

  for (let column in columns[COLUMN_TYPES.MANDATORY]) {
    if (!column) return;
    columnsArray.push(columns[COLUMN_TYPES.MANDATORY][column]);
  }

  return {
    rows,
    columns: columnsArray,
  };
}
