// Libs
import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

// Style
import common from "../../../style/common";

// Actions
import ActionWrapper from "../ActionWrapper";
import { updateActiveRequestHandlers } from "../../../actions/registrationActions";

// Components
import DropDownSearchList from "./components/DropDownSearchList";
import DropDown from "./components/DropDown";

// Utilities
import req from "../../../utilities/request-utility";
import getTimeRegistrationDataIdentifierQueryParamString from "../../../utilities/get-time-registration-data-identifier-query-param-string";

/** This is a customly styled drop-down list
 *
 * It accepts the following props:
 * @param {*} props - A react style object
 * @param {object} style - A react style object
 * @param {string} className  - A class or a list of classes
 * @param {function} onChange - Fires when somthing is is selected
 * @param {string} initText - The placeholder text to appear before something is selected
 * @param {string} placeholder - Can be used instead of initText
 * @param {boolean} usePlaceholder - To toggle whether or not to show a placeholder
 * @param {Object[]} dropDownListContent - an array of elements to show.
 * @param {integer} dropDownListContent[].id - Id of the element to show.
 * @param {string} dropDownListContent[].title - Title of the element to show.
 * @
 *
 * OR
 *
 * [{ value: XX, name: YY}]
 * ```
 *
 * EXAMPLE:
 *
 * ```
 * <DropDownList
            dropDownListContent={this.state.dropDownListContent}
            selectedDropDownItemId={this.state.selectedDropDownItemId}
            onListChange={event => {
              this.setState({
                selectedDropDownContentId: event.target.value
              });
            }}
          />
 * ```
 */

function getLastUsedItemsFromLocalStorage(key) {
  let potentialItems = localStorage.getItem(key);

  if (potentialItems) {
    try {
      // Parse and filter out any falsy values
      potentialItems = JSON.parse(potentialItems).filter((item) => item);
      return potentialItems;
    } catch {
      return [];
    }
  } else {
    return [];
  }
}

function filterItemsToRemoveItemsNotCurrentlyPresent(storedItems = [], currentItems = []) {
  try {
    return storedItems.filter((item) => currentItems.some((c_item) => c_item.id === item.id));
  } catch {
    return [];
  }
}

class DropDownList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      lastUsedItems: [],
      items: [],
      lastUsedDataIdentifierQueryString: getTimeRegistrationDataIdentifierQueryParamString(),
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getOptions();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let dataIdentifierQueryString = getTimeRegistrationDataIdentifierQueryParamString();

    if (this.state.lastUsedDataIdentifierQueryString !== dataIdentifierQueryString) {
      this.getOptions();
      this.setState({ lastUsedDataIdentifierQueryString: dataIdentifierQueryString });
    }

    if (
      this.state.options.length !== prevState.options.length &&
      this.props.showLastUsedValues &&
      this.props.localStorageKey
    ) {
      let storedItems = getLastUsedItemsFromLocalStorage(this.props.localStorageKey);

      storedItems = filterItemsToRemoveItemsNotCurrentlyPresent(storedItems, this.state.options);

      if (storedItems.length < 1) return;

      if (this.props.defaultToLastUsedValue) {
        this.dropdownChanged({ target: { value: storedItems[0].id } });
      }

      this.setState({ lastUsedItems: storedItems });
    }
  }

  dropdownChanged = (e) => {
    this.setState({ selectedContentId: e.target.value });

    // Saving the selected object
    if (this.props.dataIdentifier) {
      let option = this.state.options.find((o) => o.id == e.target.value);
      if (option && option.title && option.id) this.props.onChange({ target: { value: option } });
    }

    // Saving the selected object id
    else {
      this.props.onChange(e);
    }

    if (this.props.showLastUsedValues && this.props.localStorageKey) {
      this.updateItemsInLocalStorage({
        selectedId: e.target.value,
        dropDownListContent: this.state.options,
        localStorageKey: this.props.localStorageKey,
      });
    }
  };

  updateItemsInLocalStorage = ({ selectedId, dropDownListContent, localStorageKey }) => {
    let storedItems = getLastUsedItemsFromLocalStorage(localStorageKey);
    let selectedItem = dropDownListContent.find((e) => e.id == selectedId);

    if (!selectedItem) return;

    try {
      storedItems = storedItems.filter((e) => e.id !== selectedId);
      storedItems.unshift(selectedItem);
      storedItems = storedItems.slice(0, 4);
      localStorage.setItem(localStorageKey, JSON.stringify(storedItems));
      this.setState({ lastUsedItems: storedItems });
    } catch {}
  };

  getValue = () => {
    if (this.props.stateless === true) {
      return this.props.selectedContentId;
    } else if (this.state.selectedContentId) {
      return this.state.selectedContentId;
    } else if (this.props.selectedContentId) {
      return this.props.selectedContentId;
    } else {
      return 0;
    }
  };

  getOptions = async () => {
    const registration = this.props.registration;

    if (!this.getValue()) {
      this.setState({ loading: true });
    }

    let options = [];
    if (this.props.dataIdentifier && registration) {
      try {
        this.props.updateActiveRequestHandlers(1);
        let { data } = await req()(
          `registration/${registration.id}/question-data/${
            this.props.dataIdentifier
          }?${getTimeRegistrationDataIdentifierQueryParamString()}`
        );
        options = data;

        const selectedValue = this.getValue();

        if (!options.some((option) => option.id == selectedValue)) {
          this.dropdownChanged({ target: { value: null } });
          this.props.onChange({ target: { value: null } });
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.props.updateActiveRequestHandlers(-1);
      }
    } else if (this.props.dropDownListContent) {
      options = this.props.dropDownListContent;
    }
    this.setState({ options, loading: false });
  };

  render() {
    const {
      style,
      className,
      name,
      initText,
      placeholder,
      usePlaceholder = true,
      showLastUsedValues,
      enableSearch,
    } = this.props;

    return (
      <div style={style} className={`${componentStyle(this.props)} ${className || ""}`}>
        <DropDown
          value={this.getValue()}
          onChange={this.dropdownChanged}
          usePlaceholder={usePlaceholder}
          placeholder={initText || placeholder}
          enableSearch={enableSearch}
          options={this.state.options}
          name={name}
          loading={this.state.loading}
        />

        {enableSearch === true && (
          <DropDownSearchList appended={true} onSelect={(e) => this.dropdownChanged(e)} options={this.state.options} />
        )}

        {showLastUsedValues === true && this.state.lastUsedItems.length > 0 && (
          <div id="last-used-items" className="last-used-items-wrapper">
            {this.state.lastUsedItems.map((item) => (
              <ActionWrapper
                key={`last-used-item-drop-down-${item.id}`}
                onClick={() => this.dropdownChanged({ target: { value: item.id } })}
                className="last-used-item"
              >
                {item.title}
              </ActionWrapper>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const componentStyle = (props) => css`
  .actual-input {
    display: flex;
    align-items: end;
    position: relative;
    select option:hidden {
      display: none;
    }

    select {
      background: none;
      width: 100%;
      border: 1px solid var(--midGrey);
      border-radius: 3px;
      background: transparent;
      padding-left: 1rem;
      padding-right: 2.5rem;
      display: block;
      height: ${props.height ? props.height : "40px"};
      font-size: 1rem;
      font-family: ${common.fontStack};
      appearance: none;
      color: #6e6e6e;
      cursor: pointer;

      &:focus {
        outline: 0;
        border: 1px solid ${props.primaryColor};
      }

      &.append {
        &.bottom-component {
          z-index: 5;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    svg {
      margin-left: -40px;
      height: 40px;
      pointer-events: none;
    }

    svg.dropdown-arrow {
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.15rem;
      height: 1.15rem;
    }
  }

  .last-used-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.35rem;
    margin: 0 -0.5rem;

    .last-used-item {
      display: inline-block;
      color: ${props.primaryColor};
      padding: 0.35rem 0.7rem;
      margin: 0.1rem 0.1rem;
    }
  }
`;

DropDownList.propTypes = {
  /** object for overriding css styles */
  style: PropTypes.object,
  /**  A class or a list of classes used for selecting classes that live inside DropDownList.jsx*/
  className: PropTypes.string,
  /** Function to be executed when an item is selected */
  onChange: PropTypes.func,
  /** The text that appears before anything is selected */
  initText: PropTypes.string,
  /** The elements to be shown in the dropdownlist, look above for required structure on the elements */
  dropDownListContent: PropTypes.array,
};

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
  registration: state.registration.form.registration,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveRequestHandlers: bindActionCreators(updateActiveRequestHandlers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropDownList);
