import { groupTypes } from "../config/groupTypes";

export default function getGroupTypeName({ count = 0, lang, groupType }) {
  if (groupType === groupTypes.users) {
    if (count === 1) return lang?.user?.toLowerCase();
    return lang?.users?.toLowerCase();
  }

  if (groupType === groupTypes.userGroups) {
    if (count === 1) return lang?.subGroup?.toLowerCase();
    return lang?.subGroups?.toLowerCase();
  }

  if (groupType === groupTypes.masterGroups) {
    if (count === 1) return lang?.mainGroup?.toLowerCase();
    return lang?.mainGroups?.toLowerCase();
  }

  if (groupType === groupTypes.customGroups) {
    if (count === 1) return lang?.customGroup?.toLowerCase();
    return lang?.customGroups?.toLowerCase();
  }

  if (groupType === groupTypes.jobTitles) {
    if (count === 1) return lang?.jobTitle?.toLowerCase();
    return lang?.jobTitles?.toLowerCase();
  }
}
