import {
  GET_ALL_EQUIPMENT,
  GET_ALL_EQUIPMENT_SUCCESS,
  GET_ALL_EQUIPMENT_FAILURE,
  SET_ALL_EQUIPMENT_END_OF_FEED,
  GET_MY_EQUIPMENT,
  GET_MY_EQUIPMENT_SUCCESS,
  GET_MY_EQUIPMENT_FAILURE,
  SET_MY_EQUIPMENT_END_OF_FEED,
  SET_CURRENT_EQUIPMENT_BOOKING_ID,
  RESET_EQUIPMENT_BOOKING_STATE,
  SET_MY_EQUIPMENT_COUNT,
  SET_EQUIPMENT_CATEGORIES,
  SET_EQUIPMENT_FILTER_VALUE,
  REPLACE_ALL_EQUIPMENT_DATA,
  SET_EQUIPMENT_SEARCHTERM,
  SET_PREVENT_STATE_RESET,
} from "../actions/actionTypes";
import equipmentStatuses from "../components/equipmentBooking/equipmentStatuses";

const initialState = {
  allEquipment: {
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  },
  myBookings: {
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
    count: 0,
  },
  categories: [],
  filters: {
    bookingStatus: equipmentStatuses.all,
    categoryId: "all",
  },
  searchterm: "",
  currentEquipmentBookingId: undefined,
  preventStateReset: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EQUIPMENT_SEARCHTERM:
      return {
        ...state,
        searchterm: action.payload,
      };

    case GET_ALL_EQUIPMENT:
      return {
        ...state,
        allEquipment: {
          ...state.allEquipment,
          loading: true,
          error: false,
        },
      };

    case GET_ALL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        allEquipment: {
          ...state.allEquipment,
          loading: false,
          error: false,
          data: [...state.allEquipment.data, ...action.payload],
        },
      };

    case REPLACE_ALL_EQUIPMENT_DATA:
      return {
        ...state,
        allEquipment: {
          ...state.myBookings,
          loading: false,
          error: false,
          data: action.payload,
        },
      };

    case GET_ALL_EQUIPMENT_FAILURE:
      return {
        ...state,
        allEquipment: {
          ...state.allEquipment,
          loading: false,
          error: true,
        },
      };

    case SET_ALL_EQUIPMENT_END_OF_FEED:
      return {
        ...state,
        allEquipment: {
          ...state.allEquipment,
          endOfFeed: true,
        },
      };

    case GET_MY_EQUIPMENT:
      return {
        ...state,
        myBookings: {
          ...state.myBookings,
          loading: true,
          error: false,
        },
      };

    case GET_MY_EQUIPMENT_SUCCESS:
      return {
        ...state,
        myBookings: {
          ...state.myBookings,
          loading: false,
          error: false,
          data: [...state.myBookings.data, ...action.payload],
        },
      };

    case GET_MY_EQUIPMENT_FAILURE:
      return {
        ...state,
        myBookings: {
          ...state.myBookings,
          loading: false,
          error: true,
        },
      };

    case SET_MY_EQUIPMENT_COUNT:
      return {
        ...state,
        myBookings: {
          ...state.myBookings,
          count: action.payload,
        },
      };

    case SET_MY_EQUIPMENT_END_OF_FEED:
      return {
        ...state,
        myBookings: {
          ...state.myBookings,
          endOfFeed: true,
        },
      };

    case SET_EQUIPMENT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case SET_EQUIPMENT_FILTER_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };

    case RESET_EQUIPMENT_BOOKING_STATE:
      return initialState;

    case SET_CURRENT_EQUIPMENT_BOOKING_ID:
      return {
        ...state,
        currentEquipmentBookingId: action.payload,
      };

    case SET_PREVENT_STATE_RESET:
      return {
        ...state,
        preventStateReset: action.payload,
      };

    default:
      return state;
  }
}
