// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Utilities and config
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import linkPathCreator from "../../../utilities/get-link-path-from-page-object";
import pageTransitions from "../../../config/page-transitions";
import req from "../../../utilities/request-utility";
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";

// Components
import ScrollView from "../ScrollView";
import InlineSpinner from "../InlineSpinner";
import Page from "../Page";
import TopBar from "../TopBar";
import StatusBox from "../StatusBox";
import ActionWrapper from "../ActionWrapper";
import NotificationListItem from "./NotificationListItem";
import ButtonRounded from "../ButtonRounded";

// Style
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";

// Icons
import { FeatureSearchOutlineIcon, CloseIcon } from "mdi-react";

// Actions
import { getNotifications, resetNotifications } from "../../../actions/notificationActions";
import NotificationListItemSkeleton from "../skeletons/NotificationListItemSkeleton";

const NotificationCenterMain = (props) => {
  const dispatch = useDispatch();

  // Redux state
  const { notifications, loading, error, endOfFeed, showSkeleton } = useSelector((state) => state.notification);
  const { language: lang } = useSelector((state) => state.language);
  const { primaryColor } = useSelector((s) => s.appConfig);

  // ComponentDidMount
  useEffect(() => {
    // Fetch notifications
    dispatch(getNotifications());

    // Update count of notifications on frontPage
    req().put(`notifications/seen`);

    // Reset the notifications when componentWillUnmount
    return () => {
      dispatch(resetNotifications());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  const showNotificationContent = (notification) => {
    // Dismiss the notification
    req().put(`notifications/dismiss`, { notificationIds: notification.notificationGroup.map(({ id }) => id) });

    // Navigate to notification content page
    pageNavigatorV2({
      path: `${linkPathCreator(notification.page, "absolute", {
        suffix: notification.topLevelContentId ? notification.topLevelContentId : notification.contentId,
      })}`,
      direction: pageTransitions.forward,
    });
  };

  return (
    <Page>
      <TopBar
        title={lang.notifications}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigatorV2({ mode: "pop", direction: pageTransitions.modalPageDown })}>
            <CloseIcon />
          </ActionWrapper>
        }
      />
      <ScrollView className={componentStyles(primaryColor)} id="notifications-page-scroll-view">
        {/* Status box */}
        {notifications.length === 0 && !loading && !error && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noNotifications}
            content={lang.comeBackLater}
            style={{ margin: "2rem auto" }}
          />
        )}

        {/* Content */}
        {!showSkeleton && (
          <div className="notifications-container">
            {notifications.map((notification) => {
              return (
                <NotificationListItem
                  imageLeft={getProfilePicture(notification.triggeredByUser, 32, { margin: "0 1rem 0 0" })}
                  key={notification.id}
                  onClick={() => showNotificationContent(notification)}
                  {...notification}
                />
              );
            })}
          </div>
        )}

        {/* Load more content button */}
        {!endOfFeed && notifications.length > 10 && !loading && (
          <ButtonRounded onClick={() => dispatch(getNotifications({ offset: notifications.length }))} styleType="neutral">
            {lang.viewOlderNotifications}
          </ButtonRounded>
        )}

        {/* Skeleton loading */}
        {showSkeleton && (
          <div className="notifications-container">
            {[...Array(7)].map((_, i) => (
              <NotificationListItemSkeleton key={i} />
            ))}
          </div>
        )}

        {/* Error */}
        {!loading && error && <StatusBox />}

        {/* Loading */}
        {!showSkeleton && loading && !error && <InlineSpinner style={{ margin: "2rem auto" }} />}
      </ScrollView>
    </Page>
  );
};

const componentStyles = (primaryColor) => css`
  .notifications-container {
    max-width: ${breakpoints.md}px;
    margin: 1rem auto 0;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1rem auto;
      overflow: hidden;

      div:last-child {
        border-bottom: none;
      }
    }
  }

  button {
    &.primary {
      margin: 1rem auto;
      background-color: ${tinycolor(primaryColor).setAlpha(0.1).toRgbString()};
      color: ${primaryColor};
      transition: background-color 300ms ease;

      &:hover {
        background-color: ${tinycolor(primaryColor).setAlpha(0.2).toRgbString()};
      }
    }
  }
`;

export default NotificationCenterMain;
