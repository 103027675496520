import { css } from "emotion";
import React from "react";
import styleTypes from "../../config/styleTypes";
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import ActionWrapper from "./ActionWrapper";
import { CloseIcon } from "mdi-react";
import { CSSTransition } from "react-transition-group";
import tinycolor from "tinycolor2";
import styleTypeNormalizer from "../../utilities/style-type-normalizer";

const ANIMATION_LENGTH = 1000;

function BreakingBar({ active, content, styleType = "neutral", id, dismissBreakingBanner = () => {} }) {
  return (
    <div className={`${componentStyles()}`}>
      <CSSTransition
        in={active}
        timeout={ANIMATION_LENGTH}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames={"breaking-bar"}
      >
        <div className={`breaking-bar ${styleTypeNormalizer(styleType)}`}>
          <p>{content}</p>

          <ActionWrapper className="close-icon" onClick={() => dismissBreakingBanner(id)}>
            <CloseIcon />
          </ActionWrapper>
        </div>
      </CSSTransition>
    </div>
  );
}

const ROTATE_ANGLE = 0.35;
const SHAKE_DISTANCE = 6;

const componentStyles = () => css`
  .breaking-bar {
    display: flex;
    width: 100%;
    flex: 100% 1 0;
    padding: 0.65rem 1.75rem 0.65rem 0.65rem;
    min-height: 70px;
    border-top: 1px solid var(--lightGrey);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform-origin: 50% 0;
    align-items: center;

    .close-icon {
      position: absolute;
      top: 0.55rem;
      right: 0.55rem;
      width: 1.85rem;
      height: 1.85rem;
      border-radius: 50%;
      padding: 0.35rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: var(--black);
        opacity: 0.7;
      }
    }

    p {
      max-width: ${breakpoints.lg}px;
      color: black;
      font-size: 1rem;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 0.25rem;
    }

    &.${styleTypes.error} {
      background-color: var(--redLight);
      border-left: 3px var(--red) solid;

      p {
        color: ${tinycolor(colors.red).darken(20).toString()};
      }
    }
    &.${styleTypes.neutral} {
      background-color: var(--yellowLight);
      border-left: 3px var(--yellowDark) solid;

      p {
        color: ${tinycolor(colors.yellowDark).darken(20).toString()};
      }
    }
    &.${styleTypes.ok} {
      background-color: var(--greenLight);
      border-left: 3px var(--green) solid;

      p {
        color: ${tinycolor(colors.green).darken(20).toString()};
      }
    }
  }

  .breaking-bar-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  .breaking-bar-enter-active {
    animation: breakingBarIn ${ANIMATION_LENGTH}ms forwards ease-in;
  }
  .breaking-bar-exit {
    opacity: 1;
    transform: scaleY(1);
  }
  .breaking-bar-exit-active {
    opacity: 0;
    transform: scaleY(0.5);
    transition: opacity 200ms ease, transform 200ms ease;
  }

  @keyframes breakingBarIn {
    0% {
      opacity: 0;
      transform: translate3d(0px, 0px, 0) rotate(0deg) scaleY(0.5);
    }
    35% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0) rotate(0deg) scaleY(1);
    }
    35% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0) rotate(0deg) scaleY(1);
    }
    40% {
      opacity: 1;
      transform: translate3d(-${SHAKE_DISTANCE * 0.5}px, 0px, 0) rotate(-${ROTATE_ANGLE}deg) scaleY(1);
    }
    50% {
      opacity: 1;
      transform: translate3d(${SHAKE_DISTANCE * 1}px, 0px, 0) rotate(${ROTATE_ANGLE}deg) scaleY(1);
    }
    60% {
      opacity: 1;
      transform: translate3d(-${SHAKE_DISTANCE * 1.25}px, 0px, 0) rotate(-${ROTATE_ANGLE}deg) scaleY(1);
    }
    70% {
      opacity: 1;
      transform: translate3d(${SHAKE_DISTANCE * 1.65}px, 0px, 0) rotate(${ROTATE_ANGLE}deg) scaleY(1);
    }
    80% {
      opacity: 1;
      transform: translate3d(-${SHAKE_DISTANCE * 1.15}px, 0px, 0) rotate(-${ROTATE_ANGLE}deg) scaleY(1);
    }
    90% {
      opacity: 1;
      transform: translate3d(${SHAKE_DISTANCE * 0.5}px, 0px, 0) rotate(${ROTATE_ANGLE}deg) scaleY(1);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0) rotate(0deg) scaleY(1);
    }
  }
`;

export default BreakingBar;
