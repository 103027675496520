// Libs
import React from "react";

// Utilities and config
import req from "../utilities/request-utility";
import { eventTypes } from "../components/event/config";

// Actions
import { addToast, hideModalPage } from "./uiActions";

// Styles
import { ErrorOutlineIcon } from "mdi-react";

// Action types
import {
  ATTEND_EVENT,
  ATTEND_EVENT_FAILURE,
  ATTEND_EVENT_SUCCESS,
  DELETE_ATTEND_EVENT,
  DELETE_ATTEND_EVENT_FAILURE,
  DELETE_ATTEND_EVENT_SUCCESS,
  END_OF_EVENTS,
  GET_EVENT,
  GET_EVENT_ATTENDEES,
  GET_EVENT_ATTENDEES_FAILURE,
  GET_EVENT_ATTENDEES_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENTS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  RESET_EVENT,
  RESET_EVENTS,
} from "./actionTypes";
import { getPages } from "./pagesActions";

/**
 * Fetches events from api.
 * Appends events onto already existing events.
 * use resetEvents() to empty feed.
 * @param {String} eventType
 * @param {Integer} limit
 * @param {Integer} offset
 * @param {Integer} groupId
 */
export function getEvents({ eventType, limit = 10, offset = null, groupId = undefined }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().event[eventType];

      let events = [];

      if (offset === null) offset = state.events.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_EVENTS, payload: { eventType } });

      if (eventType === eventTypes.all) {
        let URL = `events/?limit=${limit}&offset=${offset}`;
        if (groupId) URL += `&groupId=${groupId}`;

        const { data: allEvents } = await req()(URL);
        events = allEvents;
      }

      if (eventType === eventTypes.attending) {
        let URL = `events/?isAttending=1&?limit=${limit}&offset=${offset}`;

        if (groupId) URL += `&groupId=${groupId}`;

        const { data: attendingEvents } = await req()(URL);
        events = attendingEvents;
      }

      if (events.length) {
        dispatch({ type: GET_EVENTS_SUCCESS, payload: { events, eventType } });
      } else {
        dispatch({ type: END_OF_EVENTS, payload: { eventType } });
      }
    } catch (err) {
      dispatch({ type: GET_EVENTS_FAILURE, payload: { eventType } });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetEvents,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Attends or not attending event function.
 * @param {Integer} eventId
 * @param {Object} fields
 * @param {Integer} attendeeId
 */
export function attendEvent({ eventId, fields = null, attendeeId }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().event;

      if (state.loading) return;

      dispatch({ type: ATTEND_EVENT });

      await req().put(`events/${eventId}/attendees/${attendeeId}`, { fields });
      const { data: event } = await req()(`events/${eventId}`);

      dispatch({ type: ATTEND_EVENT_SUCCESS, payload: { event } });

      // Update pages so notification count goes down when you go back
      getPages()(dispatch, getState);
    } catch (err) {
      dispatch({ type: ATTEND_EVENT_FAILURE });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotSaveYourAnswer,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Deletes all relation to an event - this means if user attends or not and the answered questions
 * @param {Integer} eventId
 * @param {Object} fields
 * @param {Integer} attendeeId
 */
export function deleteAttendEvent({ eventId, attendeeId }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().event;

      if (state.loading) return;

      dispatch({ type: DELETE_ATTEND_EVENT });

      await req().delete(`events/${eventId}/attendees/${attendeeId}`);
      const { data: event } = await req()(`events/${eventId}`);

      dispatch({ type: DELETE_ATTEND_EVENT_SUCCESS, payload: { event } });
    } catch (err) {
      dispatch({ type: DELETE_ATTEND_EVENT_FAILURE });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotDeleteYouAnswer,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Resets the events from the store
 */
export function resetEvents() {
  return function (dispatch) {
    dispatch({
      type: RESET_EVENTS,
    });
  };
}

/**
 * Gets the content for an event
 * @param {Integer} eventId
 */
export function getEvent({ eventId }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().event;

      if (state.loading) return;

      dispatch({ type: GET_EVENT });

      const { data: event } = await req()(`events/${eventId}`);

      dispatch({
        type: GET_EVENT_SUCCESS,
        payload: { event },
      });
    } catch (err) {
      dispatch({ type: GET_EVENT_FAILURE });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetTheEvent,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function resetEvent() {
  return function (dispatch) {
    dispatch({
      type: RESET_EVENT,
    });
  };
}

/**
 * Gets the attendees for an event
 * @param {Integer} eventId
 */
export function getEventAttendees({ eventId }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().event;

      if (state.loadingAttendees || state.errorAttendees) return;

      dispatch({ type: GET_EVENT_ATTENDEES });

      const { data: attendees } = await req()(`events/${eventId}/attendees`);

      dispatch({
        type: GET_EVENT_ATTENDEES_SUCCESS,
        payload: { attendees },
      });
    } catch (err) {
      dispatch({ type: GET_EVENT_ATTENDEES_FAILURE });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetTheAttendees,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}
