// libs
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function dateBetweenFilterFn(rows, id, filterValues) {
  const startDate = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const endDate = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (endDate || startDate) {
    return rows.filter((row) => {
      const cellDate = new Date(dayjs(row.values.date, "D/M-YYYY").format());

      if (endDate && startDate) {
        return cellDate >= startDate && cellDate <= endDate;
      } else if (startDate) {
        return cellDate >= startDate;
      } else if (endDate) {
        return cellDate <= endDate;
      }
    });
  } else {
    return rows;
  }
}

export default dateBetweenFilterFn;
