import store from "../store";

/**
 * @returns {String}
 *
 */
export default () => {
  /**
   * What we want to do here is to generate a query-param string of identifiers with what id user has answered with.
   * This can be used to diff previous anwers to current answers to see if any dataIdentifier-realted questions
   * has been changed or to send to the api
   *
   * ie. if there was a question with data identifier `REGISTRATION_TYPE` and the user has answered the id `EVENT`
   * the query-string should look like `REGISTRATION_TYPE=EVENT`
   *
   */

  let state = store.getState();

  if (!state.registration.form.registration) return "";

  let dataIdentifierStrings = state.registration.form.registration.categories
    // Map through categories returning each's questions
    .map((category) => category.questions)
    // Flatten array of otherwise [ [question1, question2], [question3, question4] ]
    .flat()
    // Remove all questions that doesn't have a dataIdentifer (not important here)
    .filter((q) => q.dataIdentifier)
    // Map through the questions and construct a string = `${DATA_IDENTIFIER}=${ANSWER}`
    // Will either be `DATA_IDENTIFIER=5342` or `DATA_IDENTIFIER=` for no answer
    .map((q) => `${q.dataIdentifier}=${q.answer && q.answer.value ? encodeURIComponent(q.answer.value.id) : ""}`);

  // append either userId from overwrite if applicable
  if (state.timeRegistration && state.timeRegistration.selectedUser && state.timeRegistration.selectedUser.id) {
    dataIdentifierStrings.push(`userId=${state.timeRegistration.selectedUser.id}`);
  }

  return dataIdentifierStrings.join("&"); // Join all the answers with `&`
};
