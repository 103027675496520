// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import getYear from "date-fns/getYear";
import { bindActionCreators } from "redux";

// Utilities and config
import req from "../../utilities/request-utility";
import currentSeason from "../../utilities/get-current-quarter";
import currentMonth from "../../utilities/get-current-month";
import breakpoints from "../../config/breakpoints";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../utilities/get-user-group-title";

// Styles
import colors from "../../style/colors";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ListItem from "../ui/ListItem";
import StatusBox from "../ui/StatusBox";

// Icons
import { FeatureSearchOutlineIcon } from "mdi-react";

// Actions
import { addToast, showModalPage, updateModalPage } from "../../actions/uiActions";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";
import { ListItemSkeletonGroup, ListItemSkeleton } from "../ui/ListItemSkeleton";
import useCurrentPage from "../../hooks/useCurrentPage";

const spacingTop = "2rem";

const Highscore = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Functionality local state
  const [quarterPeriod, setQuarterPeriod] = useState(false);
  const [scores, setScores] = useState({ departments: [], users: [], myDepartment: [] });
  const [usersPlacementNr, setUsersPlacementNr] = useState(null);
  const [usersPlacementInMyDepartment, setUsersPlacementInMyDepartment] = useState(null);

  // Page state
  const [loading, setLoading] = useState(true);
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [error, setError] = useState(false);
  const [tabs] = useState([lang.top10, lang.myDepartment]);
  const [activeTab, setActiveTab] = useState(0);

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const pageConfig = page.config;

  let onlyInUsersDepartment = false;

  useEffect(() => {
    getPoints();
    // eslint-disable-next-line
  }, []);

  // To get the users information when clicked on the image
  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  const getPoints = async () => {
    try {
      const users = await getUsers();
      const departments = await getDepartments();
      const myDepartment = await getMyDepartments();

      // Update the state
      setScores((scores) => ({ ...scores, users, departments, myDepartment }));
      setLoading(false);

      /*  it takes a long time to get the placement of the current user. To not make the initial load too long, 
          I first return the users without the current user and then add them below, if they are not part of the initial top ten */
      if (!users.some((u) => u.id === user.id)) {
        const currentUser = await getCurrentUser();
        const usersPlacementNr = await getCurrentUsersPosition();

        setUsersPlacementNr(usersPlacementNr);
        setScores((scores) => ({ ...scores, users: [...scores.users, currentUser] }));
      }
      setLoadingCurrentUser(false);
    } catch (err) {
      // Error handling
      props.addToast({
        styleType: "error",
        title: lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.highscore.toLowerCase()),
        content: `${lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.highscore.toLowerCase())}. ${
          lang.tryAgainOrContactSupport
        }.`,
        duration: 20000,
      });
      setLoading(false);
      setError(true);
    }
  };

  const getUsers = async (onlyInUsersDepartment = false) => {
    /** TOP 10 **/
    // Fetch top 10 users either for the whole company or just for the current user's department
    const { data } = await req()(`/highscore-v2/users${onlyInUsersDepartment ? `?departmentId=${user.userGroup.id}` : ""}`);
    let { users, highscorePeriod } = data;

    // Sets the quarter period to true
    if (highscorePeriod === "QUARTER") {
      setQuarterPeriod(true);
    }

    return users;
  };

  const getCurrentUser = async () => {
    // Fetch the users points for the period
    let { data: points } = await req()(`/pointlog/count`);

    const { id, name, profilePicture, userGroup, masterGroup } = user;
    const currentUser = {
      id,
      name,
      profilePicture,
      userGroup: userGroup,
      masterGroup: masterGroup,
      points: points.count,
    };
    return currentUser;
  };

  const getCurrentUsersPosition = async (onlyInUsersDepartment = false) => {
    // Fetch current users position on the highscore either for the whole company or just for the current user's department
    let { data: usersPlacementNr } = await req()(
      `/highscore-v2/user-position${onlyInUsersDepartment ? `?departmentId=${user.userGroup.id}` : ""}`
    );
    return usersPlacementNr;
  };

  const getMyDepartments = async () => {
    // Fetch top 10 in users department
    onlyInUsersDepartment = true;
    const usersInDepartment = await getUsers(onlyInUsersDepartment);

    /* 
      If the current user isn't in top 10 get the current user and their position and add it to the bottom of the list.
      For the department I do this before returning the users of the department, whereas for the whole company this check is done in the getPoints funtion.
      The reason being that when it comes to the department it doesn't influence the initial load time and then I think it is cleaner to do it this way.
      Feel free to change it, if you have a better solution.
    */
    if (!usersInDepartment.some((u) => u.id === user.id)) {
      const currentUser = await getCurrentUser();
      let usersPlacementInMyDepartment = await getCurrentUsersPosition(onlyInUsersDepartment);
      usersInDepartment.push(currentUser);
      setUsersPlacementInMyDepartment(usersPlacementInMyDepartment);
    }

    return usersInDepartment;
  };

  const getDepartments = async () => {
    // Fetch average scores for departments
    let { data: departments } = await req()("/highscore-v2/departments");
    return departments;
  };

  const getDepartmentTitle = () => {
    if (pageConfig?.USERGROUP_HEADLINE_OVERWRITE) {
      return lang && lang[pageConfig.USERGROUP_HEADLINE_OVERWRITE];
    } else if (quarterPeriod) {
      return `${lang.departments} - ${currentSeason()}`;
    } else {
      return `${lang.departments} - ${currentMonth()} ${getYear(new Date())}`;
    }
  };

  const ListContainer = ({ title, children }) => (
    <>
      <h1 style={{ marginTop: spacingTop }}>{title}</h1>
      <div className="scores-container">
        <div className="score-header">
          <p>{lang.rank}</p>
          <p className="name">{lang.name}</p>
          <p>{lang.points}</p>
        </div>
        {children}
      </div>
    </>
  );

  return (
    <Page className={componentStyle(primaryColor)}>
      <TopBar useDefaultBackButton={true} title={lang.highscore} />
      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      <TabView
        activeTabIndex={activeTab}
        tabs={[
          /* Tab 1: (Top 10) */
          <div className="container">
            {loading && (
              <ListContainer title={""}>
                <ListItemSkeletonGroup />
              </ListContainer>
            )}

            {!loading && error && <StatusBox />}
            {!loading && !error && (
              <>
                {/* If both the user section and the usergroup section is set to hidden, we show this statusbox. This is never meant to happen though. */}
                {pageConfig?.HIDE_USER_SECTION && pageConfig?.HIDE_USERGROUP_SECTION && (
                  <StatusBox
                    icon={<FeatureSearchOutlineIcon />}
                    title={lang.noContentTitle}
                    content={lang.noContentContent}
                    style={{ margin: "3rem 0" }}
                  />
                )}

                {/* Single users */}
                {!pageConfig?.HIDE_USER_SECTION && (
                  <ListContainer title={quarterPeriod ? currentSeason() : `${currentMonth()} ${getYear(new Date())}`}>
                    {scores.users.map((scoreUser, userIndex) => {
                      return (
                        <ListItem
                          clickable={true}
                          maxWidth={breakpoints.lg}
                          className={`list-item ${user.id === scoreUser.id ? "current-user" : ""}`}
                          itemNumberLeft={usersPlacementNr && user.id === scoreUser.id ? usersPlacementNr : userIndex + 1}
                          onClick={() => authorModal(scoreUser.id)}
                          imageLeft={getProfilePicture(scoreUser, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
                          key={userIndex}
                          title={scoreUser.name}
                          subTitle={getUserGroupTitle(scoreUser)}
                          iconRight={scoreUser.points || 0}
                        />
                      );
                    })}
                    {loadingCurrentUser && <ListItemSkeleton />}
                  </ListContainer>
                )}

                {!pageConfig?.HIDE_USERGROUP_SECTION && (
                  <ListContainer title={getDepartmentTitle()}>
                    {scores.departments.length > 0 &&
                      scores.departments.map((score, scoreKey) => (
                        <ListItem
                          clickable={true}
                          maxWidth={breakpoints.lg}
                          className={`list-item ${user.userGroup.id === score.userGroup.id ? "current-user" : ""}`}
                          key={scoreKey}
                          itemNumberLeft={scoreKey + 1}
                          // style={scoreKey === scores.departments.length - 1 ? listItemStyling("lastRow") : listItemStyling()}
                          title={`${score.userGroup.title}, ${score.masterGroup.title}`}
                          iconRight={score.points}
                        />
                      ))}
                  </ListContainer>
                )}
              </>
            )}
          </div>,
          /* Tab 2 (My department) */
          <div className="container">
            {loading && (
              <ListContainer title={user.userGroup.title}>
                <ListItemSkeletonGroup />
              </ListContainer>
            )}
            {!loading && error && <StatusBox />}
            {!loading && !error && (
              <ListContainer title={user.userGroup.title}>
                {scores.myDepartment.map((scoreUser, scoreKey) => (
                  <ListItem
                    clickable={true}
                    maxWidth={breakpoints.lg}
                    className={`list-item ${user.id === scoreUser.id ? "current-user" : ""}`}
                    itemNumberLeft={
                      usersPlacementInMyDepartment && user.id === scoreUser.id ? usersPlacementInMyDepartment : scoreKey + 1
                    }
                    onClick={() => authorModal(scoreUser.id)}
                    imageLeft={getProfilePicture(scoreUser, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
                    key={scoreKey}
                    title={scoreUser.name}
                    subTitle={getUserGroupTitle({
                      ...scoreUser,
                      userGroup: {
                        ...scoreUser.userGroup,
                        title: scoreUser.userGroup.title ? scoreUser.userGroup.title : scoreUser.userGroup,
                      },
                      masterGroup: {
                        ...scoreUser.masterGroup,
                        title: scoreUser.masterGroup.title ? scoreUser.masterGroup.title : scoreUser.masterGroup,
                      },
                    })}
                    iconRight={scoreUser.points}
                  />
                ))}
              </ListContainer>
            )}
          </div>,
        ]}
      />
    </Page>
  );
};

const componentStyle = (primaryColor) => css`
  h1 {
    margin: 0 1rem 1rem 1rem;
  }

  .container {
    @media screen and (min-width: ${breakpoints.lg}px) {
      width: ${breakpoints.lg}px;
      margin: 1rem auto;
      flex: initial !important; /* Removes forced 100% height */
    }

    .list-item:last-of-type {
      border-radius: 0 0 3px 3px;
    }

    .list-item.current-user {
      font-weight: 700;
      color: ${primaryColor};
      background-color: var(--ultraLightGrey);
    }

    .scores-container {
      border-radius: 3px;
      border-top: 1px solid var(--midGrey);
      border-bottom: 1px solid var(--midGrey);
      background-color: var(--white);
      margin-bottom: 2rem;
    }
    .score-header {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      border-left: 0.5px solid var(--midGrey);
      border-right: 0.5px solid var(--midGrey);
      text-align: right;
      padding: 0.75rem;
      font-size: 0.75rem;
      color: var(--darkGrey);
      display: flex;

      p.name {
        flex: 1;
        text-align: start;
        padding-left: 0.5rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(Highscore);
