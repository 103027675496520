import { showDialog, hideDialog } from "../../actions/uiActions";
import store from "../../store";
import getUserPushSubscription from "./get-user-push-subscrtipion";
import isPushNotificationsSupported from "./is-push-notifications-supported";
import promptForPushPermission from "./prompt-user-for-push-notifications";
import subscribeUserToPush from "./subscribe-user-to-push-notifications";
import trackAction from "../../services/tracking/trackAction.js";
import trackingActions from "../../config/trackingActions.js";

export default async function potentiallyPrompotUserForPushNotificationPermission() {
  let appConfig = store.getState().appConfig;
  let lang = store.getState().language.language;

  // 1. Start by checking if push notifications is enabled for this app:
  if (appConfig.enablePushNotifications !== true) return;

  // 2. Check if push notifications is even supported:
  if (!isPushNotificationsSupported()) return;

  // Ensure the user isn't getting spammed with the dialog (if they dismiss 5 times, they won't be asked again)
  // This logic is device-specific, so the user will still be asked on other devices
  let dismissedCount = localStorage.getItem("dismissedPushNotificationDialogCount") || 0;

  if (dismissedCount >= 5) return;

  // Check if the cooldown has expired
  let lastDismissed = localStorage.getItem("lastDismissedPushNotificationDialog");

  if (lastDismissed) {
    let lastDismissedDate = new Date(lastDismissed);
    let now = new Date();

    let diff = now - lastDismissedDate;

    if (diff < 1000 * 60 * 60 * 24) return;
  }

  // 3. Okay we got this far. This means that the current app has enabled push and that the current device supports it.
  //    Now we need to check if the current user has a subscription
  let potentialPushSubscription = await getUserPushSubscription();

  // 4a. Okay current user doesn't have a push-subscription and their permission setting is neither granted or denied so we can go ahead
  //    and ask for permission
  //    -> This case is for a user who has never been asked before
  if (!potentialPushSubscription && Notification.permission !== "granted" && Notification.permission !== "denied") {
    trackAction({ action: trackingActions.NOTIFICATION_DIALOG_SHOWN });
    store.dispatch(
      showDialog({
        title: lang.pushNotificationDialogTitle,
        content: lang.pushNotificationDialogContent,
        primaryActionTitle: lang.pushNotificationDialogPrimaryAction,
        primaryAction: promptForPushPermission,
        secondaryActionTitle: lang.askMeLater,
        secondaryAction: () => {
          trackAction({ action: trackingActions.NOTIFICATION_DIALOG_DISMISSED });
          localStorage.setItem("lastDismissedPushNotificationDialog", new Date());
          localStorage.setItem("dismissedPushNotificationDialogCount", dismissedCount + 1);
          store.dispatch(hideDialog());
        },
        dismissAction: () => {
          trackAction({ action: trackingActions.NOTIFICATION_DIALOG_DISMISSED });
          localStorage.setItem("lastDismissedPushNotificationDialog", new Date());
          localStorage.setItem("dismissedPushNotificationDialogCount", dismissedCount + 1);
          store.dispatch(hideDialog());
        },
      })
    );
  }

  // 4b.
  // -> This case is for a user that has been asked before but somehow doesn't have his subscription any more.
  //    It could have expired, been deleted manually or something else. In this case we will just go straight to
  //    obtaining a push subscription as permissions is ok, we just need the subscription (no user interaction needed)
  if (!potentialPushSubscription && Notification.permission === "granted") {
    await subscribeUserToPush();
  }

  // 4c.
  // User has a subscription! Yay!
  // And the permission is granted. Just to make sure the subscription is in the database (could have been deleted)
  // We will try to save it to the database silently
  if (potentialPushSubscription && Notification.permission === "granted") {
    await subscribeUserToPush({ showToasts: false });
  }
}
