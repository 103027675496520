import React from "react";

// Utilities
import req from "../utilities/request-utility";

// Actions
import {
  EXPORT_TIME_REGISTRATION_TIME_SHEET,
  EXPORT_TIME_REGISTRATION_TIME_SHEET_FAILURE,
  EXPORT_TIME_REGISTRATION_TIME_SHEET_SUCCESS,
  GET_TIME_REGISTRATION_PERMISSIONS_FAILURE,
  GET_TIME_REGISTRATION_PERMISSIONS_SUCCESS,
  GET_TIME_REGISTRATION_TIME_SHEETS,
  GET_TIME_REGISTRATION_TIME_SHEETS_FAILURE,
  GET_TIME_REGISTRATION_TIME_SHEETS_SUCCESS,
  RESET_TIME_REGISTRATION,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_FAILURE,
  UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_SUCCESS,
  SET_SELECTED_DATE,
  SET_SELECTED_USER,
  RESET_TIME_REGISTRATION_TIME_SHEETS,
  UPDATE_TIME_REGISTRATION_TABLE_FILTERS,
  UPDATE_TIME_REGISTRATION_TABLE_GLOBAL_FILTER,
  RESET_TIME_REGISTRATION_TABLE_FILTERS,
  RESET_TIME_REGISTRATION_TABLE_FILTER,
  NOT_FOUND_TIME_REGISTRATION_TIME_SHEETS,
} from "./actionTypes";
import { addToast } from "./uiActions";

// Components
import { ErrorOutlineIcon } from "mdi-react";

// Utilities
import getDateInterval from "../components/timeRegistration/utilities/getDateInterval";
import getUserLocale from "../utilities/get-user-locale";
import { subWeeks } from "date-fns";

export function updateTableFilters({ filters, callback }) {
  return function (dispatch) {
    dispatch({ type: UPDATE_TIME_REGISTRATION_TABLE_FILTERS, payload: filters });

    if (callback) callback();
  };
}

export function updateTableGlobalFilter(filter) {
  return function (dispatch) {
    dispatch({ type: UPDATE_TIME_REGISTRATION_TABLE_GLOBAL_FILTER, payload: filter });
  };
}

export function resetTableFilters({ callback }) {
  return function (dispatch, getState) {
    const user = getState().auth.user;
    const timeSheetsNotFound = getState().timeRegistration.table.timeSheetsNotFound;

    if (timeSheetsNotFound) return;

    // Get last week
    const lastWeek = subWeeks(new Date(), 1);

    let { startDate, endDate } = getDateInterval({ date: lastWeek, locale: getUserLocale(user) });

    dispatch({ type: RESET_TIME_REGISTRATION_TABLE_FILTERS, payload: { startDate, endDate } });

    if (callback) callback();
  };
}

export function resetTableFilter({ filterProperty }) {
  return function (dispatch) {
    dispatch({ type: RESET_TIME_REGISTRATION_TABLE_FILTER, payload: filterProperty });
  };
}

export function getTimeSheets({ timeRegistrationId }) {
  return async function (dispatch, getState) {
    try {
      const state = getState().timeRegistration.table;
      const { startDate, endDate } = state.filters;

      if (state.loading || state.endOfFeed) return;

      dispatch({ type: GET_TIME_REGISTRATION_TIME_SHEETS });

      const URL = `admin/time-registrations/${timeRegistrationId}/time-sheets?startDate=${startDate}&endDate=${endDate}`;

      const { data: timeSheets } = await req()(URL);

      if (timeSheets.length === 0) {
        const { language: lang } = getState().language;
        addToast({
          title: "Obs! Ingen registreringer fundet indenfor den angivne periode",
          content: "Det var desværre ikke muligt at finde nogen registreringer indenfor den angivne periode",
          icon: <ErrorOutlineIcon />,
          styleType: "neutral",
          duration: 20000,
        })(dispatch, getState);

        dispatch({ type: NOT_FOUND_TIME_REGISTRATION_TIME_SHEETS });
      } else {
        dispatch({ type: GET_TIME_REGISTRATION_TIME_SHEETS_SUCCESS, payload: timeSheets });
      }
    } catch (error) {
      dispatch({ type: GET_TIME_REGISTRATION_TIME_SHEETS_FAILURE });
    }
  };
}

export function resetTimeSheets() {
  return function (dispatch) {
    dispatch({ type: RESET_TIME_REGISTRATION_TIME_SHEETS });
  };
}

export function resetAll() {
  return function (dispatch) {
    dispatch({ type: RESET_TIME_REGISTRATION });
  };
}

export function updateTimeSheetApprovalStatus({
  timeRegistrationId,
  timeSheetIds = [],
  status,
  comment = "",
  callback = () => {},
}) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL });

      const URL = `admin/time-registrations/${timeRegistrationId}/time-sheets/approvals`;
      const body = { status, timeSheetIds, comment };

      const { data } = await req().put(URL, body);
      const { timeSheets, failedTimeSheetIds } = data;

      if (failedTimeSheetIds.length > 0) {
        const { language: lang } = getState().language;
        addToast({
          title: "Obs! Nogle registreringer blev ikke godkendte",
          content: "Dette skyldes at du ikke har rettighederne til at godkende disse registreringer",
          icon: <ErrorOutlineIcon />,
          styleType: "neutral",
          duration: 20000,
        })(dispatch, getState);
      }

      dispatch({
        type: UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_SUCCESS,
        payload: timeSheets,
      });
    } catch (error) {
      dispatch({ type: UPDATE_TIME_REGISTRATION_TIME_SHEET_APPROVAL_FAILURE });
    } finally {
      if (callback) callback();
    }
  };
}

export function exportTimeSheets({ timeRegistrationId, timeSheetIds, callback = () => {} }) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: EXPORT_TIME_REGISTRATION_TIME_SHEET });

      const URL = `admin/time-registrations/${timeRegistrationId}/time-sheets/export`;
      const body = { timeSheetIds };

      const { data } = await req().put(URL, body);
      const { timeSheets, failedTimeSheetIds, URLofExportedCSV = undefined } = data;

      if (Array.isArray(failedTimeSheetIds) && failedTimeSheetIds.length > 0) {
        const { language: lang } = getState().language;
        addToast({
          title: "Obs! Nogle registreringer blev ikke eksporteret",
          content:
            "Dette skyldes at du ikke har rettighederne til at eksportere, eller at registreringerne ikke er blevet godkendt endnu",
          icon: <ErrorOutlineIcon />,
          styleType: "neutral",
          duration: 20000,
        })(dispatch, getState);
      }

      if (URLofExportedCSV) window.open(URLofExportedCSV.fileURL, "_blank");

      dispatch({
        type: EXPORT_TIME_REGISTRATION_TIME_SHEET_SUCCESS,
        payload: timeSheets,
      });
    } catch (error) {
      dispatch({ type: EXPORT_TIME_REGISTRATION_TIME_SHEET_FAILURE });
    } finally {
      if (callback) callback();
    }
  };
}

/**
 * @param {Object} anon
 * @param {Number} anon.timeRegistrationId id of the registration to check permissions for for current user
 */
export function getPermissions({ timeRegistrationId }) {
  return async function (dispatch) {
    try {
      let URL = `admin/time-registrations/${timeRegistrationId}/permissions`;
      const { data: permissions } = await req()(URL);

      dispatch({
        type: GET_TIME_REGISTRATION_PERMISSIONS_SUCCESS,
        payload: permissions,
      });
    } catch (error) {
      dispatch({ type: GET_TIME_REGISTRATION_PERMISSIONS_FAILURE });
    }
  };
}

export function setSelectedDate(date) {
  return function (dispatch) {
    dispatch({
      type: SET_SELECTED_DATE,
      payload: date,
    });
  };
}

export function setSelectedUser(user) {
  return function (dispatch) {
    dispatch({
      type: SET_SELECTED_USER,
      payload: user,
    });
  };
}
