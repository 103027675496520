import React from "react";
import { css } from "emotion";
import { connect } from "react-redux";

// Utilities
import colors from "../../style/colors";

const Toggle = (props) => (
  <div className={componentStyles(props) + (props.loading ? " loading" : "")} onClick={props.onClick}>
    <div className={"toggle " + (props.on ? "on" : "off")} />
  </div>
);

const height = 30;
const transitionShort = "140ms ease";
const transitionLong = "300ms ease";

const componentStyles = (props) => css`
  width: ${height * 2}px;
  height: ${height}px;
  border: 1px ${props.on ? props.primaryColor : colors.midGrey} solid;
  border-radius: ${height / 2}px;
  background-color: ${props.on ? props.primaryColor || colors.midGrey : colors.ultraLightGrey};
  transition: background-color ${transitionLong}, border ${transitionLong};
  transition-delay: 45ms;
  cursor: pointer;

  .toggle {
    box-sizing: border-box;
    border: 1px ${props.on ? props.primaryColor : colors.midGrey} solid;
    border-radius: ${height / 2}px;
    height: ${height}px;
    width: ${height}px;
    background-color: var(--white);
    margin: -1px 0 0 -1px;
    transition: background-color ${transitionLong}, border ${transitionLong}, transform ${transitionShort};

    &.on {
      transition: background-color ${transitionLong}, border ${transitionLong}, transform ${transitionShort};
      transform: translate3d(${height}px, 0, 0);
    }
  }

  &.loading {
    background-color: transparent;
    border-color: transparent;

    .toggle {
      border-width: 3px;
      border-color: ${props.primaryColor} ${props.primaryColor} ${props.primaryColor} transparent;
      transition: background-color ${transitionLong}, border ${transitionLong}, transform ${transitionLong};
      animation: spin 0.7s infinite linear;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});
export default connect(mapStateToProps)(Toggle);
