import {
  GET_LANGUAGE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAILURE,
  GET_TRANSLATION_LANGUAGES,
  GET_TRANSLATION_LANGUAGES_SUCCESS,
  GET_TRANSLATION_LANGUAGES_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  language: {},
  loading: true,
  error: false,
  translationsLanguages: {
    languages: [],
    loading: false,
    error: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LANGUAGE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        language: action.payload,
      };

    case GET_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_TRANSLATION_LANGUAGES:
      return {
        ...state,
        translationsLanguages: {
          loading: true,
          error: false,
        },
      };

    case GET_TRANSLATION_LANGUAGES_SUCCESS:
      return {
        ...state,
        translationsLanguages: {
          languages: action.payload,
          loading: false,
          error: false,
        },
      };

    case GET_TRANSLATION_LANGUAGES_FAILURE:
      return {
        ...state,
        translationsLanguages: {
          loading: false,
          error: true,
        },
      };

    default:
      return state;
  }
}
