// Libs
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import NormalHighscoreMain from "./NormalHighscoreMain";
import NormalHighscoreMyDepartment from "./NormalHighscoreMyDepartment";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

const NormalHighscoreOverview = (props) => {
  const { language: lang } = useSelector((state) => state.language);

  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  const [activeTab, setActiveTab] = useState(0);
  const tabs = [lang.top10, lang.myDepartment];

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />
      <TabView activeTabIndex={activeTab} tabs={[<NormalHighscoreMain pageTitle={page.title}/>, <NormalHighscoreMyDepartment pageTitle={page.title}/>]} />
    </Page>
  );
};

export default NormalHighscoreOverview;
