// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

// Components
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import TextareaInput from "../ui/TextareaInput";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";
import RadioGroup from "../ui/RadioGroup";

// Utilities
import req from "../../utilities/request-utility";
import { getDateString, parseDate } from "../../utilities/parse-date";

// Config
import typeOfEndDateConfig from "./config/typeOfEndDate";

// Styles
import breakpoints from "../../config/breakpoints";

// Actions
import { addToast, hideModalPage } from "../../actions/uiActions";
import TextInput from "../ui/TextInput";
import CheckboxGroup from "../ui/CheckboxGroup";
import DropDownList from "../ui/dropDown/DropDownList";

const SickLeaveModal = (props) => {
  // Language state from redux
  const { language: lang } = useSelector((state) => state.language);

  // From props
  const { addToast, typeOfEndDate, hideModalPage, getSickStatus, showComments } = props;

  // Local state
  const [loading, setLoading] = useState(true);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [receiverOptions, setReceiverOptions] = useState([]);
  const [formula, setFormula] = useState({
    receiver: null,
    nearestLeader: "",
    sickReason: null,
    sickStartDate: format(new Date(), "yyyyMMdd"),
    sickEndDate: null,
    /* The input field for comments is only visible if the app has put the setting "SICKLEAVE_SHOW_COMMENTS" to true in the config column in menuitems.  
       The sick comment will be sent to the reciever in the email, but will not be stored in our database */
    sickComment: "",
  });
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [contactedLeader, setContactedLeader] = useState(false);
  const [notValidIds, setNotValidIds] = useState([]);

  // Get the backend sick-leave registration with questions attached
  useEffect(() => {
    req()(`sick-leave/forms/sick`).then(({ data: form }) => {
      setReasonOptions(form.reasons);
      if (form.receivers && form.receivers.length > 0) setReceiverOptions(form.receivers);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  // Validation - checks if the required fields are filled
  useEffect(() => {
    if (acceptConditions && contactedLeader) {
      const currentDate = parseInt(getDateString());
      const { receiver, sickReason, sickStartDate, sickEndDate, sickLengthEstimate } = formula;
      const validationArr = [];
      if (receiverOptions.length > 0 && !receiver) validationArr.push(1);
      if (!sickReason) validationArr.push(2);
      if (!sickStartDate) validationArr.push(3);
      if (sickStartDate > currentDate) {
        addToast({
          styleType: "error",
          title: lang.unvalidDate,
          content: lang.firstSickdayCantbeInFuture,
          duration: 5000,
        });
        validationArr.push(3);
      }

      if (!sickEndDate) validationArr.push(4);
      if (typeOfEndDate !== typeOfEndDateConfig.estimate) {
        if (sickEndDate && sickEndDate < sickStartDate) {
          addToast({
            styleType: "error",
            title: lang.unvalidDate,
            content: lang.lastSickdayCantBeBeforeFirst,
            duration: 5000,
          });
          validationArr.push(4);
        }
      }

      setNotValidIds([...validationArr]);
    }
    // eslint-disable-next-line
  }, [acceptConditions, contactedLeader, formula]);

  const onSubmit = async () => {
    setLoading(true);
    req()
      .put(`sick-leave/forms/sick`, {
        ...formula,
        showComments,
        typeOfEndDate,
      })
      .then(() => {
        setLoading(false);
        hideModalPage();
        addToast({
          styleType: "success",
          title: lang.thankYouForTheRegistration,
          content: lang.registrationReceived,
          duration: 5000,
        });
        // Fetch the sick registration status
        getSickStatus();
      })
      .catch((err) => {
        hideModalPage();
        setLoading(false);
        addToast({ template: "error" });
      });
  };

  return (
    <ScrollView className={componentStyle()}>
      {loading && (
        <div className="spinner-container">
          <InlineSpinner />
        </div>
      )}
      {!loading && (
        <div className="sick-outer-container">
          {receiverOptions.length > 0 && (
            <div className="sick-container">
              <p className={notValidIds.some((id) => id === 1) ? "sick-label not-valid-title" : "sick-label"}>
                {lang.receiver}
              </p>
              <RadioGroup
                className={notValidIds.some((id) => id === 1) ? "not-valid" : ""}
                selectedValues={formula.receiver ? formula.receiver.id : formula.receiver}
                onSelect={(id) => setFormula({ ...formula, receiver: receiverOptions.find((reason) => reason.id === id) })}
                options={receiverOptions}
              />
            </div>
          )}
          <div className="sick-container">
            <p className="sick-label">{lang.closestLeader}</p>
            <TextInput
              placeholder={lang.writeHere}
              value={formula.nearestLeader}
              onChange={(e) => setFormula({ ...formula, nearestLeader: e.target.value })}
            />
          </div>
          {reasonOptions.length > 0 && (
            <div className="sick-container">
              <p className={notValidIds.some((id) => id === 2) ? "sick-label not-valid-title" : "sick-label"}>
                {lang.reason}
              </p>
              <RadioGroup
                className={notValidIds.some((id) => id === 2) ? "not-valid" : ""}
                selectedValues={formula.sickReason ? formula.sickReason.id : formula.sickReason}
                onSelect={function (id) {
                  setFormula({ ...formula, sickReason: reasonOptions.find((reason) => reason.id === id) });
                }}
                options={reasonOptions}
              />
            </div>
          )}

          <div className="sick-container">
            <p className={notValidIds.some((id) => id === 3) ? "sick-label not-valid-title" : "sick-label"}>
              {lang.firstSickDay}
            </p>
            <DatePicker
              className={notValidIds.some((id) => id === 3) ? "not-valid" : ""}
              name="date"
              defaultDate={parseDate(formula.sickStartDate, "yyyy-MM-dd")}
              onDateChange={(date) => setFormula({ ...formula, sickStartDate: date.replace(/-/g, "") })}
            />
          </div>

          {typeOfEndDate === typeOfEndDateConfig.estimate ? (
            <div className="sick-container">
              <p className={notValidIds.some((id) => id === 4) ? "sick-label not-valid-title" : "sick-label"}>
                {lang.specifyDuration}:
              </p>
              <DropDownList
                className={notValidIds.some((id) => id === 4) ? "not-valid" : ""}
                style={{ marginBottom: "1.5rem" }}
                dropDownListContent={[
                  // in the database shorter and longer sick leave has been stored as one of these two strings. To make it backwards compatible I keep using them.
                  { id: "Kortere fravær", title: lang.illnessShorterDuration },
                  { id: "Længere fravær", title: lang.illnessLongerDuration },
                ]}
                onChange={(e) => setFormula({ ...formula, sickEndDate: e.target.value })}
              />
            </div>
          ) : (
            <div className="sick-container">
              <p className={notValidIds.some((id) => id === 4) ? "sick-label not-valid-title" : "sick-label"}>
                {lang.expectedLastSickDay}
              </p>
              <DatePicker
                className={notValidIds.some((id) => id === 4) ? "not-valid" : ""}
                name="date"
                defaultDate={parseDate(formula.sickEndDate, "yyyy-MM-dd")}
                sickEndDate
                onDateChange={(date) => setFormula({ ...formula, sickEndDate: date.replace(/-/g, "") })}
              />
            </div>
          )}

          {showComments && (
            <div className="sick-container">
              <p className="sick-label">{lang.comments}:</p>
              <TextareaInput
                placeholder={lang.writeHere}
                value={formula.sickComment}
                onChange={(e) => setFormula({ ...formula, sickComment: e.target.value })}
              />
            </div>
          )}

          <div className="sick-container">
            <CheckboxGroup
              options={[{ id: 1, title: lang.iDeclareOnFaithAndLaws }]}
              onSelect={() => setAcceptConditions(!acceptConditions)}
            />
          </div>
          <div className="sick-container">
            <CheckboxGroup
              options={[{ id: 1, title: lang.yesIHaveContactedMyImmediateManager }]}
              onSelect={() => setContactedLeader(!contactedLeader)}
            />
          </div>
          <Button
            onClick={() => onSubmit()}
            buttonType={!acceptConditions || !contactedLeader || notValidIds.length > 0 ? "disabled" : ""}
            disabled={!acceptConditions || !contactedLeader || notValidIds.length > 0}
          >
            {lang.createSickLeave}
          </Button>
        </div>
      )}
    </ScrollView>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: var(--white);

  .not-valid {
    border: 1px solid var(--redMedium);
  }

  .not-valid-title {
    color: var(--redMedium);
  }

  div.sick-outer-container {
    max-width: ${breakpoints.md}px;
    margin: auto;

    div.sick-container {
      margin-bottom: 2rem;

      p.sick-label {
        margin-bottom: 0.5rem;
      }

      p.sick-other-label {
        font-size: 1.125rem;
        margin: 0.5rem 0;
      }
    }
  }

  div.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(SickLeaveModal);
