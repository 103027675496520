// Libs
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideContextMenu } from "../../actions/uiActions";
import { css } from "emotion";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

// Styles
import colors from "../../style/colors";
import { durations } from "../../config/animations";
import breakpoints from "../../config/breakpoints";

// Icons
import { CloseIcon } from "mdi-react";
import Overlay from "./Overlay";

/**
 * Component is implemented through a redux action, this means you should NOT implement this component directly but rather implement is as:
 * ```
 * this.props.showContextMenu([
      {
        icon: <EditIcon />,
        title: "Rediger",
        callback: () => {}
      },
      {
        icon: <TrashIcon />,
        title: "Slet registrering",
        callback: () => {}
      }
    ]);
 *  ```
 * import the needed action from ./actions
 *  And remember to do this on the implementing component
 *  ```
 *  const mapDispatchToProps = dispatch => ({
 * showContextMenu: bindActionCreators(showContextMenu, dispatch)
 * });
 *  ```
 * showContext takes an Array of the options you want to have displayed + a cancel option. so you dont need to write that.
 * To write an action for the context menu follow the structure above:
 *  ```
 *  {
 *  icon: the icon you want to be displayed to the left of the title
 *  title: the text on the element
 *  callback: the function to execute when the element is clicked, NOTE: don't forget () =>, as this prevents the function from executing on component mount.
 *  }
 *  ```
 */
const ContextMenu = (props) => {
  let { active, actions, defaultActionTitle, closeOnActionCallback } = props.contextMenu;
  let { lang } = props;

  return (
    <div className={componentStyles(props)}>
      <Overlay active={active} onClick={props.hideContextMenu} />
      <CSSTransition
        in={active}
        timeout={durations.normal}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames="context-menu"
      >
        <div className="context-menu" data-test-id="context-menu">
          {actions.map((action, actionIndex) => (
            <div
              className="action"
              data-test-id={action["data-test-id"] || ""}
              key={actionIndex}
              onClick={(e) => {
                e.stopPropagation();
                action.callback();
                if (closeOnActionCallback !== false) props.hideContextMenu();
              }}
            >
              {action.icon} {action.title}
              {actionIndex !== actions.length - 1 && <div className="seperator" />}
            </div>
          ))}

          <div className="action default-action" onClick={props.hideContextMenu}>
            <CloseIcon /> {defaultActionTitle || lang.cancel}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const componentStyles = () => css`
  z-index: 30;
  position: relative;

  .context-menu {
    position: fixed;
    bottom: 0px; /* Component enters with a spring animation, and needs extra padding in bottom */
    padding-bottom: 0px;
    left: 0;
    /* z-index: 6; */
    width: 100%;
    background-color: var(--white);
    will-change: auto;

    @media screen and (min-width: ${breakpoints.md}px) {
      width: ${breakpoints.md}px;
      bottom: 1rem;
      border-radius: 6px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    }

    &.context-menu-enter {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    &.context-menu-enter-active {
      transform: translate3d(0, 0%, 0);
      opacity: 1;
      transition: transform ${durations.fast}ms ease, opacity ${durations.fast}ms;
    }
    &.context-menu-exit {
      opacity: 1;
      transform: scale(1);
    }
    &.context-menu-exit-active {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: transform ${durations.normal}ms ease, opacity ${durations.normal}ms;
    }

    .action {
      padding: 0.75rem;
      display: flex;
      position: relative;
      transition: ${durations.normal}ms;
      transition-delay: 400ms;

      &:active {
        background-color: var(--lightGrey);
        transition: 80ms;
      }

      &:hover {
        cursor: pointer;
      }

      svg {
        padding: 0.25rem;
        fill: var(--darkGrey);
        margin-right: 0.5rem;
      }

      .seperator {
        position: absolute;
        height: 1px;
        bottom: 0;
        right: 0;
        background-color: var(--midGrey);
        width: 95%;
      }

      &.default-action {
        border-top: 1px var(--midGrey) solid;
      }
    }
  }
`;

ContextMenu.propTypes = {
  /** Language object from redux, needed for easy translated text elements */
  lang: PropTypes.object,
};
const mapStateToProps = (state) => ({
  contextMenu: state.ui.contextMenu,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  hideContextMenu: bindActionCreators(hideContextMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
