import React from "react";

import SearchPageBreadcrumb from "./../SeachPageBreadcrumb";
import highlightMatch, { modes as highLightModes } from "../../../../utilities/highlight-match";
import getExcerpt from "../../../../utilities/get-excerpt";
import markdownParser from "../../../../utilities/markdown-parser";
import { useSelector } from "react-redux";
import pageNavigatorV2 from "../../../../utilities/page-navigator-v2";
import getLinkPathFromPageObject from "../../../../utilities/get-link-path-from-page-object";
import pageTransitions from "../../../../config/page-transitions";
import { ChevronRightIcon } from "mdi-react";
import { getExcerptAndHighlightMatch } from "../utils/getExcerptAndHightlightMatch";

function getURL(result) {
  if (result.page.module.toLowerCase() === "newsfilearchive") {
    return `${getLinkPathFromPageObject(result.page, "absolute")}`;
  }
  if (result.page.module === "newsfeedFaq") {
    // For faq go straight to page instead
    return `${getLinkPathFromPageObject(result.page, "absolute")}&scrollToId=${result.data.id}`;
  } else {
    return `${getLinkPathFromPageObject(result.page, "absolute", { suffix: result.data.id })}`;
  }
}

const SearchResultNewsPost = (props) => {
  const { result } = props;
  const searchTerm = useSelector((s) => s.searchPage.searchTerm);

  return (
    <div
      className={`list-element`}
      key={`div__news-post__${result.data.id}}`}
      onClick={() => {
        pageNavigatorV2({ path: getURL(result), direction: pageTransitions.forward });
      }}
    >
      <div style={{ display: "flex", marginBottom: "0.35rem" }}>
        <SearchPageBreadcrumb page={result.page} key={`breadcrumb__div__news-post__${result.data.id}`} />
      </div>
      <p
        className="title"
        dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content: result.data.title, searchTerm })}
      />
      <div
        className="content"
        dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content: result.data.content, searchTerm })}
      />

      <ChevronRightIcon className="navigation-arrow-indicator" />
    </div>
  );
};

export default SearchResultNewsPost;
