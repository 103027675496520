import { addToast } from "../../actions/uiActions";
import store from "../../store";
import subscribeUserToPush from "./subscribe-user-to-push-notifications";
import styleTypes from "../../config/styleTypes";
import { SmileySadIcon } from "mdi-react";
import trackAction from "../../services/tracking/trackAction";
import trackingActions from "../../config/trackingActions";

function askForNotificationPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
}
/**
 * Function that activates the build-in browser-UI asking the user for notification-permissions
 * If user accepts this permission then
 *
 */
async function promptForPushPermission() {
  try {
    await askForNotificationPermission();
    await trackAction({ action: trackingActions.NOTIFICATION_DIALOG_ACCEPTED });
  } catch {
    let lang = store.getState().language.language;

    store.dispatch(
      addToast({
        styleType: styleTypes.neutral,
        icon: <SmileySadIcon />,
        title: lang.toastPushNotificationPermissionFailedTitle,
        content: lang.toastPushNotificationPermissionFailedContent,
      })
    );
    return;
  }

  if (Notification.permission === "granted") {
    await subscribeUserToPush();
  }
}

export default promptForPushPermission;
