// Libs
import React, { useCallback, useEffect, useRef, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

// Components
import { SearchIcon, CloseIcon } from "mdi-react";

// Styles
import colors from "../../../../style/colors";
import common from "../../../../style/common";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

const DropDownSearchList = (props) => {
  const { options, className, style, appended } = props;

  const optionsContainerRef = useRef(null);
  const optionRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [cursor, setCursor] = useState(0);

  function handleKeyDown(e) {
    // arrow up/down button should select next/previous list element
    if (e.key === "ArrowUp" && cursor > 0) {
      if (!optionRef.current || !optionsContainerRef.current) return;

      setCursor((c) => c - 1);

      const optionHeight = optionRef.current.offsetHeight;
      const optionHeightToContainerTop = optionRef.current.offsetTop;
      const containerHeight = optionsContainerRef.current.scrollTop;

      if (optionHeightToContainerTop < optionHeight) {
        optionsContainerRef.current.scrollTop = 0;
      } else if (cursor === filteredOptions.length - 1) {
        optionsContainerRef.current.scrollTop = optionRef.current.offsetTop;
      } else {
        optionsContainerRef.current.scrollTop = containerHeight - optionHeight;
      }
    } else if (e.key === "ArrowDown" && cursor < filteredOptions.length - 1) {
      if (!optionsContainerRef.current) return;

      setCursor((c) => c + 1);
      optionsContainerRef.current.scrollTop = optionRef.current.offsetTop;
    } else if (e.key === "Backspace") {
      if (!optionsContainerRef.current) return;

      optionsContainerRef.current.scrollTop = 0;
      setCursor(0);
    } else if (e.key === "Enter") {
      selectOption(filteredOptions[cursor]);
    }

    if (["ArrowDown", "ArrowUp", "BackSpace"].includes(e.key)) e.preventDefault();
  }

  function filterOptions({ options, searchValue }) {
    if (!searchValue) return setFilteredOptions([...options]);
    setFilteredOptions(
      options.filter((option) => {
        if (option.title && option.title.toLowerCase().includes(searchValue.toLowerCase())) {
          return option;
        } else if (option.name && option.name.toLowerCase().includes(searchValue.toLowerCase())) {
          return option;
        }
      })
    );
  }

  function selectOption(option) {
    setCursor(0);
    setSearchValue("");
    props.onSelect({ target: { value: option.id } });
  }

  // To prevent reCreation of function in useClickOutside()
  const onSearchValueChange = useCallback(() => {
    setSearchValue("");
  }, [searchValue]);

  // Close optionsContainer when clicking outside of div
  useOnClickOutside(optionsContainerRef, onSearchValueChange);

  useEffect(() => {
    filterOptions({ options, searchValue });
  }, [options, searchValue]);

  let [stateClassName, setStateClassName] = useState("");

  useEffect(() => {
    setStateClassName(componentStyle(props));
  }, []);

  return (
    <div className={stateClassName} style={style}>
      <div className="search-container">
        <input
          className={`${appended ? "append top-component" : ""} ${
            searchValue && filteredOptions.length > 0 ? "append options" : ""
          }`}
          onKeyDown={handleKeyDown}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue ? <CloseIcon className="cancel" onClick={() => setSearchValue("")} /> : <SearchIcon />}
      </div>
      {searchValue && filteredOptions.length > 0 && (
        <ul ref={optionsContainerRef} className="options-container" style={appended && { borderTop: `unset` }}>
          {filteredOptions.map((option, index) => (
            <li
              key={`dropdown-list-option__${option.id}__${option.title}`}
              id={`drop-down-search-list-option-${option.id}`}
              onClick={() => selectOption(option)}
              ref={cursor === index ? optionRef : null}
              className={cursor === index ? "cursor" : ""}
            >
              {option.title || option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const componentStyle = () => css`
  div.search-container {
    display: flex;
    align-items: end;
    position: relative;

    input {
      background: none;
      width: 100%;
      border: 1px solid var(--midGrey);
      border-radius: 3px;
      background: transparent;
      padding-left: 1rem;
      padding-right: 2.5rem;
      display: block;
      height: 40px;
      font-size: 1rem;
      font-family: ${common.fontStack};
      appearance: none;
      color: #6e6e6e;

      &:focus {
        outline: 0;
        z-index: 15;
        border: 1px solid var(--midGrey);
      }

      &.append {
        &.top-component {
          margin-top: -1px;
        }
        &.options {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    svg {
      margin-left: -40px;
      pointer-events: none;
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.15rem;
      height: 1.15rem;
      color: var(--darkGrey);

      &.cancel {
        pointer-events: unset;
        cursor: pointer;
      }
    }
  }

  ul.options-container {
    list-style: none;
    max-height: 120px;
    overflow-y: auto;
    position: relative;
    border: 1px solid var(--midGrey);
    border-bottom-left-radius: 3px;
    margin-bottom: -1px;
    border-bottom-right-radius: 3px;

    li {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      list-style: none;

      height: 40px;
      cursor: pointer;
      color: var(--darkGrey);
      border-bottom: 1px solid var(--midGrey);

      &.cursor {
        background-color: var(--ultraLightGrey);
      }

      &:last-child {
        border-bottom: none;
      }

      &:active {
        background-color: ${tinycolor(colors.midGrey)};
      }
    }
  }
`;

export default DropDownSearchList;
