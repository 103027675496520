// Libs
import dayjs from "dayjs";

import "dayjs/locale/da";
import "dayjs/locale/en";
import "dayjs/locale/fi";
import "dayjs/locale/pl";
import "dayjs/locale/se";
import store from "../store";

// Utilities

function setDayjsLocale() {
  const language = store.getState().auth?.user?.language;

  switch (language) {
    case "da":
      dayjs.locale("da");
      dayjs.updateLocale("da", {
        months: [
          "Januar",
          "Februar",
          "Marts",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "December",
        ],
      });
      break;

    case "en":
      dayjs.locale("en");
      break;

    case "fi":
      dayjs.locale("fi");
      break;

    case "pl":
      dayjs.locale("pl");
      break;

    case "se":
      dayjs.locale("se");
      break;

    default:
      dayjs.locale("en");
  }

  return dayjs;
}

export default setDayjsLocale;
