// Libs
import { useDispatch, useSelector } from "react-redux";
import useLang from "../../../hooks/useLang";
import { useEffect, useState } from "react";
import { css } from "emotion";

// Components
import { InfoOutlineIcon, InformationOutlineIcon, TrashCanEmptyIcon, TrashCanOutlineIcon } from "mdi-react";
import InformationBox from "../../ui/InformationBox";
import ButtonRounded from "../../ui/ButtonRounded";
import TextareaInput from "../../ui/TextareaInput";

// Actions
import { refreshRegistrationPost, refetchRegistrationTabPost } from "../../../actions/registrationActions";
import { addToast, showDialog } from "../../../actions/uiActions";

// Config
import styleTypes from "../../../config/styleTypes";
import { feedTypes } from "../config";

// util
import req from "../../../utilities/request-utility";

function AdminReplyQuestionInput({ questionAnswer, postId, registrationId, feedType }) {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.auth.user);
  const lang = useLang();

  // local state
  const [textInputValue, setTextInputValue] = useState(questionAnswer.title || ""); // to prevent the string value of "null"
  const [enableEditing, setEnableEditing] = useState(false);
  const [submittingReply, setSubmittingReply] = useState(false);
  const [deletingReply, setDeletingReply] = useState(false);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);

  const currentTab = useSelector((state) => state.registration.processflow.currentTab.tab);

  useEffect(() => {
    if (textInputValue.trim().length > 0) {
      setEnableSubmitButton(true);
    } else {
      setEnableSubmitButton(false);
    }
  }, [textInputValue]);

  async function saveAdminReply() {
    try {
      setSubmittingReply(true);

      let url = `admin/registrations/${registrationId}/posts/${postId}/admin-reply-questions/${questionAnswer.id}`;
      let formData = { answer: textInputValue };

      if (!questionAnswer.title) {
        await req().post(url, formData);
      } else {
        await req().put(url, formData);
      }

      if (feedType === feedTypes.processflow) {
        dispatch(refetchRegistrationTabPost({ registrationId, tab: currentTab, postId, feedType: feedTypes.processflow }));
      } else {
        dispatch(
          refreshRegistrationPost({
            feedType,
            postId,
            registrationId,
          })
        );
      }
    } catch (err) {
      dispatch(addToast({ template: "error" }));
      window.Rollbar.error(err);
    } finally {
      setSubmittingReply(false);
      setEnableEditing(false);
    }
  }

  async function deleteReply() {
    try {
      setDeletingReply(true);
      await req().delete(`admin/registrations/${registrationId}/posts/${postId}/admin-reply-questions/${questionAnswer.id}`);

      if (feedType === feedTypes.processflow) {
        dispatch(refetchRegistrationTabPost({ registrationId, tab: currentTab, postId, feedType: feedTypes.processflow }));
      } else {
        dispatch(
          refreshRegistrationPost({
            feedType,
            postId,
            registrationId,
          })
        );
      }
    } catch (err) {
      dispatch(addToast({ template: "error" }));
      window.Rollbar.error(err);
    } finally {
      setDeletingReply(false);
      setTextInputValue("");
    }
  }

  return (
    <>
      {user.admin && (
        <InformationBox
          icon={<InfoOutlineIcon />}
          style={{ marginBottom: "0.65rem", marginTop: "0.65rem" }}
          title={lang.pleaseNote}
          description={lang.questionReplyableForAdmins}
        />
      )}

      {!enableEditing && (
        <>
          {!questionAnswer.title ? (
            <p className="no-answer answer">
              <InformationOutlineIcon /> {lang.noAnswer}
            </p>
          ) : (
            <p className="answer">{questionAnswer.title}</p>
          )}

          {user.admin && (
            <div className={componentStyles()}>
              <ButtonRounded
                style={{ marginTop: "-0.5rem" }}
                title={questionAnswer.title ? lang.edit : lang.addAnswer}
                size="small"
                secondary={true}
                onClick={() => setEnableEditing(true)}
              />
              {questionAnswer.title && (
                <ButtonRounded
                  active={deletingReply}
                  style={{ marginTop: "-0.5rem" }}
                  title={lang.delete}
                  size="small"
                  styleType={styleTypes.error}
                  secondary={true}
                  onClick={() =>
                    dispatch(
                      showDialog({
                        icon: <TrashCanOutlineIcon />,
                        title: lang.delete,
                        styleType: styleTypes.error,
                        content: lang.deleteInfoWith__placeholder__.replace(
                          "{{placeholder}}",
                          lang.yourAnswer.toLowerCase()
                        ),
                        primaryAction: deleteReply,
                        primaryActionTitle: lang.yeDeleteAnswer,
                      })
                    )
                  }
                />
              )}
            </div>
          )}
        </>
      )}
      {enableEditing && (
        <>
          <TextareaInput
            rows={2}
            minHeight={"1rem"}
            disabled={false}
            placeholder={lang.writeHere}
            style={{ marginBottom: "0.65rem" }}
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value)}
          />
          {user.admin && (
            <div className={componentStyles()}>
              <ButtonRounded
                disabled={!enableSubmitButton}
                size="small"
                title={lang.save}
                active={submittingReply}
                onClick={() => saveAdminReply()}
              />
              <ButtonRounded size="small" title={lang.cancel} secondary={true} onClick={() => setEnableEditing(false)} />
            </div>
          )}
        </>
      )}
    </>
  );
}

const componentStyles = () => css`
  display: flex;
  gap: 0.35rem;
  align-items: center;
  margin-bottom: 2rem;
`;

export default AdminReplyQuestionInput;
