// Libs
import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);
import customParseFormat from "dayjs/plugin/customParseFormat";

// Components
import ScrollView from "../../ui/ScrollView";
import Spinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";
import { InfoOutlineIcon } from "mdi-react";

// Hooks
import useTableContent from "../hooks/useTableContent";

// Styles
import colors from "../../../style/colors";

// Utilities
import addColorToTableNameCell from "../utilities/addColorToTableNameCell";
import addColorToCell from "../utilities/addColorToCell";

require("dayjs/locale/da");

const Table = (props) => {
  const { headers, rows, loading } = useTableContent(props.filters);

  return (
    <ScrollView className={`${componentStyle2()} ${props.className || ""}`} style={{ overflowX: "auto" }}>
      {loading && <Spinner />}
      {!loading && (rows.length === 0 || headers.length === 0) && (
        <StatusBox icon={<InfoOutlineIcon />} title="Afdeling ikke valgt" content="Vælg en afdeling for at se en oversigt" />
      )}
      {!loading && rows.length > 0 && headers.length > 0 && (
        <table>
          <thead>
            {/* Header */}
            <tr className="columns-header date-wrapper">
              <th className="total background-fill" style={{ zIndex: 10 }}>
                <p className="total-wrapper">Total</p>
              </th>
              {headers.map(({ date, total }) => (
                <th className="header-column no-fill" key={`header__${date}`}>
                  <p>{dayjs(String(date), "YYYYMMDD").locale("da").format("ddd")}</p>
                  <p>{dayjs(String(date), "YYYYMMDD").locale("da").format("D/M")}</p>

                  <div className="filled">{total}</div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {/* Actual data  */}
            {rows.map((row) => (
              <tr className={`row `} key={`row__${row.name}`}>
                <td className={`name ${addColorToTableNameCell(row.dates)}`}>
                  <div className={`name-wrapper`}>{row.name}</div>
                  <div className="total-wrapper">{row.total}</div>
                </td>

                {row.dates.map(({ count, status, date }) => (
                  <td
                    key={`cell__${date}__${row.name}`}
                    style={{ textAlign: "center" }}
                    className={addColorToCell(status, date)}
                  >
                    <span className="opacify">{count}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </ScrollView>
  );
};

const componentStyle2 = () => css`
  table {
    border-collapse: collapse;
    position: relative;
    margin: 0 auto;
    color: var(--black);
    position: relative;
    font-size: 0.9rem;
    border-spacing: 0px;

    td,
    th {
      background: var(--white);
      padding: 0.35rem 0.55rem;
      border: 1px var(--midGrey) solid;
      margin: 0px 0px 0 -1px;
    }

    th.no-fill {
      background: inherit;
      border: 0px;
    }

    th.background-fill {
      background: var(--lightGrey);
      border: 0px;
    }

    tr th {
      color: var(--darkGrey);
    }
    .opacify {
      opacity: 0.7;
    }

    .date-wrapper th {
      font-weight: 400;
      color: var(--darkGrey);
    }

    tr > th:nth-child(1),
    tr > td:nth-child(1) {
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      border-right: 0px transparent solid;
      z-index: 1;
    }
    tr > th:nth-child(2),
    tr > td:nth-child(2) {
      border-left: 0px transparent solid;
    }

    th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }

    /* Color styling */

    .met {
      background-color: ${tinycolor(colors.greenLight).darken(5).toString()};
    }

    .absent {
      background-color: ${tinycolor(colors.redLight).darken(5).toString()};
    }

    .sick {
      background-color: ${tinycolor(colors.yellow).lighten(10).toString()};
    }

    td.name {
      white-space: nowrap;
      position: relative;

      div.name-wrapper {
        margin-left: 0.35rem;
        color: var(--darkGrey);
        padding-right: 4.85rem;
      }

      div.total-wrapper {
        color: var(--darkGrey);
        background-color: var(--white);
        position: absolute;
        right: 0px;
        border-left: 1px var(--midGrey) solid;
        border-right: 1px var(--midGrey) solid;
        top: 0;
        bottom: 0;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }
    }

    th.total {
      position: relative;

      .total-wrapper {
        color: var(--darkGrey);
        position: absolute;
        right: 0px;
        margin-top: -1px;
        border: 1px var(--midGrey) solid;
        background-color: var(--white);
        line-height: 2rem;
        bottom: 0;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;

        &:after {
          position: absolute;
          content: "";
          bottom: -1px;
          height: 1px;
          width: 100vw;
          background-color: var(--midGrey);
          display: block;
        }
      }
    }

    .header-column {
      padding-bottom: 0px;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      font-size: 0.9rem;
      z-index: 1;

      p {
        background-color: var(--lightGrey);
        padding-top: 0.25rem;

        &:last-of-type {
          padding-bottom: 0.5rem;
        }
      }

      .filled {
        background-color: var(--white);
        margin-top: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 2rem;
        width: 100%;
        border-right: 1px var(--midGrey) solid;
        border-top: 1px var(--midGrey) solid;
        border-bottom: 1px var(--midGrey) solid;
      }
    }
  }
`;

export default Table;
