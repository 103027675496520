// Libs
import React, { useContext } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import iconConverter from "../../../../../utilities/icon-converter";
import removePageFromPage from "../../../utilities/removePageFromPages";
import addPageToParentPage from "../../../utilities/addPageToParentPage";

// Components
import ButtonRounded from "../../../../ui/ButtonRounded";
import { WebIcon } from "mdi-react";

// Config
import { NAVIGATION_LIST, NAVIGATION_GRID, CHAT, CHAT_NAVIGATION_LIST } from "../../../config/moduleTypes";
import { pagesChangeHandler } from "../../../../../actions/pageBuilderActions";
import { hideModalPage } from "../../../../../actions/uiActions";

// Hooks
import useNotAllowedToMoveToPages from "../../../hooks/useNotAllowedToMoveToPages";
import useNavigateToSubPages from "../../../hooks/useNavigateToSubPages";
import { PageBuilderContext } from "../../../context/PageBuilderContext";

const PageBuilderTreeLeaf = (props) => {
  const dispatch = useDispatch();

  const { page, viewOnly = false, pageToBeMoved, pageClickCallback = undefined } = props;
  const { primaryColor } = useSelector((state) => state.appConfig);

  const { pageIdsNotAllowedToBeMovedTo } = useNotAllowedToMoveToPages({
    parentPageId: pageToBeMoved ? pageToBeMoved.id : undefined,
  });

  const { moduleId } = useContext(PageBuilderContext);
  const { navigateToSubPages } = useNavigateToSubPages({ moduleId });

  const pages = useSelector((state) => state.pageBuilder.pages);

  const movePage = () => {
    let pagesWithRemovedPage = removePageFromPage({ pageToBeRemovedId: pageToBeMoved.id, pages });

    let pagesWithAddedPages = addPageToParentPage({
      pages: pagesWithRemovedPage,
      pageToBeMoved,
      destinationParentPage: page,
    });

    dispatch(pagesChangeHandler({ pages: pagesWithAddedPages }));

    navigateToPage();
  };

  const navigateToPage = () => {
    navigateToSubPages(page.id);
    dispatch(hideModalPage());
  };

  const shouldItRenderButton = () => {
    return (
      ((!viewOnly && !pageIdsNotAllowedToBeMovedTo.includes(page.id)) || viewOnly) &&
      ((pageToBeMoved?.parentId !== page.id && !viewOnly) || viewOnly) &&
      (([NAVIGATION_LIST, NAVIGATION_GRID].includes(page.module.trim()) && pageToBeMoved?.module !== CHAT) ||
        ([CHAT_NAVIGATION_LIST].includes(page.module.trim()) && pageToBeMoved?.module === CHAT)) &&
      page.id !== pageToBeMoved?.id
    );
  };

  const onPageClick = (page = undefined) => {
    if (props.pageClickCallback) {
      props.pageClickCallback(page);
      return;
    }

    if (viewOnly) {
      navigateToPage();
    } else {
      movePage();
    }
  };

  return (
    <div className={componentStyle(primaryColor, viewOnly)}>
      <div className="leaf-wrapper">
        {shouldItRenderButton() ? (
          <ButtonRounded
            data-test-id={`tree-leaf-page-btn-${page.title}`}
            secondary={true}
            className={`leaf ${page && pageToBeMoved && page.id === pageToBeMoved.id ? "current-position" : ""}`}
            onClick={() => onPageClick(page)}
          >
            <div className="icon-wrapper">{iconConverter(page.icon)}</div>
            <p>{page.title}</p>
            <div className={`online-status ${page.active ? "on" : "off"}`}>
              <WebIcon />
            </div>
          </ButtonRounded>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={`leaf ${page && pageToBeMoved && page.id === pageToBeMoved.id ? "current-position" : ""}`}>
              <div className="icon-wrapper">{iconConverter(page.icon)}</div>
              <p>{page.title}</p>
              <div className={`online-status ${page.active ? "on" : "off"}`}>
                <WebIcon />
              </div>
            </div>
            {page && pageToBeMoved && page.id === pageToBeMoved.id && (
              <p style={{ marginLeft: "1rem", color: primaryColor, fontWeight: "bold" }}>Du er her</p>
            )}
          </div>
        )}
      </div>
      {Array.isArray(page.pages) && page.pages.length > 0 && (
        <div className="child-pages">
          {page.pages.map((page) => (
            <PageBuilderTreeLeaf
              key={page.id}
              page={page}
              viewOnly={viewOnly}
              pageToBeMoved={pageToBeMoved}
              pageClickCallback={pageClickCallback}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  .leaf-wrapper {
    display: flex;
    align-items: center;

    .leaf {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;

      &.current-position {
        border: 1px solid ${primaryColor};
        border-radius: 50px;
      }

      & > p {
        color: var(--darkGrey);
        margin-left: 0.75rem;
      }

      .icon-wrapper {
        height: 1.25rem;
        width: 1.25rem;

        svg {
          fill: var(--darkGrey);
        }
      }

      .online-status {
        display: flex;
        align-items: center;

        svg {
          height: 1.25rem;
          width: 1.25rem;
          margin-left: 0.75rem;
        }

        &.on {
          color: var(--green);
        }

        &.off {
          color: var(--midGrey);
        }
      }
    }

    div.current-location {
      display: flex;
      justify-content: center;
      margin-left: 0.5rem;

      svg {
        height: 1.25rem;
        width: 1.25rem;
        color: ${primaryColor};
      }
    }
  }

  button.leaf {
    padding: 0 0.75rem;
    margin: 0.25rem 0;

    p {
      color: ${primaryColor};
    }
  }

  .child-pages {
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;
  }
`;

export default PageBuilderTreeLeaf;
