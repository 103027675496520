// Libs
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

// Components
import PageBuilderTree from "../modal/pageBuilderTree/PageBuilderTree";
import PageContent from "../pageContent/PageContent";

// Utilities
import updatePageInPages from "../../utilities/updatePageInPages";

// Actions
import { showModalPage } from "../../../../actions/uiActions";
import { pagesChangeHandler } from "../../../../actions/pageBuilderActions";

const PageListItemContent = (props) => {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.pageBuilder.pages);
  const lang = useSelector((state) => state.language.language);
  const [localPage, setLocalPage] = useState(props.page);

  useEffect(() => {
    setLocalPage(props.page);
  }, [props.page]);

  function onChangeHandler(e) {
    try {
      const name = e.target.name;
      const value = e.target.value;

      let pageCopy = { ...props.page };
      if (!pageCopy.flags.modified) pageCopy.flags.modified = true;

      pageCopy[name] = value;
      setLocalPage(pageCopy);

      debounceSavePageChanges({ page: pageCopy, pages });
    } catch (error) {
      console.log(error);
    }
  }

  const [debounceSavePageChanges] = useDebouncedCallback(({ page, pages }) => {
    let newPages = updatePageInPages({ newPage: page, pages });
    dispatch(pagesChangeHandler({ pages: newPages }));
  }, 500);

  function movePage() {
    dispatch(
      showModalPage({
        title: `${lang.moving} '${props.page.title}'`,
        content: <PageBuilderTree pageToBeMoved={props.page} />,
        useScrollView: true,
      })
    );
  }

  return <PageContent page={localPage} onChangeHandler={onChangeHandler} movePage={movePage} />;
};

export default PageListItemContent;
