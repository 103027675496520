// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { showContextMenu, showModalPage } from "../../actions/uiActions";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ListItem from "../ui/ListItem";
import Spinner from "../ui/InlineSpinner";
import AcademyPreview from "../academy/AcademyPreview";
import CertificateResults from "./CertificateResults";

// Icons
import { InfoOutlineIcon } from "mdi-react";
import { CertificateIcon, TimetableIcon, CheckCircleIcon } from "mdi-react";

// Config
import breakpoints from "../../config/breakpoints";
import { getCertificateOverview } from "../../actions/academyActions";
import { academyTypes } from "../../config/academyTypes";
import StatusBox from "../ui/StatusBox";
import { parseDate } from "../../utilities/parse-date";

// Styles and UI
import ContextMenuButton from "../ui/ContextMenuButton";
import colors from "../../style/colors";

const CertificateOverview = (props) => {
  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // Destructure the props
  const { match, showModalPage } = props;

  // Page for navigation
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  // UI Layout
  const tabs = [lang.available, lang.myCertificates];
  const [activeTab, setActiveTab] = useState(0);

  // Certificate  redux state
  const { newCertificates, completedCertificates, loading } = useSelector((state) => state.academy.certificate);

  useEffect(() => {
    props.getCertificateOverview();
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />
      {loading && <Spinner style={{ marginTop: "3rem" }} />}
      {!loading && (
        <TabView
          activeTabIndex={activeTab}
          tabs={[
            <div style={{ marginTop: "1rem" }}>
              {newCertificates.length > 0 &&
                newCertificates.map((c, index) => (
                  <ListItem
                    clickable={true}
                    dataTestId={`quiz-row-${index}`}
                    key={index}
                    maxWidth={breakpoints.md}
                    title={c.title}
                    onClick={() => {
                      showModalPage({
                        title: lang.certificate,
                        content: <AcademyPreview quiz={c} type={academyTypes.certificate} />,
                      });
                    }}
                  />
                ))}
              {newCertificates.length === 0 && (
                <StatusBox
                  className={"completed-certificate-container"}
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.thereIsNoCertificatesNot}
                  content={" "}
                />
              )}
            </div>,
            <div style={{ marginTop: "1rem" }}>
              {completedCertificates.length > 0 &&
                completedCertificates.map((c, index) => (
                  <ListItem
                    key={index}
                    maxWidth={breakpoints.md}
                    title={c.title}
                    subTitle={
                      <div className={componentStyle()}>
                        <div className="certificate-sub-container">
                          <CertificateIcon size={17} />
                          <p className="title">{`${lang.certified} ${parseDate(c.startDate)}`}</p>
                        </div>
                        {c.endDate && (
                          <div className="certificate-sub-container">
                            <TimetableIcon size={17} />
                            <p className="title">{`${lang.expires} ${parseDate(c.endDate)}`}</p>
                          </div>
                        )}
                      </div>
                    }
                    iconRight={
                      <ContextMenuButton
                        dataTestId={"show-certificate-options"}
                        onClick={() => {
                          props.showContextMenu([
                            {
                              "data-test-id": "view-answers",
                              icon: <CheckCircleIcon />,
                              title: lang.viewAnswers,
                              callback: () => {
                                props.showModalPage({
                                  title: lang.quizAnswers,
                                  content: <CertificateResults quizId={c.id} />,
                                  useScrollView: false,
                                  pageStyle: { backgroundColor: colors.white },
                                });
                              },
                            },
                          ]);
                        }}
                      />
                    }
                  />
                ))}
              {completedCertificates.length === 0 && (
                <StatusBox
                  className={"completed-certificate-container"}
                  style={{ marginBottom: "2rem" }}
                  icon={<InfoOutlineIcon />}
                  title={lang.youHaveNotCompletedAnyCertifications}
                  content={" "}
                />
              )}
            </div>,
          ]}
        />
      )}
    </Page>
  );
};

const componentStyle = () => css`
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;

  .certificate-sub-container {
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
    align-items: center;
  }

  .title {
    margin-left: 0.3rem;
  }

  .completed-certificate-container {
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
    align-items: center;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getCertificateOverview: bindActionCreators(getCertificateOverview, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
});

export default connect(null, mapDispatchToProps)(CertificateOverview);
