import React from "react";
import { AccountCircleIcon } from "mdi-react";
import colors from "../style/colors";

export default (userObject = {}, size = 36, styles = {}, callback) => {
  // Missing profile picture
  if (!userObject || !userObject.profilePicture || !userObject.profilePicture.image) {
    return (
      <svg
        style={{
          width: size + "px",
          height: size + "px",
          marginRight: "8px",
          fill: colors.darkGrey,
          ...styles,
          marginBottom: "-6px",
        }}
        width="24"
        height="24"
        fill="currentColor"
        viewBox="2 2 20 20"
      >
        <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5C13.66,5 15,6.34 15,8C15,9.66 13.66,11 12,11C10.34,11 9,9.66 9,8C9,6.34 10.34,5 12,5M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.47 17.5,2 12,2Z"></path>
      </svg>
    );
  }

  let imageUrl;
  if (userObject.profilePicture && userObject.profilePicture.baseURL && userObject.profilePicture.image) {
    imageUrl = `${userObject.profilePicture.baseURL}dpr_3,w_${size},h_${size},c_lfill,g_face/${userObject.profilePicture.image}`;
  } else if (userObject.profilePicture) {
    imageUrl = userObject.profilePicture.image;
  }

  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: "50%",
        backgroundImage: `url('${imageUrl}')`,
        backgroundSize: "cover",
        display: "inline-block",
        verticalAlign: "middle",
        marginBottom: "2px",
        border: `1px var(--lightGrey) solid`,
        flexShrink: 0,
        ...styles,
      }}
      onClick={() => {
        if (callback) callback();
      }}
    />
  );
};
