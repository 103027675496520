import React, { useCallback, useState } from "react";
import req from "../../../../../utilities/request-utility";
import { addToast, hideModalPage } from "../../../../../actions/uiActions";
import { CheckIcon, ErrorIcon } from "mdi-react";
import { useDispatch, useSelector } from "react-redux";
import useRegistrationOptions from "../../../../../hooks/useRegistrationOptions";

const UseSubmitRegistrationSettings = ({ registrationId, showAsModal = false }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.language);

  const [loading, setLoading] = useState(false);

  const { mutate: mutateRegistrationOptions } = useRegistrationOptions({ groupBy: "active" });

  const updateChanges = useCallback(async (values) => {
    try {
      setLoading(true);

      await req().put(`admin/registrations/${registrationId}/settings`, values);

      dispatch(
        addToast({
          title: "Settings were successfully updated",
          content: "",
          icon: <CheckIcon />,
          styleType: "success",
          duration: 5000,
        })
      );

      if (showAsModal) dispatch(hideModalPage());
      mutateRegistrationOptions();
    } catch (error) {
      dispatch(
        addToast({
          title: lang.errorGeneral,
          content: error,
          icon: <ErrorIcon />,
          styleType: "error",
          duration: 5000,
        })
      );
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    updateChanges,
    loading,
  };
};

export default UseSubmitRegistrationSettings;
