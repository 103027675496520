import pushNotificationsChecks from "./push-notifications-checks";

export default function isPushNotificationsSupported() {
  let allChecksPassed = true;

  try {
    pushNotificationsChecks.forEach((check) => {
      let checkIsOK = check.performCheck();

      if (!checkIsOK) allChecksPassed = false;
    });
  } catch (err) {
    console.log(err);
    allChecksPassed = false;
  }

  console.log("⚪️ Checking for pushnotification support");
  if (allChecksPassed) {
    console.log("✅ pushnotification is supported!");
    return true;
  } else {
    console.log("🚫 pushnotification is not supported!");
    return false;
  }
}
