import { addToast } from "../../../actions/uiActions";
import store from "../../../store";
import getFormattedHour from "../../../utilities/get-formatted-hour";
import requestUtility from "../../../utilities/request-utility";

/**
 *
 * @param {Object} anon
 * @param {Object} anon.page - pageObject of the current page
 */
async function getApprovedWithChanges({ page }) {
  try {
    const selectedUser = store.getState().timeRegistration.selectedUser;

    let URL = `time-registrations/${page.dataId}/time-sheets/approved-with-changes`;

    if (selectedUser) URL += `?selectedUserId=${selectedUser?.id}`;

    let { data: registrations } = await requestUtility()(URL);

    registrations = registrations.map((registration) => ({
      ...registration,
      formattedHour: getFormattedHour(registration.hours),
    }));

    return registrations;
  } catch (err) {
    store.dispatch(addToast({ template: "error" }));
    throw new Error("getApprovedWithChanges failed");
  }
}

export default getApprovedWithChanges;
