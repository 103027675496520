function getEventThumbnail(event) {
  if (event.images.length !== 0) {
    return `${event.images[0].baseURL}/${event.images[0].image}`;
  }

  if (event.video) {
    // We can potentially get a thumbnail from Cloudinary, if the video is hosted there
    let url = `${event.video.baseURL}${event.video.video}`;

    if (url.includes("cloudinary") && url.includes("mp4")) {
      return `${url.substring(0, url.indexOf(".mp4"))}.jpg`;
    }

    return null;
  }

  return null;
}

export default getEventThumbnail;
