// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

// Components
import Button from "../../ui/Button";
import InlineSpinner from "../../ui/InlineSpinner";
import Image from "../../ui/Image";
import {
  InfoOutlineIcon,
  CheckDecagramIcon,
  InformationOutlineIcon,
  PencilIcon,
  AlertDecagramIcon,
  TrashCanOutlineIcon,
} from "mdi-react";
import EquipmentBookingStartBookingModal from "../EquipmentBookingStartBookingModal";
import EquipmentBookingEndBookingModal from "../EquipmentBookingEndBookingModal";
import ButtonRounded from "../../ui/ButtonRounded";
import ContextMenuButton from "../../ui/ContextMenuButton";
import FileViewer from "../../ui/FileViewer";

// Utilities
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import { parseDatetime } from "../../../utilities/parse-date";
import markdownParser from "../../../utilities/markdown-parser";
import req from "../../../utilities/request-utility";

// Hooks
import useCurrentPage from "../../../hooks/useCurrentPage";

// Config
import pageConfig from "../../../config/pageConfig";
import styleTypes from "../../../config/styleTypes";
import breakpoints from "../../../config/breakpoints";
import { addToast, hideModalPage, showContextMenu, showDialog, showModalPage } from "../../../actions/uiActions";

// Actions
import { getEquipment, getMyBookingsCount, setEquipmentBookingId } from "../../../actions/equipmentBookingActions";

// Styles
import colors from "../../../style/colors";

function EquipmentBookingSingleDetailsTab({ match }) {
  const dispatch = useDispatch();
  const [equipment, setEquipment] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { language: lang } = useSelector((state) => state.language);

  const page = useCurrentPage({ pageId: match.params.pageId });
  const user = useSelector((s) => s.auth.user);

  const hideEndDate = page.config && page.config[pageConfig.HIDE_END_DATE] ? page.config[pageConfig.HIDE_END_DATE] : false;

  function getEquipmentData() {
    req()(`equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}`)
      .then(({ data }) => {
        setEquipment(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(false);
      });
  }

  useEffect(() => {
    getEquipmentData();
    dispatch(setEquipmentBookingId(page.dataId));
  }, []);

  function handleFinishedBooking() {
    setLoading(true);
    dispatch(hideModalPage());
    getEquipmentData();
    dispatch(getMyBookingsCount());
    dispatch(
      addToast({
        title: lang.success,
        content: lang.equipmentBooked,
        styleType: styleTypes.ok,
        icon: <CheckDecagramIcon />,
      })
    );
    dispatch(getEquipment(true));
  }

  function handleFinishedUnbooking() {
    setLoading(true);
    getEquipmentData();
    dispatch(getMyBookingsCount());
    dispatch(hideModalPage());
    dispatch(
      addToast({
        title: lang.success,
        content: lang.bookingCompleted,
        styleType: styleTypes.ok,
        icon: <CheckDecagramIcon />,
      })
    );
    dispatch(getEquipment(true));
  }

  function bookEquipment() {
    dispatch(
      showModalPage({
        content: (
          <EquipmentBookingStartBookingModal
            equipmentBookingId={page.dataId}
            config={page.config}
            equipmentId={match.params.equipmentId}
            onFinishedBooking={handleFinishedBooking}
          />
        ),
        pageStyle: { backgroundColor: colors.white },
      })
    );
  }

  function unbookEquipment() {
    dispatch(
      showModalPage({
        content: (
          <EquipmentBookingEndBookingModal
            equipmentBookingId={page.dataId}
            equipmentId={match.params.equipmentId}
            bookingId={equipment.activeBooking.id}
            onFinishedUnbooking={handleFinishedUnbooking}
          />
        ),
        pageStyle: { backgroundColor: colors.white },
      })
    );
  }

  function deleteBooking(bookingId) {
    req()
      .delete(`equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}/bookings/${bookingId}/remove`)
      .then(() => {
        getEquipmentData();
      })
      .catch(() => {
        dispatch(addToast({ template: "error" }));
      });
  }

  function handleContextMenuClick(booking) {
    dispatch(
      showContextMenu({
        actions: [
          {
            icon: <PencilIcon />,
            title: "Rediger",
            callback: () => {
              dispatch(
                showModalPage({
                  pageStyle: {
                    backgroundColor: colors.white,
                  },
                  content: (
                    <EquipmentBookingStartBookingModal
                      equipmentBookingId={booking.id}
                      equipmentId={match.params.equipmentId}
                      onFinishedBooking={() => {
                        getEquipmentData();
                        dispatch(hideModalPage());
                      }}
                      config={page.config}
                      editData={booking}
                    />
                  ),
                })
              );
            },
          },
          {
            icon: <TrashCanOutlineIcon />,
            title: "Slet",
            callback: () => {
              dispatch(
                showDialog({
                  title: "Vil du fjerne din bookning?",
                  content:
                    "Er du sikker på at du vil fjerne din bookning - så snart den er fjernet kan andre booke udstyret i stedet for dig.",
                  primaryActionTitle: "Ja, fjern min bookning",
                  primaryAction: () => deleteBooking(booking.id),
                  styleType: styleTypes.error,
                  icon: <AlertDecagramIcon />,
                })
              );
            },
          },
        ],
      })
    );
  }

  const isAvailable = equipment.activeBooking && equipment.activeBooking.bookingStartDate ? false : true;
  const image = equipment.images && equipment.images.length > 0 ? equipment.images[0] : null;
  return (
    <>
      {loading && !error && <InlineSpinner style={{ marginTop: "2rem" }} />}

      {!loading && !error && (
        <div className={componentStyles()}>
          {image && (
            <div className="main-image">
              <Image src={`${image.baseURL}${image.image}`} />{" "}
            </div>
          )}

          <h2 style={{ fontSize: "1.65rem" }}>{equipment.title}</h2>
          <p className="meta" style={{ marginBottom: "0.5rem" }}>
            {equipment.category.title}
          </p>
          {isAvailable ? <p className="badge available">LEDIG</p> : <p className="badge booked">UDLÅNT</p>}

          {/* Files */}
          {!equipment.files || equipment.files.length === 0 ? null : (
            <div className="attachments-container">
              {equipment.files.map((file) => (
                <FileViewer
                  key={`file-viewer-compontent${file.fileSrc}`}
                  href={`${file.baseURL || ""}${file.fileSrc}`}
                  title={file.title}
                />
              ))}
            </div>
          )}

          {/* If its available show a book button */}
          {(hideEndDate === false || isAvailable) && <Button onClick={bookEquipment}>Book</Button>}

          {/* If its booked by current user show a "unbook" button */}
          {/* !isAvailable && equipment.activeBooking.user.id === user.id && (
            <Button onClick={unbookEquipment}>Afslut bookning</Button>
          ) */}

          {/* If its not available show booking details */}
          {!isAvailable && (
            <>
              <p className="meta" style={{ marginBottom: "0.35rem" }}>
                <InfoOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-3px" }} /> Booket af:
              </p>
              <div className="booking-details" style={{ marginBottom: "1.5rem" }}>
                <div className="upper-section">
                  {getProfilePictureFromUserObject(equipment.activeBooking.user, 56)}
                  <div className="user-details">
                    <p className="name">{equipment.activeBooking.user.name}</p>
                    <p className="meta">Booket: {parseDatetime(equipment.activeBooking.bookingStartDate)}</p>
                    {hideEndDate === false && (
                      <p className="meta">Ledig: {parseDatetime(equipment.activeBooking.bookingEndDate)}</p>
                    )}
                  </div>
                  <div className="actions">
                    {equipment.activeBooking.user.id === user.id && (
                      <ContextMenuButton onClick={() => handleContextMenuClick(equipment.activeBooking)} />
                    )}
                  </div>
                </div>
                <p className="comment-label">Kommentar ved bookning:</p>
                {equipment.activeBooking.bookingStartComment ? (
                  <p className="comment-content">{equipment.activeBooking.bookingStartComment}</p>
                ) : (
                  <p className="comment-content meta" style={{ opacity: 0.8 }}>
                    <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen
                    kommentar
                  </p>
                )}

                {equipment.activeBooking.user.id === user.id && (
                  <ButtonRounded style={{ width: "100%", margin: "1rem 0 0 0" }} secondary={true} onClick={unbookEquipment}>
                    Afslut bookning
                  </ButtonRounded>
                )}
              </div>
            </>
          )}

          <div className="content" dangerouslySetInnerHTML={markdownParser(equipment.content)}></div>
        </div>
      )}
    </>
  );
}

const boxPadding = 0.85;

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 1rem auto 5rem auto;
  background-color: var(--white);
  padding: ${boxPadding}rem;
  border-width: 1px 0;
  border-color: var(--midGrey);
  border-style: solid;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 1rem;
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
  }

  .main-image {
    margin: -${boxPadding}rem -${boxPadding}rem ${boxPadding}rem -${boxPadding}rem;
    border-bottom: 1px var(--midGrey) solid;

    img {
      width: 100%;
      display: block;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  p.meta {
    color: var(--darkGrey);
  }

  div.content {
    white-space: normal;
  }

  .badge {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    font-size: 0.85rem;
    margin-bottom: 1rem;

    &.available {
      background-color: var(--green);
    }
    &.booked {
      background-color: var(--red);
    }
  }

  button {
    margin-bottom: 1.5rem;
  }

  .booking-details {
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    .upper-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.85rem;

      .user-details {
        flex-grow: 1;
        padding-left: 0.5rem;
      }
    }

    .comment-label {
      margin-bottom: 0.15rem;
    }

    .comment-content {
      font-size: 0.95rem;
      color: var(--darkGrey);
      white-space: pre-wrap;
    }
  }

  /* Documents amd links */
  .attachments-container {
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    margin-bottom: 1rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: var(--darkGrey);
      border-bottom: 1px var(--midGrey) solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0px;
      }

      &:active {
        background-color: var(--lightGrey);
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: var(--darkGrey);
      }
    }
  }

  .seperator {
    border-bottom: 1px var(--midGrey) solid;
    margin: ${boxPadding}rem -${boxPadding}rem;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 1rem;
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
  }
`;

export default EquipmentBookingSingleDetailsTab;
