import { useSelector } from "react-redux";

export default function ({ enableReadAloud, languageCode }) {
  if (!enableReadAloud) return false;
  
  const languages = useSelector((state) => state.language.translationsLanguages.languages);

  // This is a fallback.
  // Some apps only have readAloud and not translation, so their translationLanguages array is empty
  //
  // We can assume the users current language is supported for readAloud,
  // since it rarely makes sense to enable readAloud if you're not using translation
  // and the primary/secondary languages don't support readAloud
  if (!languages || languages.length === 0) return true;

  const language = languages.find((language) => language.identifier === languageCode);

  if (language && language.audioSupported === true) return true;

  return false;
}
