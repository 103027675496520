// Libs
import React from "react";

// Styles
import { AlertIcon, TickCircleIcon, HourglassEmptyIcon } from "mdi-react";
import colors from "../../../style/colors";
import approvalStatuses from "../config/approvalStatuses";

export default (approvalStatus = "") => {
  if (approvalStatus === approvalStatuses.APPROVED) return "Godkendt";
  if (approvalStatus === approvalStatuses.REJECTED) return "Afvist";
  if (approvalStatus === approvalStatuses.PENDING) return "Afventer godkendelse";
  return null; // Fallback / no icon
};
