// Libs
import React from "react";
import { css } from "emotion";

// Redux
import { connect, useSelector } from "react-redux";

// Config
import breakpoints from "../../config/breakpoints";

// Style
import colors from "../../style/colors";
import Certificate from "./Certificate";
import Button from "../ui/Button";
import { bindActionCreators } from "redux";
import { showModalPage } from "../../actions/uiActions";

const CertificateNotPassedResults = (props) => {
  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // Destructure the object
  const { correctAnswers, totalQuestions, quizPassScore, certificateId } = props.results;

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <div className="container">
        <p className="title">{lang.unfortunately}</p>
        <p style={{ marginBottom: "0.5rem" }}>
          {lang.youGot__placeholderCorrectAnswers____placeholderCorrect__outOf__placeholderTotalQuestions__correctAndHaveNotReceivedCertificate
            .replace(/{{placeholderCorrectAnswers}}/gi, correctAnswers)
            .replace(/{{placeholderTotalQuestions}}/gi, totalQuestions)
            .replace(
              /{{placeholderCorrect}}/gi,
              correctAnswers === 1 ? lang.correct.toLowerCase() : lang.manyCorrect.toLowerCase()
            )}
        </p>

        <p style={{ marginBottom: "2rem" }}>
          {lang.youNeedAMinimumOf__placeholderPassScore__correctToPass.replace(/{{placeholderPassScore}}/gi, quizPassScore)}
        </p>

        <Button
          onClick={() =>
            props.showModalPage({
              title: lang.certificate,
              content: <Certificate certificateId={certificateId} />,
            })
          }
        >
          {lang.tryAgain}
        </Button>
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: var(--white);
  width: 100%;
  height: 100%;

  .container {
    max-width: ${breakpoints.md}px;
    margin: auto;
    overflow: auto;
    padding: 1rem;
  }

  .title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(CertificateNotPassedResults);
