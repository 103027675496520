export default {
  id: null,
  name: "(Slettet bruger)",
  userGroup: {
    id: null,
    title: "Ukendt"
  },
  masterGroup: {
    id: null,
    title: "Ukendt"
  }
};
