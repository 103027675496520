// Libraries
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage } from "../../actions/uiActions";
import { refreshPostComments } from "../../actions/socialActions";
import PropTypes from "prop-types";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";

// Components
import ScrollView from "../ui/ScrollView";
import Spinner from "../ui/InlineSpinner";
import PostCommentForm from "../ui/PostCommentForm";

// Utils
import req from "../../utilities/request-utility";

/** Modal page for editing comments.
 *
 * Adding comments is done inline in the Post component.
 * Is implemented as a component passed to a redux action
 *
 * @example
 * ```jsx
 * showModalPage({
 *   title: `${lang.edit} ${lang.comment.toLowerCase()}`,
 *   content: <SocialCommentModal comment={comment} postId={id} />
 * });
 * ```
 */

function SocialCommentModal(props) {
  const dataId = useSelector((s) => s.social.currentDataId);
  const [savingChanges, setSavingChanges] = useState(false);

  function saveComment(comment) {
    setSavingChanges(true);
    req()
      .put(`social/${dataId}/posts/${props.postId}/comments/${comment.id}`, {
        content: comment.content,
        image: comment.image,
      })
      .then(() => props.refreshPostComments(props.postId, props.hideModalPage))
      .catch(() => {
        setSavingChanges(false);
        props.addToast({ template: "error" });
      });
  }

  return (
    <ScrollView className={componentStyle(props)}>
      {savingChanges && <Spinner />}
      {!savingChanges && (
        <PostCommentForm
          minHeight={"35vh"}
          comment={props.comment}
          boxPadding={boxPadding}
          onComment={(comment) => saveComment(comment)}
          submittingComment={true}
          showForm={true}
          imageInputId={"edit-image-upload"}
        />
      )}
    </ScrollView>
  );
}

const boxPadding = "0.65";

const componentStyle = () => css`
  padding: 1rem;
  background-color: var(--white);
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
});

export default connect(null, mapDispatchToProps)(SocialCommentModal);

SocialCommentModal.propTypes = {
  /** The id of the post to which the comment is being edited */
  postId: PropTypes.number,
  /** The comment being edited (it has more attributes, but only id and comment is needed as the author is implicitly defined through auth) */
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
  }),
};
