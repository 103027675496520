// Libs
import React, { useImperativeHandle, useState } from "react";
import { css } from "emotion";
import { Draggable } from "react-beautiful-dnd";

// Components
import PageListItemHeader from "./PageListItemHeader";
import ActionWrapper from "../../../ui/ActionWrapper";
import PageListItemContent from "./PageListItemContent";

const PageListItem = (props) => {
  const { scrollToRef = undefined } = props;

  const [expanded, setExpanded] = useState(false);

  function expandListItem() {
    setExpanded(!expanded);
  }

  useImperativeHandle(
    scrollToRef,
    () => {
      if (scrollToRef && scrollToRef.current) {
        return {
          expandListItem,
          domElement: scrollToRef.current,
        };
      }
    },
    []
  );

  return (
    <div ref={scrollToRef} className={`${componentStyle()} ${props.className || ""}`}>
      <Draggable draggableId={`list-item-${props.page.id}`} index={props.index}>
        {(provided) => (
          <div className="item-content" ref={provided.innerRef} {...provided.draggableProps}>
            <ActionWrapper onClick={expandListItem} data-test-id={`page-builder__list-item-header__${props.page.title}`}>
              <PageListItemHeader expanded={expanded} dragHandleProps={provided.dragHandleProps} {...props} />
            </ActionWrapper>

            {expanded && <PageListItemContent {...props} />}
          </div>
        )}
      </Draggable>
    </div>
  );
};

const componentStyle = () => css`
  .item-content {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border: 1px solid var(--midGrey);
    margin: -1px auto 0;
    width: 100%;
  }
`;

export default PageListItem;
