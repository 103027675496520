// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTableFilters } from "../../../../../actions/timeRegistrationActions";

const DropDownFilter = ({ column }) => {
  const { setFilter, preFilteredRows, id } = column;

  const dispatch = useDispatch();
  const filterValue = useSelector((state) => state.timeRegistration.table.filters["user.id"]);

  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });

    const optionsArray = [...options.values()].sort((a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return optionsArray;
  }, [id, preFilteredRows]);

  function onChangeHandler(e) {
    setFilter(e.target.value || undefined);
    dispatch(
      updateTableFilters({
        filters: { [`${id}`]: e.target.value },
      })
    );
  }

  useEffect(() => {
    setFilter(filterValue);
  }, [filterValue]);

  // Render a multi-select box
  return (
    <div className="input-container">
      <label>{column.render("Header")}</label>
      <select value={filterValue || ""} onChange={onChangeHandler}>
        <option value="">Vis alle</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDownFilter;
