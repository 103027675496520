// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import { CheckboxMarkedOutlineIcon, InformationOutlineIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import Image from "../ui/Image";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import { format } from "date-fns/esm";
import req from "../../utilities/request-utility";
import iconConverter from "../../utilities/icon-converter";

// Styles
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import InlineSpinner from "../ui/InlineSpinner";

function IDCard(props) {
  const [verifiedData, setVerifiedData] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    setVerifiedData(format(new Date(), "HH:mm:ss dd-MM-yyyy"));

    req()(`id-card/`).then(({ data }) => setContent(data));
  }, []);

  const appId = useSelector((s) => s.appConfig.appId);
  let showEmployeeId = [35, 36, 37, 38, 39, 41, 42].includes(appId) ? false : true;

  const pages = useSelector((state) => state.pages.pages);
  const lang = useSelector((state) => state.language.language);
  const user = useSelector((state) => state.auth.user);
  const appConfig = useSelector((state) => state.appConfig);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  return (
    <Page className={componentStyles()} backgroundColor={colors.white}>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView>
        <div className="content">
          {getProfilePictureFromUserObject(user, 196, { margin: "0 auto 1rem auto", display: "block" })}
          <h2>{user.name}</h2>
          <Image className="company-logo" src={`${appConfig.customerLogo.baseURL}${appConfig.customerLogo.image}`} />
          {showEmployeeId && user.externalId && (
            <>
              <h3>Medarbejder ID</h3>
              <p>{user.externalId}</p>
            </>
          )}
          {content && (
            <>
              {content.map((item) => (
                <React.Fragment key={item.id}>
                  <h3>
                    {item.icon && iconConverter(item.icon)} {item.title}:
                  </h3>
                  {item.type === "USER_EMAIL" && (
                    <p>{user.email ? user.email : "Vi har ikke adgang til at vise din e-mail."}</p>
                  )}
                  {item.type === "USER_PHONE" && (
                    <p>{user.phone ? user.phone : "Vi har ikke adgang til at vise dit telefonnr."}</p>
                  )}
                  {/** Please do not add more fields via this method, instead use the getReplacement function
                   *   in the getIdCardContent service to add more dynamic fields.
                   */}
                  {!item.type && <p>{item.description}</p>}
                </React.Fragment>
              ))}
              <h3 style={{ color: colors.green }}>
                <CheckboxMarkedOutlineIcon color={colors.green} />
                {lang.verified}
              </h3>
              <p>{verifiedData}</p>
            </>
          )}
          {!content && <InlineSpinner />}
        </div>
      </ScrollView>
    </Page>
  );
}

const componentStyles = () => css`
  div.content {
    padding: 1rem;
    max-width: ${breakpoints.xs}px;
    margin: 0 auto;
    text-align: center;

    .company-logo {
      width: 100%;
      max-width: 300px;
      margin: 0 0 2rem 0;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0 0 2rem 0;
    }

    h3 {
      font-size: 1.05rem;
      margin: 0 0 0.25rem 0;

      svg {
        color: var(--darkGrey);
        margin-right: 0.25rem;
        height: 1.25rem;
        height: 1.25rem;
        margin-bottom: -0.15rem;
      }
    }

    p {
      font-size: 1rem;
      margin: 0 0 1.25rem 0;
    }
  }
`;

export default IDCard;
