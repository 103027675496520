/** This took more effor than i would like to admit... See get-formatted-hour.unit-test.js for details.
 * If you change anything in this file make sure to run that file
 * (`npm run test:unit`)
 *
 *
 */

export default (hour) => {
  //formats the hour fom eg 4.5 to "4:30" or 7 to "7:00". It only works with devisions down to a quarter of an hour.

  let [hours, minutes] = hour.toString().split(".");

  // Time was a whole number and had no decimals
  if (!minutes) return `${hours}:00`;

  // Treat minutes as a decimal
  minutes = Number("." + minutes);

  // Get minute value
  minutes = String(Math.round(minutes * 60));

  // Zeropad minutes with fewer than to characters (so instead of i.e 7:7 it will say 7:07)
  if (minutes.length < 2) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
};
