// Libraries
import React from "react";

import { css } from "emotion";

import packageJson from "../../../package.json";
import DebugItem from "./DebugItem";
import ButtonRounded from "../ui/ButtonRounded";
import nativeCommunicator from "../../utilities/native-communicator";

function DebugModal(props) {
  const userAgent = window.navigator.userAgent;
  const displayModeIsStandalone = window.matchMedia("(display-mode: standalone)").matches;
  // Sets isIOS for ipad iphone and ipod
  let isIOS = userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false;
  // Sets isAndroid for android
  let isAndroid = userAgent.match(/Android/) !== null ? true : false;
  // Sets isAndroid for android
  let isSamsungInternet = userAgent.match(/SamsungBrowser/) !== null ? true : false;

  // Sets isNativeApp for EkkoAppNativeWrapper
  let isNativeApp = userAgent.match(/EkkoAppNativeWrapper/) !== null ? true : false;
  // Sets nativeAppVersion for EkkoAppNativeWrapper
  let nativeAppVersion = userAgent.match(/EkkoAppNativeWrapper\/\d+\.\d+\.\d+/);
  // Splits the pathname to get the current page
  const path = window.location.pathname.split("/");

  return (
    <div className={componentStyles()}>
      {/* WebApp version */}
      <DebugItem label="version" result={packageJson.version} />

      {/* User Agent */}
      <DebugItem label="userAgent" code="window.navigator.userAgent" result={userAgent} />

      {/* Display Mode Is Standalone */}
      <DebugItem
        label="displayModeIsStandalone"
        code='window.matchMedia("(display-mode: standalone)").matches'
        result={displayModeIsStandalone.toString()}
      />

      {/* isIOS */}
      <DebugItem
        label="isIOS"
        code="userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false"
        result={isIOS.toString()}
      />

      {/* isAndroid */}
      <DebugItem label="isAndroid" code="userAgent.match(/Android/) !== null ? true : false" result={isAndroid.toString()} />

      {/* isSamsungInternet */}
      <DebugItem
        label="isSamsungInternet"
        code="userAgent.match(/SamsungBrowser/) !== null ? true : false"
        result={isSamsungInternet.toString()}
      />

      {/* isNativeApp */}
      <DebugItem
        label="isNativeApp"
        code="userAgent.match(/EkkoAppNativeWrapper/) !== null ? true : false"
        result={isNativeApp.toString()}
      />

      {isNativeApp && (
        <>
          {/* nativeAppVersion */}
          <DebugItem label="nativeAppVersion" result={nativeAppVersion[0].split("/")[1]} />

          {/* native button*/}
          <ButtonRounded
            className="button"
            onClick={() => {
              nativeCommunicator.send("show-native-dev-tool", { pathname: path[2] + "/" + path[3] });
            }}
          >
            Native dev tools
          </ButtonRounded>
        </>
      )}
    </div>
  );
}

const componentStyles = () => css`
  .button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
`;

export default DebugModal;
