// Libs
import React, { useEffect, useState } from "react";
import * as queryString from "query-string";

// Utilities
import req from "../../../utilities/request-utility";
import getHeadersFromRows from "../utilities/getHeadersFromRows";

const useTableContent = (filters) => {
  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getTableContent();
  }, [filters]);

  async function getTableContent() {
    try {
      setLoading(true);
      const { userGroupId, fromDate, toDate } = filters;
      if (!fromDate || !toDate || !userGroupId) {
        setLoading(false);
        return;
      }

      let URL = `admin/time-registrations/${timeRegistrationId}/users/time-sheets/meta?`;

      URL += `fromDate=${fromDate}`;
      URL += `&toDate=${toDate}`;

      if (userGroupId) URL += `&userGroupId=${userGroupId}`;

      let { data: rows } = await req()(URL);

      const headers = getHeadersFromRows({ rows, fromDate, toDate });

      setHeaders(headers);
      setRows(rows);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return {
    headers,
    rows,
    loading,
    error,
  };
};

export default useTableContent;
