import {
  GET_INITIAL_DATA,
  GET_INITIAL_DATA_SUCCESS,
  GET_INITIAL_DATA_FAILURE,
  HIDE_TARGET_GROUP_PANEL,
  SET_SELECTION_PANEL_ACTIVE_TAB_INDEX,
  SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX,
  SHOW_TARGET_GROUP_PANEL,
  ADD_TO_TARGET_GROUP,
  REMOVE_FROM_TARGET_GROUP,
  ADD_ALL_TO_TARGET_GROUP,
  RESET_TARGET_GROUP,
  REMOVE_ALL_FROM_TARGET_GROUP,
  SET_INITIAL_TARGET_GROUP,
} from "../actions/targetGroupModalctionsTypes";

const initialState = {
  users: {
    data: [],
    loading: true,
    error: false,
  },
  userGroups: {
    data: [],
    loading: true,
    error: false,
  },
  masterGroups: {
    data: [],
    loading: true,
    error: false,
  },
  customGroups: {
    data: [],
    loading: true,
    error: false,
  },
  jobTitles: {
    data: [],
    loading: true,
    error: false,
  },
  targetGroup: {
    users: [],
    userGroups: [],
    masterGroups: [],
    customGroups: [],
    jobTitles: [],
  },
  hasTargetGroupChanged: false,
  isTargetGroupPanelVisible: false,
  selectionPanel: {
    activeTabIndex: 0,
  },
  targetGroupPanel: {
    activeTabIndex: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...action.payload,
        },
      };

    case GET_INITIAL_DATA:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
        userGroups: {
          ...state.userGroups,
          loading: true,
        },
        masterGroups: {
          ...state.masterGroups,
          loading: true,
        },
        customGroups: {
          ...state.customGroups,
          loading: true,
        },
        jobTitles: {
          ...state.jobTitles,
          loading: true,
        },
      };

    case GET_INITIAL_DATA_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: true,
        },
        userGroups: {
          ...state.userGroups,
          loading: false,
          error: true,
        },
        masterGroups: {
          ...state.masterGroups,
          loading: false,
          error: true,
        },
        customGroups: {
          ...state.customGroups,
          loading: false,
          error: true,
        },
        jobTitles: {
          ...state.jobTitles,
          loading: false,
          error: true,
        },
      };

    case GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.users || [],
          loading: false,
          error: false,
        },
        userGroups: {
          ...state.userGroups,
          data: action.payload.userGroups || [],
          loading: false,
          error: false,
        },
        masterGroups: {
          ...state.masterGroups,
          data: action.payload.masterGroups || [],
          loading: false,
          error: false,
        },
        customGroups: {
          ...state.customGroups,
          data: action.payload.customGroups || [],
          loading: false,
          error: false,
        },
        jobTitles: {
          ...state.jobTitles,
          data: action.payload.jobTitles || [],
          loading: false,
          error: false,
        },
      };

    case SHOW_TARGET_GROUP_PANEL:
      return {
        ...state,
        isTargetGroupPanelVisible: true,
      };

    case HIDE_TARGET_GROUP_PANEL:
      return {
        ...state,
        isTargetGroupPanelVisible: false,
      };

    case SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX:
      return {
        ...state,
        targetGroupPanel: {
          ...state.targetGroupPanel,
          activeTabIndex: action.payload,
        },
      };

    case SET_SELECTION_PANEL_ACTIVE_TAB_INDEX:
      return {
        ...state,
        selectionPanel: {
          ...state.selectionPanel,
          activeTabIndex: action.payload,
        },
      };

    case ADD_ALL_TO_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...state.targetGroup,
          [action.payload.type]: [...state.targetGroup[action.payload.type], ...action.payload.ids],
        },
      };

    case ADD_TO_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...state.targetGroup,
          [action.payload.type]: [...state.targetGroup[action.payload.type], action.payload.id],
        },
      };

    case REMOVE_FROM_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...state.targetGroup,
          [action.payload.type]: state.targetGroup[action.payload.type].filter((id) => id !== action.payload.id),
        },
      };

    case REMOVE_ALL_FROM_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...state.targetGroup,
          [action.payload]: [],
        },
      };

    case RESET_TARGET_GROUP:
      return {
        ...state,
        targetGroup: {
          ...state.targetGroup,
          users: [],
          userGroups: [],
          masterGroups: [],
          customGroups: [],
          jobTitles: [],
        },
      };

    default:
      return state;
  }
}
