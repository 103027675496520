// Libs
import React from "react";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import Highscore from "./components/Highscore";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

const CustomHighscore = (props) => {
  const page = useCurrentPage({ pageId: props.match.params.pageId });

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <Highscore highscoreId={page.dataId} />
    </Page>
  );
};

export default CustomHighscore;
