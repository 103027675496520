function findPage({ pageId, pages }) {
  let locatedPage = undefined;

  for (let page of pages) {
    if (page.id === pageId) locatedPage = page;

    if (Array.isArray(page.pages) && page.pages.length > 0) {
      let found = findPage({ pageId, pages: page.pages });
      if (found) locatedPage = found;
    }
  }

  return locatedPage;
}

export default findPage;
