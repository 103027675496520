import React from "react";
import { css } from "emotion";
import { DotsVerticalIcon } from "mdi-react";
import colors from "../../style/colors";
import { durations } from "../../config/animations";

const ContextMenuButton = (props) => (
  <DotsVerticalIcon
    data-test-id={props["data-test-id"]}
    className={`${style(props.size)} ${props.className || ""}`}
    onClick={props.onClick}
    style={props.style}
  />
);

const style = (size = 36) => css`
  width: ${size}px;
  height: ${size}px;
  padding: ${size / 5}px;
  border-radius: 50%;
  color: var(--darkGrey);
  transition: background-color ${durations.normal}ms ease;
  flex-shrink: 0;

  &:active,
  &:hover {
    color: var(--black);
    background-color: var(--lightGrey);
    transition: background-color ${durations.ultraFast}ms ease;
    cursor: pointer;
  }
`;

export default ContextMenuButton;
