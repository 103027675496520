// Libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import dayjs from "dayjs";

// Utilities and config
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import breakpoints from "../../config/breakpoints";
import getUserGroupTitle from "../../utilities/get-user-group-title";

// Styles
import { FilterOutlineIcon, InfoOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

// Components
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import ListItem from "../ui/ListItem";
import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import AppFilterModal from "../ui/AppFilterModal";
import ActionWrapper from "../ui/ActionWrapper";
import Notification from "../ui/Notification";
import StatusBox from "../ui/StatusBox";

// Actions
import {
  getBirthdays,
  resetAll,
  showBirthdayFilters,
  hideBirthdayFilters,
  getRelatedBirthdayApps,
  setBirthdayFilterValue,
} from "../../actions/birthdayActions";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

const Birthdays = (props) => {
  const dispatch = useDispatch();

  // Birthdays state from redux
  const {
    loading,
    error,
    birthdays: stateBirthdays,
    newBirthdays: newStateBirthdays,
    endOfFeed,
    isNoBirthdays,
    filters,
    relatedApps,
  } = useSelector((state) => state.birthday);

  // Language state from redux
  const { language: lang } = useSelector((state) => state.language);
  const appConfig = useSelector((state) => state.appConfig);

  // Used for showing the user modal
  const authorModal = useAuthorModal();

  // ComponentDidMount - initial load of component
  useEffect(() => {
    dispatch(resetAll());
    dispatch(getRelatedBirthdayApps());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /* When the component mounts we dispatch an action, which fetch potential related app ids and set the id of the currently selected app. 
    If the app is not related to any apps the selectedAppId is still set to the id of the app.
    This hook is run both when the app mounts and when the selectedAppId changes. 
    To avoid the reset and get birthday functions to run several times when the pages loads, we want to wait until the selectedAppId is set before running these functions */
    if (filters.selectedAppId === null) return;
    dispatch(resetAll());
    dispatch(getBirthdays());

    // eslint-disable-next-line
  }, [filters.selectedAppId]);

  // Call getBirthdays if the height of device show empty space from last birthday to bottom clientHeight (screen height)
  useEffect(() => {
    /* When the component mounts we dispatch an action, which fetch potential related app ids and set the id of the currently selected app.
    When selectedAppIs is set, the hook above dispatches the "getBirthday" action.
    Only after the selectedAppId is set, do we want this function to run to avoid getBirthdays to be dispatch simultaniosly from different places. */
    if (filters.selectedAppId === null) return;
    let currentFeed = document.querySelector(".scroll-view");
    if (currentFeed.scrollHeight <= currentFeed.clientHeight) dispatch(getBirthdays());

    // eslint-disable-next-line
  }, [stateBirthdays, newStateBirthdays]);

  const getTitle = (birthday) => {
    const showAge = appConfig.birthdaysAgeSetting || null;

    let title = birthday.name;

    if (showAge === "all" || (showAge === "round" && birthday.age % 10 === 0)) {
      title += ` - ${birthday.age} ${lang.years}`;
      if (birthday.age % 10 === 0) {
        title += ` 🎉`;
      }
    }

    if (parseInt(birthday.birthday.substr(4, 7)) === parseInt(dayjs().format("MMDD"))) title += ` 🎂`;

    return title;
  };

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={lang.birthdays}
        actionRight={
          relatedApps.length > 0 && (
            <ActionWrapper style={{ position: "relative" }} onClick={() => dispatch(showBirthdayFilters())}>
              <FilterOutlineIcon />
              {Number(filters.selectedAppId) !== Number(appConfig.appId) && (
                <Notification notifications={1} style={{ position: "absolute", top: "2px", right: "2px" }} />
              )}
            </ActionWrapper>
          )
        }
      />
      <ScrollView onScrollEnd={() => dispatch(getBirthdays())}>
        <div className={componentStyle()}>
          {/* There is no birthdays */}
          {isNoBirthdays && !loading && stateBirthdays.length === 0 && newStateBirthdays.length === 0 && (
            <StatusBox
              style={{ marginTop: "1rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.thereIsNoBirthdaysHere}
              content={lang.comeBackSoon}
            />
          )}

          {stateBirthdays.concat(newStateBirthdays).map(({ birthdaysContainer, title }, index) => (
            <div className="birthday-container" key={`old-birthday-list-item-${index}-${title}`}>
              <p className="date-title">{title}</p>
              {birthdaysContainer.map((birthday, index) => (
                <ListItem
                  key={`birthday-user-${birthday.id}-${index}`}
                  maxWidth={breakpoints.md}
                  clickable={true}
                  onClick={() => authorModal(birthday.id)}
                  title={getTitle(birthday)}
                  subTitle={getUserGroupTitle(birthday)}
                  imageLeft={getProfilePictureFromUserObject(birthday, undefined, { marginRight: "0.6rem" })}
                />
              ))}
            </div>
          ))}
        </div>

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.birthdays.toLowerCase()}...`} />
        )}
      </ScrollView>
      <AppFilterModal
        active={filters.active}
        hideFilters={() => dispatch(hideBirthdayFilters())}
        relatedApps={relatedApps}
        onChange={(e) => dispatch(setBirthdayFilterValue({ key: e.target.name, value: e.target.value }))}
        values={filters}
        clearFilters={() => dispatch(setBirthdayFilterValue({ key: "selectedAppId", value: appConfig.appId }))}
      />
    </Page>
  );
};

const componentStyle = () => css`
  max-width: ${breakpoints.md}px;
  margin: auto;
  padding-top: 1rem;

  .birthday-container {
    margin-bottom: 1.25rem;
  }

  .inner-container {
    width: 100%;
  }

  .date-title {
    padding: 0.75rem;
  }

  .name {
    margin: 0 0 0.5rem 0;
  }

  .meta {
    color: var(--darkGrey);
  }
`;

export default Birthdays;
