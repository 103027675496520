import store from "../../store";
import req from "../request-utility";
import { addToast } from "../../actions/uiActions";

/**
 * Honestly this function is taken straight out of notification setup guides. I've seen this
 * function used both by google and mdn. It is included directly here so we can guarantee that it
 * doesn't change in the future.
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vavid key
 */
function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 *
 * @param {object} anon
 * @param {boolean} anon.showToasts - shows toasts if subsription is either saved succesfully or if it fails. Defaults to true
 *
 */
async function subscribeUserToPush({ showToasts = true } = {}) {
  const lang = store.getState().language.language;
  let subscription;
  let serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();

  if (!serviceWorkerRegistration) {
    console.error("[ERROR] Can't subscribe user to push notifications as no service worker is registered");
    return;
  }

  // Get existing registration
  let potentialRegistration = await serviceWorkerRegistration.pushManager.getSubscription();

  if (!potentialRegistration) {
    // get a brand new subscription as there is none
    subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        "BFOOjhisUl5Fsvc-H-NaeJlqnpkyAgyijZpoqaGa6yehBE7mAahCPPifXMQUuH6E2fQoVvrPkChiAnLCri3lGwo"
      ),
    });
  } else {
    // Proceed with the existing subscription
    subscription = potentialRegistration;
  }

  // Save push-subscription to database
  await req()
    .post(`users/push-subscription`, { subscription })
    .then(() => {
      if (showToasts === true) {
        store.dispatch(
          addToast({
            styleType: styleTypes.ok,
            icon: <ThumbsUpOutlineIcon />,
            title: lang.success + "!",
            content: lang.youAreNowReadyToReceiveNotifications,
          })
        );
      }
    })
    .catch(() => {
      if (showToasts === true) {
        store.dispatch(
          addToast({
            styleType: styleTypes.error,
            icon: <SmileySadIcon />,
            title: lang.toastPushNotificationPermissionFailedTitle,
            content: lang.toastPushNotificationPermissionFailedContent,
          })
        );
      }
    });

  return subscription;
}

export default subscribeUserToPush;
