// Action types
import {
  END_OF_ANNIVERSARIES_FEED,
  GET_ANNIVERSARIES,
  GET_ANNIVERSARIES_FAILURE,
  GET_ANNIVERSARIES_SUCCESS,
  RESET_ANNIVERSARY,
} from "../actions/actionTypes";

const initialState = {
  anniversaries: [],
  loading: false,
  error: false,
  endOfFeed: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ANNIVERSARIES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ANNIVERSARIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        anniversaries: [...action.payload.anniversaries],
      };

    case END_OF_ANNIVERSARIES_FEED:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: true,
      };

    case GET_ANNIVERSARIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case RESET_ANNIVERSARY:
      return {
        ...state,
        anniversaries: [],
        loading: false,
        error: false,
        endOfFeed: false,
      };

    default:
      return state;
  }
}
