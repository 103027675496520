import React from "react";
import Icon from "@mdi/react";
import DynamicIcon from "../components/ui/Icons/DynamicIcon";

import {
  WrenchIcon,
  EthernetCableIcon,
  BookOpenVariantIcon,
  ClipboardCheckOutlineIcon,
  ClipboardTextOutlineIcon,
  PowerPlugIcon,
  TableSearchIcon,
  CalendarClockIcon,
  CalendarRemoveIcon,
  PlaylistCheckIcon,
  CalendarQuestionIcon,
  MedalIcon,
  FlaskOutlineIcon,
  DumbbellIcon,
  HammerIcon,
  HelpCircleIcon,
  LightbulbOnOutlineIcon,
  EggEasterIcon,
  TruckTrailerIcon,
  LaptopIcon,
  MonitorIcon,
  SitemapIcon,
  WeatherSunnyIcon,
  HumanHandsupIcon,
  PlusIcon,
  AutoFixIcon,
  ViewModuleIcon,
  InformationOutlineIcon,
} from "mdi-react";
import {
  mdiAccountMultiple,
  mdiCalendarMonth,
  mdiClipboardAccount,
  mdiClipboardAccountOutline,
  mdiEmoticonOutline,
  mdiFolderMultiplePlusOutline,
  mdiHammerScrewdriver,
  mdiHumanGreeting,
  mdiNewspaperVariantOutline,
  mdiWhistleOutline,
  mdiCashMultiple,
  mdiIsland,
  mdiAccountHardHat,
  mdiAccountVoice,
  mdiHardHat,
  mdiAccountGroup,
  mdiHalloween,
  mdiAccountInjuryOutline,
  mdiCrane,
  mdiFlaskOutline,
  mdiHeart,
  mdiHumanQueue,
  mdiMagnify,
  mdiBookshelf,
  mdiBookAccountOutline,
  mdiWeatherSunny,
  mdiBeach,
  mdiWeatherLightningRainy,
  mdiEmoticonSickOutline,
  mdiSchool,
  mdiWhistle,
  mdiBookOpenPageVariantOutline,
  mdiDumpTruck,
  mdiSnowman,
  mdiPlayCircle,
  mdiLeadPencil,
  mdiWaves,
  mdiPig,
  mdiAccountSwitch,
  mdiDesktopClassic,
  mdiFlag,
  mdiHome,
  mdiCamera,
  mdiSafetyGoggles,
  mdiTruckPlus,
  mdiPlaylistEdit,
  mdiAlert,
} from "@mdi/js";

export default function (mdiString = "", styles = null) {
  if (!mdiString) return <HelpCircleIcon style={styles} className="base-icon" />;

  mdiString = mdiString.trim().toLowerCase();

  switch (mdiString) {
    case "safety-goggles":
      return <Icon path={mdiSafetyGoggles} className="base-icon" style={styles} />;
    case "home":
      return <Icon path={mdiHome} className="base-icon" style={styles} />;
    case "camera":
      return <Icon path={mdiCamera} className="base-icon" style={styles} />;
    case "flag":
      return <Icon path={mdiFlag} className="base-icon" style={styles} />;
    case "account-injury-outline":
      return <Icon path={mdiAccountInjuryOutline} className="base-icon" style={styles} />;
    case "crane":
      return <Icon path={mdiCrane} className="base-icon" style={styles} />;
    case "flask-outline":
      return <Icon path={mdiFlaskOutline} className="base-icon" style={styles} />;
    case "heart":
      return <Icon path={mdiHeart} className="base-icon" style={styles} />;
    case "snowman":
      return <Icon path={mdiSnowman} className="base-icon" style={styles} />;
    case "human-queue":
      return <Icon path={mdiHumanQueue} className="base-icon" style={styles} />;
    case "magnify":
      return <Icon path={mdiMagnify} className="base-icon" style={styles} />;
    case "play-circle":
      return <Icon path={mdiPlayCircle} className="base-icon" style={styles} />;
    case "lead-pencil":
      return <Icon path={mdiLeadPencil} className="base-icon" style={styles} />;
    case "bookshelf":
      return <Icon path={mdiBookshelf} className="base-icon" style={styles} />;
    case "book-account-outline":
      return <Icon path={mdiBookAccountOutline} className="base-icon" style={styles} />;
    case "weather-sunny":
      return <Icon path={mdiWeatherSunny} className="base-icon" style={styles} />;
    case "beach":
      return <Icon path={mdiBeach} className="base-icon" style={styles} />;
    case "weather-lightning-rainy":
      return <Icon path={mdiWeatherLightningRainy} className="base-icon" style={styles} />;
    case "emoticon-sick-outline":
      return <Icon path={mdiEmoticonSickOutline} className="base-icon" style={styles} />;
    case "school":
      return <Icon path={mdiSchool} className="base-icon" style={styles} />;
    case "whistle":
      return <Icon path={mdiWhistle} className="base-icon" style={styles} />;
    case "dump-truck":
      return <Icon path={mdiDumpTruck} className="base-icon" style={styles} />;
    case "book-open-page-variant-outline":
      return <Icon path={mdiBookOpenPageVariantOutline} className="base-icon" style={styles} />;
    case "halloween":
      return <Icon path={mdiHalloween} className="base-icon" style={styles}></Icon>;
    case "account-group":
      return <Icon path={mdiAccountGroup} className="base-icon" style={styles}></Icon>;
    case "hard-hat":
      return <Icon path={mdiHardHat} className="base-icon" style={styles}></Icon>;
    case "human-greeting":
      return <Icon path={mdiHumanGreeting} className="base-icon" style={styles}></Icon>;
    case "account-hard-hat":
      return <Icon path={mdiAccountHardHat} className="base-icon" style={styles}></Icon>;
    case "island":
      return <Icon path={mdiIsland} className="base-icon" style={styles}></Icon>;
    case "cash-multiple":
      return <Icon path={mdiCashMultiple} className="base-icon" style={styles}></Icon>;
    case "calendar-month":
      return <Icon path={mdiCalendarMonth} className="base-icon" style={styles}></Icon>;
    case "emoticon-outline":
      return <Icon path={mdiEmoticonOutline} className="base-icon" style={styles}></Icon>;
    case "clipboard-account":
      return <Icon path={mdiClipboardAccount} className="base-icon" style={styles}></Icon>;
    case "newspaper-variant-outline":
      return <Icon path={mdiNewspaperVariantOutline} className="base-icon" style={styles}></Icon>;
    case "folder-multiple-plus-outline":
      return <Icon path={mdiFolderMultiplePlusOutline} className="base-icon" style={styles}></Icon>;
    case "sitemap":
      return <SitemapIcon style={styles} className="base-icon" />;
    case "hammer-screwdriver":
      return <Icon path={mdiHammerScrewdriver} className="base-icon" style={styles}></Icon>;
    case "account-multiple":
      return <Icon path={mdiAccountMultiple} className="base-icon" style={styles}></Icon>;
    case "laptop":
      return <LaptopIcon style={styles} className="base-icon" />;
    case "monitor":
      return <MonitorIcon style={styles} className="base-icon" />;
    case "lightbulb-on-outline":
      return <LightbulbOnOutlineIcon style={styles} className="base-icon" />;
    case "clipboard-account":
      return <Icon path={mdiClipboardAccountOutline} className="base-icon" style={styles}></Icon>;
    case "hammer":
      return <HammerIcon style={styles} className="base-icon" />;
    case "truck-trailer":
      return <TruckTrailerIcon style={styles} className="base-icon" />;
    case "flask-outline":
      return <FlaskOutlineIcon style={styles} className="base-icon" />;
    case "egg-easter":
      return <EggEasterIcon style={styles} className="base-icon" />;
    case "dumbbell":
      return <DumbbellIcon style={styles} className="base-icon" />;
    case "whistle-outline":
      return <Icon path={mdiWhistleOutline} className="base-icon" style={styles}></Icon>;
    case "medal":
      return <MedalIcon style={styles} className="base-icon" />;
    case "plus":
      return <PlusIcon style={styles} className="base-icon" />;
    case "calendar-clock":
      return <CalendarClockIcon style={styles} className="base-icon" />;
    case "calendar-remove":
      return <CalendarRemoveIcon style={styles} className="base-icon" />;
    case "calendar-question":
      return <CalendarQuestionIcon style={styles} className="base-icon" />;
    case "wrench-outline":
      return <WrenchIcon style={styles} className="base-icon" />;
    case "human-handsup":
      return <HumanHandsupIcon style={styles} className="base-icon" />;
    case "ethernet-cable":
      return <EthernetCableIcon style={styles} className="base-icon" />;
    case "power-plug":
      return <PowerPlugIcon style={styles} className="base-icon" />;
    case "book-open-variant":
      return <BookOpenVariantIcon style={styles} className="base-icon" />;
    case "clipboard-check-outline":
      return <ClipboardCheckOutlineIcon style={styles} className="base-icon" />;
    case "clipboard-text-outline":
      return <ClipboardTextOutlineIcon style={styles} className="base-icon" />;
    case "playlist-check-icon":
      return <PlaylistCheckIcon style={styles} className="base-icon" />;
    case "table-search":
      return <TableSearchIcon style={styles} className="base-icon" />;
    case "account-voice":
      return <Icon path={mdiAccountVoice} className="base-icon" style={styles}></Icon>;
    case "pig":
      return <Icon path={mdiPig} className="base-icon" style={styles}></Icon>;
    case "account-switch":
      return <Icon path={mdiAccountSwitch} className="base-icon" style={styles}></Icon>;
    case "desktop-classic":
      return <Icon path={mdiDesktopClassic} className="base-icon" style={styles}></Icon>;
    case "waves":
      return <Icon path={mdiWaves} className="base-icon" style={styles}></Icon>;
    case "weather-sunny":
      return <WeatherSunnyIcon style={styles} className="base-icon" />;
    case "auto-fix":
      return <AutoFixIcon style={styles} className="base-icon" />;
    case "view-module-icon":
      return <ViewModuleIcon style={styles} className="base-icon" />;
    case "information-outline":
      return <InformationOutlineIcon style={styles} className="base-icon" />;
    case "truck-plus":
      return <Icon path={mdiTruckPlus} style={styles} className="base-icon" />;
    case "play-list-edit":
      return <Icon path={mdiPlaylistEdit} style={styles} className="base-icon" />;
    case "alert":
      return <Icon path={mdiAlert} style={styles} className="base-icon" />;
    default:
      return <DynamicIcon style={styles} className="base-icon" icon={`${mdiString}`} />;
  }
}
