import getMonth from "date-fns/getMonth";
import store from "../store";

export default () => {
  const month = getMonth(new Date()) + 1;
  const lang = store.getState().language.language;

  if (month === 1) {
    return lang.monthJanuary;
  } else if (month === 2) {
    return lang.monthFebruary;
  } else if (month === 3) {
    return lang.monthMarts;
  } else if (month === 4) {
    return lang.monthApril;
  } else if (month === 5) {
    return lang.monthMay;
  } else if (month === 6) {
    return lang.monthJune;
  } else if (month === 7) {
    return lang.monthJuly;
  } else if (month === 8) {
    return lang.monthAugust;
  } else if (month === 9) {
    return lang.monthSeptember;
  } else if (month === 10) {
    return lang.monthOctober;
  } else if (month === 11) {
    return lang.monthNovember;
  } else if (month === 12) {
    return lang.monthDecember;
  }
};
