// Libs
import React from "react";
import hm from "./highlight-match";

// Store
import store from "./../store";

export default function (user, searchTerm = "") {
  const { showMasterGroup, showUserGroup, showJobTitle } = store.getState().appConfig;

  const getText = (text) => {
    if (searchTerm) return hm(text, searchTerm);
    else return text;
  };

  return (
    <>
      {showMasterGroup && user?.masterGroup?.title && <span>{getText(user.masterGroup.title.trim())}</span>}
      {showMasterGroup &&
        user?.masterGroup &&
        ((showUserGroup && user?.userGroup?.title) || (showJobTitle && user?.jobTitle)) && <span>, </span>}
      {showUserGroup && user?.userGroup?.title && <span>{getText(user.userGroup.title)}</span>}
      {showUserGroup && showJobTitle && user?.jobTitle && <span>, </span>}
      {showJobTitle && user && <span>{getText(user.jobTitle)}</span>}
    </>
  );
}
