import { useDispatch } from "react-redux";
import React from "react";

// Icons
import { AlertDecagramIcon } from "mdi-react";

// Actions
import { showDialog } from "../../../actions/uiActions";
import { updateTimeSheetApprovalStatus } from "../../../actions/timeRegistrationActions";

// Config
import approvalStatus from "../config/approvalStatus";

export default function UseHandleReject() {
  const dispatch = useDispatch();

  const handleReject = ({ value, timeRegistrationId, callback = () => {} }) => {
    dispatch(
      showDialog({
        styleType: "warning",
        icon: <AlertDecagramIcon />,
        showTextArea: true,
        title: "Afvis registrering",
        content: "Indtast kommentar her. Din kommentar kan ses af medarbejderen.",
        primaryActionTitle: "Afvis registrering",
        primaryAction: ({ message }) => {
          dispatch(
            updateTimeSheetApprovalStatus({
              timeSheetIds: [value],
              status: approvalStatus.rejected,
              comment: message,
              timeRegistrationId,
              callback,
            })
          );
        },
      })
    );
  };

  return handleReject;
}
