import React from "react";
import { css } from "emotion";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import ContactMenuOptions from "./ContactMenuOptions";

const ContactsListItemSkeleton = ({ index = 1 }) => (
  <div className={componentStyle()}>
    <div className="contact-picture" style={{ animationDelay: `${index * 1}ms` }}>
      {getProfilePictureFromUserObject({}, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
    </div>
    <div className="fake-title-container">
      <div className="fake-title header" style={{ animationDelay: `${index * 10}ms` }}>
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
      </div>
      <div className="fake-title sub-header" style={{ animationDelay: `${index * 20}ms` }}>
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
      </div>
    </div>
    <ContactMenuOptions className="contact-menu-options" style={{ animationDelay: `${index * 10}ms` }} />
  </div>
);

const ContactsListItemSkeletonGroup = () => (
  <>
    <ContactsListItemSkeleton index={1} />
    <ContactsListItemSkeleton index={2} />
    <ContactsListItemSkeleton index={3} />
    <ContactsListItemSkeleton index={4} />
    <ContactsListItemSkeleton index={5} />
    <ContactsListItemSkeleton index={6} />
    <ContactsListItemSkeleton index={7} />
    <ContactsListItemSkeleton index={8} />
    <ContactsListItemSkeleton index={9} />
    <ContactsListItemSkeleton index={10} />
    <ContactsListItemSkeleton index={11} />
    <ContactsListItemSkeleton index={12} />
  </>
);

const componentStyle = (props) => css`
  background-color: var(--white);
  margin-bottom: -1px;
  border-top: 1px solid var(--midGrey);
  border-bottom: 1px solid var(--midGrey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  flex-shrink: 0;
  max-width: ${breakpoints.lg}px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-width: ${breakpoints.lg}px;
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1px solid var(--midGrey);
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .contact-menu-options {
    opacity: 0.2;
    filter: brightness(30%);
    animation: blink 1.8s infinite;
  }

  .contact-picture {
    opacity: 0.2;
    animation: blink 1.8s infinite;
  }

  .fake-title-container {
    flex-grow: 1;
  }

  .fake-title {
    display: flex;
    opacity: 0.2;
    animation: blink 1.8s infinite;

    .tile {
      background-color: var(--darkGrey);
      border-radius: 4px;
      margin-right: 0.45rem;
    }

    &.header {
      margin-bottom: 0.9rem;

      .tile-1 {
        width: 50px;
        height: 7px;
      }
      .tile-2 {
        width: 35px;
        height: 7px;
      }
      .tile-3 {
        width: 80px;
        height: 7px;
      }
    }
    &.sub-header {
      .tile-1 {
        width: 40px;
        height: 4px;
      }
      .tile-2 {
        width: 55px;
        height: 4px;
      }
      .tile-3 {
        width: 30px;
        height: 4px;
      }
    }
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export { ContactsListItemSkeletonGroup, ContactsListItemSkeleton };
