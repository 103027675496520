import useSWR from "swr";
import { swrFetcher } from "./swr-fetcher";

export default function swrRequest({ url }) {
  const {
    data: data,
    error: error,
    isValidating: loading,
  } = useSWR(url, swrFetcher, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // If the error is 404, we don't want to retry
      if (error.status === 404) {
        return;
      }

      // if error is not 404, retry 2 times with 5 seconds delay, so in total 3 attempts
      if (retryCount >= 2) return;
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
  });

  return { data, error, loading };
}
