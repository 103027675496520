// libs
import React, { useEffect, useState, useRef } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import { InfoOutlineIcon, ChevronRightIcon } from "mdi-react";

// Components
import ListItem from "../../ui/ListItem";
import FloatingSearchInput from "../../ui/FloatingSearchInput";
import { ListItemSkeletonGroup } from "../../ui/ListItemSkeleton";
import ReverseScrollView from "./reverseScrollView";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";

// redux
import { addToast, hideModalPage } from "../../../actions/uiActions";
import { getUsersInApp, removeUsers } from "../../../actions/liveChatActions";

// Util
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import highlightMatch from "../../../utilities/highlight-match";
import getLastTimeUserWasOnlineString from "../utilities/getLastTimeUserWasOnlineString";
import breakpoints from "../../../config/breakpoints";
import isUserOnline from "../utilities/isUserOnline";

function UserList(props) {
  const { url } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const initailize = useRef(false);
  const timerDebounceRef = useRef();
  const users = useSelector((state) => state.liveChat.usersList);
  const currentUser = useSelector((state) => state.auth.user);
  const socket = useSelector((state) => state.liveChat.socket);
  const onlineUsers = useSelector((state) => state.liveChat.onlineUsers);
  const { language: lang } = useSelector((state) => state.language);

  useEffect(() => {
    if (!initailize.current) {
      initailize.current = true;
    }
    return () => {
      // remove users from redux
      if (initailize.current) dispatch(removeUsers());
    };
  }, []);

  useEffect(() => {
    // get users with searchTerm with a delay
    const delayTime = setTimeout(() => dispatch(getUsersInApp({ searchTerm: searchTerm })), 400);
    return () => clearTimeout(delayTime);
  }, [searchTerm]);

  function clickOnUserButtonHandler({ receiver }) {
    // check for existing chat rooms
    socket.emit("checkForChatRoom", receiver, (chatId) => {
      // if there is not already a chat room between the users
      if (chatId === null) {
        dispatch(addToast({ title: "fejl", content: "der skete en fejl", styleType: "error", duration: 3000 }));
      } else if (chatId === false) {
        // create chatRoom
        socket.emit("createOneOnOneChatRoom", [currentUser, receiver], (chatRoom) => {
          if (chatRoom) {
            // set current chatRoom
            dispatch(hideModalPage());
            // navigate to chatRoomPage
            pageNavigatorV2({
              path: `${url}${chatRoom.id}`,
              direction: "forward",
            });
          }
        });
      } else {
        dispatch(hideModalPage());

        // navigate to chatRoomPage
        pageNavigatorV2({
          path: `${url}${chatId}`,
          direction: "forward",
        });
      }
    });
  }

  function handleDebounceScrollEnd() {
    // if timer is set, reset timer
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }

    // Start the timer and save it in timerDebounceRef
    timerDebounceRef.current = setTimeout(() => {
      // get chatRooms after 100ms
      if (users.loading === false) dispatch(getUsersInApp({ searchTerm: searchTerm, scrollEnd: true }));
    }, 100);
  }

  return (
    <ReverseScrollView className={componentStyle()} onScrollEnd={() => handleDebounceScrollEnd()} reverse={false}>
      {/* search bar */}
      <FloatingSearchInput style={{ marginTop: "0.3rem" }} onChange={(e) => setSearchTerm(e.target.value)} />

      {/* button for creating chat group */}
      {/* <ListItem
        className="createGroupButton"
        title={"Opret gruppe"}
        clickable={false}
        onClick={() => console.log("opret gruppe")}
      /> */}

      {/* description */}
      <p className="description">Brugere: </p>

      {/* loading then no users */}
      {users.loading === true && users.error === false && users.users.length === 0 && (
        <ListItemSkeletonGroup style={{ width: "100%" }} />
      )}

      {/* could not get users */}
      {users.loading === false && users.error === true && (
        <StatusBox
          style={{ marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.errorCouldNotGetUsers}
          content={lang.errorCouldNotGetUsersInformation}
        />
      )}

      {/* users */}
      {users.error === false &&
        users.users.length > 0 &&
        users.users.map((user) => (
          <ListItem
            className="user"
            key={user.id}
            clickable={true}
            onClick={() => clickOnUserButtonHandler({ receiver: user })}
            imageLeft={getProfilePicture(user, 42, { marginRight: "0.5rem", marginBottom: "-3px" })}
            title={highlightMatch(user.name, searchTerm)}
            middleTitle={highlightMatch(user.jobTitle, searchTerm)}
            subTitle={
              isUserOnline({ userObject: user, onlineUsers: onlineUsers }) === true
                ? lang.onlineNow
                : lang.lastActive +
                  ": " +
                  getLastTimeUserWasOnlineString({ userObject: user, language: currentUser.language, lang: lang })
            }
          />
        ))}

      {/* loading then there is already users */}
      {users.loading === true && users.error === false && users.users.length > 0 && <InlineSpinner />}
    </ReverseScrollView>
  );
}
const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  align-items: center;

  .user {
    max-width: ${breakpoints.lg}px;
    width: 100%;
  }

  .createGroupButton {
    max-width: ${breakpoints.lg}px;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 0.5rem;
  }

  .description {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-size: 20px;
    margin-top: 4.8rem;
  }

  .listSkeleton {
    width: 100%;
    margin: auto;
  }
`;

export default UserList;
