/**
 * We wish to return headers that contain:
 * - a date
 * - the total registrations for that date
 *
 * @param {array} rows
 * @param {number} fromDate
 * @param {number} toDate
 */

export default function ({ rows, fromDate, toDate }) {
  try {
    // Prevent crash...
    if (!rows || (Array.isArray(rows) && rows.length === 0)) return [];
    if (!fromDate || !toDate) return;

    // Get dates-array
    let headers = [];
    for (let i = 0; i < rows[0].dates.length; i++) {
      headers.push({ date: rows[0].dates[i].date, total: 0 });
    }

    // Fill dates with the total registrations for the given date
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].dates.length; j++) {
        headers[j].total += rows[i].dates[j].count;
      }
    }

    // return the dates/headers
    return headers;
  } catch (error) {
    console.log(error);
  }
}
