// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { addToast, hideDialog, hideModalPage, showDialog } from "../../actions/uiActions";

// Icons
import { LockQuestionIcon, AlertDecagramIcon, ErrorOutlineIcon } from "mdi-react";

// Components
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import ScrollView from "../ui/ScrollView";
import { renewPassword, renewPasswordHandled } from "../../actions/authActions";
import colors from "../../style/colors";
import ToastHandler from "../ui/ToastHandler";
import DropDownList from "../ui/dropDown/DropDownList";
import countryCodes from "./config/countryCodes";

const ForgotPassword = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(props.prefillValue || "");
  const [countryCode, setCountryCode] = useState(localStorage.getItem("last-used-country-code") || "+45");
  const { appConfig } = useSelector((state) => state);
  const { renewingPassword } = useSelector((state) => state.auth);

  const renewPasswordError = useSelector((state) => state.auth.renewPasswordError);

  useEffect(() => {
    localStorage.setItem("last-used-country-code", countryCode);
  }, [countryCode]);

  // If something goes wrong when reset password
  if (renewPasswordError) {
    props.renewPasswordHandled();
    props.addToast({
      title: "Could not reset your password",
      content: "Something went wrong",
      icon: <ErrorOutlineIcon />,
      styleType: "error",
      duration: 20000,
    });
  }

  function handlePasswordReset() {
    props.showDialog({
      title: "Warning",
      content:
        "You are about to request a new password. Doing so will deactivate your old password and you will no longer be able to access the app with it",
      icon: <AlertDecagramIcon />,
      styleType: "warning",
      primaryActionTitle: "Okay, request a new password",
      secondaryActionTitle: "Cancel, keep my old password",
      primaryAction: () => {
        props.renewPassword({ phone: phoneNumber, countryCode });
      },
    });
  }

  return (
    <>
      <ScrollView className={componentStyle(props)} style={{ backgroundColor: "#ffffff" }}>
        <div className="forgot-password-container">
          <div className="icon-container">
            <LockQuestionIcon className="lock-icon" size={50} color={appConfig.primaryColor} />
          </div>
          <h3 style={{ marginBottom: "1rem" }}>Forgot your password?</h3>
          <p style={{ marginBottom: "3rem" }}>
            Use the form below to reset your password. You will receive a new one to your phone
          </p>

          <div className="input-wrapper">
            <DropDownList
              className={"country-code"}
              onChange={(e) => setCountryCode(e.target.value)}
              dropDownListContent={countryCodes}
              selectedContentId={countryCode}
              usePlaceholder={false}
            />
            <TextInput
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={`Enter your phone number here`}
              value={phoneNumber}
            />
          </div>
          <Button
            disabled={phoneNumber === ""}
            onClick={handlePasswordReset}
            title="Request a new password"
            active={renewingPassword}
          />
        </div>
      </ScrollView>
      <ToastHandler />
    </>
  );
};

const componentStyle = (props) => css`
  .forgot-password-container {
    padding: 0.7rem;
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    color: var(--black);
    text-align: center;

    .input-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .country-code {
      cursor: pointer;
      width: 104px;
      border-radius: 2px;
      padding-right: 1px;
      margin-right: 0.5rem;

      select {
        cursor: pointer;
        font-size: 0.8rem;
        padding-left: 5px;
        padding-right: 0;
      }
      svg {
        margin-left: 0;
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 78px;
      border: 1px var(--lightGrey) solid;
      border-radius: 50%;
      margin: 2rem auto;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  renewPasswordHandled: bindActionCreators(renewPasswordHandled, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  renewPassword: bindActionCreators(renewPassword, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
