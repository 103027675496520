import { addDays, format, parse } from "date-fns";

export default function getDatesArray(startDate, endDate, locale) {
  // Produces an array with all dates between the selected / initial start date and end date
  let dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(format(new Date(parse(currentDate, "yyyyMMdd", 0)), "yyyyMMdd", locale));
    currentDate = format(addDays(new Date(parse(currentDate, "yyyyMMdd", 0)), 1), "yyyyMMdd", locale);
  }
  return dateArray;
}
