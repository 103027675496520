// Libs
import React from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

import { ContactsList } from "./components/ContactList";
// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

export default function ContactsGroup({ match }) {
  const { language: lang } = useSelector((state) => state.language);

  // Params
  const groupId = parseInt(match.params.groupId) || null;
  let { mainContactPageId, pageTitle, contactType, groupType = null } = queryString.parse(window.location.search);

  const page = useCurrentPage({ pageId: mainContactPageId });

  return (
    <Page>
      <TopBar title={groupId ? pageTitle : lang.showAll} useDefaultBackButton={true} />
      <ContactsList isASubGroup={true} groupId={groupId} mainPage={page} contactType={contactType} groupType={groupType} />
    </Page>
  );
}
