import { useEffect, useState } from "react";

import req from "../../../utilities/request-utility";

const useNewsPostViewTracker = ({ newsPostRef, subTypeId, postId, useExpandCollapseLayout = false }) => {
  // states used for tracking if the post is viewed
  const [isVisible, setIsVisible] = useState(false);
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    if (!newsPostRef?.current || useExpandCollapseLayout) return;

    // Observer is used to observe changes in the intersection of a target element, in this case the newsPostRef.
    // This is a modern way to to do it and is performant.
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // viewport
        rootMargin: "0px", // no margin
        threshold: getThreshold(newsPostRef.current.clientHeight), // procent of target visible in decimals example: 0.2 = 20%
      }
    );

    if (newsPostRef.current) {
      observer.observe(newsPostRef.current);
    }

    // Clean up the observer
    return () => {
      if (newsPostRef.current) {
        observer.unobserve(newsPostRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let readTimeout = null;

    // if visible, start a timeout to send a request to the server after 5 seconds, if not visible after 5 seconds don't send the request
    if (isVisible) {
      readTimeout = setTimeout(() => {
        if (isVisible && isViewed === false) {
          req()
            .post(`news/${subTypeId}/${postId}/views`)
            .then(() => {
              setIsViewed(true);
            })
            .catch(() => {
              setIsViewed(false);
            });
        }
      }, 5000);
    }

    return () => {
      if (readTimeout) clearTimeout(readTimeout);
    };
  }, [isVisible]);

  function getThreshold(height) {
    // If the height is less than 400px, return 0.5 equals 200px of the target is visible
    if (height < 400) {
      return 0.5;
    } else {
      // If the height is more than 400px, return 200/height. This will give a threshold in decimals of 200 pixels
      return parseFloat((200 / height).toFixed(2));
    }
  }
};

export default useNewsPostViewTracker;
