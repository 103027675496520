// Libs
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";

// Components
import { AlertCircleOutlineIcon } from "mdi-react";
import PostComment from "./PostComment";

// Styles
import colors from "../../style/colors";
import PostCommentForm from "./PostCommentForm";
import ScrollView from "./ScrollView";

const PostComments = (props) => {
  const { comments = [], postId = "" } = props;
  const [syncedShowForm, setSyncedShowForm] = useState(false);

  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  return (
    <div className={style(props)} ref={props.forwardRef}>
      <ScrollView onScroll={(e) => e.stopPropagation()} className="scroll-view" style={{ paddingBottom: "120px" }}>
        {/* There are comments */}
        {comments &&
          comments.map((comment) => <PostComment key={`post-comment-${comment.id}`} {...props} comment={comment} />)}

        {/* There are no comments */}
        {(!comments || comments.length === 0) && (
          <p className="meta">
            <AlertCircleOutlineIcon /> {lang.noComments}
          </p>
        )}
      </ScrollView>

      <div className="post-comment-form-wrapper" style={{ pointerEvents: syncedShowForm === false ? "none" : "initial" }}>
        <PostCommentForm
          className="post-comment-form"
          boxPadding={props.boxPadding}
          onComment={(comment) => props.onComment(comment)}
          submittingComment={props.submittingComment}
          imageInputId={`image-upload-${postId}`}
          onShowFormUpdate={(formState) => setSyncedShowForm(formState)}
        />
      </div>
    </div>
  );
};

const style = (props) => css`
  margin-left: -${props.boxPadding}rem;
  margin-right: -${props.boxPadding}rem;
  display: flex;
  max-height: 65vh;
  overflow-y: hidden;
  border-top: 1px var(--lightGrey) solid;
  background-color: var(--white);
  position: relative;

  .scroll-view {
    padding: ${props.boxPadding}rem ${props.boxPadding}rem 140px ${props.boxPadding}rem;
  }

  .post-comment-form-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${props.boxPadding}rem;
  }

  .meta {
    /* If updating this make sure to also update the one in PostLikes to match */
    color: var(--darkGrey);

    svg {
      width: 0.9rem;
      height: 0.9rem;
      color: var(--darkGrey);
      vertical-align: bottom;
      margin-bottom: 2px;
    }
  }
`;

PostComments.propTypes = {
  /** Array which holds comments to be displayed by component */
  comments: PropTypes.array,
};

export default connect(null, null, null, { forwardRef: true })(PostComments);
