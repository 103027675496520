// Libs
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as queryString from "query-string";
import { css } from "emotion";

// Components
import ButtonRounded from "../../ui/ButtonRounded";
import ActionWrapper from "../../ui/ActionWrapper";

// Actions
import { hideModalPage } from "../../../actions/uiActions";

// Style
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";

// Icons
import { TickCircleIcon, CancelIcon, DotsVerticalIcon, ExportIcon, PencilIcon, TrashCanOutlineIcon } from "mdi-react";

// Hooks
import UseHandleAccept from "../hooks/useHandleAccept";
import UseHandleReject from "../hooks/useHandleReject";
import UseHandleExport from "../hooks/useHandleExport";
import UseHandleEdit from "../hooks/useHandleEdit";
import UseHandleDelete from "../hooks/useHandleDelete";
import UseToggleContextMenu from "../hooks/useToggleContextMenu";

const RegistrationDetailModalActions = ({ value, exported, user, getTimesheet }) => {
  const dispatch = useDispatch();
  const handleAccept = UseHandleAccept();
  const handleReject = UseHandleReject();
  const handleExport = UseHandleExport();
  const handleEdit = UseHandleEdit();
  const handleDelete = UseHandleDelete();
  const toggleContextMenu = UseToggleContextMenu();

  const permissions = useSelector((state) => state.timeRegistration.permissions);
  const timeSheet = useSelector((state) => state.timeRegistration.table.timeSheets).find(
    (timeSheet) => timeSheet.id === value
  );

  let timeRegistrationId = queryString.parse(window.location.search).dataId;

  return (
    <div className={`${componentStyle()} ${exported && "disabled"}`}>
      {permissions.canApprove && (
        <>
          <ButtonRounded
            className="btn-reject"
            secondary
            styleType="error"
            onClick={(e) => {
              e.stopPropagation();
              handleReject({ value, timeRegistrationId, callback: () => getTimesheet() });
            }}
          >
            <CancelIcon fill={colors.red} />
            Afvis
          </ButtonRounded>

          <ButtonRounded
            className="btn-approve"
            secondary
            styleType="ok"
            onClick={(e) => {
              e.stopPropagation();
              handleAccept({ value, timeRegistrationId, callback: () => getTimesheet() });
            }}
          >
            <TickCircleIcon fill={colors.green} />
            Godkend
          </ButtonRounded>
        </>
      )}

      {permissions?.canExport && timeSheet?.timeRegistrationData?.isApproved && (
        <ButtonRounded
          className="btn-export"
          secondary
          onClick={(e) => {
            e.stopPropagation();
            handleExport({ value, timeRegistrationId, callback: () => getTimesheet() });
          }}
        >
          <ExportIcon />
          Eksporter
        </ButtonRounded>
      )}

      {permissions?.canRegisterForOthers && (
        <>
          <ButtonRounded
            className="btn-edit"
            secondary
            onClick={(e) => {
              e.stopPropagation();
              handleEdit({
                user,
                timeSheet,
                callback: () => getTimesheet(),
              });
            }}
          >
            <PencilIcon />
            Rediger
          </ButtonRounded>
          <ButtonRounded
            className="btn-delete"
            secondary
            onClick={(e) => {
              e.stopPropagation();
              handleDelete({
                value,
                timeRegistrationId,
                callback: () => {
                  dispatch(hideModalPage());
                },
              });
            }}
          >
            <TrashCanOutlineIcon />
            Slet
          </ButtonRounded>

          {(permissions.canExport || permissions.canRegisterForOthers) && (
            <ActionWrapper
              className="context-menu-icon"
              onClick={(e) => {
                e.stopPropagation();
                toggleContextMenu({ value, timeSheet, timeRegistrationId, user, callback: getTimesheet });
              }}
            >
              <DotsVerticalIcon color={colors.midDarkGrey} />
            </ActionWrapper>
          )}
        </>
      )}
    </div>
  );
};

const componentStyle = () => css`
  background-color: white;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid var(--lightGrey);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  button {
    margin-right: 0.5rem;
    svg {
      margin-right: 0.2rem;
    }
  }

  .context-menu-icon {
    display: none;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    .context-menu-icon {
      display: block;
    }

    .btn-delete,
    .btn-edit,
    .btn-export {
      display: none;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    .btn-approve,
    .btn-reject {
      svg {
        display: none;
      }
    }
  }
`;

export default RegistrationDetailModalActions;
