import { format, isBefore, isToday, startOfDay, startOfMinute } from "date-fns";
import timePickerValueToString from "../../../utilities/time-picker-value-to-string";

/**
 * Checks if the date is before the current date
 * @param { string } date - The date to check, in the format YYYY-MM-DD
 */
export const isDateInPast = (date) => {
  const today = startOfDay(new Date());
  const other = startOfDay(new Date(date));
  return isBefore(other, today);
}

/**
 * Checks if the time is before the current time
 * @param { TimePickerValue } time - The time to check, in the format HH:mm
 * @param { string } date - The date to check, in the format YYYY-MM-DD
 */
export const isTimeInPast = (date, time) => {
  const today = startOfMinute(new Date());
  const other = startOfMinute(new Date(`${date}T${timePickerValueToString(time)}`));
  return isBefore(other, today);
}

/**
 * Checks if the date is today
 * @param date - The date to check, in the format YYYY-MM-DD
 * @return {boolean} - True if the date is today, false otherwise
 */
export const isDateToday = (date) => {
  return isToday(new Date(date));
}

export const isDateAndHourNow = (date, time) => {
  const current = new Date();

  const cDate = format(current, "yyyy-MM-dd");
  const cHours = format(current, "HH");

  return cDate === date && cHours === time.hours;
}

/**
 * Checks if the time is before the current time
 * @param { DateTimePickerValue } value
 * @param { DateTimePickerValue } other
 */
export const isDateTimeBefore = (value, other) => {
  // Compare dates
  if (value.date !== other.date) {
    return isBefore(new Date(value.date), new Date(other.date));
  }

  // Compare hours
  if (value.time.hours !== other.time.hours) {
    return value.time.hours < other.time.hours;
  }

  // Compare minutes
  return value.time.minutes < other.time.minutes;
}

export const isDateTimeEqual = (value, other) => {
  // Compare dates
  if (value.date !== other.date) {
    return false;
  }

  // Compare hours
  if (value.time.hours !== other.time.hours) {
    return false;
  }

  // Compare minutes
  return value.time.minutes === other.time.minutes;
}

export const isTimeBefore = (value, other) => {
  // Compare hours
  if (value.hours !== other.hours) {
    return value.hours < other.hours;
  }

  // Compare minutes
  return value.minutes < other.minutes;
}