export const registrationTypes = {
  RADIO: "RADIO",
  RADIO_HORIZONTAL: "RADIO_HORIZONTAL",
  DROPDOWN: "DROPDOWN",
  DROPDOWN_WITH_EXTERNAL_DATA: "DROPDOWN_WITH_EXTERNAL_DATA",
  DROPDOWN_FORM_BUILDER: "DROPDOWN_FORM_BUILDER",
  CHECKBOX: "CHECKBOX",
  RATING: "RATING",
  RATING_CUSTOM_LABELS: "RATING_CUSTOM_LABELS",
  FILE: "FILE",
  YES_NO: "YES_NO",
  TEXT: "TEXT",
  DATE: "DATE",
  TIME: "TIME",
  IMAGE: "IMAGE",
  CHECKLIST: "CHECKLIST",
  RADIO_TRAFFIC_LIGHT: "RADIO_TRAFFIC_LIGHT",
  DATA_SELECT: "DATA_SELECT",
  VIDEO: "VIDEO",
  IMAGE_AND_VIDEO: "IMAGE_AND_VIDEO",
  GEOLOCATION: "GEOLOCATION",
  MAIL_TEXT: "MAIL_TEXT",
  INFORMATION: "INFORMATION",
  TABS: "TABS",
  ADMIN_REPLY_QUESTION: "ADMIN_REPLY_QUESTION",
  NUMERIC: "NUMERIC",
};

export const feedTypes = {
  all: "all",
  mostLiked: "mostLiked",
  mine: "mine",
  toMe: "toMe",
  processflow: "processflow",
};

export const postLayoutModes = {
  post: "post",
  accordion: "accordion",
};

export const localStorageAction = {
  saving: "saving",
  delete: "delete",
  get: "get",
};

export const formBuilderModes = {
  edit: "edit",
  duplicate: "duplicate",
};
