/**
 * @param {string} string - The string to convert, in the format HH:mm
 * @returns {TimePickerValue} - The time picker value
 */
export default function stringToTimePickerValue(string) {
  if (!string) {
    return null;
  }

  const [hours, minutes] = string.split(":");
  return { hours, minutes };
}