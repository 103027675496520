import React, { useState } from "react";
import { useDispatch } from "react-redux";
import req from "../../../../../../utilities/request-utility";
import { getPageBuilderPages } from "../../../../../../actions/pageBuilderActions";
import { hideModalPage } from "../../../../../../actions/uiActions";

const useCreatePage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const createPage = async (page) => {
    try {
      setLoading(true);
      await req().post(`admin/pages`, page);

      dispatch(getPageBuilderPages());
      dispatch(hideModalPage());
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { createPage, error, loading };
};

export default useCreatePage;
